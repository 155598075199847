import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { useState, useRef, useEffect } from "react";

const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            contentRef.current.style.maxHeight = contentRef.current.scrollHeight + "px";
        } else {
            contentRef.current.style.maxHeight = "0";
        }
    }, [isOpen]);

    return (
        <div className="border-b border-[#707070]">
            <div
                className="flex items-center justify-between py-2 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="flex-1 font-light tracking-wide">{title}</p>
                {isOpen ? (
                    <CaretUp size={22} color="white" weight="bold" />
                ) : (
                    <CaretDown size={22} color="white" weight="bold" />
                )}
            </div>
            <div
                ref={contentRef}
                className={`overflow-hidden transition-all ease-linear duration-300 transform`}
            >
                <p className="py-2 tracking-wider font-light text-[15px]">{children}</p>
            </div>
        </div>
    );
};

export default AccordionItem;
