import React from 'react'

export default function DataCard({ title, value }) {
    const numericValue = parseFloat(value.replace('%', ''));

    // Determine the text color class based on the numeric value
    let textColorClass = '';
    if (isNaN(numericValue) || !isFinite(numericValue)) {
        textColorClass = 'text-white'; // Set to white if value is NaN or Infinity
    } else {
        textColorClass = numericValue >= 0 ? 'text-green-500' : 'text-red-500';
    }

    return (
        <div className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]">
            <p className="">{title}</p>
            <p className={`text-lg font-medium ${textColorClass}`}>{value}</p>
        </div>
    )
}
