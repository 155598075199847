import React from "react";
import ReactApexChart from "react-apexcharts";

function BarChart({ records, filters, base }) {
  if (!records[filters[0]]) {
    return (
      <div className="flex items-center justify-center h-52">
        <p>No Analytics data present for this {base}</p>
      </div>
    );
  } else
    return (
      <ReactApexChart
        options={{
          chart: {
            id: "basic-bar",
            toolbar: {
              show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            categories: ["link open", "user loggedin", "completed"],
          },
          tooltip: { fillSeriesColor: true },
        }}
        series={filters.reduce((acc, curr) => {
          if (records[curr]) {
            acc.push({ name: curr, data: [records[curr]] });
            return acc;
          } else return acc;
        }, [])}
        // series={[
        //   {
        //     name: filters[0],
        //     data: [records[filters[0]] ?? 0],
        //   },
        //   {
        //     name: filters[1],
        //     data: [records[filters[1]] ?? 0],
        //   },
        //   {
        //     name: filters[2],
        //     data: [records[filters[2]] ?? 0],
        //   },
        // ]}
        type="bar"
        width="450"
      />
    );
}

export default BarChart;
