import { CircleWavyCheck, UploadSimple } from "phosphor-react";
import React, { useState } from "react";
import Stickyheader from "../components/stickyheader";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { strings } from "../common/Strings";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { Link } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";
import AWN from "awesome-notifications";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Checks,
  CircleNotch,
  Copy,
  FloppyDiskBack,
  ShieldSlash,
} from "@phosphor-icons/react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { showSuccess, showWarning } from "../components/toast/toast";

export default function Settings() {
  const [{ user, brand }, dispatch] = useStateValue();
  const [show_verification, setshow_verification] = useState(false);
  const [verification_details, setverification_details] = useState({});
  const [uploading, setuploading] = useState(false);
  const [progress, setprogress] = useState(0);
  const [tokenLoading, setTokenLoading] = useState(false);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [instagramLink, setInstagramLink] = useState(
    brand?.socialMedia?.instagram ?? null
  );
  const [facebookLink, setFacebookLink] = useState(
    brand?.socialMedia?.facebook ?? null
  );
  const [youtubeLink, setYoutubeLink] = useState(
    brand?.socialMedia?.youtube ?? null
  );

  const [smtpSettings, setSmtpSettings] = useState({
    username: user?.smtp?.username ?? "",
    password: user?.smtp?.password ?? "",
    host: user?.smtp?.host ?? "",
    port: user?.smtp?.port ?? "",
    secure: user?.smtp?.secure ?? "true",
  });

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `brand_verification/${brand?.id}/` + brand?.id + ".pdf"
    );
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setuploading(true);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setverification_details({
            ...verification_details,
            documentUrl: downloadURL,
          });
          setuploading(false);
        });
      }
    );
  };

  const uploadBrandImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `brand/${brand?.id}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          async function updateBrand() {
            let headersList = {
              Accept: "*/*",
              Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
            };

            let bodyContent = {
              brandId: brand?.id,
              updates: {
                imageUrl: downloadURL,
              },
            };
            let reqOptions = {
              url: `https://brandapi-zscu3untuq-el.a.run.app/updateBrand`,
              method: "PUT",
              headers: headersList,
              data: bodyContent,
            };

            let response = await axios.request(reqOptions);
            await updateUserImage(user?.appUserId, downloadURL);
            if (response.data.success === false) {
              return;
            }
            if (response.data.success === true) {
              sessionStorage.setItem(
                "brand",
                JSON.stringify({ ...brand, imageUrl: downloadURL })
              );
              dispatch({
                type: "SET_BRAND",
                brand: { ...brand, imageUrl: downloadURL },
              });
            }
          }
          updateBrand();

          // setbrand({ ...brand, imageUrl: downloadURL });
          // updateDoc(doc(db, strings.testBrand, brand?.id), {
          //   imageUrl: downloadURL,
          // });
        });
      }
    );
  };
  const uploadBrandCoverImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `coverImage/${brand?.id}-coverImage.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // setbrand({ ...brand, imageUrl: downloadURL });
          async function updateBrand() {
            let headersList = {
              Accept: "*/*",
              Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
            };

            let bodyContent = {
              brandId: brand?.id,
              updates: {
                coverImage: downloadURL,
              },
            };
            let reqOptions = {
              url: `https://brandapi-zscu3untuq-el.a.run.app/updateBrand`,
              method: "PUT",
              headers: headersList,
              data: bodyContent,
            };

            let response = await axios.request(reqOptions);
            if (response.data.success === false) {
              return;
            }
            if (response.data.success === true) {
              sessionStorage.setItem(
                "brand",
                JSON.stringify({ ...brand, coverImage: downloadURL })
              );
              dispatch({
                type: "SET_BRAND",
                brand: { ...brand, coverImage: downloadURL },
              });
            }
          }
          updateBrand();
        });
      }
    );
  };

  const submit = async () => {
    if (!verification_details?.address) {
      showWarning("Please add office address");
      return;
    }
    if (!verification_details?.description) {
      showWarning("Please add the description");
      return;
    }
    if (!verification_details?.info?.fullName) {
      showWarning("Please fill out the details");
      return;
    }
    if (!verification_details?.info?.designation) {
      showWarning("Please fill out the details");
      return;
    }
    if (!verification_details?.info?.workEmail) {
      showWarning("Please fill out the details");
      return;
    }
    if (!verification_details?.info?.contactNumber) {
      showWarning("Please fill out the details");
      return;
    }
    if (!verification_details?.info?.handle) {
      showWarning("Please fill out the details");
      return;
    }
    if (!verification_details?.documentUrl) {
      showWarning("Please upload the document");
      return;
    }

    var verification = {
      ...verification_details,
      appliedAt: Date.now(),
      verified: false,
    };
    await updateDoc(doc(db, strings.adminCollection, user?.email), {
      verification,
    }).then((res) => {
      showSuccess("Successfully applied for verification.");
      sessionStorage.setItem("user", JSON.stringify({ ...user, verification }));
      dispatch({ type: "SET_USER", user: { ...user, verification } });
    });
  };

  async function copyText(text) {
    navigator.clipboard.writeText(text);
    showSuccess("Copied to clipboard");
    return;
  }

  async function showWarningMsg(msg) {
    showWarning(msg);
  }

  async function createToken() {
    if (!brand?.id) {
      showWarningMsg("Please refresh or relogin to continue");
      return;
    }

    try {
      setTokenLoading(true);
      let headersList = {
        Accept: "*/*",
      };

      let reqOptions = {
        url: "https://api.myrevue.app/generateToken/" + brand?.id,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);

      await updateDoc(doc(db, strings.adminCollection, user?.email), {
        clientSecret: response?.data?.token,
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...user, clientSecret: response?.data?.token })
      );
      dispatch({
        type: "SET_USER",
        brand: { ...user, clientSecret: response?.data?.token },
      });
      setTokenLoading(false);
      window.location.reload();
    } catch (error) {
      showWarningMsg(error);
      setTokenLoading(false);
    }
  }

  async function updateUserImage(userId, imageUrl) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
    };
    let bodyContent = {
      userId: userId,
      imageUrl: imageUrl,
    };

    let reqOptions = {
      url: "https://userapi-zscu3untuq-el.a.run.app/userImageUpdate",
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };

    await axios.request(reqOptions);
  }

  async function submitSMTP() {
    if (!smtpSettings.host) {
      showWarning("Please add host");
      return;
    }
    if (!smtpSettings.host) {
      showWarning("Please add host");
      return;
    }
    if (!smtpSettings.port) {
      showWarning("Please add port");
      return;
    }
    if (!smtpSettings.secure) {
      showWarning("Please select secure");
      return;
    }
    if (!smtpSettings.username) {
      showWarning("Please add username");
      return;
    }
    if (!smtpSettings.password) {
      showWarning("Please add password");
      return;
    }
    await updateDoc(doc(db, "brandAdmin", user?.email), { smtp: smtpSettings });
    sessionStorage.setItem(
      "user",
      JSON.stringify({ ...user, smtp: smtpSettings })
    );
    dispatch({ type: "SET_USER", user: { ...user, smtp: smtpSettings } });
    showSuccess("SMTP settings updated successfully");
  }

  async function updateSocial() {
    try {
      const instagramRegex =
        /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]{1,30}(\/)?$/;
      const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[^/?&]+$/;
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(c\/|user\/)?|youtu\.be\/)([^/?&]+|@[^/?&]+)$/;
      if (!youtubeLink && !instagramLink && !facebookLink) {
        return showWarning("Please enter atleast one social profile");
      }

      if (instagramLink) {
        const isInstagramLink = instagramRegex.test(instagramLink);
        if (!isInstagramLink) {
          return showWarning("Please enter valid social profile links");
        }
      }
      if (facebookLink) {
        const isFacebookLink = facebookRegex.test(facebookLink);
        if (!isFacebookLink) {
          return showWarning("Please enter valid social profile links");
        }
      }
      if (youtubeLink) {
        const isYoutubeLink = youtubeRegex.test(youtubeLink);
        if (!isYoutubeLink) {
          return showWarning("Please enter valid social profile links");
        }
      }
      let headersList = {
        Accept: "*/*",
        Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
      };
      let bodyContent = {
        brandId: brand?.id,
        updates: {
          instagram: instagramLink ?? null,
          facebook: facebookLink ?? null,
          youtube: youtubeLink ?? null,
        },
      };
      let reqOptions = {
        url: "https://brandapi-zscu3untuq-el.a.run.app/updateBrandSocialMedia",
        method: "PUT",
        headers: headersList,
        data: bodyContent,
      };
      const result = await axios.request(reqOptions);
      if (!result.data.success) {
        return showWarning("Something went wrong while updating social links");
      }
      dispatch({
        type: "SET_BRAND",
        brand: { ...brand, socialMedia: bodyContent.updates },
      });
      // window.location.reload()
      showSuccess("Social links updated");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-3xl font-medium">
        <p className="pl-5 first-letter:uppercase ">Settings</p>
      </div>

      {(searchParams.get("tab") === null ||
        searchParams.get("tab")?.includes("profile")) && (
          <div className="relative p-5">
            <div className="max-w-[860px] w-full rounded-xl p-8 bg-[#131418] mx-auto">
              <div className="flex items-center justify-between mb-5">
                <img
                  crossOrigin="anonymous"
                  src={
                    require("../assets/logo/MyRevue Logo elite new.svg").default
                  }
                  alt=""
                  className="h-10"
                />
                <button
                  onClick={() =>
                    !user?.verification
                      ? setshow_verification(!show_verification)
                      : user?.verification?.verified === true
                        ? showSuccess("Brand is already verified")
                        : showSuccess("Verification request has already been sent")
                  }
                  className=" w-fit hover:bg-[#1f2027] rounded-lg h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                >
                  {!user?.verification && (
                    <p className="pt-1 mr-3 font-work">Apply for verification</p>
                  )}
                  {user?.verification?.verified === false && (
                    <p className="pt-1 mr-3 font-work">
                      Applied for verification
                    </p>
                  )}
                  {user?.verification?.verified === true && (
                    <p className="pt-1 mr-3 font-work">Verified</p>
                  )}
                  <CircleWavyCheck
                    size={24}
                    color={
                      user?.verification?.verified === true ? "green" : "#ffffff"
                    }
                  />
                </button>
              </div>
              <div className="relative w-32 h-32 mx-auto rounded-full">
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  className="absolute w-full h-full rounded-full opacity-0"
                  onChange={(e) => uploadBrandImage(e)}
                />
                <img
                  src={brand?.imageUrl}
                  alt="brand_image"
                  className="w-full h-full rounded-full"
                />
              </div>
              <div className="w-full mx-auto max-w-[650px] mt-10 relative">
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  className="absolute w-full h-full opacity-0 rounded-3xl"
                  onChange={(e) => uploadBrandCoverImage(e)}
                />
                <img
                  src={brand?.coverImage}
                  alt="brandCoverImage"
                  className="object-cover w-full h-40 bg-black border-2 border-white rounded-3xl"
                />
              </div>
              <div className="max-w-[708px] w-full mt-20 mx-auto">
                <div className="pl-3">
                  <h3 className="text-xl font-medium tracking-wide">
                    Brand Information
                  </h3>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Brand Name
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      readOnly
                      className="flex-1 tracking-wide text-white bg-transparent outline-none appearance-none min-w-fit"
                      defaultValue={brand?.name}
                    />
                    <button
                      onClick={() => copyText(brand?.name)}
                      className="hover:text-white text-[#5C5C5D]"
                    >
                      <Copy size={28} color="currentcolor" />
                    </button>
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Brand ID
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      readOnly
                      className="flex-1 tracking-wide text-white bg-transparent outline-none appearance-none min-w-fit"
                      defaultValue={brand?.id}
                    />
                    <button
                      onClick={() => copyText(brand?.id)}
                      className="hover:text-white text-[#5C5C5D]"
                    >
                      <Copy size={28} color="currentcolor" />
                    </button>
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Brand Email
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      readOnly
                      className="flex-1 tracking-wide text-white bg-transparent outline-none appearance-none min-w-fit"
                      defaultValue={user?.email}
                    />
                    <button
                      onClick={() => copyText(user?.email)}
                      className="hover:text-white text-[#5C5C5D]"
                    >
                      <Copy size={28} color="currentcolor" />
                    </button>
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <div className="flex items-center h-full">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Authorization Token
                    </p>
                  </div>
                  {user?.clientSecret ? (
                    <div className="relative flex items-center w-full col-span-2 gap-5">
                      <input
                        readOnly
                        className="flex-1 tracking-wide text-white bg-transparent outline-none appearance-none min-w-fit"
                        defaultValue={user?.clientSecret}
                      />
                      <button
                        onClick={() => copyText(user?.clientSecret)}
                        className="hover:text-white text-[#5C5C5D]"
                      >
                        <Copy size={28} color="currentcolor" />
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => createToken()}
                      className="bg-[#4611ea] px-4 py-2 text-sm tracking-wide font-work rounded-md flex items-center justify-center"
                    >
                      {tokenLoading === true ? (
                        <CircleNotch size={24} color="white" />
                      ) : (
                        "Generate Client Secret"
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className="max-w-[708px] w-full mt-8 mx-auto">
                <div className="pl-3">
                  <h3 className="text-xl font-medium tracking-wide">
                    Display language
                  </h3>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Language
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      readOnly
                      className="tracking-wide text-white bg-transparent outline-none appearance-none w-fit"
                      defaultValue={"English"}
                    />
                  </div>
                </div>
              </div>
              <div className="max-w-[708px] w-full mt-8 mx-auto">
                <div className="pl-3">
                  <h3 className="text-xl font-medium tracking-wide">
                    Social Links
                  </h3>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Instagram
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                      placeholder="https://instagram.com/...."
                      value={instagramLink}
                      onChange={(event) => setInstagramLink(event.target.value)}
                    />
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Facebook
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                      placeholder="https://facebook.com/...."
                      value={facebookLink}
                      onChange={(event) => setFacebookLink(event.target.value)}
                    />
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Youtube
                  </p>
                  <div className="relative flex items-center w-full col-span-2 gap-5">
                    <input
                      className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                      placeholder="https://youtube.com/...."
                      value={youtubeLink}
                      onChange={(event) => setYoutubeLink(event.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center mt-5">
                  <button
                    onClick={() => updateSocial()}
                    className=" w-fit bg-background hover:bg-[#1f2027] rounded-lg h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                  >
                    Update Social
                  </button>
                </div>
              </div>
            </div>
            {!user?.verification && show_verification === true && (
              <div
                className="max-w-[860px] w-full rounded-xl p-8 bg-[#131418] mx-auto mt-10"
                id="verification"
              >
                <div className="flex items-center justify-between mb-5">
                  <img
                    crossOrigin="anonymous"
                    src={
                      require("../assets/logo/MyRevue Logo elite new.svg").default
                    }
                    alt=""
                    className="h-10"
                  />
                </div>
                <div className="relative w-32 h-32 mx-auto rounded-full">
                  <input
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    className="absolute w-full h-full rounded-full opacity-0"
                    onChange={(e) => uploadBrandImage(e)}
                  />
                  <img
                    src={brand?.imageUrl}
                    alt="brand_image"
                    className="w-full h-full rounded-full"
                  />
                </div>
                <div className="max-w-[708px] w-full mt-20 mx-auto">
                  <div className="pl-3">
                    <h3 className="text-xl font-medium tracking-wide">
                      Brand Information
                    </h3>
                  </div>
                  <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Brand Name
                    </p>
                    <div className="relative flex items-center w-full col-span-2 gap-5">
                      <input
                        readOnly
                        className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                        defaultValue={brand?.name}
                      />
                    </div>
                  </div>
                  <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Brand Address <span className="text-red-500">*</span>
                    </p>
                    <div className="relative flex items-center w-full col-span-2 gap-5">
                      <input
                        className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                        placeholder="ex: Indore, MP, PIN:XXXXXX"
                        defaultValue={user?.verification?.address}
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            address: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Brand Description <span className="text-red-500">*</span>
                    </p>
                    <div className="relative flex items-center w-full col-span-2 gap-5">
                      <textarea
                        className="w-full tracking-wide text-white bg-transparent outline-none appearance-none h-28"
                        placeholder="ex: brand description"
                        defaultValue={user?.verification?.description}
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            description: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="max-w-[708px] w-full mt-8 mx-auto">
                  <div className="pl-3">
                    <h3 className="text-xl font-medium tracking-wide">
                      Contact information
                    </h3>
                  </div>
                  <div className="grid grid-cols-2 gap-10 mt-5">
                    <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                      <p className="font-semibold text-[#747474] tracking-wide w-full">
                        Full Name <span className="text-red-500">*</span>
                      </p>
                      <div className="relative flex items-center w-full col-span-2 gap-5 mt-2">
                        <input
                          className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                          onChange={(event) =>
                            setverification_details({
                              ...verification_details,
                              info: {
                                ...verification_details.info,
                                fullName: event.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                      <p className="font-semibold text-[#747474] tracking-wide w-full">
                        Designation <span className="text-red-500">*</span>
                      </p>
                      <div className="relative flex items-center w-full col-span-2 gap-5 mt-2">
                        <input
                          className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                          onChange={(event) =>
                            setverification_details({
                              ...verification_details,
                              info: {
                                ...verification_details.info,
                                designation: event.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10 mt-5">
                    <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                      <p className="font-semibold text-[#747474] tracking-wide w-full">
                        Work Email <span className="text-red-500">*</span>
                      </p>
                      <div className="relative flex items-center w-full col-span-2 gap-5 mt-2">
                        <input
                          className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                          onChange={(event) =>
                            setverification_details({
                              ...verification_details,
                              info: {
                                ...verification_details.info,
                                workEmail: event.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                      <p className="font-semibold text-[#747474] tracking-wide w-full">
                        Secondary email (optional)
                      </p>
                      <div className="relative flex items-center w-full col-span-2 gap-5 mt-2">
                        <input
                          className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                          onChange={(event) =>
                            setverification_details({
                              ...verification_details,
                              info: {
                                ...verification_details.info,
                                secondaryEmail: event.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10 mt-5">
                    <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                      <p className="font-semibold text-[#747474] tracking-wide w-full">
                        Contact Number <span className="text-red-500">*</span>
                      </p>
                      <div className="relative flex items-center w-full col-span-2 gap-5 mt-2">
                        <input
                          className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                          onChange={(event) =>
                            setverification_details({
                              ...verification_details,
                              info: {
                                ...verification_details.info,
                                contactNumber: event.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                      <p className="font-semibold text-[#747474] tracking-wide w-full">
                        Website/Social Handle{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <div className="relative flex items-center w-full col-span-2 gap-5 mt-2">
                        <input
                          className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                          onChange={(event) =>
                            setverification_details({
                              ...verification_details,
                              info: {
                                ...verification_details.info,
                                handle: event.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Buisness Document <span className="text-red-500">*</span>
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-4 bg-[#2B2C31] h-12 rounded-lg p-1">
                      <input
                        type="file"
                        accept=".pdf"
                        className="absolute w-full h-full opacity-0"
                        onChange={(e) => uploadFile(e)}
                      />
                      <div className="h-full aspect-square bg-[#666666] rounded-md flex items-center justify-center">
                        <UploadSimple size={24} color="white" />
                      </div>
                      <div className="flex items-center justify-center flex-1 w-full">
                        <p className="text-[#A5A5A5] text-center tracking-wide">
                          {uploading === false && progress < 99 ? (
                            <span className="flex items-center gap-x-3">
                              <UploadSimple size={20} />
                              Upload .pdf format only
                            </span>
                          ) : (
                            <span>
                              {progress > 99
                                ? "Uploaded."
                                : `Uploading ${progress} %`}
                            </span>
                          )}
                        </p>
                      </div>
                      {verification_details?.documentUrl ? (
                        <div className="z-50 mr-5">
                          <Link
                            to={{ pathname: verification_details?.documentUrl }}
                            target="_blank"
                          >
                            <p className=" font-work text-[#d1d1d1] underline underline-offset-2 cursor-pointer">
                              View
                            </p>
                          </Link>
                        </div>
                      ) : null}
                    </div>
                    <p className="text-xs tracking-wide text-[#454545] text-center mt-2">
                      Buisness legal name would be present in document
                    </p>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      onClick={() => submit()}
                      className="bg-[#63D876] hover:bg-green-500 h-12 w-fit px-8 flex items-center  justify-center gap-4 rounded-full"
                    >
                      <FloppyDiskBack size={24} color="white" />
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      {searchParams.get("tab")?.includes("settings") && (
        <div className="relative p-5">
          <div className="max-w-[860px] w-full rounded-xl p-8 bg-[#131418] mx-auto">
            <div className="flex items-center justify-between mb-5">
              <img
                crossOrigin="anonymous"
                src={
                  require("../assets/logo/MyRevue Logo elite new.svg").default
                }
                alt=""
                className="h-10"
              />
              {/* <button className=" w-fit hover:bg-[#1f2027] rounded-lg h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center">
                Send Test Mail
              </button> */}
            </div>
            <div className="max-w-[708px] w-full mt-20 mx-auto">
              <div className="pl-3">
                <h3 className="text-xl font-medium tracking-wide">
                  SMTP Settings
                </h3>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Host
                </p>
                <div className="relative flex items-center w-full col-span-2 gap-5">
                  <input
                    className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                    placeholder="ex: smtp.example.com"
                    value={smtpSettings.host}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        host: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Port
                </p>
                <div className="relative flex items-center w-full col-span-2 gap-5">
                  <input
                    type="number"
                    className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                    placeholder="ex: 465"
                    value={smtpSettings.port}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        port: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Secure
                </p>
                <div className="relative flex items-center w-full col-span-2 gap-5">
                  <select
                    className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        secure: event.target.value,
                      })
                    }
                  >
                    <option value="true" className="text-black">
                      true
                    </option>
                    <option value="false" className="text-black">
                      false
                    </option>
                  </select>{" "}
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Username
                </p>
                <div className="relative flex items-center w-full col-span-2 gap-5">
                  <input
                    className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                    placeholder="username"
                    value={smtpSettings.username}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        username: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Password
                </p>
                <div className="relative flex items-center w-full col-span-2 gap-5">
                  <input
                    className="w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                    type="password"
                    placeholder="password"
                    value={smtpSettings.password}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        password: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => submitSMTP()}
              className="bg-[#93A8F4] h-10 mt-5 mx-auto rounded-lg flex items-center gap-3 px-10 appearance-none outline-none"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
