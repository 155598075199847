import { X } from "@phosphor-icons/react";
import React from "react";
import { useStateValue } from "../../context/StateProvider";
import { useHistory } from "react-router-dom";

export default function Notification({ closeModal }) {
  // do all queries here
  const [{ notifications }, dispatch] = useStateValue();

  const router = useHistory();

  const dateString = (ct) => {
    const d = Date.now() - 24 * 60 * 60 * 1000;
    const ctTemp = new Date(ct);
    if (ct > d) return ctTemp.toLocaleTimeString();
    return ctTemp.toLocaleDateString();
  };

  return (
    <>
      <div
        onClick={() => closeModal(false)}
        className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
        style={{ pointerEvents: "auto" }}
        data-aria-hidden="true"
        aria-hidden="true"
      />
      <div
        role="dialog"
        className="fixed z-50 gap-4 bg-background py-6 px-2 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 inset-y-0 right-0 h-full w-3/4 border-l border-[#8080801e] data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm"
        tabIndex={-1}
        style={{ pointerEvents: "auto" }}
      >
        <div className="flex flex-col py-2 space-y-2 text-center sm:text-left">
          <h2 className="text-lg font-semibold text-foreground">
            Notifications
          </h2>
          <button
            onClick={() => closeModal(false)}
            type="button"
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
          >
            <X size={22} color="white" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="h-full overflow-auto thin-scrollbar flex flex-col gap-3 p-2">
          {notifications.map((n, index) => (
            <div
              className="bg-gray-800 p-1 rounded grid "
              key={"notif" + index}
              style={{ gridTemplateColumns: "1fr 35px" }}
            >
              <p className="  w-full text-sm">{n.message}</p>
              <button
                onClick={() => {
                  router.replace(n.endpoint);
                }}
                className="text-xs row-span-2"
              >
                View
              </button>

              <p className="  w-full text-xs text-gray-500">
                {dateString(n.datetime)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
