import React, { useEffect, useState } from "react";
import { getEngagementAnalytics } from "../common/functions";
import { useStateValue } from "../context/StateProvider";
import BarChart from "./BarChart";
import { CircleNotch } from "@phosphor-icons/react";

function EngagementAnalytics() {
  const [records, setRecords] = useState(null);
  const [base, setbase] = useState("week");
  const [{ brand }] = useStateValue();

  useEffect(() => {
    if (brand.id)
      getEngagementAnalytics(brand.id, base).then((res) => {
        var data = {};
        for (const rec of res) {
          data[rec._id] = rec.count;
        }
        setRecords(data);
      });
    else console.log("no id found to call engagement analytics");
  }, [base]);
  if (!records)
    return (
      <div className="flex flex-col items-center justify-center h-screen p-5 duration-300 transform">
        <p className="pt-10 text-5xl font-bold">Analytics</p>
        <p className="">
          <CircleNotch size={22} color="white" className="animate-spin" />
        </p>
      </div>
    );
  else
    return (
      <div className="p-5 duration-300 transform">
        <div className="flex items-center justify-between h-32">
          {/* <p className="flex-1 text-5xl font-semibold ">Analytics</p> */}
          <div className="">
            <p className="text-3xl font-medium">Analytics</p>
            <p className="tracking-wide">
              Enagement analytics
            </p>
          </div>
          <div className="flex items-center justify-end">
            <button
              onClick={() => setbase("week")}
              className={
                base === "week"
                  ? "rounded-l px-4 py-3 bg-[#131418]"
                  : "rounded-l px-4 py-3 bg-[#202020]"
              }
            >
              This Week
            </button>
            <button
              onClick={() => setbase("month")}
              className={
                base === "month"
                  ? "rounded-r px-4 py-3 bg-[#131418]"
                  : "rounded-r px-4 py-3 bg-[#202020]"
              }
            >
              This Month
            </button>
          </div>
        </div>
        <div className="grid items-start justify-center grid-cols-3 gap-5">
          <div className="h-96 bg-[#131418] p-3 rounded-md border border-[#383636c4]">
            <p className="pb-3 text-xl font-medium">Campaigns</p>
            <BarChart
              records={records}
              filters={[
                "open_campaign",
                "campaign_login_success",
                "user_campaign_submission",
              ]}
              base={base}
            />
          </div>
          <div className=" bg-[#131418] p-3 rounded-md h-96 border border-[#383636c4]">
            <p className="pb-3 text-xl font-medium">Quiz</p>
            <BarChart
              records={records}
              filters={[
                "open_quiz",
                "quiz_login_success",
                "user_quiz_complete",
              ]}
              base={base}
            />
          </div>
          <div className="bg-[#131418] p-3 rounded-md h-96 border border-[#383636c4]">
            <p className="pb-3 text-xl font-medium">FAQ</p>
            <BarChart
              records={records}
              filters={["open_faq", "faq_login_success", "faq_login_success"]}
              base={base}
            />
          </div>
          <div className="bg-[#131418] p-3 rounded-md h-96 border border-[#383636c4]">
            <p className="pb-3 text-xl font-medium ">Challenge</p>
            <BarChart
              records={records}
              filters={[
                "open_challenge",
                "challenge_login_success",
                "user_challenge_submited",
              ]}
              base={base}
            />
          </div>
        </div>
      </div>
    );
}

export default EngagementAnalytics;
