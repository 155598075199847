import React from 'react'
import SocialAnalyticsCard from './card'

export default function Linkedin() {
    return (
        <div className='relative w-full h-screen overflow-y-auto'>
            <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
                <p className="text-lg font-light tracking-wide">Linkedin Analytics</p>
            </div>
            <div className="px-5 py-3 m-10 rounded-md bg-bg_highlight">
                <p className="text-xl tracking-wide">Content</p>
                <div className="grid grid-cols-3 gap-10 pt-5">
                    <SocialAnalyticsCard text={"Reactions"} value={"0"} />
                    <SocialAnalyticsCard text={"Comments"} value={"0"} />
                    <SocialAnalyticsCard text={"Reposts"} value={"0"} />
                </div>
            </div>
            <div className="px-5 py-3 m-10 rounded-md bg-bg_highlight">
                <p className="text-xl tracking-wide">Visitors</p>
                <div className="grid grid-cols-3 gap-10 pt-5">
                    <SocialAnalyticsCard text={"Page views"} value={"0"} />
                    <SocialAnalyticsCard text={"Unique visitors"} value={"0"} />
                    <SocialAnalyticsCard text={"Custom button clicks"} value={"0"} />
                </div>
            </div>
        </div>
    )
}
