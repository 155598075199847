import { Eye } from '@phosphor-icons/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStateValue } from '../../context/StateProvider';
import { showWarning } from '../../components/toast/toast';
import { useHistory } from 'react-router-dom';

export default function Whatsapp() {
    const router = useHistory();
    const [{ brand, user }, dispatch] = useStateValue();
    const accessToken = user?.social?.whatsapp?.accessToken;
    const whatsappId = user?.social?.whatsapp?.waId ?? null
    const [templates, setTemplates] = useState({});

    useEffect(() => {
        if (user?.social?.whatsapp) {
            loadWhatsappTemplates();
        }
    }, [])


    async function loadWhatsappTemplates() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`
        }

        let reqOptions = {
            url: `https://graph.facebook.com/v19.0/${whatsappId}/message_templates`,
            method: "GET",
            headers: headersList,
        }
        let response = await axios.request(reqOptions);
        console.log(response.data)
        setTemplates(response.data)
    }
    return (
        <div className="relative w-full h-full pt-5 bg-background">
            <div className="flex items-center justify-between w-full px-10 mt-5">
                <div className="">
                    <p className="text-2xl font-medium tracking-wide text-white">
                        WhatsApp Templates
                    </p>
                </div>
                <div className="">
                    <Link to="/admin/whatsapp/new">
                        <button onClick={() => {
                            if (!user?.social?.whatsapp) {
                                showWarning("Please connect a whatsapp account to continue")
                                router.replace("/social/channel");
                                return
                            }
                            else {
                                router.push("/admin/whatsapp/new")
                                return
                            }
                        }} className="px-5 py-2 bg-blue-600 rounded-md">Create new template</button>
                    </Link>
                </div>
            </div>
            <div className="p-10">
                <div className="relative block mt-10 mb-10">
                    <div className="relative">
                        <div className="overflow-x-auto min-h-[0.01%]">
                            <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                <thead className="h-14 bg-[#131418]">
                                    <tr>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            #
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Template Name
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Content
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Language
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Category
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Status
                                        </th>
                                        {/* <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Actions
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody className="table-row-group align-middle border-inherit">
                                    {templates?.data?.map((template, index) => (
                                        <React.Fragment key={index}>
                                            <tr
                                                className="table-row align-[inherit]  bg-[#131418] border-t border-white/10"
                                            >
                                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                    {index + 1}
                                                </td>
                                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                    {template?.name}
                                                </td>
                                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                    {template?.components?.filter(obj => obj.type === 'BODY')[0]?.text}
                                                </td>
                                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                    {template?.language}
                                                </td>
                                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                    {template?.category}
                                                </td>
                                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                    {template?.status}
                                                </td>
                                                {/* <td
                                                    className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                                >
                                                    <div className="flex items-center gap-x-3">
                                                        <div className="relative flex items-center justify-center w-10 h-10">
                                                            <Eye
                                                                size={28}
                                                                className="absolute"
                                                                weight="bold"
                                                            />
                                                        </div>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                            {(!templates?.data || templates?.data?.length === 0) &&
                                <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                    No Templates Available
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
