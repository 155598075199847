import React, { useEffect, useState } from 'react'
import { MagnifyingGlass } from "phosphor-react";
import { Eye } from "phosphor-react"
import { useHistory } from 'react-router';
import { useStateValue } from '../context/StateProvider';


export default function Reviews() {
    const [{ reviews }, dispatch] = useStateValue();
    const [review, setreview] = useState([]);
    const [search_text, setsearch_text] = useState("")

    const router = useHistory();

    useEffect(() => {
        setreview(reviews.filter((val) => val.type === "experience"))
    }, [])


    const csvString = [
        [
            "Serial No",
            "Review title",
            "Product Url",
            "Product ID",
            "Status",
            "Views",
            "Rating",
            "Uploaded By"
        ],
        ...reviews.map((item, index) => [
            index + 1,
            item.name,
            `https://myrevue.app/product/${item.id}`,
            item.id,
            item.staus ? "Published" : "Pending",
            item.views,
            item.rating,
            item.uploadedBy.name
        ])
    ].map(e => e.join(",")).join("\n");

    function exportcsv() {
        const data = `data:,${csvString}`;
        const filename = 'experiences.csv';
        const aTag = document.createElement('a');

        aTag.href = data;
        aTag.download = filename;
        aTag.click();
    };
    return (
        <div className='p-5'>
            <div className="flex items-center justify-between">
                <p className="text-3xl tracking-wide text-white font-extralight">Experiences</p>
                <div className="relative flex items-center h-10 w-80">
                    <div className="absolute right-3">
                        <MagnifyingGlass size={24} color="white" />
                    </div>
                    <input type="text" onChange={(e) => setsearch_text(e.target.value)} className='bg-bg_highlight h-10 pr-12 rounded w-80 appearance-none outline-none pl-3 text-white placeholder:text-[#606165] font-normal text-sm placeholder:font-normal tracking-wide' placeholder='Search by title or product name' />
                </div>
            </div>
            <div className="relative block mt-10 mb-10">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className='bg-[#131418] rounded-t-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0'>
                            <thead className="">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Title
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Status
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Views
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Rating
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {review.filter((val) => {
                                    if (search_text === "") {
                                        return val
                                    }
                                    else if ((val.name.toLowerCase().includes(search_text.toLowerCase())) || (val.parentName.toLowerCase().includes(search_text.toLowerCase()))) {
                                        return val
                                    }
                                }).map((review, index) => (
                                    <tr className='table-row align-[inherit] border-inherit'>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">{index + 1}</td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">{review?.name}</td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">{review?.status === true ? "Published" : "Pending"}</td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">{review?.views ?? 0}</td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">{review?.rating}</td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer" onClick={() => router.push("/admin/viewreview/" + review.id)}><div className="flex items-center gap-x-3">View <Eye size={24} /></div></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {review.filter((val) => {
                            if (search_text === "") {
                                return val
                            }
                            else if ((val.name.toLowerCase().includes(search_text.toLowerCase())) || (val.parentName.toLowerCase().includes(search_text.toLowerCase()))) {
                                return val
                            }
                        }).length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                No experiences Available
                            </div>}
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 bg-background w-[calc(100vw-295px)] border-t-[2px] border-[#292929] flex items-center justify-between">
                <div className="h-12 flex items-center justify-between  w-full text-[#808080]">
                    Showing 1 to {review.length} of {review.length}
                </div>
                <div className="text-[#808080] hover:text-white cursor-pointer">
                    {review.length}
                </div>
            </div>
        </div>
    )
}
