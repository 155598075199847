import React, { useEffect, useState } from "react";
import { toCanvas, toPng, toSvg } from "html-to-image";
import {
  Cards,
  CheckCircle,
  DiamondsFour,
  Eye,
  Faders,
  VideoCamera,
} from "phosphor-react";
import Reviews from "./components/reviews";
import Templates from "./components/templates";
import { db, storage } from "../../firebase";
import { getDownloadURL, listAll, ref, uploadString } from "firebase/storage";
import AWN from "awesome-notifications";
import axios from "axios";
import { useHistory } from "react-router";
import { strings } from "../../common/Strings";
import Designs from "./components/designs";
import { doc, setDoc } from "firebase/firestore";
import { useStateValue } from "../../context/StateProvider";
import { nanoid } from "nanoid";
import Customize from "./components/customize";

export default function Studio() {
  const navigate = useHistory();
  const [tab, settab] = useState(2);
  const [templates, settemplates] = useState([]);
  const [custom_templates, setcustom_templates] = useState([]);
  const [fetched, setfetched] = useState(false);
  const [design, setdesign] = useState(1);
  const [review, setreview] = useState({});
  const [template, settemplate] = useState("");
  const [exported_image, setexported_image] = useState("");
  const [sentforrender, setsentforrender] = useState(false);
  const [{ user, reviews, brand }, dispatch] = useStateValue();
  const [type, settype] = useState("");
  const [customTempId, setcustomTempId] = useState(0);
  const [customText, setcustomText] = useState({
    heading: "",
    subheading: "",
  });
  const [fontSize, setfontSize] = useState({
    headText: 92,
    subheadText: 42,
  });
  const [exporting, setexporting] = useState(false);
  const [renderid, setrenderid] = useState(nanoid(20));
  const [logodisplay, setlogodisplay] = useState(false);

  useEffect(() => {
    if (!user) {
      return navigate.replace("/login");
    }
    fetchuploads();
    fetchcustomtemplates();
  }, []);

  async function fetchuploads() {
    if (fetched === false) {
      const listRef = ref(storage, "/studio/templates");
      listAll(listRef)
        .then((res) => {
          var a = [];
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef).then((url) => {
              a.push(url);
            });
          });
          settemplates(a);
          setfetched(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function fetchcustomtemplates() {
    if (fetched === false) {
      const listRef = ref(storage, "/studio/custom templates");
      listAll(listRef)
        .then((res) => {
          var a = [];
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef).then((url) => {
              a.push(url);
            });
          });
          setcustom_templates(a);
          setfetched(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function exportToPng() {
    if (type === "") {
      if (JSON.stringify(review) === "{}") {
        return new AWN().warning("Please select a review");
      }
      if (template === "") {
        return new AWN().warning("Please select a template");
      }
      const node = document.getElementById("details");
      await toPng(node).then((res) => {
        setexporting(true);
        uploadfile(res);
      });
    }
    if (type === "custom") {
      const videoelement = document.getElementById("iphonevideo");
      const node1 = document.getElementById("layer1");
      const node2 = document.getElementById("layer2");
      const node3 = document.getElementById("layer3");
      const node4 = document.getElementById("layer4");
      videoelement.remove();
      const backgrounds = [
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fone%2Fbackground.png?alt=media&token=35c8ad84-fb32-4979-baee-50d36b1d96c9",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Ftwo%2Fbackground.png?alt=media&token=885b9a6a-0ff1-4e9f-9650-01a0993ddd7b",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fthree%2Fbackground.png?alt=media&token=a2cc512f-53d5-437d-8c90-bba92bd750bc",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Ffour%2Fbackground.png?alt=media&token=e54680d0-e792-4218-b416-c61b9b2d76d0",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Ffive%2Fbackground.png?alt=media&token=40167f97-0872-42d5-92b8-64e5a3e6b682",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fsix%2Fbackground6.png?alt=media&token=5c6b352b-dbdd-4ea1-b65f-2772936aa392",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fseven%2Fbackground7.png?alt=media&token=4a9b2611-92c0-43de-a8ae-9f86dcb5327b",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Feight%2Fbackground8.png?alt=media&token=2dcc04c3-55fb-415e-94c3-1782fbe760c2",
      ];
      var xcordinates = [533, 78, 625, 532, 90, 90, 90, 90];
      var ycordinates = [278, 278, 242, 275, 190.19, 190, 190];

      var renderdata = {
        layer1: backgrounds[customTempId - 1],
        layer2: "",
        layer3: "",
        layer4:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FcustomTemplates%2Fblank.png?alt=media&token=238e5429-c9d7-4442-8740-dd4ed6ecf00a",
        videoUrl: review.reviewUrl,
        cordinates: {
          x: xcordinates[customTempId - 1],
          y: ycordinates[customTempId - 1],
        },
      };

      setexporting(true);
      // await toCanvas(node1).then(res => {
      //   downlaodcanvas(res, 'image/png', 'background.png');
      //   console.log(res)
      // }).catch(err => console.log(err));
      await toCanvas(node2)
        .then(async (res) => {
          var newCanvas = document.createElement("canvas");
          newCanvas.width = 1080;
          newCanvas.height = 1080;
          var ctx = newCanvas.getContext("2d");
          ctx.drawImage(res, 0, 0, 1350, 1350, 0, 0, 1080, 1080);

          var pngData = newCanvas.toDataURL("image/png");

          const storageRef = ref(
            storage,
            `studio/custom_temp_exported/${renderid}/iphone.png`
          );
          await uploadString(storageRef, pngData, "data_url").then(
            (snapshot) => {
              getDownloadURL(storageRef).then((downloadURL) => {
                renderdata.layer2 = downloadURL;
              });
            }
          );
        })
        .catch((err) => console.log(err));
      await toCanvas(node3)
        .then(async (res) => {
          var newCanvas = document.createElement("canvas");
          newCanvas.width = 1080;
          newCanvas.height = 1080;
          var ctx = newCanvas.getContext("2d");
          ctx.drawImage(res, 0, 0, 1350, 1350, 0, 0, 1080, 1080);

          var pngData = newCanvas.toDataURL("image/png");
          const storageRef = ref(
            storage,
            `studio/custom_temp_exported/${renderid}/text.png`
          );
          await uploadString(storageRef, pngData, "data_url").then(
            (snapshot) => {
              getDownloadURL(storageRef).then((downloadURL) => {
                renderdata.layer3 = downloadURL;
                rederCustom(renderdata);
              });
            }
          );
          setsentforrender(true);
        })
        .catch((err) => console.log(err));
    }
  }

  async function uploadfile(string) {
    const storageRef = ref(
      storage,
      "studio/exported/" + Date.now() + Math.floor(Math.random() * 100) + ".jpg"
    );

    uploadString(storageRef, string, "data_url").then((snapshot) => {
      getDownloadURL(storageRef).then((downloadURL) => {
        setexported_image(downloadURL);
        renderthis(downloadURL);
      });
    });
  }

  async function renderthis(url) {
    const data = {
      url: review.reviewUrl,
      productImage: url,
      template: template,
    };

    let notifier = new AWN();
    setsentforrender(true);
    notifier.async(
      axios.post(strings.apiLink + "studio/edit", data, {
        headers: { "Content-Type": "application/json" },
      }),
      async (response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.data.success) {
            new AWN().success("review processed and it's exporting now.");
            setsentforrender(false);
            var newdoc = {
              videoUrl: response.data.url,
              user: user?.email || null,
              brand: brand?.id || null,
              export_Date: Date.now(),
              review: {
                name: review?.name,
                productName: review?.parentName,
                uploadedBy: review?.uploadedBy,
              },
            };
            try {
              await setDoc(doc(db, "exports", renderid), newdoc);
              window.open(response.data.url);
              navigate.push("/admin");
            } catch (err) {
              new AWN().warning(err.message);
              window.location.reload();
              return;
            }
          } else {
            new AWN().warning(response.data.message);
            window.location.reload();
            return;
          }
        } else {
          new AWN().warning(response.message);
          window.location.reload();
          return;
        }
      }
    );
  }

  async function rederCustom(data) {
    console.log(data);
    let notifier = new AWN();
    notifier.async(
      axios.post(strings.apiLink + "template/create", data, {
        headers: { "Content-Type": "application/json" },
      }),
      async (response) => {
        if (response.status === 200) {
          if (response.data.success) {
            new AWN().success("review processed and it's exporting now.");
            setsentforrender(false);
            var newdoc = {
              videoUrl: response.data.url,
              user: user?.email || null,
              brand: brand?.id || null,
              export_Date: Date.now(),
              review: {
                name: review?.name,
                productName: review?.parentName,
                uploadedBy: review?.uploadedBy,
              },
            };

            try {
              await setDoc(doc(db, "exports", renderid), newdoc);
              window.open(response.data.url);
              navigate.push("/admin");
            } catch (err) {
              new AWN().warning(err.message);
              window.location.reload();
              return;
            }

            return;
          } else {
            new AWN().warning(response.data.error);
            window.location.reload();
            return;
          }
        } else {
          new AWN().warning(response.message);
          window.location.reload();
          return;
        }
      }
    );
  }

  async function changeheadtext(value) {
    const textlayer = document.getElementById("headtext");
    textlayer.style.fontSize = value + "px";
    textlayer.style.lineHeight = parseInt(value) + 10 + "px";
  }

  async function changesubheadtext(value) {
    const textlayer = document.getElementById("subheadtext");
    textlayer.style.fontSize = value + "px";
    textlayer.style.lineHeight = parseInt(value) + 10 + "px";
  }
  async function changelogo(property, y) {
    const layer = document.getElementById("logo");
    const image = document.getElementById("logoimage");
    if (property === "left") {
      layer.style.left = y + "px";
      return;
    }
    if (property === "top") {
      layer.style.top = y + "px";
      return;
    }

    if (property === "width") {
      layer.style.width = y + "px";
      return;
    }
    if (property === "height") {
      layer.style.height = y + "px";
      return;
    }
    if (property === "angle") {
      layer.style.transform = `rotate(${y}deg)`;
      return;
    }
    if (property === "round") {
      image.style.borderRadius = y + "px";
      return;
    }
    if (property === "opacity") {
      image.style.opacity = y + "%";
      return;
    }
  }
  async function changeproductmargin(property, y) {
    const layer = document.getElementById("product");

    if (property === "left") {
      layer.style.marginLeft = y + "px";
      return;
    }
    if (property === "top") {
      layer.style.marginTop = y + "px";
      return;
    }
  }

  return (
    <div className="h-screen w-full bg-[#131418] overflow-y-auto flex">
      {sentforrender && (
        <div className="absolute h-full w-full flex items-center justify-center z-[150] bg-[#00000069]">
          <div className="w-[500px] aspect-video bg-[#20222b] flex items-center justify-center p-5 shadow-lg">
            <div className="flex flex-col items-center justify-center gap-5">
              <CheckCircle size={52} color="#28da1b" />
              <p className="text-sm font-light tracking-wide text-center text-white text-opacity-80">
                Video is being processed. Once it gets completed it will
                automatically get downloaded.
                <br></br>
                Don't close this tab or window unless its downloaded
              </p>
              {/* <button
                className="bg-[#0d0d0d] hover:bg-[#0d0d0d90] px-8 py-2 rounded-md appearance-none outline-none"
                onClick={() => navigate.replace("/admin")}
              >
                Go back to dashboard
              </button> */}
            </div>
          </div>
        </div>
      )}

      <div className="fixed right-5 top-5">
        <button
          className="bg-[#0d0d0d] hover:bg-[#0d0d0d90] px-8 py-2 rounded-md appearance-none outline-none flex items-center justify-center"
          onClick={() =>
            exporting === true ? console.log("exporting") : exportToPng()
          }
        >
          {exporting === true ? <div id="loading"></div> : "Export"}
        </button>
      </div>
      <div className="w-20 bg-[#0d0d0d] flex flex-col py-10">
        <div
          onClick={() => settab(1)}
          className="flex flex-col items-center hover:bg-[#00000040] w-full aspect-square justify-center cursor-pointer"
        >
          <div className="">
            <Cards size={32} color="#ffffffbd" />
          </div>
          <div className="">
            <p className="text-[10px] text-[#ffffffbd] tracking-widest pt-1">
              Design
            </p>
          </div>
        </div>
        <div
          onClick={() => settab(2)}
          className="flex flex-col items-center hover:bg-[#00000040] w-full aspect-square justify-center cursor-pointer"
        >
          <div className="">
            <VideoCamera size={32} color="#ffffffbd" />
          </div>
          <div className="">
            <p className="text-[10px] text-[#ffffffbd] tracking-widest pt-1">
              Reviews
            </p>
          </div>
        </div>
        <div
          onClick={() => settab(3)}
          className="flex flex-col items-center hover:bg-[#00000040] w-full aspect-square justify-center cursor-pointer"
        >
          <div className="">
            <DiamondsFour size={32} color="#ffffffbd" />
          </div>
          <div className="">
            <p className="text-[10px] text-[#ffffffbd] tracking-widest pt-1">
              Templates
            </p>
          </div>
        </div>
        {type === "custom" && (
          <div
            onClick={() => settab(4)}
            className="flex flex-col items-center hover:bg-[#00000040] w-full aspect-square justify-center cursor-pointer"
          >
            <div className="">
              <Faders size={32} color="#ffffffbd" />
            </div>
            <div className="">
              <p className="text-[10px] text-[#ffffffbd] tracking-widest pt-1">
                Customize
              </p>
            </div>
          </div>
        )}
      </div>
      <div
        className="w-80 bg-[#101011] p-4 overflow-y-auto "
        id="thin-scrollbar"
      >
        {tab === 1 && <Designs setdesign={setdesign} />}
        {tab === 2 && <Reviews reviewss={reviews} setreview={setreview} />}
        {tab === 3 && (
          <Templates
            templates={templates}
            setlogodisplay={setlogodisplay}
            settemplate={settemplate}
            custom_templates={custom_templates}
            settype={settype}
            setcustomTempId={setcustomTempId}
          />
        )}
        {tab === 4 && (
          <div>
            <div className="relative w-full h-full">
              <p className="pb-2 font-light tracking-wide text-white text-opacity-60">
                Customization{" "}
                <span className="text-xs">(*canvas size 1080x1080)</span>
              </p>
              <div className="border-t border-[#80808040] mt-2 space-y-5">
                <div className="">
                  <p className="pb-2 mt-2 font-light tracking-wide text-white text-opacity-60">
                    Heading Text <span className="text-xs">(*font-size)</span>
                  </p>
                  <input
                    type="number"
                    defaultValue={92}
                    onChange={(event) => changeheadtext(event.target.value)}
                    className=" px-2 bg-transparent border border-[#80808040] h-9 outline-none w-16 text-white"
                  />
                </div>
                <div className="">
                  <p className="pb-2 mt-2 font-light tracking-wide text-white text-opacity-60">
                    Sub-heading Text{" "}
                    <span className="text-xs">(*font-size)</span>
                  </p>
                  <input
                    type="number"
                    defaultValue={customTempId === 2 ? 26 : 42}
                    onChange={(event) => changesubheadtext(event.target.value)}
                    className=" px-2 bg-transparent border border-[#80808040] h-9 outline-none w-16 text-white"
                  />
                </div>

                <div className="">
                  <p className="pb-2 mt-2 font-light tracking-wide text-white text-opacity-60">
                    Logo
                  </p>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <p className="text-[#808080]">X</p>
                      </div>
                      <input
                        type="number"
                        min={0}
                        max={1080}
                        onChange={(event) =>
                          changelogo("left", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <p className="text-[#808080]">Y</p>
                      </div>
                      <input
                        type="number"
                        min={0}
                        max={1080}
                        onChange={(event) =>
                          changelogo("top", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <p className="text-[#808080]">W</p>
                      </div>
                      <input
                        type="number"
                        min={0}
                        max={1080}
                        onChange={(event) =>
                          changelogo("width", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <p className="text-[#808080]">H</p>
                      </div>
                      <input
                        type="number"
                        min={0}
                        max={1080}
                        onChange={(event) =>
                          changelogo("height", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <svg
                          className="svg"
                          width="12"
                          height="12"
                          viewBox="0 0 8 8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 0v8h8V7H5c0-2.21-1.79-4-4-4V0H0zm1 4v3h3c0-1.657-1.343-3-3-3z"
                            fillRule="evenodd"
                            fillOpacity="1"
                            fill="#808080"
                            stroke="none"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="number"
                        min={0}
                        max={360}
                        onChange={(event) =>
                          changelogo("angle", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <svg
                          className="svg"
                          width="12"
                          height="12"
                          viewBox="0 0 9 9"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 1H5C2.79 1 1 2.79 1 5v4H0V5c0-2.761 2.239-5 5-5h4v1z"
                            fillRule="nonzero"
                            fillOpacity="1"
                            fill="#808080"
                            stroke="none"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="number"
                        min={0}
                        onChange={(event) =>
                          changelogo("round", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <Eye size={20} color="#808080" />
                      </div>
                      <input
                        type="number"
                        defaultValue={100}
                        min={0}
                        max={100}
                        onChange={(event) =>
                          changelogo("opacity", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="pb-2 mt-2 font-light tracking-wide text-white text-opacity-60">
                    Product <span className="text-xs">(*margin)</span>
                  </p>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <p className="text-[#808080]">X</p>
                      </div>
                      <input
                        type="number"
                        onChange={(event) =>
                          changeproductmargin("left", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                    <div className="relative w-full h-9">
                      <div className="absolute flex items-center h-full px-3">
                        <p className="text-[#808080]">Y</p>
                      </div>
                      <input
                        type="number"
                        onChange={(event) =>
                          changeproductmargin("top", event.target.value)
                        }
                        className="w-full pl-10 pr-3 bg-transparent border border-[#80808040] h-9 outline-none text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {type === "custom" && (
        <div className="flex gap-y-5 fixed left-[40%] gap-5 bottom-5">
          <div className="flex">
            <input
              type="text"
              className="h-10 w-80 rounded-md bg-transparent border border-[#80808050] px-3"
              onChange={(event) =>
                setcustomText({ ...customText, heading: event.target.value })
              }
              placeholder="Heading"
            />
            <input
              type="text"
              className="h-10 w-80 rounded-md bg-transparent border border-[#80808050] px-3"
              onChange={(event) =>
                setcustomText({ ...customText, subheading: event.target.value })
              }
              placeholder="Subheading"
            />
          </div>
        </div>
      )}
      {type !== "custom" && (
        <div className="flex items-center justify-center flex-1 w-full h-full">
          {design === 1 || design === 2 ? (
            <div
              className={`w-80 p-5 aspect-[9/16] flex items-center justify-center bg-[#0c0c0c]  2xl:transform min-[1720px]:scale-125 relative`}
            >
              {template !== "" && (
                <img src={template} alt="" className="absolute" />
              )}
              <div className="z-50 flex flex-col items-center w-full">
                <video
                  playsInline
                  src={review?.reviewUrl}
                  autoPlay={true}
                  onClick={(e) =>
                    e.currentTarget.paused
                      ? e.currentTarget.play()
                      : e.currentTarget.pause()
                  }
                  alt=""
                  className={`w-[95%] aspect-[9/16] rounded-md mb-3 ${
                    JSON.stringify(review) === "{}" &&
                    "border border-white border-opacity-20"
                  } `}
                />
                {JSON.stringify(review) !== "{}" ? (
                  <div
                    id="details"
                    style={{ width: 280, height: 40, display: "flex" }}
                  >
                    <div>
                      <img
                        src={review?.product?.imageUrl}
                        alt=""
                        style={{ height: 40, width: 40, borderRadius: "100%" }}
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: 15 }}>
                      <p style={{ fontSize: 12, paddingBottom: 4 }}>
                        {review?.product?.name}
                      </p>
                      <div style={{ display: "flex", columnGap: 4 }}>
                        {review?.rating >= 0 && review?.rating < 1 ? (
                          <div className="flex gap-x-1">
                            {review?.rating > 0.49 && review?.rating < 1 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                            )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                          </div>
                        ) : null}
                        {review?.rating >= 1 && review?.rating < 2 ? (
                          <div className="flex gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            {review?.rating > 1.49 && review?.rating < 2 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                            )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                          </div>
                        ) : null}
                        {review?.rating >= 2 && review?.rating < 3 ? (
                          <div className="flex gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            {review?.rating > 2.49 && review?.rating < 3 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                            )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                          </div>
                        ) : null}
                        {review?.rating >= 3 && review?.rating < 4 ? (
                          <div className="flex gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            {review?.rating > 3.49 && review?.rating < 4 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                            )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path
                                d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></path>
                            </svg>
                          </div>
                        ) : null}
                        {review?.rating >= 4 && review?.rating < 5 ? (
                          <div className="flex gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            {review?.rating > 4.49 && review?.rating < 5 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                            )}
                          </div>
                        ) : null}
                        {review?.rating === 5 && review?.rating > 5 ? (
                          <div className="flex gap-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                            </svg>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    id="details"
                    style={{ width: 280, height: 40, display: "flex" }}
                  >
                    <div
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: "100%",
                        borderWidth: 1,
                        borderColor: "#3D3D3D",
                      }}
                    />
                    <div style={{ flex: 1, marginLeft: 15 }}>
                      <p
                        style={{
                          fontSize: 12,
                          paddingBottom: 4,
                          color: "#3d3d3d",
                        }}
                      >
                        {"Product Name"}
                      </p>
                      <div className="flex gap-1 text-[#3D3D3D]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <path
                            d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <path
                            d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <path
                            d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <path
                            d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 256 256"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <path
                            d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center ">
              <p className="text-3xl">Coming Soon</p>
            </div>
          )}
        </div>
      )}
      {type === "custom" && (
        <div className="flex items-center justify-between flex-1 w-full h-full">
          <div className="transform scale-50">
            <div className="h-[1080px] w-[1080px] bg-[#0c0c0c] relative overflow-hidden">
              <>
                {customTempId === 1 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fone%2Fbackground.png?alt=media&token=35c8ad84-fb32-4979-baee-50d36b1d96c9"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 2 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Ftwo%2Fbackground.png?alt=media&token=885b9a6a-0ff1-4e9f-9650-01a0993ddd7b"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 3 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fthree%2Fbackground.png?alt=media&token=a2cc512f-53d5-437d-8c90-bba92bd750bc"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 4 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Ffour%2Fbackground.png?alt=media&token=e54680d0-e792-4218-b416-c61b9b2d76d0"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 5 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Ffive%2Fbackground.png?alt=media&token=40167f97-0872-42d5-92b8-64e5a3e6b682"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 6 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fsix%2Fbackground6.png?alt=media&token=5c6b352b-dbdd-4ea1-b65f-2772936aa392"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 7 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Fseven%2Fbackground7.png?alt=media&token=4a9b2611-92c0-43de-a8ae-9f86dcb5327b"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
                {customTempId === 8 && (
                  <div className="absolute z-10 w-full h-full" id="layer1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom_design_templates%2Feight%2Fbackground8.png?alt=media&token=2dcc04c3-55fb-415e-94c3-1782fbe760c2"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                )}
              </>

              <>
                <div
                  className={
                    (customTempId === 1 &&
                      "absolute z-50 h-full w-full pl-[459px] pt-[251px]") ||
                    (customTempId === 2 &&
                      "absolute z-50 h-full w-full pl-[3px] pt-[254px]") ||
                    (customTempId === 3 &&
                      "absolute z-50 h-full w-full pl-[549px] pt-[221px]") ||
                    (customTempId === 4 &&
                      "absolute z-50 h-full w-full pl-[459px] pt-[251px]") ||
                    (customTempId === 5 &&
                      "absolute z-50 h-full w-full pl-[17px] pt-[174px]") ||
                    (customTempId === 6 &&
                      "absolute z-50 h-full w-full pl-[17px] pt-[174px]") ||
                    (customTempId === 7 &&
                      "absolute z-50 h-full w-full pl-[17px] pt-[174px]") ||
                    (customTempId === 8 &&
                      "absolute z-50 h-full w-full pl-[17px] pt-[174px]")
                  }
                  id="layer2"
                >
                  <div className="h-[830px] w-[504px] absolute">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ftest%20custom%20template%2Fiphone.png?alt=media&token=db327333-fbc2-447f-b994-8c54e3b8296d"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                  {logodisplay === true && (
                    <div
                      id="logo"
                      className={
                        (customTempId === 1 &&
                          "h-24 w-24 absolute left-10 top-10") ||
                        (customTempId === 2 &&
                          "h-24 w-24 absolute left-16 top-10") ||
                        (customTempId === 3 &&
                          "h-24 w-24 absolute left-10 top-10") ||
                        (customTempId === 4 &&
                          "h-24 w-24 absolute left-10 top-10") ||
                        (customTempId === 5 &&
                          "h-24 w-24 absolute right-10 top-10") ||
                        (customTempId === 6 &&
                          "h-24 w-24 absolute right-10 top-10") ||
                        (customTempId === 7 &&
                          "h-24 w-24 absolute right-10 top-10") ||
                        (customTempId === 8 &&
                          "h-24 w-24 absolute right-10 top-10")
                      }
                    >
                      <img
                        src={brand?.imageUrl}
                        alt=""
                        className="w-full h-full"
                        id="logoimage"
                      />
                    </div>
                  )}
                  <div
                    className="h-[789px] aspect-[9/20] ml-[70px] mt-1"
                    id="videolayer"
                  >
                    <video
                      playsInline
                      src={review?.reviewUrl}
                      alt=""
                      className="object-cover w-full h-full rounded-xl"
                      id="iphonevideo"
                    />
                  </div>
                </div>
              </>
              <>
                <div
                  className={
                    (customTempId === 1 &&
                      "absolute z-20 h-full w-full pt-[223px] pl-[43px]") ||
                    (customTempId === 2 &&
                      "absolute z-20 h-full w-full pt-[587px] pl-[516px]") ||
                    (customTempId === 3 &&
                      "absolute z-20 h-full w-full pt-[183px] pl-[45px]") ||
                    (customTempId === 4 &&
                      "absolute z-20 h-full w-full pt-[230px] pl-[40px]") ||
                    (customTempId === 5 &&
                      "absolute z-20 h-full w-full pt-[176px] pl-[535px]") ||
                    (customTempId === 6 &&
                      "absolute z-20 h-full w-full pt-[176px] pl-[535px]") ||
                    (customTempId === 7 &&
                      "absolute z-20 h-full w-full pt-[176px] pl-[535px]") ||
                    (customTempId === 8 &&
                      "absolute z-20 h-full w-full pt-[176px] pl-[535px]")
                  }
                  id="layer3"
                >
                  <>
                    <p
                      className=" font-medium w-[460px] leading-[100px] cursor-pointer draggable"
                      id="headtext"
                      style={{ fontSize: 92 }}
                    >
                      {customText?.heading
                        ? customText?.heading
                        : "Heading Text"}
                    </p>
                  </>
                  <>
                    <p
                      className={`
                     text-[42px] font-medium w-[457px] mt-5 leading-[50px] cursor-pointer draggable

                     ${
                       customTempId === 2 &&
                       "w-[330px] text-[26px] left-[612px] top-[250px] absolute"
                     }
                      `}
                      id="subheadtext"
                    >
                      {customText?.subheading
                        ? customText?.subheading
                        : "Subheading Text"}
                    </p>
                  </>
                  <>
                    <div
                      className={`
                    ${customTempId === 3 && "mt-20"}
                    `}
                      id="product"
                    >
                      {JSON.stringify(review) !== "{}" ? (
                        <div className="flex w-full w-max-[330px] gap-8 mt-10">
                          <div className="h-[90px] w-[90px] rounded-full">
                            <img
                              src={review?.product?.imageUrl}
                              alt=""
                              className="w-full h-full rounded-full"
                            />
                          </div>
                          <div className="flex-1 w-full">
                            <p className="text-3xl text-white">
                              {review?.product?.name}
                            </p>
                            <div className="flex gap-1 mt-3 text-white">
                              {review?.rating >= 0 && review?.rating < 1 ? (
                                <div className="flex gap-x-1">
                                  {review?.rating > 0.49 &&
                                  review?.rating < 1 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path
                                        d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="16"
                                      ></path>
                                    </svg>
                                  )}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                </div>
                              ) : null}
                              {review?.rating >= 1 && review?.rating < 2 ? (
                                <div className="flex gap-x-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  {review?.rating > 1.49 &&
                                  review?.rating < 2 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path
                                        d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="16"
                                      ></path>
                                    </svg>
                                  )}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                </div>
                              ) : null}
                              {review?.rating >= 2 && review?.rating < 3 ? (
                                <div className="flex gap-x-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  {review?.rating > 2.49 &&
                                  review?.rating < 3 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path
                                        d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="16"
                                      ></path>
                                    </svg>
                                  )}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                </div>
                              ) : null}
                              {review?.rating >= 3 && review?.rating < 4 ? (
                                <div className="flex gap-x-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  {review?.rating > 3.49 &&
                                  review?.rating < 4 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path
                                        d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="16"
                                      ></path>
                                    </svg>
                                  )}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path
                                      d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="16"
                                    ></path>
                                  </svg>
                                </div>
                              ) : null}
                              {review?.rating >= 4 && review?.rating < 5 ? (
                                <div className="flex gap-x-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  {review?.rating > 4.49 &&
                                  review?.rating < 5 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59-3.8a.5.5,0,0,1-.4-.3l-22-55.5a16.3,16.3,0,0,0-30.4,0l-22,55.5a.5.5,0,0,1-.4.3L31.4,86A16.4,16.4,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.2,37.6a.8.8,0,0,1,.2.8L53.9,207a18.6,18.6,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.8,16.8,0,0,0,9.5-3.1,16.6,16.6,0,0,0,6.3-17.7l-14.5-57.3a.8.8,0,0,1,.2-.8L234,115.5A16.8,16.8,0,0,0,239.2,97.4Zm-15.4,5.8-45.3,37.6a16.8,16.8,0,0,0-5.4,17l14.5,57.3c.1.4.1.5,0,.6a.7.7,0,0,1-.3.3h-.2L136.7,184a16,16,0,0,0-8.7-2.5V32c.1,0,.2,0,.3.3l22.1,55.5a16.4,16.4,0,0,0,14.1,10.4l59.1,3.8c.1,0,.2,0,.3.4S223.9,103.1,223.8,103.2Z"></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <path
                                        d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="16"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                              ) : null}
                              {review?.rating === 5 && review?.rating > 5 ? (
                                <div className="flex gap-x-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    viewBox="0 0 256 256"
                                  >
                                    <rect
                                      width="256"
                                      height="256"
                                      fill="none"
                                    ></rect>
                                    <path d="M239.2,97.4A16.4,16.4,0,0,0,224.6,86l-59.4-4.1-22-55.5A16.4,16.4,0,0,0,128,16h0a16.4,16.4,0,0,0-15.2,10.4L90.4,82.2,31.4,86A16.5,16.5,0,0,0,16.8,97.4,16.8,16.8,0,0,0,22,115.5l45.4,38.4L53.9,207a18.5,18.5,0,0,0,7,19.6,18,18,0,0,0,20.1.6l46.9-29.7h.2l50.5,31.9a16.1,16.1,0,0,0,8.7,2.6,16.5,16.5,0,0,0,15.8-20.8l-14.3-58.1L234,115.5A16.8,16.8,0,0,0,239.2,97.4Z"></path>
                                  </svg>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex w-full w-max-[380px] gap-8 mt-10">
                          <div className="h-[90px] w-[90px] border-[2px] border-[#3d3d3d] rounded-full"></div>
                          <div className="flex-1 w-full">
                            <p className="text-[#3d3d3d] text-3xl">
                              Product Name
                            </p>
                            <div className="flex gap-1 text-[#3D3D3D] scale-110 pl-3 mt-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="30"
                                width="30"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="30"
                                width="30"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="30"
                                width="30"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="30"
                                width="30"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="30"
                                width="30"
                                fill="currentColor"
                                viewBox="0 0 256 256"
                              >
                                <rect
                                  width="256"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <path
                                  d="M132.4,190.7l50.4,32c6.5,4.1,14.5-2,12.6-9.5l-14.6-57.4a8.7,8.7,0,0,1,2.9-8.8l45.2-37.7c5.9-4.9,2.9-14.8-4.8-15.3l-59-3.8a8.3,8.3,0,0,1-7.3-5.4l-22-55.4a8.3,8.3,0,0,0-15.6,0l-22,55.4a8.3,8.3,0,0,1-7.3,5.4L31.9,94c-7.7.5-10.7,10.4-4.8,15.3L72.3,147a8.7,8.7,0,0,1,2.9,8.8L61.7,209c-2.3,9,7.3,16.3,15,11.4l46.9-29.7A8.2,8.2,0,0,1,132.4,190.7Z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </>
              <>
                <div
                  className={
                    "absolute z-40 h-full w-full pt-[920px] pl-[131px]"
                  }
                  id="layer4"
                ></div>
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
