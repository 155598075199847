import React from 'react'

export default function InstagramFeed({ caption, review, user }) {
    return (
        <div className="flex flex-col bg-white border border-[#E4E6EB]">
            {user?.social?.instagram && (
                <div className="flex items-center px-[10px] h-10">
                    <div className="flex ">
                        <img
                            className="h-[26px] rounded-full cursor-pointer aspect-square"
                            src={user?.social?.instagram?.profile_pic}
                            alt=""
                        />
                    </div>
                    <div className="pl-2">
                        <p className="font-medium text-[#1c1e21] text-[11px]">
                            {user?.social?.instagram?.name}
                        </p>
                    </div>
                </div>
            )}
            <div className={`w-full aspect-square`}>
                {review?.reviewUrl ? (
                    <video playsInline
                        src={review?.reviewUrl}
                        className="object-cover w-full aspect-square"
                        disablePictureInPicture
                    ></video>
                ) : (
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffacebookpreview.png?alt=media&token=ca5406fc-100f-4835-834a-318efffc4e94"
                        alt="preview"
                        className="object-cover w-full aspect-square"
                    />
                )}
            </div>
            <div className="flex items-center justify-between py-2">
                <div className="flex items-center gap-2  pb-1 px-[10px] ">
                    <i
                        className="xxk0z11 xvy4d1p xvijh9v xmo9yow xat24cr xgzva0m xexx8yu x4uap5 x18d9i69 xkhd6sd img"
                        alt=""
                        data-visualcompletion="css-img"
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/WPFS_OkZLtj.png")',
                            backgroundPosition: "0px -91px",
                            backgroundSize: "auto",
                            width: 24,
                            height: 24,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                    <i
                        className="xxk0z11 xvy4d1p xvijh9v xmo9yow xat24cr xgzva0m xexx8yu x4uap5 x18d9i69 xkhd6sd img"
                        alt=""
                        data-visualcompletion="css-img"
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/WPFS_OkZLtj.png")',
                            backgroundPosition: "0px -66px",
                            backgroundSize: "auto",
                            width: 24,
                            height: 24,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                    <i
                        className="xxk0z11 xvy4d1p xvijh9v xmo9yow xat24cr xgzva0m xexx8yu x4uap5 x18d9i69 xkhd6sd img"
                        alt=""
                        data-visualcompletion="css-img"
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/tInpRYyDCy3.png")',
                            backgroundPosition: "-188px -1608px",
                            backgroundSize: "auto",
                            width: 24,
                            height: 24,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                </div>
                <div className="flex items-center gap-2  pb-1 px-[10px] ">
                    <i
                        className="xxk0z11 xvy4d1p xvijh9v xmo9yow xat24cr xgzva0m xexx8yu x4uap5 x18d9i69 xkhd6sd img"
                        alt=""
                        data-visualcompletion="css-img"
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/tInpRYyDCy3.png")',
                            backgroundPosition: "0px -1669px",
                            backgroundSize: "auto",
                            width: 24,
                            height: 24,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                </div>
            </div>
            <div className="px-[10px]">
                {caption && <p className="text-[11px] text-[#282828]"> <span className=''>{user?.social?.instagram?.name}</span><span className="px-2">·</span><span className="">{caption}</span></p>}
            </div>
        </div>
    )
}
