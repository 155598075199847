import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";

function InstagramProfile({ pageid, token }) {
  //7260279174035999?fields=profile_pic,username,media_count,follow_count,followed_by_count
  const [insight, setInsight] = useState(null);
  useEffect(() => {
    axios
      .get(
        `https://graph.facebook.com/v19.0/${pageid}?fields=profile_pic,username,media_count,follow_count,followed_by_count&access_token=${token}`
      )
      .then((response) => {
        setInsight(response.data);
      });
  }, []);
  if (!insight)
    return (
      <div className="">
        <p className="">Loading...</p>
      </div>
    );
  else
    return (
      <div className="flex items-center justify-center gap-10 p-5 rounded-md bg-background">
        <div className="">
          <img src={insight.profile_pic} className="rounded-full h-28" alt="" />
        </div>
        <div className="flex-1 ">
          <div className="">
            <p className="font-light tracking-wide">{insight.username}</p>
            {/* <p className="font-light tracking-wide">{insight?.biography}</p> */}
          </div>
        </div>
        <div className="flex items-center justify-end flex-1 w-full gap-5">
          <div className="flex flex-col items-center p-2 px-4 rounded-md bg-bg_highlight border border-[#8080801e] min-w-[140px]">
            <p className="">{insight.media_count ?? 0}</p>
            <p className="">Posts</p>
          </div>
          <div className="flex flex-col items-center p-2 px-4 rounded-md bg-bg_highlight border border-[#8080801e] min-w-[140px]">
            <p className="">{insight.follow_count ?? 0}</p>
            <p className="">Following</p>
          </div>
          <div className="flex flex-col items-center p-2 px-4 rounded-md bg-bg_highlight border border-[#8080801e] min-w-[140px]">
            <p className="">{insight.followed_by_count ?? 0}</p>
            <p className="">Followers</p>
          </div>
        </div>
      </div>
    );
}

export default InstagramProfile;
