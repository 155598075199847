import React, { useEffect, useMemo, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import DataCard from "./datacard";

function InstagramVisitLikes({ pageid }) {
  const [{ user }, dispatch] = useStateValue();
  const [views, setViews] = useState([]);
  const [video_views, setVideoViews] = useState([]);

  useEffect(() => {
    const token = user.social.facebook.access_token;
    const instabusiness = user.social.instagram.business_account;
    var timestamp = Math.floor(Date.now() / 1000) - 28 * 24 * 60 * 60;
    console.log(timestamp);
    axios
      .get(
        `https://graph.facebook.com/v19.0/${instabusiness}/insights?metric=impressions,reach,follower_count&period=day&since=${timestamp}&access_token=${token}`
      )
      .then((response) => {
        setViews(response.data.data);
      });
  }, []);

  function getAnaltyic(metric) {
    const sum = metric.values.reduce((sum, curr) => sum + curr.value, 0);
    const avg = sum / metric.values.length;
    var growth = avg - metric.values[0].value;
    growth /= metric.values[0].value;
    growth *= 100;
    growth = Math.floor(growth);

    return { name: metric.name, growth: growth, sum: sum, title: metric.title };
  }

  const viewData = useMemo(() => {
    return {
      analytic: views.map((metric) => getAnaltyic(metric)),
      options: {
        markers: {
          show: false, // Hide the data point markers
        },
        legend: {
          show: true, // Hide the legend
        },
        dataLabels: {
          enabled: false, // Disable the data labels
        },
        grid: {
          show: false,
        },
        chart: {
          id: "area-datetime",
          toolbar: {
            show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
          },
        },
        xaxis: {
          type: "datetime",
        },
      },
      series: views.map((d) => ({
        name: d.title,
        data: d.values.map((f) => [new Date(f.end_time).getTime(), f.value]),
      })),
    };
  }, [views]);

  if (!views)
    return (
      <div className="">
        <p className="">Loading...</p>
      </div>
    );
  else
    return (
      <div className="px-5 py-3 rounded-md bg-bg_highlight">
        <div className="">
          <div className="">
            <div className="">Performance</div>
          </div>
          <div className="flex">
            <div className="flex-1">
              <ReactApexChart
                options={viewData.options}
                series={viewData.series}
                type="area"
                height={460}
              />
            </div>
            <div className="flex flex-col items-start justify-end w-64 h-full">
              <p className="">
                Page Views Breakdown <br />
                <span className="text-xs text-gray-400">(Last 28 Days)</span>
              </p>
              {viewData?.analytic.map((analytic) => (
                <div key={analytic.name} className="w-full">
                  <p className="text-[#808080] text-xs tracking-wide">
                    {analytic.title}
                  </p>
                  <div className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]">
                    <p className="">Total</p>
                    <p className="text-lg font-medium">{analytic.sum}</p>
                  </div>
                  <DataCard title={"Growth"} value={`${analytic.growth}%`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
}

export default InstagramVisitLikes;
