import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function NotloginfooterNew() {
  const router = useHistory();
  return (
    <div className="bg-[#171717] w-full px-5 md:px-10 pt-10 md:pt-20 pb-4 md:grid md:grid-cols-3">
      <div className="">
        <Link to="/">
          <img
            loading="lazy"
            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
            alt="logo"
            className="h-[82px] w-auto"
          />
        </Link>
        <div className="flex gap-3 mt-5">
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/myrevueapp"
            target="_blank"
          >
            <img
              loading="lazy"
              src={require("../assets/icons/Social/Instagram.svg").default}
              alt="logo"
              className="h-[26px] w-auto"
            />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.facebook.com/myrevueapp/"
            target="_blank"
          >
            <img
              loading="lazy"
              src={require("../assets/icons/Social/Facebook.svg").default}
              alt="logo"
              className="h-[26px] w-auto"
            />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/MyrevueApp"
            target="_blank"
          >
            <img
              loading="lazy"
              src={require("../assets/icons/Social/Twitter.svg").default}
              alt="logo"
              className="h-[26px] w-auto"
            />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://in.linkedin.com/company/myrevueapp"
            target="_blank"
          >
            <img
              loading="lazy"
              src={require("../assets/icons/Social/Linkedin.svg").default}
              alt="logo"
              className="h-[26px] w-auto"
            />
          </a>
        </div>
      </div>
      <div className="flex justify-end w-full md:col-span-2 ">
        <div className="grid grid-cols-2 gap-10 mt-10 md:grid-cols-4 md:mt-0">
          <div className="w-full">
            <p className="text-3xl font-semibold">Products</p>
            <div className="mt-2">
              <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] font-work">
                Interactive videos
              </p>
              <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] font-work">
                Shoppable videos
              </p>
              <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] font-work">
                Fully Remote
              </p>
            </div>
          </div>
          <div className="w-full">
            <p className="text-3xl font-semibold">Solutions</p>
            <div className="mt-2">
              <a href="/saas" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] cursor-pointer font-work">
                  Saas
                </p>
              </a>
              <a href="/getavideo" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] cursor-pointer font-work">
                  Get a Video
                </p>
              </a>
              <a href="/showcase" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] cursor-pointer font-work">
                  Showcase
                </p>
              </a>
              <a href="/ugc" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] cursor-pointer font-work">
                  UGC
                </p>
              </a>
              <a href="/stock" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide transition-all hover:text-[#F92147] cursor-pointer font-work">
                  Stock Videos
                </p>
              </a>
            </div>
          </div>
          <div className="w-full">
            <p className="text-3xl font-semibold">Partners</p>
            <div className="mt-2">
              <a
                href="/serviceReview"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Service Review
                </p>
              </a>
              <a
                href="/become_partner"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Become A Agency
                </p>
              </a>
              <a
                href="https://creator.myrevue.app"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Creator Program
                </p>
              </a>
            </div>
          </div>
          <div className="w-full">
            <p className="text-3xl font-semibold">Resources</p>
            <div className="mt-2">
              <a
                href="https://myrevue.app/blogs"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Blog
                </p>
              </a>
              <a
                href="https://support.myrevue.app"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Help Center
                </p>
              </a>
              <a
                href="/terms-and-conditions"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Terms & Conditions
                </p>
              </a>
              <a href="/privacy-policy" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Privacy Policy
                </p>
              </a>
              <a href="/refundpolicy" rel="noopener noreferrer" className="">
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Refund Policy
                </p>
              </a>
              <a
                href="/cancellationpolicy"
                rel="noopener noreferrer"
                className=""
              >
                <p className="text-[#BAB1B1] tracking-wide cursor-pointer transition-all hover:text-[#F92147] font-work">
                  Cancellation Policy
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full  pt-4 mt-4 border-t border-[#535353] col-span-3">
        <p className="text-xl text-center ">Contact Us</p>
        <div className="flex flex-wrap gap-4 pt-4 text-sm tracking-wide justify-evenly lg:grid lg:grid-cols-4">
          <div className="">
            <p className="font-work ">TribeVibe Experiences Private Limited</p>
          </div>
          <div className="">
            <p className="font-work lg:text-center">
              {" "}
              MyRevue HQ , 336-B, IDA Scheme No 140 , Indore , Indore Madhya Pradesh , 452016
            </p>
          </div>
          <div className="">
            <p className="font-work lg:text-center">
              Email: connect@myrevue.app
            </p>
          </div>
          <div className="">
            <p className="font-work lg:text-end">Phone: +91 8770097379</p>
          </div>
        </div>
      </div>
      <div className="border-t border-[#817C7C] col-span-3 mt-6">
        <p className="text-[#ACA7A7] font-medium pt-4 tracking-wide">
          © 2024 TribeVibe Experiences Private Limited
        </p>
      </div>
    </div>
  );
}
