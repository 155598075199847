import React, { useState } from 'react'
import HalfStarRating from '../halfstarrating'

export default function ReviewCard({ review, selectedReviews, updateReviewSelection, isReview }) {
    const [displayVideo, setDisplayVideo] = useState(false);

    return (
        <div className={`w-full p-3 bg-bg_highlight rounded-md flex gap-3 cursor-pointer border-2  ${selectedReviews.includes(review?.id) ? " border-[#8EA9FA]" : "border-transparent hover:border-[#435488]"}`} >
            <div className="w-52 aspect-[9/16] rounded-md bg-black">
                {!displayVideo ?
                    <img loading='lazy' src={review?.coverImage ?? review?.thumbnail} onClick={() => setDisplayVideo(true)} alt={"thumbnail" + review?.id} className='object-cover w-full h-full rounded-md' />
                    :
                    <video playsInline poster={review?.coverImage ?? review?.thumbnail} controls src={review?.reviewUrl} className='object-cover w-full h-full rounded-md' controlsList='nodownload noplaybackrate' disablePictureInPicture ></video>
                }
            </div>
            <div className="flex-1 w-full " onClick={() => updateReviewSelection(review?.id)} >
                <div className="mb-3 text-white">
                    <p className="font-work">{isReview ? "Review" : "Flick"} Title</p>
                    <input
                        type="text"
                        defaultValue={review?.name}
                        readOnly
                        className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                    />
                </div>
                <div className="mb-3 text-white">
                    <p className="font-work">Product Name</p>
                    <input
                        type="text"
                        defaultValue={review?.product?.name}
                        readOnly
                        className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                    />
                </div>
                {isReview &&
                    <div className="mb-3 text-white">
                        <p className="font-work">Rating</p>
                        <HalfStarRating sizeh={22} rating={review?.rating} colorcode={"yellow"} />
                    </div>
                }
            </div>
        </div>
    )
}
