import React, { useEffect, useMemo, useState } from "react";
import SocialAnalyticsCard from "./card";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";

function PageAnalyticDashboard({ pageid }) {
  const [{ user }, dispatch] = useStateValue();
  const [insight, setInsight] = useState(null);

  const page = useMemo(() => {
    return user.social.facebook_pages.find((p) => p.id === pageid);
  }, []);

  useEffect(() => {
    var token = user.social.facebook_pages.find(
      (p) => p.id === pageid
    )?.access_token;
    axios
      .get(
        `https://graph.facebook.com/v19.0/${pageid}/insights?metric=page_impressions,page_posts_impressions_viral,page_video_views,page_views_total,post_video_avg_time_watched,page_total_actions,page_actions_post_reactions_like_total&period=week&access_token=${token}`
      )
      .then((response) => {
        setInsight(response.data.data);
      });
  }, []);

  if (!insight)
    return (
      <div className="">
        <p className="">Loading...</p>
      </div>
    );
  else
    return (
      <>
        <div className="px-5 py-3 rounded-md bg-bg_highlight">
          <p className="text-xl tracking-wide">Facebook Page ({page.name})</p>
          <p className="text-xs text-gray-500">Last Week</p>
          <div className="grid grid-cols-3 gap-10 pt-5">
            {insight.map((card, index) => (
              <SocialAnalyticsCard key={card.name} card={card} />
            ))}
          </div>
        </div>
      </>
    );
}

export default PageAnalyticDashboard;
