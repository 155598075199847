import React, { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import WebTotalViews from "../analytics/WebTotalViews";
import ProductListByViews from "../analytics/ProductListByViews";
import { showWarning } from "../components/toast/toast";

export default function Analytics() {
  const [{ user }, dispatch] = useStateValue();
  const [brandTotalAnalytics, setBrandTotalAnalytics] = useState([]);

  useEffect(() => {
    async function getBrandTotalAnalytics() {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: user?.clientSecret,
        };

        let reqOptions = {
          url: `https://api.myrevue.app/getBrandPluginsAnalyticsCount`,
          method: "GET",
          headers: headersList,
        };
        let response = sessionStorage.getItem("getBrandPluginsAnalyticsCount");

        if (!response) {
          response = await axios.request(reqOptions);
          sessionStorage.setItem(
            "getBrandPluginsAnalyticsCount",
            JSON.stringify(response.data.data)
          );
          setBrandTotalAnalytics(response.data.data);
        } else {
          response = JSON.parse(response);
          setBrandTotalAnalytics(response);
        }
      } catch (error) {
        showWarning(error.message);
      }
    }
    getBrandTotalAnalytics();
  }, [user]);

  const totalViews =
    brandTotalAnalytics?.masterFaq +
    brandTotalAnalytics?.views +
    brandTotalAnalytics?.productFaq +
    brandTotalAnalytics?.homeGuide;
  if (totalViews)
    return (
      <div className="">
        <div className="px-10 py-5">
          <p className="text-3xl font-medium tracking-wide text-white">
            Website Analytics
          </p>
        </div>
        <div className="grid grid-cols-5 gap-5 px-10 my-5">
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Website Total Views</p>
            <p className="my-5 text-3xl ">{totalViews}</p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Video Guide Total Views</p>
            <p className="my-5 text-3xl ">
              {brandTotalAnalytics?.homeGuide ?? 0}
            </p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Master FAQ's Total Views</p>
            <p className="my-5 text-3xl ">
              {brandTotalAnalytics?.masterFaq ?? 0}
            </p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Product FAQ's Total Views</p>
            <p className="my-5 text-3xl ">
              {brandTotalAnalytics?.productFaq ?? 0}
            </p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Product Added To Cart </p>
            <p className="my-5 text-3xl ">
              {brandTotalAnalytics?.addTocart ?? 0}
            </p>
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-10 px-10">
          <div className="">
            <ProductListByViews />
          </div>
          <div className="w-full">
            <WebTotalViews />
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="h-screen">
        <div className="px-10 py-5">
          <p className="text-3xl font-medium tracking-wide text-white">
            Website Analytics
          </p>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="">
            Widget is not Integrated. Analytical data will be shown after
            integration of MyRevue widgets.
          </p>
          <a
            href="https://myrevue.gitbook.io/"
            rel="noreferrer"
            target="_blank"
            className="px-3 py-2 my-2 text-white duration-200 bg-purple-600 rounded hover:bg-purple-500"
          >
            Integrate Now
          </a>
        </div>
      </div>
    );
}
