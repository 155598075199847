import React from "react";
import { useState } from "react";
import { nanoid } from "nanoid";
import axios from "axios";
import { useStateValue } from "../../context/StateProvider";
import {
  CircleNotch,
  Plus,
  Trash,
  UploadSimple,
  X,
} from "@phosphor-icons/react";
import HalfStarRating from "../../components/halfstarrating";
import { useHistory } from "react-router-dom";
import { getDownloadURL, getStorage, ref, uploadBytesResumable, } from "firebase/storage";
import { showWarning } from "../../components/toast/toast";

export default function Local() {
  const [importLinks, setImportLinks] = useState([""]);
  const [importedLinks, setImportedLinks] = useState([{}]);
  const [imported, setImported] = useState(true);
  const [importLoading, setImportLoading] = useState(false);
  const [{ user, products, reviews, brand }, dispatch] = useStateValue();
  const [productSearchText, setProductSearchText] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const router = useHistory();
  const flickLanguage = "Hindi,English,Telugu,Tamil,Marathi,Bengali";
  const flickTags = [
    {
      tag: "Get The Looks",
      specific: [
        "Ethnic Wear Tips",
        "Wedding Wear",
        "SkinCare Routine Tips",
        "Cocktail Wear",
        "Vintage & Retro Styling",
        "Fitness & Health Tips",
      ],
    },
    {
      tag: "Haul Videos",
      specific: ["Food Haul", "Tech Haul", "Beauty Haul", "Fashion Haul"],
    },
    {
      tag: "Tutorial Videos",
      specific: [
        "Makeup Tutorial",
        "SkinCare Tutorial",
        "Smart Devices Tutorial",
        "App-Web Tools Tutorial",
        "Nail Art Tutorial",
        "healthy Cooking Tutorial",
        "hair Styling Tutorial",
      ],
    },
    {
      tag: "Unboxing Videos",
      specific: [
        "Food & Drink Unboxing",
        "Beauty Unboxing",
        "Gadget Unboxing",
        "Fashion Unboxing",
      ],
    },
    {
      tag: "Store Visit Videos",
      specific: ["Online Store Visit", "Offline store Visit"],
    },
  ];

  // Function to handle changes in the input fields
  const handleInputChange = (index, value) => {
    const updatedImportLinks = [...importLinks];
    updatedImportLinks[index] = value;
    setImportLinks(updatedImportLinks);
  };
  const validateLinkFormat = (index) => {
    const updatedImportLinks = [...importLinks];
    const inputValue = updatedImportLinks[index];

    const youtubeRegex =
      /^https?:\/\/(?:www\.)?youtube\.com\/shorts\/[a-zA-Z0-9_-]+(\?.*)?$/;
    const instagramRegex =
      /^https?:\/\/(?:www\.)?instagram\.com\/reel\/[a-zA-Z0-9_-]+(\/\?.*)?$/;

    if (!youtubeRegex.test(inputValue) && !instagramRegex.test(inputValue)) {
      updatedImportLinks[index] = "";
      setImportLinks(updatedImportLinks);
      showWarning("Url format is incorrect");
      return;
    }
  };

  // Function to add a new input field
  const addInput = (index) => {
    // setImportLinks([...importLinks, '']);

    if (importedLinks.length >= 3) {
      showWarning("Only 3 Videos can be uploaded at a time.");
      return;
    }
    setImportedLinks((links) => [...importedLinks, {}]);
  };

  // Function to delete an input field by its index
  const deleteInput = (index) => {
    const updatedImportLinks = [...importedLinks];
    if (updatedImportLinks.length <= 1) {
      showWarning("There must be atleast one video to import");
      return;
    }
    updatedImportLinks.splice(index, 1);
    setImportedLinks(updatedImportLinks);
  };

  async function importVideos() {
    if (importLinks.length === 0) {
      showWarning("Please add atleast 1 link");
      return;
    }
    const hasEmptyString = importLinks.some((link) => link.trim() === "");

    if (hasEmptyString) {
      showWarning("Please add correct links before submitting.");
      return;
    }
    try {
      setImportLoading(true);
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };
      let bodyContent = {
        links: importLinks,
      };
      let reqOptions = {
        url: "https://videoimporter-zscu3untuq-uc.a.run.app/brandadmin/importVideos",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };
      let response = await axios.request(reqOptions);
      setImportedLinks(response.data.contentLinks);
      setImported(true);
      setImportLoading(false);
    } catch (error) {
      setImported(false);
      setImportLoading(false);
    }
  }
  async function SaveImportVideos() {
    if (importedLinks.length === 0) {
      showWarning("There must atleast 1 video");
      return;
    }
    setSubmitLoading(true);
    const updatedLinks = [];
    const newLinks = [...importedLinks];

    newLinks.forEach((flick) => {
      updatedLinks.push({
        id: "review_" + nanoid(16),
        title: flick?.name,
        name: flick?.name,
        description: flick?.name,
        bpsId: flick?.prodId,
        flickTag: flick?.flickTag,
        uploadedBy: flick?.userData?.userid ?? user?.appUserId,
        thumbnail: flick?.thumbnail,
        reviewUrl: flick?.reviewUrl,
        subFlickTag: flick?.subFlickTag,
        brand: brand?.id,
        views: 0,
        hearts: 0,
        rating: 0,
        type: "flicks",
        processed: false,
        subcategory: null,
        catId: null,
        reviewLanguage: flick?.language,
        purchaseMode: null,
        purchaseTime: null,
        orderId: null,
      });
    });
    async function validateUpdatedLinks(updatedLinks) {
      const isValidLinks = updatedLinks.every((flick, index) => {
        const requiredFields = [
          "id",
          "title",
          "name",
          "description",
          "bpsId",
          "flickTag",
          "uploadedBy",
          "thumbnail",
          "reviewUrl",
          "subFlickTag",
        ];

        return requiredFields.every((field) => flick[field] !== undefined);
      });

      if (!isValidLinks) {
        showWarning("Please fill all details.");
        setSubmitLoading(false);
        return false;
      }

      const hasMinLengthTitle = updatedLinks.every((flick, index) => {
        if (flick.title.length < 20) {
          showWarning(`import video ${index + 1} has less than 20 characters`);
          return false;
        }
        return true;
      });

      if (!hasMinLengthTitle) {
        setSubmitLoading(false);
        return false;
      }

      return true;
    }

    const isValidLinks = await validateUpdatedLinks(updatedLinks);
    if (isValidLinks === false) {
      setSubmitLoading(false);
      return;
    }

    async function sendApiRequestsSequentially() {
      try {
        const headersList = {
          Accept: "*/*",
          Authorization:
            "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
        };

        for (const obj of updatedLinks) {
          const reqOptions = {
            url: `https://flicksapi-zscu3untuq-el.a.run.app/createFlicks`,
            method: "PUT",
            headers: headersList,
            data: obj,
          };

          const response = await axios.request(reqOptions);
          console.log(response.data);
          console.log("done: " + obj?.id);
        }
      } catch (error) {
        console.error("Error creating documents:", error);
      }
    }
    async function getBrandFlicks() {
      let headersList = {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      };

      let reqOptions = {
        url:
          "https://adminapi-zscu3untuq-el.a.run.app/getBrandFlicks/" +
          brand?.id,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      sessionStorage.setItem("flicks", JSON.stringify(response.data));
      dispatch({ type: "SET_FLICKS", flicks: response.data });
    }
    await sendApiRequestsSequentially();
    await getBrandFlicks();
    setSubmitLoading(false);
    router.push("/admin/flicks");
  }
  function addFieldToObjects(objects, index, fieldName, fieldValue) {
    if (index < 0 || index >= objects.length) {
      return objects; // Index out of bounds, return the original array
    }

    const updatedObjects = objects.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          [fieldName]: fieldValue,
        };
      }
      return obj;
    });

    return updatedObjects;
  }
  const handleAddFields = (index, field, value) => {
    const updatedArray = addFieldToObjects(importedLinks, index, field, value);
    setImportedLinks(updatedArray);
  };
  async function uploadCoverImage(index, event) {
    const file = event.target.files[0];

    if (!file) {
      showWarning("No files selected.");
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = function () {
      const aspectRatio = img.width / img.height;
      console.log(aspectRatio)
      // if (aspectRatio !== 9 / 16) {
      //   showWarning("Image aspect ratio must be 9:16.");
      //   return;
      // }

      if (img.height < 500) {
        showWarning("Image height must be at least 500px.");
        return;
      }

      const storage = getStorage();
      const storageRef = ref(storage, `importVideos/${Date.now()}.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            handleAddFields(index, "thumbnail", downloadURL);
          });
        }
      );
    };
  }
  async function validateVideo(file) {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      const fileReader = new FileReader();
      fileReader.onload = async function (event) {
        video.src = event.target.result;

        await video.play(); // Play the video to load metadata
        if (video.videoHeight < 600) {
          URL.revokeObjectURL(video.src); // Release the object URL
          showWarning("video height must be at least 700px.");
          resolve(false);
        } else {
          URL.revokeObjectURL(video.src); // Release the object URL
          resolve(true);
        }
      };

      fileReader.readAsDataURL(file);
    });
  }

  async function uploadLocalVideo(index, event) {
    const file = event.target.files[0];

    if (!file) {
      showWarning("No files selected.");
      return;
    }

    // const isValid = await validateVideo(file);

    // if (!isValid) {
    //     return;
    // }
    const storage = getStorage();
    const storageRef = ref(storage, `importVideos/${Date.now()}.mp4`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const element = document.getElementById(`upload${index}`);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        element.innerText =
          progress > 0 && progress < 100
            ? `Uploading: ${progress.toFixed(2)}%`
            : `Upload video file (.mp4)`;
        console.log(`Uploading: ${progress.toFixed(2)}%`);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        handleAddFields(index, "reviewUrl", downloadURL);
      }
    );
  }
  async function getUser(userId) {
    const response = await axios.get(`https://userapi-zscu3untuq-el.a.run.app/getUser/${userId}`, {
      headers: {
        Accept: "*/*",
        Authorization:
          "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
      },
    });
    return response.data
  }
  return (
    <div className="pt-5">
      <div className="px-5">
        <p className="text-3xl font-medium tracking-wide text-white">
          Import Videos
        </p>
      </div>
      <div className="px-5 pb-5">
        {imported === false ? (
          <div className="w-full p-5 mt-10 rounded-lg bg-bg_highlight">
            <div className="">
              <p className="">Add Links</p>
              <div className="mt-1 text-sm">
                <p className="font-light tracking-wide ">
                  Supported Url formats:
                </p>
                <p className="text-xs font-light tracking-wider font-work">
                  Youtube: https://www.youtube.com/shorts/
                  <span className="text-red-500">id</span>
                </p>
                <p className="text-xs font-light tracking-wider font-work">
                  Instagram: https://www.instagram.com/reel/
                  <span className="text-red-500">id</span>
                </p>
                {/* <p className="text-xs font-light tracking-wider font-work">https://www.facebook.com/reel/<span className="text-red-500">id</span></p> */}
              </div>
            </div>
            {importLinks.map((link, index) => (
              <div
                className="h-11 flex bg-[#2E3036] w-full items-center px-1 rounded-lg gap-3 my-2"
                key={index}
              >
                <div className="h-9 w-9 rounded-md bg-[#93A8F4] flex items-center justify-center">
                  {index + 1}
                </div>
                <div className="relative flex-1 w-full h-full">
                  <button
                    className="absolute flex items-center h-full px-1 right-1"
                    onClick={() => deleteInput(index)}
                  >
                    <Trash size={22} color="red" />
                  </button>
                  <input
                    type="text"
                    value={link}
                    onBlur={() => validateLinkFormat(index)}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    className="w-full h-full rounded-md appearance-none outline-none bg-transparent caret-[#93A8F4] tracking-wide"
                  />
                </div>
              </div>
            ))}
            <div className="flex items-center justify-center gap-5 my-5">
              <button
                className="bg-[#2E3036] w-fit px-6 rounded-full h-11 flex items-center gap-2"
                onClick={() => addInput()}
                disabled={importLoading}
              >
                <Plus size={22} color="white" />
                Add More
              </button>
              <button
                className="bg-[#93A8F4] w-fit px-12 rounded-full h-11 flex items-center gap-2"
                onClick={() => importVideos()}
              >
                {importLoading ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full p-5 mt-10 rounded-lg bg-bg_highlight">
            {importedLinks.map((link, index) => (
              <div key={index}>
                <div className="h-11 flex bg-[#2E3036] w-full items-center px-1 rounded-lg gap-3 mt-5 mb-2">
                  <div className="h-9 w-9 rounded-md bg-[#93A8F4] flex items-center justify-center">
                    {index + 1}
                  </div>
                  <div className="relative flex-1 w-full h-full">
                    <button
                      className="absolute flex items-center h-full px-1 right-1"
                      onClick={() => deleteInput(index)}
                    >
                      <Trash size={22} color="red" />
                    </button>
                    <input
                      type="text"
                      defaultValue={`import video ${index + 1}`}
                      readOnly
                      className="w-full h-full rounded-md appearance-none outline-none bg-transparent caret-[#93A8F4] tracking-wide"
                    />
                  </div>
                </div>
                {!link?.reviewUrl && (
                  <div className="h-24 w-96 rounded-lg bg-[#2E3036] mx-auto my-10 flex flex-col items-center justify-center relative">
                    <input
                      type="file"
                      accept=".mp4"
                      onChange={(event) => uploadLocalVideo(index, event)}
                      className="absolute w-full h-full rounded-lg opacity-0"
                    />
                    <UploadSimple size={22} color="white" />
                    <p className="font-work" id={`upload${index}`}>
                      Upload video file (.mp4)
                    </p>
                    {/* <p className="text-sm tracking-wider font-work">Uploading 1%</p> */}
                  </div>
                )}
                {link?.reviewUrl && (
                  <div className="flex items-start w-full gap-5">
                    <div className="flex gap-5">
                      <div className="">
                        <p className="text-sm tracking-wide font-work">Video</p>
                        <div className="w-48 aspect-[9/16] bg-[#2E3036] rounded-md border border-[#8080801e]">
                          <video playsInline
                            src={link?.reviewUrl}
                            controls
                            className="object-cover w-full h-full rounded-md"
                            controlsList="nodownload noplaybackrate"
                            disablePictureInPicture
                          ></video>
                        </div>
                        <div className="flex justify-center">
                          <button className="relative mt-2 text-sm text-center hover:underline underline-offset-2 w-fit">
                            <input
                              type="file"
                              accept=".mp4"
                              className="absolute top-0 left-0 w-full h-full bg-white rounded-full opacity-0"
                              onChange={(event) =>
                                uploadLocalVideo(index, event)
                              }
                            />
                            Upload video
                          </button>
                        </div>
                      </div>
                      <div className="">
                        <p className="text-sm tracking-wide font-work">
                          Thumbnail
                        </p>
                        <div className="w-48 aspect-[9/16] bg-[#2E3036] rounded-lg border border-[#8080801e]">
                          {link.thumbnail &&
                            <img
                              src={link.thumbnail}
                              className="object-cover w-full h-full rounded-lg"
                              alt=""
                            />
                          }
                        </div>
                        <div className="flex justify-center">
                          <button className="relative mt-2 text-sm text-center hover:underline underline-offset-2 w-fit">
                            <input
                              type="file"
                              accept=".jpg,.png,.jpeg"
                              className="absolute top-0 left-0 w-full h-full bg-white rounded-full opacity-0"
                              onChange={(event) =>
                                uploadCoverImage(index, event)
                              }
                            />
                            Upload thumbnail
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 w-full mt-3">
                      <div className="flex items-center justify-between mt-3">
                        <input onChange={(event) => handleAddFields(index, "userid", event.target.value)}
                          className="flex-1 w-full  h-10 px-3 bg-[#2E3036] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Enter User Id" />
                        <button className="w-32 h-10 ml-5 bg-blue-500 rounded-md"
                          onClick={async () => {
                            if (!importedLinks[index]?.userid || !importedLinks[index]?.userid.trim()) {
                              return showWarning("Please add a user id")
                            }
                            const user = await getUser(importedLinks[index]?.userid.trim())
                            if (!user.success) {
                              return showWarning("Please enter a valid user id")
                            }
                            const data = {
                              name: user?.user?.name,
                              imageUrl: user?.user?.imageUrl ?? "https://ui-avatars.com/api/?name=" + user?.user?.name,
                              userid: user?.user?.id
                            }
                            handleAddFields(index, "userData", data)
                          }}
                        >Check user</button>
                      </div>
                      {link?.userData &&
                        <div className="flex gap-5 mt-2">
                          <div className="w-12 h-12 rounded-full">
                            <img src={link?.userData?.imageUrl} alt="" className="w-12 h-12 rounded-full" />
                          </div>
                          <div className="flex-1">
                            <div className="flex-1 max-w-[400px]  h-10 px-3 bg-[#2E3036] rounded-lg flex items-center">{link?.userData?.name}</div>
                          </div>
                        </div>
                      }
                      <div className=" bg-[#2E3036] h-[116px] rounded-xl w-full mt-5">
                        <textarea
                          onChange={(event) =>
                            handleAddFields(index, "name", event.target.value)
                          }
                          className="w-full h-full p-3 bg-transparent placeholder:text-[#8E8B8B] appearance-none outline-none"
                          placeholder="write a caption"
                        ></textarea>
                      </div>
                      <div className="relative w-full mt-5 group">
                        <div className=" mt-16 absolute w-full h-72 overflow-y-auto bg-[#202020] z-50 border border-[#8080801e] rounded-md hidden group-hover:flex flex-col">
                          <div className="flex items-center w-full h-12 gap-3 px-3 my-3">
                            <input
                              type="text"
                              value={productSearchText}
                              onChange={(event) =>
                                setProductSearchText(event.target.value)
                              }
                              className="flex-1 w-full h-10 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                              placeholder="Search by product name"
                            />
                            <button
                              className=""
                              onClick={() => setProductSearchText("")}
                            >
                              <X size={32} color="white" />
                            </button>
                          </div>
                          {products
                            ?.filter((val) =>
                              val?.name
                                ?.toLowerCase()
                                ?.includes(productSearchText?.toLowerCase())
                            )
                            ?.map((prod, pindex) => (
                              <div
                                className="w-full flex h-16 items-center justify-between cursor-pointer hover:bg-[#2E3036] px-3 py-2"
                                key={pindex}
                                onClick={() =>
                                  handleAddFields(index, "prodId", prod.id)
                                }
                              >
                                <div className="flex items-center flex-1 w-full gap-3">
                                  <div className="w-12 h-12 rounded-full">
                                    <img
                                      src={prod?.imageUrl}
                                      alt="productimage"
                                      className="w-full h-full rounded-full"
                                    />
                                  </div>
                                  <div className="flex-1 w-full">
                                    <p className="w-full text-sm tracking-wide">
                                      {prod?.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="">
                                  <HalfStarRating
                                    sizeh={25}
                                    colorcode={"#E2BE45"}
                                    rating={parseFloat(prod?.rating)}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="flex items-center w-full gap-8">
                          <div className="w-16 h-16 rounded-full">
                            <div className=" cursor-pointer h-full w-full bg-[#434343] rounded-full text-xl flex items-center justify-center">
                              {link?.prodId === null ? (
                                <p className="">+</p>
                              ) : (
                                <img
                                  src={
                                    products?.filter(
                                      (val) => val?.id === link?.prodId
                                    )[0]?.imageUrl
                                  }
                                  alt=""
                                  className="w-full h-full rounded-full"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex-1 w-full">
                            <div className="my-3">
                              <input
                                type="text"
                                readOnly
                                defaultValue={
                                  products?.filter(
                                    (val) => val?.id === link?.prodId
                                  )[0]?.name
                                }
                                className="w-full h-10 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                                placeholder="Product Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <p className="text-lg tracking-wide">Language</p>
                        <div className="flex flex-wrap gap-3 mt-1">
                          {flickLanguage.split(",").map((lang) => (
                            <button
                              key={lang + "lang"}
                              className={` ${link.language === lang
                                ? "bg-[#93A8F4] "
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(index, "language", lang)
                              }
                            >
                              {lang}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="mt-3">
                        <p className="text-lg tracking-wide">
                          What is this flick about{" "}
                          <span className="text-[#8E8B8B]">
                            (choose any one)
                          </span>
                        </p>
                        <div className="flex flex-wrap gap-3 mt-1">
                          {flickTags.map((tag) => (
                            <button
                              key={tag.tag + "flicktag"}
                              className={` ${link.flickTag === tag.tag
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(index, "flickTag", tag.tag)
                              }
                            >
                              #{tag.tag}
                            </button>
                          ))}
                        </div>
                      </div>
                      {!link?.flickTag ? null : (
                        <div className="mt-3">
                          <p className="text-lg tracking-wide">
                            More Specifically..
                          </p>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {flickTags
                              .find((d) => d.tag === link.flickTag)
                              ?.specific.map((specificTag) => (
                                <button
                                  key={specificTag}
                                  className={` ${link.subFlickTag === specificTag
                                    ? "bg-[#93A8F4]"
                                    : "bg-[#2E3036]"
                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                  onClick={() =>
                                    handleAddFields(
                                      index,
                                      "subFlickTag",
                                      specificTag
                                    )
                                  }
                                >
                                  #{specificTag}
                                </button>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="flex items-center justify-center gap-5 my-5">
              <button
                className="bg-[#2E3036] w-fit px-6 rounded-full h-11 flex items-center gap-2"
                onClick={() => addInput()}
              >
                Add more
              </button>
              <button
                className="bg-[#93A8F4] w-fit px-12 rounded-full h-11 flex items-center gap-2"
                onClick={() => SaveImportVideos()}
              >
                {submitLoading ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
