import React from "react";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  applicantStatus,
  compatiblityUserScoreComponent,
} from "./huntFunctions";

function ApplicantsCard({ application }) {
  const router = useHistory();
  const location = useLocation();

  const VisitProfileButton = () => {
    sessionStorage.setItem("applicantTemp", JSON.stringify(application));
    router.push(location.pathname + "/" + application.userId);
  };

  return (
    <div className="w-[300px] h-auto p-5 bg-[#131418] border border-[#545454] rounded-md">
      <div className="flex items-center justify-end gap-2 my-2">
        {/* TODO -- DONE ---- Add Tooltip on MyRevue Icon with message : MyRevue Verified Account */}
        <div data-tooltip="MyRevue Verified Account">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20icon.png?alt=media&token=71ebcd5d-3060-457a-a6fc-0d4dfa6de361"
            alt=""
            className="h-6 aspect-square"
            data-tooltip="MyRevue Verified Account"
          />
        </div>
        {/* TODO DONE ---- Add Tooltip on Instagram Icon with message : linked Instagram Account - Visit Profile to see more details  */}
        {application.instagramConnected && (
          <div data-tooltip="Linked Instagram Account">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
              alt=""
              className="h-6 aspect-square"
            />
          </div>
        )}
        {/* TODO - DONE Add Tooltip  : Requirements Matching Score */}
        {compatiblityUserScoreComponent(application.score)}
        {application.status && applicantStatus(application.status)}
      </div>

      <div className="flex justify-center w-full">
        <div className="h-24 w-24 rounded-full flex items-center justify-center bg-[#23252D]">
          {application?.user?.imageUrl !== null ? (
            <img
              src={application?.user?.imageUrl}
              alt="user_image"
              className="w-full h-full rounded-full"
            />
          ) : (
            <p className="">
              {(application?.user?.name &&
                application?.user?.name
                  .split(" ")
                  .map((n) => n[0].toUpperCase())
                  .join("")) ||
                ""}
            </p>
          )}
        </div>
      </div>
      <div className="my-2">
        <p className="text-sm text-center">{application?.user?.name}</p>
      </div>
      <div className="grid grid-cols-3 gap-3 my-5">
        <div className="flex flex-col items-center justify-center w-full h-12 gap-3 ">
          <p className="text-lg leading-none">{application.user.rating ?? 3}</p>
          <p className="text-xs leading-none">Rating</p>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-12 gap-3 ">
          <p className="text-lg leading-none">
            {application.user.followers ?? 0}
          </p>
          <p className="text-xs leading-none">Followers</p>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-12 gap-3 ">
          <p className="text-lg leading-none">{application.user.flicks ?? 0}</p>
          <p className="text-xs leading-none">Flicks</p>
        </div>
      </div>
      <div className="grid w-full my-2 place-items-center">
        <button onClick={VisitProfileButton} className="text-xs ">
          Visit Profile
        </button>
      </div>
    </div>
  );
}

export default ApplicantsCard;
