import React, { useMemo } from "react";

export default function SocialAnalyticsCard({ card }) {
  const growth = useMemo(() => {
    const val = Math.floor(
      ((card.values[1].value - card.values[0].value) * 100) /
      card.values[0].value
    );
    if (isNaN(val)) return 0;
    return val;
  }, [card]);
  return (
    <div className="flex flex-col justify-between h-32 p-2 rounded-md min-w-fit bg-background border border-[#8080801e]">
      <div className="flex items-center justify-between">
        <p className="text-sm font-light tracking-wide">{card.title}</p>
        <p className={growth < 0 ? "text-xl self-end text-red-500" : "text-lime-500 text-xl self-end"}  >
          {growth} <span>%</span>{" "}
        </p>
      </div>
      <div className="flex items-center justify-start gap-10">
        <div className="">
          <p className="text-2xl font-semibold ">{card.values[0].value}</p>
          <p className="text-xs font-light text-[#808080]">
            {new Date(card.values[0].end_time).toLocaleString()}
          </p>
        </div>
        <div className="">
          <p className="text-2xl font-semibold">{card.values[1].value}</p>
          <p className="text-xs font-light text-[#808080]">
            {new Date(card.values[1].end_time).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
}
