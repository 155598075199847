import React, { Suspense, lazy, useEffect, useMemo, useRef, useState, } from "react";
import Loadingdot from "./components/loadingdot";
import { ArrowLeft, ArrowRight, Check, Play, RocketLaunch, ShuffleAngular, Target, Wallet, X } from "@phosphor-icons/react";
import AccordionItem from "./components/accordian";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useHistory, useLocation, Link } from "react-router-dom";
import PlaylistFeed from "./components/playlistFeed";
import Modalcard from "./components/modalcard";
import axios from "axios";
import { showWarning } from "./components/toast/toast";
import { Navigation } from "swiper";
import ContactForm from "./components/ContactForm";
const LogoSlider = lazy(() => import("./components/logoSlider"));
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));

export default function InhouseVideos() {
    const swiperRef = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const router = useHistory();
    const [activePlaylist, setactivePlaylist] = useState([]);
    const flicksArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4D5ebca875b24ba2a87b7463546eeed6.mp4?alt=media&token=3672d45e-8251-4eb6-b8af-908ea2ebbb85",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4d5ebca875b24ba2a87b7463546eeed6.png?alt=media&token=bc19efab-0992-4647-829d-949151b9a63a",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol2.mp4?alt=media&token=b08eb87a-7c95-4963-8bf2-12dd60d78610",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol2.webp?alt=media&token=bb5e8402-d3a4-45dd-827f-316530275dd8",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol3.mp4?alt=media&token=8ba06cca-84de-4cf8-98b9-2924acb4c7e0",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol3.webp?alt=media&token=96ebe8dd-0d48-4809-a636-4974dc2f8cbc",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini2.mp4?alt=media&token=6a0b0d49-b92b-4af5-ae47-0b00c5c96ede",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fbhavini2.webp?alt=media&token=51f8f3c3-0a72-42a5-9bd3-1489bfa09d20",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).mp4?alt=media&token=b7198879-89b5-4c8a-a455-a09441445922",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).png?alt=media&token=0fd786ff-6e46-4c12-85ff-755e97f0c02a",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTaponn%20(Anurag%20Sir).mp4?alt=media&token=322221ae-19fc-4bb1-a973-f4f6c060c68b",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTapOnn%20(Anurag%20Sir).png?alt=media&token=cf12877c-adb7-4f4d-9cdd-73cc624fef7b",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTaponn%20(Khushi).mp4?alt=media&token=39e81ea3-7ea6-40a2-843d-baa4ed3b619b",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTapOnn%20(Khushi).png?alt=media&token=25dd607d-ea02-445e-80ed-714562b80b5a",
        },
        // {
        //     video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FWildglow%20Reel.mp4?alt=media&token=e4a6304e-3c89-4a19-b672-cc4b364d03a8",
        //     poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FWildglow%20Reel.png?alt=media&token=41db347e-fb3d-420f-8492-b52ceb15136f",
        // },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag1.mp4?alt=media&token=172ad69d-7f1c-446e-a867-26bce250fc85",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanurag1.webp?alt=media&token=cf7a5008-5453-47c0-9e05-ec7505a9755e",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh1.mp4?alt=media&token=b7b7271e-de84-4944-a8dc-77565b207148",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fvedansh1.webp?alt=media&token=ca258d8c-fdb0-41c0-af9d-47e70426451b",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksE%2Freview_219qSxB_KIriCVfpmsov5PKBc.mp4?alt=media&token=37e0578c-87ce-43fd-b0f6-b9c87fb8a88e",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_219qSxB_KIriCVfpmsov5PKBc.jpg?alt=media&token=f171286a-0a92-48f5-ada7-cb836b5283a3",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_fuzOoj7gC4rbPhXz_p.mp4?generation=1677135449934793&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_fuzOoj7gC4rbPhXz_p.jpg?alt=media&token=acd55324-7e91-4388-b044-44d8fc7b5f16",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak1.mp4?alt=media&token=521c8f94-f535-4f97-86d8-7ad2036b80ac",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fpalak1.webp?alt=media&token=2321831f-95b0-4f16-a39b-2d30b487a6c9",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_ppsL7LRYG5VkcB93FM.mp4?alt=media&token=268435fc-6c68-4bcb-a699-ed1fe63a70d4",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_ppsL7LRYG5VkcB93FM.jpg?alt=media&token=860d6e6a-141f-4fb0-9356-1f35f5fd0eaf",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak3.mp4?alt=media&token=f7948f01-26c7-41b2-93e8-0820246244f5",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fpalak3.webp?alt=media&token=6cfd0d86-5f99-4f15-b22c-134f0b10ede3",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_skbS98U1PBPnkcTGiz.mp4?generation=1670858902234562&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_skbS98U1PBPnkcTGiz.jpg?alt=media&token=c5c78128-d63d-44f2-ab3f-e15d43032172",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy2.mp4?alt=media&token=46106ffd-4267-4b02-9bbe-2899768fd2d8",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fdaisy2.webp?alt=media&token=538b750b-17be-49f6-b25d-5d0fc87c2d3a",
        },
    ];
    const [talkModal, setTalkModal] = useState(false);

    const [paymentLoading, setPaymentLoading] = useState(false);
    const [notes, setNotes] = useState({
        "brandName": "",
        "brandWebsite": "",
        "websiteCategory": "",
        "spokePersonName": "",
        "contactNumber": "",
        "socialMediaLink": "",
        "brandLogoLink": "",
        "spokePersoneMailId": "",
        "referenceVideoLink": "",
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (params.get('showModal') === 'true') {
            if (activePlaylist.length === 0) {
                router.replace("/inhousevideos")
            }
        }
    }, [params, router, activePlaylist])
    async function validatebrandinfo() {
        // Regular expression patterns for email and URL validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/; // Basic URL pattern

        // Create an object to hold errors
        let validationErrors = {};

        // Check if any required fields are empty or if they don't meet the validation criteria
        if (!notes.brandName) {
            validationErrors.brandName = "Brand name is required";
        }
        if (!notes.brandWebsite || !urlPattern.test(notes.brandWebsite)) {
            validationErrors.brandWebsite = "Valid website URL is required";
        }
        if (!notes.websiteCategory) {
            validationErrors.websiteCategory = "Website category is required";
        }
        if (!notes.spokePersonName) {
            validationErrors.spokePersonName = "Spokesperson name is required";
        }
        if (!notes.contactNumber) {
            validationErrors.contactNumber = "Contact number is required";
        }
        if (!notes.socialMediaLink || !urlPattern.test(notes.socialMediaLink)) {
            validationErrors.socialMediaLink = "Valid social media URL is required";
        }
        if (!notes.brandLogoLink || !urlPattern.test(notes.brandLogoLink)) {
            validationErrors.brandLogoLink = "Valid logo URL is required";
        }
        if (!notes.spokePersoneMailId || !emailPattern.test(notes.spokePersoneMailId)) {
            validationErrors.contactMailId = "Valid email address is required";
        }

        // Update state with errors
        setErrors(validationErrors);

        // Check if there are any errors
        return Object.keys(validationErrors).length === 0;
    }
    async function generatePaymentLink(planType) {
        setPaymentLoading(true)
        if (!planType) {
            return showWarning("Please select a plan")
        } const plan = ["TryMe", "Launch", "Grow", "Scale"];
        const amount = [8000, 25000, 40000, 70000];

        const TAX_RATE = 0.18;
        const originalAmount = amount[planType - 1];
        const taxAmount = originalAmount * TAX_RATE;
        const finalAmount = originalAmount + taxAmount;

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };
        let bodyContent = {
            amount: finalAmount,
            currency: "INR",
            description: `Order UGC Video : ${plan[planType - 1]}`,
            url: "ugc",
            metadata: {
                "type": "UGC Order",
                "plan": plan[planType - 1],
                "amount": finalAmount,
            }
        };

        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/razorpay/create/ugc/checkoutLink",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };
        let response = await axios.request(reqOptions);
        setPaymentLoading(false)
        makePayment(response.data)
        // window.location.href = response.data.url;
    }
    async function closeModal() {
        history.replace("/inhousevideos")
    }
    const makePayment = async (data) => {
        const res = await initializeRazorpay();
        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }
        var options = {
            key: "rzp_live_E4dtid6tq7cO0E", // Enter the Key ID generated from the Dashboard
            // key: "rzp_test_J6MDQxvvITDTvi", // Enter the Key ID generated from the Dashboard
            name: "MyRevue",
            currency: data.currency,
            amount: data.amount,
            order_id: data.id,
            description: data.notes.description,
            image: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue.png?alt=media&token=b9dc6ef0-c272-49ba-a7c5-5bf51b46eda7",
            handler: function (response) {
                setPaymentLoading(false)
                history.replace(`/inhousevideos?paymentModal=true&orderId=${data.id}`)
            },
            theme: {
                hide_topbar: false,
                color: '#FF005C',
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    const initializeRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    async function updateUGCOrder() {
        try {
            const isValid = await validatebrandinfo();
            if (!isValid) {
                return showWarning("Please fill out all required fields");
            }
            const noteDocs = {
                brandName: notes.brandName,
                brandWebsite: notes.brandWebsite,
                websiteCategory: notes.websiteCategory,
                spokePersonName: notes.spokePersonName,
                contactNumber: notes.contactNumber,
                socialMediaLink: notes.socialMediaLink,
                brandLogoLink: notes.brandLogoLink,
                contactMailId: notes.spokePersoneMailId,
                reference: notes.referenceVideoLink ?? ""
            };
            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
            let reqOptions = {
                url: "https://stripeugcapi-zscu3untuq-as.a.run.app/ugc/orders/update/" + params.get('orderId'),
                method: "POST",
                headers: headersList,
                data: noteDocs,
            };
            let response = await axios.request(reqOptions);
            if (response.data.success === true) {
                return history.replace("/inhousevideos?success=true")
            }
            else {
                return history.replace("/inhousevideos")
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    return (
        <div className="h-screen overflow-y-auto bg-[#101010]" id="document">
            <Suspense fallback={<Loadingdot />}>
                <NotLoginHeaderNew />
                {talkModal &&
                    <Modalcard close={setTalkModal}>
                        <ContactForm />
                    </Modalcard>
                }
                {params.get('success') ?
                    <div className="max-w-[800px] mx-auto flex-1 h-[calc(100vh-64px)] flex items-center">
                        <div className="lg:col-span-2 w-full  bg-[#171717] rounded-xl p-5 py-10 flex flex-col items-center justify-center">
                            <p className="text-3xl font-medium tracking-wide text-center text-white">🎉 Payment Successfull</p>
                            <p className="mt-2 tracking-wide text-center text-white">Our team will get in touch with you soon.</p>
                            <p className="mt-3 text-xs tracking-wide text-center text-white">*For any queries mail us on connect@myrevue.app</p>
                        </div>
                    </div>
                    :
                    <>
                        {params.get('paymentModal') === 'true' && (
                            <>
                                <Modalcard customClass={"flex item-start w-full"} close={closeModal}>
                                    <div id="thin-scrollbar" className="h-full w-full overflow-y-auto p-5 mx-auto  max-w-[500px] bg-background border-2 border-[#8080809d] rounded-lg">
                                        <div className="flex items-center justify-between w-full">
                                            <p className="text-2xl">Brand Information</p>
                                            <button className="flex items-center justify-center h-8 rounded-md aspect-square bg-bg_highlight" onClick={() => closeModal()} ><X size={22} color="red" /></button>
                                        </div>
                                        <div className="w-full mt-3 space-y-4">
                                            <div className="">
                                                <p className="font-light tracking-wide">Brand Name <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.brandName} onChange={(event) => setNotes({ ...notes, brandName: event.target.value })} placeholder="Enter your brand name" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.brandName ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, brandName: false })} />
                                                {errors?.brandName && <p className="text-sm tracking-wide text-red-500">{errors?.brandName}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Brand Website <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.brandWebsite} onChange={(event) => setNotes({ ...notes, brandWebsite: event.target.value })} placeholder="Enter your brand website url" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.brandWebsite ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, brandWebsite: false })} />
                                                {errors?.brandWebsite && <p className="text-sm tracking-wide text-red-500">{errors?.brandWebsite}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Business Category <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.websiteCategory} onChange={(event) => setNotes({ ...notes, websiteCategory: event.target.value })} placeholder="Enter your category" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.websiteCategory ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, websiteCategory: false })} />
                                                {errors?.websiteCategory && <p className="text-sm tracking-wide text-red-500">{errors?.websiteCategory}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Spoke Person Name <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.spokePersonName} onChange={(event) => setNotes({ ...notes, spokePersonName: event.target.value })} placeholder="Enter your brand spokeperson name" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.spokePersonName ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, spokePersonName: false })} />
                                                {errors?.spokePersonName && <p className="text-sm tracking-wide text-red-500">{errors?.spokePersonName}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Spoke Person Email id <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.spokePersoneMailId} onChange={(event) => setNotes({ ...notes, spokePersoneMailId: event.target.value })} placeholder="Enter your brand spokeperson email id" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.contactMailId ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, contactMailId: false })} />
                                                {errors?.contactMailId && <p className="text-sm tracking-wide text-red-500">{errors?.contactMailId}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Contact Number <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.contactNumber} onChange={(event) => setNotes({ ...notes, contactNumber: event.target.value })} placeholder="Enter your spokeperson number" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.contactNumber ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, contactNumber: false })} />
                                                {errors?.contactNumber && <p className="text-sm tracking-wide text-red-500">{errors?.contactNumber}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Social Media Link <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.socialMediaLink} onChange={(event) => setNotes({ ...notes, socialMediaLink: event.target.value })} placeholder="Enter your social media link" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.socialMediaLink ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, socialMediaLink: false })} />
                                                {errors?.socialMediaLink && <p className="text-sm tracking-wide text-red-500">{errors?.socialMediaLink}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Brand Logo Link <span className="text-red-500">*</span></p>
                                                <input type="text" value={notes?.brandLogoLink} onChange={(event) => setNotes({ ...notes, brandLogoLink: event.target.value })} placeholder="Enter your brand logo link" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.brandLogoLink ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, brandLogoLink: false })} />
                                                {errors?.brandLogoLink && <p className="text-sm tracking-wide text-red-500">{errors?.brandLogoLink}</p>}
                                            </div>
                                            <div className="">
                                                <p className="font-light tracking-wide">Reference Videos ( If any)</p>
                                                <input type="text" value={notes?.referenceVideoLink} onChange={(event) => setNotes({ ...notes, referenceVideoLink: event.target.value })} placeholder="Enter any reference urls" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border border-[#80808079]`} />
                                            </div>
                                            <div className="pt-4" onClick={() => updateUGCOrder()}>
                                                <button className="py-2 px-8 h-10 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modalcard>
                            </>
                        )}
                        {params.get('showModal') === 'true' && (
                            <>
                                <Modalcard close={closeModal}>
                                    <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                                        <div className="w-full h-full overflow-y-auto rounded snap-y snap-mandatory scrollbar" id="zero-scrollbar">
                                            <style>
                                                {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
                                            </style>
                                            <>
                                                {activePlaylist?.map((video, index) => (
                                                    <PlaylistFeed data={video} key={index} keyid={`playlist${index}`} displayscroll={true} />
                                                ))}
                                            </>
                                        </div>
                                    </div>
                                </Modalcard>
                            </>
                        )}
                        <div className="bg-[#101010] px-0 lg:px-10">
                            <div className="pt-[35px] lg:pt-[60px] mb-[20px] pb-[40px] lg:pb-[60px] px-5" id="pricing-plans">
                                <p className="text-[32px] md:text-[58px] mb-[40px] text-center  font-semibold">Select Your Inhouse UGC Video Package</p>
                                {/* <p className="text-[32px] md:text-[58px] mb-[40px] text-left block md:hidden font-semibold">UGC packs designed to drive results</p> */}
                                <div className="grid gap-5 mx-auto md:grid-cols-2 lg:grid-cols-4" >
                                    <div className="p-3 bg-[#171717] rounded-md shadow-md">
                                        <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%203.png?alt=media&token=f13b7684-60a4-40b3-a1b8-50aff16892ab')] bg-cover bg-center">
                                            {/* <p className="text-[32px] font-medium">Try Me</p>
                                        <p className="text-sm tracking-wide">To get your first trust building video with MyRevue</p> */}
                                            <p className="text-[38px] font-semibold text-center">₹8000 + GST</p>
                                            <p className="text-[24px] font-medium text-center">Try Me Package</p>
                                            {/* <p className="text-sm tracking-wide">For individuals and teams pushing to succeed with limited resources</p> */}
                                        </div>
                                        <div className="px-[30px] py-[36px]">
                                            <div className="pb-[15px]">
                                                {/* <p className="mb-[10px]">Up to</p> */}
                                                <p className="text-[78px] font-medium">
                                                    1 <span className="text-lg font-normal tracking-wide align-baseline">video</span>
                                                </p>
                                            </div>
                                            <div className="">
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                                </p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            </div>
                                            <div className="mt-3">
                                                <p className="text-[30px] font-medium flex items-center flex-wrap">
                                                    ₹8000 + GST
                                                </p>
                                                <p className="opacity-0">
                                                    .
                                                </p>
                                            </div>
                                            <div className="mt-[50px]">
                                                <button
                                                    onClick={() => generatePaymentLink(1)}
                                                    className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                    Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 bg-[#171717] rounded-md shadow-md">
                                        <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%202.png?alt=media&token=359cfb7a-40f4-40bf-baa9-23fc9c6f5adb')] bg-cover bg-center">
                                            <p className="text-[38px] font-semibold text-center">₹5000 + GST</p>
                                            <p className="text-[24px] font-medium text-center">Launch Package</p>
                                            {/* <p className="text-sm tracking-wide">For individuals and teams pushing to succeed with limited resources</p> */}
                                        </div>
                                        <div className="px-[30px] py-[36px]">
                                            <div className="pb-[15px]">
                                                {/* <p className="mb-[10px]">Up to</p> */}
                                                <p className="text-[78px] font-medium">
                                                    5 <span className="text-lg font-normal tracking-wide align-baseline">videos</span>
                                                </p>
                                            </div>
                                            <div className="">
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                                </p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            </div>
                                            <div className="mt-3">
                                                <p className="text-[30px] font-medium">₹25000 + GST</p>
                                                <p className="">
                                                    <span className="text-base align-middle line-through font-light text-[#9747FF]">₹40,000</span>
                                                    <span className="">
                                                        <span className="text-base align-middle pl-2 text-[#9747FF] font-light">37.5%</span>
                                                        <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="mt-[50px]">
                                                <button
                                                    onClick={() => generatePaymentLink(2)}
                                                    className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                    Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 bg-[#171717] rounded-md shadow-md">
                                        <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%202.png?alt=media&token=359cfb7a-40f4-40bf-baa9-23fc9c6f5adb')] bg-cover bg-center">
                                            {/* <p className="text-[32px] font-medium">Grow</p>
                                        <p className="text-sm tracking-wide">For aspiring professionals and businesses aiming to elevate their ads to the next level</p>
                             */}
                                            <p className="text-[38px] font-semibold text-center">₹4000 + GST</p>
                                            <p className="text-[24px] font-medium text-center">Grow Package</p>
                                        </div>
                                        <div className="px-[30px] py-[36px]">
                                            <div className="pb-[15px]">
                                                {/* <p className="mb-[10px]">Up to</p> */}
                                                <p className="text-[78px] font-medium">
                                                    10 <span className="text-lg font-normal tracking-wide align-baseline">videos</span>
                                                </p>
                                            </div>
                                            <div className="">
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> 30-day refund policy
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> 2 free edit requests per video
                                                </p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                                <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            </div>
                                            <div className="mt-3">
                                                <p className="text-[30px] font-medium flex items-center flex-wrap">
                                                    ₹40,000 + GST
                                                    {/* <span className="text-base align-middle pl-2 line-through font-light text-[#9747FF]">₹52,500</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">42%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span> */}
                                                </p>
                                                <p className="">
                                                    <span className="text-base align-middle line-through font-light text-[#9747FF]">₹80,000</span>
                                                    <span className="">
                                                        <span className="text-base align-middle pl-2 text-[#9747FF] font-light">50%</span>
                                                        <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="mt-[50px]">
                                                <button
                                                    onClick={() => generatePaymentLink(3)}
                                                    className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                    Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 bg-[#171717] rounded-md shadow-md">
                                        <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%203.png?alt=media&token=f13b7684-60a4-40b3-a1b8-50aff16892ab')] bg-cover bg-center">
                                            {/* <p className="text-[32px] font-medium">Scale</p> */}
                                            {/* <p className="text-sm tracking-wide">For seasoned marketers and agencies on the hunt for their next big triumph</p> */}
                                            <p className="text-[38px] font-semibold text-center">₹3500 + GST</p>
                                            <p className="text-[24px] font-medium text-center">Scale Package</p>
                                        </div>
                                        <div className="px-[30px] py-[36px]">
                                            <div className="pb-[15px]">
                                                {/* <p className="mb-[10px]">Up to</p> */}
                                                <p className="text-[78px] font-medium">
                                                    20+ <span className="text-lg font-normal tracking-wide align-baseline">videos</span>
                                                </p>
                                            </div>
                                            <div className="">
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> 30-day refund policy
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> 2 free edit requests per video
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Priority support
                                                </p>
                                                <p className="tracking-wide mb-[7px]">
                                                    <Check size={22} className="inline-block align-middle" /> Whitelabeling
                                                </p>
                                            </div>
                                            <div className="mt-3">
                                                <p className="text-[30px] font-medium flex items-center flex-wrap">
                                                    ₹70,000 + GST
                                                    {/* <span className="text-base align-middle pl-2 line-through font-light text-[#9747FF]">₹1,36,500</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">57%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span> */}
                                                </p>
                                                <p className="">
                                                    <span className="text-base align-middle line-through font-light text-[#9747FF]">₹1,60,000</span>
                                                    <span className="">
                                                        <span className="text-base align-middle pl-2 text-[#9747FF] font-light">77.14%</span>
                                                        <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="mt-[50px]">
                                                <button
                                                    onClick={() => generatePaymentLink(4)}
                                                    className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                    Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:max-w-[50%] flex flex-col items-center mt-10 lg:mt-20 mx-auto space-y-2">
                                    <p className="text-2xl font-semibold text-center lg:text-3xl">Can't find what you are looking for?</p>
                                    <p className="text-sm font-medium text-center lg:text-base">Let us know what you need - we're happy to help!</p>
                                    <button onClick={() => setTalkModal(true)} className="mt-1 py-3 px-12 w-fit rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">Talk To Us</button>
                                </div>
                            </div>
                        </div>
                        <div className=" bg-[#171717] py-10">
                            <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                                <div className="grid gap-20 lg:grid-cols-2">
                                    <div className="flex flex-col justify-center">
                                        <p className="text-[36px] leading-tight tracking-wide font-semibold ">
                                            Why Are Our Videos So Affordable?
                                        </p>
                                        <p className="mt-5 text-lg tracking-wide">It’s very simple. We eliminate the middlemen and get straight to the point!</p>
                                        <p className="mt-3 text-lg tracking-wide">We connect eCom brands (you) with thousands of vetted video creators and editors working from their home studios all over the India.</p>
                                    </div>
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Findia%20map.webp?alt=media&token=f03d9cee-1d79-451b-98bc-cf14886ac03d" alt="" className="max-h-[400px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" bg-[#101010] py-10">
                            <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                                <div className="grid gap-20 lg:grid-cols-2">
                                    <div className="flex flex-col justify-center">
                                        <p className="text-[36px] leading-tight tracking-wide font-semibold ">
                                            Get Custom Videos in just 96 hours with a Dedicated Brand Manager
                                        </p>
                                        <p className="mt-5 text-lg tracking-wide">Maximize MyRevue value: training and success plan for casting top talent and receiving high-performing net-new creatives faster</p>
                                    </div>
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Foffice.webp?alt=media&token=5037a76d-44a3-4c9d-9a52-2e30f06e1b1d" alt="" className="max-h-[350px] rounded-2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#171717] py-[40px] md:py-[50px] p-5 md:p-10">
                            <div className="md:max-w-[60%] mx-auto">
                                <p className="text-center mb-[25px] leading-snug tracking-wide text-[32px] md:text-[58px] font-semibold">Tap into a network of over 5,000 verified UGC creators</p>
                            </div>
                            <div className="overflow-hidden">
                                <ReactVisibilitySensor partialVisibility once>
                                    {({ isVisible }) => (
                                        <div className={`mt-10 ${isVisible ? "slide-to-left" : ""}`}>
                                            <Swiper
                                                spaceBetween={25}
                                                // slidesPerView={1}
                                                // onSlideChange={() => console.log("slide change")}
                                                // onSwiper={(swiper) => console.log(swiper)}
                                                ref={swiperRef}
                                                breakpoints={{
                                                    // when window width is >= 640px
                                                    640: {
                                                        width: 640,
                                                        slidesPerView: 1,
                                                    },
                                                    // when window width is >= 768px
                                                    768: {
                                                        width: 768,
                                                        slidesPerView: 2,
                                                    },
                                                    964: {
                                                        width: 964,
                                                        slidesPerView: 3,
                                                    },
                                                }}
                                                modules={[Navigation]}
                                                onBeforeInit={(swiper) => {
                                                    swiperRef.current = swiper;
                                                }}>
                                                {flicksArray.map((flick, index) => (
                                                    <SwiperSlide
                                                        key={index}
                                                        className="relative cursor-pointer"
                                                        onClick={() => {
                                                            // setplaylisttype("reviews");
                                                            setactivePlaylist(flicksArray);
                                                            // setActiveIndex(index);
                                                            history.push("/inhousevideos?showModal=true")
                                                            // setshowModal(true);
                                                            setTimeout(() => {
                                                                const videoid = document.getElementById(`playlist${index}`);
                                                                videoid.scrollIntoView();
                                                            }, 100);
                                                        }}>
                                                        <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                            <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                                                <Play size={34} color="white" weight="fill" />
                                                            </div>
                                                        </div>
                                                        <img loading="lazy" src={flick?.poster} alt="" className="w-full aspect-[9/16] object-cover rounded-xl" />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    )}
                                </ReactVisibilitySensor>
                                <div className="flex items-center justify-center gap-3 pb-5 mt-4">
                                    <button onClick={() => swiperRef.current?.slidePrev()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                                        <ArrowLeft size={26} color="black" />
                                    </button>
                                    <button onClick={() => swiperRef.current?.slideNext()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                                        <ArrowRight size={26} color="black" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#101010] pt-[40px] md:pt-[70px] p-5 md:p-10">
                            <div className="max-w-[1248px] bg-[#171717] p-5 shadow-lg rounded-md mx-auto">
                                <p className="text-[24px] mb-[20px]">FAQ</p>
                                <div className="accordion">
                                    <AccordionItem title="Why Choose MyRevue Elite Packages?">
                                        MyRevue Elite Packs offer a convenient and budget-friendly means to access a diverse range of video options. Whether you're in need
                                        of eCommerce videos, Instagram Reels, YouTube Shorts, TikTok content, or personalized Shopify videos, our packs come with discounted
                                        prices and added value. Opting for packs allows you to acquire more videos at a lower cost, enabling you to grow your content
                                        library and effectively captivate your audience.{" "}
                                    </AccordionItem>
                                    <AccordionItem title="How do packs work?">
                                        After you've placed an order, the MyRevue Elite content team will reach out to you to collaborate on designing the content.
                                    </AccordionItem>
                                    <AccordionItem title="What packs can be used for?">
                                        Credit packs offer versatility for use in any order on MyRevue Elite, excluding the acquisition of additional packs. Conversely, our
                                        custom packs are tailored to address specific video types, durations, and extra services as defined within the chosen pack
                                        structure.
                                    </AccordionItem>
                                    <AccordionItem title="Do packs expire?">
                                        Credit packs come with an extended validity period of 45 days, commencing from the date of purchase. In contrast, credits for all
                                        other packs are valid for 15 days, starting from the date of purchase.
                                    </AccordionItem>
                                    <AccordionItem title="Is it refundable?">Yes</AccordionItem>
                                    <AccordionItem title="Can I discuss my needs with MyRevue Elite representative?">
                                        Feel free to reach out to us at connect@myrevue.app, and we'll assist you by sharing our previous experiences with similar
                                        companies, analyzing your specific situation, and devising the optimal solution for your needs.
                                    </AccordionItem>
                                </div>
                            </div>
                        </div>
                        <NotloginfooterNew />
                    </>
                }
            </Suspense>
        </div>
    )
}
