import AWN from "awesome-notifications";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { auth, db } from "../firebase";
import { strings } from "../common/Strings";
import { sendAmplitudeData } from "../amplitude";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { CircleNotch, Eye, EyeSlash } from "@phosphor-icons/react";
import { nanoid } from "nanoid";
import { showWarning } from "../components/toast/toast";

function Login() {
  const [{ user }, dispatch] = useStateValue();
  const [newUserId, setNewUserId] = useState(nanoid(12));
  const [loginLoading, setLoginLoading] = useState(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);
  useEffect(() => {
    sendAmplitudeData("login page opened");
    var title = `Login - MyRevue`;
    var metaTitle = `Login - MyRevue`;
    var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var metaKeyword = `Brands - MyRevue, video testimonials software ,best video testimonial software, video testimonials, get video testimonials, collect video testimonials, free video testimonial software, video testimonials app, video customer testimonials, video testimonial platform, video testimonial tool`;

    var ogUrl = `https://brand.myrevue.app/login`;
    var ogtype = `website`;
    var ogtitle = `Login - MyRevue`;
    var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/login`;
    var twittertitle = `Login - MyRevue`;
    var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, []);

  let emailRef = useRef();
  let passwordRef = useRef();

  const router = useHistory();

  const signInByEmail = (email, password) => {
    setLoginLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        if (res.user) {
          const docref = doc(db, strings.adminCollection, email);
          const docsnap = await getDoc(docref);

          if (!docsnap.exists()) {
            return showWarning("No linked account found");
          }
          var data = docsnap.data();
          if (!data?.appUserId) {
            const appUserId = await GetUserId(data);
            data.appUserId = appUserId;
          }
          if (!data?.limit) {
            const result = await updateLimit(data);
            data.limit = result;
          }

          if (data.email === "myrevueappofficial@gmail.com") {
            sessionStorage.setItem("user", JSON.stringify(data));
            dispatch({ type: "SET_USER", user: data });
            setLoginLoading(false);
            router.replace("/masterlogin");
            return;
          } else if (data.role === "brandAdmin") {
            let headersList = {
              Accept: "*/*",
              Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
            };

            let reqOptions = {
              url: `https://brandapi-zscu3untuq-el.a.run.app/getBrand/${data?.brandId}`,
              method: "GET",
              headers: headersList,
            };

            let response = await axios.request(reqOptions);
            if (response.data.success === false) {
              showWarning(`No brand is associated with the login credentials`);
            } else {
              sessionStorage.setItem(
                "brand",
                JSON.stringify(response.data.brand)
              );
              dispatch({ type: "SET_BRAND", brand: response.data.brand });
              sessionStorage.setItem("user", JSON.stringify(data));
              dispatch({ type: "SET_USER", user: data });
              setLoginLoading(false);
              router.replace("/admin/inhouserequest");
              return;
            }
          }
        } else {
          setLoginLoading(false);
          showWarning("credential not valid");
        }
      })
      .catch((e) => {
        setLoginLoading(false);
        showWarning(
          "Either account doesn't exist or Wrong password or signin with google"
        );
      });
  };

  async function updateLimit(data) {
    try {
      const limitDoc = {
        campaignLimit: data?.campaignLimit ?? 1,
        commentLimit: data?.commentLimit ?? 0,
        downloadLimit: data?.downloadLimit ?? 3,
        faqLimit: 1,
        galleryLimit: 1,
        challangeLimit: 1,
        quizLimit: 1,
      };
      await updateDoc(doc(db, "brandAdmin", data.email), { limit: limitDoc });
      return limitDoc;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function GetUserId(data) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
      "Content-Type": "application/json",
    };

    let bodyContent = {
      rating: 3,
      followers: 0,
      following: 0,
      reviews: 0,
      views: 0,
      flicks: 0,
      languages: ["English"],
      name: data?.companyName ?? data?.name,
      categories: ["cat_kdik4uvKPxQPc1iG8Nmn4S"],
      refCode: null,
      weight: -1,
      rank: null,
      socialMedia: null,
      token: null,
      qrCodeUrl: null,
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fdefault_dp.png?alt=media&token=12a37164-51dc-48a8-9bf3-f008fa6a5113",
      refBy: null,
      joinDate: Date.now(),
      about: "",
      dob: null,
      verified: null,
      address: null,
      favorite: null,
      savedReviews: null,
      phone: null,
      id: "user_" + newUserId,
      lastLogin: Date.now(),
      email: data?.email,
      achievement: null,
      totalReviews: 0,
      gender: "male",
      tag: "@user_" + newUserId,
      coins: 0,
      totalCoins: 0,
    };

    let reqOptions = {
      url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);

    if (response.data?.newUser === false) {
      if (!data?.appUserId) {
        await updateDoc(doc(db, "brandAdmin", data.email), {
          appUserId: response.data.user.id,
        });
        return response.data.user?.id;
      }
    }
    if (response.data?.newUser === true) {
      await updateDoc(doc(db, "brandAdmin", data.email), {
        appUserId: response.data.user.id,
      });
      return response.data.user?.id;
    }
  }

  const signInByPopUp = () => {
    const provider = new GoogleAuthProvider();
    setGoogleLoginLoading(true);
    signInWithPopup(auth, provider)
      .then(async (res) => {
        if (res.user) {
          const docref = doc(db, strings.adminCollection, res.user.email);
          const docsnap = await getDoc(docref);

          if (!docsnap.exists()) {
            return showWarning("No linked account found");
          }
          var data = docsnap.data();
          if (!data?.appUserId) {
            const appUserId = await GetUserId(data);
            data.appUserId = appUserId;
          }
          if (!data?.limit) {
            const result = await updateLimit(data);
            data.limit = result;
          }
          if (data.role === "brandAdmin") {
            let headersList = {
              Accept: "*/*",
              Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
            };

            let reqOptions = {
              url: `https://brandapi-zscu3untuq-el.a.run.app/getBrand/${data?.brandId}`,
              method: "GET",
              headers: headersList,
            };

            let response = await axios.request(reqOptions);

            if (response.data.success === false) {
              setGoogleLoginLoading(false);
              showWarning(`No brand is associated with the login credentials`);
            } else {
              sessionStorage.setItem(
                "brand",
                JSON.stringify(response.data.brand)
              );
              dispatch({ type: "SET_BRAND", brand: response.data.brand });
              sessionStorage.setItem("user", JSON.stringify(data));
              dispatch({ type: "SET_USER", user: data });
              setGoogleLoginLoading(false);
              router.replace("/admin");
              return;
            }
          } else {
            setGoogleLoginLoading(false);
            showWarning("credential not valid");
          }
        } else {
          setGoogleLoginLoading(false);
          showWarning("credential not valid");
        }
      })
      .catch((error) => {
        setGoogleLoginLoading(false);
        console.log(error);
        return showWarning(error);
      });
  };
  async function validateLogin(event) {
    event.preventDefault();
    let email = emailRef.current.value.trim();
    let password = passwordRef.current.value.trim();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return showWarning("Please enter a valid email address");
    }
    const passwordRegex = /.{5,}/;
    if (!passwordRegex.test(password)) {
      return showWarning("Password must be at least 5 characters long");
    }
    signInByEmail(email, password);
  }
  return (
    <div className="bg-[#1A1A1A] h-screen">
      <div className="grid w-full h-full lg:grid-cols-2">
        {/* <div className="grid grid-cols-2 bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-full w-full"> */}
        <div className="relative items-center justify-center hidden w-full h-full lg:flex">
          <img
            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
            alt="logo"
            className="h-[50px]  transform scale-150 absolute left-24 top-10"
          />
          <video
            playsInline
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Flogin.mp4?alt=media&token=52fec1d6-7ce1-4ff8-9bf8-0e12640e053e"
            className="max-h-[380px]"
            loop
            muted
            autoPlay
          ></video>
        </div>
        <div className="h-full w-full bg-[#2B2F37] rounded-l-xl flex lg:items-center justify-center p-5">
          <div className="w-full md:w-96">
            <img
              src={require("../assets/logo/MyRevue Logo elite new.svg").default}
              alt="logo"
              className="h-[60px]   mx-auto mb-20 lg:hidden"
            />
            <p className="text-4xl text-center font-work lg:text-left">
              Welcome Back
            </p>
            <form onSubmit={validateLogin}>
              <div className="w-full mt-5">
                <input
                  type="text"
                  ref={emailRef}
                  autoComplete={false}
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Business Email Address"
                />
              </div>
              <div className="relative w-full mt-5 h-max">
                <div className="absolute right-0 flex items-center h-full cursor-pointer">
                  {ShowPassword === false ? (
                    <button
                      type="button"
                      onClick={() => setShowPassword(!ShowPassword)}
                    >
                      <Eye size={22} color="#8E8B8B" className="" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setShowPassword(!ShowPassword)}
                    >
                      <EyeSlash size={22} color="#8E8B8B" className="" />
                    </button>
                  )}
                </div>
                <input
                  type={ShowPassword === true ? "text" : "password"}
                  ref={passwordRef}
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Password"
                />
              </div>
              <div className="flex justify-end w-full mt-3">
                <button
                  type="button"
                  className="text-[#F92147] font-medium text-right font-work tracking-wide select-none cursor-pointer"
                  onClick={() => router.push("/forgotpassword")}
                >
                  Forgot Password
                </button>
              </div>
              <div className="w-full mt-3">
                <button
                  onClick={validateLogin}
                  type="submit"
                  className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md flex justify-center items-center"
                >
                  {loginLoading ? (
                    <CircleNotch
                      size={25}
                      weight="bold"
                      className="animate-spin"
                    />
                  ) : (
                    "Log in"
                  )}
                </button>
              </div>
            </form>
            <div className="w-full mt-3">
              <button
                onClick={signInByPopUp}
                className="flex items-center justify-center w-full h-12 gap-3 font-medium text-black bg-white rounded-md font-work"
                type="button"
              >
                {googleLoginLoading ? (
                  <CircleNotch
                    size={25}
                    weight="bold"
                    className="animate-spin"
                  />
                ) : (
                  <>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FGoogle__G__Logo.svg.png?alt=media&token=693d692a-9a33-4720-8a94-6e3a35810d8d"
                      alt="google"
                      className="w-8 h-8"
                    />
                    <p className="">Login with Google</p>
                  </>
                )}
              </button>
            </div>
            <div className="flex justify-center w-full gap-1 mt-3 tracking-wide font-work">
              Don’t have an account?
              <button
                className="text-[#F92147] font-medium tracking-wide"
                type="button"
                onClick={() => router.push("/signup")}
              >
                Create an account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
