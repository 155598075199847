const Challengedurations = {
  7: { duration: "1 Week", frequency: ["Daily", "Alternate Days"] },
  14: { duration: "2 Week", frequency: ["Daily", "Alternate Days", "Weekly"] },
  21: { duration: "3 Week", frequency: ["Alternate Days", "Weekly"] },
  30: {
    duration: "1 Month",
    frequency: ["Weekly", "15 Days"],
  },
  60: {
    duration: "2 Months",
    frequency: ["Weekly", "15 Days", "Monthly"],
  },
  90: { duration: "3 Months", frequency: ["Weekly", "15 Days", "Monthly"] },
};

const frequencies = {
  Daily: 1,
  "Alternate Days": 2,
  Weekly: 7,
  "15 Days": 15,
  Monthly: 30,
};

export { Challengedurations, frequencies };
