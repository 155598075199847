import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
  collection,
  addDoc,
} from "firebase/firestore";
import { Chat, ChatDots, Flag, Share, Code } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loadingdot from "../../components/loadingdot";
import Sharecard from "../../components/sharecard";
import Stickyheader from "../../components/stickyheader";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import { ArrowLeft } from "@phosphor-icons/react";
import { useHistory } from 'react-router-dom'

export default function Viewreview() {
  const { id } = useParams();
  const [{ brand }, dispatch] = useStateValue();
  const [loading, setloading] = useState(false);
  const [review, setreview] = useState({});
  const [share, setshare] = useState(false);
  const [embed, setembed] = useState(false);
  const [comment, setcomment] = useState(false);
  const [flag, setflag] = useState(false);
  const [flagtab, setflagtab] = useState(0);
  const [flagreason, setflagreason] = useState("");
  const [other, setother] = useState(false);
  const [comment_text, setcomment_text] = useState("");
  const [comment_success, setcomment_success] = useState(false);
  const [flag_success, setflag_success] = useState(false);
  const router = useHistory();

  useEffect(() => {
    fetchreview();
    sendAmplitudeData("viewing review, view review page", {
      reviewid: id,
    });
  }, []);

  async function fetchreview() {
    setloading(true);
    let headersList = {
      "Accept": "*/*",
      "Authorization": "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25"
    }

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getReview/" + id,
      method: "GET",
      headers: headersList,
    }

    let response = await axios.request(reqOptions);
    let data = response.data;
    setreview(data);
    setloading(false);
  }

  async function commentnow() {
    if (comment_text?.length < 3) {
      return alert("Please enter a comment of atleast 2-3 words");
    }
    if (review.comment.length >= 1) {
      return alert("Limit exceeded");
    }
    await updateDoc(doc(db, "review", id), {
      comment: arrayUnion({
        message: comment_text,
        time: Timestamp.now(),
      }),
    })
      .then(() => setcomment_success(true))
      .catch((err) => alert(err));
  }
  async function flagreview() {
    addDoc(collection(db, "reports"), {
      id: id,
      reason: flagreason,
      role: "brand",
      time: Timestamp.now(),
      createdby: brand?.id,
      status: "pending",
    })
      .then((res) => {
        // console.log("flag with id" + res.id);
        setflag_success(true);
        setflag(false);
        setflagreason("");
        setother(false);
      })
      .catch((err) => alert(err));
  }
  return (
    <div className="flex flex-col h-full">
      <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-2xl tracking-wide font-medium">
        <div className="flex items-center justify-center w-12 h-12 cursor-pointer" onClick={() => router.goBack()}>
          <ArrowLeft size={36} color="#ffffff" />
        </div>
        <p className="pl-5 first-letter:uppercase ">{review?.name}</p>
      </div>
      {loading ? (
        <Loadingdot />
      ) : (
        <div className="p-5">
          {share === true ? (
            <Sharecard
              setshare={setshare}
              url={"https://myrevue.app/viewReview/" + review?.type + "/" + review?.id}
              text={`Hey! Check out this review on MyRevue app. Get the best review before buying anything on MyRevue! download the app `}
            />
          ) : null}
          <div className="flex items-start w-full p-4 rounded-lg bg-bg_highlight gap-x-5">
            <div className="w-80 aspect-[9/16] bg-background rounded-lg">
              <video playsInline
                src={review?.reviewUrl}
                onClick={(e) =>
                  e.currentTarget.paused ? e.target.play() : e.target.pause()
                }
                className="cursor-pointer rounded-lg w-80 aspect-[9/16] object-cover object-right-top"
              >

              </video>
            </div>
            <div className="flex-1 w-full bg-bg_highlight">
              <div className="">
                <div className="mb-3 text-white">
                  <p className="font-work">ID</p>
                  <input
                    type="text"
                    defaultValue={review?.id}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Name</p>
                  <input
                    type="text"
                    defaultValue={review?.name}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Product Id</p>
                  <input
                    type="text"
                    defaultValue={review?.product?.id}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Product Name</p>
                  <input
                    type="text"
                    defaultValue={review?.product?.name}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Uploaded By</p>
                  <input
                    type="text"
                    defaultValue={review?.user?.name}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
              </div>
              <div className="flex my-5 gap-x-5">
                <button
                  onClick={() => setshare(true)}
                  className="rounded-md flex-row-reverse bg-background hover:bg-[#4611ea] h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                >
                  <p className="pt-1 ml-3">Share review</p>{" "}
                  <Share size={24} color="#ffffff" />
                </button>
                <button
                  onClick={() => setcomment(!comment)}
                  className="rounded-md flex-row-reverse bg-background hover:bg-[#4611ea] h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                >
                  <p className="pt-1 ml-3">Comment</p>{" "}
                  <ChatDots size={24} color="#ffffff" />
                </button>
                <button
                  onClick={() => setflag(!flag)}
                  className="rounded-md flex-row-reverse bg-background hover:bg-[#4611ea] h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                >
                  <p className="pt-1 ml-3">Report</p>{" "}
                  <Flag size={24} color="#ffffff" />
                </button>
                <button
                  onClick={() => setembed(!embed)}
                  className="rounded-md flex-row-reverse bg-background hover:bg-[#4611ea] h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                >
                  <p className="pt-1 ml-3">Embed</p>{" "}
                  <Code size={24} color="#ffffff" />
                </button>
              </div>
              {embed === true ? (
                <div className="">
                  <iframe
                    title="embed review"
                    src="https://carbon.now.sh/embed?bg=rgba%2874%2C144%2C226%2C1%29&t=night-owl&wt=none&l=htmlmixed&width=867.5&ds=false&dsyoff=20px&dsblur=68px&wc=true&wa=false&pv=0px&ph=0px&ln=true&fl=1&fm=Fira+Code&fs=17px&lh=189%25&si=false&es=2x&wm=false&code=%253C%21DOCTYPE%2520html%253E%250A%253Chtml%2520lang%253D%2522en%2522%253E%250A%253Chead%253E%250A%2520%2520%253Cmeta%2520charset%253D%2522UTF-8%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520http-equiv%253D%2522X-UA-Compatible%2522%2520content%253D%2522IE%253Dedge%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520name%253D%2522viewport%2522%2520content%253D%2522width%253Ddevice-width%252C%2520initial-scale%253D1.0%2522%2520%252F%253E%250A%2520%2520%253Ctitle%253EReview%253C%252Ftitle%253E%250A%253C%252Fhead%253E%250A%253Cbody%253E%250A%253Ciframe%2520%250A%2520%2520%2520%2520%2520%2520%2520%2520src%253D%2522https%253A%252F%252Fembed.myrevue.app%252Fembed%252Freview%252Freview_QK0Xy3b3Xg1HHlnq%2522%2520%2520%2520%2520%2520%2520%2520%2520%2520height%253D%2522532%2522%250A%2520%2520%2520%2520%2520%2520%2520%2520width%253D%2522300%2522%250A%2520%2520%2520%2520%2520%2520%2520%2520title%253D%2522Review%2522%250A%2520%2520%2520%2520%2520%2520%2520%2520%253E%250A%2520%253C%252Fiframe%253E%250A%253C%252Fbody%253E%250A%253C%252Fhtml%253E"
                    className="w-[868px] h-[680px] border-0 overflow-hidden transform scale-100"
                    sandbox="allow-scripts allow-same-origin"
                  ></iframe>
                </div>
              ) : null}
              {comment === true ? (
                <div className="border-t-[1px] border-[#f2f2f230] py-5">
                  <p className="text-xs text-[#808080]">Comment</p>
                  <input
                    type="text"
                    defaultValue={
                      review?.comment ? review?.comment[0]?.message : ""
                    }
                    disabled={comment_success === true ? true : false}
                    onChange={(event) => setcomment_text(event.target.value)}
                    placeholder="Enter comment for review"
                    className="w-full h-10 px-3 mt-2 text-sm font-normal tracking-wide text-white truncate rounded-md outline-none appearance-none bg-background"
                  />
                  {!review?.comment ? (
                    <button
                      onClick={() =>
                        comment_success === true ? commentnow() : null
                      }
                      className=" mt-5 bg-background hover:bg-[#4611ea] h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                    >
                      <p className="pt-1 mr-3">
                        {comment_success === true ? "Success" : "Submit"}
                      </p>
                    </button>
                  ) : null}
                </div>
              ) : null}
              {flag === true ? (
                <div className="border-t-[1px] border-[#f2f2f230] py-5">
                  <p className="text-xs text-[#808080]">Flag</p>
                  <div className="border-[1px] border-[#ececec30] mt-5">
                    <div className="border-[1px] border-[#ececec30] h-12 px-3 text-white flex items-center  text-sm font-light tracking-wide">
                      Why are you flagging this review?
                    </div>
                    <div className="">
                      {flagtab === 0 && (
                        <div className="p-5 text-[#808080]">
                          <div className="">
                            <p className="tracking-wide ">
                              Ask Viewers to update thier review before you flag
                              it.
                            </p>
                            <div className="px-5 text-sm">
                              <p className="mt-5">
                                Now buisness have to give reviewers a chance to
                                update thier review before thay can flag it for
                                these reasons:
                              </p>
                              <ul className="p-2">
                                <li>Personal information</li>
                                <li>Advertising or Promotional</li>
                                <li>About a different brand</li>
                              </ul>
                            </div>
                          </div>
                          <div className="mt-5">
                            <p className="tracking-wide">
                              Most flagged reviews stay online while we
                              investigate them
                            </p>
                            <p className="px-5 mt-5 text-sm">
                              As part of our trust promise to consumers and
                              buisness reviews flagged for any resons other than
                              Harmful or Illegal content will remain visible on
                              website while other Content Integrity Team
                              investigates them.
                            </p>
                          </div>
                          <button
                            onClick={() => setflagtab(1)}
                            className="bg-background hover:bg-[#4611ea] px-12 py-3 mt-3"
                          >
                            {" "}
                            Proceed
                          </button>
                        </div>
                      )}
                      {flagtab === 1 && (
                        <div className="">
                          <div className="px-5 py-3 ">
                            <p className="text-[#808080]">
                              Please choose a reason:
                            </p>
                          </div>
                          <div className="flex flex-col m-3 text-[#808080]">
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id1"
                                name="flag"
                                value="Harmful or illegal"
                                onChange={() =>
                                  setflagreason("Harmful or illegal")
                                }
                                onClick={() => setother(false)}
                              />
                              <label htmlFor="id1">Harmful or illegal</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id2"
                                name="flag"
                                value="Personal Information"
                                onChange={() =>
                                  setflagreason("Personal Information")
                                }
                                onClick={() => setother(false)}
                              />
                              <label htmlFor="id2">Personal Information</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id3"
                                name="flag"
                                value="Advertising or promotional"
                                onChange={() =>
                                  setflagreason("Advertising or promotional")
                                }
                                onClick={() => setother(false)}
                              />
                              <label htmlFor="id3">
                                Advertising or promotional
                              </label>
                            </div>
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id4"
                                name="flag"
                                value="Not based on genuine review"
                                onChange={() =>
                                  setflagreason("Not based on genuine review")
                                }
                                onClick={() => setother(false)}
                              />
                              <label htmlFor="id4">
                                Not based on genuine review
                              </label>
                            </div>
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id5"
                                name="flag"
                                value="Fake product"
                                onChange={() => setflagreason("Fake product")}
                                onClick={() => setother(false)}
                              />
                              <label htmlFor="id5">Fake product</label>
                            </div>
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id6"
                                name="flag"
                                value="Defaming brand/product"
                                onChange={() =>
                                  setflagreason("Defaming brand/product")
                                }
                                onClick={() => setother(false)}
                              />
                              <label htmlFor="id6">
                                Defaming brand/product
                              </label>
                            </div>
                            <div className="flex items-center gap-x-5">
                              <input
                                type="radio"
                                id="id7"
                                name="flag"
                                value="Other"
                                onClick={() => setother(true)}
                              />
                              <label htmlFor="id7">Other</label>
                            </div>
                            {other && (
                              <div className="">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setflagreason(e.target.value)
                                  }
                                  className="w-full h-10 px-5 mt-5 text-sm text-white outline-none appearance-none bg-background"
                                />
                              </div>
                            )}
                            <div className="pt-5">
                              {flag_success === true ? (
                                <button className="bg-[#4611ea] text-white px-12 py-3">
                                  Success
                                </button>
                              ) : (
                                <button
                                  onClick={() => flagreview()}
                                  className="bg-background hover:bg-[#4611ea] text-white px-12 py-3"
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
