import React, { lazy, Suspense } from 'react'
import { useState } from 'react'
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';
import { showWarning } from './components/toast/toast';
import Loadingdot from './components/loadingdot';
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));

export default function Feedback() {

    const [text, settext] = useState(null);
    const [name, setname] = useState(null);


    async function submit() {

        if (!name || name?.length < 3) {
            showWarning("Please add your name");
            return;
        }
        if (!text || text?.length < 20) {
            showWarning("Please add a longer feedback");
            return;
        }
        const document = {
            feedback: text,
            id: null,
            name: name,
            date: Date.now()
        }

        await addDoc(collection(db, "feedback"), document).then(res => {
            window.location.reload();
        })

    }
    return (
        <div className="bg-[#101010] ">
            <div className="bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-screen overflow-y-auto w-full">
                <Suspense fallback={<Loadingdot />}>
                    <NotLoginHeaderNew />
                    <div className="flex justify-center flex-1 w-full p-5 h-fit ">
                        <div className="w-full">
                            <div className="">
                                <h1 className="text-3xl font-semibold text-center text-white">Your feedback matters!</h1>
                                <p className="text-center text-[#8E8B8B]">Share your thoughts and help us serve you better.</p>
                            </div>
                            <div className="p-5 md:p-10 text-white bg-[#191919] w-full max-w-[540px] mx-auto mt-5 rounded-xl">
                                <div className="">
                                    <input placeholder='Full Name' onChange={(event) => setname(event.target.value)} className="bg-[#101010] rounded-lg placeholder:font-medium placeholder:text-[#8E8B8B] placeholder:tracking-wide  h-12 w-full appearance-none outline-none p-3 text-sm tracking-wide font-light" />
                                </div>
                                <div className="mt-5">
                                    <textarea placeholder='Feedback' onChange={(event) => settext(event.target.value)} className="bg-[#101010] rounded-lg placeholder:font-medium placeholder:text-[#8E8B8B] placeholder:tracking-wide  h-40 w-full appearance-none outline-none p-3 text-sm tracking-wide font-light"></textarea>
                                </div>
                                <div className="flex items-center justify-center">
                                    <button onClick={() => submit()} className=" mt-3 py-[10px] text-lg tracking-wide px-12 rounded-lg w-fit bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NotloginfooterNew />
                </Suspense>
            </div>
        </div>
    )
}
