import React, { useRef, Suspense, lazy } from 'react'
import { Checks, ShieldSlash } from '@phosphor-icons/react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';
import { useHistory } from "react-router-dom";
import { showSuccess, showWarning } from './components/toast/toast';
import Loadingdot from './components/loadingdot';
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));
export default function Servicereview() {
    let nameRef = useRef();
    let companyRef = useRef();
    let emailRef = useRef();
    let websiteRef = useRef();
    let countryCode = useRef();
    let phoneRef = useRef();
    const router = useHistory()


    async function submit() {
        const document = {
            name: nameRef.current.value,
            companyName: companyRef.current.value,
            businessEmail: emailRef.current.value,
            website: websiteRef.current.value,
            phone: countryCode.current.value + phoneRef.current.value,
            date: Date.now()
        }
        if (!document.name || !document.companyName || !document.businessEmail || !document.website || document.phone.length < 10) {
            showWarning("Please fill all details");
            return
        }

        await addDoc(collection(db, "serviceReviewRequest"), document).then(res => {
            showSuccess("We have received your request, we will reach out to you soon.");
            nameRef.current.value = null;
            companyRef.current.value = null;
            emailRef.current.value = null;
            websiteRef.current.value = null;
            countryCode.current.value = null;
            phoneRef.current.value = null;
            return;
        })
    }

    return (
        <div className="h-screen overflow-y-auto bg-[#101010]">
            <Suspense fallback={<Loadingdot />}>
                <NotLoginHeaderNew />
                <div className="grid px-5 mx-auto md:my-10 lg:grid-cols-2 md:px-10 xl:px-24 2xl:32">
                    <div className="flex flex-col justify-center">
                        <p className="font-semibold tracking-wide uppercase text-[#F92147]">Services REVIEWS</p>
                        <h1 className="text-3xl md:text-[40px] font-semibold leading-tight ">Your brand, amplified. Unforgettable conversations.</h1>
                        <p className="md:text-lg mt-2 tracking-wide font-medium text-[#8E8B8B]">
                            Collecting reviews on MyRevue allows current and future customers to learn about the experiences of other buyers in your business.
                        </p>
                        <div className="flex items-center w-full gap-5 mt-5">
                            <button
                                className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                                onClick={() => router.push("/signup")}>
                                Sign Up
                            </button>
                            <div className="relative pl-5 md:pl-0">
                                <button className="z-10 py-2 font-medium tracking-wide md:px-12" onClick={() => router.push("/signup")}>
                                    Try it for free
                                </button>
                                <img loading="lazy" src={require("./assets/icons/icons/hero section arrow.svg").default} alt="" className="absolute -ml-12 top-2" />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-end flex-1 w-full mt-24 md:mt-0">
                        <div className="w-full max-w-[450px] bg-[#171717] p-5 lg:p-10 rounded-xl">
                            <h1 className="text-xl font-semibold text-center">BOOK FREE DEMO</h1>
                            <div className="mt-3">
                                <div className="w-full gap-4 pt-3 space-y-3">
                                    <input type="text" ref={nameRef} className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full" placeholder="Full Name" />
                                    <input type="text" ref={companyRef} className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full" placeholder="Comapny Name" />
                                    <input type="text" ref={emailRef} className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full" placeholder="Business Email Address" />
                                    <input type="text" ref={websiteRef} className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full" placeholder="Website URL" />
                                    <div className="flex gap-1">
                                        <input type="text" ref={countryCode} value="+91" className="font-work w-[50px] bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none" placeholder="+91" />
                                        <input type="text" ref={phoneRef} className="flex-1 font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full" placeholder="Phone Number" />
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <button onClick={() => submit()} className=" mt-3 py-[10px] text-lg tracking-wide px-12 rounded-lg w-fit bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotloginfooterNew />
            </Suspense>
        </div >
    )
}
