import React from "react";
import { useStateValue } from "../context/StateProvider";

export default function DashboardCard({ text, value }) {
  return (
    <div className="bg-[#131418] shadow-sm shadow-gray-600 h-40 flex-1 rounded-md flex flex-col justify-between p-5 text-white">
      {/* <p className="font-extralight text-5xl text-[#9a9999]">{value}</p> */}
      <p className="text-5xl text-white font-extralight">{value}</p>
      <div className="">
        <p className="text-sm font-light tracking-wider">{text}</p>
      </div>
    </div>
  );
}
