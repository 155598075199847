import { InstagramLogo, UploadSimple } from "@phosphor-icons/react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Instagram from "./instagram";
import { useHistory } from "react-router-dom";
import Local from "./local";
import { useLocation } from "react-router-dom";

export default function Import() {
  const { path } = useRouteMatch();
  const router = useHistory();
  const location = useLocation();
  return (
    <div className="flex w-full h-screen">
      {/* <div className="h-screen overflow-hidden w-[200px] bg-[#1e1f25] p-[8px]">
        <p className="text-xs tracking-wide px-[12px] py-[6px]">Sources</p>
        <div className="space-y-2">
          <button className={`px-[12px] py-[8px] rounded-md bg-transparent  ${((location.pathname.includes("instagram")) || (location.pathname === ("/admin/import"))) ? "bg-[#131418]" : "hover:bg-[#131418]"} w-full flex items-center gap-3 text-xs tracking-wide`} onClick={() => router.push("/admin/import/instagram")}>
            <InstagramLogo color='white' size={16} />
            Instagram
          </button>
          <button
            className={`px-[12px] py-[8px] rounded-md bg-transparent  ${location.pathname.includes("local")
              ? "bg-[#131418]"
              : "hover:bg-[#131418]"
              } w-full flex items-center gap-3 text-xs tracking-wide`}
            onClick={() => router.push("/admin/import/local")}
          >
            <UploadSimple color="white" size={16} />
            Local
          </button>
        </div>
      </div > */}
      <div div className="flex-1 w-full h-screen overflow-y-auto" >
        <Switch>
          <Route path={`${path}`} exact component={Local} />
          <Route path={`${path}/instagram`} component={Instagram} />
          <Route path={`${path}/local`} component={Local} />
        </Switch>
      </div >
    </div >
  );
}
