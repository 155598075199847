import React, { useRef, useState, Suspense, lazy, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Play } from "@phosphor-icons/react";
import ReactVisibilitySensor from "react-visibility-sensor";
import Modalcard from "./components/modalcard";
import Loadingdot from "./components/loadingdot";
import { firstRowLogo, secondRowLogo, thirdRowLogo } from "./logo";
const HomeCarousel = lazy(() => import("./components/HomeCarousel"));
const LogoSlider = lazy(() => import("./components/logoSlider"));
const PlaylistFeed = lazy(() => import("./components/playlistFeed"));
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));
const ContactForm = lazy(() => import("./components/ContactForm"));
const FeaturedAt = lazy(() => import("./components/featuredAt"));

export default function LandingPage() {
  const router = useHistory();
  const swiperRef = useRef(null);
  const location = useLocation();
  const [showModal, setshowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePlaylist, setactivePlaylist] = useState([]);
  const shopablePlaylist = [
    {
      _id: "646f913c9778d3038927ef6b",
      id: "review_IsFQM_aANArl2OckzJTBy0Oj",
      name: "Indo Western Makeup Look ",
      coverImage: null,
      flickTag: "Tutorial Videos",
      views: 4,
      hearts: 1,
      rating: 3,
      reviewUrl:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicksF%2Freview_I4LYfEa8MnPe9Zla8Y4GxMf47.mp4?generation=1701365135738565&alt=media",
      dateAdded: 1685033276217,
      description: "Indo Western Makeup Look ",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_I4LYfEa8MnPe9Zla8Y4GxMf47.jpg?alt=media&token=6fa2bfb5-3eb8-4b25-bc46-2d353a808127",
      product: {
        _id: "6402090a9a9f7e3ec531cb23",
        name: "maybelline hypercurl mascara washable black",
        id: "pro_DBLZpdMX",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/product%2Fpro_DBLZpdMX.jpeg?alt=media&token=32188ab6-94b0-4eae-903a-b623211f7b67",
        affiliateLink: "https://amzn.to/3vfxBuN",
      },
      user: {
        _id: "646f304d0b5a22d0c1da02bf",
        id: "userDyS0P3B2nymq_09ew1n-ETLan",
        name: "Debasmita Chowdh",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/profileImage%2FuserDyS0P3B2nymq_09ew1n-ETLan.jpg?alt=media&token=b21d7108-d276-4fda-a03e-62b9f8d7d37c",
      },
    },
    {
      _id: "646f459f9778d3038927ef07",
      id: "review_EyjOjl9FFmrr4XI2409ywIQZ",
      name: "Top 6 Lipshades celebrities lovee to wear 💄",
      coverImage: null,
      flickTag: "Tutorial Videos",
      views: 2,
      hearts: 0,
      rating: 3,
      reviewUrl:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicksB%2Freview_U0vU2qYicwnbaMu-jO-lwgnbA.mp4?generation=1701523677404878&alt=media",
      dateAdded: 1685013919477,
      description: "Top 6 Lipshades celebrities lovee to wear 💄",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_U0vU2qYicwnbaMu-jO-lwgnbA.jpg?alt=media&token=9f68e865-ea53-4844-9f64-0dca7aaed03a",
      product: {
        _id: "64118f067fb1cac30a6fde1b",
        id: "pro__8au1AoE",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/product%2Fpro__8au1AoE.jpeg?alt=media&token=c775fc7b-7c78-463a-86b9-d66005ee18eb",
        name: "half n half lipstick",
        affiliateLink: "https://amzn.to/3le9XgH",
      },
      user: {
        _id: "646f304d0b5a22d0c1da02bf",
        id: "userDyS0P3B2nymq_09ew1n-ETLan",
        name: "Debasmita Chowdh",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/profileImage%2FuserDyS0P3B2nymq_09ew1n-ETLan.jpg?alt=media&token=b21d7108-d276-4fda-a03e-62b9f8d7d37c",
      },
    },
    {
      _id: "646313e4d37baeba4ab4391c",
      id: "review_fnXsi9KZq_3bj2r3yocinW-X",
      name: "bioderma atoderm cream and foaming gel . i just love their formulation. it keeps skin hydrated and mousturised .",
      coverImage: null,
      flickTag: "Tutorial Videos",
      views: 3,
      hearts: 1,
      rating: 3,
      reviewUrl:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicksH%2Freview_ELRgrdG4psOJOn9W2RbIDR-3V.mp4?generation=1707857845351195&alt=media",
      dateAdded: 1684214756012,
      description:
        "bioderma atoderm cream and foaming gel . i just love their formulation. it keeps skin hydrated and mousturised .",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_ELRgrdG4psOJOn9W2RbIDR-3V.jpg?alt=media&token=89d0acee-94d6-4dca-8368-7871e702aad3",
      product: {
        _id: "64118f067fb1cac30a6fdcc9",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/product%2Fpro_WDeJ7Pj_.jpeg?alt=media&token=b02331a4-805d-4d11-aa5d-80499bf1e977",
        name: "bioderma atoderm cream",
        id: "pro_WDeJ7Pj_",
        affiliateLink: "https://amzn.to/3vzdUyw",
      },
      user: {
        _id: "646215582bb2c3827d216bc8",
        id: "useryiGryzfsa4EEaNzEDaZSinTRn",
        name: "Raima Saha",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/profileImage%2FuseryiGryzfsa4EEaNzEDaZSinTRn.jpg?alt=media&token=5e6b785b-7d63-49dd-afee-3176e7786fb6",
      },
    },
    {
      _id: "64423f4b9ed82b83d2eb613a",
      id: "review_97j-KmWhFJR1Hsl_DYWzWfEQ",
      name: "This serum is enriched with Redensyl & Anagain. It helps in stopping hair fall, boosts hair growth and improves hair quality!\n\nworth trying 💙",
      coverImage: null,
      flickTag: "Tutorial Videos",
      views: 35,
      hearts: 5,
      rating: 3,
      reviewUrl:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicksA%2Freview_CaL_qBe-5t9MKZgqdSya6zyOK.mp4?generation=1707883537758726&alt=media",
      dateAdded: 1682063179014,
      description:
        "This serum is enriched with Redensyl & Anagain. It helps in stopping hair fall, boosts hair growth and improves hair quality!\n\nworth trying 💙",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_CaL_qBe-5t9MKZgqdSya6zyOK.jpg?alt=media&token=53b4ebbf-6940-46a0-a956-f18479769e47",
      product: {
        _id: "64118f067fb1cac30a6fe6c5",
        id: "pro_yDPPGVKM",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/product%2Fpro_yDPPGVKM.jpeg?alt=media&token=0144c2fe-0c4b-4211-b932-6687edfe328a",
        name: "pilgrim advanced hair growth serum",
        affiliateLink: "https://amzn.to/3xAEKam",
      },
      user: {
        _id: "642cf0ac10518b9ade725df2",
        id: "useraTIMOYBhkSa6HmI8SHM6ELcoM",
        name: "lakshmipriya ashok",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/profileImage%2FuseraTIMOYBhkSa6HmI8SHM6ELcoM.jpg?alt=media&token=8d0b7e9a-e6ab-4f7d-bd2e-8d346f3140a6",
      },
    },
    {
      _id: "64323ed4ecd4d038267f0d97",
      id: "review_Ds9IkFQ12ikIa0diSpdM-EYz",
      name: "This sunscreen is my favorite .It is very lightweight.",
      coverImage: null,
      flickTag: "Unboxing Videos",
      views: 8,
      hearts: 5,
      rating: 3,
      reviewUrl:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksD%2Freview_35C2SJj63UigqoJT3MsPUZcU0.mp4?alt=media&token=aa3941e3-30ec-45db-832f-4879d58bafb6",
      dateAdded: 1681014484477,
      description: "This sunscreen is my favorite .It is very lightweight.",
      thumbnail:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_35C2SJj63UigqoJT3MsPUZcU0.jpg?alt=media&token=ff5d1ce7-f205-4bf7-8cdd-e69a146c028e",
      product: {
        _id: "6402090a9a9f7e3ec531c57a",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/product%2Fpro_-8D47eZq.jpeg?alt=media&token=667b39f8-310a-4181-9c46-1c7a9dbe5c3d",
        id: "pro_-8D47eZq",
        name: "dot and key sunscreen",
        affiliateLink: "https://amzn.to/3GFDy9L",
      },
      user: {
        _id: "6401642f281272651f3df293",
        name: "ankita mishra",
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/profileImage%2Fuser_RM7NBLZj.jpg?alt=media&token=1757fbdd-fc2d-478c-89ef-71bdff5fb580",
        id: "user_RM7NBLZj",
      },
    },
  ];
  const [playlisttype, setplaylisttype] = useState("");
  const [from, setfrom] = useState("");

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      const documentDiv = document.getElementById("document");
      if (element && documentDiv) {
        // const offset = element.offsetTop - documentDiv.offsetTop;
        // documentDiv.scrollTo({ top: offset, behavior: 'smooth' });
        element.scrollIntoView();
      }
    } else {
      document
        .getElementById("document")
        .scrollTo({ top: 0, behavior: "smooth" });
    }

    var title = `Effortlessly get high quality videos for your brand ads trusted by 100+ brands.`;
    var metaTitle = `Effortlessly get high quality videos for your brand ads trusted by 100+ brands.`;
    var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

    var ogUrl = `https://brand.myrevue.app`;
    var ogtype = `website`;
    var ogtitle = `Effortlessly get high quality videos for your brand ads trusted by 100+ brands.`;
    var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FWebsite%20Clipart.png?alt=media&token=ee88344a-ee7e-4e58-82d3-c467bb28a480&_gl=1*eq5onc*_ga*NTIwNzI4NDUyLjE2ODk1MTczNzg.*_ga_CW55HF8NVT*MTY5NjMxNzE2OS43NS4xLjE2OTYzMTkzNzcuNTIuMC4w`;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app`;
    var twittertitle = `Effortlessly get high quality videos for your brand ads trusted by 100+ brands.`;
    var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FWebsite%20Clipart.png?alt=media&token=ee88344a-ee7e-4e58-82d3-c467bb28a480&_gl=1*eq5onc*_ga*NTIwNzI4NDUyLjE2ODk1MTczNzg.*_ga_CW55HF8NVT*MTY5NjMxNzE2OS43NS4xLjE2OTYzMTkzNzcuNTIuMC4w`;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, [location]);

  const Remoteimages = [
    "fully remote video 1.jpg",
    "fully remote 2.jpg",
    "fully remote 3.jpg",
  ];

  const shoppableVideos = [
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksH%2Freview_2VFPiDUE6nKcoL8Y4GyfzpvVR.mp4?alt=media&token=4cf281a9-4410-45de-ae03-456d2833e074",
      poster: "",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksL%2Freview_TreR006T-trgGiTv_YHQlNIRU.mp4?alt=media&token=41cc3b3a-3696-457d-932d-888a7327eedb",
      poster: "",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksJ%2Freview_wiMvuHlg34OMC1ip0OvtgRf0Y.mp4?alt=media&token=a399bb28-8b5f-46ae-98d1-8f7ba68603b1",
      poster: "",
    },
  ];
  const remoteVideos = [
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/review%2Freview_j6o-XOWLsoK4mm8TLL.mp4?generation=1673599840306806&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/thumbnails%2Freview_j6o-XOWLsoK4mm8TLL.jpg?alt=media&token=0d0fb15a-fc83-442f-aaea-bba9aed29e92",
    },
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/review%2Freview_rxKVVbyh81pRI0iMN2.mp4?generation=1673535034887462&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/thumbnails%2Freview_rxKVVbyh81pRI0iMN2.jpg?alt=media&amp;token=f8f15d40-85fb-440f-a1da-87b73bf7bb58",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/review%2Freview_u0VcQygRlhvvRyZvSg.mp4?alt=media&token=1a5f020b-ccc6-4e31-b969-5069a5406e79",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/thumbnails%2Freview_u0VcQygRlhvvRyZvSg.jpg?alt=media&token=e26eae82-7bb2-4d02-8c4f-aaaeaf45a717",
    },
  ];

  const flicksArray = [
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksA%2Freview_CaL_qBe-5t9MKZgqdSya6zyOK.mp4?alt=media&token=cc0f3992-a17d-4d1b-9f82-6739c1b3b438",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_CaL_qBe-5t9MKZgqdSya6zyOK.jpg?alt=media&token=53b4ebbf-6940-46a0-a956-f18479769e47",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksD%2Freview_35C2SJj63UigqoJT3MsPUZcU0.mp4?alt=media&token=cb59a45e-7a38-4071-9fa2-6089f6c054eb",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_35C2SJj63UigqoJT3MsPUZcU0.jpg?alt=media&token=ff5d1ce7-f205-4bf7-8cdd-e69a146c028e",
    },
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_9WC8KOkFyP3QPdD4q8.mp4?generation=1676134243791411&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_9WC8KOkFyP3QPdD4q8.jpg?alt=media&token=fe2a04e6-f8ca-4923-91f3-0ba076662abb",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksE%2Freview_219qSxB_KIriCVfpmsov5PKBc.mp4?alt=media&token=37e0578c-87ce-43fd-b0f6-b9c87fb8a88e",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_219qSxB_KIriCVfpmsov5PKBc.jpg?alt=media&token=f171286a-0a92-48f5-ada7-cb836b5283a3",
    },
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_fuzOoj7gC4rbPhXz_p.mp4?generation=1677135449934793&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_fuzOoj7gC4rbPhXz_p.jpg?alt=media&token=acd55324-7e91-4388-b044-44d8fc7b5f16",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_W02FYFhfAXg45EOFpV.mp4?alt=media&token=d91a6c03-0867-43ad-8d8c-2654a56064ae",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_W02FYFhfAXg45EOFpV.jpg?alt=media&token=66fea7a6-8ca9-4823-b166-ec4c9e5f44a7",
    },
    {
      video:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_ppsL7LRYG5VkcB93FM.mp4?alt=media&token=268435fc-6c68-4bcb-a699-ed1fe63a70d4",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_ppsL7LRYG5VkcB93FM.jpg?alt=media&token=860d6e6a-141f-4fb0-9356-1f35f5fd0eaf",
    },
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_lBDcirY9w6MhH1w9Ii.mp4?generation=1672050392882208&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_lBDcirY9w6MhH1w9Ii.jpg?alt=media&token=1056aa2d-82fa-4bbb-b0d9-c73db11c04ff",
    },
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_skbS98U1PBPnkcTGiz.mp4?generation=1670858902234562&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_skbS98U1PBPnkcTGiz.jpg?alt=media&token=c5c78128-d63d-44f2-ab3f-e15d43032172",
    },
    {
      video:
        "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_IEgSBWkICrQl4qliml.mp4?generation=1670856760085611&alt=media",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_IEgSBWkICrQl4qliml.jpg?alt=media&token=d7ac54b0-c4a5-4ef1-8a65-981180c47570",
    },
  ];

  return (
    <div className="h-screen overflow-y-auto bg-[#101010]" id="document">
      <Suspense fallback={<Loadingdot />}>
        {showModal === true && (
          <>
            <Modalcard close={setshowModal}>
              {from === "interactive" ? (
                <iframe
                  src="/embed/faqplayer/19j0i6o1rZsQsNQxDSFa/hR0WeL0M0FMv2HUa0gwM"
                  title="faqplayer"
                  // height="532"
                  // width="300"
                  className="w-full md:w-[380px] aspect-[9/16]"
                  style={{ borderRadius: "0.75rem" }}
                ></iframe>
              ) : (
                <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                  <div
                    className="w-full h-full overflow-y-auto rounded snap-y snap-mandatory scrollbar"
                    id="zero-scrollbar"
                  >
                    <style>
                      {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
                    </style>
                    {playlisttype === "reviews" ? (
                      <>
                        {activePlaylist?.map((video, index) => (
                          <PlaylistFeed
                            data={video}
                            key={index}
                            keyid={`playlist${index}`}
                            displayscroll={true}
                          />
                        ))}
                      </>
                    ) : (
                      <PlaylistFeed
                        data={
                          from === "remote"
                            ? remoteVideos?.[activeIndex]
                            : shopablePlaylist?.[activeIndex]
                        }
                        displayscroll={false}
                        showproduct={from === "remote" ? false : true}
                      />
                    )}
                  </div>
                </div>
              )}
            </Modalcard>
          </>
        )}
        <NotLoginHeaderNew />
        <div className="grid px-5 mx-auto md:my-10 md:flex md:px-10 xl:px-24 2xl:px-32">
          <div className="md:w-[65%]">
            <h1 className="text-4xl md:text-[55px] font-semibold leading-tight md:mt-16 text-center md:text-left">
              Level Up Your Business
              <span className="hidden md:inline">
                <br />
              </span>{" "}
              with MyRevue Elite!
            </h1>
            <p className="md:text-lg md:max-w-[70%] tracking-wide font-medium text-[#ABA3A3] text-center md:text-left">
              Build Trust, Skyrocket Sales! Say goodbye to the hassle of chasing
              video testimonials. Experience the ultimate testimonial solution
              that saves you time, money. Unlock success today with MyRevue
              Elite!
            </p>
            <div className="flex items-center justify-center w-full gap-5 mt-5 md:justify-start">
              <button
                className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
              <div className="relative pl-5 md:pl-0">
                <button
                  className="z-10 py-2 font-medium tracking-wide md:px-12"
                  onClick={() => router.push("/signup")}
                >
                  Try it for free
                </button>
                <img
                  loading="lazy"
                  src={
                    require("./assets/icons/icons/hero section arrow.svg")
                      .default
                  }
                  alt=""
                  className="absolute -ml-12 top-2"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center flex-1 w-full mt-24 md:mt-0">
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fhero-gif.gif?alt=media&token=b73af2f2-4eb1-4a33-a769-4bbf195fc6ec"
              alt=""
              className=""
            />
          </div>
        </div>
        <div className="px-5 mx-auto my-5 md:px-10 md:mt-20">
          <h2 className="text-[24px] lg:text-[32px] text-center font-semibold leading-tight mt-16">
            Trusted by 80+ brands
          </h2>
          <div className="space-y-0 md:space-y-5 ">
            <LogoSlider logos={firstRowLogo} />
            <LogoSlider logos={secondRowLogo} direction="right" />
            <LogoSlider logos={thirdRowLogo} />
          </div>
        </div>
        <div className="bg-[#171717]">
          <div className="w-full px-5 py-10 mx-auto my-10 md:px-10">
            <h2 className=" text-2xl md:text-[40px] text-center font-semibold leading-tight">
              Unlock Authentic User-Generated Content Solutions!
            </h2>
            <div className="grid md:grid-cols-3 gap-10 md:mt-16 max-w-[1400px] mx-auto">
              <ReactVisibilitySensor
                partialVisibility
                once
                offset={{ top: -20 }}
              >
                {({ isVisible }) => (
                  <div
                    className={`ugcCard mt-20 md:mt-32 ${
                      isVisible ? "fade-slide-up" : ""
                    }`}
                  >
                    <div className="w-full border-[3px] border-[#BAB1B1] transition-all hover:border-[#F92147] rounded-xl p-5 relative">
                      <div className="absolute h-20 w-40 bg-[#171717] left-1/2 -translate-x-1/2 -top-10 flex items-center justify-center">
                        <img
                          loading="lazy"
                          src={require("./assets/icons/icons/create task icon.png")}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="h-10"></div>
                      <p className="text-center text-[25px] font-semibold pb-3">
                        1. Create a task
                      </p>
                      <p className="text-center text-[17px] text-[#FFFFFFB8] tracking-wide">
                        Create a task for UGC creators in under a minute with
                        MyRevue Elite. Share your product details and select
                        your preferred video type, quality, and duration to get
                        started!
                      </p>
                    </div>
                  </div>
                )}
              </ReactVisibilitySensor>

              <ReactVisibilitySensor
                partialVisibility
                once
                offset={{ top: -20 }}
              >
                {({ isVisible }) => (
                  <div
                    className={`ugcCard mt-10 md:mt-0 ${
                      isVisible ? "fade-slide-up" : ""
                    }`}
                  >
                    <div className="w-full border-[3px] transition-all border-[#BAB1B1] hover:border-[#F92147] rounded-xl p-5 relative">
                      <div className="absolute h-20 w-40 bg-[#171717] left-1/2 -translate-x-1/2 -top-10 flex items-center justify-center">
                        <img
                          loading="lazy"
                          src={require("./assets/icons/icons/ship the product icon.png")}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="h-10"></div>
                      <p className="text-center text-[25px] font-semibold pb-3">
                        2. Ship the product
                      </p>
                      <p className="text-center text-[17px] text-[#FFFFFFB8] tracking-wide">
                        Ready to see what our UGC creators can do? Once you've
                        approved your favorites, simply ship your products to
                        them and watch the magic happen with MyRevue Elite.
                      </p>
                    </div>
                  </div>
                )}
              </ReactVisibilitySensor>

              <ReactVisibilitySensor
                partialVisibility
                once
                offset={{ top: -20 }}
              >
                {({ isVisible }) => (
                  <div
                    className={`ugcCard mt-10 md:mt-32 ${
                      isVisible ? "fade-slide-up" : ""
                    }`}
                  >
                    <div className="w-full transition-all border-[3px] border-[#BAB1B1] hover:border-[#F92147] rounded-xl p-5 relative">
                      <div className="absolute h-20 w-40 bg-[#171717] left-1/2 -translate-x-1/2 -top-10 flex items-center justify-center">
                        <img
                          loading="lazy"
                          src={require("./assets/icons/icons/enjoy the content icon.png")}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="h-10"></div>{" "}
                      <p className="text-center text-[25px] font-semibold pb-3">
                        3. Enjoy the content
                      </p>
                      <p className="text-center text-[17px] text-[#FFFFFFB8] tracking-wide">
                        With MyRevue Elite, custom-made UGC videos are the key
                        to scaling your business like crazy! Approve the content
                        or request edits, and receive your typical video in just
                        7-10 days!
                      </p>
                    </div>
                  </div>
                )}
              </ReactVisibilitySensor>
            </div>
          </div>
        </div>
        <div className="w-full px-10 mx-auto my-10 overflow-x-auto overflow-y-hidden custom-scrollbar-hide">
          <div className="grid md:grid-cols-2  max-w-[1400px] mx-auto my-10">
            <div className="flex justify-center w-full ">
              <ReactVisibilitySensor
                partialVisibility
                once
                offset={{ top: -20 }}
              >
                {({ isVisible }) => (
                  <div
                    className={`relative w-max md:ml-20 ${
                      isVisible ? "slide-to-right" : ""
                    }`}
                  >
                    <div className="z-50 flex-col items-center hidden font-semibold lg:absolute click_preview lg:flex">
                      <p className="text-2xl">Click for Preview</p>
                      <img
                        loading="lazy"
                        className={` rounded-xl  mt-5 pl-5 h-[90px]`}
                        src={
                          require("./assets/images/homepage/arrow-left-red.svg")
                            .default
                        }
                        alt="1"
                      />
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        setplaylisttype("interactive");
                        setfrom("interactive");
                        setshowModal(true);
                      }}
                    >
                      <div className="absolute z-50 w-full -translate-x-1/2 lg:hidden bottom-12 left-1/2">
                        <div className="py-2 px-10 text-black drop-shadow-lg shadow-black font-semibold w-fit mx-auto rounded-lg bg-[#EDF7ED]">
                          <p className="">Click for preview</p>
                        </div>
                      </div>
                      <div className="absolute z-50 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                          <Play size={34} color="white" weight="fill" />
                        </div>
                      </div>
                      <img
                        loading="lazy"
                        className={` rounded-xl  mt-5 max-w-[300px]`}
                        src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Finteractive.jpg?alt=media&token=7f899f70-b075-4536-ae79-4920bf902e75"
                        alt="1"
                      />
                    </div>
                    {/* <HomeCarousel
                    images={Interactiveimages}
                    setActiveVideoIndex={setActiveIndex}
                    setactivePlaylist={setactivePlaylist}
                    videos={interactiveVideos}
                    setshowModal={setshowModal}
                    from="interactive"
                    setfrom={setfrom}
                    setplaylisttype={setplaylisttype}
                  /> */}
                  </div>
                )}
              </ReactVisibilitySensor>
            </div>
            <ReactVisibilitySensor partialVisibility once>
              {({ isVisible }) => (
                <div
                  className={`interactiveVideos  w-full flex-1 md:flex order-first md:order-last md:flex-col md:items-end md:justify-center ${
                    isVisible ? "slide-to-left" : ""
                  }`}
                >
                  <p className="font-black text-[110px] text-[#FFFFFF36]">01</p>
                  <p className="-mt-16 text-5xl font-semibold">
                    Interactive Videos
                  </p>
                  <p className="mt-5 md:text-right text-[#ABA3A3] md:text-[18px] tracking-wide">
                    Experience a groundbreaking approach to engaging your
                    audience like never before. With our innovative branching
                    videos, viewers have the freedom to choose their own
                    adventure by answering questions and exploring options
                    tailored to their unique interests. Gone are the days of
                    passive viewing - our interactive videos transform the
                    educational process into an exciting and immersive
                    experience that resembles a captivating face-to-face
                    conversation.
                  </p>
                  <button
                    className="py-2 px-12 rounded-full mt-5  transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                    onClick={() => router.push("/signup")}
                  >
                    Sign Up
                  </button>
                </div>
              )}
            </ReactVisibilitySensor>
          </div>
        </div>
        <div className="bg-[#171717]">
          <div className="w-full px-5 pb-20 my-10 overflow-x-auto overflow-y-hidden md:px-10 md:py-10 custom-scrollbar-hide">
            <div className="grid md:grid-cols-2  max-w-[1400px] mx-auto my-10">
              <div className="md:flex md:items-center">
                <ReactVisibilitySensor partialVisibility once>
                  {({ isVisible }) => (
                    <div
                      className={` w-full flex-1  ${
                        isVisible ? "slide-to-right" : ""
                      }`}
                    >
                      <p className="font-black text-[110px] text-[#FFFFFF36]">
                        02
                      </p>
                      <p className="-mt-16 text-5xl font-semibold">
                        Shoppable Videos
                      </p>
                      <p className="mt-5 text-[#ABA3A3] md:text-[18px] tracking-wide">
                        Engage Customers and Boost Conversions with Integrated
                        Video Feeds and Stories on Your E-commerce Website.
                        Import videos effortlessly from TikTok and Instagram,
                        and tag products for an immersive in-video shopping
                        experience. Capture attention, drive sales, and
                        revolutionize your online store with captivating video
                        content.
                      </p>
                      <button
                        className="py-2 px-12 rounded-full mt-5 transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                        onClick={() => router.push("/signup")}
                      >
                        Sign Up
                      </button>
                    </div>
                  )}
                </ReactVisibilitySensor>
              </div>
              <div className="flex justify-center w-full ">
                <ReactVisibilitySensor
                  partialVisibility
                  once
                  offset={{ top: -20 }}
                >
                  {({ isVisible }) => (
                    <div
                      className={`relative w-max  ${
                        isVisible ? "slide-to-right" : ""
                      }`}
                    >
                      <div className="z-50 flex-col items-end hidden lg:absolute shopable_click_preview lg:flex ">
                        <p className="text-2xl">Click for Preview</p>
                        <img
                          loading="lazy"
                          className={` rounded-xl  mt-5 pr-12 h-[90px]`}
                          src={
                            require("./assets/images/homepage/arrow-right-red.svg")
                              .default
                          }
                          alt="1"
                        />
                      </div>

                      <img
                        loading="lazy"
                        className={` rounded-xl opacity-0 h-[80%]`}
                        src={require("./assets/images/homepage/Interactive video 1.png")}
                        alt="1"
                      />
                      <div className="absolute z-50 w-full -translate-x-1/2 lg:hidden bottom-12 left-1/2">
                        <div className="py-2 px-10 text-black drop-shadow-lg shadow-black font-semibold w-fit mx-auto rounded-lg bg-[#EDF7ED]">
                          <p className="">Click for preview</p>
                        </div>
                      </div>
                      <HomeCarousel
                        images={shopablePlaylist}
                        setActiveVideoIndex={setActiveIndex}
                        setactivePlaylist={setactivePlaylist}
                        videos={shoppableVideos}
                        setshowModal={setshowModal}
                        from="shopable"
                        setplaylisttype={setplaylisttype}
                        setfrom={setfrom}
                      />
                    </div>
                  )}
                </ReactVisibilitySensor>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-10 mx-auto my-10 overflow-x-auto overflow-y-hidden custom-scrollbar-hide">
          <div className="grid md:grid-cols-2  max-w-[1400px] mx-auto my-10">
            <div className="flex justify-center w-full">
              <ReactVisibilitySensor
                partialVisibility
                once
                offset={{ top: -20 }}
              >
                {({ isVisible }) => (
                  <div
                    className={`relative md:w-max md:ml-20 ${
                      isVisible ? "slide-to-right" : ""
                    }`}
                  >
                    <div className="z-50 flex-col items-center hidden font-semibold lg:absolute click_preview lg:flex">
                      <p className="text-2xl">Click for Preview</p>
                      <img
                        loading="lazy"
                        className={` rounded-xl  mt-5 pl-5 h-[90px]`}
                        src={
                          require("./assets/images/homepage/arrow-left-red.svg")
                            .default
                        }
                        alt="1"
                      />
                    </div>
                    <div className="absolute z-50 w-full -translate-x-1/2 lg:hidden bottom-12 left-1/2">
                      <div className="py-2 px-10 text-black drop-shadow-lg shadow-black font-semibold w-fit mx-auto rounded-lg bg-[#EDF7ED]">
                        <p className="">Click for preview</p>
                      </div>
                    </div>
                    <img
                      loading="lazy"
                      className={` rounded-xl opacity-0 h-[80%]`}
                      src={require("./assets/images/homepage/Interactive video 1.png")}
                      alt="1"
                    />
                    <HomeCarousel
                      images={Remoteimages}
                      setActiveVideoIndex={setActiveIndex}
                      setactivePlaylist={setactivePlaylist}
                      videos={remoteVideos}
                      setshowModal={setshowModal}
                      from="remote"
                      setplaylisttype={setplaylisttype}
                      setfrom={setfrom}
                    />
                  </div>
                )}
              </ReactVisibilitySensor>
            </div>
            <ReactVisibilitySensor partialVisibility once>
              {({ isVisible }) => (
                <div
                  className={`interactiveVideos  w-full flex-1 md:flex order-first md:order-last md:flex-col md:items-end md:justify-center ${
                    isVisible ? "slide-to-left" : ""
                  }`}
                >
                  <p className="font-black text-[110px] text-[#FFFFFF36]">03</p>
                  <p className="-mt-16 text-5xl font-semibold">
                    Fully Remote Videos
                  </p>
                  <p className="mt-5 md:text-right text-[#ABA3A3] md:text-[18px] tracking-wide">
                    Fully remote videos offer a seamless way to connect and
                    engage with audiences across the globe, regardless of
                    geographical barriers. With the power of technology,
                    businesses can produce high-quality videos without the need
                    for in-person interactions or elaborate production setups.
                    These videos enable effective communication, whether it's
                    for team collaboration, training sessions, webinars, or
                    product demonstrations. By leveraging remote video
                    capabilities, organizations can save time, reduce costs, and
                    reach a wider audience.
                  </p>
                  <button
                    className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br transition-all from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                    onClick={() => router.push("/signup")}
                  >
                    Sign Up
                  </button>
                </div>
              )}
            </ReactVisibilitySensor>
          </div>
        </div>
        <div className="bg-[#171717] ">
          <div className="w-full px-5 pt-10 pb-10 mx-auto my-10 md:px-10 md:pt-20 md:pb-20 ">
            <div className="flex flex-col items-center">
              <h3 className="md:w-[50%] text-center text-3xl md:text-[32px] font-semibold leading-tight">
                Gain a Clear Insight into Collected Reviews and Testimonials!
              </h3>
              <p className="md:w-[50%] text-center text-[#ABA3A3] mt-3 tracking-wide ">
                A video testimonial or review converts 25% more buyers than a
                written testimonial. Creating video testimonials and reviews is
                dramatically faster and easier with MyRevue Elite.
              </p>
            </div>
            <div className="grid gap-5 mt-10 md:grid-cols-3 md:gap-10 md:px-20 ">
              <ReactVisibilitySensor partialVisibility once>
                {({ isVisible }) => (
                  <div
                    className={`growthCard ${isVisible ? "fade-slide-up" : ""}`}
                  >
                    <div className="w-full p-[1.5em] lg:p-[2.5em] border-2 transition-all hover:border-[#F92147] rounded-lg">
                      <div className="flex items-end">
                        <p className="text-[24px]">Up to</p>
                        <p className="text-5xl pl-3 font-semibold text-[#F92147]">
                          25x
                        </p>
                      </div>
                      <div className="flex items-end justify-between">
                        <p className="text-2xl font-light">Increase in ROI</p>
                        <img
                          loading="lazy"
                          src={require("./assets/images/ROI.svg").default}
                          alt=""
                          className="h-32"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ReactVisibilitySensor>
              <ReactVisibilitySensor partialVisibility once>
                {({ isVisible }) => (
                  <div
                    className={`growthCard ${isVisible ? "fade-slide-up" : ""}`}
                  >
                    <div className="w-full p-[1.5em] lg:p-[2.5em] border-2 transition-all hover:border-[#F92147] rounded-lg">
                      <div className="flex items-end">
                        <p className="text-[24px]">Up to</p>
                        <p className="text-5xl pl-3 font-semibold text-[#F0DE7D]">
                          330%
                        </p>
                      </div>
                      <div className="flex items-end justify-between">
                        <p className="text-2xl font-light">
                          Increase in conversion rate
                        </p>
                        <img
                          loading="lazy"
                          src={
                            require("./assets/images/conversion.svg").default
                          }
                          alt=""
                          className="h-32"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ReactVisibilitySensor>
              <ReactVisibilitySensor partialVisibility once>
                {({ isVisible }) => (
                  <div
                    className={`growthCard ${isVisible ? "fade-slide-up" : ""}`}
                  >
                    <div className="w-full p-[1.5em] lg:p-[2.5em] border-2 transition-all hover:border-[#F92147] rounded-lg">
                      <div className="flex items-end">
                        <p className="text-[24px]">Up to</p>
                        <p className="text-5xl pl-3 font-semibold text-[#4611EA]">
                          5x
                        </p>
                      </div>
                      <div className="flex items-end justify-between">
                        <p className="text-2xl font-light">
                          Increased time on site
                        </p>
                        <img
                          loading="lazy"
                          src={
                            require("./assets/images/timeonsite.svg").default
                          }
                          alt=""
                          className="h-32"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ReactVisibilitySensor>
            </div>
            <div className="flex items-center justify-center mt-5">
              <button
                className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <div className="w-full px-5 mx-auto my-10 overflow-x-auto overflow-y-hidden md:px-10 md:py-10 md:pb-20 custom-scrollbar-hide">
          <div className="mx-auto md:w-2/3">
            <p className="text-3xl font-semibold text-center md:text-4xl ">
              See how the most innovative brands are using interactive videos!
            </p>
          </div>
          <ReactVisibilitySensor partialVisibility once>
            {({ isVisible }) => (
              <div className={`mt-10 ${isVisible ? "slide-to-left" : ""}`}>
                <Swiper
                  spaceBetween={50}
                  // slidesPerView={1}
                  // onSlideChange={() => console.log("slide change")}
                  // onSwiper={(swiper) => console.log(swiper)}
                  ref={swiperRef}
                  breakpoints={{
                    // when window width is >= 640px
                    640: {
                      width: 640,
                      slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                    964: {
                      width: 964,
                      slidesPerView: 3,
                    },
                  }}
                  navigation={{
                    nextEl: ".next-button",
                    prevEl: ".prev-button",
                  }}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {flicksArray.map((flick, index) => (
                    <SwiperSlide
                      key={index}
                      className="relative cursor-pointer"
                      onClick={() => {
                        setplaylisttype("reviews");
                        setactivePlaylist(flicksArray);
                        setActiveIndex(index);
                        setshowModal(true);
                        setTimeout(() => {
                          const videoid = document.getElementById(
                            `playlist${index}`
                          );
                          videoid.scrollIntoView();
                        }, 100);
                      }}
                    >
                      <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                          <Play size={34} color="white" weight="fill" />
                        </div>
                      </div>
                      <img
                        loading="lazy"
                        src={flick?.poster}
                        alt=""
                        className="w-full aspect-[9/16] object-cover rounded-xl"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </ReactVisibilitySensor>
        </div>
        <div className="p-5 -mt-10 -mb-5 lg:p-10">
          <div className="w-full p-5 bg-white rounded-lg lg:p-10">
            <p className="text-3xl font-semibold text-center text-black">
              Featured At
            </p>
            <FeaturedAt />
          </div>
        </div>
        <section
          className=" min w-full bg-[#171717] lg:grid grid-cols-2 gap-8 p-5"
          id="contact"
        >
          <div className="flex flex-col items-center justify-center flex-1 gap-5 py-3 lg:py-0 ">
            <p className="text-3xl md:text-[55px] font-semibold leading-tight text-center md:text-left">
              Blend Short Video Ads with the precision of Performance Marketing
            </p>
            <p className="md:text-lg tracking-wide font-medium text-[#ABA3A3] text-center md:text-left">
              Scale your business seamlessly with UGC Videos & Performance
              Marketing—short, impactful ads paired with expert strategies,
              delivered by India's Best Team for the results you desire.
            </p>
          </div>
          <div className="flex justify-center flex-1">
            <ContactForm />
          </div>
        </section>
        <NotloginfooterNew />
      </Suspense>
    </div>
  );
}
