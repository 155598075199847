import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import dayjs from "dayjs";
import React from "react";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

export default function CalendarHeader({
  monthIndex,
  setMonthIndex,
  setWeekIndex,
  weekIndex,
  currentView,
  setCurrentView,
  currentWeek,
}) {
  const today = Array.isArray(currentWeek) ? currentWeek[0] : dayjs();
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handlePrevWeek() {
    setWeekIndex(weekIndex - 1);
  }
  function handleNextWeek() {
    setWeekIndex(weekIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
    setWeekIndex(
      weekIndex === dayjs().week() ? weekIndex + Math.random() : dayjs().week()
    );
  }
  return (
    <header className="flex items-center justify-between gap-3 px-4 py-2">
      <div className="flex items-center gap-3">
        <button
          onClick={() => handleReset()}
          className="px-4 py-2 mr-5 border rounded border-[#80808057]"
        >
          Today
        </button>
        <button
          onClick={() =>
            currentView === "monthly" ? handlePrevMonth() : handlePrevWeek()
          }
          className="flex items-center justify-center w-12 h-12 rounded-md cursor-pointer bg-background"
        >
          <CaretLeft size={22} color="white" weight="bold" />
        </button>
        <button
          onClick={() =>
            currentView === "weekly" ? handleNextWeek() : handleNextMonth()
          }
          className="flex items-center justify-center w-12 h-12 rounded-md cursor-pointer bg-background"
        >
          <CaretRight size={22} color="white" weight="bold" />
        </button>
        <h2 className="ml-4 text-xl font-light text-white">
          {currentView === "monthly"
            ? dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")
            : today.format("MMMM YYYY")}
        </h2>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={() => setCurrentView("monthly")}
          className={`px-4 py-2 rounded ${
            currentView === "monthly"
              ? "bg-background"
              : "border  border-[#80808057]"
          }`}
        >
          Monthly
        </button>
        <button
          onClick={() => setCurrentView("weekly")}
          className={`px-4 py-2 rounded ${
            currentView === "weekly"
              ? "bg-background"
              : "border  border-[#80808057]"
          }`}
        >
          Weekly
        </button>
      </div>
    </header>
  );
}
