import AWN from "awesome-notifications";
import axios from "axios";
import { doc, updateDoc } from "firebase/firestore";
import moment from "moment";
import { CheckCircle, ShieldSlash } from "phosphor-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Stickyheader from "../components/stickyheader";
import { useStateValue } from "../context/StateProvider";
import { db } from "../firebase";
import { toast } from "react-toastify";
import { Checks } from "@phosphor-icons/react";
import { showSuccess, showWarning } from "../components/toast/toast";

export default function Billings() {
  const [{ user }, dispatch] = useStateValue();
  const [sub_type, setsub_type] = useState(0);
  const router = useHistory();
  const [fetchStatus, setfetchStatus] = useState(false);
  const [paymentLinkDetails, setpaymentLinkDetails] = useState({});
  const [paymentTokenDetails, setpaymentTokenDetails] = useState({});
  const [details, setdetails] = useState({
    name: user?.name ?? "",
    email: user?.email ?? "",
    phone: user?.phone ?? "",
    address: user?.address ?? "",
  });
  const [monthly, setmonthly] = useState(false);
  const [totalAmount, settotalAmount] = useState(0);
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);
  const [billing, setBilling] = useState({});

  useEffect(() => {
    if (fetchStatus === true) {
      const intervalId = setInterval(() => {
        fetchPaymentStatus();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [fetchStatus]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const successParam = urlParams.get("success");
    const sessionIdParam = urlParams.get("session_id");

    if (successParam === "true" && sessionIdParam) {
      verifyCheckoutSession(sessionIdParam);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (user?.subscription) {
      async function getSubscription() {
        let headersList = {
          Accept: "*/*",
        };

        let reqOptions = {
          url: `https://paymentapi-zscu3untuq-el.a.run.app/checkSubscriptionStatus/${user?.subscription?.customerId}`,
          method: "GET",
          headers: headersList,
        };

        let response = await axios.request(reqOptions);
        setBilling(response.data.data);
      }
      getSubscription();
    }
  }, []);

  async function fetchPaymentStatus() {
    const paymentStatusData = await axios.get(
      `https://prod-paymentapi-zscu3untuq-el.a.run.app/verifyPaymentToken/${paymentLinkDetails?.id}`
    );
    if (paymentStatusData?.data !== "") {
      if (paymentStatusData?.data?.payment_token?.status === "paid") {
        console.log(paymentStatusData);
        setpaymentTokenDetails(paymentStatusData?.data);
        setfetchStatus(false);
        updatePlan(paymentStatusData?.data);
        return;
      }
    }
  }

  useEffect(() => {
    calculateCharge();
  }, [sub_type, monthly]);

  async function calculateCharge() {
    let totalPrice = 0;
    if (monthly === true) {
      if (sub_type === 1) {
        totalPrice = 0;
      } else if (sub_type === 2) {
        totalPrice = 999;
      } else if (sub_type === 3) {
        totalPrice = 2499;
      }
    } else {
      if (sub_type === 1) {
        totalPrice = 0;
      } else if (sub_type === 2) {
        totalPrice = 9999;
      } else if (sub_type === 3) {
        totalPrice = 24999;
      }
    }
    settotalAmount(totalPrice);
  }

  async function callcashfree() {
    if (details?.name === "") {
      return showWarning("Please enter your name");
    }
    if (details?.email === "") {
      return showWarning("Please enter your email");
    }
    if (details?.phone?.length < 10) {
      return showWarning("Please enter valid phone number");
    }
    if (details?.address === "") {
      return showWarning("Please enter your address");
    }

    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let bodyContent = {
      name: details?.name,
      email: details?.email,
      phoneNumber: details?.phone,
      invoiceId: Date.now(),
      currency: "INR",
      amount: totalAmount,
      udf: {
        productName: sub_type === 2 ? "Basic" : "Standard",
        quantity: 1,
      },
    };

    let reqOptions = {
      url: "https://prod-paymentapi-zscu3untuq-el.a.run.app/createPaymentToken",
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };
    try {
      let response = await axios.request(reqOptions);
      setpaymentLinkDetails(response.data);
      triggerLayer(response.data);
      setfetchStatus(true);
    } catch (error) {
      showWarning(error.message);
    }
    function triggerLayer(data) {
      window.Layer.checkout(
        {
          token: data?.id,
          // accesskey: "2ee70b30-b33f-11ed-8323-af62a19e9aa0",
          accesskey: "785ad3c0-e970-11ed-aa1c-7161138d8d0f",
        },
        function (response) {
          if (response?.status === "failed") {
            showWarning(
              "Something wrong happend, please fill details again to proceed"
            );
            setfetchStatus(false);
          }
          if (response?.status === "pending") {
            showWarning(
              "Payment is in pending, if amount is deducted from bank, it will get refunded within 24-48 hours."
            );
            setfetchStatus(false);
            return;
          }
          if (response !== null || response.length > 0) {
            if (response.payment_id !== undefined) {
              return;
            }
          }
        },
        function (err) {
          setfetchStatus(false);
          alert(err.message);
          return;
        }
      );
    }

    return;
  }

  async function downgradePlan() {
    showWarning("To downgrade subscription, please contact support team");
  }

  async function getTodayDate() {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  function getDateAfterOneYear() {
    const today = new Date();
    const nextYear = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    );
    const day = nextYear.getDate().toString().padStart(2, "0");
    const month = (nextYear.getMonth() + 1).toString().padStart(2, "0");
    const year = nextYear.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  // Function to return date after one month
  function getDateAfterOneMonth() {
    const today = new Date();
    const nextMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    const day = nextMonth.getDate().toString().padStart(2, "0");
    const month = (nextMonth.getMonth() + 1).toString().padStart(2, "0");
    const year = nextMonth.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  async function updatePlan(token) {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    const subscription = {
      // userDetails: details,
      paymentDate: formattedDate,
      currentPlan:
        (sub_type === 1 && "Freemium") ||
        (sub_type === 2 && "Basic") ||
        (sub_type === 3 && "Standard"),
      validTill:
        monthly === false ? getDateAfterOneYear() : getDateAfterOneMonth(),
      paymentId: sub_type === 1 ? "freemium" : token.id,
      subId: sub_type === 1 ? Date.now() : token.payment_token.mtx,
      orderId: sub_type === 1 ? Date.now() : token.payment_token.mtx,
    };
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
      "Content-Type": "application/json",
    };

    let bodyContent = {
      email: details?.email,
      receiptId: token?.payment_token?.mtx,
      amountPaid: token?.payment_token?.amount,
      datePaid: formattedDate,
      productName: sub_type === 2 ? "Basic" : "Standard",
      quantity: 1,
      total: token?.payment_token?.amount,
      payMethod: token?.vpa === null ? "Card / Net Banking" : "upi",
    };

    let reqOptions = {
      url: "https://emailautomation-zscu3untuq-el.a.run.app/payment-confirmation-mail",
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };

    await axios.request(reqOptions);
    await updateDoc(doc(db, "brandAdmin", user?.email), { subscription })
      .then((res) => {
        sessionStorage.setItem(
          "user",
          JSON.stringify({ ...user, subscription })
        );
        dispatch({ type: "SET_USER", user: { ...user, subscription } });
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  async function createCheckoutSession() {
    const planType =
      (sub_type === 2 && monthly === true && "basicMonthly") ||
      (sub_type === 2 && monthly === false && "basicYearly") ||
      (sub_type === 3 && monthly === true && "standardMonthly") ||
      (sub_type === 3 && monthly === false && "standardYearly");
    if (user?.subscription) {
      await manageBilling();
      return;
    }
    try {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let bodyContent = {
        planId: planType,
        customer_email: user?.email ?? null,
        customerId: user?.subscription?.customerId ?? null,
        customer_name: user?.name,
      };

      let reqOptions = {
        url: "https://paymentapi-zscu3untuq-el.a.run.app/checkoutSubscription",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);
      if (response.data.success === true) {
        setCheckoutSessionId(response.data.session.id);
        window.location.replace(response.data.session.url);
        showSuccess("Checkout session created successfully");
      } else {
        showWarning("Something went wrong");
      }
    } catch (error) {
      showWarning(error.message);
    }
  }
  async function verifyCheckoutSession(sessionId) {
    console.log("Payment success!", sessionId);
    try {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let bodyContent = {
        sessionId: sessionId,
      };

      let reqOptions = {
        url: "https://paymentapi-zscu3untuq-el.a.run.app/verifyCheckoutSession",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      const response = await axios.request(reqOptions);
      console.log(response.data);
      if (response.data.success === true) {
        console.log("yes");
        const subscription = {
          subscriptionId: response.data?.session?.subscription,
          customerId: response.data?.session?.customer,
          id: response.data?.session?.id,
          ...response.data.subscription,
        };
        const limits = {
          downloadLimit:
            (subscription?.plan === "Basic" && 50) ||
            (subscription?.plan === "Standard" && 5000),
          commentLimit:
            (subscription?.plan === "Basic" && 25) ||
            (subscription?.plan === "Standard" && 100),
          campaignLimit:
            (subscription?.plan === "Basic" && 25) ||
            (subscription?.plan === "Standard" && 50),
        };
        await updateDoc(doc(db, "brandAdmin", user?.email), {
          subscription,
          ...limits,
        })
          .then((res) => {
            console.log("done");
            sessionStorage.setItem(
              "user",
              JSON.stringify({ ...user, subscription, ...limits })
            );
            dispatch({ type: "SET_USER", user: { ...user, subscription } });
            router.replace("/admin/billing");
            window.location.reload();
          })
          .catch((err) => console.log(err));
        showSuccess("Checkout session verified successfully");
      } else {
        showWarning("Something went wrong");
      }
    } catch (error) {
      showWarning(error.message);
    }
  }

  async function manageBilling() {
    try {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        customerId: user?.subscription?.customerId,
      });

      let reqOptions = {
        url: "https://paymentapi-zscu3untuq-el.a.run.app/manageBilling",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);
      window.open(response.data.data.url);
    } catch (error) {}
  }

  return (
    <div className="bg-[#2E3036] min-h-screen">
      <div className="p-5">
        <p className="text-3xl font-medium tracking-wide text-white">Billing</p>
      </div>
      <div className="p-5 w-full mx-auto">
        <p className="text-3xl text-center font-medium text-[#e0e0e0] tracking-wider">
          Build your subscription
        </p>
        <p className="text-xl text-center  text-[#e0e0e0] font-medium tracking-wider my-5">
          Choose a plan which suits your buisness needs.
        </p>

        {sub_type === 0 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#D9D9D9] h-14 p-[2px] rounded-full w-fit flex items-center text-black ">
                <button
                  onClick={() => setmonthly(true)}
                  className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${
                    monthly === true ? "bg-[#131418] text-white" : null
                  } rounded-full`}
                >
                  <p className=""> Billed monthly</p>
                </button>
                <button
                  onClick={() => setmonthly(false)}
                  className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${
                    monthly === false ? "bg-[#131418] text-white" : null
                  } rounded-full`}
                >
                  <p className="">Billed annually</p>
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-center w-full gap-10 mt-10">
              <div className="bg-[#131418]  p-6 w-full max-w-[310px] rounded-[20px] mt-8 h-max">
                <div className="h-11 w-full rounded-full bg-[#2E3036] flex items-center justify-center">
                  <p className="text-xl text-center ">Freemium</p>
                </div>
                <p className="mt-5 text-center ">
                  <span className="text-2xl">₹0/</span>{" "}
                  {monthly === true ? "month" : "year"} for 1 users
                </p>
                <ul className="mt-5 space-y-3 font-light tracking-wider">
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    Publish Unlimited Reviews
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    Download Upto 3 Videos
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    1 Campaign
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    1 User
                  </li>
                </ul>
                {!user?.subscription ? (
                  <button
                    className="bg-[#444444] hover:bg-[#ffffff] hover:text-black hover:font-normal h-10 mt-4 w-full rounded-md text-white font-light tracking-wider"
                    onClick={() =>
                      !user?.subscription ? null : downgradePlan()
                    }
                  >
                    {!user?.subscription ||
                    user?.subscription?.currentPlan === "Freemium"
                      ? "Current Plan"
                      : "Select Plan"}
                  </button>
                ) : (
                  <button
                    className="bg-[#444444] hover:bg-[#ffffff] hover:text-black hover:font-normal h-10 mt-4 w-full rounded-md text-white font-light tracking-wider"
                    onClick={() => manageBilling()}
                  >
                    Manage Billing
                  </button>
                )}
              </div>
              <div className="bg-[#131418] p-6  w-full max-w-[310px] rounded-[20px] mt-8  relative h-max">
                <div className="h-11 w-full rounded-full bg-[#2E3036] flex items-center justify-center">
                  <p className="text-xl text-center ">Popular</p>
                </div>
                <p className="p-2 mt-5 font-normal tracking-wide text-center">
                  Everything in Free, plus...
                </p>
                <p className="text-center">
                  <span className="text-2xl">
                    ₹{monthly === true ? "999" : "9999"}/
                  </span>{" "}
                  {monthly === true ? "month" : "year"} for 3 users
                </p>
                <ul className="mt-5 space-y-3 font-light tracking-wider">
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    Download upto 50 Videos
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    25 Campaigns
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    3 Users
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    Api Integration
                  </li>
                </ul>
                {!user?.subscription ? (
                  <button
                    className="bg-[#444444] hover:bg-[#ffffff] hover:text-black hover:font-normal h-10 mt-4 w-full rounded-md text-white font-light tracking-wider"
                    onClick={() =>
                      user?.subscription?.plan === "Basic"
                        ? null
                        : setsub_type(2)
                    }
                  >
                    {user?.subscription?.plan === "Basic"
                      ? "Current Plan"
                      : "Select Plan"}
                  </button>
                ) : (
                  <button
                    className="bg-[#444444] hover:bg-[#ffffff] hover:text-black hover:font-normal h-10 mt-4 w-full rounded-md text-white font-light tracking-wider"
                    onClick={() => manageBilling()}
                  >
                    Manage Billing
                  </button>
                )}
              </div>
              <div className="bg-[#131418] p-6  w-full max-w-[310px] rounded-[20px] mt-8 h-max">
                <div className="h-11 w-full rounded-full bg-[#2E3036] flex items-center justify-center">
                  <p className="text-xl text-center ">Standard</p>
                </div>
                <p className="pb-2 mt-5 text-center">
                  Everything in Basic, plus...
                </p>
                <p className="text-center">
                  <span className="text-2xl">
                    ₹{monthly === true ? "2499" : "24999"}/
                  </span>{" "}
                  {monthly === true ? "month" : "year"} for 5 users
                </p>
                <ul className="mt-5 space-y-3 font-light tracking-wider">
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    Download Unlimited
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    Upto 50 Campaigns
                  </li>
                  <li className="flex items-center gap-x-2">
                    {/* <CheckCircle size={22} color="#03A300" weight="fill" /> */}
                    5 Users
                  </li>
                </ul>
                {!user?.subscription ? (
                  <button
                    className="bg-[#444444] hover:bg-[#ffffff] hover:text-black hover:font-normal h-10 mt-4 w-full rounded-md text-white font-lighttracking-wider"
                    onClick={() =>
                      user?.subscription?.plan === "Standard"
                        ? null
                        : setsub_type(3)
                    }
                  >
                    {user?.subscription?.plan === "Standard"
                      ? "Current Plan"
                      : "Select Plan"}
                  </button>
                ) : (
                  <button
                    className="bg-[#444444] hover:bg-[#ffffff] hover:text-black hover:font-normal h-10 mt-4 w-full rounded-md text-white font-light tracking-wider"
                    onClick={() => manageBilling()}
                  >
                    Manage Billing
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {sub_type !== 0 && (
          <>
            <div className="">
              <div className="w-full bg-[#131418] rounded-lg p-5 mt-10">
                <div className="flex items-start justify-between">
                  <div className="text-2xl">
                    {sub_type === 1 && <p className="">Freemium</p>}
                    {sub_type === 2 && <p className="">Basic</p>}
                    {sub_type === 3 && <p className="">Standard</p>}
                  </div>
                  <div className="">
                    from{" "}
                    <span className="text-[#EA335F] tracking-wide">
                      ₹{sub_type === 1 && "0"}
                      {sub_type === 2 && monthly === true && "999"}
                      {sub_type === 2 && monthly === false && "9999"}
                      {sub_type === 3 && monthly === true && "2499"}
                      {sub_type === 3 && monthly === false && "24999"}/
                      {monthly === true ? "monthly" : "yearly"}
                    </span>
                  </div>
                </div>
                <div className="h-3"></div>
                <div>
                  {sub_type === 1 && (
                    <p className="">The perfect way to get started</p>
                  )}
                  {sub_type === 2 && (
                    <p className="">
                      The perfect way to support and engage your customers
                      across the lifecycle.
                    </p>
                  )}
                  {sub_type === 3 && (
                    <p className="">
                      The perfect way to grow and scale your buisness
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full">
                {(!user?.subscription || user?.subscription?.plan) && (
                  <button
                    className="bg-[#121212] h-10 w-fit mt-4 px-8 rounded-md text-white font-light tracking-wider"
                    onClick={() => setsub_type(0)}
                  >
                    Change Plan
                  </button>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <button
                className={`bg-[#121212] text-white h-10 w-fit mt-4 px-8 rounded-md `}
                onClick={() => createCheckoutSession()}
              >
                {" "}
                {fetchStatus === true ? (
                  <span id="loading"></span>
                ) : (
                  "Proceed for payment"
                )}
              </button>
            </div>
          </>
        )}
      </div>

      {sub_type === 0 && (
        <div className="p-10 w-full max-w-[1280px] mx-auto border-t border-[#80808040]">
          <div className="w-full bg-[#131418] rounded-lg p-8 tracking-wide ">
            <div className="flex justify-between w-full">
              <p className="text-2xl font-work">Current Plan :</p>
              <p className="pl-5 text-2xl font-work">
                {!user?.subscription && "Freemium"} {billing?.plan}
              </p>
            </div>
            <div className="flex justify-between w-full mt-3">
              <p className="font-work ">Recurring :</p>
              <p className="pl-5 font-work first-letter:uppercase">
                {!user?.subscription && "None"}{" "}
                {user?.subscription?.interval && `${billing?.interval}ly`}
              </p>
            </div>
            <div className="flex justify-between w-full ">
              <p className="font-work ">Subscription start date :</p>
              <p className="pl-5 font-work">
                {!user?.subscription && user?.dateJoined
                  ? moment(user?.dateJoined).format("DD/MM/YYYY")
                  : "Not available"}{" "}
                {billing?.subscriptionPeriodStart}
              </p>
            </div>
            <div className="flex justify-between w-full">
              <p className="font-work">Subscription valid till :</p>
              <p className="pl-5 font-work">
                {!user?.subscription
                  ? "Forever"
                  : billing?.subscriptionPeriodEnd}
              </p>
            </div>
            {billing?.canceled_at !== null && (
              <div className="flex justify-between w-full">
                <p className="font-work">Plan cancels at :</p>
                <p className="pl-5 font-work">
                  {billing.subscriptionPeriodEnd}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
