import { CircleNotch, Package, Truck, X } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react'
import Modalcard from '../../../components/modalcard';
import { showWarning } from '../../../components/toast/toast';
import axios from 'axios';
import { cachedAPICalls } from '../../../common/api';
import { APiTokens, apiHost } from '../../../common/Strings';
import { applicantMatchScoreForCampaign } from '../../../videoCampaign/huntFunctions';

export default function Delivery({ campaign, id }) {
    const [campaignApplication, setcampaignApplication] = useState([]);
    const [productDeliveryTab, setproductDeliveryTab] = useState(0);
    const [shipmentModal, setshipmentModal] = useState(false);
    const [applicationDetails, setapplicationDetails] = useState({});
    const [shippmentDetails, setshippmentDetails] = useState({
        withTracking: null,
        trackingNumber: "",
        shippingCompany: "",
        orderId: "",
        shippingDate: "",
    });
    const [selectedContent, setselectedContent] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        async function getCampaignApplications() {
            let count = 0;
            const response = await cachedAPICalls(
                `${apiHost.brandAdmin}/getAllInterestedUser/${campaign?._id}`,
                { headers: { Authorization: APiTokens.brandAdmin } }
            );
            response?.data.forEach(async (application, index) => {
                application.score = applicantMatchScoreForCampaign(campaign, application.user);
            });
            response?.data?.forEach((application, index) => {
                if (application?.status !== "pending") {
                    count += 1;
                }
            });
            setcampaignApplication(response?.data ?? []);
            setselectedContent(count || 0);
            setIsLoading(false);
        }
        getCampaignApplications()
    }, [])
    async function formateShippingDate(value) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear());
        const formattedDate = `${day}/${month}/${year}`;
        setshippmentDetails({
            ...shippmentDetails,
            shippingDate: formattedDate,
        });
    }
    async function changeShipmentStatus(application, status) {
        if (status === "Ready") {
            if (shippmentDetails?.withTracking === null) {
                showWarning(`Please select using shipment number or without shipment number`);
                return;
            }

            if (shippmentDetails?.withTracking === true) {
                if (
                    shippmentDetails?.orderId.length < 1 ||
                    shippmentDetails?.shippingCompany.length < 3 ||
                    shippmentDetails?.shippingDate.length < 5 ||
                    shippmentDetails?.trackingNumber.length < 5
                ) {
                    showWarning(`Please fill all details`);
                    return;
                }
            }
        }

        let headersList = {
            Accept: "*/*",
            Authorization:
                "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
        };

        if (status === "shipped") {
            let bodyContent = {
                userId: application?.user?.id,
                status: "Ready",
                shipmentDetails: {
                    shipmentStatus: status,
                    shipmentTrackingNumber:
                        shippmentDetails?.withTracking === false
                            ? "N/A"
                            : shippmentDetails?.trackingNumber,
                    shipmentCompany:
                        shippmentDetails?.withTracking === false
                            ? "N/A"
                            : shippmentDetails?.shippingCompany,
                    orderId:
                        shippmentDetails?.withTracking === false
                            ? "N/A"
                            : shippmentDetails?.orderId,
                    shipmentDate:
                        shippmentDetails?.withTracking === false
                            ? "N/A"
                            : shippmentDetails?.shippingDate,
                },
            };
            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
                method: "PUT",
                headers: headersList,
                data: bodyContent,
            };
            await axios.request(reqOptions).then((response) => {
                if (response?.data?.success === true) {
                    setcampaignApplication((prevData) => {
                        return prevData.map((obj) => {
                            if (obj._id === application?._id) {
                                return { ...obj, shipmentDetails: bodyContent.shipmentDetails };
                            } else {
                                return obj;
                            }
                        });
                    });
                }
            });
        }
        if (status === "delivered") {
            let bodyContent = {
                userId: application?.user?.id,
                status: "Ready",
                "shipmentDetails.shipmentStatus": status,
            };
            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
                method: "PUT",
                headers: headersList,
                data: bodyContent,
            };
            await axios.request(reqOptions).then((response) => {
                if (response?.data?.success === true) {
                    setcampaignApplication((prevData) => {
                        return prevData.map((obj) => {
                            if (obj._id === application?._id) {
                                return {
                                    ...obj,
                                    shipmentDetails: {
                                        ...obj.shipmentDetails,
                                        shipmentStatus: status,
                                    },
                                };
                            } else {
                                return obj;
                            }
                        });
                    });
                }
            });
        }

        setshippmentDetails({
            withTracking: null,
            trackingNumber: "",
            shippingCompany: "",
            orderId: "",
            shippingDate: "",
        });
        setshipmentModal(false);
        setapplicationDetails({});
    }
    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center w-full h-full">
                <CircleNotch size={22} color='white' className='animate-spin' />
                <p>Loading applications...</p>
            </div>
        )
    }
    return (
        <>
            {shipmentModal === true ? (
                <Modalcard close={setshipmentModal}>
                    <div
                        className="w-[380px] p-5 bg-[#2e313e]  overflow-y-auto rounded-md"
                        id="thin-scrollbar"
                    >
                        <div className="flex items-center justify-between">
                            <p className="text-lg tracking-wide font-work">
                                Provide shipping details
                            </p>
                            <button className="" onClick={() => setshipmentModal(false)}>
                                <X size={28} color="white" />
                            </button>
                        </div>
                        <div className="py-5">
                            <div className="pb-3">
                                <p className="font-medium tracking-wide">
                                    Shipping to {selectedContent?.user?.name}
                                </p>
                            </div>
                            <div className="my-3">
                                <label className="flex items-center gap-5 font-work">
                                    <input
                                        type="radio"
                                        name="answer"
                                        value="yes"
                                        onChange={(event) =>
                                            event.currentTarget.checked === true
                                                ? setshippmentDetails({
                                                    ...shippmentDetails,
                                                    withTracking: true,
                                                })
                                                : setshippmentDetails({
                                                    ...shippmentDetails,
                                                    withTracking: false,
                                                })
                                        }
                                    />
                                    Using a shipment number
                                </label>
                            </div>
                            <div className="my-3">
                                <label className="flex items-center gap-5 font-work">
                                    <input
                                        type="radio"
                                        name="answer"
                                        value="no"
                                        onChange={(event) =>
                                            event.currentTarget.checked === true
                                                ? setshippmentDetails({
                                                    ...shippmentDetails,
                                                    withTracking: false,
                                                })
                                                : setshippmentDetails({
                                                    ...shippmentDetails,
                                                    withTracking: true,
                                                })
                                        }
                                    />
                                    Without a shipment number
                                </label>
                            </div>
                        </div>
                        <div className="">
                            <p className="font-work tracking-wide text-sm text-[#adadad]">
                                {/* /* Details not required in case of product type is Digital */}
                            </p>
                            <div className="my-3">
                                <p className="tracking-wide font-work">Tracking number</p>
                                <input
                                    type="text"
                                    onChange={(event) =>
                                        setshippmentDetails({
                                            ...shippmentDetails,
                                            trackingNumber: event.target.value,
                                        })
                                    }
                                    className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]"
                                />
                            </div>
                            <div className="my-3">
                                <p className="tracking-wide font-work">Shipping Company</p>
                                <input
                                    type="text"
                                    onChange={(event) =>
                                        setshippmentDetails({
                                            ...shippmentDetails,
                                            shippingCompany: event.target.value,
                                        })
                                    }
                                    className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]"
                                />
                            </div>
                            <div className="my-3">
                                <p className="tracking-wide font-work">Order ID</p>
                                <input
                                    type="text"
                                    onChange={(event) =>
                                        setshippmentDetails({
                                            ...shippmentDetails,
                                            orderId: event.target.value,
                                        })
                                    }
                                    className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]"
                                />
                            </div>
                            <div className="my-3">
                                <p className="tracking-wide font-work">Shipping Date</p>
                                <input
                                    type="date"
                                    onChange={(event) =>
                                        formateShippingDate(event.target.value)
                                    }
                                    className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]"
                                />
                            </div>
                            <div className="flex justify-between mt-8">
                                <button className="border-2  border-[#93A8F4] rounded-full px-5 py-2 text-[#93A8F4]">
                                    Cancel
                                </button>
                                <button
                                    onClick={() =>
                                        changeShipmentStatus(selectedContent, "shipped")
                                    }
                                    className="bg-[#0cccd6] rounded-full px-8 py-2 text-white"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </Modalcard>
            ) : null}
            <div className="flex gap-5 m-5">
                <button
                    onClick={() => setproductDeliveryTab(0)}
                    className={`py-2 px-5 rounded-full ${productDeliveryTab === 0 ? "bg-[#2793b379]" : ""
                        } border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}
                >
                    Due to Ship
                </button>
                <button
                    onClick={() => setproductDeliveryTab(1)}
                    className={`py-2 px-5 rounded-full ${productDeliveryTab === 1 ? "bg-[#2793b379]" : ""
                        } border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}
                >
                    Shipped
                </button>
                <button
                    onClick={() => setproductDeliveryTab(2)}
                    className={`py-2 px-5 rounded-full ${productDeliveryTab === 2 ? "bg-[#2793b379]" : ""
                        } border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}
                >
                    Delivered
                </button>
                <button
                    onClick={() => setproductDeliveryTab(3)}
                    className={`py-2 px-5 rounded-full ${productDeliveryTab === 3 ? "bg-[#2793b379]" : ""
                        } border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}
                >
                    All Shipment
                </button>
            </div>
            <div className="mx-5 mt-10">
                <p className="pb-5 text-xl tracking-wide font-work">
                    {campaign?.productDetails?.productName}
                </p>
            </div>
            <div className="relative block m-5">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className="rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="bg-[#27252c] rounded-md">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Status
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Address
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Tracking
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-center cursor-pointer">
                                        Details
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit bg-[#131418] ">
                                {campaignApplication
                                    ?.filter((val) => {
                                        if (val.status !== "pending") {
                                            return val;
                                        }
                                    })
                                    .filter((val) => {
                                        if (productDeliveryTab === 0) {
                                            if (!val?.shipmentDetails) {
                                                return val;
                                            }
                                        } else if (productDeliveryTab === 1) {
                                            if (
                                                val?.shipmentDetails?.shipmentStatus === "shipped"
                                            ) {
                                                return val;
                                            }
                                        } else if (productDeliveryTab === 2) {
                                            if (
                                                val?.shipmentDetails?.shipmentStatus === "delivered"
                                            ) {
                                                return val;
                                            }
                                        } else if (productDeliveryTab === 3) {
                                            return val;
                                        }
                                    })
                                    .map((application, index) => (
                                        <tr key={index}>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {index + 1}
                                            </td>
                                            <td className=" flex items-center  gap-5 font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                <img
                                                    src={application?.user?.imageUrl}
                                                    alt={application?.user?.name}
                                                    className="rounded-full h-14 w-14"
                                                />{" "}
                                                {application?.user?.name}
                                            </td>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {!application?.shipmentDetails && (
                                                    <p className="bg-[#20a4ab] py-1 px-4 w-fit text-xs tracking-wide text-white ">
                                                        Due To Ship
                                                    </p>
                                                )}
                                                {application?.shipmentDetails?.shipmentStatus ===
                                                    "shipped" && (
                                                        <p className="bg-[#20a4ab] py-1 px-4 w-fit text-xs tracking-wide text-white ">
                                                            Shipped
                                                        </p>
                                                    )}
                                                {application?.shipmentDetails?.shipmentStatus ===
                                                    "delivered" && (
                                                        <p className="bg-[#20a4ab] py-1 px-4 w-fit text-xs tracking-wide text-white ">
                                                            Delivered
                                                        </p>
                                                    )}
                                            </td>
                                            <td
                                                className="font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer"
                                                style={{ overflow: "hidden" }}
                                            >
                                                <p
                                                    className=""
                                                    style={{
                                                        wordWrap: "break-word",
                                                        maxWidth: 320,
                                                        whiteSpace: "normal",
                                                    }}
                                                >
                                                    {application?.user?.address}
                                                </p>
                                            </td>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {application?.shipmentDetails
                                                    ?.shipmentTrackingNumber ?? "-"}
                                            </td>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {!application?.shipmentDetails && (
                                                    <button
                                                        onClick={() => {
                                                            setshipmentModal(true);
                                                            setselectedContent(application);
                                                        }}
                                                        className="bg-[#20a4ab] py-2 rounded-full mx-auto px-4 w-fit text-[13px] tracking-wide text-white flex items-center gap-3"
                                                    >
                                                        <Truck size={20} color="#ffffff" />
                                                        Mark as shipped
                                                    </button>
                                                )}
                                                {application?.shipmentDetails?.shipmentStatus ===
                                                    "shipped" && (
                                                        <button
                                                            onClick={() =>
                                                                changeShipmentStatus(application, "delivered")
                                                            }
                                                            className="bg-[#20a4ab] py-2 rounded-full px-4 w-fit text-[14px] tracking-wide font-work mx-auto text-white flex items-center gap-3"
                                                        >
                                                            <Package size={20} color="#ffffff" />
                                                            Mark as delivered
                                                        </button>
                                                    )}
                                                {application?.shipmentDetails?.shipmentStatus ===
                                                    "delivered" && (
                                                        <button className="bg-[#20a4ab] py-2 rounded-full px-4 w-fit text-[13px] tracking-wide text-white mx-auto">
                                                            Delivered
                                                        </button>
                                                    )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {campaignApplication
                            ?.filter((val) => {
                                if (val.status !== "pending") {
                                    return val;
                                }
                            })
                            .filter((val) => {
                                if (productDeliveryTab === 0) {
                                    if (!val?.shipmentDetails) {
                                        return val;
                                    }
                                } else if (productDeliveryTab === 1) {
                                    if (val?.shipmentDetails?.shipmentStatus === "shipped") {
                                        return val;
                                    }
                                } else if (productDeliveryTab === 2) {
                                    if (
                                        val?.shipmentDetails?.shipmentStatus === "delivered"
                                    ) {
                                        return val;
                                    }
                                } else if (productDeliveryTab === 3) {
                                    return val;
                                }
                            }).length === 0 && (
                                <div className="h-20 bg-[#131418] w-full font-work flex gap-5 items-center justify-center">
                                    {productDeliveryTab === 0 && (
                                        <>
                                            {" "}
                                            <Truck size={32} color="white" />{" "}
                                            <p>No task requiring delivery</p>
                                        </>
                                    )}
                                    {productDeliveryTab === 1 && (
                                        <>
                                            {" "}
                                            <Package size={32} color="white" />{" "}
                                            <p>Product has not been shipped to any users</p>
                                        </>
                                    )}
                                    {productDeliveryTab === 2 && (
                                        <>
                                            {" "}
                                            <Package size={32} color="white" />{" "}
                                            <p>Product has not been delivered to any users</p>
                                        </>
                                    )}
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}
