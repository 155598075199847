const strings = {
  local: "https://localhost:8080/",
  apiLink: "https://api.video.myrevue.app/",
  inputStyle:
    "w-full h-12 px-3 rounded-sm appearance-none border bg-transparent my-1",
  TestHost: "testchannelrevue",
  adminCollection: "brandAdmin",
  testBrand: "brand",
  storagetype: "localStorage",
  testUser: [
    "sumankuma.1997@gmail.com",
    "myrevueappofficial@gmail.com",
    "myrevueapp@gmail.com",
  ],
};

const apiHost = {
  brandAdmin: "https://brandadminapi-zscu3untuq-el.a.run.app",
  flicks: "https://flicksapi-zscu3untuq-el.a.run.app",
};

const APiTokens = {
  brandAdmin: "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
  flicks: "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
};

export { strings, apiHost, APiTokens };
