export const initialState = {
  user: JSON.parse(sessionStorage.getItem("user")),
  brand: JSON.parse(sessionStorage.getItem("brand")) || null,
  products: JSON.parse(sessionStorage.getItem("products")) || null,
  reviews: JSON.parse(sessionStorage.getItem("reviews")) || null,
  flicks: JSON.parse(sessionStorage.getItem("flicks")) || null,
  question: JSON.parse(sessionStorage.getItem("questions")) || null,
  point: JSON.parse(sessionStorage.getItem("points")) || null,
  notifications: JSON.parse(sessionStorage.getItem("notifications")) || [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "DELETE_USER":
      sessionStorage.clear();
      return {
        ...state,
        user: null,
      };
    case "SET_BRAND":
      return {
        ...state,
        brand: action.brand,
      };
    case "SET_REVIEW":
      return {
        ...state,
        reviews: action.reviews,
      };
    case "SET_FLICKS":
      return {
        ...state,
        flicks: action.flicks,
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.products,
      };
    case "SET_QUESTIONS":
      return {
        ...state,
        question: action.question,
      };
    case "SET_POINTS":
      return {
        ...state,
        point: action.point,
      };
    case "PUSH_NOTIFICATION":
      return {
        ...state,
        notifications: [action.notifications, ...state.notifications],
      };
    case "SET_NOTIFICATION":
      return {
        ...state,
        notifications: action.notifications,
      };
    default:
      return state;
  }
};

export default reducer;
