import React from 'react'
import Day from "./day";

export default function MonthCalender({ month, monthIndex }) {
    return (
        <div className="grid flex-1 grid-cols-7 grid-rows-5">
            {month?.map((row, i) => (
                <React.Fragment key={i}>
                    {row?.map((day, idx) => (
                        <Day day={day} key={idx} rowIdx={i} monthIndex={monthIndex} />
                    ))}
                </React.Fragment>
            ))}
        </div>
    )
}
