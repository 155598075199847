import React from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import Cdashboard from "../pages/Cdashboard";
import ViewCampaignDetails from "../pages/view/ViewCampaignDetails";
import ViewProductCampaigns from "../pages/ViewProductCampaigns";
import Viewsubmission from "../pages/ViewSubmission";
import Cheader from "./Cheader";
import VideoEditorPage from "../pages/VideoEditorPage";
import ViewTemplate from "../pages/ViewTemplate";
import ChooseTemplatePage from "../pages/ChooseTemplatePage";
import { sendAmplitudeData } from "../amplitude";
import { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import Faqdashboard from "../pages/faqdashboard";
import ViewcampaignFaq from "../pages/view/ViewCampaignDetailsFaq";
import ViewFaqsubmission from "../pages/view/ViewFaqSubmission";
import Requestcampaign from "../pages/requestcampaign";
import InHouseRequest from "../pages/inhouserequest";
import ViewRequestCampaign from "../pages/view/viewRequestCampaign";
import CollectorFeed from "../pages/CollectorFeed";
import CreateFeed from "../pages/CreateFeed";
import CreateCampaigns from "../pages/create/createCampaigns";
import Createfaqs from "../pages/create/createFaqs";
import CreateChallenge from "../challenge/CreateChallenge";
import Challenge from "../challenge/Challenge";
import ViewChallenge from "../challenge/ViewChallenge";
import ViewChallengeSubmission from "../challenge/ViewChallengeSubmission";
import ViewQuizs from "../quiz/ViewQuizs";
import CreateQuiz from "../quiz/CreateQuiz";
import Quiz from "../quiz/Quiz";
import ViewQuizSubmission from "../quiz/ViewQuizSubmission";
import FourZeroFour from "../pages/404";
import EngagementAnalytics from "../engagementAnalytics/EngagementAnalytics";
import ViewHuntApplicantsAnalytics from "../videoCampaign/ViewHuntApplicantsAnalytics";
function CollectorLayout() {
  const { path } = useRouteMatch();
  const [{ brand, user }, dispatch] = useStateValue();
  const location = useLocation();
  const router = useHistory();
  useEffect(() => {
    if (!user) {
      router.replace("/login");
    }
    // setTimeout(() => {
    //   if ((user?.access !== "admin") && (!user.subscription)) {
    //     if (location.pathname.includes("/billing")) {
    //       return
    //     }
    //     else {
    //       return router.replace("/admin/billing")
    //     }
    //   }
    // }, 1000);
    sendAmplitudeData("collector page opened");
    var title = `${brand?.name} - MyRevue`;
    var metaTitle = `${brand?.name} - MyRevue`;
    var metaDescription = ``;
    var metaKeyword = ``;

    var ogUrl = `https://brand.myrevue.app/collector`;
    var ogtype = `website`;
    var ogtitle = `${brand?.name} - MyRevue`;
    var ogdescription = ``;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/collector`;
    var twittertitle = `${brand?.name} - MyRevue`;
    var twitterdescription = ``;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, [location.pathname, brand]);

  return (
    <div className="bg-[#2D3037] h-screen overflow-y-auto bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern.png?alt=media&token=aa300ab1-5153-469c-a710-b2b9192b98c2')]">
      <div className="sticky top-0 z-[1500]">
        <Cheader />
      </div>
      <Switch>
        <Route
          path={path + "/createCampaign"}
          exact
          component={CreateCampaigns}
        />
        <Route path={path + "/createfaq"} exact component={Createfaqs} />
        <Route
          path={path + "/analytics"}
          exact
          component={EngagementAnalytics}
        />
        <Route path={path + "/feed"} exact component={CollectorFeed} />
        <Route
          path={path + "/challenges/create"}
          exact
          component={CreateChallenge}
        />
        <Route path={path + "/quiz/create"} exact component={CreateQuiz} />
        <Route path={path + "/feed/create"} exact component={CreateFeed} />
        <Route
          path={path + "/chooseTemplate"}
          exact
          component={ChooseTemplatePage}
        />
        <Route
          path={path + "/requestcampaign"}
          exact
          component={Requestcampaign}
        />
        <Route
          path={path + "/requestcampaign/:id"}
          exact
          component={ViewRequestCampaign}
        />
        <Route
          path={path + "/requestcampaign/:id/:userId"}
          exact
          component={ViewHuntApplicantsAnalytics}
        />
        <Route
          path={path + "/inhouserequest"}
          exact
          component={InHouseRequest}
        />
        <Route
          path={path + "/editReview/:subid/:id"}
          exact
          component={VideoEditorPage}
        />
        <Route
          path={path + "/viewTemplate/:templateId"}
          exact
          component={ViewTemplate}
        />
        <Route
          path={path + "/viewTemplate/:templateId"}
          exact
          component={ViewTemplate}
        />
        <Route
          path={path + "/view-submission/:subid/:id"}
          exact
          component={Viewsubmission}
        />
        <Route
          path={path + "/view-campaign/:id"}
          exact
          component={ViewCampaignDetails}
        />
        <Route
          path={path + "/challenges/view-challenge/:id"}
          exact
          component={ViewChallenge}
        />
        <Route
          path={path + "/quiz/view-quiz/:id"}
          exact
          component={ViewQuizs}
        />
        <Route
          path={path + "/quiz/view-quiz/:id/:subid"}
          exact
          component={ViewQuizSubmission}
        />
        <Route
          path={path + "/challenges/view-challenge/:id/:subid"}
          exact
          component={ViewChallengeSubmission}
        />
        <Route
          path={path + "/view-product-campaigns/:prodId"}
          exact
          component={ViewProductCampaigns}
        />
        <Route path={path + "/viewfaqs"} exact component={Faqdashboard} />
        <Route
          path={path + "/view-faqcampaigns/:id"}
          exact
          component={ViewcampaignFaq}
        />
        <Route path={path + "/challenges"} exact component={Challenge} />
        <Route path={path + "/quiz"} exact component={Quiz} />
        <Route
          path={path + "/view-faqsubmission/:id/:subid"}
          exact
          component={ViewFaqsubmission}
        />
        <Route path={path} exact component={Cdashboard} />
        <Route path={`${path}/*`} component={FourZeroFour} />
      </Switch>
    </div>
  );
}

export default CollectorLayout;
