import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import { Eye } from 'phosphor-react';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modalcard from '../components/modalcard';
import { useStateValue } from '../context/StateProvider';
import { db } from '../firebase';
import { Hexagon, X } from '@phosphor-icons/react';

export default function Downloads() {
    const [downloads, setdownloads] = useState([])
    const [{ user, }, dispatch] = useStateValue();
    const [review, setreview] = useState(null)
    useEffect(() => {
        const dq = query(collection(db, "exports"), where("user", "==", user?.email))
        getDocs(dq).then(res => setdownloads(res.docs.map(doc => doc.data())))
    }, [user])

    return (
        <div className="w-full p-5">
            <div className="flex items-center justify-between">
                <p className="text-3xl font-medium leading-none tracking-wide text-white">
                    Downloads
                </p>
            </div>

            {review && <Modalcard close={setreview}>
                <div className="relative flex">
                    <button onClick={() => setreview(false)} className='absolute top-0 right-0 -mx-16'><X size={32} color='white' /></button>
                    <div className="h-auto bg-white w-96">
                        <video playsInline src={review} className="h-auto bg-white w-96" controls autoPlay></video>
                    </div>
                </div>
            </Modalcard >}


            <div div className="relative block mt-10 mb-10" >
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="h-14 bg-[#131418]">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Title
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Product Name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        User Name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Export Date
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {downloads?.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="table-row-spacer"></tr>
                                        <tr
                                            className="table-row align-[inherit]  bg-[#131418]"
                                            key={index}
                                        >
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wider font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {index + 1}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wider font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {data?.review?.name}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wider font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {data?.review?.productName}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wider font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {data?.review?.uploadedBy?.name}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wider font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {moment(data?.export_Date).format("DD/MM/YYYY")}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer" >
                                                <div className="flex items-center gap-x-3" onClick={() => setreview(data?.videoUrl)}>
                                                    <div className="relative flex items-center justify-center w-10 h-10">
                                                        <Hexagon size={40} weight="light" />
                                                        <Eye size={20} className="absolute" weight="bold" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        {downloads.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                            No Downloads Available
                        </div>}
                    </div>
                </div>
            </div >

            <div className="fixed bottom-0 bg-background w-[calc(100vw-295px)] border-t-[2px] border-[#292929] flex items-center justify-between px-5">
                <div className="h-12 flex items-center justify-between  w-full text-[#808080]">
                    Showing {downloads?.length > 0 ? "1" : "0"} to {downloads?.length} of {downloads?.length}
                </div>
                <div className="text-[#808080] hover:text-white cursor-pointer">
                    {downloads?.length}
                </div>
            </div>
        </div >
    );
}
