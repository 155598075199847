import { Circle, ShieldSlash, ShoppingCart, X, } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import NotloginfooterNew from "../layouts/NotloginfooterNew";
import { useHistory } from "react-router-dom";
import { ArrowLeft } from "phosphor-react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { toast } from "react-toastify";
import StockVideoComponent from "../components/stockvideo";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { showWarning } from "../components/toast/toast";

export default function StockVideosID() {
    const swiperRef = useRef(null);
    const [items, setItems] = useState(JSON.parse(sessionStorage.getItem("stock")) || []);
    const router = useHistory();
    const [pricingMode, setPricingMode] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [videos, setVideos] = useState([]);
    const [video, setVideo] = useState({});
    const [added, setAdded] = useState(false);
    const [addedVariant, setAddedVariant] = useState({});
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    useEffect(() => {
        async function getVideos() {
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: "https://stripeugcapi-zscu3untuq-as.a.run.app/videos",
                method: "GET",
                headers: headersList,
            }

            let response = await axios.request(reqOptions);
            if (response.data.success) {
                setVideos(response.data.data)
            }
            else {
                showWarning("Something went wrong while fetching videos.")
                return
            }
        }
        async function getVideo() {
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: `https://stripeugcapi-zscu3untuq-as.a.run.app/videos/${id}`,
                method: "GET",
                headers: headersList,
            }

            let response = await axios.request(reqOptions);
            if (response.data.success) {
                setVideo(response.data.data)
            }
            else {
                showWarning("Something went wrong while fetching videos.")
                return
            }
        }
        getVideos();
        getVideo()
        const cartDocument = items.find((obj) => obj.videoId === id);
        function determinePricingType(items, id) {
            const cartDocument = items.find((obj) => obj.videoId === id);
            if (cartDocument) {
                const aspectRatio = cartDocument.variant?.aspect_ratio;

                if (aspectRatio === "9:16" && !cartDocument.variant?.exclusive) {
                    return 1;
                } else if (aspectRatio === "9:16" && cartDocument?.variant?.exclusive === true) {
                    return 2;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }
        const pricingType = determinePricingType(items, id);
        setAdded(items.some((obj) => obj.videoId === id));
        setAddedVariant(cartDocument);
        setPricingMode(pricingType);
        setLoading(false)
    }, [id, items])

    useEffect(() => {
        var title = `Ready-to-use UGC videos. In seconds!`;
        var metaTitle = `Ready-to-use UGC videos. In seconds!`;
        var metaDescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var metaKeyword = `Brands - MyRevue, UGC Video, MyRevue Elite, Billo , GoTolStoy, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

        var ogUrl = `https://brand.myrevue.app/stock/videos`;
        var ogtype = `website`;
        var ogtitle = `Ready-to-use UGC videos. In seconds!`;
        var ogdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock.png?alt=media&token=b57398ab-7e39-4110-a395-797e527d54d5`;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/stock/videos`;
        var twittertitle = `Ready-to-use UGC videos. In seconds!`;
        var twitterdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock.png?alt=media&token=b57398ab-7e39-4110-a395-797e527d54d5`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
            metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
            ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
            twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
            twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
    }, []);
    async function addToCart(docuemnt) {
        const existingCart = JSON.parse(sessionStorage.getItem('stock')) || [];
        existingCart.push(docuemnt);
        sessionStorage.setItem('stock', JSON.stringify(existingCart));
    }
    async function addProductToCart(docuemnt) {
        if (pricingMode === 0) {
            return showWarning("Please select a variant to continue")
        }
        function getVariantUrlByAspectRatio(product, aspectRatio) {
            const variant = product.variants.find((v) => v.aspect_ratio === aspectRatio);

            if (variant) {
                return variant;
            } else {
                return null; // Aspect ratio not found
            }
        }
        const aspectRatioToGet = "9:16";
        const pvariant = getVariantUrlByAspectRatio(video, aspectRatioToGet);
        let doc;
        const existingCart = JSON.parse(sessionStorage.getItem('stock')) || [];
        if (pricingMode === 1) {
            doc = {
                title: docuemnt?.title,
                videoId: docuemnt?.videoId,
                variant: pvariant
            }
        }
        if (pricingMode === 2) {
            doc = {
                title: docuemnt?.title,
                videoId: docuemnt?.videoId,
                variant: {
                    ...pvariant,
                    exclusive: true
                }
            }
        }
        existingCart.push(doc);
        sessionStorage.setItem('stock', JSON.stringify(existingCart));
        setAdded(true)
        setItems(existingCart)
    }
    return (

        <>
            {loading ?
                <div className="min-h-screen bg-[#101010] flex items-center justify-center">
                    < Circle size={50} weight="bold" color="white" />
                </div >
                :
                <div className="min-h-screen bg-[#101010] w-full">
                    <div className="h-20 w-full flex items-center justify-between px-6 shadow-md sticky top-0 z-[1000] bg-[#171717]">
                        <div className="flex items-center gap-5">
                            <Link to="/">
                                <img
                                    loading="lazy"
                                    src={require("../assets/logo/MyRevue Logo elite new.svg").default}
                                    alt="logo"
                                    className="h-[40px] md:h-[62px] w-auto"
                                />
                            </Link>
                        </div>
                        <div className="flex items-center gap-5">
                            <div className="flex items-center gap-5">
                                <Link to="/stock/cart?step=1">
                                    <button className="py-2 px-5 font-semibold flex items-center gap-5 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                        <ShoppingCart size={22} color="white" />
                                        {items.length}
                                    </button>
                                </Link>
                                <Link to="/login">
                                    <button className="py-2 px-5 hidden md:flex items-center gap-5 rounded-full hover:bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] ">
                                        Sign in
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-[1352px] mx-auto relative pt-[30px] pb-[50px] px-[30px]">
                        <button className="" onClick={() => router.push("/stock/videos")}><span className="inline-block pr-4 align-middle"> <ArrowLeft size={22} color="white" /> </span>Back</button>
                        <div className="flex-wrap gap-20 mt-8 md:flex">
                            <div className="w-full md:w-[480px]" >
                                {video?.variants && <video playsInline className="w-full aspect-[9/16] rounded-md" controls muted src={video?.variants[0]?.urls?.watermarked_url} type="video/mp4" ></video>}
                            </div>
                            <div className="flex-1 w-full mt-10 md:mt-0">
                                <div className="flex flex-wrap gap-5">
                                    {video?.tags?.map((tag, index) => (
                                        <div key={index} className="bg-[#2b2b2b] text-[1rem] rounded-full leading-6 border border-[#575757]  font-medium px-[1em] py-[0.5em]">
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-[2.5em]">
                                    <p className="text-[25px] leading-[32px] md:text-[48px] font-medium md:leading-[48px]">{video?.title}</p>
                                </div>
                                {/* <div className="flex items-center gap-[0.5em] my-[1.5em] tracking-wide">
                                    <span className="leading-[24px] font-medium">{video?.creator} </span>
                                    <div className="relative inline-block creator_location">
                                        <div className="block w-2 h-2 bg-white rounded-full"> </div>
                                    </div>
                                    {video.location}
                                </div> */}
                                <div className="flex flex-col space-y-3 w-fit">
                                    <div className="">
                                        <div onClick={() => setPricingMode(1)} className=" cursor-pointer w-full py-3 flex items-center justify-between  bg-[#2b2b2b] px-5 rounded-md gap-10">
                                            <div className="flex items-center flex-1 gap-3">
                                                <input type="radio" id="pricing" name="pricing" checked={pricingMode === 1} />
                                                <span className="flex-1">9:16 aspect ratio (Vertical)</span>
                                            </div>
                                            <div className="flex items-start gap-2">
                                                {video?.variants && <span className="text-[16px] md:text-[24px]">₹{video?.variants[0]?.discounted_price}</span>}
                                                {video?.variants && <span className="text-[14px] md:text-[16px] line-through mt-[3px]">₹{video?.variants[0]?.price}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div onClick={() => setPricingMode(2)} className=" cursor-pointer w-full py-3 flex items-center justify-between  bg-[#2b2b2b] px-5 rounded-md gap-10">
                                            <div className="flex items-center flex-1 gap-3">
                                                <input type="radio" id="pricing" name="pricing" checked={pricingMode === 2} />
                                                <span className="flex-1">Exclusive download</span>
                                            </div>
                                            <div className="flex items-start gap-2">
                                                {video?.variants && <span className="text-[16px] md:text-[24px]">₹{video?.variants[0]?.exclusive_price}</span>}
                                                {(video?.variants && (video?.variants[0]?.exclusive_price !== video?.variants[0]?.price)) && <span className="text-[14px] md:text-[16px] line-through mt-[3px]">₹{video?.variants[0]?.price}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {added ?
                                            <button type="submit" onClick={() => router.push("/stock/cart?step=1")} className=" cursor-pointer font-medium w-full py-3 flex items-center justify-center  bg-[#FF005C] hover:bg-[#F92147] px-5 rounded-md gap-10">Continue to checkout</button>
                                            :
                                            <button type="submit" onClick={() => addProductToCart(video)} className=" cursor-pointer font-medium w-full py-3 flex items-center justify-center  bg-[#FF005C] hover:bg-[#F92147] px-5 rounded-md gap-10">Add to cart</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <div className="max-w-[1352px] mx-auto relative pt-[30px] pb-[50px] px-[30px]">
                        <p className="text-[26px] md:text-[36px] font-semibold">More videos from this creator</p>
                        <div className="hidden grid-cols-3 gap-10 mt-5 sm:grid-cols-2 md:grid lg:grid-cols-4">
                            {videos?.map((docuemnt, index) => (
                                <StockVideoComponent document={docuemnt} setShowModal={setShowModal} addToCart={addToCart} key={index} />
                            ))}
                        </div>
                        <div className="block md:hidden">
                            <Swiper
                                spaceBetween={25}
                                // slidesPerView={1}
                                // onSlideChange={() => console.log("slide change")}
                                // onSwiper={(swiper) => console.log(swiper)}
                                ref={swiperRef}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        width: 640,
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },
                                    964: {
                                        width: 964,
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation={{
                                    nextEl: ".next-button",
                                    prevEl: ".prev-button",
                                }}
                                onBeforeInit={(swiper) => {
                                    swiperRef.current = swiper;
                                }}>
                                {videos?.map((docuemnt, index) => (
                                    <SwiperSlide key={index}>
                                        <StockVideoComponent document={docuemnt} setShowModal={setShowModal} addToCart={addToCart} key={index} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <div className="max-w-[1352px] mx-auto relative pt-[30px] pb-[50px] px-[30px]">
                        <p className="text-[26px] md:text-[36px] font-semibold">Similar Videos</p>
                        <div className="hidden grid-cols-3 gap-10 mt-5 sm:grid-cols-2 md:grid lg:grid-cols-4">
                            {videos?.map((docuemnt, index) => (
                                <StockVideoComponent document={docuemnt} setShowModal={setShowModal} addToCart={addToCart} key={index} />
                            ))}
                        </div>
                        <div className="block md:hidden">
                            <Swiper
                                spaceBetween={25}
                                // slidesPerView={1}
                                // onSlideChange={() => console.log("slide change")}
                                // onSwiper={(swiper) => console.log(swiper)}
                                ref={swiperRef}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        width: 640,
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },
                                    964: {
                                        width: 964,
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation={{
                                    nextEl: ".next-button",
                                    prevEl: ".prev-button",
                                }}
                                onBeforeInit={(swiper) => {
                                    swiperRef.current = swiper;
                                }}>
                                {videos?.map((docuemnt, index) => (
                                    <SwiperSlide key={index}>
                                        <StockVideoComponent document={docuemnt} setShowModal={setShowModal} addToCart={addToCart} key={index} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <NotloginfooterNew />
                </div >
            }
        </>

    );
}
