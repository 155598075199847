const firstRowLogo = [
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FDave%20.webp?alt=media&token=5721dc3e-36fd-4616-af32-003f88e4fdfb",
        "alt": "Dave .webp",
        "class": "  !h-[32px] md:!h-[50px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FDESIJUTTI.webp?alt=media&token=98ae3efc-2452-47ce-b505-93ee5b4ec0b3",
        "alt": "DESIJUTTI.webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FAOEC-India-Logo.webp?alt=media&token=0da34edb-38b8-4185-a42c-89de62e024cc",
        "alt": "AOEC-India-Logo.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FLayer%205.webp?alt=media&token=d7a30e3c-1ba6-4d71-bcb9-d3afa95c4aa5",
        "alt": "Layer 5.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fkaya.webp?alt=media&token=fba49aaf-62c4-466c-9e24-4095dcc6cf05",
        "alt": "kaya.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Ffrizty.webp?alt=media&token=ae40ff30-110e-474f-a2cb-7dad26319d09",
        "alt": "frizty.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Findiancart.webp?alt=media&token=de535b9c-29e0-40e9-8fd6-4190b61469e1",
        "alt": "indiancart.webp",
        "class": "  !h-[30px] md:!h-[45px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fgodevil.webp?alt=media&token=6f27bb84-1a4c-4932-afc9-3c7c8863c21a",
        "alt": "godevil.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Flogo-_Quench_Labz-_white.webp?alt=media&token=fa20b221-2faa-4f0f-aaad-c0cdf2c7d352",
        "alt": "logo-_Quench_Labz-_white.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Flabel-design-black-2.webp?alt=media&token=613109d8-dc10-4889-b1a0-c6b5302c9230",
        "alt": "label-design-black-2.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FZC.webp?alt=media&token=ecbee98c-ccfa-40b0-abac-db66d6b56e78",
        "alt": "ZC.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FDW%20Logo%2001.webp?alt=media&token=aa287750-b901-4787-984f-fabdf6db2cbf",
        "alt": "DW Logo 01.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Frecooty.svg?alt=media&token=c01bd2b8-4fc0-4425-b648-688e62a3e42b",
        "alt": "recooty.svg",
        "class": " !h-[70px] "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FALBATROSS.webp?alt=media&token=fb467790-5a85-4380-b026-99a95192f52a",
        "alt": "ALBATROSS.webp",
        "class": "  !h-[30px] md:!h-[45px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fmusuvidha.webp?alt=media&token=bc6036b0-1963-4605-924d-74eb367933f6",
        "alt": "musuvidha.webp",
        "class": "  !h-[30px] md:!h-[45px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fplarsh.webp?alt=media&token=520a6411-db46-4a60-86af-675ea9ef414d",
        "alt": "plarsh.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FEllipse%201.webp?alt=media&token=160012d9-943f-4b8f-af08-9543824eca5e",
        "alt": "Ellipse 1.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fsupersourcing.png?alt=media&token=6b319079-1efa-4a3a-844e-a853bfe493ad",
        "alt": "supersourcing.png",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fsakhlogo.png?alt=media&token=4fe60ef2-6597-4744-9581-3c3ab071e63c",
        "alt": "sakhlogo.png",
        "class": " !h-[70px] invert"
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FMEDIAVOX.webp?alt=media&token=a52b8d35-ab89-45dc-8332-26f2119a004f",
        "alt": "MEDIAVOX.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fprop%20suchna.webp?alt=media&token=a31cce8f-4119-466a-9b84-8e0380c8e939",
        "alt": "prop suchna.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    }
]
const secondRowLogo = [
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FDestinio.webp?alt=media&token=3756e12f-2dda-41eb-8a75-e23fdaa43d35",
        "alt": "Destinio.webp",
        "class": " !h-[25px] md: !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fhigarden.png?alt=media&token=5506a0dd-71d1-434a-94e1-9a7c3c3bcd30",
        "alt": "higarden.png",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fcureofine.webp?alt=media&token=50d76804-da7c-47f1-b34c-1286ec274643",
        "alt": "cureofine.webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fmurtihub_logo_1%20(1).webp?alt=media&token=25305261-fba1-49a0-8def-ee2af2b7b2b5",
        "alt": "murtihub_logo_1 (1).webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fherbalgood-logo-450x244.webp?alt=media&token=820fb0fb-52ec-41a1-8599-c9236b812567",
        "alt": "herbalgood-logo-450x244.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fmetroclappng.webp?alt=media&token=3181e377-6dd2-4a0e-8b17-3f8c10a8629e",
        "alt": "metroclappng.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FLayer%209.webp?alt=media&token=f9f7d9f8-8285-4d36-ac0b-ac7b70685c8d",
        "alt": "Layer 9.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FMegastro-Logo-1.webp?alt=media&token=6b15cd77-315c-4649-9ea2-2094dfdc6510",
        "alt": "Megastro-Logo-1.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FYatrikart.svg?alt=media&token=4c1cd455-7e8e-45f9-a56c-9c88542d2959",
        "alt": "Yatrikart.svg",
        "class": "  !h-[32px] md:!h-[50px]  invert"
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FLogo%20(5).webp?alt=media&token=67310813-15d5-4937-9dcb-26c56c0d5940",
        "alt": "Logo (5).webp",
        "class": "  !h-[32px] md:!h-[50px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fcinemass.png?alt=media&token=56f4ca84-7b4e-4b93-911f-139e8be604ed",
        "alt": "cinemass.png",
        "class": " !h-[70px] transform scale-150 invert grayscale"
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FProfit-plug-site-icon.webp?alt=media&token=25904542-ee4d-472e-91dc-63f4ee738129",
        "alt": "Profit-plug-site-icon.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FLayer%208.webp?alt=media&token=13152379-76f0-4dfd-b977-1de7f2dc49d1",
        "alt": "Layer 8.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fgood-choice-india-Photoroom.png-Photoroom.webp?alt=media&token=66dcdd58-6033-48a1-a37f-d9e32c59448d",
        "alt": "good-choice-india-Photoroom.png-Photoroom.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FStage.svg?alt=media&token=e77cdb0c-4d89-4478-9813-6a3183b760ac",
        "alt": "Stage.svg",
        "class": "  !h-[22px] md:!h-[35px]  grayscale transform scale-150 "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FOWNWASH%20LOGO%20(1).webp?alt=media&token=19caf861-0986-43d8-9637-06f2782810e6",
        "alt": "OWNWASH LOGO (1).webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fmoon.webp?alt=media&token=10c72074-6823-494b-be2f-161a8b2e608f",
        "alt": "moon.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FZebraLearnLogo.webp?alt=media&token=6c47df4b-4445-4688-a1b3-348c092ed207",
        "alt": "ZebraLearnLogo.webp",
        "class": " !h-[20px] "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fgyk.png?alt=media&token=b05aec6e-d3ea-4d8b-a31f-e41c251f2cb1",
        "alt": "gyk.png",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Ffleximaa.webp?alt=media&token=1560f834-7a95-43ff-86da-0568222da3f8",
        "alt": "fleximaa.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fb%20desi.webp?alt=media&token=9cab9018-7f30-4314-84dc-55c3b1996aea",
        "alt": "b desi.webp",
        "class": "  !h-[32px] md:!h-[50px]  "
    }
]
const thirdRowLogo = [
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fkesardhaga.png?alt=media&token=03131b44-f415-4972-8050-17347e62d941",
        "alt": "kesardhaga.png",
        "class": " !h-[70px] grayscale"
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FSEZNIK%20LOGO-01.webp?alt=media&token=7877c890-4a39-4775-bdca-b53a87830428",
        "alt": "SEZNIK LOGO-01.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Flogo%20(2).webp?alt=media&token=f086c00f-e324-42b1-a499-ee6225c3c05c",
        "alt": "logo (2).webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fbingage.svg?alt=media&token=45bfc67b-7b68-4ffa-bb8a-ae75e08d1075",
        "alt": "bingage.svg",
        "class": "  !h-[32px] md:!h-[50px]  invert"
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FPoptagg.webp?alt=media&token=76406684-5fe7-4b88-8a8e-e770c6a0e2a1",
        "alt": "Poptagg.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fwakeup.webp?alt=media&token=f3805e2a-7a13-4d1d-8e90-4f899f134440",
        "alt": "wakeup.webp",
        "class": "  !h-[25px] md:!h-[40px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fsavvy-groovy.webp?alt=media&token=e77e0ed5-3616-4cd9-910b-f878ccbdd69f",
        "alt": "savvy-groovy.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fprolance.svg?alt=media&token=f8c62f70-aae0-473d-86ca-52abf7f9336c",
        "alt": "prolance.svg",
        "class": "  !h-[25px] md:!h-[40px]  grayscale invert "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fthreadmonk.png?alt=media&token=dcc895a8-5481-4b2b-99a2-38d05a9992f3",
        "alt": "threadmonk.png",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fwildglow.png?alt=media&token=37220642-c18a-4d50-9bfd-2629796692ca",
        "alt": "wildglow.png",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fvedayu.png?alt=media&token=dc9c46dd-1b89-4a76-9981-192826aaaab3",
        "alt": "vedayu.png",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fwebbitsglobal.webp?alt=media&token=40b1c9c0-e10c-4f8b-98b4-6f6f6e76a97b",
        "alt": "webbitsglobal.webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fvoyager%20paris.webp?alt=media&token=3123395b-69b0-4635-b417-3b959bd969a1",
        "alt": "voyager paris.webp",
        "class": "  !h-[32px] md:!h-[50px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Ftapponn.png?alt=media&token=8fde279c-4dd9-463f-b6db-a752209feaaa",
        "alt": "tapponn.png",
        "class": " !h-[25px] "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fsocial%20money.webp?alt=media&token=9a9ba790-806a-4265-a454-46d5f5b06538",
        "alt": "social money.webp",
        "class": "  !h-[25px] md:!h-[40px]  transform scale-125"
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fsurgify-logo.webp?alt=media&token=6adf4859-5f68-4669-95ea-09c2cb1ecf47",
        "alt": "surgify-logo.webp",
        "class": "  !h-[22px] md:!h-[35px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FDropzilla.webp?alt=media&token=506b9063-4717-4756-a87d-67dca139d6f9",
        "alt": "Dropzilla.webp",
        "class": "  !h-[32px] md:!h-[50px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fimage-Photoroom.png-Photoroom%20(3).webp?alt=media&token=7d02256d-a1b8-4998-8430-28d0df644797",
        "alt": "image-Photoroom.png-Photoroom (3).webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2FRajdhani.webp?alt=media&token=ce0b6aa8-0dd3-40e3-bb92-ad984035eb92",
        "alt": "Rajdhani.webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Fwebleyindialogo_78240fef-f604-46e6-b4eb-2aa1c54ac283.webp?alt=media&token=fd452905-2cd5-461e-aa19-0db7c23f66bd",
        "alt": "webleyindialogo_78240fef-f604-46e6-b4eb-2aa1c54ac283.webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    },
    {
        "src": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogos%2Froyaltail.webp?alt=media&token=ba794e9f-1e47-48cb-aa6f-c72941ac72e4",
        "alt": "royaltail.webp",
        "class": "  !h-[20px] md:!h-[30px]  "
    }
]

export { firstRowLogo, secondRowLogo, thirdRowLogo }