import { Checks, ShieldSlash } from "@phosphor-icons/react";
import { toast } from "react-toastify";

async function showWarning(msg) {
    toast.warning(msg, {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-[13px] tracking-wide font-work first-letter:uppercase",
    });
}
async function showSuccess(msg) {
    toast.success(msg, {
        position: "top-right",
        icon: <Checks size={32} color="green" weight="bold" />,
        bodyClassName: "text-[13px] tracking-wide font-work first-letter:uppercase",
    });
}

export { showSuccess, showWarning }