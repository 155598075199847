import { Check, CircleNotch, ShieldSlash, ShoppingCart, Trash, TrashSimple, } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotloginfooterNew from "../layouts/NotloginfooterNew";
import "../ecommerce.css"
import axios from "axios";
import { useEffect } from "react";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { showWarning } from "../components/toast/toast";
export default function StockCart() {
    const [items, setItems] = useState(JSON.parse(sessionStorage.getItem("stock")) || []);
    const params = new URLSearchParams(window.location.search);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [downloadMetadata, setDownloadMetadata] = useState([]);
    const router = useHistory();
    useEffect(() => {
        async function getDownloads(orderId) {
            try {
                let headersList = {
                    "Accept": "*/*",
                }

                let reqOptions = {
                    url: "https://stripeugcapi-zscu3untuq-as.a.run.app/videos/downloads/" + orderId,
                    method: "GET",
                    headers: headersList,
                }

                let response = await axios.request(reqOptions);
                return response.data.data;
            } catch (error) {
                console.error("Error fetching downloads:", error);
                // Handle the error if needed
                return null;
            }
        }

        async function fetchData() {
            try {
                if (params.get('step') === '3' && params.get('success') === "true") {
                    const orderId = params.get("orderId");
                    const docs = await getDownloads(orderId);

                    if (docs !== null) {
                        setDownloadMetadata(docs);
                        sessionStorage.removeItem('stock');
                        setItems([])
                    }
                }
            } catch (error) {
                console.error("Error in fetchData:", error);
                // Handle the error if needed
            }
        }

        fetchData(); // Call the asynchronous function immediately
    }, []);
    useEffect(() => {
        var title = `Ready-to-use UGC videos. In seconds!`;
        var metaTitle = `Ready-to-use UGC videos. In seconds!`;
        var metaDescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var metaKeyword = `Brands - MyRevue, UGC Video, MyRevue Elite, Billo , GoTolStoy, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

        var ogUrl = `https://brand.myrevue.app/stock/cart`;
        var ogtype = `website`;
        var ogtitle = `Ready-to-use UGC videos. In seconds!`;
        var ogdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstockkart.png?alt=media&token=120dcd3e-e3d2-42de-904b-45902915c38a`;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/stock/cart`;
        var twittertitle = `Ready-to-use UGC videos. In seconds!`;
        var twitterdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstockkart.png?alt=media&token=120dcd3e-e3d2-42de-904b-45902915c38a`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
            metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
            ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
            twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
            twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
    }, []);
    async function createOrder() {
        const orderId = nanoid(20);
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let bodyContent = {
            orderId: orderId,
            items: items
        };

        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/videosorders",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        return response.data.data
    }
    async function generateCheckoutLink() {
        setPaymentLoading(true);
        const orderId = await createOrder();
        if (!orderId) {
            showWarning("Something went wrong")
            return
        }
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };


        let bodyContent = {
            amount: (items.reduce((total, item) => total + (item?.variant?.exclusive === true ? item?.variant?.exclusive_price : item.variant?.discounted_price || 0), 0))?.toFixed(2),
            currency: "INR",
            description: `Stock Video Order: OrderId- #${orderId}`,
            url: "stock/cart",
            metadata: {
                orderId: orderId,
            }
        };

        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/razorpay/create/stock/checkoutLink",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };
        try {
            let response = await axios.request(reqOptions);
            // window.location.href = response.data.url;
            makePayment(response.data)
        } catch (error) {
            setPaymentLoading(false)
        }
    }
    const makePayment = async (data) => {
        const res = await initializeRazorpay();
        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }
        var options = {
            key: "rzp_live_E4dtid6tq7cO0E", // Enter the Key ID generated from the Dashboard
            name: "MyRevue",
            currency: data.currency,
            amount: data.amount,
            order_id: data.id,
            description: data.notes.description,
            image: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue.png?alt=media&token=b9dc6ef0-c272-49ba-a7c5-5bf51b46eda7",
            handler: function (response) {
                setPaymentLoading(false)
                router.push(`/stock/cart?step=3&orderId=${data?.receipt}&success=true`);
                window.location.reload()
            },
            theme: {
                hide_topbar: false,
                color: '#FF005C',
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    const initializeRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    async function deleteItem(videoIdToRemove) {
        const newItems = items.filter((obj) => obj.videoId !== videoIdToRemove);
        sessionStorage.setItem('stock', JSON.stringify(newItems));
        setItems(newItems)
    }
    async function emptyCart() {
        sessionStorage.removeItem('stock');
        setItems([])
    }

    async function downloadVideo(url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'; // Open link in a new tab
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="min-h-screen bg-[#101010] w-full">
            <div className="h-20 w-full flex items-center justify-between px-6 shadow-md sticky top-0 z-[1000] bg-[#171717]">
                <div className="">
                    <Link to="/">
                        <img
                            loading="lazy"
                            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
                            alt="logo"
                            className="h-[40px] md:h-[62px] w-auto"
                        />
                    </Link>
                </div>
                <div className="">
                    <button className="py-2 px-5 font-semibold flex items-center gap-5 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                        <ShoppingCart size={22} color="white" />
                        {items.length}
                    </button>
                </div>
            </div>
            {(items?.length === 0 && !params.get("orderId")) && <div className="max-w-[1352px] m-auto  relative pt-[30px] pb-[50px] px-[30px]">
                <div className="flex flex-col md:flex-row gap-2 items-center justify-between p-[16px] bg-[#171717] rounded-md">
                    <p className="">Your cart is currently empty.</p>
                    <Link to="/stock/videos">
                        <div className="cursor-pointer">
                            <ShoppingCart size={22} color="white" className="inline-block align-middle" />
                            <span className="inline-block pl-3 align-middle">Return to shop</span>
                        </div>
                    </Link>
                </div>
            </div>}
            {((items?.length > 0) || (params.get('success') === 'true')) &&
                <div className="max-w-[1352px] m-auto  relative pt-[30px] pb-[50px] px-[30px]">
                    <div className="flex items-center justify-center w-full">
                        {params.get('step') === '1' && (
                            <aside className="order-wizard-container">
                                <div className="order-wizard-number">
                                    <span className="order-wizard-item active">1</span>
                                    <hr className="order-wizard-divide " />
                                    <span className="order-wizard-item ">2</span>
                                    <hr className="order-wizard-divide" />
                                    <span className="order-wizard-item">3</span>
                                </div>
                                <div className="order-wizard-text">
                                    <span className="order-wizard-item active">CART</span>
                                    <span className="order-wizard-item ">PAYMENT</span>
                                    <span className="order-wizard-item">DOWNLOAD</span>
                                </div>
                            </aside>
                        )}
                        {params.get('step') === '2' && (
                            <aside className="order-wizard-container">
                                <div className="order-wizard-number">
                                    <span className="order-wizard-item before">1</span>
                                    <hr className="order-wizard-divide active" />
                                    <span className="order-wizard-item active">2</span>
                                    <hr className="order-wizard-divide" />
                                    <span className="order-wizard-item">3</span>
                                </div>
                                <div className="order-wizard-text">
                                    <span className="order-wizard-item active">CART</span>
                                    <span className="order-wizard-item active">PAYMENT</span>
                                    <span className="order-wizard-item">DOWNLOAD</span>
                                </div>
                            </aside>
                        )}
                        {params.get('step') === '3' && (
                            <aside className="order-wizard-container">
                                <div className="order-wizard-number">
                                    <span className="order-wizard-item before">1</span>
                                    <hr className="order-wizard-divide active" />
                                    <span className="order-wizard-item before">2</span>
                                    <hr className="order-wizard-divide active" />
                                    <span className="order-wizard-item before" >3</span>
                                </div>
                                <div className="order-wizard-text">
                                    <span className="order-wizard-item before">CART</span>
                                    <span className="order-wizard-item before">PAYMENT</span>
                                    <span className="order-wizard-item active" >DOWNLOAD</span>
                                </div>
                            </aside>
                        )}
                    </div>
                    {params.get('step') === '1' &&
                        <>
                            <div className="flex items-start justify-between w-full flex-wrap gap-[3%] pb-5 max-w-[1200px] mx-auto">
                                <div className="flex-1 w-full">
                                    <div className="flex items-center justify-between ">
                                        <div className="text-[16px] md:text-[30px] ">
                                            <ShoppingCart size={22} color="white" className="inline-block align-middle" />
                                            <span className="inline-block pl-1 font-semibold align-middle md:pl-3">Checkout</span>
                                            <span className="inline-block pl-1 align-middle md:pl-3">({items?.length} items)</span>
                                        </div>
                                        {items?.length > 0 &&
                                            <div className="cursor-pointer" onClick={() => emptyCart()}>
                                                <Trash size={22} color="white" className="inline-block align-middle" />
                                                <span className="inline-block align-middle text-[16px] md:text-[18px] font-semibold pl-1 md:pl-3">Empty Cart</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="hidden md:basis-1/3"></div>
                            </div>
                            <div className="flex flex-col md:flex-row items-start justify-between w-full flex-wrap gap-[3%] max-w-[1200px] mx-auto">
                                <div className="flex-1 w-full">
                                    {items?.length > 0 &&
                                        <div className="bg-[#171717] p-5 -mx-[30px] md:mx-0   md:rounded-md">
                                            {items?.map((item, index) => (
                                                <div className={`w-full flex  ${items?.length === 1 ? "" : "border-b  border-[#373737] mb-5 pb-5"}`} key={index}>
                                                    <div className="w-[90%] md:w-3/4 flex">
                                                        <div className="w-[130px]">
                                                            <img src={item?.variant?.urls?.thumbnail_url} alt="" className="w-full aspect-[9/16] rounded-md" />
                                                        </div>
                                                        <div className="flex-1 pl-[1em]">
                                                            <p className="font-semibold text-[18px] md:text-[20px] tracking-wide leading-[24px]">{item?.title}</p>
                                                            <p className="pt-[1em] text-[16px]">{item?.variant?.name}</p>
                                                            <p className="pt-[1em] text-[16px]">₹{item?.variant?.exclusive === true ? item?.variant?.exclusive_price?.toFixed(2) : item?.variant?.discounted_price?.toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-start justify-end flex-1" onClick={() => deleteItem(item?.videoId)}>
                                                        <button className="hover:bg-[#101010] h-12 w-12 rounded-md flex items-center justify-center">
                                                            <Trash size={32} color="white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <div className="bg-[#171717] p-5 mt-10 -mx-[30px] md:mx-0   md:rounded-md">
                                        <div className="border-b border-t border-[#373737] py-5">
                                            <div className="w-full w-max-[300px] mb-3 relative bg-[#2b2b2b] rounded-[5px] shadow-md shadow-[#191919]">
                                                <div onClick={() => showWarning("Promo Code not valid")} className="absolute h-full cursor-pointer  w-fit px-[30px] right-0 rounded-r-full flex items-center justify-center">
                                                    <p className="text-[#ff1d4e] font-medium">Apply</p>
                                                </div>
                                                <form onSubmit={(event) => {
                                                    event.preventDefault(); showWarning("Promo Code not valid")
                                                }}>
                                                    <input
                                                        type="text"
                                                        className="h-[50px] appearance-none outline-none w-full bg-transparent px-4 placeholder:text-[#808080] placeholder:tracking-wide placeholder:font-light tracking-wide"
                                                        placeholder="Promo Code"
                                                        autoCapitalize="off"
                                                        autoCorrect="off"
                                                        enterKeyHint="search"
                                                        spellCheck="false"
                                                    />
                                                </form>
                                            </div>
                                            <div className="w-full font-medium tracking-wide text-[18px] text-[#817272]  py-1 flex justify-between items-center">
                                                <p className="">Subtotal</p>
                                                <p className="">₹{(items.reduce((total, item) => total + (item?.variant?.exclusive === true ? item?.variant?.exclusive_price : item.variant?.discounted_price || 0), 0))?.toFixed(2)}</p>
                                            </div>
                                            <div className="w-full font-medium tracking-wide text-[18px] text-[#817272]  py-1 flex justify-between items-center">
                                                <p className="">Tax</p>
                                                <p className="">₹0</p>
                                            </div>
                                            <div className="w-full font-medium tracking-wide text-[18px] text-[#817272]  py-1 flex justify-between items-center">
                                                <p className="">Discount</p>
                                                <p className="">₹0</p>
                                            </div>
                                            <div className="w-full font-medium tracking-wide text-[18px] text-[#817272]  py-1 flex justify-between items-center">
                                                <p className="">Offer Discount</p>
                                                <p className="">₹0</p>
                                            </div>
                                            <div className="w-full font-medium tracking-wide text-[18px]  py-1 flex justify-between items-center">
                                                <p className="">Total</p>
                                                <p className="">₹{(items.reduce((total, item) => total + (item?.variant?.exclusive === true ? item?.variant?.exclusive_price : item.variant?.discounted_price || 0), 0))?.toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center w-full mt-5">
                                            <button onClick={() => generateCheckoutLink()} className="py-3 px-8 w-full flex items-center justify-center gap-5 text-sm md:text-base rounded-md bg-[#F92147] hover:bg-[#f9215e] font-semibold">
                                                {/* {paymentLoading ?
                                                    <CircleNotch size={22} color="white" className="animate-spin" />
                                                    : */}
                                                <span className="">Make Payment</span>
                                                {/* } */}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {(params.get('step') === '3' && downloadMetadata.length > 0) &&
                        <>
                            <div className="flex flex-col items-center">
                                <div className="h-12 w-12 rounded-full border border-[#F92147] flex items-center justify-center">
                                    <Check size={22} color="#F92147" />
                                </div>
                                <p className="mt-3 font-medium tracking-wide text-center">Thank you for purchase, Download your purchased files by clicking on download below.</p>


                            </div>
                            <div className="mt-10">
                                <p className="pb-5 text-2xl font-medium">Your downloads</p>
                                <div className="bg-[#171717] p-5 -mx-[30px] md:mx-0   md:rounded-md">
                                    {downloadMetadata?.map((item, index) => (
                                        <div className={`w-full flex flex-col md:flex-row ${downloadMetadata?.length === 1 ? "" : "border-b  border-[#373737] mb-5 pb-5"}`} key={index}>
                                            <div className="flex w-full md:w-3/4">
                                                <div className="w-[130px]">
                                                    <img src={item?.variants[0]?.urls?.thumbnail_url} alt="" className="w-full aspect-[9/16] rounded-md" />
                                                </div>
                                                <div className="flex-1 pl-[1em]">
                                                    <p className="font-semibold text-[18px] md:text-[20px] tracking-wide leading-[24px]">{item?.title}</p>
                                                    <p className="pt-[1em] text-[16px]">{item?.variants[0]?.name}</p>
                                                </div>
                                            </div>
                                            <div className="flex items-start justify-end flex-1 mt-3 md:ml-5 md:mt-0" onClick={() => downloadVideo(item?.variants[0]?.urls?.video_url)}>
                                                <button className="py-3 px-8 w-full flex items-center justify-center gap-5 text-sm md:text-base rounded-md bg-[#F92147]  font-semibold">
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
            <NotloginfooterNew />
        </div>
    );
}
