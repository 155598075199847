import { Eye, Hexagon, Plus, } from '@phosphor-icons/react';
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../context/StateProvider';
import moment from 'moment';

export default function Leads() {
    const router = useHistory();
    const [leads, setLeads] = useState([]);
    const [{ brand, user }, dispatch] = useStateValue();

    useEffect(() => {
        async function getLeads() {
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: "https://massemailer-zscu3untuq-as.a.run.app/leads/" + brand?.id,
                method: "GET",
                headers: headersList,
            }
            let response = await axios.request(reqOptions);
            if (!response.data.success) {
                return
            }
            setLeads(response.data.data.leads)
        }
        getLeads()
    }, [])

    return (
        <div className="mt-10">
            <div className="flex items-center justify-between px-5">
                <p className="text-3xl font-medium tracking-wide text-white">
                    Leads
                </p>
                <button onClick={() => router.push("/admin/emails/addlead")} className="bg-[#93A8F4] h-12 rounded-lg flex items-center gap-3 px-5 appearance-none outline-none">
                    <Plus size={22} color='#f8f8f8' />
                    Add New Lead
                </button>
            </div>
            <div className="relative block px-5 mt-10 mb-10">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="h-14 bg-[#131418]">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Email address
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Full name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Segment
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Subscription status
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Added at
                                    </th>
                                    {/* <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">

                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {leads.map((lead, index) => (
                                    <React.Fragment key={index}>
                                        {/* <tr className="table-row-spacer"></tr> */}
                                        <tr className="table-row align-[inherit]  bg-[#131418] border-t border-opacity-10 border-white"  >
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                {index + 1}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                {lead?.email}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                {lead?.fullName}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                {lead?.tags.join(", ")}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                {lead?.subscribed ? <div className='bg-green-500 w-fit px-4 py-[5px] rounded text-white'>Subscribed</div> : <div className='bg-red-500 w-fit px-4 py-[5px] rounded text-white'>Not Subscribed</div>}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                {moment(lead?.updatedAt).format('MMM DD, YYYY h:mm A')}
                                            </td>
                                            {/* <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                                <button className="h-10 w-10 flex items-center justify-center relative text-[#3c3c3d] hover:text-white">
                                                    <Hexagon size={40} weight="light" color='currentcolor' />
                                                    <Eye
                                                        size={20}
                                                        className="absolute"
                                                        weight="bold"
                                                        color='currentcolor'
                                                    />
                                                </button>
                                            </td> */}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        {leads.length === 0 &&
                            <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                No Leads Available
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
