import React from "react";
import { useState } from "react";
import { nanoid } from "nanoid";
import axios from "axios";
import { useStateValue } from "../context/StateProvider";
import { CircleNotch, Plus, Trash, X } from "@phosphor-icons/react";
import HalfStarRating from "../components/halfstarrating";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { showWarning } from "../components/toast/toast";

export default function ImportVideos() {
  const [importLinks, setImportLinks] = useState([""]);
  const [importedLinks, setImportedLinks] = useState([]);
  const [imported, setImported] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [{ user, products, brand }, dispatch] = useStateValue();
  const [productSearchText, setProductSearchText] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const router = useHistory();

  // Function to handle changes in the input fields
  const handleInputChange = (index, value) => {
    const updatedImportLinks = [...importLinks];
    updatedImportLinks[index] = value;
    setImportLinks(updatedImportLinks);
  };
  const validateLinkFormat = (index) => {
    const updatedImportLinks = [...importLinks];
    const inputValue = updatedImportLinks[index];

    const youtubeRegex =
      /^https?:\/\/(?:www\.)?youtube\.com\/shorts\/[a-zA-Z0-9_-]+(\?.*)?$/;
    const instagramRegex =
      /^https?:\/\/(?:www\.)?instagram\.com\/reel\/[a-zA-Z0-9_-]+(\/\?.*)?$/;

    if (!youtubeRegex.test(inputValue) && !instagramRegex.test(inputValue)) {
      updatedImportLinks[index] = "";
      setImportLinks(updatedImportLinks);
      showWarning("Url format is incorrect");
      return;
    }
  };

  // Function to add a new input field
  const addInput = (index) => {
    // setImportLinks([...importLinks, '']);

    if (importedLinks.length >= 3) {
      showWarning("Only 3 Videos can be uploaded at a time.");
      return;
    }
    setImportedLinks((links) => [...importedLinks, {}]);
  };

  // Function to delete an input field by its index
  const deleteInput = (index) => {
    const updatedImportLinks = [...importedLinks];
    updatedImportLinks.splice(index, 1);
    setImportedLinks(updatedImportLinks);
  };

  async function importVideos() {
    if (importLinks.length === 0) {
      showWarning("Please add atleast 1 link");
      return;
    }
    const hasEmptyString = importLinks.some((link) => link.trim() === "");

    if (hasEmptyString) {
      showWarning("Please add correct links before submitting.");
      return;
    }
    try {
      setImportLoading(true);
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };
      let bodyContent = {
        links: importLinks,
      };
      let reqOptions = {
        url: "https://stripeugcapi-zscu3untuq-as.a.run.app/brandadmin/importVideos",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };
      let response = await axios.request(reqOptions);
      // console.log(response.data.contentLinks);
      setImportedLinks(response.data.contentLinks);
      setImported(true);
      setImportLoading(false);
    } catch (error) {
      setImported(false);
      setImportLoading(false);
    }
  }
  async function SaveImportVideos() {
    if (importedLinks.length === 0) {
      showWarning("There must atleast 1 video");
      return;
    }
    setSubmitLoading(true);
    const updatedLinks = [];
    const newLinks = [...importedLinks];

    newLinks.forEach((flick) => {
      updatedLinks.push({
        id: "review_" + nanoid(16),
        title: flick?.name,
        name: flick?.name,
        description: flick?.name,
        bpsId: flick?.prodId,
        flickTag: flick?.flickTag,
        uploadedBy: user?.appUserId,
        thumbnail: flick?.thumbnail,
        reviewUrl: flick?.reviewUrl,
        subFlicksTag: flick?.subFlicksTag,
        brand: brand?.id,
        views: 0,
        hearts: 0,
        rating: 0,
        type: "flicks",
        processed: false,
        subcategory: null,
        catId: null,
        reviewLanguage: flick?.language,
        purchaseMode: null,
        purchaseTime: null,
        orderId: null,
      });
    });
    async function validateUpdatedLinks(updatedLinks) {
      const isValidLinks = updatedLinks.every((flick, index) => {
        const requiredFields = [
          "id",
          "title",
          "name",
          "description",
          "bpsId",
          "flickTag",
          "uploadedBy",
          "thumbnail",
          "reviewUrl",
          "subFlicksTag",
        ];

        return requiredFields.every((field) => flick[field] !== undefined);
      });

      if (!isValidLinks) {
        showWarning("Please fill all details.");
        setSubmitLoading(false);
        return false;
      }

      const hasMinLengthTitle = updatedLinks.every((flick, index) => {
        if (flick.title.length < 20) {
          showWarning(`import video ${index + 1} has less than 20 characters`);
          return false;
        }
        return true;
      });

      if (!hasMinLengthTitle) {
        setSubmitLoading(false);
        return false;
      }

      return true;
    }
    // console.log(updatedLinks)

    const isValidLinks = await validateUpdatedLinks(updatedLinks);
    if (isValidLinks === false) {
      setSubmitLoading(false);
      return;
    }
    async function sendApiRequestsSequentially() {
      try {
        const headersList = {
          Accept: "*/*",
          Authorization:
            "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
        };

        for (const obj of updatedLinks) {
          const reqOptions = {
            url: `https://flicksapi-zscu3untuq-el.a.run.app/createFlicks`,
            method: "PUT",
            headers: headersList,
            data: obj,
          };

          const response = await axios.request(reqOptions);
          // console.log(response.data)
          console.log("done: " + obj?.id);
        }
      } catch (error) {
        console.error("Error creating documents:", error);
      }
    }
    async function getBrandFlicks() {
      let headersList = {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      };

      let reqOptions = {
        url:
          "https://adminapi-zscu3untuq-el.a.run.app/getBrandFlicks/" +
          brand?.id,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      sessionStorage.setItem("flicks", JSON.stringify(response.data));
      dispatch({ type: "SET_FLICKS", flicks: response.data });
    }
    await sendApiRequestsSequentially();
    await getBrandFlicks();
    setSubmitLoading(false);
    router.push("/admin/flicks");
  }
  function addFieldToObjects(objects, index, fieldName, fieldValue) {
    if (index < 0 || index >= objects.length) {
      return objects; // Index out of bounds, return the original array
    }

    const updatedObjects = objects.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          [fieldName]: fieldValue,
        };
      }
      return obj;
    });

    return updatedObjects;
  }
  const handleAddFields = (index, field, value) => {
    const updatedArray = addFieldToObjects(importedLinks, index, field, value);
    setImportedLinks(updatedArray);
  };
  async function uploadCoverImage(index, event) {
    const file = event.target.files[0];

    if (!file) {
      showWarning("No files selected.");
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = function () {
      const aspectRatio = img.width / img.height;

      if (aspectRatio !== 9 / 16) {
        showWarning("Image aspect ratio must be 9:16.");
        return;
      }

      if (img.height < 500) {
        showWarning("Image height must be at least 500px.");
        return;
      }

      const storage = getStorage();
      const storageRef = ref(storage, `importVideos/${Date.now()}.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            handleAddFields(index, "thumbnail", downloadURL);
          });
        }
      );
    };
  }
  async function validateVideo(file) {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      const fileReader = new FileReader();
      fileReader.onload = async function (event) {
        video.src = event.target.result;

        await video.play(); // Play the video to load metadata
        if (video.videoHeight < 600) {
          URL.revokeObjectURL(video.src); // Release the object URL
          showWarning("video height must be at least 700px.");
          resolve(false);
        } else {
          URL.revokeObjectURL(video.src); // Release the object URL
          resolve(true);
        }
      };

      fileReader.readAsDataURL(file);
    });
  }
  async function uploadLocalVideo(index, event) {
    const file = event.target.files[0];

    if (!file) {
      showWarning("No files selected.");
      return;
    }

    // const isValid = await validateVideo(file);

    // if (!isValid) {
    //     return;
    // }
    const storage = getStorage();
    const storageRef = ref(storage, `importVideos/${Date.now()}.mp4`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload progress: ${progress.toFixed(2)}%`);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        handleAddFields(index, "reviewUrl", downloadURL);
      }
    );
  }
  return (
    <div className="pt-5">
      <div className="px-5">
        <p className="text-3xl font-medium tracking-wide text-white">
          Import Videos
        </p>
      </div>
      <div className="px-5 pb-5">
        {imported === false ? (
          <div className="w-full p-5 mt-10 rounded-lg bg-bg_highlight">
            <div className="">
              <p className="">Add Links</p>
              <div className="mt-1 text-sm">
                <p className="font-light tracking-wide ">
                  Supported Url formats:
                </p>
                <p className="text-xs font-light tracking-wider font-work">
                  Youtube: https://www.youtube.com/shorts/
                  <span className="text-red-500">id</span>
                </p>
                <p className="text-xs font-light tracking-wider font-work">
                  Instagram: https://www.instagram.com/reel/
                  <span className="text-red-500">id</span>
                </p>
                {/* <p className="text-xs font-light tracking-wider font-work">https://www.facebook.com/reel/<span className="text-red-500">id</span></p> */}
              </div>
            </div>
            {importLinks.map((link, index) => (
              <div
                className="h-11 flex bg-[#2E3036] w-full items-center px-1 rounded-lg gap-3 my-2"
                key={index}
              >
                <div className="h-9 w-9 rounded-md bg-[#93A8F4] flex items-center justify-center">
                  {index + 1}
                </div>
                <div className="relative flex-1 w-full h-full">
                  <button
                    className="absolute flex items-center h-full px-1 right-1"
                    onClick={() => deleteInput(index)}
                  >
                    <Trash size={22} color="red" />
                  </button>
                  <input
                    type="text"
                    value={link}
                    onBlur={() => validateLinkFormat(index)}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    className="w-full h-full rounded-md appearance-none outline-none bg-transparent caret-[#93A8F4] tracking-wide"
                  />
                </div>
              </div>
            ))}
            <div className="flex items-center justify-center gap-5 my-5">
              <button
                className="bg-[#2E3036] w-fit px-6 rounded-full h-11 flex items-center gap-2"
                onClick={() => addInput()}
                disabled={importLoading}
              >
                <Plus size={22} color="white" />
                Add More
              </button>
              <button
                className="bg-[#93A8F4] w-fit px-12 rounded-full h-11 flex items-center gap-2"
                onClick={() => importVideos()}
              >
                {importLoading ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full p-5 mt-10 rounded-lg bg-bg_highlight">
            {importedLinks.map((link, index) => (
              <div key={index}>
                <div className="h-11 flex bg-[#2E3036] w-full items-center px-1 rounded-lg gap-3 mt-5 mb-2">
                  <div className="h-9 w-9 rounded-md bg-[#93A8F4] flex items-center justify-center">
                    {index + 1}
                  </div>
                  <div className="relative flex-1 w-full h-full">
                    <button
                      className="absolute flex items-center h-full px-1 right-1"
                      onClick={() => deleteInput(index)}
                    >
                      <Trash size={22} color="red" />
                    </button>
                    <input
                      type="text"
                      defaultValue={`import video ${index + 1}`}
                      readOnly
                      className="w-full h-full rounded-md appearance-none outline-none bg-transparent caret-[#93A8F4] tracking-wide"
                    />
                  </div>
                </div>
                <div className="flex items-start w-full gap-5">
                  <div className="flex gap-5">
                    <div className="">
                      <p className="text-sm tracking-wide font-work">Video</p>
                      <div className="w-48 aspect-[9/16] bg-[#2E3036] rounded-lg">
                        <video playsInline
                          src={link.reviewUrl}
                          controls
                          className="object-cover w-full h-full rounded-lg"
                          controlsList="nodownload noplaybackrate"
                          disablePictureInPicture
                        ></video>
                      </div>
                      <div className="flex justify-center">
                        <button className="relative mt-2 text-sm text-center hover:underline underline-offset-2 w-fit">
                          <input
                            type="file"
                            accept=".mp4"
                            className="absolute top-0 left-0 w-full h-full bg-white rounded-full opacity-0"
                            onChange={(event) => uploadLocalVideo(index, event)}
                          />
                          Upload video
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <p className="text-sm tracking-wide font-work">
                        Thumbnail
                      </p>
                      <div className="w-48 aspect-[9/16] bg-[#2E3036] rounded-lg">
                        <img
                          src={link.thumbnail}
                          className="object-cover w-full h-full rounded-lg"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-center">
                        <button className="relative mt-2 text-sm text-center hover:underline underline-offset-2 w-fit">
                          <input
                            type="file"
                            accept=".jpg,.png,.jpeg"
                            className="absolute top-0 left-0 w-full h-full bg-white rounded-full opacity-0"
                            onChange={(event) => uploadCoverImage(index, event)}
                          />
                          Upload cover image
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 w-full">
                    <div className=" bg-[#2E3036] h-[116px] rounded-xl w-full">
                      <textarea
                        onChange={(event) =>
                          handleAddFields(index, "name", event.target.value)
                        }
                        className="w-full h-full p-3 bg-transparent placeholder:text-[#8E8B8B] appearance-none outline-none"
                        placeholder="write a caption"
                      ></textarea>
                    </div>
                    <div className="relative w-full mt-2 group">
                      <div className=" mt-16 absolute w-full h-72 overflow-y-auto bg-[#202020] z-50 border border-[#808080] rounded-md hidden group-hover:flex flex-col">
                        <div className="flex items-center w-full h-12 gap-3 px-3 my-3">
                          <input
                            type="text"
                            value={productSearchText}
                            onChange={(event) =>
                              setProductSearchText(event.target.value)
                            }
                            className="flex-1 w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                            placeholder="Search by product name"
                          />
                          <button
                            className=""
                            onClick={() => setProductSearchText("")}
                          >
                            <X size={32} color="white" />
                          </button>
                        </div>
                        {products
                          ?.filter((val) =>
                            val?.name
                              ?.toLowerCase()
                              ?.includes(productSearchText?.toLowerCase())
                          )
                          ?.map((prod, pindex) => (
                            <div
                              className="w-full flex h-16 items-center justify-between cursor-pointer hover:bg-[#2E3036] px-3 py-2"
                              key={pindex}
                              onClick={() =>
                                handleAddFields(index, "prodId", prod.id)
                              }
                            >
                              <div className="flex items-center flex-1 w-full gap-3">
                                <div className="w-12 h-12 rounded-full">
                                  <img
                                    src={prod?.imageUrl}
                                    alt="productimage"
                                    className="w-full h-full rounded-full"
                                  />
                                </div>
                                <div className="flex-1 w-full">
                                  <p className="w-full text-sm tracking-wide line-clamp-3">
                                    {prod?.name}
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <HalfStarRating
                                  sizeh={25}
                                  colorcode={"#E2BE45"}
                                  rating={parseFloat(prod?.rating)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="flex items-center w-full gap-8">
                        <div className="w-16 h-16 rounded-full">
                          <div className=" cursor-pointer h-full w-full bg-[#434343] rounded-full text-xl flex items-center justify-center">
                            {link?.prodId === null ? (
                              <p className="">+</p>
                            ) : (
                              <img
                                src={
                                  products?.filter(
                                    (val) => val?.id === link?.prodId
                                  )[0]?.imageUrl
                                }
                                alt=""
                                className="w-full h-full rounded-full"
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex-1 w-full">
                          <div className="my-3">
                            <input
                              type="text"
                              readOnly
                              defaultValue={
                                products?.filter(
                                  (val) => val?.id === link?.prodId
                                )[0]?.name
                              }
                              className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                              placeholder="Product Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-5">
                                            <button className={`bg-[#2E3036] relative hover:bg-[#93A8F4] h-11 w-52 rounded-full appearance-none outline-none tracking-wide`} >
                                                <input type="file" className="absolute top-0 left-0 w-full h-full bg-white rounded-full opacity-0" onChange={(event) => uploadCoverImage(index, event)} />
                                                Upload Cover Image
                                            </button>
                                        </div> */}
                    <div className="mt-3">
                      <p className="text-lg tracking-wide">
                        Language {link?.language}
                      </p>
                      <div className="flex flex-wrap gap-3 mt-1">
                        <button
                          className={`${link.language === "English"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "language", "English")
                          }
                        >
                          English
                        </button>
                        <button
                          className={`${link.language === "Hindi"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "language", "Hindi")
                          }
                        >
                          Hindi
                        </button>
                        <button
                          className={`${link.language === "Telugu"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "language", "Telugu")
                          }
                        >
                          Telugu
                        </button>
                        <button
                          className={`${link.language === "Tamil"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "language", "Tamil")
                          }
                        >
                          Tamil
                        </button>
                        <button
                          className={`${link.language === "Marathi"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "language", "Marathi")
                          }
                        >
                          Marathi
                        </button>
                        <button
                          className={`${link.language === "Bengali"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "language", "Bengali")
                          }
                        >
                          Bengali
                        </button>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="text-lg tracking-wide">
                        What is this flick about{" "}
                        <span className="text-[#8E8B8B]">(choose any one)</span>
                      </p>
                      <div className="flex flex-wrap gap-3 mt-1">
                        <button
                          className={`${link.flickTag === "Get The Looks"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "flickTag", "Get The Looks")
                          }
                        >
                          #Get The Looks
                        </button>
                        <button
                          className={`${link.flickTag === "Haul Videos"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(index, "flickTag", "Haul Videos")
                          }
                        >
                          #Haul Videos
                        </button>
                        <button
                          className={`${link.flickTag === "Tutorial Videos"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(
                              index,
                              "flickTag",
                              "Tutorial Videos"
                            )
                          }
                        >
                          #Tutorial Videos
                        </button>
                        <button
                          className={`${link.flickTag === "Unboxing Videos"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(
                              index,
                              "flickTag",
                              "Unboxing Videos"
                            )
                          }
                        >
                          #Unboxing Videos
                        </button>
                        <button
                          className={`${link.flickTag === "Store Visit Videos"
                            ? "bg-[#93A8F4]"
                            : "bg-[#2E3036]"
                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                          onClick={() =>
                            handleAddFields(
                              index,
                              "flickTag",
                              "Store Visit Videos"
                            )
                          }
                        >
                          #Store Visit Videos
                        </button>
                      </div>
                    </div>
                    {!link?.flickTag ? null : (
                      <div className="mt-3">
                        <p className="text-lg tracking-wide">
                          More Specifically..
                        </p>
                        {link.flickTag === "Get The Looks" && (
                          <div className="flex flex-wrap gap-3 mt-1">
                            <button
                              className={`${link.subFlicksTag === "Ethnic Wear Tips"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Ethnic Wear Tips"
                                )
                              }
                            >
                              #Ethnic Wear Tips
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Wedding Wear"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Wedding Wear"
                                )
                              }
                            >
                              #Wedding Wear
                            </button>
                            <button
                              className={`${link.subFlicksTag === "SkinCare Routine Tips"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "SkinCare Routine Tips"
                                )
                              }
                            >
                              #SkinCare Routine Tips
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Cocktail Wear"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Cocktail Wear"
                                )
                              }
                            >
                              #Cocktail Wear
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Vintage & Retro Styling"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Vintage & Retro Styling"
                                )
                              }
                            >
                              #Vintage & Retro Styling
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Fitness & Health Tips"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Fitness & Health Tips"
                                )
                              }
                            >
                              #Fitness & Health Tips
                            </button>
                          </div>
                        )}
                        {link.flickTag === "Tutorial Videos" && (
                          <div className="flex flex-wrap gap-3 mt-1">
                            <button
                              className={`${link.subFlicksTag === "Makeup Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Makeup Tutorial"
                                )
                              }
                            >
                              #Makeup Tutorial
                            </button>
                            <button
                              className={`${link.subFlicksTag === "SkinCare Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "SkinCare Tutorial"
                                )
                              }
                            >
                              #SkinCare Tutorial
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Smart Devices Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Smart Devices Tutorial"
                                )
                              }
                            >
                              #Smart Devices Tutorial
                            </button>
                            <button
                              className={`${link.subFlicksTag === "App-Web Tools Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "App-Web Tools Tutorial"
                                )
                              }
                            >
                              #App-Web Tools Tutorial
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Nail Art Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Nail Art Tutorial"
                                )
                              }
                            >
                              #Nail Art Tutorial
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Healthy Cooking Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Healthy Cooking Tutorial"
                                )
                              }
                            >
                              #Healthy Cooking Tutorial
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Hair Styling Tutorial"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Hair Styling Tutorial"
                                )
                              }
                            >
                              #Hair Styling Tutorial
                            </button>
                          </div>
                        )}
                        {link.flickTag === "Haul Videos" && (
                          <div className="flex flex-wrap gap-3 mt-1">
                            <button
                              className={`${link.subFlicksTag === "Food Haul"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Food Haul"
                                )
                              }
                            >
                              #Food Haul
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Tech Haul"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Tech Haul"
                                )
                              }
                            >
                              #Tech Haul
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Beauty Haul"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Beauty Haul"
                                )
                              }
                            >
                              #Beauty Haul
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Fashion Haul"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Fashion Haul"
                                )
                              }
                            >
                              #Fashion Haul
                            </button>
                          </div>
                        )}
                        {link.flickTag === "Unboxing Videos" && (
                          <div className="flex flex-wrap gap-3 mt-1">
                            <button
                              className={`${link.subFlicksTag === "Food & Drink Unboxing"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Food & Drink Unboxing"
                                )
                              }
                            >
                              #Food & Drink Unboxing
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Beauty Unboxing"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Beauty Unboxing"
                                )
                              }
                            >
                              #Beauty Unboxing
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Gadget Unboxing"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Gadget Unboxing"
                                )
                              }
                            >
                              #Gadget Unboxing
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Fashion Unboxing"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Fashion Unboxing"
                                )
                              }
                            >
                              #Fashion Unboxing
                            </button>
                          </div>
                        )}
                        {link.flickTag === "Store Visit Videos" && (
                          <div className="flex flex-wrap gap-3 mt-1">
                            <button
                              className={`${link.subFlicksTag === "Offline Store Visit"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Offline Store Visit"
                                )
                              }
                            >
                              #Offline Store Visit
                            </button>
                            <button
                              className={`${link.subFlicksTag === "Online Store Visit"
                                ? "bg-[#93A8F4]"
                                : "bg-[#2E3036]"
                                } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                              onClick={() =>
                                handleAddFields(
                                  index,
                                  "subFlicksTag",
                                  "Online Store Visit"
                                )
                              }
                            >
                              #Online Store Visit
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex items-center justify-center gap-5 my-5">
              <button
                className="bg-[#2E3036] w-fit px-6 rounded-full h-11 flex items-center gap-2"
                onClick={() => addInput()}
              >
                Add more
              </button>
              <button
                className="bg-[#93A8F4] w-fit px-12 rounded-full h-11 flex items-center gap-2"
                onClick={() => SaveImportVideos()}
              >
                {submitLoading ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
