import { AmazonLogo, Check, CheckCircle, FacebookLogo, InstagramLogo, YoutubeLogo } from '@phosphor-icons/react';
import React from 'react'

export default function Brief({ campaign }) {
    const videoTypes = [
        "",
        "Video Ad",
        "Unboxing Video",
        "Tutorial Video",
        "Get The Look Video",
        "Haul Video",
        "360 Showcase Video",
        "Testimonial Video",
        "Store Visit Video",
    ];
    const videoTypesDescription = [
        "",
        "Best for ads on Facebook, Instagram, YouTube, etc.",
        "Opening of new product and showcasing its content and features.",
        "Record how to use or step by step instructions video.",
        "Showcases how to recreate a specific style or look.",
        "Showcasing and reviewing a collection of products they have purchased.",
        "Show your product from every angle.",
        "Highlights your product benefits.",
        "Record a walkin video in brand store.",
    ];

    const inHouseCeators = [
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR1.webp?alt=media&token=45bdbe9d-797e-47cb-9e06-e860ebaade8b",
            gender: "female",
            id: "MR1",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol1.mp4?alt=media&token=1c623df3-3586-443e-857c-d4602a08ffd4",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol2.mp4?alt=media&token=b08eb87a-7c95-4963-8bf2-12dd60d78610",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol3.mp4?alt=media&token=8ba06cca-84de-4cf8-98b9-2924acb4c7e0",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol4.mp4?alt=media&token=2dfdd881-dfdc-4659-a635-2ff78ed66045",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR2.webp?alt=media&token=ef789f52-b273-4742-89bd-34d3d3edcb1a",
            gender: "female",
            id: "MR2",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini1.mp4?alt=media&token=f0c92456-32b9-4cfc-a8b0-713022702cdd",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini2.mp4?alt=media&token=6a0b0d49-b92b-4af5-ae47-0b00c5c96ede",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR3.webp?alt=media&token=03e74c19-ea6e-47a6-a47d-01bdd3191b3f",
            gender: "female",
            id: "MR3",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy1.mp4?alt=media&token=78ab7b00-1b2a-4e63-87ee-f11482faf725",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy2.mp4?alt=media&token=46106ffd-4267-4b02-9bbe-2899768fd2d8",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy3.mp4?alt=media&token=e581f916-91c8-4fff-9f01-bd38d10a7966",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR4.webp?alt=media&token=ad8b341f-7ace-41c9-ac27-0a46ac5d383a",
            gender: "female",
            id: "MR4",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan1.mp4?alt=media&token=7a4e45ee-9890-4c93-9e9c-1b320914eb16",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan2.mp4?alt=media&token=80a1ace7-6ed9-4a12-be33-88ede3708a29",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan3.mp4?alt=media&token=d4e574b4-f047-4b88-9a58-64d41b0c7a47",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan4.mp4?alt=media&token=2b3aa29d-19f9-46bc-8352-5bb15098e2dd",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR5.webp?alt=media&token=7a2f4fab-9a30-41cf-a17c-2414a97962d9",
            gender: "female",
            id: "MR5",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak1.mp4?alt=media&token=521c8f94-f535-4f97-86d8-7ad2036b80ac",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak2.mp4?alt=media&token=bedd1e0b-3398-4e0d-a2fb-0bb13ceaf33a",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak3.mp4?alt=media&token=f7948f01-26c7-41b2-93e8-0820246244f5",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR6.webp?alt=media&token=fa352735-b0f7-4f7f-8382-9233fa85d09a",
            gender: "female",
            id: "MR6",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti1.mp4?alt=media&token=c6fc3274-15fe-48a9-ac1c-6327c63dbc73",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti2.mp4?alt=media&token=2e6d7c99-0d42-4384-9004-e58edddd1dd0",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti3.mp4?alt=media&token=09fb22a6-4e78-4092-9233-2afddcc74451",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti4.mp4?alt=media&token=a56c66cf-1665-4e1c-b662-a61aa764f553",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti5.mp4?alt=media&token=a01ccba0-0695-4504-83c3-52ff8f0a0a9f",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti6.mp4?alt=media&token=4bd5d875-6478-40c1-af1e-5d664415708a",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR7.webp?alt=media&token=a1fcb7ad-9a8e-43f5-a678-16a2b312df2e",
            gender: "female",
            id: "MR7",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi1.mp4?alt=media&token=35ed07fb-787e-4128-a458-c628861a51cd",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi2.mp4?alt=media&token=8b272888-898b-4d98-931d-70b44ad9c13c",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi3.mp4?alt=media&token=e4589efe-8fc2-4105-a9fa-1bf0a794ed1a",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi4.mp4?alt=media&token=6813ec5c-65c2-44c7-874e-fbabdf4d0756",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi5.mp4?alt=media&token=4274e1cf-b39b-41ad-adfe-a553ecea9142",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR8.webp?alt=media&token=15e6aecd-7edb-44f0-a070-8805f0306917",
            gender: "female",
            id: "MR8",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVarusha1.mp4?alt=media&token=f09a983d-64fe-4728-b9d5-dd974214cd08",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVarusha2.mp4?alt=media&token=36b8a21e-8076-4965-86f0-50d6e38b2857",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVarusha3.mp4?alt=media&token=d3522737-6d3f-4493-a298-37d6a119f9dc",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR51.webp?alt=media&token=8cab2891-74a4-47ba-bd00-16dbc28f8dc5",
            gender: "male",
            id: "MR9",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh1.mp4?alt=media&token=b7b7271e-de84-4944-a8dc-77565b207148",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh2.mp4?alt=media&token=5fef69a5-af4f-4a73-bbd2-74c381345ae0",
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR52.webp?alt=media&token=c4878f9c-12cb-4f02-aa46-3410ae999136",
            gender: "male",
            id: "MR10",
            videos: [
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag1.mp4?alt=media&token=172ad69d-7f1c-446e-a867-26bce250fc85",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag2.mp4?alt=media&token=28e9c419-161c-420b-a25a-36690f7d9029",
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag3.mp4?alt=media&token=202773d7-b9f2-4e83-8916-c5a5971751c5",
            ],
        },
    ];
    return (
        <>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="text-lg tracking-wide font-work ">
                    Is your product physical or digital?
                </p>
                <div className="flex items-center gap-5 mt-3 font-work">
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[122px] w-[200px] shadow-gray-600 border-4 ${campaign?.productType === "Physical"
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md flex flex-col items-center justify-center gap-1`}
                    >
                        <div className="">
                            <svg
                                width="40"
                                height="40"
                                fill={`${campaign?.productType === "Physical"
                                    ? "#93A8F4"
                                    : "#ffffff"
                                    }`}
                                className="bui-icon"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" d="M0 0H24V24H0z"></path>
                                <path d="M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-6-2c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2zm6 16H6V8h2v2c0 .55.45 1 1 1s1-.45 1-1V8h4v2c0 .55.45 1 1 1s1-.45 1-1V8h2v12z"></path>
                            </svg>
                        </div>
                        <div className="font-work">Physical</div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[122px] w-[200px] shadow-gray-600 border-4 ${campaign?.productType === "Digital"
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md flex flex-col items-center justify-center gap-1`}
                    >
                        <div className="">
                            <svg
                                width="40"
                                height="40"
                                fill={`${campaign?.productType === "Digital"
                                    ? "#93A8F4"
                                    : "#ffffff"
                                    }`}
                                className="bui-icon"
                                viewBox="0 0 24 24"
                            >
                                <path d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"></path>
                            </svg>
                        </div>
                        <div className="font-work">Digital</div>
                    </div>
                </div>
            </div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="pb-3 text-lg tracking-wide font-work">Video type</p>
                <div className="flex flex-wrap gap-5">
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[1]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[1]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[2]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[2]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[3]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[3]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[4]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[4]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[5]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[5]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[6]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[6]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[7]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[7]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[8]
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="text-[#93A8F4] font-medium text-lg font-work">
                            {videoTypes[8]}
                        </div>
                        <div className="text-[13px] font-work">
                            {videoTypesDescription[8]}
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b mt-10 m-5 border-[#555555]"></div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="pb-3 text-lg tracking-wide font-work">Creator type</p>
                <div className="flex flex-wrap gap-5">
                    <div
                        className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${campaign?.creatorsType === "inhouse"
                            ? "border-[#93A8F4]"
                            : "border-transparent hover:border-[#93A8F4] shadow-sm"
                            } rounded-md flex flex-col items-center justify-center`}
                    >
                        <div
                            className={`text-center font-work mt-4 text-2xl font-medium ${campaign?.creatorsType === "inhouse"
                                ? "text-[#93A8F4]"
                                : "text-white"
                                }`}
                        >
                            Inhouse video
                        </div>
                    </div>
                    <div
                        className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${campaign?.creatorsType !== "inhouse"
                            ? "border-[#93A8F4]"
                            : "border-transparent hover:border-[#93A8F4] shadow-sm"
                            } rounded-md flex flex-col items-center justify-center`}
                    >
                        <div
                            className={`text-center font-work mt-4 text-2xl font-medium ${campaign?.creatorsType !== "inhouse"
                                ? "text-[#93A8F4]"
                                : "text-white"
                                }`}
                        >
                            Influencer video
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <p className="text-white/70 font-work">
                        Inhouse video: We take care of script writing, content creator,
                        videographer, quality check, editing
                    </p>
                    <p className="text-white/70 font-work">
                        Influencer video: Influencer is reponsible of all shooting &
                        editing
                    </p>
                </div>
            </div>
            {campaign?.creatorsType === "inhouse" && (
                <>
                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Creators</p>
                        <div className="flex flex-wrap gap-5">
                            {inHouseCeators?.map((creator, index) => (
                                <div
                                    key={index}
                                    className={`w-52 h-16 px-3 flex items-center gap-3 rounded-md bg-background hover:bg-black/30 border border-[#555555] ${campaign?.creators?.includes(creator?.id) &&
                                        "!bg-black/30"
                                        }`}
                                >
                                    <div className="relative h-12 rounded-full cursor-pointer aspect-square">
                                        {campaign?.creators?.includes(creator?.id) && (
                                            <div className="absolute flex items-center justify-center w-full h-full rounded-full bg-black/70">
                                                <CheckCircle
                                                    size={32}
                                                    color="#93A8F4"
                                                    weight="duotone"
                                                />
                                            </div>
                                        )}
                                        <img
                                            src={creator?.dp}
                                            alt="dp"
                                            className="w-full h-full rounded-full"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <p className="leading-none tracking-wide font-work ">
                                            {creator?.id}
                                        </p>
                                        <p className="text-sm leading-none tracking-wide font-work text-white/50 first-letter:uppercase">
                                            {creator?.gender}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            <div className="border-b mt-10 m-5 border-[#555555]"></div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="pb-3 text-lg tracking-wide font-work">
                    Where your {campaign?.videoType} will be displayed
                </p>
                <div className="flex flex-wrap gap-5">
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Facebook"
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="flex items-center justify-center mb-5">
                            <FacebookLogo
                                size={52}
                                color={
                                    campaign?.adDisplayed === "Facebook"
                                        ? "#93A8F4"
                                        : "#ffffff"
                                }
                                weight="fill"
                            />
                        </div>
                        <div className="text-[13px] font-work text-center">
                            Facebook
                        </div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Instagram"
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="flex items-center justify-center mb-5">
                            <InstagramLogo
                                size={52}
                                color={
                                    campaign?.adDisplayed === "Instagram"
                                        ? "#93A8F4"
                                        : "#ffffff"
                                }
                                weight="fill"
                            />
                        </div>
                        <div className="text-[13px] font-work text-center">
                            Instagram
                        </div>
                    </div>
                    {/* <div
                className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Twitter"
                  ? "border-[#93A8F4]"
                  : "border-transparent shadow-sm"
                  } rounded-md p-4`}
              >
                <div className="flex items-center justify-center mb-5">
                  <TwitterLogo
                    size={52}
                    color={
                      campaign?.adDisplayed === "Twitter"
                        ? "#93A8F4"
                        : "#ffffff"
                    }
                    weight="fill"
                  />
                </div>
                <div className="text-[13px] font-work text-center">Twitter</div>
              </div> */}
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Amazon"
                            ? "border-[#93A8F4]"
                            : "border-transparent shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="flex items-center justify-center mb-5">
                            <AmazonLogo
                                size={52}
                                color={
                                    campaign?.adDisplayed === "Amazon" ? "#93A8F4" : "#ffffff"
                                }
                                weight="fill"
                            />
                        </div>
                        <div className="text-[13px] font-work text-center">Amazon</div>
                    </div>
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "YouTube"
                            ? "border-[#93A8F4]"
                            : "border-transparent  shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="flex items-center justify-center mb-5">
                            <YoutubeLogo
                                size={52}
                                color={
                                    campaign?.adDisplayed === "YouTube"
                                        ? "#93A8F4"
                                        : "#ffffff"
                                }
                                weight="fill"
                            />
                        </div>
                        <div className="text-[13px] font-work text-center">YouTube</div>
                    </div>
                    {/* <div
                className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Snapchat"
                  ? "border-[#93A8F4]"
                  : "border-transparent  shadow-sm"
                  } rounded-md p-4`}
              >
                <div className="flex items-center justify-center mb-5">
                  <SnapchatLogo
                    size={52}
                    color={
                      campaign?.adDisplayed === "Snapchat"
                        ? "#93A8F4"
                        : "#ffffff"
                    }
                    weight="fill"
                  />
                </div>
                <div className="text-[13px] font-work text-center">
                  Snapchat
                </div>
              </div> */}
                </div>
            </div>
            <div className="border-b mt-10 m-5 border-[#555555]"></div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="pb-3 text-lg tracking-wide font-work">
                    Choose video format
                </p>
                <div className="flex flex-wrap gap-5">
                    <div
                        className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoFormat === "9:16"
                            ? "border-[#93A8F4]"
                            : "border-transparent  shadow-sm"
                            } rounded-md p-4`}
                    >
                        <div className="flex items-center justify-center mb-3">
                            <div
                                className={`h-12 aspect-[10/16] border-4 border-[${campaign?.videoFormat === "9:16" ? "#93A8F4" : "#ffffff"
                                    }]`}
                            ></div>
                        </div>
                        <div className="text-[13px] font-work text-center">9:16</div>
                        <div className="text-[12px] font-work text-center">
                            Portrait
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b mt-10 m-5 border-[#555555]"></div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <div className="flex items-start justify-between w-full">
                    <div className="">
                        <p className="pb-3 text-lg tracking-wide font-work">Photos</p>
                        <p className="pb-3 text-lg font-work">
                            4 authentic photos of your product from each creator
                        </p>
                        <button className="h-12 w-fit px-8 rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                            {campaign?.photos === true && (
                                <Check size={28} color="#6200ee" />
                            )}
                            <p className="">
                                {" "}
                                {campaign?.photos === true ? "Added" : "Add"} - ₹800 / (₹200
                                per photo)
                            </p>
                        </button>
                    </div>
                    <div className="flex gap-5 w-[390px]">
                        <div className="h-[185px]  aspect-square">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcontent_examples.png?alt=media&token=3f9e80a5-1226-4f4c-a3d3-0b051c10894d"
                                alt=""
                                className="h-[185px] aspect-square object-cover"
                            />
                        </div>
                        <div className="">
                            <p className="pb-3 tracking-wide font-work">
                                Upgrade your product listing or ads with authentic stills of
                                your product.
                            </p>
                            <p className="tracking-wide font-work pb-3 underline underline-offset-2 text-[#93A8F4]">
                                See examples
                            </p>
                        </div>
                    </div>
                </div>
                {campaign?.photos === true && (
                    <div className="w-full">
                        <textarea
                            className="h-32 bg-[#23252D] w-full mt-5 rounded-md p-3 font-work"
                            defaultValue={campaign?.photosDescription}
                            disabled
                            maxLength={300}
                        />
                        <div className="flex justify-between">
                            <p className="text-xs tracking-wide font-work text-[#b7b7b7]">
                                Edit to your preference
                            </p>
                            <p className="text-xs tracking-wide font-work text-[#b7b7b7]">
                                {campaign?.photosDescription?.length}/300
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className="border-b mt-10 m-5 border-[#555555]"></div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="pb-3 text-lg tracking-wide font-work">
                    Creator criteria
                </p>
                <div className="">
                    <p className="pb-1 tracking-wide font-work">Gender</p>
                    <select
                        className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                        disabled
                    >
                        <option value="">
                            {campaign?.creatorCriteria?.gender.charAt(0).toUpperCase() +
                                campaign?.creatorCriteria?.gender.slice(1)}
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="any">Any</option>
                    </select>
                </div>
                <div className="mt-5">
                    <p className="pb-1 tracking-wide font-work">Language</p>
                    <div className="flex gap-5">
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="english"
                                name="english"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "English"
                                )}
                            />
                            <label htmlFor="english" className="ml-2">
                                English
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="hindi"
                                name="hindi"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "Hindi"
                                )}
                            />
                            <label htmlFor="hindi" className="ml-2">
                                Hindi
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="bengali"
                                name="bengali"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "Bengali"
                                )}
                            />
                            <label htmlFor="bengali" className="ml-2">
                                Bengali
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="tamil"
                                name="tamil"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "Tamil"
                                )}
                            />
                            <label htmlFor="tamil" className="ml-2">
                                Tamil
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="telgu"
                                name="telgu"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "Telgu"
                                )}
                            />
                            <label htmlFor="telgu" className="ml-2">
                                Telgu
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="gujrati"
                                name="gujrati"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "Gujrati"
                                )}
                            />
                            <label htmlFor="gujrati" className="ml-2">
                                Gujrati
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                id="marathi"
                                name="marathi"
                                defaultChecked={campaign?.creatorCriteria?.language.includes(
                                    "Marathi"
                                )}
                            />
                            <label htmlFor="marathi" className="ml-2">
                                Marathi
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="pb-1 tracking-wide font-work">Age</p>
                    <div className="flex gap-5">
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                name="18-24"
                                defaultChecked={campaign?.creatorCriteria?.age?.includes(
                                    "18-24"
                                )}
                            />
                            <label htmlFor="18-24" className="ml-2">
                                18-24
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                name="25-34"
                                defaultChecked={campaign?.creatorCriteria?.age?.includes(
                                    "25-34"
                                )}
                            />
                            <label htmlFor="25-34" className="ml-2">
                                25-34
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                name="35-44"
                                defaultChecked={campaign?.creatorCriteria?.age?.includes(
                                    "35-44"
                                )}
                            />
                            <label htmlFor="35-44" className="ml-2">
                                35-44
                            </label>
                        </div>
                        <div className="">
                            <input
                                type="checkbox"
                                disabled
                                name="44+"
                                defaultChecked={campaign?.creatorCriteria?.age?.includes(
                                    "44+"
                                )}
                            />
                            <label htmlFor="44+" className="ml-2">
                                44+
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <p className="pb-1 tracking-wide font-work">Creator preference</p>
                    <input
                        type="text"
                        readOnly
                        className="h-10 w-full appearance-none outline-none rounded-md bg-[#23252D] px-3 tex-sm placeholder:text-sm"
                        placeholder="Looking for anything specific? Describe creator qualities (e.g. has a pet / can film in a car)"
                    />
                </div>
            </div>
            <div className="border-b mt-10 m-5 border-[#555555]"></div>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5 mb-8">
                <p className="pb-3 text-lg tracking-wide font-work">
                    Number of videos
                </p>
                <div className="flex w-full gap-5">
                    <div className="">
                        <div
                            className={`bg-[#23252d] cursor-pointer h-[152px] w-[200px] shadow-gray-600 shadow-sm rounded-md p-4`}
                        >
                            <div className="py-1 text-xs tracking-wide text-center">
                                How many videos you'd like to order?
                            </div>
                            <div className="text-[#93A8F4] text-2xl text-center font-medium mt-1">
                                {campaign?.videos} videos
                            </div>
                            <div className="flex items-center justify-center gap-5 mt-2 font-work">
                                <div className="h-10 w-10 rounded-full bg-[#93A8F4] flex items-center justify-center text-3xl font-light cursor-pointer select-none">
                                    -
                                </div>
                                <div className="h-10 w-10 rounded-full bg-[#93A8F4] flex items-center justify-center text-3xl font-light cursor-pointer select-none">
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#333640] p-3 rounded-md flex-1 w-full">
                        <p className="font-work">
                            Order will be carried out by{" "}
                            <span className="text-[#93A8F4]">
                                {campaign?.creatorsType === "inhouse"
                                    ? campaign?.creators?.length
                                    : campaign?.videos}{" "}
                                creators
                            </span>
                        </p>
                        <p className="my-2 text-sm font-medium tracking-wide font-work">
                            Your product shipping
                        </p>
                        <p className="my-2 text-sm tracking-wide font-work">
                            Note that you{" "}
                            <span className="underline underline-offset-2">
                                will have to send one product to each creator producing the
                                video (as a giveaway).
                            </span>
                        </p>
                        <p className="my-2 text-sm tracking-wide font-work">
                            The faster you ship products to approved creators, the faster
                            you can expect to receive the final videos.
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 bg-[#131418] m-5 rounded-md mt-5 p-5">
                <div className="">
                    <p className="pb-3 text-lg tracking-wide font-work">
                        Product Info
                    </p>
                    <div className="">
                        <p className="pb-1 tracking-wide font-work">Product Type</p>
                        <select
                            disabled
                            className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                        >
                            <option value="">
                                {campaign?.productDetails?.productType
                                    .charAt(0)
                                    .toUpperCase() +
                                    campaign?.productDetails?.productType.slice(1)}
                            </option>
                            <option value="Giveaway">Giveaway</option>
                            <option value="Pre Owned">Pre Owned</option>
                        </select>
                    </div>
                    <>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">Product Name</p>
                            <input
                                type="text"
                                readOnly
                                defaultValue={campaign?.productDetails?.productName}
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">
                                Product (SKU Code)
                            </p>
                            <input
                                type="text"
                                readOnly
                                defaultValue={campaign?.productDetails?.productSKU}
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">Product Link</p>
                            <input
                                type="text"
                                readOnly
                                defaultValue={campaign?.productDetails?.productLink}
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">Product Value</p>
                            <input
                                type="text"
                                readOnly
                                defaultValue={campaign?.productDetails?.productValue}
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        {campaign?.productDetails?.productType === "Giveaway" && (
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">
                                    Estimated Delivery Time
                                </p>
                                <input
                                    type="text"
                                    readOnly
                                    defaultValue={
                                        campaign?.productDetails?.estimatedDeliveryTime
                                    }
                                    placeholder=""
                                    className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                                />
                            </div>
                        )}
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">
                                Script/ Guidelines
                            </p>
                            <textarea
                                type="text"
                                readOnly
                                defaultValue={campaign?.productDetails?.script}
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-32 pt-2 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">
                                Reference Video (Link)
                            </p>
                            <input
                                type="text"
                                readOnly
                                defaultValue={campaign?.productDetails.referenceVideo}
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                    </>
                </div>
                <div className="">
                    <p className="pb-1 tracking-wide font-work">Product Image</p>
                    <div className="h-52 aspect-square bg-[#23252D] relative flex flex-col items-center justify-center rounded-md">
                        <div className="w-full h-full">
                            <img
                                src={campaign?.productDetails?.productImage}
                                alt="productimage"
                                className="object-cover w-full h-full rounded-md"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {campaign?.extra && (
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work ">
                        Other Information
                    </p>
                    <div className="">
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">
                                Brand Spoke Person Name{" "}
                                <span className="text-red-500">*</span>
                            </p>
                            <input
                                type="text"
                                defaultValue={campaign?.extra?.spokePersonName}
                                readOnly
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">
                                Brand Spoke Person Number{" "}
                                <span className="text-red-500">*</span>
                            </p>
                            <input
                                type="text"
                                defaultValue={campaign?.extra?.spokePersonNumber}
                                readOnly
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">Attire</p>
                            <input
                                type="text"
                                defaultValue={campaign?.extra?.attire}
                                readOnly
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">Hairstyle</p>
                            <input
                                type="text"
                                defaultValue={campaign?.extra?.hairstyle}
                                readOnly
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">
                                Location/Background
                            </p>
                            <input
                                type="text"
                                defaultValue={campaign?.extra?.location}
                                readOnly
                                placeholder=""
                                className="w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                            />
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work">Vocal</p>
                            <div className="flex items-center gap-2 pt-2">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        disabled
                                        checked={campaign?.extra?.vocal?.includes("verbal")}
                                        name="verbal"
                                        id="verbal"
                                    />
                                    <label htmlFor="verbal" className="ml-2">
                                        Verbal
                                    </label>
                                </div>
                                <div className="">
                                    <input
                                        type="checkbox"
                                        disabled
                                        checked={campaign?.extra?.vocal?.includes("non-verbal")}
                                        name="non-verbal"
                                        id="non-verbal"
                                    />
                                    <label htmlFor="non-verbal" className="ml-2">
                                        Non-verbal
                                    </label>
                                </div>
                            </div>
                            {/* <input type='text' onChange={(event) => setExtraInfo({ ...extraInfo, location: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' /> */}
                        </div>
                        {/* <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Shipment Date</p>
                                <input type='date' onChange={(event) => setExtraInfo({ ...extraInfo, shipmentDate: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div> */}
                    </div>
                </div>
            )}
            <div className="bg-[#131418] mx-5 h-16 sticky bottom-0 flex justify-end border-t border-[#474747]">
                <div className="flex items-center mr-5">
                    <div className="">
                        <p className="font-work">
                            {campaign?.videos} videos x ₹{campaign?.perVideoAmount} + GST
                        </p>
                        <p className="-mt-1 font-work">
                            Total: ₹{campaign?.totalAmount} +{" "}
                            {campaign?.totalAmount * 0.18}{" "}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
