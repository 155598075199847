import React, { useEffect, useState } from 'react'
import { getMonth, getWeek } from './calender/utils';
import CalendarHeader from './calender/CalendarHeader';
import MonthCalender from './calender/month';
import dayjs from "dayjs";
import Week from './calender/week';
import moment from 'moment';
var weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear)

export default function Scheduler() {
    const [currentView, setCurrentView] = useState('weekly');
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [currentWeek, setCurrentWeek] = useState(getWeek());
    const [monthIndex, setMonthIndex] = useState(0);
    const [weekIndex, setWeekIndex] = useState(0);
    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
        setCurrentWeek(getWeek(weekIndex))
    }, [monthIndex, weekIndex]);

    useEffect(() => {
        setTimeout(() => {
            const currentMonthIndex = moment().month();
            const currentWeekIndex = moment().week();
            setMonthIndex(currentMonthIndex)
            setWeekIndex(currentWeekIndex)
        }, 100);
    }, [])


    return (
        <div className='relative w-full h-screen overflow-y-auto'>
            <div className="h-14 w-full border-b flex items-center px-5 border-[#808080]">
                <p className="text-lg font-light tracking-wide">Calendar</p>
            </div>
            <div className='w-full p-5'>
                <div className="w-full rounded-md bg-bg_highlight">
                    <React.Fragment>
                        <div className="flex flex-col h-screen">
                            <CalendarHeader setCurrentMonth={setCurrentMonth} setMonthIndex={setMonthIndex} monthIndex={monthIndex} setCurrentWeek={setCurrentWeek} setWeekIndex={setWeekIndex} weekIndex={weekIndex} currentWeek={currentWeek} setCurrentView={setCurrentView} currentView={currentView} />
                            <div className="flex flex-1">
                                {currentView === "monthly" ?
                                    <MonthCalender month={currentMonth} monthIndex={monthIndex} />
                                    :
                                    <Week week={currentWeek} weekIndex={weekIndex} />
                                }
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    )
}
