import {
  Binoculars,
  CaretDown,
  CaretLeft,
  CaretUp,
  Checks,
  CircleNotch,
  ClipboardText,
  DotsSixVertical,
  FilePlus,
  Heart,
  PenNib,
  PencilSimple,
  SealQuestion,
  ShieldSlash,
  Tipi,
  Trash,
  Upload,
  VideoCamera,
  X,
} from "@phosphor-icons/react";
import React from "react";
import { useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { nanoid } from "nanoid";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { db, storage } from "../../firebase";
import Modalcard from "../../components/modalcard";
import { useHistory } from "react-router-dom";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { strings } from "../../common/Strings";
import { getOpenAIContent } from "../../aivideoGenerator/aiapis";
import ElevateButton from "../../components/elevateButton";
import ElevatedCard from "../../components/elevatedCard";
import { showSuccess, showWarning } from "../../components/toast/toast";

export default function CreateCampaigns() {
  const router = useHistory();
  const [{ user, question, point, brand, products }, dispatch] =
    useStateValue();
  const [tab, settab] = useState(null);
  const [incentiveType, setInType] = useState({
    type: null,
    freeProduct: null,
    cashback: null,
    discount: null,
  });
  const [camapignId, setcamapignId] = useState(nanoid(20));
  const [design, setDesign] = useState({
    logo: user?.imageUrl ?? null,
    primaryColor: "#FF005C",
    secondaryColor: "#121212",
  });
  const [overview, setOverview] = useState({
    camapaignName: null,
    spokespersonlogo: null,
    welcomeMessage: null,
    spokespersonDesignation: null,
    spokespersonName: null,
  });
  const [productInfo, setProductInfo] = useState({
    productName: null,
    productImage: null,
    productId: null,
    sku: null,
    brandName: brand?.name,
    brandId: brand?.id,
    catId: null,
    subcategory: null,
  });
  const [points, setPoints] = useState(point || [{ id: 1, question: "" }]);
  const [questions, setQuestions] = useState(
    question || [
      {
        id: 1,
        question: "",
        opt1: "",
        opt2: "",
        opt3: "",
      },
    ]
  );
  const [customizations, setCustomizations] = useState({
    askQuestionBefore: true,
    multipleResponse: true,
  });
  const [thankYou, setthankYou] = useState({
    headline: null,
    thankyouMessage: null,
  });
  const [productSearchText, setProductSearchText] = useState("");
  const [showProductModal, setshowProductModal] = useState(false);
  const [productAdding, setproductAdding] = useState(false);
  const [draggedPointIndex, setDraggedPointIndex] = useState(null);
  const [finalSubmissionLoading, setfinalSubmissionLoading] = useState(false);
  const [link, setlink] = useState("");
  const maxPoints = 3;

  async function getBase64(image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  }

  async function updateLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setDesign({ ...design, logo: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error.message);
          return;
        });
    }
  }
  async function updateSpokespersonLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setOverview({ ...overview, spokespersonlogo: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error.message);
          return;
        });
    }
  }

  async function setProduct(prod) {
    if (!prod) {
      showWarning("Please select a product");
      return;
    }
    try {
      setproductAdding(true);
      let headersList = {
        Accept: "*/*",
        Authorization:
          "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD",
      };

      let reqOptions = {
        url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${prod?.id}`,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      if (response?.data?.success === false) {
        showWarning("Something went wrong");
        return;
      }
      let data = response.data?.product;
      setProductInfo({
        productName: data?.name,
        productImage: data?.imageUrl,
        productId: data?.id,
        sku: data?.sku ?? null,
        brandName: brand?.name,
        brandId: brand?.id,
        catId: data?.catId,
        subcategory: data?.subcategory,
      });
      setproductAdding(false);
      setshowProductModal(false);
    } catch (error) {
      if (error.request) {
        showWarning(error.request);
      } else {
        showWarning(error.message);
      }
    }
  }

  const handlePointChange = (index, value) => {
    const updatedPoints = [...points];
    updatedPoints[index].question = value;
    setPoints(updatedPoints);
  };

  const handleDeletePoint = (index) => {
    const updatedPoints = [...points];
    updatedPoints.splice(index, 1);

    // Update the id of remaining points after deletion
    const updatedPointsWithNewIds = updatedPoints.map((point, newIndex) => ({
      ...point,
      id: newIndex + 1,
    }));

    setPoints(updatedPointsWithNewIds);
  };

  const handleAddPoint = () => {
    if (points.length < maxPoints) {
      const newId = points.length + 1;
      const newPoint = { id: newId, question: "" };
      setPoints([...points, newPoint]);
    }
  };

  const handlePointDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handlePointDragOver = (event) => {
    event.preventDefault();
  };

  const handlePointDrop = (event, dropIndex) => {
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedPoints = [...points];
    const draggedPoint = updatedPoints[dragIndex];

    updatedPoints.splice(dragIndex, 1);
    updatedPoints.splice(dropIndex, 0, draggedPoint);

    const updatedPointsWithNewIds = updatedPoints.map((point, index) => ({
      ...point,
      id: index + 1,
    }));

    setPoints(updatedPointsWithNewIds);
    setDraggedPointIndex(dropIndex); // Set the index of the dragged point

    // Clear the dragged point after a small delay to allow re-render
    setTimeout(() => {
      setDraggedPointIndex(null);
    }, 10);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (index) => {
    // Create a copy of the questions array
    const updatedQuestions = [...questions];

    // Remove the question at the specified index
    updatedQuestions.splice(index, 1);

    // Update the id of remaining points after deletion
    const updatedQuestionsWithNewIds = updatedQuestions.map(
      (question, newIndex) => ({
        ...question,
        id: newIndex + 1,
      })
    );

    // Set the state with the updated questions
    setQuestions(updatedQuestionsWithNewIds);
  };

  const handleAddQuestion = () => {
    const newId = questions.length + 1;
    const newQuestion = {
      id: newId,
      question: "",
      opt1: "",
      opt2: "",
      opt3: "",
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
    const updatedQuestions = [...questions];
    const draggedQuestion = updatedQuestions[dragIndex];

    updatedQuestions.splice(dragIndex, 1);
    updatedQuestions.splice(dropIndex, 0, draggedQuestion);

    const updatedQuestionsWithNewIds = updatedQuestions.map(
      (question, index) => ({
        ...question,
        id: index + 1,
      })
    );

    setQuestions(updatedQuestionsWithNewIds);
    setDraggedPointIndex(dropIndex); // Set the index of the dragged point

    // Clear the dragged point after a small delay to allow re-render
    setTimeout(() => {
      setDraggedPointIndex(null);
    }, 10);

    // setQuestions(updatedQuestions);
  };
  async function finalSubmit() {
    setfinalSubmissionLoading(true);
    const data = {
      LogoLink: design?.logo,
      askQuestionBefore: customizations?.askQuestionBefore,
      bpsId: productInfo?.brandId,
      brandemail: user?.email,
      campaign_name: overview?.camapaignName,
      catId: productInfo?.catId,
      dateAdded: Date.now(),
      designation: overview?.spokespersonDesignation,
      headLine: thankYou.headline,
      id: camapignId,
      name: overview.spokespersonName,
      parentName: brand?.name,
      points: points,
      prodid: productInfo?.productId,
      productimage: productInfo?.productImage,
      productname: productInfo?.productName,
      productsku: productInfo?.sku ?? null,
      questions: questions,
      spokeLink: overview.spokespersonlogo,
      subcategory: productInfo?.subcategory ?? "",
      submissions: 0,
      textReviews: 0,
      videoReviews: 0,
      incentive: incentiveType,
      thankYouMessageRef: thankYou.thankyouMessage,
      multipleResponse: customizations?.multipleResponse,
      welcomeMessage: overview.welcomeMessage,
      customizations: {
        primaryColor: design.primaryColor,
        secondaryColor: design.secondaryColor,
      },
    };
    if (!data.LogoLink) {
      showWarning("Please upload brand logo");
      setfinalSubmissionLoading(false);
      return;
    }

    if (!data.spokeLink) {
      showWarning("Please add spokesperson details");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.name) {
      showWarning("Please add spokesperson details");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.designation) {
      showWarning("Please add spokesperson details");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.designation) {
      showWarning("Please add welcome message");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.prodid) {
      showWarning("Please select a product");
      setfinalSubmissionLoading(false);
      return;
    }
    if (data.points.length < 1) {
      showWarning("Please add few point/tips for users");
      setfinalSubmissionLoading(false);
      return;
    }
    if (data.questions.length < 1) {
      showWarning("Please add atleast one question");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.incentive.type) {
      showWarning("Please Select Incentives");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.incentive[data.incentive.type]) {
      showWarning("Please Select Incentive " + data.incentive.type);
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data?.headLine) {
      showWarning("Please add a thank you headline");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data?.thankYouMessageRef) {
      showWarning("Please add a thank you message");
      setfinalSubmissionLoading(false);
      return;
    }
    // Validate each question has at least two options
    const hasMinimumOptions = data.questions.every(
      (question) =>
        question.opt1.trim().length > 0 && question.opt2.trim().length > 0
    );
    if (!hasMinimumOptions) {
      showWarning("Each question should have at least two options");
      setfinalSubmissionLoading(false);
      return;
    }
    // Set empty or null values to null for options
    const updatedQuestions = data.questions.map((aquestion) => {
      const updatedQuestion = { ...aquestion };
      if (updatedQuestion.opt1 === "" || updatedQuestion.opt1 === null) {
        updatedQuestion.opt1 = null;
      }
      if (updatedQuestion.opt2 === "" || updatedQuestion.opt2 === null) {
        updatedQuestion.opt2 = null;
      }
      if (updatedQuestion.opt3 === "" || updatedQuestion.opt3 === null) {
        updatedQuestion.opt3 = null;
      }
      return updatedQuestion;
    });

    data.questions = updatedQuestions;
    if (data.LogoLink.includes("data:image") === true) {
      const contentType = data.LogoLink.split(";")[0].split(":")[1]; // Extract content type from data URL
      const fileName = `forms/${camapignId}/logo.png`;
      const downloadURL = await uploadFile(
        data.LogoLink.split(",")[1],
        fileName,
        contentType
      );
      data.LogoLink = downloadURL;
      design.logo = downloadURL;
    }
    if (data.spokeLink.includes("data:image") === true) {
      const contentType = data.spokeLink.split(";")[0].split(":")[1]; // Extract content type from data URL
      const fileName = `forms/${camapignId}/spokeLogo.png`;
      const downloadURL = await uploadFile(
        data.spokeLink.split(",")[1],
        fileName,
        contentType
      );
      data.spokeLink = downloadURL;
      overview.spokespersonlogo = downloadURL;
    }
    await setDoc(doc(db, "forms/" + camapignId), data);
    showSuccess("campaign created successfully");
    await updateDoc(doc(db, strings.adminCollection + "/" + user?.email), {
      campaigns: user?.campaigns ? user?.campaigns + 1 : 1,
    });
    dispatch({
      type: "SET_USER",
      user: { ...user, campaigns: user?.campaigns ? user?.campaigns + 1 : 1 },
    });
    setlink(`https://brand.myrevue.app/userview/${camapignId}`);
    setfinalSubmissionLoading(null);
  }

  async function uploadFile(base64Data, fileName, contentType) {
    try {
      const storageRef = ref(storage, fileName);
      await uploadString(storageRef, base64Data, "base64", {
        contentType: contentType,
      }); // Change the contentType as needed
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.log(error);
      showWarning("Error in uploading image");
      return null;
    }
  }
  async function goBack() {
    router.push("/engagement/view-campaign/" + camapignId);
  }
  async function copylink() {
    navigator.clipboard.writeText(link);
    showSuccess("Link copied to clipboard");
    return;
  }

  return (
    <div className="h-[calc(100vh-64px)] flex overflow-y-auto">
      {finalSubmissionLoading === null && (
        <>
          <Modalcard close={goBack}>
            <div className="h-[70vh] aspect-video rounded-xl bg-[#202020] p-10 space-y-2">
              <p className="text-3xl font-medium tracking-wide">
                Collect Responses
              </p>
              <p className="font-work">
                Almost done! To collect responses, you'll need to share a link
                with your target respondents. You can do this via email, SMS,
                social media, or on the web.
              </p>
              <div className="relative bg-[#2E3036] h-14 w-full rounded-xl flex items-center justify-between p-1">
                <div className="flex-1 pl-3 break-words">
                  <p className="font-work">{link}</p>
                </div>
                <div
                  onClick={() => copylink()}
                  className="cursor-pointer bg-[#D9D9D9] h-full w-fit px-8 flex flex-col items-center justify-center rounded-lg text-black"
                >
                  <ClipboardText size={18} color="currentcolor" />
                  <p className="text-xs tracking-wide font-work">Copy</p>
                </div>
              </div>
              <p className="">QUICK TIPS</p>
              <ul className="pl-5">
                <li className="list-disc font-work">
                  Each time you get a response, we'll autogenerate a draft video
                  for you to review & publish.
                </li>
                <li className="list-disc font-work">
                  Ready to invite respondents but not sure what to say ? Adapt
                  our sample invitaion messages.
                </li>
                <li className="list-disc font-work">
                  Check out our11 tips for higher response rates.
                </li>
              </ul>
            </div>
          </Modalcard>
        </>
      )}
      <div className="w-full max-w-[576px] h-full bg-[#202020] p-8 overflow-y-auto">
        <div className="mb-3 rounded-lg">
          {(tab === null || tab === 2 || tab === 4 || tab === 3) && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Welcome to <span className="text-[#EA335F]">MyRevue</span>! Our
              FAQ form will assist you in gathering valuable reviews from your
              users. Let's get started with the setup!
            </p>
          )}
          {tab === 0 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Configure your video collector's{" "}
              <span className="text-[#EA335F]">look & feel here.</span>& add
              your <span className="text-[#EA335F]">logo</span>
            </p>
          )}
          {tab === 1 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              This page greets respondents, explains how it{" "}
              <span className="text-[#EA335F]">works</span>, and{" "}
              <span className="text-[#EA335F]">
                offers optional incentives.
              </span>
              This can be different from you collector's internal name.
            </p>
          )}
          {tab === 5 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Say <span className="text-[#EA335F]">thanks</span> for
              participating and let people know you'll follow up about any
              custom incentives they've earned.
            </p>
          )}
        </div>

        <div className="">
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 0 ? settab(null) : settab(0))}
              className={`${
                tab === 0
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 0 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <PenNib size={22} color="currentcolor" />
              <p className="">Design</p>
              <div className="flex-grow"></div>
            </button>

            {tab === 0 && (
              <div className="w-full py-4">
                <div className="">
                  <p className="">Logo</p>
                  <div className="h-16  min-w-[120px] max-w-fit bg-[#ffffff] mt-2 rounded-md flex items-center justify-center p-1 text-xl relative">
                    {design?.logo === null ? (
                      <p className="">+</p>
                    ) : (
                      <img src={design?.logo} alt="" className="h-full" />
                    )}
                    <input
                      type="file"
                      onChange={(event) => updateLogo(event)}
                      className="absolute w-full h-full opacity-0"
                      accept=".png, .jpg,.jpeg"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-3">
                  <div className="w-full">
                    <p className="">Primary Color</p>
                    <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                      <div className="relative self-center w-6 h-6">
                        <div className="absolute z-10 w-full h-full">
                          <input
                            type="color"
                            onChange={(event) =>
                              setDesign({
                                ...design,
                                primaryColor: event.target.value,
                              })
                            }
                            className="w-full h-full rounded-full opacity-0"
                            name=""
                            id=""
                          />
                        </div>
                        <button
                          type="button"
                          className="w-full h-full border rounded-full"
                          style={{ backgroundColor: design?.primaryColor }}
                        ></button>
                      </div>
                      <input
                        type="text"
                        value={design?.primaryColor}
                        onChange={(event) =>
                          setDesign({
                            ...design,
                            primaryColor: event.target.value,
                          })
                        }
                        className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                        placeholder="#4E46E5"
                        name=""
                        id=""
                      ></input>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="">Background Color</p>
                    <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                      <div className="relative self-center w-6 h-6">
                        <div className="absolute z-10 w-full h-full">
                          <input
                            type="color"
                            onChange={(event) =>
                              setDesign({
                                ...design,
                                secondaryColor: event.target.value,
                              })
                            }
                            className="w-full h-full rounded-full opacity-0"
                            name=""
                            id=""
                          />
                        </div>
                        <button
                          type="button"
                          className="w-full h-full border rounded-full"
                          style={{ backgroundColor: design?.secondaryColor }}
                        ></button>
                      </div>
                      <input
                        type="text"
                        value={design?.secondaryColor}
                        onChange={(event) =>
                          setDesign({
                            ...design,
                            secondaryColor: event.target.value,
                          })
                        }
                        className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                        placeholder="#4E46E5"
                        name=""
                        id=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 1 ? settab(null) : settab(1))}
              className={`${
                tab === 1
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 1 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Binoculars size={22} color="currentcolor" />
              <p className="">Overview</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 1 && (
              <div className="w-full py-4 space-y-3">
                <div className="">
                  <p className="text-[#F8F8F8]">Campaign Name</p>
                  <input
                    type="text"
                    className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Campaign name"
                    value={overview?.camapaignName}
                    onChange={(event) =>
                      setOverview({
                        ...overview,
                        camapaignName: event.target.value,
                      })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 5
                        ? showWarning("Campaign Name must be atleast 5 letters")
                        : null
                    }
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide">
                    <span className="text-[#EA335F] text-base">
                      Spokesperson
                    </span>
                    <br />
                    Make it feel personal with a friendly face from your
                    company.
                  </p>
                  <div className="flex items-center w-full gap-8 mt-2">
                    <div className="h-20 w-20 bg-[#434343] rounded-full flex items-center justify-center p-1 text-xl relative">
                      {overview?.spokespersonlogo === null ? (
                        <p className="">+</p>
                      ) : (
                        <img
                          src={overview?.spokespersonlogo}
                          alt=""
                          className="object-cover w-full h-full rounded-full"
                        />
                      )}
                      <input
                        type="file"
                        onChange={(event) => updateSpokespersonLogo(event)}
                        className="absolute w-full h-full opacity-0"
                        accept=".png, .jpg,.jpeg"
                      />
                    </div>
                    <div className="flex-1 w-full space-y-3">
                      <div className="">
                        <input
                          type="text"
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Full Name"
                          value={overview?.spokespersonName}
                          onChange={(event) =>
                            setOverview({
                              ...overview,
                              spokespersonName: event.target.value,
                            })
                          }
                          onBlur={(event) =>
                            event.currentTarget.value.length < 3
                              ? showWarning(
                                  "Please enter full name of spokesperson"
                                )
                              : null
                          }
                        />
                      </div>
                      <div className="">
                        <input
                          type="text"
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Designation"
                          value={overview?.spokespersonDesignation}
                          onChange={(event) =>
                            setOverview({
                              ...overview,
                              spokespersonDesignation: event.target.value,
                            })
                          }
                          onBlur={(event) =>
                            event.currentTarget.value.length < 2
                              ? showWarning(
                                  "Please enter designation of spokesperson"
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="text-[#F8F8F8]">Welcome Message</p>
                  <div className="relative">
                    <i
                      onClick={async (e) => {
                        const icon = e.currentTarget;
                        if (icon) {
                          icon.classList.remove("fa-lightbulb");
                          icon.classList.add("fa-spinner");
                          icon.classList.add("animate-spin");

                          try {
                            const response = await getOpenAIContent(
                              overview?.welcomeMessage
                            );
                            setOverview({
                              ...overview,
                              welcomeMessage: response,
                            });
                          } catch (error) {
                            console.error(error);
                          } finally {
                            if (icon) {
                              icon.classList.remove("fa-spinner");
                              icon.classList.remove("animate-spin");
                              icon.classList.add("fa-lightbulb");
                            }
                          }
                        }
                      }}
                      className="absolute hidden text-yellow-400 cursor-pointer fa-solid top-4 right-2 fa-lightbulb"
                    ></i>
                    <textarea
                      onChange={(event) => {
                        const icon =
                          event.target.parentElement.querySelector("i");
                        if (icon) {
                          icon.style.display =
                            event.target.value.length > 10 ? "block" : "none";
                        }
                        setOverview({
                          ...overview,
                          welcomeMessage: event.target.value,
                        });
                      }}
                      value={overview?.welcomeMessage}
                      type="text"
                      className="appearance-none outline-none h-24 rounded-md p-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="Add a welcome message maximum to 140 characters."
                      maxLength={50}
                      onBlur={(event) =>
                        event.currentTarget.value.length < 5 &&
                        showWarning("Please add a longer welcome message")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 2 ? settab(null) : settab(2))}
              className={`${
                tab === 2
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 2 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <FilePlus size={22} color="currentcolor" />
              <p className="">Product</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 2 && (
              <div className="relative w-full py-4 space-y-3 group">
                <div className="w-full">
                  <div className="flex items-center w-full gap-8 mt-2">
                    <div className="w-16 h-16 rounded-full">
                      <div
                        onClick={() => setshowProductModal(!showProductModal)}
                        className=" cursor-pointer h-full w-full bg-[#434343] rounded-full text-xl flex items-center justify-center"
                      >
                        {productAdding === true ? (
                          <div className="animate-spin">
                            <CircleNotch size={22} color="white" />
                          </div>
                        ) : productInfo?.productImage === null ? (
                          <p className="">+</p>
                        ) : (
                          <img
                            src={productInfo?.productImage}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex-1 w-full">
                      <div className="my-3">
                        <input
                          type="text"
                          defaultValue={productInfo?.productName}
                          readOnly
                          onFocus={() => setshowProductModal(!showProductModal)}
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Product Name"
                        />
                      </div>
                    </div>
                  </div>
                  {showProductModal === true && (
                    <div className="absolute mt-3 py-3 w-full h-80 overflow-y-auto bg-[#202020] z-50 border border-[#808080] rounded-md">
                      <div className="flex gap-3 px-3 my-3">
                        <input
                          type="text"
                          onChange={(event) =>
                            setProductSearchText(event.target.value)
                          }
                          className="flex-1 w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Search by product name"
                        />
                        <button
                          className=""
                          onClick={() => setshowProductModal(false)}
                        >
                          <X size={32} color="white" />
                        </button>
                      </div>
                      {products
                        ?.filter((val) =>
                          val?.name
                            ?.toLowerCase()
                            ?.includes(productSearchText?.toLowerCase())
                        )
                        ?.map((prod, index) => (
                          <div
                            className="w-full flex h-16 my-3 items-center justify-between cursor-pointer hover:bg-[#2E3036] px-3"
                            key={index}
                            onClick={() => setProduct(prod)}
                          >
                            <div className="flex items-center flex-1 w-full gap-3">
                              <div className="w-12 h-12 rounded-full">
                                <img
                                  src={prod?.imageUrl}
                                  alt="productimage"
                                  className="w-full h-full rounded-full"
                                />
                              </div>
                              <div className="flex-1 w-full">
                                <p className="w-full text-sm tracking-wide">
                                  {prod?.name}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <ReactStars
                                size={25}
                                count={5}
                                isHalf={false}
                                value={prod?.rating}
                                color="white"
                                activeColor="#FF7D58"
                                edit={false}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 3 ? settab(null) : settab(3))}
              className={`${
                tab === 3
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 3 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Tipi size={22} color="currentcolor" />
              <p className="">Points/Tips</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 3 && (
              <div className="w-full py-4 space-y-3">
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide">
                    <span className="text-[#EA335F] text-base">Points</span>
                    <br />
                    Ask user to cover this points in their video.
                  </p>
                  <div className="">
                    <div className="w-full mt-2">
                      {points?.map((point, index) => (
                        <div
                          className="flex items-center flex-1 w-full"
                          key={index}
                          draggable
                          onDragStart={(e) => handlePointDragStart(e, index)}
                          onDragOver={handlePointDragOver}
                          onDrop={(e) => handlePointDrop(e, index)}
                        >
                          <button className="">
                            <DotsSixVertical size={28} color="white" />
                          </button>
                          <div className="relative flex-1 w-full my-3">
                            <div className="absolute flex items-center h-full right-3">
                              <button
                                className="ml-2 text-red-500 "
                                onClick={() => handleDeletePoint(index)}
                              >
                                <Trash size={25} color="white" />
                              </button>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter a point/tips for user"
                              onChange={(e) =>
                                handlePointChange(index, e.target.value)
                              }
                              value={point.question}
                              className={`w-full h-9 pl-3 pr-12 text-sm tracking-wide bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide ${
                                draggedPointIndex === index ? "dragged" : ""
                              }`}
                            />
                          </div>
                        </div>
                      ))}
                      {points.length < maxPoints && (
                        <div className="border p-2 border-[#808080] rounded-lg">
                          <button
                            className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none flex items-center justify-center"
                            onClick={handleAddPoint}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 4 ? settab(null) : settab(4))}
              className={`${
                tab === 4
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 4 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <SealQuestion size={22} color="currentcolor" />
              <p className="">Question</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 4 && (
              <div className="w-full py-4 space-y-3">
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide">
                    <span className="text-[#EA335F] text-base">Questions</span>
                    <br />
                    Ask up to 5 questions. Read our tips & tricks
                  </p>
                  <div className="">
                    <div className="w-full mt-2">
                      {questions.map((question, index) => (
                        <div
                          className="flex items-center flex-1 w-full gap-3 my-3"
                          key={question.id}
                          draggable
                          onDragStart={(e) => handleDragStart(e, index)}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, index)}
                        >
                          <button className="">
                            <DotsSixVertical size={28} color="white" />
                          </button>
                          <div className="space-y-3 bg-[#3e3e3e] p-3 rounded-md">
                            <div className="flex items-center justify-center gap-1">
                              <input
                                value={question.question}
                                type="text"
                                className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                                placeholder="Enter a question"
                                onChange={(e) => {
                                  handleQuestionChange(
                                    index,
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                              <i
                                onClick={async (e) => {
                                  const icon = e.currentTarget;
                                  if (icon) {
                                    icon.classList.add("animate-pulse");

                                    try {
                                      const text =
                                        e.currentTarget.previousSibling.value;
                                      var response = await getOpenAIContent(
                                        "write only a short mcq having 3 very short option for customer asking about " +
                                          text
                                      );
                                      console.log(response);
                                      let reg = /\(?[\w]\)/g;
                                      response = response.split(reg);
                                      handleQuestionChange(
                                        index,
                                        "question",
                                        response[0] ?? "unknown"
                                      );
                                      handleQuestionChange(
                                        index,
                                        "opt1",
                                        response[1] ?? "unknown"
                                      );
                                      handleQuestionChange(
                                        index,
                                        "opt2",
                                        response[2] ?? "unknown"
                                      );
                                      handleQuestionChange(
                                        index,
                                        "opt3",
                                        response[3] ?? "unknown"
                                      );
                                    } catch (error) {
                                      console.error(error);
                                    } finally {
                                      if (icon) {
                                        icon.classList.remove("animate-pulse");
                                      }
                                    }
                                  }
                                }}
                                className="px-2 text-yellow-400 duration-150 transform cursor-pointer fas hover:scale-105 fa-lightbulb"
                              ></i>
                            </div>
                            <input
                              value={question.opt1}
                              type="text"
                              className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                              placeholder="Option 1"
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "opt1",
                                  e.target.value
                                )
                              }
                            />
                            <input
                              value={question.opt2}
                              type="text"
                              className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                              placeholder="Option 2"
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "opt2",
                                  e.target.value
                                )
                              }
                            />
                            <input
                              value={question.opt3}
                              type="text"
                              className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                              placeholder="Option 3"
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "opt3",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <button
                            className=""
                            onClick={() => handleDeleteQuestion(index)}
                          >
                            <Trash size={28} color="white" />
                          </button>
                        </div>
                      ))}
                      {questions.length < 5 && (
                        <div className="border p-2 border-[#808080] rounded-lg">
                          <button
                            onClick={() => handleAddQuestion()}
                            className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none flex items-center justify-center"
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 7 ? settab(null) : settab(7))}
              className={`${
                tab === 7
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 7 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Heart size={22} color="currentcolor" />
              <p className="">Incentives</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 7 && (
              <div className="w-full py-4 space-y-3">
                <div className="flex flex-wrap items-center gap-3 ">
                  <input
                    type="radio"
                    value="cashback"
                    name="incentives"
                    id="incentivecashback"
                    defaultChecked={incentiveType.type === "cashback"}
                    onClick={() =>
                      setInType({ ...incentiveType, type: "cashback" })
                    }
                  />
                  <label htmlFor="incentivecashback">Cashback</label>
                  <input
                    type="radio"
                    value="discount"
                    name="incentives"
                    id="incentivediscount"
                    defaultChecked={incentiveType.type === "discount"}
                    onClick={() =>
                      setInType({ ...incentiveType, type: "discount" })
                    }
                  />
                  <label htmlFor="incentivediscount">Exclusive Discount</label>
                  <input
                    type="radio"
                    value="freeProduct"
                    name="incentives"
                    id="incentivefreeProduct"
                    defaultChecked={incentiveType.type === "freeProduct"}
                    onClick={() =>
                      setInType({ ...incentiveType, type: "freeProduct" })
                    }
                  />
                  <label htmlFor="incentivefreeProduct">Free Product</label>
                </div>

                {incentiveType.type === "cashback" && (
                  <div className="">
                    <p className="text-[#F8F8F8]">Incentives Cashback</p>
                    <input
                      type="number"
                      className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="incentives in rupee"
                      defaultValue={incentiveType.cashback ?? 0}
                      onChange={(event) =>
                        setInType({
                          ...incentiveType,
                          cashback: Number.parseInt(event.target.value),
                        })
                      }
                    />
                  </div>
                )}
                {incentiveType.type === "cashback" && (
                  <p className="w-full text-right">
                    <span className="text-3xl">
                      {incentiveType?.cashback?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </span>{" "}
                    <span className="text-sm">per User</span>
                  </p>
                )}

                {incentiveType.type === "discount" && (
                  <div>
                    <p className="text-[#F8F8F8]">% Discount on Order value</p>
                    <input
                      type="number"
                      className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="% Discount on Order value"
                      defaultValue={incentiveType.discount ?? 0}
                      onChange={(event) =>
                        setInType({
                          ...incentiveType,
                          discount: Number.parseInt(event.target.value),
                        })
                      }
                    />
                  </div>
                )}
                {incentiveType.type === "freeProduct" && (
                  <div>
                    <p className="text-[#F8F8F8]">Free Product name</p>
                    <input
                      type="text"
                      className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="Product Name"
                      defaultValue={incentiveType.freeProduct ?? ""}
                      onChange={(event) =>
                        setInType({
                          ...incentiveType,
                          freeProduct: event.target.value,
                        })
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 5 ? settab(null) : settab(5))}
              className={`${
                tab === 5
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 5 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <PencilSimple size={22} color="currentcolor" />
              <p className="">Customizations</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 5 && (
              <div className="w-full py-4 space-y-3">
                <div className="flex justify-between">
                  <p className="text-[#F8F8F8] tracking-wide text-sm">
                    Ask question before uploading review{" "}
                  </p>
                  {/* <input type="checkbox" className="h-10 outline-none appearance-none" /> */}
                  <input
                    type="checkbox"
                    id="askQuestionBefore"
                    name="askQuestionBefore"
                    onChange={(event) =>
                      event.target.checked === true
                        ? setCustomizations({
                            ...customizations,
                            askQuestionBefore: true,
                          })
                        : setCustomizations({
                            ...customizations,
                            askQuestionBefore: false,
                          })
                    }
                    checked={customizations?.askQuestionBefore}
                  />
                  <label htmlFor="askQuestionBefore"></label>
                </div>
                <div className="flex justify-between">
                  <p className="text-[#F8F8F8] tracking-wide text-sm">
                    Can user upload mulitple response in same product
                  </p>
                  {/* <input type="checkbox" className="h-10 outline-none appearance-none" /> */}
                  <input
                    type="checkbox"
                    id="multipleResponse"
                    name="multipleResponse"
                    onChange={(event) =>
                      event.target.checked === true
                        ? setCustomizations({
                            ...customizations,
                            multipleResponse: true,
                          })
                        : setCustomizations({
                            ...customizations,
                            multipleResponse: false,
                          })
                    }
                    checked={customizations?.multipleResponse}
                  />
                  <label htmlFor="multipleResponse"></label>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 6 ? settab(null) : settab(6))}
              className={`${
                tab === 6
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 6 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Heart size={22} color="currentcolor" />
              <p className="">Thank you page</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 6 && (
              <div className="w-full py-4 space-y-3">
                <div className="">
                  <p className="text-[#F8F8F8]">Headline</p>
                  <input
                    type="text"
                    className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Headline"
                    value={thankYou.headline}
                    onChange={(event) =>
                      setthankYou({ ...thankYou, headline: event.target.value })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 1
                        ? showWarning("Please add a headline")
                        : null
                    }
                  />
                </div>
                <div className="">
                  <p className="text-[#F8F8F8]">Message</p>
                  <textarea
                    type="text"
                    className="appearance-none outline-none h-28 rounded-md p-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Message"
                    value={thankYou.thankyouMessage}
                    onChange={(event) =>
                      setthankYou({
                        ...thankYou,
                        thankyouMessage: event.target.value,
                      })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 1
                        ? showWarning("Please add a thank you message")
                        : null
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            {finalSubmissionLoading === null ? (
              <button
                className={`bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto    `}
              >
                <p className="">Finished</p>
              </button>
            ) : (
              <button
                onClick={() =>
                  finalSubmissionLoading === true ? null : finalSubmit()
                }
                className={`bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto    `}
              >
                {finalSubmissionLoading === true ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  <p className="">Finish</p>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-1 w-full h-full px-10">
        <div className="relative flex flex-none flex-col overflow-hidden bg-[#000000] shadow-xl ring-4 ring-white duration-300 w-full rounded-md h-[90%] p-5">
          {[0, 1, 2, 5, null, 7].includes(tab) && (
            <div
              id="thin-scrollbar"
              className={`h-full aspect-[9/16] sm:border-4 border-white xl:aspect-[9/18] relative rounded-lg mx-auto overflow-y-auto`}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div className="flex flex-col justify-between w-full h-full px-3 pt-3">
                <div className="">
                  <div className="flex justify-center">
                    <div className="w-auto px-5 py-2 rounded-md h-14">
                      {!design?.logo ? (
                        <img
                          src={
                            require("../../assets/logo/MyRevue Logo elite new.svg")
                              .default
                          }
                          alt=""
                          className="h-full "
                        />
                      ) : (
                        <img src={design?.logo} alt="" className="h-full " />
                      )}
                    </div>
                  </div>
                  <div className="w-full mt-5">
                    <p className="text-xl font-medium leading-tight text-center">
                      {overview?.welcomeMessage || "Welcome Message"}
                    </p>
                  </div>
                  <div className="mt-5">
                    <ElevatedCard>
                      <div className="flex flex-col items-center gap-3 -mx-4 -my-4">
                        <div className="flex items-center justify-center w-1/2 bg-black aspect-square">
                          <img
                            src={productInfo?.productImage}
                            alt="product_image"
                            className="object-cover w-full h-full"
                          />
                        </div>
                        <div className="flex flex-col items-center flex-1 w-full pb-3">
                          <p className="text-[15px] leading-tight text-center font-medium">
                            {productInfo?.productName || "Product Name"}
                          </p>
                        </div>
                      </div>
                    </ElevatedCard>
                  </div>
                  <div className="mt-5">
                    <ElevatedCard>
                      <div className="flex items-center gap-3 -mx-4 -my-4">
                        <div className="flex items-center justify-center w-12 h-12 bg-black">
                          {overview?.spokespersonlogo ? (
                            <img
                              src={overview?.spokespersonlogo}
                              alt=""
                              className="object-cover w-12 h-12 mx-auto my-2"
                            />
                          ) : (
                            <div className="flex items-center justify-center w-12 h-12 text-lg text-white bg-black">
                              {overview?.spokespersonName
                                ?.split(" ")[0]
                                .substring(0, 2)
                                .toUpperCase() || "NA"}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <p className="text-sm leading-tight">
                            {overview?.spokespersonName ?? "Spokesperson Name"}
                          </p>
                          <p className="text-xs leading-tight">
                            {overview?.spokespersonDesignation ??
                              "Spokesperson Designation"}
                          </p>
                        </div>
                      </div>
                    </ElevatedCard>
                  </div>
                  {incentiveType?.type !== null && (
                    <div className="mt-5">
                      <ElevatedCard>
                        <div className="flex items-center gap-3 px-2 py-1 -mx-4 -my-4">
                          <p className="text-sm font-medium first-letter:uppercase">
                            {incentiveType.type} :{" "}
                          </p>
                          <div className="">
                            {incentiveType.type === "cashback" && (
                              <>
                                <span className="text-base">
                                  {incentiveType?.cashback?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )}
                                </span>
                              </>
                            )}
                            {incentiveType.type === "discount" && (
                              <>
                                <span className="text-base">
                                  {incentiveType?.discount}% discount
                                </span>
                              </>
                            )}
                            {incentiveType.type === "freeProduct" && (
                              <>
                                <span className="text-base">
                                  {incentiveType?.freeProduct}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </ElevatedCard>
                    </div>
                  )}
                </div>
                <div className="">
                  <div className="">
                    <p className="text-[14px] font-medium">How it Works</p>
                    <p className="text-[12px] leading-tight">
                      Tell your story by recording a short video clip here.
                      Nothing to download or install.
                    </p>
                  </div>
                  {/* <button className="w-full h-10 mt-5 bg-white rounded-full " onClick={() => router.push(`/userView/${form}?step=signin`)}>
                                        <p className="text-black" >
                                            Continue
                                        </p>
                                    </button> */}
                  <div className="relative w-full mt-2">
                    <ElevateButton buttonActiveColor={design?.primaryColor}>
                      <span className="">Let's Go</span>
                    </ElevateButton>
                  </div>
                  <div className="flex items-center justify-center gap-2">
                    <span className="text-xs">Powered by</span>
                    <img
                      src={
                        require("../../assets/logo/MyRevue Logo elite new.svg")
                          .default
                      }
                      alt=""
                      className="h-8"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {tab === 3 && (
            <div
              id="thin-scrollbar"
              className={`h-full aspect-[9/16] sm:border-4 border-white xl:aspect-[9/18] relative rounded-lg mx-auto overflow-y-auto`}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="space-y-3">
                    <button className="mt-3">
                      <CaretLeft
                        size={22}
                        color="white"
                        weight="bold"
                        className="hover:opacity-40"
                      />
                    </button>
                  </div>
                  <div className="">
                    <p className="text-lg font-medium">
                      Points to cover in this video
                    </p>
                    <ol>
                      {points?.map((point, index) => (
                        <li
                          key={index}
                          className="flex items-start gap-2 py-1 "
                        >
                          <div className="mt-1 mark"></div>
                          <p className="flex-1 text-sm leading-tight">
                            {point?.question}
                          </p>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
                <div className="pb-3 mt-3 space-y-2">
                  <ElevateButton buttonActiveColor={design?.primaryColor}>
                    <div className="relative flex items-center justify-center w-full h-full">
                      <span className="">Upload Video</span>
                    </div>
                  </ElevateButton>
                  <ElevateButton buttonActiveColor={design?.primaryColor}>
                    <div className="relative flex items-center justify-center w-full h-full">
                      <span className="">Record Video</span>
                    </div>
                  </ElevateButton>
                </div>
              </div>
            </div>
          )}
          {tab === 4 && (
            <div
              id="thin-scrollbar"
              className={`h-full aspect-[9/16] sm:border-4 border-white xl:aspect-[9/18] relative rounded-lg mx-auto overflow-y-auto`}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="space-y-3">
                    <button className="mt-3">
                      <CaretLeft
                        size={22}
                        color="white"
                        weight="bold"
                        className="hover:opacity-40"
                      />
                    </button>
                  </div>
                  <div className="flex-1 my-5 space-y-3">
                    {questions?.map((quest, index) => (
                      <ElevatedCard key={index}>
                        <p className="mb-2 text-sm font-medium leading-tight">
                          {quest.id}: {quest.question}
                        </p>
                        {[1, 2, 3].map(
                          (optionIndex) =>
                            quest[`opt${optionIndex}`] && (
                              <div
                                className="flex items-center my-1"
                                key={optionIndex}
                              >
                                <input
                                  type="radio"
                                  className="!border-[#2d2d2d] customRadio"
                                  id={`opt${optionIndex}${index}`}
                                  name={quest.id}
                                  value={quest[`opt${optionIndex}`]}
                                />
                                <label
                                  className="flex-1 w-full pl-2 text-sm"
                                  htmlFor={`opt${optionIndex}${index}`}
                                >
                                  {quest[`opt${optionIndex}`]}
                                </label>
                              </div>
                            )
                        )}
                      </ElevatedCard>
                    ))}
                  </div>
                </div>
                <div className="pb-5 space-y-2">
                  <ElevateButton buttonActiveColor={design?.primaryColor}>
                    <span className="">Continue</span>
                  </ElevateButton>
                </div>
              </div>
            </div>
          )}
          {tab === 6 && (
            <div
              id="thin-scrollbar"
              className={`h-full aspect-[9/16] sm:border-4 border-white xl:aspect-[9/18] relative rounded-lg mx-auto overflow-y-auto`}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="flex-1 space-y-3">
                    <div className="mt-10">
                      <p className="text-2xl font-medium text-center first-letter:uppercase">
                        {thankYou?.headline || "Thank You"}
                      </p>
                      <p className="text-sm text-center first-letter:uppercase">
                        {thankYou?.thankyouMessage || "Thank You Message"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <ElevatedCard>
                    <div className="flex flex-col items-center">
                      <p className="font-sans tracking-widest text-black ">
                        Powered By
                      </p>
                      <a
                        href="https://brand.myrevue.app"
                        target="_blank"
                        className=""
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMyRevue%20Logo%20(1)%201.svg?alt=media&amp;token=f3878494-f182-43c4-bbfb-6d1f7247af27"
                          alt="logo"
                          className="h-14"
                        />
                      </a>
                      <p className="text-center text-black">
                        Tech Enabled
                        <span className="font-semibold">
                          {" "}
                          Content As A Service (CAAS) Platform{" "}
                        </span>
                        For BHARAT Brands
                      </p>
                      <span className="font-semibold text-[13px] text-[#FF005C]">
                        {" "}
                        AWARE. ENGAGE. RETAIN
                      </span>
                    </div>
                  </ElevatedCard>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
