import axios from "axios";

const aiServerUrl = "https://aivideo.myrevue.app/"; //"http://34.93.81.229/";"http://localhost:5000/";

async function getOpenAIContent(userMessage) {
  const requestData = {
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "user",
        content: `modify content to get profession product description, max 50 words. complete this line that is  - ${userMessage}`,
      },
    ],
    temperature: 0.7,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer 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`,
    },
  };

  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      requestData,
      config
    );
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error(error);
    throw error; // You may want to handle errors differently based on your use case
  }
}
async function getAiContent(userMessage) {
  const requestData = {
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "user",
        content: userMessage,
      },
    ],
    temperature: 0.7,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer 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`,
    },
  };

  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      requestData,
      config
    );
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error(error);
    throw error; // You may want to handle errors differently based on your use case
  }
}

const aiResponse = (projectId, brand, text, video, audio) =>
  new Promise(async (resolve, reject) => {
    try {
      let headersList = {
        Accept: "/",
        "Content-Type": "application/json",
      };

      let bodyContent = {
        projectId: projectId,
        brand: brand,
        videoType: video,
        query: text,
        audioType: audio,
      };

      let reqOptions = {
        url: aiServerUrl + "generateAiVideo",
        method: "POST",
        headers: headersList,
        data: bodyContent,
        timeout: 80000,
        mode: "no-cors",
      };
      // console.log("calling apis");
      let response = await axios.request(reqOptions);
      // console.log(response.data);
      resolve(response.data.id);
    } catch (error) {
      console.log(error);
      reject(false);
    }
  });

export { aiResponse, aiServerUrl, getOpenAIContent, getAiContent };
