import { ArrowLeft, BracketsCurly, TextB, TextItalic, TextStrikethrough } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react'
import { showWarning } from '../../components/toast/toast';
import Modalcard from '../../components/modalcard';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useStateValue } from '../../context/StateProvider';

export default function CreateTemplate() {
    const router = useHistory();
    const [{ user }, dispatch] = useStateValue();
    const accessToken = user?.social?.whatsapp?.accessToken;
    const wab_Id = user?.social?.whatsapp?.waId ?? null
    const whatsapp_languages = [
        {
            "language": "English",
            "short_code": "en_US"
        },
        {
            "language": "Hindi",
            "short_code": "hi"
        },
        {
            "language": "Spanish",
            "short_code": "es"
        },
        {
            "language": "French",
            "short_code": "fr"
        },
        {
            "language": "German",
            "short_code": "de"
        },
        {
            "language": "Portuguese",
            "short_code": "pt_BR"
        },
        {
            "language": "Arabic",
            "short_code": "ar"
        },
        {
            "language": "Chinese",
            "short_code": "zh_CN"
        },
        {
            "language": "Japanese",
            "short_code": "ja"
        },
        {
            "language": "Russian",
            "short_code": "ru"
        },
        {
            "language": "Italian",
            "short_code": "it"
        },
        {
            "language": "Dutch",
            "short_code": "nl"
        },
        {
            "language": "Turkish",
            "short_code": "tr"
        },
        {
            "language": "Korean",
            "short_code": "ko"
        },
        {
            "language": "Indonesian",
            "short_code": "id"
        },
        {
            "language": "Thai",
            "short_code": "th"
        },
        {
            "language": "Vietnamese",
            "short_code": "vi"
        },
        {
            "language": "Greek",
            "short_code": "el"
        },
        {
            "language": "Swedish",
            "short_code": "sv"
        },
        {
            "language": "Polish",
            "short_code": "pl"
        },
        {
            "language": "Czech",
            "short_code": "cs"
        },
        {
            "language": "Hungarian",
            "short_code": "hu"
        },
        {
            "language": "Romanian",
            "short_code": "ro"
        },
        {
            "language": "Finnish",
            "short_code": "fi"
        },
        {
            "language": "Danish",
            "short_code": "da"
        },
        {
            "language": "Norwegian",
            "short_code": "nb"
        },
        {
            "language": "Hebrew",
            "short_code": "he"
        },
        {
            "language": "Malay",
            "short_code": "ms"
        },
        {
            "language": "Bengali",
            "short_code": "bn"
        },
        {
            "language": "Tamil",
            "short_code": "ta"
        },
        {
            "language": "Telugu",
            "short_code": "te"
        },
        {
            "language": "Punjabi",
            "short_code": "pa"
        },
        {
            "language": "Marathi",
            "short_code": "mr"
        },
        {
            "language": "Urdu",
            "short_code": "ur"
        }
    ]
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [templateDetails, settemplateDetails] = useState({
        name: "",
        category: ""
    });
    const [headerType, setHeaderType] = useState("none");
    const [buttonType, setButtonType] = useState("none");
    const [headerContent, setHeaderContent] = useState(null);
    const [footerContent, setFooterContent] = useState(null);
    const [message, setMessage] = useState("");
    const [buttons, setButtons] = useState([]);
    const [samples, setSamples] = useState([]);
    const [variableCounts, setvariableCounts] = useState(0);
    const [variables, setVariables] = useState([]);

    useEffect(() => {
        if (!user?.social?.whatsapp) {
            router.replace("/social/channel")
        }
    }, [])


    const messageOutput = () => {
        let output = message;
        // Replace bold text
        output = output.replace(/\*(.*?)\*/g, '<b>$1</b>');

        // Replace italic text
        output = output.replace(/_(.*?)_/g, '<i>$1</i>');

        // Replace underlined text
        output = output.replace(/~(.*?)~/g, '<strike>$1</strike>');
        // Replace newline characters with <br> tags
        output = output.replace(/(?:\r\n|\r|\n)/g, '<br>');
        // Replace remaining asterisks with HTML entity
        output = output.replace(/\*/g, '&ast;');
        // Convert HTML string to React elements
        return { __html: output };
    };

    async function variableCount() {
        const matches = message.match(/\{\{(.*?)\}\}/g);
        const newCount = matches ? matches.length : 0;
        setvariableCounts(newCount)
        return newCount
    }
    async function addButton() {
        if (buttons.length === 2) {
            return showWarning("Maximum 2 buttons can be added")
        }
        const newDoc = {
            action: null,
            buttonText: "",
            urlType: "",
            websiteUrl: "",
            phoneNumber: ""
        }
        setButtons(prevData => [...prevData, newDoc])
    }
    async function updateButton(index, key, value) {
        setButtons(prevData => {
            const updatedButtons = [...prevData];
            if (index >= 0 && index < updatedButtons.length) {
                updatedButtons[index] = { ...updatedButtons[index], [key]: value };
            }
            return updatedButtons;
        });
    }
    const updateVariable = (index, newValue) => {
        setVariables(prevArray => {
            if (index >= 0 && index < prevArray.length) {
                const newArray = [...prevArray];
                newArray[index] = newValue;
                return newArray;
            } else {
                const newArray = [...prevArray];
                for (let i = prevArray.length; i <= index; i++) {
                    newArray.push("");
                }
                newArray[index] = newValue;
                return newArray;
            }
        });
    };
    async function finalSubmit() {

        if (!selectedLanguage) {
            return showWarning("Please select a language")
        }
        if (!templateDetails?.name.trim()) {
            return showWarning("Please enter template name")
        }
        if (!templateDetails.category) {
            return showWarning("Please select template category")
        }
        if (message.length < 30) {
            return showWarning("Body content must be minimum 30 characters")
        }
        if (!variables.every(item => item !== "")) {
            return showWarning("Please add all variables samples")
        }
        const document = {
            "name": templateDetails?.name.trim(),
            "language": selectedLanguage,
            "category": templateDetails.category.toUpperCase(),
            "components": []
        };
        const header = {
            "type": "HEADER",
            "format": headerType.toUpperCase(),
        };

        if (headerType === "text") {
            header.text = headerContent;
        }

        if (headerType === "image" || headerType === "document") {
            const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            if (!urlRegex.test(headerContent)) {
                return showWarning("Please upload sample url for header");
            }
            header.example = { header_url: [headerContent] };
        }

        if (headerType !== "none") {
            document.components.push(header);
        }

        const body = {
            "type": "BODY",
            "text": message,
        };

        if (variableCounts > 0) {
            body.example = { body_text: variables };
        }

        document.components.push(body)
        const footer = {
            "type": "FOOTER",
            "text": footerContent
        };
        if (footerContent) {
            document.components.push(footer)
        }
        const button = {
            "type": "BUTTONS",
            "buttons": buttons.map((btn, index) => {
                const buttonObject = {
                    type: btn.action === "visit_website" ? "URL" : "PHONE_NUMBER",
                    text: btn.buttonText,
                };
                if (btn.action === "visit_website") {
                    buttonObject.url = btn.websiteUrl;
                }
                if (btn.action === "call_phone_number") {
                    buttonObject.phone_number = btn.phoneNumber;
                }
                if (btn.action === "visit_website" && btn.urlType === "dynamic") {
                    buttonObject.example = [btn.variable];
                }
                return buttonObject;
            })
        };

        if (buttons.length > 0) {
            document.components.push(button)
        }
        let headersList = {
            "Accept": "*/*",
            "Authorization": `Bearer ${accessToken}`
        }

        let reqOptions = {
            url: `https://graph.facebook.com/v19.0/${wab_Id}/message_templates`,
            method: "POST",
            headers: headersList,
            data: document
        }

        try {
            let response = await axios.request(reqOptions);
            if (response.data.status === "PENDING") {
                showWarning('Template created successfully')
                router.push("/admin/whatsapp");
                return
            }
            else {
                showWarning('Something went wrong while creating template, try again later')
                router.push("/admin/whatsapp");
            }
        } catch (error) {
            showWarning(error.message)
        }
    }
    return (
        <div className="relative w-full h-full pt-5 bg-background">
            <div className="flex items-center justify-between w-full px-5 mt-5">
                <div className="flex items-center gap-4 px-5">
                    <button className="flex items-center justify-center w-6 aspect-square" onClick={() => router.goBack()}>
                        <ArrowLeft size={24} color='white' />
                    </button>
                    <p className="text-2xl font-medium tracking-wide text-white">
                        WhatsApp Template
                    </p>
                </div>
                {/* <div className="">
                    <button
                        className="bg-[#8ea9fa] h-10 px-5 cursor-pointer select-none text-white rounded"
                    >
                        Create New Template
                    </button>
                </div> */}
            </div>
            <div className="w-full p-10">
                <div className="border-2 border-[#8080809a] w-full h-[80vh] grid grid-cols-10 rounded-lg bg-bg_highlight">
                    <div className="h-full col-span-2 p-5 rounded-l-lg bg-bg_highlight">
                        <p className="">Languages</p>
                        <select onChange={(event) => setSelectedLanguage(event.target.value)} className="h-10 rounded-md border mt-2 w-full px-3 text-sm tracking-wide font-light outline-none bg-background border-[#8080801e] appearance-none">
                            <option value="" hidden>Please select</option>
                            {whatsapp_languages?.map((lang, index) => (
                                <option value={lang?.short_code} key={index} className="first-letter:uppercase">{lang?.language}</option>
                            ))}
                        </select>
                    </div>
                    <div className="h-full col-span-5 p-5 overflow-y-auto bg-background" id="thin-scrollbar">
                        <div className="grid grid-cols-2 gap-10 pb-10 border-b border-[#80808080]">
                            <div className="">
                                <p className="">Template Name</p>
                                <input type="text" onChange={(event) => settemplateDetails({ ...templateDetails, name: event.target.value })} placeholder="ex: product_delivered" className="bg-bg_highlight placeholder:text-[#757070] h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-2" />
                            </div>
                            <div className="">
                                <p className="">Category</p>
                                <select onChange={(event) => settemplateDetails({ ...templateDetails, category: event.target.value })} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-2" >
                                    <option value="" hidden>Please select</option>
                                    <option value="marketing">Marketing</option>
                                    <option value="utility">Utility</option>
                                    <option value="authentication">Authentication</option>
                                </select>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-10 pt-10 pb-5">
                            <div className="">
                                <p className="">Button Type (Optional)</p>
                                <select onChange={(event) => setButtonType(event.target.value)} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-2" >
                                    <option value="" hidden>Please select</option>
                                    <option value="cta">Call to action</option>
                                    {/* <option value="quick_reply">Quick reply </option> */}
                                    <option value="none" selected>None</option>
                                </select>
                            </div>
                            <div className="">
                                <p className="">Header Type (Optional)</p>
                                <select onChange={(event) => setHeaderType(event.target.value)} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-2" >
                                    <option value="text">Text</option>
                                    <option value="image">Image</option>
                                    <option value="document">Document</option>
                                    <option value="none" selected>None</option>
                                </select>
                            </div>
                        </div>
                        {headerType !== "none" &&
                            <div className="pb-5 ">
                                <div className="">
                                    <p className="">Header</p>
                                    <input type='text' placeholder={`${headerType === "text" ? "Enter header text here" : "Enter sample file url"}`} onChange={(event) => setHeaderContent(event.target.value)} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-2" />
                                </div>
                            </div>
                        }
                        <div className="pb-5">
                            <div className="">
                                <p className="">Messaage</p>
                                <textarea type='text' value={message} onChange={(event) => { setMessage(event.target.value); variableCount() }} className="bg-bg_highlight h-52 rounded-md w-full appearance-none outline-none border-[#8080801e] p-3 border text-sm mt-2" />
                            </div>
                            <div className="flex justify-end gap-2">
                                <div onClick={() => setMessage(msg => msg + " **")} className="h-6 w-6 bg-[#93a8f44d] rounded-md flex items-center justify-center text-sm cursor-pointer">
                                    <TextB size={16} weight="bold" />
                                </div>
                                <div onClick={() => setMessage(msg => msg + " __")} className="h-6 w-6 bg-[#93a8f44d] rounded-md flex items-center justify-center text-sm cursor-pointer">
                                    <TextItalic size={16} weight="bold" />
                                </div>
                                <div onClick={() => setMessage(msg => msg + " ~~")} className="h-6 w-6 bg-[#93a8f44d] rounded-md flex items-center justify-center text-sm cursor-pointer">
                                    <TextStrikethrough size={16} weight="bold" />
                                </div>
                                <div onClick={async () => { const count = await variableCount(); const text = `{{${count + 1}}}`; setMessage(msg => msg + " " + text); setvariableCounts(count + 1) }} className="h-6 px-1 bg-[#93a8f44d] rounded-md flex items-center justify-center text-xs gap-2 cursor-pointer">
                                    <BracketsCurly size={16} weight="bold" />
                                    Add Variable
                                </div>
                            </div>
                            <div className="bg-bg_highlight border border-[#8080801e] px-5 mt-3 rounded-md">
                                {variableCounts > 0 && <div className="py-3">
                                    <p className="">Sample Content</p>
                                    {Array.from({ length: variableCounts }, (data, index) => (
                                        <div className="mt-2" key={index}>
                                            <input type="text" onChange={(event) => updateVariable(index, event.target.value)} className='w-full h-10 px-3 text-sm bg-transparent border border-[#e2e2e28f] rounded-md' placeholder={`{{${index + 1}}}`} />
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </div>
                        {buttonType !== "none" &&
                            <div className="pb-5 ">
                                <div className="">
                                    <p className="">Buttons</p>
                                    {buttonType === "cta" && <p className="text-[13px] text-[#aca5a5]">Create up to 2 'Visit Website' button or 1 'Call Phone Number' button</p>}
                                    {buttons?.map((button, index) => (
                                        <div key={index} className="border-2 mt-2 relative border-[#85808086] px-3 py-4  rounded-md">
                                            <div className="w-full">
                                                <div className="flex w-full">
                                                    <div className="basis-1/3">
                                                        <p className="pl-1 text-sm">Type of Action</p>
                                                        <select onChange={(event) => { updateButton(index, "action", event.target.value); updateButton(index, "buttonText", ""); updateButton(index, "urlType", ""); updateButton(index, "websiteUrl", ""); updateButton(index, "phoneNumber", "") }} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-1" >
                                                            <option value="" hidden>None</option>
                                                            <option value="visit_website" >Visit Website</option>
                                                            <option value="call_phone_number" >Call Phone Number</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {!button?.action ? null :
                                                    <div className="flex w-full mt-3">
                                                        <div className={`${button?.action === "visit_website" ? "basis-1/3" : "basis-1/2"}`}>
                                                            <p className="pl-1 text-sm">Button text</p>
                                                            <input value={button?.buttonText} onChange={(event) => updateButton(index, "buttonText", event.target.value)} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-1" />
                                                        </div>
                                                        {button?.action === "visit_website" &&
                                                            <>
                                                                <div className="basis-1/3">
                                                                    <p className="pl-1 text-sm">Url Type</p>
                                                                    <select onChange={(event) => updateButton(index, "urlType", event.target.value)} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-1" >
                                                                        <option value="static" selected>Static</option>
                                                                        <option value="dynamic" >Dynamic</option>
                                                                    </select>
                                                                </div>
                                                                <div className="basis-1/3">
                                                                    <p className="pl-1 text-sm">Website URL</p>
                                                                    <input onChange={(event) => updateButton(index, "websiteUrl", event.target.value)} type='text' className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-1" />
                                                                </div>
                                                            </>
                                                        }
                                                        {button?.action === "call_phone_number" &&
                                                            <>
                                                                <div className="basis-1/2">
                                                                    <p className="pl-1 text-sm">Phone Number</p>
                                                                    <input onChange={(event) => updateButton(index, "phoneNumber", event.target.value)} type='text' className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-1" />
                                                                </div>
                                                            </>
                                                        }

                                                    </div>
                                                }
                                            </div>
                                            {(button?.websiteUrl.includes("{{1}}") && button?.urlType === "dynamic") &&
                                                <div className="basis-1/2">
                                                    <p className="pl-1 text-sm">Sample Url</p>
                                                    <input onChange={(event) => updateButton(index, "variable", event.target.value)} type='text' className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-1" />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <button onClick={() => addButton()} className="text-[#93A8F4] font-medium mt-2"> + Add button</button>
                                </div>
                            </div>
                        }
                        <div className="pb-5 ">
                            <div className="">
                                <p className="">Footer</p>
                                <input type='text' onChange={(event) => setFooterContent(event.target.value)} className="bg-bg_highlight h-10 rounded-md w-full appearance-none outline-none border-[#8080801e] px-3 border text-sm mt-2" />
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-3 pb-5">
                            <button onClick={() => finalSubmit()} className="appearance-none outline-none rounded-md h-10 px-5 text-sm bg-[#93A8F4]">Submit for approval</button>
                        </div>
                    </div>
                    <div className="flex justify-center h-full col-span-3 p-5 rounded-r-lg bg-bg_highlight ">
                        {/* <p classNamse="text-sm font-light tracking-wide"> <span className="text-base font-medium">Preview</span> (this is a preview only)</p> */}
                        <div className="w-full min-w-[250px] max-w-[330px]  bg-[#F1EDE5] rounded-[7.5px] relative h-full flex flex-col select-none">
                            <div className="h-[80px] w-full bg-[#065F54] px-4 flex items-center rounded-t-lg">
                                <div className="h-[30px] aspect-square rounded-full mr-[12px]">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20icon.png?alt=media&token=71ebcd5d-3060-457a-a6fc-0d4dfa6de361" alt="" className="object-cover w-full h-full rounded-full" />
                                </div>
                                <p className="font-light tracking-wide">MyRevue Elite</p>
                            </div>
                            <div className="flex-1 px-4 pt-6 pb-4 ">
                                <div className="mb-3"><span color="blue" className="h-4 px-1 overflow-hidden text-xs font-semibold leading-4 tracking-wide text-blue-600 whitespace-no-wrap bg-blue-200 rounded">English</span></div>
                                <div className="css-1bofdss emggyy90 max-w-[90%]">
                                    {headerType === "text" && <div className="text-base font-semibold">{headerContent}</div>}
                                    {(headerType === "image" || headerType === "document") &&
                                        <div className="flex items-center justify-center h-[130px] mb-2 bg-blue-300 rounded-md">
                                            {headerType === "image" &&
                                                <svg
                                                    width={85}
                                                    height={64}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    data-prefix="fas"
                                                    data-icon="image"
                                                    role="img"
                                                    viewBox="0 0 512 512"
                                                    aria-labelledby="header-image-icon"
                                                >
                                                    <path
                                                        fill="#FFF"
                                                        d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"
                                                    />
                                                </svg>
                                            }
                                            {headerType === "document" &&
                                                <svg
                                                    width={48}
                                                    height={64}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    data-prefix="fas"
                                                    data-icon="file"
                                                    role="img"
                                                    viewBox="0 0 384 512"
                                                    aria-labelledby="header-document-icon"
                                                >
                                                    <path
                                                        fill="#FFF"
                                                        d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                                                    />
                                                </svg>
                                            }
                                        </div>
                                    }
                                    {message
                                        ?
                                        <div className="mt-1 text-sm leading-tight tracking-wide " dangerouslySetInnerHTML={messageOutput()}></div>
                                        :
                                        <div>Message text here...</div>
                                    }
                                    {footerContent && <div className="css-1hkjabm emggyy93">{footerContent}</div>}
                                    <div className="css-1n3x2a2">Now</div>
                                </div>
                                {/* <div className="css-n5f8r7 e1ah7oii0 max-w-[90%]">
                                    <div className="css-81c5cm e1ah7oii1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            className="css-13s3r1o"
                                        >
                                            <g
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                fill="none"
                                                fillRule="evenodd"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M13 3h4v4M17 13v2.6c0 .8-.6 1.4-1.4 1.4H4.4c-.8 0-1.4-.6-1.4-1.4V4.4C3 3.6 3.6 3 4.4 3H7M8 12l9-9" />
                                            </g>
                                        </svg>
                                        Website
                                    </div>
                                </div> */}
                            </div>
                            <footer className="flex p-4">
                                <div className="flex items-center justify-between css-1xjjb7d emggyy98">
                                    <div className="flex items-center gap-2">
                                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none">
                                            <circle cx={8} cy={8} r={7} stroke="#9AA8BD" strokeWidth={2} />
                                            <circle cx={6} cy={6} r={1} fill="#9AA8BD" />
                                            <circle cx={10} cy={6} r={1} fill="#9AA8BD" />
                                            <path d="M5 8a3 3 0 006 0" stroke="#9AA8BD" strokeWidth={2} />
                                        </svg>
                                        <span className="css-6pef9e emggyy97">Type a message</span>
                                    </div>
                                    <svg width={20} height={18} viewBox="0 0 20 18" fill="none">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.38.736L5.388 3.178H3.516c-1.623 0-2.93 1.348-2.93 3.024v8.025c0 1.675 1.307 3.024 2.93 3.024h12.88c1.622 0 2.929-1.349 2.929-3.024V6.202c0-1.676-1.307-3.024-2.93-3.024h-1.872L12.532.736H7.38zm2.575 4.358c2.542 0 4.614 2.138 4.614 4.762 0 2.624-2.072 4.762-4.614 4.762-2.541 0-4.612-2.138-4.612-4.762 0-2.624 2.07-4.762 4.612-4.762zM6.298 9.856c0-2.092 1.631-3.776 3.657-3.776 2.027 0 3.659 1.684 3.659 3.776 0 2.092-1.633 3.776-3.659 3.776s-3.657-1.684-3.657-3.776z"
                                            fill="#687992"
                                        />
                                    </svg>
                                </div>
                                <span className="css-19ylc6g emggyy910">
                                    <svg width={14} height={19} viewBox="0 0 14 19" fill="none">
                                        <path
                                            d="M6.832.45a2.738 2.738 0 012.744 2.745v5.49a2.738 2.738 0 01-2.744 2.744 2.738 2.738 0 01-2.745-2.745V3.195A2.738 2.738 0 016.832.451z"
                                            fill="#fff"
                                        />
                                        <path
                                            d="M1.135 8.828A.856.856 0 00.31 9.93c.811 2.83 3.023 4.901 5.664 5.275v3.017c0 .657 1.711.657 1.711 0v-3.017c2.638-.373 4.848-2.438 5.663-5.264.336-1.107-1.34-1.59-1.645-.475-.72 2.5-2.704 4.096-4.875 4.094-2.172-.004-4.157-1.602-4.874-4.102a.856.856 0 00-.82-.631z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </span>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
