import {
  Binoculars,
  CaretDown,
  CaretLeft,
  CaretUp,
  Checks,
  CircleNotch,
  ClipboardText,
  DotsSixVertical,
  FilePlus,
  Heart,
  PenNib,
  PencilSimple,
  SealQuestion,
  ShieldSlash,
  Trash,
  Upload,
  VideoCamera,
  X,
} from "@phosphor-icons/react";
import React from "react";
import { useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { nanoid } from "nanoid";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { db, storage } from "../../firebase";
import Modalcard from "../../components/modalcard";
import { useHistory } from "react-router-dom";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { strings } from "../../common/Strings";
import { getOpenAIContent } from "../../aivideoGenerator/aiapis";
import { showSuccess, showWarning } from "../../components/toast/toast";

export default function Createfaqs() {
  const router = useHistory();
  const params = new URLSearchParams(window.location.search);
  const form = params.get("form");
  const [{ user, brand, products }, dispatch] = useStateValue();
  const [tab, settab] = useState(null);
  const [camapignId, setcamapignId] = useState(nanoid(20));
  const [design, setDesign] = useState({
    logo: user?.imageUrl ?? null,
    primaryColor: "#0097A7",
    secondaryColor: "#02638F",
  });
  const [overview, setOverview] = useState({
    camapaignName: null,
    spokespersonlogo: null,
    welcomeMessage: null,
    spokespersonDesignation: null,
    spokespersonName: null,
  });
  const [productInfo, setProductInfo] = useState({
    productName: null,
    productImage: null,
    productId: null,
    sku: null,
    brandName: brand?.name,
    brandId: brand?.id,
    catId: null,
    subcategory: null,
  });
  const [points, setPoints] = useState([{ id: 1, question: "" }]);
  const [questions, setQuestions] = useState([{ id: 1, question: "" }]);
  const [customizations, setCustomizations] = useState({
    askQuestionBefore: false,
    multipleResponse: true,
  });
  const [thankYou, setthankYou] = useState({
    headline: null,
    thankyouMessage: null,
  });
  const [productSearchText, setProductSearchText] = useState("");
  const [showProductModal, setshowProductModal] = useState(false);
  const [productAdding, setproductAdding] = useState(false);
  const [draggedPointIndex, setDraggedPointIndex] = useState(null);
  const [draggedQuestionIndex, setDraggedQuestionIndex] = useState(null);
  const [finalSubmissionLoading, setfinalSubmissionLoading] = useState(false);
  const [link, setlink] = useState("");
  const maxPoints = 3;
  const maxQuestions = 5;

  async function getBase64(image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  }

  async function updateLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setDesign({ ...design, logo: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error.message);
          return;
        });
    }
  }
  async function updateSpokespersonLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setOverview({ ...overview, spokespersonlogo: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error.message);
          return;
        });
    }
  }

  async function setProduct(prod) {
    if (!prod) {
      showWarning("Please select a product");
      return;
    }
    try {
      setproductAdding(true);
      let headersList = {
        Accept: "*/*",
        Authorization:
          "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD",
      };

      let reqOptions = {
        url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${prod?.id}`,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      if (response?.data?.success === false) {
        showWarning("Something went wrong");
        return;
      }
      let data = response.data?.product;
      setProductInfo({
        productName: data?.name,
        productImage: data?.imageUrl,
        productId: data?.id,
        sku: data?.sku ?? null,
        brandName: brand?.name,
        brandId: brand?.id,
        catId: data?.catId,
        subcategory: data?.subcategory,
      });
      setproductAdding(false);
      setshowProductModal(false);
    } catch (error) {
      if (error.request) {
        showWarning(error.request);
      } else {
        showWarning(error.message);
      }
    }
  }

  const handlePointChange = (index, value) => {
    const updatedPoints = [...points];
    updatedPoints[index].question = value;
    setPoints(updatedPoints);
  };

  const handleDeletePoint = (index) => {
    const updatedPoints = [...points];
    updatedPoints.splice(index, 1);

    // Update the id of remaining points after deletion
    const updatedPointsWithNewIds = updatedPoints.map((point, newIndex) => ({
      ...point,
      id: newIndex + 1,
    }));

    setPoints(updatedPointsWithNewIds);
  };

  const handleAddPoint = () => {
    if (points.length < maxPoints) {
      const newId = points.length + 1;
      const newPoint = { id: newId, question: "" };
      setPoints([...points, newPoint]);
    }
  };

  const handlePointDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handlePointDragOver = (event) => {
    event.preventDefault();
  };

  const handlePointDrop = (event, dropIndex) => {
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedPoints = [...points];
    const draggedPoint = updatedPoints[dragIndex];

    updatedPoints.splice(dragIndex, 1);
    updatedPoints.splice(dropIndex, 0, draggedPoint);

    const updatedPointsWithNewIds = updatedPoints.map((point, index) => ({
      ...point,
      id: index + 1,
    }));

    setPoints(updatedPointsWithNewIds);
    setDraggedPointIndex(dropIndex); // Set the index of the dragged point

    // Clear the dragged point after a small delay to allow re-render
    setTimeout(() => {
      setDraggedPointIndex(null);
    }, 10);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestion = [...questions];
    updatedQuestion[index].question = value;
    setQuestions(updatedQuestion);
  };

  const handleDeleteQuestions = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);

    // Update the id of remaining points after deletion
    const updatedQuestionsWithNewIds = updatedQuestions.map(
      (question, newIndex) => ({
        ...question,
        id: newIndex + 1,
      })
    );

    setQuestions(updatedQuestionsWithNewIds);
  };

  const handleAddQuestion = () => {
    if (questions.length < maxQuestions) {
      const newId = questions.length + 1;
      const newQuestion = { id: newId, question: "" };
      setQuestions([...questions, newQuestion]);
    }
  };

  const handleQuestionDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleQuestionDragOver = (event) => {
    event.preventDefault();
  };

  const handleQuestionDrop = (event, dropIndex) => {
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedQuestion = [...questions];
    const draggedQuestion = updatedQuestion[dragIndex];

    updatedQuestion.splice(dragIndex, 1);
    updatedQuestion.splice(dropIndex, 0, draggedQuestion);

    const updatedQuestionWithNewIds = updatedQuestion.map((point, index) => ({
      ...point,
      id: index + 1,
    }));

    setQuestions(updatedQuestionWithNewIds);
    setDraggedQuestionIndex(dropIndex); // Set the index of the dragged point

    // Clear the dragged point after a small delay to allow re-render
    setTimeout(() => {
      setDraggedQuestionIndex(null);
    }, 10);
  };

  async function finalSubmit() {
    setfinalSubmissionLoading(true);

    const data = {
      LogoLink: design?.logo,
      bpsId: user?.brandId,
      brandemail: user?.email ?? user?.brandId,

      campaign_name: overview?.camapaignName,
      catId: productInfo?.catId,
      dateAdded: Date.now(),
      designation: overview?.spokespersonDesignation,
      headLine: thankYou.headline,
      id: camapignId,
      name: overview.spokespersonName,
      parentName: brand?.name,
      points: points,
      prodid: productInfo?.productId ?? null,
      productimage: productInfo?.productImage ?? null,
      productname: productInfo?.productName ?? null,
      productsku: productInfo?.sku ?? null,
      questions: questions,
      spokeLink: overview.spokespersonlogo,
      subcategory: productInfo?.subcategory ?? "",
      submissions: 0,
      thankYouMessageRef: thankYou.thankyouMessage,
      multipleResponse: customizations?.multipleResponse,
      welcomeMessage: overview.welcomeMessage,
      customizations: {
        primaryColor: design.primaryColor,
        secondaryColor: design.secondaryColor,
      },
    };
    if (form !== "floating_faq" && productInfo.productId === null) {
      showWarning("Please add a product");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.LogoLink) {
      showWarning("Please upload brand logo");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.spokeLink) {
      showWarning("Please add spokesperson details");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.name) {
      showWarning("Please add spokesperson details");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.designation) {
      showWarning("Please add spokesperson details");
      setfinalSubmissionLoading(false);
      return;
    }

    if (data.points.length < 1) {
      showWarning("Please add few point/tips for users");
      setfinalSubmissionLoading(false);
      return;
    }
    if (data.questions.length < 1) {
      showWarning("Please add atleast one question");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data?.headLine) {
      showWarning("Please add a thank you headline");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data?.thankYouMessageRef) {
      showWarning("Please add a thank you message");
      setfinalSubmissionLoading(false);
      return;
    }
    if (data.LogoLink.includes("data:image") === true) {
      const contentType = data.LogoLink.split(";")[0].split(":")[1]; // Extract content type from data URL
      const fileName = `faqforms/${camapignId}/logo.png`;
      const downloadURL = await uploadFile(
        data.LogoLink.split(",")[1],
        fileName,
        contentType
      );
      data.LogoLink = downloadURL;
      design.logo = downloadURL;
    }
    if (data.spokeLink.includes("data:image") === true) {
      const contentType = data.spokeLink.split(";")[0].split(":")[1]; // Extract content type from data URL
      const fileName = `faqforms/${camapignId}/spokeLogo.png`;
      const downloadURL = await uploadFile(
        data.spokeLink.split(",")[1],
        fileName,
        contentType
      );
      data.spokeLink = downloadURL;
      overview.spokespersonlogo = downloadURL;
    }
    await setDoc(doc(db, "faqforms/" + camapignId), data);
    showSuccess("campaign created successfully");
    await updateDoc(doc(db, strings.adminCollection + "/" + user?.email), {
      campaigns: user?.campaigns ? user?.campaigns + 1 : 1,
    });
    dispatch({
      type: "SET_USER",
      user: { ...user, campaigns: user?.campaigns ? user?.campaigns + 1 : 1 },
    });
    setlink(`https://brand.myrevue.app/faquserview/${camapignId}`);
    setfinalSubmissionLoading(null);
  }

  async function uploadFile(base64Data, fileName, contentType) {
    try {
      const storageRef = ref(storage, fileName);
      await uploadString(storageRef, base64Data, "base64", {
        contentType: contentType,
      }); // Change the contentType as needed
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.log(error);
      showWarning("Error in uploading image");
      return null;
    }
  }
  async function goBack() {
    router.push("/engagement/view-faqcampaigns/" + camapignId);
  }
  async function copylink() {
    navigator.clipboard.writeText(link);
    showSuccess("Link copied to clipboard");
    return;
  }
  return (
    <div className="h-[calc(100vh-64px)] flex overflow-y-auto">
      {finalSubmissionLoading === null && (
        <>
          <Modalcard close={goBack}>
            <div className="h-[70vh] aspect-video rounded-xl bg-[#202020] p-10 space-y-2">
              <p className="text-3xl font-medium tracking-wide">
                Collect Responses
              </p>
              <p className="font-work">
                Almost done! To collect responses, you'll need to share a link
                with your target respondents. You can do this via email, SMS,
                social media, or on the web.
              </p>
              <div className="relative bg-[#2E3036] h-14 w-full rounded-xl flex items-center justify-between p-1">
                <div className="flex-1 pl-3 break-words">
                  <p className="font-work">{link}</p>
                </div>
                <div
                  onClick={() => copylink()}
                  className="cursor-pointer bg-[#D9D9D9] h-full w-fit px-8 flex flex-col items-center justify-center rounded-lg text-black"
                >
                  <ClipboardText size={18} color="currentcolor" />
                  <p className="text-xs tracking-wide font-work">Copy</p>
                </div>
              </div>
              <p className="">QUICK TIPS</p>
              <ul className="pl-5">
                <li className="list-disc font-work">
                  Each time you get a response, we'll autogenerate a draft video
                  for you to review & publish.
                </li>
                <li className="list-disc font-work">
                  Ready to invite respondents but not sure what to say ? Adapt
                  our sample invitaion messages.
                </li>
                <li className="list-disc font-work">
                  Check out our11 tips for higher response rates.
                </li>
              </ul>
            </div>
          </Modalcard>
        </>
      )}
      <div className="w-full max-w-[576px] h-full bg-[#202020] p-8 overflow-y-auto">
        <div className="mb-3 rounded-lg">
          {(tab === null || tab === 2 || tab === 4 || tab === 3) && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Welcome to <span className="text-[#EA335F]">MyRevue</span>! Our
              FAQ form will assist you in gathering valuable reviews from your
              users. Let's get started with the setup!
            </p>
          )}
          {tab === 0 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Configure your video collector's{" "}
              <span className="text-[#EA335F]">look & feel here.</span>& add
              your <span className="text-[#EA335F]">logo</span>
            </p>
          )}
          {tab === 1 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              This page greets respondents, explains how it{" "}
              <span className="text-[#EA335F]">works</span>, and{" "}
              <span className="text-[#EA335F]">
                offers optional incentives.
              </span>
              This can be different from your collector's internal name.
            </p>
          )}
          {tab === 5 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Say <span className="text-[#EA335F]">thanks</span> for
              participating and let people know you'll follow up about any
              custom incentives they've earned.
            </p>
          )}
        </div>

        <div className="">
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 0 ? settab(null) : settab(0))}
              className={`${
                tab === 0
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 0 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <PenNib size={22} color="currentcolor" />
              <p className="">Design</p>
              <div className="flex-grow"></div>
            </button>

            {tab === 0 && (
              <div className="w-full py-4">
                <div className="">
                  <p className="">Logo</p>
                  <div className="h-16  min-w-[120px] max-w-fit bg-[#ffffff] mt-2 rounded-md flex items-center justify-center p-1 text-xl relative">
                    {design?.logo === null ? (
                      <p className="">+</p>
                    ) : (
                      <img src={design?.logo} alt="" className="h-full" />
                    )}
                    <input
                      type="file"
                      onChange={(event) => updateLogo(event)}
                      className="absolute w-full h-full opacity-0"
                      accept=".png, .jpg,.jpeg"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-3">
                  <div className="w-full">
                    <p className="">Primary Color</p>
                    <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                      <div className="relative self-center w-6 h-6">
                        <div className="absolute z-10 w-full h-full">
                          <input
                            type="color"
                            onChange={(event) =>
                              setDesign({
                                ...design,
                                primaryColor: event.target.value,
                              })
                            }
                            className="w-full h-full rounded-full opacity-0"
                            name=""
                            id=""
                          />
                        </div>
                        <button
                          type="button"
                          className="w-full h-full border rounded-full"
                          style={{ backgroundColor: design?.primaryColor }}
                        ></button>
                      </div>
                      <input
                        type="text"
                        defaultValue={design?.primaryColor}
                        onChange={(event) =>
                          setDesign({
                            ...design,
                            primaryColor: event.target.value,
                          })
                        }
                        className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                        placeholder="#4E46E5"
                        name=""
                        id=""
                      ></input>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="">Background Color</p>
                    <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                      <div className="relative self-center w-6 h-6">
                        <div className="absolute z-10 w-full h-full">
                          <input
                            type="color"
                            onChange={(event) =>
                              setDesign({
                                ...design,
                                secondaryColor: event.target.value,
                              })
                            }
                            className="w-full h-full rounded-full opacity-0"
                            name=""
                            id=""
                          />
                        </div>
                        <button
                          type="button"
                          className="w-full h-full border rounded-full"
                          style={{ backgroundColor: design?.secondaryColor }}
                        ></button>
                      </div>
                      <input
                        type="text"
                        defaultValue={design?.secondaryColor}
                        onChange={(event) =>
                          setDesign({
                            ...design,
                            secondaryColor: event.target.value,
                          })
                        }
                        className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                        placeholder="#4E46E5"
                        name=""
                        id=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 1 ? settab(null) : settab(1))}
              className={`${
                tab === 1
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 1 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Binoculars size={22} color="currentcolor" />
              <p className="">Overview</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 1 && (
              <div className="w-full py-4 space-y-3">
                <div className="">
                  <p className="text-[#F8F8F8]">Campaign Name</p>
                  <input
                    type="text"
                    className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Campaign name"
                    value={overview?.camapaignName}
                    onChange={(event) =>
                      setOverview({
                        ...overview,
                        camapaignName: event.target.value,
                      })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 5
                        ? showWarning("Campaign Name must be atleast 5 letters")
                        : null
                    }
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide">
                    <span className="text-[#EA335F] text-base">
                      Spokesperson
                    </span>
                    <br />
                    Make it feel personal with a friendly face from your
                    company.
                  </p>
                  <div className="flex items-center w-full gap-8 mt-2">
                    <div className="h-20 w-20 bg-[#434343] rounded-full flex items-center justify-center p-1 text-xl relative">
                      {overview?.spokespersonlogo === null ? (
                        <p className="">+</p>
                      ) : (
                        <img
                          src={overview?.spokespersonlogo}
                          alt=""
                          className="object-cover w-full h-full rounded-full"
                        />
                      )}
                      <input
                        type="file"
                        onChange={(event) => updateSpokespersonLogo(event)}
                        className="absolute w-full h-full opacity-0"
                        accept=".png, .jpg,.jpeg"
                      />
                    </div>
                    <div className="flex-1 w-full space-y-3">
                      <div className="">
                        <input
                          type="text"
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Full Name"
                          value={overview?.spokespersonName}
                          onChange={(event) =>
                            setOverview({
                              ...overview,
                              spokespersonName: event.target.value,
                            })
                          }
                          onBlur={(event) =>
                            event.currentTarget.value.length < 3
                              ? showWarning(
                                  "Please enter full name of spokesperson"
                                )
                              : null
                          }
                        />
                      </div>
                      <div className="">
                        <input
                          type="text"
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Designation"
                          value={overview?.spokespersonDesignation}
                          onChange={(event) =>
                            setOverview({
                              ...overview,
                              spokespersonDesignation: event.target.value,
                            })
                          }
                          onBlur={(event) =>
                            event.currentTarget.value.length < 2
                              ? showWarning(
                                  "Please enter designation of spokesperson"
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="text-[#F8F8F8]">Welcome Message</p>
                  <div className="relative">
                    <i
                      onClick={async (e) => {
                        const icon = e.currentTarget;
                        if (icon) {
                          icon.classList.remove("fa-lightbulb");
                          icon.classList.add("fa-spinner");
                          icon.classList.add("animate-spin");

                          try {
                            const response = await getOpenAIContent(
                              overview?.welcomeMessage
                            );
                            setOverview({
                              ...overview,
                              welcomeMessage: response,
                            });
                          } catch (error) {
                            console.error(error);
                          } finally {
                            if (icon) {
                              icon.classList.remove("fa-spinner");
                              icon.classList.remove("animate-spin");
                              icon.classList.add("fa-lightbulb");
                            }
                          }
                        }
                      }}
                      className="absolute hidden text-yellow-400 cursor-pointer fa-solid top-4 right-2 fa-lightbulb"
                    ></i>
                    <textarea
                      onChange={(event) => {
                        const icon =
                          event.target.parentElement.querySelector("i");
                        if (icon) {
                          icon.style.display =
                            event.target.value.length > 10 ? "block" : "none";
                        }
                        setOverview({
                          ...overview,
                          welcomeMessage: event.target.value,
                        });
                      }}
                      value={overview?.welcomeMessage}
                      type="text"
                      className="appearance-none outline-none h-24 rounded-md p-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="Welcome Message"
                      onBlur={(event) =>
                        event.currentTarget.value.length < 5 &&
                        showWarning("Please add a longer welcome message")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {form !== "floating_faq" && (
            <div className="border-b border-[#808080]">
              <button
                onClick={() => (tab === 2 ? settab(null) : settab(2))}
                className={`${
                  tab === 2
                    ? "border-b border-[#808080] text-white"
                    : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
              >
                {tab === 2 ? (
                  <CaretUp size={16} color="currentcolor" />
                ) : (
                  <CaretDown size={16} color="currentcolor" />
                )}
                <FilePlus size={22} color="currentcolor" />
                <p className="">Product</p>
                <div className="flex-grow"></div>
              </button>
              {tab === 2 && (
                <div className="relative w-full py-4 space-y-3 group">
                  <div className="w-full">
                    <div className="flex items-center w-full gap-8 mt-2">
                      <div className="w-16 h-16 rounded-full">
                        <div
                          onClick={() => setshowProductModal(!showProductModal)}
                          className=" cursor-pointer h-full w-full bg-[#434343] rounded-full text-xl flex items-center justify-center"
                        >
                          {productAdding === true ? (
                            <div className="animate-spin">
                              <CircleNotch size={22} color="white" />
                            </div>
                          ) : productInfo?.productImage === null ? (
                            <p className="">+</p>
                          ) : (
                            <img
                              src={productInfo?.productImage}
                              alt=""
                              className="w-full h-full rounded-full"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex-1 w-full">
                        <div className="my-3">
                          <input
                            type="text"
                            defaultValue={productInfo?.productName}
                            readOnly
                            onFocus={() =>
                              setshowProductModal(!showProductModal)
                            }
                            className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                            placeholder="Product Name"
                          />
                        </div>
                      </div>
                    </div>
                    {showProductModal === true && (
                      <div className="absolute mt-3 py-3 w-full h-80 overflow-y-auto bg-[#202020] z-50 border border-[#808080] rounded-md">
                        <div className="flex gap-3 px-3 my-3">
                          <input
                            type="text"
                            onChange={(event) =>
                              setProductSearchText(event.target.value)
                            }
                            className="flex-1 w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                            placeholder="Search by product name"
                          />
                          <button
                            className=""
                            onClick={() => setshowProductModal(false)}
                          >
                            <X size={32} color="white" />
                          </button>
                        </div>
                        {products
                          ?.filter((val) =>
                            val?.name
                              ?.toLowerCase()
                              ?.includes(productSearchText?.toLowerCase())
                          )
                          ?.map((prod, index) => (
                            <div
                              className="w-full flex h-16 my-3 items-center justify-between cursor-pointer hover:bg-[#2E3036] px-3"
                              key={index}
                              onClick={() => setProduct(prod)}
                            >
                              <div className="flex items-center flex-1 w-full gap-3">
                                <div className="w-12 h-12 rounded-full">
                                  <img
                                    src={prod?.imageUrl}
                                    alt="productimage"
                                    className="w-full h-full rounded-full"
                                  />
                                </div>
                                <div className="flex-1 w-full">
                                  <p className="w-full text-sm tracking-wide">
                                    {prod?.name}
                                  </p>
                                </div>
                              </div>
                              <div className="">
                                <ReactStars
                                  size={25}
                                  count={5}
                                  isHalf={false}
                                  value={prod?.rating}
                                  color="white"
                                  activeColor="#FF7D58"
                                  edit={false}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 3 ? settab(null) : settab(3))}
              className={`${
                tab === 3
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 3 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <SealQuestion size={22} color="currentcolor" />
              <p className="">Question</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 3 && (
              <div className="w-full py-4 space-y-3">
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide">
                    <span className="text-[#EA335F] text-base">Points</span>
                    <br />
                    Ask user to cover this points in their video.
                  </p>
                  <div className="">
                    <div className="w-full mt-2">
                      {points?.map((point, index) => (
                        <div
                          className="flex items-center flex-1 w-full"
                          key={index}
                          draggable
                          onDragStart={(e) => handlePointDragStart(e, index)}
                          onDragOver={handlePointDragOver}
                          onDrop={(e) => handlePointDrop(e, index)}
                        >
                          <button className="">
                            <DotsSixVertical size={28} color="white" />
                          </button>
                          <div className="relative flex-1 w-full my-3">
                            <div className="absolute flex items-center h-full right-3">
                              <button
                                className="ml-2 text-red-500 "
                                onClick={() => handleDeletePoint(index)}
                              >
                                <Trash size={25} color="white" />
                              </button>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter a point/tips for user"
                              onChange={(e) =>
                                handlePointChange(index, e.target.value)
                              }
                              value={point.question}
                              className={`w-full h-9 pl-3 pr-12 text-sm tracking-wide bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide ${
                                draggedPointIndex === index ? "dragged" : ""
                              }`}
                            />
                          </div>
                        </div>
                      ))}
                      {points.length < maxPoints && (
                        <div className="border p-2 border-[#808080] rounded-lg">
                          <button
                            className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none flex items-center justify-center"
                            onClick={handleAddPoint}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide mt-12">
                    <span className="text-[#EA335F] text-base">Questions</span>
                    <br />
                    Ask up to 5 questions. Read our tips & tricks
                  </p>
                  <div className="">
                    <div className="w-full mt-2">
                      {questions?.map((question, index) => (
                        <div
                          className="flex items-center flex-1 w-full"
                          key={index}
                          draggable
                          onDragStart={(e) => handleQuestionDragStart(e, index)}
                          onDragOver={handleQuestionDragOver}
                          onDrop={(e) => handleQuestionDrop(e, index)}
                        >
                          <button className="">
                            <DotsSixVertical size={28} color="white" />
                          </button>
                          <div className="relative flex-1 w-full my-3">
                            <div className="absolute flex items-center h-full right-3">
                              <button
                                className="ml-2 text-red-500 "
                                onClick={() => handleDeleteQuestions(index)}
                              >
                                <Trash size={25} color="white" />
                              </button>
                            </div>
                            <input
                              type="text"
                              placeholder="Enter a faq question for user"
                              onChange={(e) =>
                                handleQuestionChange(index, e.target.value)
                              }
                              value={question.question}
                              className={`w-full h-9 pl-3 pr-12 text-sm tracking-wide bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide ${
                                draggedPointIndex === index ? "dragged" : ""
                              }`}
                            />
                          </div>
                        </div>
                      ))}
                      {questions.length < maxQuestions && (
                        <div className="border p-2 border-[#808080] rounded-lg">
                          <button
                            className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none flex items-center justify-center"
                            onClick={handleAddQuestion}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 4 ? settab(null) : settab(4))}
              className={`${
                tab === 4
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 4 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <PencilSimple size={22} color="currentcolor" />
              <p className="">Customizations</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 4 && (
              <div className="w-full py-4 space-y-3">
                <div className="flex justify-between">
                  <p className="text-[#F8F8F8] tracking-wide text-sm">
                    Can user upload mulitple response in same product
                  </p>
                  {/* <input type="checkbox" className="h-10 outline-none appearance-none" /> */}
                  <input
                    type="checkbox"
                    id="multipleResponse"
                    name="multipleResponse"
                    onChange={(event) =>
                      event.target.checked === true
                        ? setCustomizations({
                            ...customizations,
                            multipleResponse: true,
                          })
                        : setCustomizations({
                            ...customizations,
                            multipleResponse: false,
                          })
                    }
                    checked={customizations?.multipleResponse}
                  />
                  <label htmlFor="multipleResponse"></label>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 5 ? settab(null) : settab(5))}
              className={`${
                tab === 5
                  ? "border-b border-[#808080] text-white"
                  : "text-[#acacac]"
              }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 5 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Heart size={22} color="currentcolor" />
              <p className="">Thank you page</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 5 && (
              <div className="w-full py-4 space-y-3">
                <div className="">
                  <p className="text-[#F8F8F8]">Headline</p>
                  <input
                    type="text"
                    className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Headline"
                    value={thankYou.headline}
                    onChange={(event) =>
                      setthankYou({ ...thankYou, headline: event.target.value })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 1
                        ? showWarning("Please add a headline")
                        : null
                    }
                  />
                </div>
                <div className="">
                  <p className="text-[#F8F8F8]">Message</p>
                  <textarea
                    type="text"
                    className="appearance-none outline-none h-28 rounded-md p-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Message"
                    value={thankYou.thankyouMessage}
                    onChange={(event) =>
                      setthankYou({
                        ...thankYou,
                        thankyouMessage: event.target.value,
                      })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 1
                        ? showWarning("Please add a thank you message")
                        : null
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            {finalSubmissionLoading === null ? (
              <button
                className={`bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto    `}
              >
                <p className="">Finished</p>
              </button>
            ) : (
              <button
                onClick={() =>
                  finalSubmissionLoading === true ? null : finalSubmit()
                }
                className={`bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto    `}
              >
                {finalSubmissionLoading === true ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  <p className="">Finish</p>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-1 w-full h-full px-10">
        <div className="relative flex flex-none flex-col overflow-hidden bg-[#20202060] shadow-xl ring-4 ring-white duration-300 w-full rounded-md h-[90%] p-5">
          {(tab === 0 ||
            tab === 1 ||
            tab === 2 ||
            tab === 4 ||
            tab === null) && (
            <div
              className={`h-full aspect-[9/17] xl:aspect-[9/18] relative rounded-lg mx-auto `}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div
                className="relative h-full overflow-y-auto"
                id="thin-scrollbar"
              >
                <div className="flex items-center justify-center px-5 pt-10">
                  <div className="flex items-center justify-center h-16 px-8 py-2 overflow-hidden bg-white rounded-xl ">
                    <img
                      src={
                        design.logo ??
                        "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      }
                      alt=""
                      className="h-full mx-auto "
                    />
                  </div>
                </div>
                <div className="px-5 mt-5">
                  <div className="">
                    <p className="font-medium tracking-wider text-center">
                      {overview?.camapaignName ?? "Campaign Name"}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center my-5">
                    <div>
                      {overview?.spokespersonlogo ? (
                        <img
                          src={overview?.spokespersonlogo}
                          alt=""
                          className="object-cover w-16 h-16 mx-auto my-2 rounded-full"
                        />
                      ) : (
                        <div className="flex items-center justify-center w-16 h-16 text-3xl text-pink-500 bg-white rounded-full">
                          {overview?.spokespersonName
                            ?.split(" ")[0]
                            .substring(0, 2)
                            .toUpperCase() ?? "NA"}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <p className="tracking-wide text-center ">
                        {overview?.spokespersonName ?? "Spokesperson Name"}
                      </p>
                      <p className="font-light tracking-wide text-center opacity-50">
                        {overview?.spokespersonDesignation ??
                          "Spokesperson Designation"}
                      </p>
                    </div>
                    <div className="border-l-[3px] border-red-500 mt-5">
                      <p className="px-2 tracking-wide">
                        {overview?.welcomeMessage ?? "Welcome to MyRevue"}
                      </p>
                    </div>
                    <div className="flex flex-col items-center mt-12">
                      <p className="text-lg font-medium">How it Works</p>
                      <div className="flex w-full mt-5 gap-x-2">
                        <img
                          src="https://vocalvideo.com/assets/icons/record-blurb-mobile-ddb01ba6be7d6a634b3af138233feacb91bcdaa39f496d14eede0627ab2c573b.svg"
                          alt=""
                          className=""
                        />
                        <p className="flex-1 w-full text-sm tracking-wide">
                          Tell your story by recording a short video clip here.
                          Nothing to download or install.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="absolute bottom-0 flex flex-col justify-end w-full h-32 px-5 pb-5 rounded-b-lg bg-gradient-to-b from-transparent via-transparent"
                style={{ "--tw-gradient-to": "#00000069" }}
              >
                <button
                  className="w-full py-3 rounded-lg outline-none appearance-none"
                  style={{ backgroundColor: design?.primaryColor }}
                >
                  Let's Go
                </button>
              </div>
            </div>
          )}

          {tab === 3 && (
            <div
              className={`h-full aspect-[9/17] xl:aspect-[9/18] relative rounded-lg mx-auto `}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div
                className="relative h-full px-5 py-5 overflow-y-auto"
                id="thin-scrollbar"
              >
                <div className="flex items-center justify-center bg-white rounded-lg bg-opacity-10 h-14 w-14">
                  <CaretLeft size={22} color="white" />
                </div>
                <div className="mt-8 ">
                  <p className="px-2  tracking-wide text-[#698FAC]">
                    Question:{" "}
                    <span className="text-white text-opacity-100">
                      Question Name
                    </span>
                  </p>
                  <p className="px-2 mt-3 mb-1 text-lg tracking-wide text-red-500">
                    Tips
                  </p>
                  {points?.map((point, index) => (
                    <div key={index} className="pl-3">
                      <p className="text-sm tracking-wide ">
                        {index + 1}: {point.question}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="absolute bottom-0 flex flex-col justify-end w-full h-40 px-5 pb-5 rounded-b-lg bg-gradient-to-b from-transparent via-transparent"
                style={{ "--tw-gradient-to": "#00000069" }}
              >
                <p className="mb-3 tracking-wide text-center">
                  How would you like to respond?
                </p>
                <button
                  className="flex items-center justify-center w-full gap-4 py-3 my-1 rounded-lg outline-none appearance-none"
                  style={{ backgroundColor: design?.primaryColor }}
                >
                  <VideoCamera size={22} color="white" />
                  Record video
                </button>
                <button
                  className="flex items-center justify-center w-full gap-4 py-3 my-1 rounded-lg outline-none appearance-none"
                  style={{ backgroundColor: design?.primaryColor }}
                >
                  <Upload size={22} color="white" />
                  Upload video
                </button>
              </div>
            </div>
          )}
          {tab === 5 && (
            <div
              className={`h-full aspect-[9/17] xl:aspect-[9/18] relative rounded-lg mx-auto `}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div
                className="relative h-full px-5 py-5 overflow-y-auto"
                id="thin-scrollbar"
              >
                <div className="flex items-center justify-center bg-white rounded-lg bg-opacity-10 h-14 w-14">
                  <CaretLeft size={22} color="white" />
                </div>
                <div className="mt-8 ">
                  <div className="flex flex-col justify-center mt-12">
                    <div className="border-l-[3px] border-red-500 ">
                      <p className="px-2 text-3xl font-medium">
                        🎉 {thankYou?.headline ?? "HeadLine"}
                      </p>
                      <p className="px-2 mt-3 font-sans text-xl font-light tracking-wide">
                        {thankYou?.thankyouMessage ?? "Thank you message"}
                      </p>
                    </div>
                    <div className="flex items-center mt-8 gap-x-3">
                      <div>
                        {overview?.spokespersonlogo ? (
                          <img
                            src={overview?.spokespersonlogo}
                            alt=""
                            className="object-cover w-16 h-16 mx-auto my-2 rounded-full"
                          />
                        ) : (
                          <div className="flex items-center justify-center w-16 h-16 text-3xl text-pink-500 bg-white rounded-full">
                            {overview?.spokespersonlogo
                              ?.split(" ")[0]
                              .substring(0, 2)
                              .toUpperCase() ?? "NA"}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <p className="tracking-wide ">
                          {overview?.spokespersonName ?? "Spokesperson Name"}
                        </p>
                        <p className="font-light tracking-wide opacity-50">
                          {overview?.spokespersonDesignation ??
                            "Spokesperson Designation"}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-end mt-16 ">
                      <div className="flex flex-col items-center justify-center w-full p-10 bg-white rounded-2xl">
                        <p className="font-sans text-lg tracking-widest text-black uppercase ">
                          POWERED BY
                        </p>
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMyRevue%20Logo%20(1)%201.svg?alt=media&token=f3878494-f182-43c4-bbfb-6d1f7247af27"
                          alt=""
                          className="my-3 h-14"
                        />
                        <p className="mt-3 italic text-center text-black">
                          <span className="not-italic font-medium text-pink-500 underline underline-offset-2">
                            MyRevue
                          </span>{" "}
                          is first indian platform for reviews content. Stream
                          and publish reviews and get assured cashbacks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
