import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modalcard from "../components/modalcard";
import { useStateValue } from "../context/StateProvider";
import {
  ArrowLeft,
  ArrowRight,
  CaretDown,
  MagnifyingGlass,
  X,
} from "@phosphor-icons/react";
import { showSuccess, showWarning } from "../components/toast/toast";
import ReviewCard from "../components/integration/reviewCard";
import ProductCard from "../components/integration/productCard";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import AWN from "awesome-notifications";

export default function Integration() {
  const [{ user, brand, products, flicks, reviews }, dispatch] =
    useStateValue();
  const router = useHistory();
  const params = new URLSearchParams(window.location.search);
  const step = params.get("step");
  const tab = params.get("tab");
  const [search_text, setSearch_text] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    sorting: null,
    rating: null,
    dateAdded: null,
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);

  const [faqVideos, setFaqVideos] = useState([]);
  const [selectedFloatingFaq, setSelectedFloatingFaq] = useState(null);
  const [selectedFaq, setSelectedFaq] = useState([]);
  const [previewFaq, setPreviewFaq] = useState(null);

  useEffect(() => {
    if (
      step === "floating-video" ||
      step === "video-showcase" ||
      step === "product-faq"
    ) {
      getFaqSubmissions();
    }
  }, [step]);

  async function getFaqSubmissions() {
    const formsCollection = collection(db, "faqforms");
    const formQuery = query(
      formsCollection,
      where("brandemail", "==", user?.email)
    );

    try {
      const querySnapshot = await getDocs(formQuery);
      const promises = querySnapshot.docs.map(async (doc) => {
        const submissionsCollection = collection(
          db,
          "faqforms",
          doc.data().id,
          "submissions"
        );
        const submissionsSnapshot = await getDocs(submissionsCollection);
        return submissionsSnapshot.docs?.map((submission) => ({
          ...submission.data(),
          campaign_name: submission?.data()?.campaign_info,
          id: submission.id,
          brand: brand?.id,
          campaignId: doc.data().id,
          prodid: doc.data()?.prodid ?? null,
          productname: doc.data()?.productname ?? null,
          productimage: doc.data()?.productimage ?? null,
          productsku: doc.data()?.productsku ?? null,
        }));
      });
      const results = await Promise.all(promises);
      const submissions = [].concat(...results);
      setFaqVideos(submissions);
      return submissions;
    } catch (error) {
      showWarning("Error fetching FAQ submissions:");
      console.log(error);
      return [];
    }
  }

  async function clearFilter() {
    setFilters({
      sorting: null,
      rating: null,
      dateAdded: null,
    });
    setShowFilters(false);
  }

  async function closePreview() {
    return router.replace("/admin/integration");
  }

  async function updateProductSelection(id) {
    if (selectedProducts.includes(id)) {
      const updatedSelection = selectedProducts.filter(
        (productId) => productId !== id
      );
      setSelectedProducts(updatedSelection);
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  }
  async function updateSingleProductSelection(id) {
    setSelectedProducts([id]);
  }

  async function updateReviewSelection(id) {
    if (selectedReviews.includes(id)) {
      const updatedSelection = selectedReviews.filter(
        (reviewId) => reviewId !== id
      );
      setSelectedReviews(updatedSelection);
    } else {
      setSelectedReviews([...selectedReviews, id]);
    }
  }
  async function updateFaqSelection(faq) {
    if (selectedFaq.includes(faq?.id)) {
      const updatedSelection = selectedFaq.filter((faqId) => faqId !== faq?.id);
      setSelectedFaq(updatedSelection);
    } else {
      setSelectedFaq([...selectedFaq, faq?.id]);
    }
  }

  async function clearAllSelections() {
    clearFilter();
    setSearch_text("");
    setSelectedProducts([]);
    setSelectedReviews([]);
    setSelectedFaq([]);
    setSelectedFloatingFaq(null);
    setPreviewFaq(null);
  }
  async function closeFaqPreviewModal() {
    setPreviewFaq(null);
  }
  async function submitProductWall() {
    if (selectedReviews.length === 0) {
      return showWarning("Please select atleast one review/flick to continue");
    }
    try {
      let headersList = {
        Accept: "*/*",
        Authorization:
          "7o6pu8v-0Au8vx9yi1jCDECDEr7si1ji1ju8vl5mghiwx93ghstu1jkQPOPQRmnoPQRMNODEFl5mJKLBCD",
        "Content-Type": "application/json",
      };

      let bodyContent = {
        vids: selectedReviews,
        prods: selectedProducts,
        brand: brand.id,
      };

      let reqOptions = {
        url: "https://mainapi-zscu3untuq-as.a.run.app/updateProductWall",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };
      var response = await axios.request(reqOptions);
      if (response.data.success) {
        showSuccess("Product Wall successfully updated");
        router.replace("/admin/integration");
        window.open(
          "https://myrevue.gitbook.io/myrevue-elite/integrations/product-wall",
          "_blank"
        );
        router.replace("/admin/integration");
        return;
      } else {
        return showWarning("Something went wrong");
      }
    } catch (error) {
      return showWarning("Something went wrong");
    }
  }
  async function submitFloatingVideo() {
    if (selectedFloatingFaq === null) {
      return showWarning("Please select a faq submission to conitnue");
    }
    try {
      const data = selectedFloatingFaq?.reviews?.map((review) => {
        return {
          brand: brand?.id,
          question: review.question,
          source: review.reviewUrl,
          type: "homeGuide",
          group: "homeGuide" + brand?.id,
          thumbnail: selectedFloatingFaq.thumbnailurl,
        };
      });

      const myrevuequestions = data
        .map(
          (quest) => `<myrevue
      question="${quest.question}"
      src="${quest.source}"
    ></myrevue>`
        )
        .join("");
      let headersList = {
        Accept: "*/*",
        Authorization:
          "7o6pu8v-0Au8vx9yi1jCDECDEr7si1ji1ju8vl5mghiwx93ghstu1jkQPOPQRmnoPQRMNODEFl5mJKLBCD",
        "Content-Type": "application/json",
      };

      let bodyContent = data;

      let reqOptions = {
        url: "https://mainapi-zscu3untuq-as.a.run.app/insertFaqVidoes",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };
      const response = await axios.request(reqOptions);
      if (response.data.success) {
        showSuccess("Floating Video Guide has been successfully updated");
        router.replace("/admin/integration");
        var codeCopy = `<div id="myrevue-home-ceo-guide">
        ${myrevuequestions}
        <div class="myrevue-home-ceo-guide-container">
          <span id="myrevue-guide-close"> &#x2715; </span>
          <video playsInline 
            poster="${data[0].thumbnail}"
            id="myrevue-home-vid"
          ></video>
          <div
            id="myrevue-guide-question-wrapper"
            class="myrevue-question-wrapper"
          ></div>
          <div class="myrevue-powered-by">
            <span>Powered By</span>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Flogo%20myrevue.svg?alt=media&token=dfb36592-62a2-4e4c-aeda-bd3a6f118947"
              alt=""
            />
          </div>
        </div>
      </div>`;
        new AWN().modal(
          `<h3>Copy and paste this code on Custom Liquid Block.</h3><pre><textarea style='width:100%;height:500px;color:white;background-color:black' readonly>${codeCopy}</textarea></pre>`
        );
        return;
      } else {
        return showWarning("Something went wrong while updating");
      }
    } catch (error) {
      console.log(error);
      showWarning("Something went wrong");
      return;
    }
  }
  async function submitVideoWall() {
    if (selectedReviews.length === 0) {
      return showWarning("Please select atleast one reviews/flicks");
    }
    try {
      let headersList = {
        Accept: "*/*",
        Authorization:
          "7o6pu8v-0Au8vx9yi1jCDECDEr7si1ji1ju8vl5mghiwx93ghstu1jkQPOPQRmnoPQRMNODEFl5mJKLBCD",
        "Content-Type": "application/json",
      };

      let bodyContent = { vids: selectedReviews, brand: brand.id };
      let reqOptions = {
        url: "https://mainapi-zscu3untuq-as.a.run.app/updateVideoWall",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };
      var response = await axios.request(reqOptions);
      if (response.data.success) {
        showSuccess("Video wall has been successfully updated");
        router.replace("/admin/integration");
        window.open(
          "https://myrevue.gitbook.io/myrevue-elite/integrations/video-wall",
          "_blank"
        );
        return;
      } else {
        showWarning("something went wrong while updating");
        return;
      }
    } catch (error) {
      console.log(error);
      showWarning("Something went wrong");
      return;
    }
  }
  async function submitVideoShowcase() {
    if (selectedFaq.length === 0) {
      return showWarning("Please select atleast one faq submission");
    }
    var faqs = [];
    var selectedSkus = new Set(); // Set to store unique selected SKUs
    for (const faq of faqVideos) {
      if (selectedFaq.includes(faq.id)) {
        if (faq.productsku) {
          selectedSkus.add(faq.productsku); // Add the SKU to the selectedSkus Set
        }
      }
    }

    var uniqueSkus = Array.from(selectedSkus);
    for (const faq of faqVideos) {
      if (selectedFaq.includes(faq.id)) {
        const selectedIndex = selectedFaq.indexOf(faq.id) + 1;
        const faqsArray = faq.reviews?.map((rev) => {
          return {
            brand: brand?.id,
            question: rev.question,
            source: rev.reviewUrl,
            type: "multiProduct",
            group: brand?.id + "MULTI" + selectedIndex,
            sku: uniqueSkus, // Use the uniqueSkus array as the value for sku
            thumbnail: faq.thumbnailurl ?? "",
            user: {
              name: faq?.user_info?.name,
              image: faq?.user_info?.image,
              city: "",
            },
          };
        });
        faqs.push(...faqsArray);
      }
    }
    try {
      let headersList = {
        Accept: "*/*",
        Authorization:
          "7o6pu8v-0Au8vx9yi1jCDECDEr7si1ji1ju8vl5mghiwx93ghstu1jkQPOPQRmnoPQRMNODEFl5mJKLBCD",
        "Content-Type": "application/json",
      };

      let bodyContent = faqs;

      let reqOptions = {
        url: "https://mainapi-zscu3untuq-as.a.run.app/insertFaqVidoes",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      const response = await axios.request(reqOptions);
      if (response.data.success) {
        showSuccess("Video Showcase has been successfully updated");
        router.replace("/admin/integration");
        window.open(
          "https://myrevue.gitbook.io/myrevue-elite/integrations/video-showcase",
          "_blank"
        );
        return;
      } else {
        showWarning("something went wrong while updating");
        return;
      }
    } catch (error) {
      console.log(error);
      showWarning("Something went wrong");
      return;
    }
  }
  async function submitProductFaq() {
    if (selectedFaq.length === 0) {
      return showWarning("Please select atleast one faq submission");
    }
    var faqs = [];
    var selectedSkus = new Set(); // Set to store unique selected SKUs
    for (const faq of faqVideos) {
      if (selectedFaq.includes(faq.id)) {
        if (faq.productsku) {
          selectedSkus.add(faq.productsku); // Add the SKU to the selectedSkus Set
        }
      }
    }
    var uniqueSkus = Array.from(selectedSkus);
    let count = 0;
    faqVideos.forEach((faq) => {
      if (selectedFaq.includes(faq.id)) {
        count += 1;
        const faqsArray = faq.reviews?.map((rev) => {
          return {
            brand: brand?.id,
            question: rev.question,
            source: rev.reviewUrl,
            type: "singleProduct",
            group: brand?.id + "xSUB" + count,
            sku: uniqueSkus,
            thumbnail: faq.thumbnailurl ?? "",
            user: {
              name: faq?.user_info?.name,
              image: faq?.user_info?.image,
              city: "",
            },
          };
        });
        faqs.push(...faqsArray);
      }
    });
    try {
      let headersList = {
        Accept: "*/*",
        Authorization:
          "7o6pu8v-0Au8vx9yi1jCDECDEr7si1ji1ju8vl5mghiwx93ghstu1jkQPOPQRmnoPQRMNODEFl5mJKLBCD",
        "Content-Type": "application/json",
      };

      let bodyContent = faqs;

      let reqOptions = {
        url: "https://mainapi-zscu3untuq-as.a.run.app/insertFaqVidoes",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };
      const response = await axios.request(reqOptions);
      if (response.data.success) {
        showSuccess("Product Faq has been successfully updated");
        router.replace("/admin/integration");
        window.open(
          "https://myrevue.gitbook.io/myrevue-elite/integrations/video-showcase",
          "_blank"
        );
        return;
      } else {
        showWarning("something went wrong while updating");
        return;
      }
    } catch (error) {
      console.log(error);
      showWarning("Something went wrong");
      return;
    }
  }

  async function connectInstagram() {
    window.open(
      "https://www.facebook.com/dialog/oauth?client_id=318975980599685&redirect_uri=http://localhost:3000/admin/integration?instagram&scope=name",
      "_blank"
    );
  }
  async function connectFacebook() {
    window.open(
      "https://www.facebook.com/dialog/oauth?client_id=318975980599685&redirect_uri=http://localhost:3000/admin/integration?instagram&scope=ads_managementbusiness_management,instagram_basic,instagram_content_publish,pages_read_engagement",
      "_blank"
    );
  }
  return (
    <div className="relative w-full min-h-screen p-10 overflow-y-auto bg-background">
      {step === "preview" && (
        <Modalcard close={closePreview}>
          <div className="w-[600px] bg-[#383636] rounded-lg p-5">
            {tab === "product-wall" && (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FProduct%20Wall.PNG?alt=media&token=2c30ae0f-9b80-41a9-a786-4cf3dac54120"
                alt=""
                className="rounded-sm"
              />
            )}
            {tab === "floating-video" && (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FFloating%20Video%20Guide.PNG?alt=media&token=e3cdd564-c960-4f69-aaaf-6871c25c0daf"
                alt=""
                className="rounded-sm"
              />
            )}
            {tab === "video-showcase" && (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMasters%20Faq.PNG?alt=media&token=5e236706-1e01-41f1-9a5c-9989fec758cf"
                alt=""
                className="rounded-sm"
              />
            )}
            {tab === "video-wall" && (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FVideo%20wall.PNG?alt=media&token=0b85ca3e-a773-4a76-989c-bb1ef8b7b97f"
                alt=""
                className="rounded-sm"
              />
            )}
            {tab === "product-faq" && (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FProduct%20Faq.PNG?alt=media&token=a7a7ce6f-def2-4d06-af99-cfafd10043c2"
                alt=""
                className="rounded-sm"
              />
            )}
          </div>
        </Modalcard>
      )}
      {previewFaq !== null && (
        <Modalcard close={closeFaqPreviewModal}>
          <iframe
            height={613}
            width={345}
            src={`/embed/faqplayer/${previewFaq?.campaignId}/${previewFaq?.id}`}
            className="rounded-xl"
            title="previewModal"
          ></iframe>
        </Modalcard>
      )}
      {(!step || step === "preview") && (
        <>
          <div className="max-w-[1280px] mx-auto">
            <div className="mb-5 text-2xl font-semibold tracking-wide">
              Integration
            </div>
            <div className="grid w-full grid-cols-3 gap-10">
              <div className="w-full p-5 rounded-lg bg-bg_highlight">
                <div className="flex items-center justify-between">
                  <div className="">
                    <p className="text-lg font-medium tracking-wide">
                      Product Wall
                    </p>
                  </div>
                  <div className="">
                    <button
                      onClick={() => {
                        clearAllSelections();
                        router.push("/admin/integration?step=product-wall");
                      }}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      Continue
                    </button>
                  </div>
                </div>
                <div className="my-5">
                  <p className="">
                    Empower users with an interactive and informative product
                    experience—reviews and flicks at their fingertips.
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() =>
                      router.push(
                        "/admin/integration?step=preview&tab=product-wall"
                      )
                    }
                    className="px-5 py-2 text-sm rounded-full bg-[#2E3036] hover:bg-[#222325]"
                  >
                    Preview
                  </button>
                  <a
                    href="https://myrevue.gitbook.io/myrevue-elite/integrations/product-wall"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="px-5 py-2 text-sm rounded-full bg-[#3e3e41] hover:bg-[#1D1D1F]">
                      Documentation
                    </button>
                  </a>
                </div>
              </div>
              <div className="w-full p-5 rounded-lg bg-bg_highlight">
                <div className="flex items-center justify-between">
                  <div className="">
                    <p className="text-lg font-medium tracking-wide">
                      Floating Video Guide
                    </p>
                  </div>
                  <div className="">
                    <button
                      onClick={() => {
                        clearAllSelections();
                        router.push("/admin/integration?step=floating-video");
                      }}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      Continue
                    </button>
                  </div>
                </div>
                <div className="my-5">
                  <p className="">
                    The CEO Guide Widget is a powerful tool that allows you to
                    provide an interactive and informative experience for your
                    website visitors
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() =>
                      router.push(
                        "/admin/integration?step=preview&tab=floating-video"
                      )
                    }
                    className="px-5 py-2 text-sm rounded-full bg-[#2E3036] hover:bg-[#222325]"
                  >
                    Preview
                  </button>
                  <a
                    href="https://myrevue.gitbook.io/myrevue-elite/integrations/ceo-video"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="px-5 py-2 text-sm rounded-full bg-[#3e3e41] hover:bg-[#1D1D1F]">
                      Documentation
                    </button>
                  </a>
                </div>
              </div>
              <div className="w-full p-5 rounded-lg bg-bg_highlight">
                <div className="flex items-center justify-between">
                  <div className="">
                    <p className="text-lg font-medium tracking-wide">
                      Video Showcase
                    </p>
                  </div>
                  <div className="">
                    <button
                      onClick={() => {
                        clearAllSelections();
                        router.push("/admin/integration?step=video-showcase");
                      }}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      Continue
                    </button>
                  </div>
                </div>
                <div className="my-5">
                  <p className="">
                    {" "}
                    The Video Showcase allows you to create a comprehensive FAQ
                    section that covers multiple products or services offered by
                    your brand.
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() =>
                      router.push(
                        "/admin/integration?step=preview&tab=video-showcase"
                      )
                    }
                    className="px-5 py-2 text-sm rounded-full bg-[#2E3036] hover:bg-[#222325]"
                  >
                    Preview
                  </button>
                  <a
                    href="https://myrevue.gitbook.io/myrevue-elite/integrations/video-showcase"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="px-5 py-2 text-sm rounded-full bg-[#3e3e41] hover:bg-[#1D1D1F]">
                      Documentation
                    </button>
                  </a>
                </div>
              </div>
              <div className="w-full p-5 rounded-lg bg-bg_highlight">
                <div className="flex items-center justify-between">
                  <div className="">
                    <p className="text-lg font-medium tracking-wide">
                      Product FAQ's
                    </p>
                  </div>
                  <div className="">
                    <button
                      onClick={() => {
                        clearAllSelections();
                        router.push("/admin/integration?step=product-faq");
                      }}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      Continue
                    </button>
                  </div>
                </div>
                <div className="my-5">
                  <p className="">
                    The Product FAQ allows you to create a comprehensive FAQ
                    section that covers single products or services offered by
                    your brand.{" "}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() =>
                      router.push(
                        "/admin/integration?step=preview&tab=product-faq"
                      )
                    }
                    className="px-5 py-2 text-sm rounded-full bg-[#2E3036] hover:bg-[#222325]"
                  >
                    Preview
                  </button>
                  <a
                    href="https://myrevue.gitbook.io/myrevue-elite/integrations/product-faq"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="px-5 py-2 text-sm rounded-full bg-[#3e3e41] hover:bg-[#1D1D1F]">
                      Documentation
                    </button>
                  </a>
                </div>
              </div>
              <div className="w-full p-5 rounded-lg bg-bg_highlight">
                <div className="flex items-center justify-between">
                  <div className="">
                    <p className="text-lg font-medium tracking-wide">
                      Video Wall
                    </p>
                  </div>
                  <div className="">
                    <button
                      onClick={() => {
                        clearAllSelections();
                        router.push("/admin/integration?step=video-wall");
                      }}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      Continue
                    </button>
                  </div>
                </div>
                <div className="my-5">
                  <p className="">
                    Showcase brand product video reviews on your homepage for an
                    immersive and compelling viewing experience, bringing your
                    products to life.
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() =>
                      router.push(
                        "/admin/integration?step=preview&tab=video-wall"
                      )
                    }
                    className="px-5 py-2 text-sm rounded-full bg-[#2E3036] hover:bg-[#222325]"
                  >
                    Preview
                  </button>
                  <a
                    href="https://myrevue.gitbook.io/myrevue-elite/integrations/video-wall"
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <button className="px-5 py-2 text-sm rounded-full bg-[#3e3e41] hover:bg-[#1D1D1F]">
                      Documentation
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step === "product-wall" && (
        <>
          <div className="mb-5">
            <button
              onClick={() => router.goBack()}
              className="flex items-center gap-3 px-3 py-2 rounded-md hover:bg-[#0d0d0d]"
            >
              <ArrowLeft size={22} color="white" />
              Back
            </button>
          </div>
          <div className="w-full max-w-[1350px] mx-auto space-y-10">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-5">
                <div className="h-11 w-80 relative bg-[#131418] rounded-md flex items-center px-3 group gap-3">
                  <MagnifyingGlass
                    size={22}
                    className="group-focus-within:text-white text-[#8E8B8B]"
                  />
                  <input
                    type="text"
                    onChange={(event) => setSearch_text(event.target.value)}
                    className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B] placeholder:text-sm"
                    placeholder="Search by product name"
                  />
                </div>
                <div className="relative">
                  {/* <button onClick={() => setShowFilters(!showFilters)} className='bg-[#131418] hover:bg-[#302d2d] rounded-md px-5 py-2 h-11 flex items-center gap-3'>
                                        <FunnelSimple size={22} color='white' />
                                        Filters
                                    </button> */}
                </div>
              </div>
              <div className="flex items-center gap-5">
                {step === "product-wall" && !tab && (
                  <button
                    onClick={() =>
                      selectedProducts.length < 3
                        ? showWarning("Please select atleast 3 products")
                        : router.push(
                          "/admin/integration?step=product-wall&tab=videos"
                        )
                    }
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Next
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
                {step === "product-wall" && tab === "videos" && (
                  <button
                    onClick={() => submitProductWall()}
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Submit
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
              </div>
            </div>
            {step === "product-wall" && !tab && (
              <div className="flex flex-wrap w-full gap-5">
                {products
                  ?.filter(
                    (val) =>
                      val?.name
                        .toLowerCase()
                        .includes(search_text.toLowerCase()) &&
                      val?.status === true
                  )
                  ?.map((product, index) => {
                    const productReviews = reviews.filter(
                      (review) =>
                        review?.product?.id === product?.id &&
                        review?.status === true
                    );
                    const productFlicks = flicks.filter(
                      (review) => review?.product?.id === product?.id
                    );
                    return (
                      <ProductCard
                        selectedProducts={selectedProducts}
                        product={product}
                        updateProductSelection={updateProductSelection}
                        productReviews={productReviews}
                        productFlicks={productFlicks}
                      />
                    );
                  })}
                {products?.filter(
                  (val) =>
                    val?.name
                      .toLowerCase()
                      .includes(search_text.toLowerCase()) &&
                    val?.status === true
                )?.length === 0 && <div className="">No product available</div>}
              </div>
            )}
            {step === "product-wall" && tab === "videos" && (
              <>
                <div className="grid grid-cols-2 gap-5">
                  {reviews
                    ?.filter(
                      (val) =>
                        selectedProducts.includes(val?.product?.id) &&
                        val?.status === true
                    )
                    ?.map((review, index) => (
                      <ReviewCard
                        key={index}
                        updateReviewSelection={updateReviewSelection}
                        review={review}
                        selectedReviews={selectedReviews}
                        isReview={true}
                      />
                    ))}
                  {flicks
                    ?.filter((val) =>
                      selectedProducts.includes(val?.product?.id)
                    )
                    ?.map((review, index) => (
                      <ReviewCard
                        key={index}
                        updateReviewSelection={updateReviewSelection}
                        review={review}
                        selectedReviews={selectedReviews}
                        isReview={false}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
          {showFilters && (
            <div className="absolute top-0 right-0 flex flex-col justify-between h-screen overflow-y-auto w-80 bg-bg_highlight">
              <div className="">
                <div className="h-14 w-full flex items-center justify-between pl-6 pr-[18px] border-b border-[#302e2e]">
                  <p className="">Filters</p>
                  <button
                    onClick={() => setShowFilters(false)}
                    className="flex items-center justify-center w-8 h-8 rounded-full bg-background"
                  >
                    <X size={18} color="white" />
                  </button>
                </div>
                <div className="">
                  <div className="px-6 mt-4">
                    <div
                      onClick={(event) => {
                        const nextElement =
                          event.currentTarget.nextElementSibling;
                        nextElement.style.display =
                          nextElement.style.display === "block"
                            ? "none"
                            : "block";
                      }}
                      className="flex items-center justify-between w-full py-2 cursor-pointer "
                    >
                      <p className="">Sorting</p>
                      <CaretDown size={18} color="white" />
                    </div>
                    <div className="border-b border-[#302e2e] hidden">
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="sortingNewToOld"
                          className="flex-1 text-white cursor-pointer"
                        >
                          {" "}
                          Sorting (New to Old)
                        </label>
                        <input
                          type="radio"
                          id="sortingNewToOld"
                          name="sorting"
                        />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="sortingOldToNew"
                          className="flex-1 text-white cursor-pointer"
                        >
                          {" "}
                          Sorting (Old to New)
                        </label>
                        <input
                          type="radio"
                          id="sortingOldToNew"
                          name="sorting"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-6">
                    <div
                      onClick={(event) => {
                        const nextElement =
                          event.currentTarget.nextElementSibling;
                        nextElement.style.display =
                          nextElement.style.display === "block"
                            ? "none"
                            : "block";
                      }}
                      className="flex items-center justify-between w-full py-2 cursor-pointer "
                    >
                      <p className="">Date Added</p>
                      <CaretDown size={18} color="white" />
                    </div>
                    <div className="border-b border-[#302e2e] hidden">
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="last7days"
                          className="flex-1 text-white cursor-pointer"
                        >
                          Last 7 days
                        </label>
                        <input type="radio" id="last7days" name="dateAdded" />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="last14days"
                          className="flex-1 text-white cursor-pointer"
                        >
                          Last 14 days
                        </label>
                        <input type="radio" id="last14days" name="dateAdded" />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="last30days"
                          className="flex-1 text-white cursor-pointer"
                        >
                          Last 30 days
                        </label>
                        <input type="radio" id="last30days" name="dateAdded" />
                      </div>
                    </div>
                  </div>
                  <div className="px-6">
                    <div
                      onClick={(event) => {
                        const nextElement =
                          event.currentTarget.nextElementSibling;
                        nextElement.style.display =
                          nextElement.style.display === "block"
                            ? "none"
                            : "block";
                      }}
                      className="flex items-center justify-between w-full py-2 cursor-pointer "
                    >
                      <p className="">Rating</p>
                      <CaretDown size={18} color="white" />
                    </div>
                    <div className="border-b border-[#302e2e] hidden">
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="one"
                          className="flex-1 text-white cursor-pointer"
                        >
                          1
                        </label>
                        <input type="radio" id="one" name="rating" />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="two"
                          className="flex-1 text-white cursor-pointer"
                        >
                          2
                        </label>
                        <input type="radio" id="two" name="rating" />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="three"
                          className="flex-1 text-white cursor-pointer"
                        >
                          3
                        </label>
                        <input type="radio" id="three" name="rating" />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="four"
                          className="flex-1 text-white cursor-pointer"
                        >
                          4
                        </label>
                        <input type="radio" id="four" name="rating" />
                      </div>
                      <div className="flex items-center justify-between w-full py-2">
                        <label
                          htmlFor="five"
                          className="flex-1 text-white cursor-pointer"
                        >
                          5
                        </label>
                        <input type="radio" id="five" name="rating" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <button
                  onClick={() => clearFilter()}
                  className="flex items-center gap-3 px-3 py-2 w-full justify-center rounded-md bg-[#0d0d0d] hover:bg-[#2b2929]"
                >
                  Clear All
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {step === "floating-video" && (
        <>
          <div className="mb-5">
            <button
              onClick={() => router.goBack()}
              className="flex items-center gap-3 px-3 py-2 rounded-md hover:bg-[#0d0d0d]"
            >
              <ArrowLeft size={22} color="white" />
              Back
            </button>
          </div>
          <div className="w-full max-w-[1350px] mx-auto space-y-10">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-5">
                <div className="h-11 w-80 relative bg-[#131418] rounded-md flex items-center px-3 group gap-3">
                  <MagnifyingGlass
                    size={22}
                    className="group-focus-within:text-white text-[#8E8B8B]"
                  />
                  <input
                    type="text"
                    onChange={(event) => setSearch_text(event.target.value)}
                    className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B] placeholder:text-sm"
                    placeholder="Search by campaign name"
                  />
                </div>
              </div>
              <div className="flex items-center gap-5">
                {step === "floating-video" && (
                  <button
                    onClick={() => submitFloatingVideo()}
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Submit
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              {faqVideos?.filter(
                (val) =>
                  val?.prodid === null &&
                  val?.campaign_name
                    ?.toLowerCase()
                    ?.includes(search_text.toLowerCase())
              )?.length === 0 && (
                  <p className="">No Faq submissions available</p>
                )}
              {faqVideos
                ?.filter(
                  (val) =>
                    val?.prodid === null &&
                    val?.campaign_name
                      ?.toLowerCase()
                      ?.includes(search_text.toLowerCase())
                )
                ?.map((faq, index) => (
                  <div
                    key={index}
                    className={`w-full p-3 bg-bg_highlight rounded-md flex gap-3 cursor-pointer border-2 ${selectedFloatingFaq?.id === faq.id
                        ? " border-[#8EA9FA]"
                        : "border-transparent hover:border-[#435488]"
                      }`}
                  >
                    <div
                      onClick={() => setPreviewFaq(faq)}
                      className="w-52 aspect-[9/16] rounded-md bg-black"
                    >
                      {faq?.thumbnailurl ? (
                        <img
                          src={faq?.thumbnailurl}
                          alt="FaqSubmissions"
                          className={"w-full h-full rounded-lg object-cover"}
                        />
                      ) : (
                        <video playsInline
                          src={faq?.reviews[0]?.reviewUrl}
                          alt="nopreview_available"
                          className={"w-full h-full rounded-lg object-cover"}
                        ></video>
                      )}
                    </div>
                    <div
                      className="flex-1 w-full "
                      onClick={() =>
                        selectedFloatingFaq?.id === faq.id
                          ? setSelectedFloatingFaq(null)
                          : setSelectedFloatingFaq(faq)
                      }
                    >
                      <div className="mb-3 text-white">
                        <p className="">Faq Campaign Name</p>
                        <input
                          type="text"
                          defaultValue={faq?.campaign_name}
                          readOnly
                          className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      {step === "video-showcase" && (
        <>
          <div className="mb-5">
            <button
              onClick={() => router.goBack()}
              className="flex items-center gap-3 px-3 py-2 rounded-md hover:bg-[#0d0d0d]"
            >
              <ArrowLeft size={22} color="white" />
              Back
            </button>
          </div>
          {previewFaq !== null && (
            <Modalcard close={closeFaqPreviewModal}>
              <iframe
                height={613}
                width={345}
                src={`/embed/faqplayer/${previewFaq?.campaignId}/${previewFaq?.id}`}
                className="rounded-xl"
                title="previewModal"
              ></iframe>
            </Modalcard>
          )}
          <div className="w-full max-w-[1350px] mx-auto space-y-10">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-5">
                <div className="h-11 w-80 relative bg-[#131418] rounded-md flex items-center px-3 group gap-3">
                  <MagnifyingGlass
                    size={22}
                    className="group-focus-within:text-white text-[#8E8B8B]"
                  />
                  <input
                    type="text"
                    onChange={(event) => setSearch_text(event.target.value)}
                    className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B] placeholder:text-sm"
                    placeholder="Search by campaign or product name"
                  />
                </div>
              </div>
              <div className="flex items-center gap-5">
                {step === "video-showcase" && (
                  <button
                    onClick={() => submitVideoShowcase()}
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Submit
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              {faqVideos?.filter(
                (val) =>
                  val?.prodid !== null &&
                  (val?.campaign_name
                    ?.toLowerCase()
                    ?.includes(search_text.toLowerCase()) ||
                    val?.productname
                      ?.toLowerCase()
                      ?.includes(search_text.toLowerCase()))
              )?.length === 0 && (
                  <p className="">No Faq submissions available</p>
                )}
              {faqVideos
                ?.filter(
                  (val) =>
                    val?.prodid !== null &&
                    (val?.campaign_name
                      ?.toLowerCase()
                      ?.includes(search_text.toLowerCase()) ||
                      val?.productname
                        ?.toLowerCase()
                        ?.includes(search_text.toLowerCase()))
                )
                ?.map((faq, index) => (
                  <div
                    key={index}
                    className={`w-full p-3 bg-bg_highlight rounded-md flex gap-3 cursor-pointer border-2 ${selectedFaq.includes(faq?.id)
                        ? " border-[#8EA9FA]"
                        : "border-transparent hover:border-[#435488]"
                      }`}
                  >
                    <div
                      onClick={() => setPreviewFaq(faq)}
                      className="w-52 aspect-[9/16] rounded-md bg-black"
                    >
                      {faq?.thumbnailurl ? (
                        <img
                          src={faq?.thumbnailurl}
                          alt="FaqSubmissions"
                          className={"w-full h-full rounded-lg object-cover"}
                        />
                      ) : (
                        <video playsInline
                          src={faq?.reviews[0]?.reviewUrl}
                          alt="nopreview_available"
                          className={"w-full h-full rounded-lg object-cover"}
                        ></video>
                      )}
                    </div>
                    <div
                      className="flex-1 w-full "
                      onClick={() => updateFaqSelection(faq)}
                    >
                      <div className="mb-3 text-white">
                        <p className="">Faq Campaign Name</p>
                        <input
                          type="text"
                          defaultValue={faq?.campaign_name}
                          readOnly
                          className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background"
                        />
                      </div>
                      <div className="mb-3 text-white">
                        <p className="">Product Name</p>
                        <input
                          type="text"
                          defaultValue={faq?.productname}
                          readOnly
                          className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      {step === "video-wall" && (
        <>
          <div className="mb-5">
            <button
              onClick={() => router.goBack()}
              className="flex items-center gap-3 px-3 py-2 rounded-md hover:bg-[#0d0d0d]"
            >
              <ArrowLeft size={22} color="white" />
              Back
            </button>
          </div>
          <div className="w-full max-w-[1350px] mx-auto space-y-10">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-5">
                <div className="h-11 w-80 relative bg-[#131418] rounded-md flex items-center px-3 group gap-3">
                  <MagnifyingGlass
                    size={22}
                    className="group-focus-within:text-white text-[#8E8B8B]"
                  />
                  <input
                    type="text"
                    onChange={(event) => setSearch_text(event.target.value)}
                    className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B] placeholder:text-sm"
                    placeholder="Search by title or product name"
                  />
                </div>
                <div className="relative">
                  {/* <button onClick={() => setShowFilters(!showFilters)} className='bg-[#131418] hover:bg-[#302d2d] rounded-md px-5 py-2 h-11 flex items-center gap-3'>
                                        <FunnelSimple size={22} color='white' />
                                        Filters
                                    </button> */}
                </div>
              </div>
              <div className="flex items-center gap-5">
                {step === "video-wall" && (
                  <button
                    onClick={() => submitVideoWall()}
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Submit
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              {flicks?.map((review, index) => (
                <ReviewCard
                  key={index}
                  updateReviewSelection={updateReviewSelection}
                  review={review}
                  selectedReviews={selectedReviews}
                  isReview={false}
                />
              ))}
              {reviews
                ?.filter((val) => val?.status === true)
                ?.map((review, index) => (
                  <ReviewCard
                    key={index}
                    updateReviewSelection={updateReviewSelection}
                    review={review}
                    selectedReviews={selectedReviews}
                    isReview={true}
                  />
                ))}
            </div>
          </div>
        </>
      )}
      {step === "product-faq" && (
        <>
          <div className="mb-5">
            <button
              onClick={() => router.goBack()}
              className="flex items-center gap-3 px-3 py-2 rounded-md hover:bg-[#0d0d0d]"
            >
              <ArrowLeft size={22} color="white" />
              Back
            </button>
          </div>
          <div className="w-full max-w-[1350px] mx-auto space-y-10">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-5">
                <div className="h-11 w-80 relative bg-[#131418] rounded-md flex items-center px-3 group gap-3">
                  <MagnifyingGlass
                    size={22}
                    className="group-focus-within:text-white text-[#8E8B8B]"
                  />
                  <input
                    type="text"
                    onChange={(event) => setSearch_text(event.target.value)}
                    className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B] placeholder:text-sm"
                    placeholder={
                      step === "product-faq" && tab === "videos"
                        ? "Search by campaign name"
                        : "Search by product name"
                    }
                  />
                </div>
                <div className="relative">
                  {/* <button onClick={() => setShowFilters(!showFilters)} className='bg-[#131418] hover:bg-[#302d2d] rounded-md px-5 py-2 h-11 flex items-center gap-3'>
                                        <FunnelSimple size={22} color='white' />
                                        Filters
                                    </button> */}
                </div>
              </div>
              <div className="flex items-center gap-5">
                {step === "product-faq" && !tab && (
                  <button
                    onClick={() =>
                      selectedProducts.length < 1
                        ? showWarning("Please select a product")
                        : router.push(
                          "/admin/integration?step=product-faq&tab=videos"
                        )
                    }
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Next
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
                {step === "product-faq" && tab === "videos" && (
                  <button
                    onClick={() => submitProductFaq()}
                    className="flex items-center gap-3 px-6 py-2 h-11 rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                  >
                    Submit
                    <ArrowRight size={22} color="white" />
                  </button>
                )}
              </div>
            </div>
            {step === "product-faq" && !tab && (
              <div className="flex flex-wrap w-full gap-5">
                {products
                  ?.filter(
                    (val) =>
                      val?.name
                        .toLowerCase()
                        .includes(search_text.toLowerCase()) &&
                      val?.status === true
                  )
                  ?.map((product, index) => {
                    const productFaqSubmissions =
                      faqVideos.filter((faq) => faq?.prodid === product?.id)
                        .length || 0;
                    return (
                      <ProductCard
                        selectedProducts={selectedProducts}
                        product={product}
                        updateProductSelection={updateSingleProductSelection}
                        isFaq={true}
                        faqSubmission={productFaqSubmissions}
                        productReviews={0}
                        productFlicks={0}
                      />
                    );
                  })}
                {products?.filter(
                  (val) =>
                    val?.name
                      .toLowerCase()
                      .includes(search_text.toLowerCase()) &&
                    val?.status === true
                )?.length === 0 && <div className="">No product available</div>}
              </div>
            )}
            {step === "product-faq" && tab === "videos" && (
              <>
                <div className="grid grid-cols-2 gap-5">
                  {faqVideos?.filter(
                    (val) =>
                      val?.prodid !== null &&
                      val?.prodid === selectedProducts[0] &&
                      (val?.campaign_name
                        ?.toLowerCase()
                        ?.includes(search_text.toLowerCase()) ||
                        val?.productname
                          ?.toLowerCase()
                          ?.includes(search_text.toLowerCase()))
                  )?.length === 0 && (
                      <p className="">No Faq submissions available</p>
                    )}
                  {faqVideos
                    ?.filter(
                      (val) =>
                        val?.prodid !== null &&
                        val?.prodid === selectedProducts[0] &&
                        (val?.campaign_name
                          ?.toLowerCase()
                          ?.includes(search_text.toLowerCase()) ||
                          val?.productname
                            ?.toLowerCase()
                            ?.includes(search_text.toLowerCase()))
                    )
                    ?.map((faq, index) => (
                      <div
                        key={index}
                        className={`w-full p-3 bg-bg_highlight rounded-md flex gap-3 cursor-pointer border-2 ${selectedFaq.includes(faq?.id)
                            ? " border-[#8EA9FA]"
                            : "border-transparent hover:border-[#435488]"
                          }`}
                      >
                        <div
                          onClick={() => setPreviewFaq(faq)}
                          className="w-52 aspect-[9/16] rounded-md bg-black"
                        >
                          {faq?.thumbnailurl ? (
                            <img
                              src={faq?.thumbnailurl}
                              alt="FaqSubmissions"
                              className={
                                "w-full h-full rounded-lg object-cover"
                              }
                            />
                          ) : (
                            <video playsInline
                              src={faq?.reviews[0]?.reviewUrl}
                              alt="nopreview_available"
                              className={
                                "w-full h-full rounded-lg object-cover"
                              }
                            ></video>
                          )}
                        </div>
                        <div
                          className="flex-1 w-full "
                          onClick={() => updateFaqSelection(faq)}
                        >
                          <div className="mb-3 text-white">
                            <p className="">Faq Campaign Name</p>
                            <input
                              type="text"
                              defaultValue={faq?.campaign_name}
                              readOnly
                              className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background"
                            />
                          </div>
                          <div className="mb-3 text-white">
                            <p className="">Product Name</p>
                            <input
                              type="text"
                              defaultValue={faq?.productname}
                              readOnly
                              className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
