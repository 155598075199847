import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ProjectCard({ data: project }) {
  const router = useHistory();
  return (
    <div
      onClick={(e) => {
        router.push("/admin/generate-ai-video/" + project.id);
      }}
      className=" cursor-pointer flex-grow w-full h-52 bg-[#131418] rounded-md p-3 shadow-sm shadow-gray-600 flex flex-col justify-between"
    >
      <div className="">
        <p className="text-xs text-[#ffffff91] tracking-wide font-light first-letter:uppercase"> {project.name}</p>
        <p className="first-letter:uppercase">{project.query}</p>
      </div>
      <div className="flex items-end justify-between">
        <p className="text-xs">{new Date(project.date).toLocaleDateString()}</p>
        {project.draft && (
          <button className="py-1 rounded px-3 bg-[#B0B0B0]">Draft</button>
        )}
        {project.published && (
          <button className="py-1 rounded px-3 bg-[#63D876]">Published</button>
        )}
      </div>
    </div>
  );
}

export default ProjectCard;
