import { Eye, Hexagon, Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import moment from "moment";
export default function Campaigns() {
    const router = useHistory();
    const [camapaigns, setCamapaigns] = useState([]);
    const [{ brand, user }, dispatch] = useStateValue();

    useEffect(() => {
        async function getCampaigns() {
            let headersList = {
                Accept: "*/*",
            };

            let reqOptions = {
                url: "https://massemailer-zscu3untuq-as.a.run.app/campaigns/" + brand?.id,
                method: "GET",
                headers: headersList,
            };
            let response = await axios.request(reqOptions);
            if (!response.data.success) {
                return;
            }
            setCamapaigns(response.data.data);
        }
        getCampaigns();
    }, []);
    return (
        <div className="mt-10">
            <div className="flex items-center justify-between px-5">
                <p className="text-3xl font-medium tracking-wide text-white">Campaign</p>
                <button
                    onClick={() => router.push("/admin/emails/create")}
                    className="bg-[#93A8F4] h-12 rounded-lg flex items-center gap-3 px-5 appearance-none outline-none">
                    <Plus size={22} color="#f8f8f8" />
                    Create New Campaign
                </button>
            </div>
            <div className="relative block px-5 mt-10 mb-10">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="h-14 bg-[#131418]">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Recipient name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Send time
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Delivered
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Open rate
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Click rate
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {camapaigns?.map((campaign, index) => (
                                    <tr className="table-row align-[inherit]  bg-[#131418] border-t border-opacity-10 border-white" key={index}>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            {index + 1}
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            {campaign?.campaignName}
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            All recipients
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            {campaign?.sendTime ? moment(campaign?.sendTime).format('MMM DD, YYYY h:mm A') : "-"}
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            {campaign?.delivered ?? 0}
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            {campaign?.openRate ?? "0.00%"}
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            {campaign?.clickRate ?? "0.00%"}
                                        </td>
                                        <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell">
                                            <button onClick={() => router.push("/admin/emails/viewemailcampaign/" + campaign?._id)} className="h-10 w-10 flex items-center justify-center relative text-[#3c3c3d] hover:text-white">
                                                <Hexagon size={40} weight="light" color='currentcolor' />
                                                <Eye
                                                    size={20}
                                                    className="absolute"
                                                    weight="bold"
                                                    color='currentcolor'
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {camapaigns.length === 0 && (
                            <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                No Campaigns Available
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
