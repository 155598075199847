import React, { useState } from "react";
import SelectProductList from "./SelectProductList";
import SelectVideoLanguage from "./SelectVideoLanguage";
import SelectVideoCategory from "./SelectVideoCategory";
import { aiServerUrl, getOpenAIContent } from "./aiapis";
import { useStateValue } from "../context/StateProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { publishFlick } from "../common/functions";
import AWN from "awesome-notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function PublishVideoContent({ videoUrl, setUpward }) {
  const [{ user }] = useStateValue();
  const [step, setStep] = useState(1);
  const { id } = useParams();
  // 1: default - no action
  // 2. publishing video
  // 3.
  const [flick, setFlick] = useState({
    brand: user.brandId,
    appUserId: user.appUserId,
  });

  const setProduct = (id) => {
    setFlick({ ...flick, prodId: id });
  };
  const setLanguage = (lang) => {
    setFlick({ ...flick, language: lang });
  };
  const setVideoCat = (flickCat) => {
    setFlick({ ...flick, ...flickCat });
  };

  const publishVideo = async () => {
    try {
      if (step === 2) return;
      setStep(2);

      if (!flick.description || flick.description?.length < 20) {
        return new AWN().warning("please input descripion atlest 20 letters");
      }
      if (!flick.language) {
        return new AWN().warning("please select language");
      }
      if (!flick.flickTag) {
        return new AWN().warning("please select category");
      }
      if (!flick.subFlicksTag) {
        return new AWN().warning("please select sub-category");
      }
      if (!flick.prodId) {
        return new AWN().warning(
          "please select product, if not product please create one."
        );
      }

      const flicksData = {
        ...flick,
        thumbnail: aiServerUrl + "thumbnail/" + id,
        reviewUrl: videoUrl,
      };
      var success = await publishFlick(flicksData);
      if (success) {
        updateDoc(doc(db, "aivideosProjects/" + id), {
          published: true,
          draft: false,
        }).then((res) => {
          new AWN().success("Video Published Success");
          setUpward({ published: true, draft: false });
        });
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setStep(1);
      }, 2000);
    }
  };

  return (
    <div className="grid grid-cols-2">
      <div className="flex items-start justify-center">
        <video playsInline
          controls
          autoPlay
          className="rounded-lg shadow-lg shadow-black aspect-[9/16]"
          style={{ height: "600px" }}
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          src={videoUrl}
        ></video>
      </div>
      <div className="flex flex-col gap-2 ">
        <div className="">
          <p className="">Caption</p>
          <div className="relative">
            <i
              onClick={async (e) => {
                const icon = e.currentTarget;
                if (icon) {
                  icon.classList.remove("fa-lightbulb");
                  icon.classList.add("fa-spinner");
                  icon.classList.add("animate-spin");

                  try {
                    const response = await getOpenAIContent(flick.description);
                    setFlick({
                      ...flick,
                      description: response,
                    });
                  } catch (error) {
                    console.error(error);
                  } finally {
                    if (icon) {
                      icon.classList.remove("fa-spinner");
                      icon.classList.remove("animate-spin");
                      icon.classList.add("fa-lightbulb");
                    }
                  }
                }
              }}
              className="absolute hidden p-2 text-yellow-400 duration-150 transform rounded-md cursor-pointer animate-spin hover:bg-gray-600 fa-solid top-4 right-2 fa-lightbulb"
            ></i>
            <textarea
              type="text"
              value={flick?.description}
              onChange={(event) => {
                const icon = event.target.parentElement.querySelector("i");
                setFlick({ ...flick, description: event.target.value });
                if (icon) {
                  icon.style.display =
                    event.target.value.length > 25 ? "block" : "none";
                }
              }}
              placeholder="Enter product description"
              className="w-full h-40 p-3 mt-2 text-sm font-normal tracking-wide break-words whitespace-pre-wrap rounded-md outline-none bg-background"
            />
          </div>
        </div>
        <SelectProductList setUpward={setProduct} />
        <SelectVideoLanguage setUpward={setLanguage} />
        <SelectVideoCategory setUpward={setVideoCat} />
        <div className="py-4">
          <button
            onClick={publishVideo}
            className="bg-[#93A8F4] hover:bg-[#4611ea] flex items-center justify-center transform duration-150 py-2 px-8 rounded"
          >
            {step === 2 && (
              <i className="mx-2 fas fa fa-spinner animate-spin"></i>
            )}
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PublishVideoContent;
