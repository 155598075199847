import { ArrowLeft, CaretDoubleLeft } from "phosphor-react";
import React, { useEffect } from "react";
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";
import { useStateValue } from "../../context/StateProvider";
import "../../styles/social.css";
import {
  Calendar,
  CaretLeft,
  FacebookLogo,
  House,
  Info,
  InstagramLogo,
  Package,
  ShareNetwork,
  TrendUp,
} from "@phosphor-icons/react";
import BrandInformation from "./brandInformation";
import FourZeroFour from "../404";
import SocialChannels from "./channels";
import Scheduler from "./scheduler";
import Posts from "./posts";
import Createpost from "./createpost";
import Dashboard from "./dashboard";
import ViewPost from "./viewPost";
import Facebook from "./analytics/facebook";
import Instagram from "./analytics/instagram";
import Youtube from "./analytics/youtube";
import Linkedin from "./analytics/linkedin";
import { Link } from "react-router-dom";
export default function SocialLayout() {
  const location = useLocation();
  const router = useHistory();
  const { path } = useRouteMatch();
  const [{ brand, user }, dispatch] = useStateValue();
  const nav_active =
    "font-light flex items-center gap-x-3 text-sm h-10 my-2 px-5 text-white cursor-pointer bg-gradient-to-r from-one via-[#91A8ED50] to-transparent";
  const nav_passive =
    "flex items-center gap-x-3 text-sm h-10 my-2 px-5 text-[#55565a] cursor-pointer hover:bg-gradient-to-r from-one via-[#91A8ED50] to-transparent hover:text-white";

  if (!brand || !user) {
    return router.replace("/login");
  }
  return (
    <div className="flex h-screen w-full bg-[#2D3037] ">
      <div className="h-full thin-scrollbar bg-bg_highlight w-[260px] flex flex-col justify-between overflow-y-auto">
        <div className="">
          {/* <div className="flex items-center justify-center w-full h-16 gap-3">
            <div
              className="flex items-center gap-1 text-[#8b8787] hover:text-[#FF005C] cursor-pointer"
              onClick={() => router.push("/admin/home")}
            >
              <CaretDoubleLeft size={25} color="currentcolor" />
              <p className="font-medium tracking-wide text-currentcolor">
                MyRevue Elite
              </p>
            </div>
            <div className="flex items-center gap-1">
              <svg viewBox="0 0 8 15" className="h-4">
                <path transform="translate(-201 -400) translate(54 383) translate(24 14)" fill="currentColor" stroke="none" strokeWidth="1" fillRule="evenodd" d="M123 18L129.137566 3 131 3 124.904762 18z" ></path>
              </svg>
              <p className="font-medium tracking-wide">Social</p>
            </div>
          </div> */}
          <div className="flex justify-center mt-5">
            <img
              crossOrigin="anonymous"
              src={require("../../assets/logo/MyRevue Logo elite new.svg").default}
              alt=""
              className="h-10 transform scale-150"
            />
          </div>
          <div className="px-5 my-3">
            <Link to="/admin">
              <div className="flex items-center w-full gap-3 px-3 py-2 rounded-md cursor-pointer bg-background hover:bg-background/70">
                <ArrowLeft size={26} />
                <span className="">Home</span>
              </div>
            </Link>
          </div>
          <div className="">
            <div className="">
              <div className="flex items-center gap-1 mx-4 mt-5 select-none">
                <p className="text-sm uppercase text-[#808080]">Content</p>
                <span className="w-full bg-[#808080] h-[1px] flex-1"></span>
              </div>
              <div className="">
                <div
                  onClick={() => router.push("/social/home")}
                  className={
                    location.pathname === "/social/home" ||
                      location.pathname === "/social"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <House size={26} />
                  <p className="">Dashboard</p>
                </div>
                {/* <div
                  onClick={() => router.push("/social/calender")}
                  className={
                    location.pathname === "/social/calender"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <Calendar size={26} />
                  <p className="">Calender</p>
                </div> */}
                <div
                  onClick={() => router.push("/social/posts")}
                  className={
                    location.pathname === "/social/posts"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <Package size={26} />
                  <p className="">Posts</p>
                </div>
              </div>
            </div>
            {(user.social?.facebook_pages || user.social?.instagram) && <div className="">
              <div className="flex items-center gap-1 mx-4 mt-5 select-none">
                <p className="text-sm uppercase text-[#808080]">Analytics</p>
                <span className="w-full bg-[#808080] h-[1px] flex-1"></span>
              </div>
              <div className="">
                {user.social?.facebook_pages?.map((page) => (
                  <div
                    key={page.id}
                    onClick={() =>
                      router.push("/social/analytics/facebook/" + page.id)
                    }
                    className={
                      location.pathname ===
                        "/social/analytics/facebook/" + page.id
                        ? nav_active
                        : nav_passive
                    }
                  >
                    <FacebookLogo size={26} />
                    <p className="">{page.name}</p>
                  </div>
                ))}
                {user.social?.instagram && (
                  <div
                    onClick={() =>
                      router.push(
                        "/social/analytics/instagram/" +
                        user.social?.instagram.id
                      )
                    }
                    className={
                      location.pathname ===
                        "/social/analytics/instagram/" + user.social?.instagram.id
                        ? nav_active
                        : nav_passive
                    }
                  >
                    <InstagramLogo size={26} />
                    <p className="">{user.social?.instagram?.name}</p>
                  </div>
                )}
                {/* <div
                  onClick={() => router.push("/social/analytics/youtube")}
                  className={
                    location.pathname === "/social/analytics/youtube"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <InstagramLogo size={26} />
                  <p className="">Youtube</p>
                </div> */}
                {/* <div
                  onClick={() => router.push("/social/analytics/linkedin")}
                  className={
                    location.pathname === "/social/analytics/linkedin"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <LinkedinLogo size={26} />
                  <p className="">Linkedin</p>
                </div> */}
              </div>
            </div>}
            <div className="">
              <div className="flex items-center gap-1 mx-4 mt-5 select-none">
                <p className="text-sm uppercase text-[#808080]">
                  Brand Settings
                </p>
                <span className="w-full bg-[#808080] h-[1px] flex-1"></span>
              </div>
              <div className="">
                {/* <div
                  onClick={() => router.push("/social/brand")}
                  className={
                    location.pathname === "/social/brand"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <Info size={26} />
                  <p className="">Brand Information</p>
                </div> */}
                <div
                  onClick={() => router.push("/social/channel")}
                  className={
                    location.pathname === "/social/channel"
                      ? nav_active
                      : nav_passive
                  }
                >
                  <ShareNetwork size={26} />
                  <p className="">Social Channels</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 w-full h-screen">
        <Switch>
          <Route path={`${path}`} exact component={Dashboard} />
          <Route path={`${path}/home`} exact component={Dashboard} />
          <Route path={`${path}/brand`} exact component={BrandInformation} />
          <Route path={`${path}/channel/*`} exact component={SocialChannels} />
          <Route path={`${path}/channel`} exact component={SocialChannels} />
          <Route path={`${path}/calender`} exact component={Scheduler} />
          <Route path={`${path}/posts`} exact component={Posts} />
          <Route path={`${path}/posts/create`} exact component={Createpost} />
          <Route
            path={`${path}/posts/view-post/:id`}
            exact
            component={ViewPost}
          />
          <Route
            path={`${path}/analytics/facebook/:pageid`}
            exact
            component={Facebook}
          />
          <Route
            path={`${path}/analytics/instagram/:id`}
            exact
            component={Instagram}
          />
          <Route path={`${path}/analytics/youtube`} exact component={Youtube} />
          <Route
            path={`${path}/analytics/linkedin`}
            exact
            component={Linkedin}
          />
          <Route path={`${path}/*`} exact component={FourZeroFour} />
        </Switch>
      </div>
    </div>
  );
}
