import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft } from "@phosphor-icons/react";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { useLocation, useHistory, useParams } from "react-router-dom";

export default function ViewEmaIlCampaign() {
    const [campaign, setCampaign] = useState({});
    const [campaignReports, setCampaignReports] = useState({});
    const [campaignTemplate, setCampaignTemplate] = useState(null);
    const iframeRef = useRef(null);
    const router = useHistory();
    const { id } = useParams();
    const [{ brand, user }, dispatch] = useStateValue();
    const activetab = useLocation().search;
    const currentTab = new URLSearchParams(activetab);

    useEffect(() => {
        async function fetchData() {
            let headersList = {
                Accept: "*/*",
            };

            let reqOptions = {
                url: "https://massemailer-zscu3untuq-as.a.run.app/campaigns/view/" + brand?.id + "/" + id,
                method: "GET",
                headers: headersList,
            };
            let response = await axios.request(reqOptions);
            if (!response.data.success) {
                return;
            }
            setCampaign(response.data.data);
            setCampaignTemplate(response.data.template);
            if (response.data.reports !== null) {
                return setCampaignReports(response.data.reports);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const resizeIframe = () => {
            if (iframeRef.current) {
                const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                const contentHeight = iframeDocument.documentElement.scrollHeight || iframeDocument.body.scrollHeight;
                iframeRef.current.style.height = `${contentHeight}px`;
            }
        };

        // Attach the resizeIframe function to the window resize event
        window.addEventListener('resize', resizeIframe);

        // Initial resize when the component mounts
        resizeIframe();

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', resizeIframe);
        };
    }, [campaignTemplate]);
    return (
        <div className="w-full pt-5 relative  min-h-screen bg-[#1B1F24] overflow-y-auto">
            <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-2xl tracking-wide font-medium">
                <div className="flex items-center justify-center w-12 h-12 cursor-pointer" onClick={() => router.goBack()}>
                    <ArrowLeft size={36} color="#ffffff" />
                </div>
                <p className="pl-5 first-letter:uppercase ">{campaign.campaignName}</p>
            </div>
            <div className="mt-5">
                <div className="grid w-full grid-cols-2">
                    <button
                        onClick={() => router.replace(`?tab=details`)}
                        className={`pb-3 border-b ${currentTab.get("tab") === null || currentTab.get("tab")?.includes("details") ? "border-[#808080] font-medium" : "border-transparent"
                            } text-center tracking-wide text-lg appearance-none outline-none`}>
                        Campaign details
                    </button>
                    <button
                        onClick={() => router.replace(`?tab=reports`)}
                        className={`pb-3 border-b ${currentTab.get("tab")?.includes("reports") ? "border-[#808080] font-medium" : "border-transparent"
                            } text-center tracking-wide text-lg appearance-none outline-none`}>
                        Campaign Reports
                    </button>
                </div>
            </div>
            {(currentTab.get("tab") === null || currentTab.get("tab")?.includes("details")) && (
                <div className="relative p-10">
                    <div className="border-b border-[#5C5C5D] mt-8 pb-2 w-full">
                        <div className="relative w-full ">
                            <input
                                className="text-white tracking-wide w-full flex-1 bg-transparent appearance-none outline-none placeholder:text-[#808080]"
                                readOnly
                                defaultValue={campaign?.campaignName}
                            />
                        </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                        <p className="font-medium text-[#747474] tracking-wide w-full">Audience</p>
                        <div className="relative w-full col-span-2">
                            <input
                                className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                                readOnly
                                type="text"
                                defaultValue={campaign?.audidienceCount}
                            />
                        </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                        <p className="font-medium text-[#747474] tracking-wide w-full">Subject</p>
                        <div className="relative w-full col-span-2">
                            <input
                                className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                                type="text"
                                readOnly
                                defaultValue={campaign?.subject}
                            />
                        </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                        <p className="font-medium text-[#747474] tracking-wide w-full">Sender name</p>
                        <div className="relative w-full col-span-2">
                            <input
                                className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                                type="text"
                                readOnly
                                defaultValue={campaign?.senderName}
                            />
                        </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                        <p className="font-medium text-[#747474] tracking-wide w-full">Reply to email address	</p>
                        <div className="relative w-full col-span-2">
                            <input
                                className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                                type="text"
                                readOnly
                                defaultValue={campaign?.replyToEmailAddress}
                            />
                        </div>
                    </div>
                    <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                        <p className="font-medium text-[#747474] tracking-wide w-full">Created by</p>
                        <div className="relative w-full col-span-2">
                            <input
                                className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none"
                                type="text"
                                readOnly
                                defaultValue={campaign?.email}
                            />
                        </div>
                    </div>
                    <div className="mt-10">
                        <p className="w-full font-medium tracking-wide">Content</p>
                        <div className="rounded-md border border-[#d1d5dd]">
                            <iframe ref={iframeRef} title="View campaign" sandbox="allow-same-origin" referrerPolicy="no-referrer" className="w-full min-h-[50vh]" srcDoc={campaignTemplate?.html}></iframe>
                        </div>
                    </div>
                </div>
            )}
            {currentTab.get("tab")?.includes("reports") && (
                <div className="grid grid-cols-4 gap-10 m-10">
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-blue-500">{campaignReports?.data?.sent ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Total Sent</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-green-500">{campaignReports?.data?.delivered ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Delivered</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-red-400">{campaignReports?.data?.bounced ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Bounced</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-green-500">{campaignReports?.data?.opened ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Opened</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-green-500">{campaignReports?.data?.clicked ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Clicked</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-yellow-500">{campaignReports?.data?.openRate ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Open Rate</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-yellow-500">{campaignReports?.data?.clickRate ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Click Rate</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full  h-52 border border-[#ffffff31] rounded-lg flex flex-col items-center justify-center">
                            <p className="text-5xl font-semibold text-red-500">{campaignReports?.data?.unsubscribed ?? 0}</p>
                            <p className="mt-4 text-xl font-medium">Unsubscribed</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
