import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PageOverView from "./PageOverView";
import PostListAnalytic from "./PostListAnalytic";
import { useState } from "react";
import PageVisitLikes from "./PageVisitLikes";
import PageProfile from "./PageProfile";

export default function Facebook() {
  const { pageid } = useParams();

  return (
    <div className="relative grid w-full h-screen overflow-y-auto">
      <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
        <p className="text-lg font-light tracking-wide">Facebook Analytics</p>
      </div>
      <div className="p-5 space-y-5">
        <PageProfile pageid={pageid} />
        <PageOverView pageid={pageid} />
        <PageVisitLikes pageid={pageid} />
        <PostListAnalytic pageid={pageid} />
      </div>
    </div>
  );
}
