import React, { useState } from 'react'
import { showWarning } from '../../components/toast/toast';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { CircleNotch } from '@phosphor-icons/react';
import { useStateValue } from '../../context/StateProvider';

export default function CreateLead() {
    const [{ brand, user }, dispatch] = useStateValue();
    const router = useHistory();
    const [processedData, setProcessedData] = useState([]);
    const [processedDataHeader, setProcessedDataHeader] = useState([]);
    const [flowStep, setFlowStep] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);

    async function downloadFile(url, filename) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const anchor = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);

            anchor.href = objectUrl;
            anchor.download = filename || 'sample';

            document.body.appendChild(anchor);
            anchor.click();

            document.body.removeChild(anchor);
            URL.revokeObjectURL(objectUrl); // Clean up the object URL
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    async function downloadSampleCSV() {
        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fsample_files%2Fsample.csv?alt=media&token=6d2da210-0067-4af1-88ff-7a7c3afd5b76';
        const desiredFilename = 'sample.csv';
        downloadFile(fileUrl, desiredFilename);
    }

    async function downloadSampleJSON() {
        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fsample_files%2Fsample.json?alt=media&token=a908d9e5-267e-4189-a990-eab7a540f106';
        const desiredFilename = 'sample.json';
        downloadFile(fileUrl, desiredFilename);
    }
    const processCSV = async (csv) => {
        const lines = csv.trim().split("\n");
        const headers = lines[0].split(",").map(header => header.trim().replace(/\s/g, ''));
        const rows = lines.slice(1);

        const result = [];

        for (const row of rows) {
            const values = row.split(",");
            const rowData = {};

            for (let i = 0; i < headers.length; i++) {
                // Trim the values to remove leading/trailing whitespace and remove \r characters
                rowData[headers[i]] = values[i].replace(/\r/g, '').trim().replace(/\s/g, '');
            }

            result.push(rowData);
        }
        return result;
    };
    const isJSON = (content) => {
        try {
            JSON.parse(content);
            return true;
        } catch (error) {
            return false;
        }
    };
    const validateJSON = (jsonData) => {
        // Add any additional JSON validation logic here
        // For example, checking if jsonData is an array of objects
        if (!Array.isArray(jsonData) || typeof jsonData[0] !== "object") {
            return showWarning("Invalid JSON format")
        }
        const allowedFields = ["id", "firstname", "lastname", "email"];
        for (const obj of jsonData) {
            const keys = Object.keys(obj);
            if (!keys.every((key) => allowedFields.includes(key))) {
                return showWarning("Invalid JSON fields")
            }
        }
    };
    const isCSV = (content) => {
        return content.includes(",") && content.includes("\n");
    };
    const validateCSV = (csvData) => {
        if (csvData.length === 0 || Object.keys(csvData[0]).length === 0) {
            return showWarning("Invalid CSV format")
        }
        const allowedHeaders = ["id", "firstname", "lastname", "email"];
        const headers = Object.keys(csvData[0]);
        if (!headers.every((header) => allowedHeaders.includes(header))) {
            return showWarning("Invalid CSV headers")
        }
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
            const fileContent = event.target.result;
            try {
                let processedData;
                if (isJSON(fileContent)) {
                    processedData = JSON.parse(fileContent);
                    validateJSON(processedData);
                } else if (isCSV(fileContent)) {
                    processedData = await processCSV(fileContent);
                    validateCSV(processedData);
                } else {
                    return showWarning("Invalid file format")
                }

                setProcessedData(processedData);
                setProcessedDataHeader(Object.keys(processedData[0]));
                setFlowStep((step) => step + 1);
            } catch (error) {
                showWarning("Error processing file")
                console.error("Error processing file:", error.message);
            }
        };

        reader.readAsText(file);
    };
    async function finalSubmit() {
        try {
            setSubmitLoading(true)
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: "https://massemailer-zscu3untuq-as.a.run.app/leads",
                method: "POST",
                headers: headersList,
                data: processedData.map((data) => ({ ...data, brand: brand?.id }))
            }
            let response = await axios.request(reqOptions);
            if (!response.data.success) {
                setSubmitLoading(false)
                return
            }
            setSubmitLoading(false);
            router.push("/admin/emails")
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {flowStep === 0 && <div className='flex flex-col items-center justify-center w-full h-screen bg-background'>
                <div className="w-fit">
                    <div className="w-full mb-5">
                        <input type="text" className="w-full h-10 px-5 rounded-md bg-bg_highlight" placeholder='Enter the leads segment name' />
                    </div>
                    <div className="relative h-[500px] aspect-video bg-bg_highlight rounded-lg border-2 border-dashed  border-white border-opacity-25 flex flex-col items-center justify-center">
                        <input onChange={(event) => handleFileChange(event)} className="absolute w-full h-full rounded-md opacity-0" type='file' accept='.csv, .json' />
                        <p className="text-2xl">Drop your CSV file here</p>
                        <p className="">Max file size: 10MB</p>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="mt-5 tracking-wide">Sample files <span className="cursor-pointer hover:underline hover:underline-offset-2" onClick={() => downloadSampleCSV()}>.csv</span> or <span className="cursor-pointer hover:underline hover:underline-offset-2" onClick={() => downloadSampleJSON()}>.json</span></div>
                    </div>
                </div>
            </div>
            }
            {flowStep === 1 &&
                <div className='relative h-screen p-10 overflow-y-auto bg-background'>
                    <div className="flex items-center justify-between ">
                        <p className="text-3xl font-medium">Leads</p>
                        <button onClick={() => finalSubmit()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none flex items-center justify-center">
                            {submitLoading ?
                                <CircleNotch size={22} color='white' className='animate-spin' />
                                :
                                <p className="">Add</p>
                            }
                        </button>
                    </div>
                    <div className="relative block mt-10 mb-10">
                        <div className="relative">
                            <div className="overflow-x-auto min-h-[0.01%]">
                                <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                    <thead className="h-14 bg-[#131418]">
                                        <tr>
                                            {processedDataHeader.map((header, index) => (
                                                <th key={index} className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                    {header}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="table-row-group align-middle border-inherit">
                                        {processedData.map((row, rowIndex) => (
                                            <React.Fragment key={rowIndex}>
                                                <tr className="table-row-spacer"></tr>
                                                <tr
                                                    className="table-row align-[inherit]  bg-[#131418]"
                                                >
                                                    {processedDataHeader.map((header, cellIndex) => (
                                                        <td key={cellIndex} className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                            {row[header]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                {processedData.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                    No fields Available
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
