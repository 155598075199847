function partition(array, l, r, pivot, field) {
  while (l <= r) {
    while (array[l][field] < pivot) l++;
    while (array[r][field] > pivot && r >= 0) r--;
    if (l <= r) {
      const temp = array[l];
      array[l] = array[r];
      array[r] = temp;
      l++;
      r--;
    }
  }
  return l - 1;
}

function sortByDate(arr = [], s, e, field) {
  if (s < e) {
    let mid = Math.floor((s + e) / 2);
    let pivot = arr[mid][field];
    let index = partition(arr, s, e, pivot, field);
    sortByDate(arr, s, index);
    sortByDate(arr, index + 1, e);
  }
}

function applicantMatchScoreForCampaign(campaign, user) {
  const requirements = [...campaign.creatorCriteria.language];
  if (campaign.creatorCriteria.gender === "any") {
    requirements.push("male");
    requirements.push("female");
  } else {
    requirements.push(campaign.creatorCriteria.gender);
  }

  var userFeatures = [...user.languages, user.gender];

  userFeatures = userFeatures.filter((f) => requirements.includes(f));

  return Math.ceil((userFeatures.length / requirements.length) * 100);
}

function compatiblityUserScoreComponent(score = 0) {
  const shades = [
    "#ab0000",
    "#ab2800",
    "#a83c02",
    "#a84d02",
    "#a84d02",
    "#bd7100",
    "#a1ad00",
    "#7fad00",
    "#5fad00",
    "#1aad00",
    "#1aad00",
  ];

  const pickedColor = shades[Math.ceil(score / 10)];
  return (
    <button
      data-tooltip="Requirements Matching Score"
      className="px-2 py-1 text-xs text-white rounded-md shadow"
      style={{ backgroundColor: pickedColor }}
    >
      {score}%
    </button>
  );
}

function applicantStatus(status) {
  // TODO - DONE - add more colors to describe status of applicant
  const colorMap = {
    "default": "#a1ad00",
    "Ready": "#1aad00",
    "In Review": "#ff6600",
    "Completed": "#0099ff",
    "Rejected": "#cc0000",
    "pending": "#666666"
  };
  const color = colorMap[status] ?? colorMap.default;
  return (
    <button
      data-tooltip="Applicant Status"
      className="px-2 py-1 text-xs text-white rounded-md shadow"
      style={{ backgroundColor: color }}
    >
      {status}
    </button>
  );
}

export {
  applicantMatchScoreForCampaign,
  sortByDate,
  compatiblityUserScoreComponent,
  applicantStatus,
};
