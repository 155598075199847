import React, { useEffect, useState } from 'react'
import { useStateValue } from '../context/StateProvider';
import { useHistory } from 'react-router-dom';
import { Plus, X } from '@phosphor-icons/react';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import Modalcard from '../components/modalcard';
import { showSuccess, showWarning } from '../components/toast/toast';

export default function Getavideocoupons() {
    const [{ user }, dispatch] = useStateValue();
    const router = useHistory();
    const [coupons, setCoupons] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false)
    const [data, setData] = useState({
        couponName: "",
        quantity: 1,
        used: 0,
        value: 0
    });

    async function fetchData() {
        try {
            const snapshot = await getDocs(collection(db, "getavideocoupons"));
            const couponsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setCoupons(couponsData);
        } catch (error) {
            console.error("Error fetching coupons:", error);
        }
    }
    useEffect(() => {
        if (user.access !== "admin") {
            router.replace("/admin")
        }
        fetchData();
    }, [user.access, router])
    async function createCoupon() {
        try {
            if (!data.couponName || !data.value === 0) {
                return showWarning("Please fill all details")
            }
            const couponQuery = query(collection(db, "getavideocoupons"), where("couponName", "==", data.couponName.replace(/\s/g, "").toUpperCase()));
            const existingCouponSnapshot = await getDocs(couponQuery);
            if (!existingCouponSnapshot.empty) {
                return showWarning("Coupon name already exist");
            }
            const doc = {
                dateAdded: Date.now(),
                couponName: data.couponName.replace(/\s/g, "").toUpperCase(),
                quantity: 1,
                used: 0,
                value: parseInt(data.value.trim())
            }
            await addDoc(collection(db, "getavideocoupons"), doc);
            setShowModal(false);
            showSuccess("Coupon createed successfully")
            setData({
                couponName: "",
                quantity: 1,
                used: 0,
                value: 0
            });
            await fetchData();
            return
        } catch (error) {
            console.log("error: " + error.message)
        }
    }
    return (
        <div className='w-full h-full p-10 bg-background'>
            {showModal &&
                <Modalcard close={setShowModal}>
                    <div id="thin-scrollbar" className="h-full w-full overflow-y-auto p-5 mx-auto  lg:min-w-[500px] bg-background border-2 border-[#8080809d] rounded-lg">
                        <div className="flex items-center justify-between w-full">
                            <p className="text-2xl">Coupon</p>
                            <button className="flex items-center justify-center h-8 rounded-md aspect-square bg-bg_highlight" onClick={() => setShowModal(false)} ><X size={22} color="red" /></button>
                        </div>
                        <div className="w-full mt-6 space-y-4">
                            <div className="">
                                <p className="font-light tracking-wide">Coupon Code <span className="text-red-500">*</span></p>
                                <input type="text" value={data?.couponName} onChange={(event) => setData({ ...data, couponName: event.target.value })} placeholder="Enter coupon code" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border border-[#80808079]`} />
                            </div>
                            <div className="">
                                <p className="font-light tracking-wide">Coupon Value <span className="text-red-500">*</span></p>
                                <input type="number" min={0} value={data?.value} onChange={(event) => setData({ ...data, value: event.target.value })} placeholder="Enter coupon value" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border border-[#80808079]`} />
                            </div>
                            <div className="pt-4" onClick={() => createCoupon()}>
                                <button className="py-2 px-8 h-10 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </Modalcard>
            }
            <div className="flex items-center justify-between">
                <div className="">
                    <p className="text-2xl font-medium">Coupons</p>
                </div>
                <div className="">
                    <button
                        className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center gap-3"
                        onClick={() => setShowModal(true)}
                    >
                        <Plus size={22} color="white" />
                        Create
                    </button>
                </div>
            </div>
            <div className="relative block m-10">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className="bg-[#131418] rounded-t-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Id
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Coupon Code
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Status
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Date Added
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Value
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Quantity
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Used
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {coupons
                                    ?.sort((a, b) => b.dateAdded - a.dateAdded)
                                    ?.map((coupon, index) => (
                                        <tr
                                            className="table-row align-[inherit] border-inherit"
                                            key={index}
                                        >
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {index + 1}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.id}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.couponName}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.used > 0 ? "Used" : "Not Used"}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.dateAdded && moment(coupon?.dateAdded).format("DD/MM/YYYY")}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle text-center h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.value}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.quantity}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {coupon?.used}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {coupons.length === 0 && (
                            <div className="h-14 bg-[#121212] rounded-b-md flex items-center justify-center">
                                <p className="tracking-wide ">No coupons available</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
