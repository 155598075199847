import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { sendAmplitudeData } from "../amplitude";
import { Eye, Hexagon, Plus, ShieldSlash } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { useStateValue } from "../context/StateProvider";
import { showWarning } from "../components/toast/toast";

function Quiz() {
  const [quizzes, setquizzes] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const { path } = useRouteMatch();
  const router = useHistory();

  useEffect(() => {
    sendAmplitudeData("Quiz dashboard page opened");

    const q = query(
      collection(db, "quizs"),
      where("brandemail", "==", user?.email)
    );

    getDocs(q).then((res) => {
      const quizzesData = res.docs.map((doc) => doc.data());
      setquizzes(quizzesData);
      setloading(false);
    });
  }, []);

  function statusQuiz(form) {
    if (!form.startDate)
      return <p className="px-2 py-1 bg-gray-700 rounded-md">Draft</p>;
    let isExpired = Date.now() > form.startDate + 86400000 * form.duration;
    if (isExpired)
      return <p className="px-2 py-1 bg-red-700 rounded-md">Expired</p>;
    return <p className="px-2 py-1 bg-green-700 rounded-md">Live</p>;
  }

  async function changeRoute() {
    let limit = user?.limit?.quizLimit ?? 1;
    if ((user?.quizCount ?? quizzes.length) >= limit) {
      showWarning("Quiz limit exceeded, Please upgrade your plan.");
      return;
    }
    router.push(path + "/create");
  }

  if (loading)
    return (
      <div className=" min-h-screen -mt-16 relative flex items-center justify-center overflow-y-auto bg-[#323336] py-16 px-20">
        <p className="">Gettings your quizzes ready</p>
      </div>
    );

  if (quizzes.length === 0) {
    return (
      <div className="relative min-h-screen px-20 py-16 -mt-16 overflow-y-auto ">
        <div className="">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffaq%20initial%20login%20image.png?alt=media&token=e461dbfd-68e5-44c8-aeea-69a539e20f54"
            alt="rightImage"
            className="absolute right-0 bottom-0 max-h-[80vh]"
          />
        </div>
        <div className="pt-28">
          <p className="text-6xl font-semibold">Welcome</p>
          <div className="mt-8">
            <p className="mb-1 text-xl font-medium tracking-wider">
              What is Quiz ?
            </p>
            <p className="font-light tracking-wider">
              Start by creating a Quiz. This will let you remotely gather videos
              from any device just by sharing a link.
            </p>
          </div>
          <div className="my-8">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create Quiz
            </button>
          </div>
        </div>
        <div className="h-auto p-3 py-5 my-2 text-center border-2 border-white w-fit rounded-xl">
          <p className="mx-auto text-lg font-medium tracking-wider">
            quizzes used
          </p>
          <div className="mt-5 text-3xl">
            <span className="">
              {user?.quizzes ?? 0}/
              <span className="">{user?.limit?.quizLimit ?? 10}</span>
            </span>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="min-h-screen ">
        <div className="px-10 py-5">
          <div className="w-full lg:max-w-[70%] mx-auto grid grid-cols-2 gap-5">
            <div className="w-full h-auto p-3 py-5 my-2 text-center border-2 border-white rounded-xl">
              <p className="mx-auto text-lg font-medium tracking-wider">
                quizzes used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.quizzes ?? quizzes?.length}/
                  <span className="">{user?.limit?.quizLimit ?? 1}</span>
                </span>
              </div>
            </div>
            <div className="w-full h-auto p-3 py-5 my-2 text-center border-2 border-white rounded-xl">
              <p className="mx-auto text-lg font-medium tracking-wider">
                Downloads used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.downloads ?? 0} /
                  <span className="">
                    {" "}
                    {user?.downloadLimit === 5000
                      ? "Unlimited"
                      : user?.downloadLimit ?? 3}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between p-10">
          <div className="">
            <p className="text-3xl font-medium">Your quizzes</p>
            <p className="tracking-wide">
              Use quizzes to collect testimonials from your users.
            </p>
          </div>
          <div className="">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() =>
                user?.quizzes >= 10
                  ? showWarning(
                    "Challnages limit exceeded, Please upgrade to continue"
                  )
                  : changeRoute()
              }
            >
              <Plus size={22} color="white" /> Create Quiz
            </button>
          </div>
        </div>

        <div className="grid w-full p-5 pb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-16 ">
          {quizzes?.map((form, index) => (
            <div
              className=" cursor-pointer w-full bg-[#232323] h-[173px] rounded-3xl relative flex flex-col justify-between"
              key={index}
              onClick={() => router.push(path + "/view-quiz/" + form?.id)}
            >
              <div className="">
                <div className="flex items-center gap-3 p-3">
                  <img
                    src={form?.productimage}
                    alt="thumbnail"
                    className="h-[85px] aspect-square rounded-lg -mt-8"
                  />
                  <div className="">
                    <p className="">{statusQuiz(form)}</p>
                  </div>
                </div>
                <div className="flex items-center justify-between px-3">
                  <div className="">
                    <div className="flex items-center gap-3">
                      <p className="font-work">{form?.campaign_name}</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="relative flex items-center justify-center w-6 h-6">
                      <Hexagon size={22} weight="light" />
                      <Eye size={10} className="absolute" weight="bold" />
                    </div>
                    <p className="text-[8px] tracking-wide">View</p>
                  </div>
                </div>
              </div>
              <div className="h-auto text-sm font-light tracking-wide py-2 px-3 bg-[#2C2C2C] text-[#D9D9D9] rounded-b-3xl">
                {form?.productname}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Quiz;
