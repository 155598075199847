import React from 'react'

export default function InstagramReel({ review, user, caption }) {
    return (
        <div className="w-full max-w-[320px]  aspect-[9/16] bg-white border border-[#525050f6] rounded relative">
            <div className="absolute flex items-end w-full h-full">
                <div className="flex-1 w-full pr-2 pb-[10px] pl-[10px] flex-col flex justify-between h-full">
                    <div className="flex-1 w-full"></div>
                    <div className="">
                        <div className="pb-[16px] w-full flex items-center gap-3">
                            <div className="h-[32px] w-[32px] rounded-full  bg-black">
                                {user?.social?.instagram && <img src={user?.social?.instagram?.profile_pic} alt="" className="w-full h-full rounded-full" />}
                            </div>
                            <p className=" text-[12px] flex-1 w-full">
                                <span className="">{user?.social?.instagram?.name}</span>
                                <span className="px-2">·</span>
                                <span className="px-[6px] py-1 rounded-md border border-white">Follow</span>
                            </p>
                        </div>
                        <div className="pb-[16px] w-full">
                            <p className="line-clamp-1 text-[12px]">
                                {caption}
                            </p>
                        </div>
                        <div className="flex items-center w-full gap-2">
                            <svg
                                aria-label="Audio image"
                                className="x1lliihq x1n2onr6 x9bdzbf"
                                fill="currentColor"
                                height={12}
                                role="img"
                                viewBox="0 0 24 24"
                                width={12}
                            >
                                <path d="M21.002 16.972V2.044a.999.999 0 0 0-.36-.769 1.012 1.012 0 0 0-.823-.214L6.816 3.479A1 1 0 0 0 6 4.462v10.864A3.75 3.75 0 1 0 9 19V9.56l9.003-1.675v5.442A3.75 3.75 0 1 0 21.005 17c0-.01-.003-.02-.003-.029Z" />
                            </svg>
                            <p className="text-[12px]">{user?.social?.instagram?.name}<span className="px-2">·</span>Original audio</p>
                        </div>
                    </div>
                </div>
                <div className="w-[30px] pb-[10px]">
                    <div className="w-6 pb-[16px]">
                        <svg
                            aria-label="Like"
                            className="x1lliihq x1n2onr6 x1hfr7tm xq3z1fi"
                            fill="currentColor"
                            height={24}
                            role="img"
                            viewBox="0 0 24 24"
                            width={24}
                        >
                            <title>Like</title>
                            <path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z" />
                        </svg>
                        <p className="text-xs text-center pt-[2px]">33</p>
                    </div>
                    <div className="w-6 pb-[16px]">
                        <svg
                            aria-label="Comment"
                            className="x1lliihq x1n2onr6 x1hfr7tm xq3z1fi"
                            fill="currentColor"
                            height={24}
                            role="img"
                            viewBox="0 0 24 24"
                            width={24}
                        >
                            <title>Comment</title>
                            <path
                                d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth={2}
                            />
                        </svg>
                        <p className="text-xs text-center pt-[2px]">33</p>
                    </div>
                    <div className="w-6 pb-[16px]">
                        <svg
                            aria-label="Direct"
                            className="x1lliihq x1n2onr6 x1hfr7tm xq3z1fi"
                            fill="currentColor"
                            height={24}
                            role="img"
                            viewBox="0 0 24 24"
                            width={24}
                        >
                            <title>Direct</title>
                            <line
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                x1={22}
                                x2="9.218"
                                y1={3}
                                y2="10.083"
                            />
                            <polygon
                                fill="none"
                                points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth={2}
                            />
                        </svg>
                        <p className="text-xs text-center pt-[2px]">33</p>
                    </div>
                    <div className="w-6 pb-[16px]">
                        <svg
                            aria-label="More"
                            className="x1lliihq x1n2onr6 x1hfr7tm xq3z1fi"
                            fill="currentColor"
                            height={24}
                            role="img"
                            viewBox="0 0 24 24"
                            width={24}
                        >
                            <title>More</title>
                            <circle cx={12} cy={12} r="1.5" />
                            <circle cx={6} cy={12} r="1.5" />
                            <circle cx={18} cy={12} r="1.5" />
                        </svg>
                    </div>
                    <div className="w-6 bg-black aspect-square rounded-[3px]">
                        <img src={user?.social?.instagram?.profile_pic} alt="" className="object-cover w-full h-full rounded-[3px]" />
                    </div>
                </div>
            </div>
            <div className="w-full h-full rounded">
                {review?.reviewUrl ? (
                    <video playsInline
                        src={review?.reviewUrl}
                        className="object-cover w-full h-full rounded"
                    ></video>
                ) : (
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffacebookpreview.png?alt=media&token=ca5406fc-100f-4835-834a-318efffc4e94"
                        alt="preview"
                        className="object-cover w-full h-full"
                    />
                )}
            </div>
        </div>
    )
}
