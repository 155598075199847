import React, { useEffect, useState } from "react";
import SocialAnalyticsCard from "./card";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";

function InstagramAnalytics({ pageid, token }) {
  const [insight, setInsight] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://graph.facebook.com/v19.0/${pageid}/insights?metric=impressions,reach,website_clicks,get_directions_clicks,profile_views,follower_count&period=day&access_token=${token}`
      )
      .then((response) => {
        setInsight(response.data.data);
      });
  }, []);

  if (!insight)
    return (
      <div className="">
        <p className="">Loading...</p>
      </div>
    );
  else
    return (
      <>
        <div className="py-3 rounded-md bg-bg_highlight">
          <p className="pl-5 text-xl tracking-wide">Instagram</p>
          <p className="pl-5 text-xs text-gray-500">Yesterday</p>
          <div className="grid w-full grid-cols-3 gap-10 p-5">
            {insight.map((card, index) => (
              <SocialAnalyticsCard key={"insight" + index} card={card} />
            ))}
          </div>
        </div>
      </>
    );
}

export default InstagramAnalytics;
