import React from 'react'

export default function ProductCard({ product, selectedProducts, productReviews, productFlicks, updateProductSelection, isFaq, faqSubmission }) {
    return (
        <div className={`w-[200px] cursor-pointer select-none border-2 rounded-lg ${selectedProducts.includes(product?.id) ? "border-[#8EA9FA]" : "border-transparent hover:border-[#435488]"}`} onClick={() => updateProductSelection(product?.id)}>
            <div className="w-full aspect-square">
                <img loading='lazy' src={product?.imageUrl} alt="" className="w-full h-full aspect-square rounded-t-md" />
            </div>
            <div className="bg-bg_highlight w-full min-h-[150px] flex flex-col justify-between rounded-b-md p-3 ">
                <p className="text-sm line-clamp-5">{product?.name}</p>
                {!isFaq ?
                    <div className="flex items-center justify-between">
                        <p className="text-[13px]">Reviews: {productReviews?.length}</p>
                        <p className="text-[13px]">Flicks: {productFlicks?.length}</p>
                    </div>
                    :
                    <div className="flex items-center justify-between">
                        <p className="text-[13px]"> Faq Submissions: {faqSubmission}</p>
                    </div>
                }
            </div>
        </div>
    )
}
