import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import InstagramProfile from "./InstagramProfile";
import InstagramVisitLikes from "./InstagramVisiLikes";
import InstagramPosts from "./InstagramPosts";

export default function Instagram() {
  const { id } = useParams();

  return (
    <div className="relative w-full h-screen overflow-y-auto">
      <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
        <p className="text-lg font-light tracking-wide">Instagram Analytics</p>
      </div>
      <div className="p-5 space-y-5">
        <InstagramProfile pageid={id} />
        <InstagramVisitLikes pageid={id} />
        <InstagramPosts pageid={id} />
      </div>
    </div>
  );
}
