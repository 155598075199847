import { Check, CircleNotch, DotsThreeVertical, X } from '@phosphor-icons/react';
import React, { useEffect } from 'react'
import { showWarning } from '../../../components/toast/toast';
import axios from 'axios';
import { useState } from 'react';
import Modalcard from '../../../components/modalcard';
import ErrorBoundary from '../../../components/errorBoundary';

export default function Content({ campaign, }) {
    const [reviewModal, setReviewModal] = useState(false);
    const [campaignContent, setcampaignContent] = useState([]);
    const [selectedContent, setselectedContent] = useState({});
    const [changesRef, setchangesRef] = useState({
        comments: "",
        changes: "",
    });
    const [applicationModal, setapplicationModal] = useState(false);
    const [applicationDetails, setapplicationDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        async function getCampaignContent() {
            let headersList = {
                Accept: "*/*",
                Authorization:
                    "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
            };

            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/getHouseContent/${campaign?._id}`,
                method: "GET",
                headers: headersList,
            };

            const response = await axios.request(reqOptions).then((res) => res?.data?.data);
            setcampaignContent(response ?? []);
            setIsLoading(false)
        }
        getCampaignContent();
    }, [campaign?._id]);
    async function approveContent(application) {
        const aprovedvideos = campaignContent.filter(
            (val) => val?.status === "Completed"
        );
        if (application?.status === "Completed") {
            showWarning("Content is already approved");

            return;
        }
        if (aprovedvideos.length === campaign?.videos) {
            showWarning("You have already approved maximum no of videos");
            return;
        }
        let headersList = {
            Accept: "*/*",
            Authorization:
                "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
        };

        let bodyContent = {
            userId: application?.user?.id,
            status: "Completed",
        };

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent,
        };
        await axios.request(reqOptions).then((response) => {
            if (response?.data?.success === true) {
                setcampaignContent((prevData) => {
                    return prevData.map((obj) => {
                        if (obj._id === application?._id) {
                            return { ...obj, status: "Completed" };
                        } else {
                            return obj;
                        }
                    });
                });
            }
        });
    }
    async function rejectContent(application) {
        if (application?.status === "Rejected") {
            showWarning("Content is already rejected");
            return;
        }

        let headersList = {
            Accept: "*/*",
            Authorization:
                "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
        };

        let bodyContent = {
            userId: application?.user?.id,
            status: "Rejected",
        };

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent,
        };
        await axios.request(reqOptions).then((response) => {
            if (response?.data?.success === true) {
                setcampaignContent((prevData) => {
                    return prevData.map((obj) => {
                        if (obj._id === application?._id) {
                            return { ...obj, status: "Rejected" };
                        } else {
                            return obj;
                        }
                    });
                });
            }
        });
    }
    async function requestEditContent() {
        if (selectedContent?.status === "Completed" || selectedContent?.status === "Rejected") {
            showWarning(`Cannot request for edit as content is already ${selectedContent?.status === "Completed" ? "Approved" : "Rejected"}`);
            return;
        }

        if (changesRef?.changes.length < 5) {
            showWarning(`Please select a changes title`);
            return;
        }
        let headersList = {
            Accept: "*/*",
            Authorization: "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
        };

        let bodyContent = {
            userId: selectedContent?.user?.id,
            changesTitle: changesRef?.changes,
            changesComment: changesRef?.comments ?? null,
            status: "Ready",
        };

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent,
        };
        await axios.request(reqOptions).then((response) => {
            if (response?.data?.success === true) {
                setcampaignContent((prevData) => {
                    return prevData.map((obj) => {
                        if (obj._id === selectedContent?._id) {
                            return {
                                ...obj,
                                changesTitle: changesRef?.changes,
                                changesComment: changesRef?.comments ?? null,
                            };
                        } else {
                            return obj;
                        }
                    });
                });
                setapplicationModal(false);
                setapplicationDetails({});
            }
        });
    }
    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center w-full h-full">
                <CircleNotch size={22} color='white' className='animate-spin' />
                <p>Loading applications...</p>
            </div>
        )
    }
    return (
        <ErrorBoundary>
            <>
                {applicationModal === true && (
                    <>
                        <Modalcard close={setapplicationModal}>
                            <div
                                className="w-[900px] bg-[#2e313e] h-[80vh] overflow-y-auto rounded-md"
                                id="thin-scrollbar"
                            >
                                <div className="h-16 border-b bg-[#2e313e] border-[#83838380] flex items-center justify-between gap-5 px-10 sticky top-0 z-10">
                                    <div className="flex gap-5">
                                        <button className="border-b-2 border-[#4611ea]">
                                            Request Edit
                                        </button>
                                    </div>
                                </div>
                                <div className="flex gap-5 m-5">
                                    <div className="w-96">
                                        <video
                                            playsInline
                                            onContextMenu={(e) => e.preventDefault()}
                                            controls={false}
                                            src={selectedContent?.url}
                                            onClick={(event) =>
                                                event.currentTarget?.paused
                                                    ? event.currentTarget.play()
                                                    : event.currentTarget.pause()
                                            }
                                            className="aspect-[9/16] rounded-md  w-[250px]"
                                        ></video>
                                    </div>
                                    <div className="w-full gap-5">
                                        <p className="pb-3 text-lg font-work">Request edits</p>
                                        <div className="my-3">
                                            <p className="pb-1 font-work">Changes #1</p>
                                            <select
                                                type="text"
                                                onChange={(event) =>
                                                    setchangesRef({
                                                        ...changesRef,
                                                        changes: event.target.value,
                                                    })
                                                }
                                                className="w-full bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                                            >
                                                <option value="">Please Select</option>
                                                <option value="Fix aspect ratio to fit the Brief">
                                                    Fix aspect ratio to fit the Brief
                                                </option>
                                                <option value="Fix duration to fit the Brief">
                                                    Fix duration to fit the Brief
                                                </option>
                                                <option value="Videos needs to look more natural">
                                                    Videos needs to look more natural{" "}
                                                </option>
                                                <option value="Videos need more energy">
                                                    Videos need more energy
                                                </option>
                                                <option value="Remove filters or effects">
                                                    Remove filters or effects
                                                </option>
                                                <option value="Remove voice over/music">
                                                    Remove voice over/music
                                                </option>
                                                <option value="Incorrect pronounciation">
                                                    Incorrect pronounciation
                                                </option>
                                                <option value="Video/audio quality issue">
                                                    Video/audio quality issue
                                                </option>
                                                <option value="Product not shown">
                                                    Product not shown
                                                </option>
                                                <option value="Inaccurate Scene">
                                                    Inaccurate Scene
                                                </option>
                                                <option value="Incorrect mention">
                                                    Incorrect mention
                                                </option>
                                                <option value="Film it differently">
                                                    Film it differently
                                                </option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="my-3">
                                            <p className="pb-1 font-work">Comments (Optional)</p>
                                            <textarea
                                                type="text"
                                                onChange={(event) =>
                                                    setchangesRef({
                                                        ...changesRef,
                                                        comments: event.target.value,
                                                    })
                                                }
                                                placeholder=""
                                                defaultValue={selectedContent?.video?.comments}
                                                className="w-full bg-[#23252D] h-32 appearance-none outline-none rounded-md p-3 text-sm tracking-wide"
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <button
                                                onClick={() => {
                                                    setselectedContent("");
                                                    setapplicationModal({});
                                                }}
                                                className="border-2  border-[#93A8F4] rounded-full px-8 py-2 text-[#93A8F4]"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => requestEditContent()}
                                                className="bg-[#93A8F4] rounded-full px-8 py-2 text-white"
                                            >
                                                Send Request
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modalcard>
                    </>
                )}
                {reviewModal === true ? (
                    <Modalcard close={setReviewModal}>
                        <video
                            playsInline
                            onKeyDown={(event) => {
                                if (event.button === 2) {
                                    event.preventDefault();
                                }
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                            src={applicationDetails?.reviewUrl ?? applicationDetails?.url}
                            onClick={(event) =>
                                event.currentTarget?.paused
                                    ? event.currentTarget.play()
                                    : event.currentTarget.pause()
                            }
                            className="aspect-[9/16] rounded-md mx-auto w-[320px] z-10 appearance-none outline-none"
                            controls
                            controlsList="nodownload noplaybackrate"
                            disablePictureInPicture
                        ></video>
                    </Modalcard>
                ) : null}
                <div className="flex flex-wrap gap-5 m-5">
                    {campaignContent?.map((application, index) => (
                        <div
                            key={index}
                            className="w-[270px] bg-[#131418] border border-gray-400 rounded-md relative"
                        >
                            <div className="flex justify-end p-3">
                                {application?.changesTitle && application?.status === "In Review" && (
                                    <div className="py-1 px-3 w-fit bg-[#4611ea] text-xs">
                                        Asked for changes
                                    </div>
                                )}
                                {application?.status === "Completed" && (
                                    <div className="py-1 px-3 w-fit bg-[#4611ea] text-xs">
                                        Approved
                                    </div>
                                )}
                                {application?.status === "Rejected" && (
                                    <div className="py-1 px-3 w-fit bg-[#ea1111] text-xs">
                                        Rejected
                                    </div>
                                )}
                            </div>
                            <div className="flex items-start gap-3 px-5 pb-5">
                                <div className="h-12 w-12 rounded-full flex items-center justify-center bg-[#23252D]">
                                    {application?.user?.imageUrl !== null ? (
                                        <img
                                            src={application?.user?.imageUrl}
                                            alt="user_image"
                                            className="w-full h-full rounded-full"
                                        />
                                    ) : (
                                        <p className="">
                                            {(application?.user?.name &&
                                                application?.user?.name
                                                    .split(" ")
                                                    .map((n) => n[0].toUpperCase())
                                                    .join("")) ||
                                                ""}
                                        </p>
                                    )}
                                </div>
                                <div className="">
                                    <p className="">{application?.user?.name}</p>
                                    <div className="text-xs tracking-wide mt-1 bg-[#23252D] py-1 px-3 rounded-md w-fit">
                                        {campaign?.videoType}
                                    </div>
                                </div>
                            </div>
                            <div className="px-5">
                                <div
                                    onClick={() => {
                                        setapplicationDetails(application);
                                        setReviewModal(true);
                                    }}
                                    className="aspect-[9/16] rounded-md mx-auto w-[180px] cursor-pointer"
                                >
                                    <video
                                        playsInline
                                        onKeyDown={(event) => {
                                            if (event.button === 2) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onContextMenu={(e) => e.preventDefault()}
                                        controls={false}
                                        src={application?.url}
                                        className="w-full h-full"
                                    ></video>
                                </div>
                            </div>
                            <div className="flex items-center justify-center gap-5 p-5">
                                {application?.status !== "Completed" && (
                                    <div className="flex flex-col items-center">
                                        <button
                                            className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center"
                                            onClick={() => {
                                                setselectedContent(application);
                                                setapplicationModal(true);
                                            }}
                                        >
                                            <DotsThreeVertical size={25} color="#998EE8" />
                                        </button>
                                        <p className="mt-1 text-xs tracking-wide">More</p>
                                    </div>
                                )}
                                {application?.status !== "Completed" && (
                                    <div
                                        className="flex flex-col items-center"
                                        onClick={() => rejectContent(application)}
                                    >
                                        <button className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center">
                                            <X size={25} color="#ff0000" />
                                        </button>
                                        <p className="mt-1 text-xs tracking-wide">
                                            {application?.status === "Ready" && "Reject"}
                                            {application?.status === "In Review" && "Reject"}
                                            {application?.status === "Rejected" && "Rejected"}
                                        </p>
                                    </div>
                                )}
                                {application?.status !== "Rejected" && (
                                    <div
                                        className="flex flex-col items-center"
                                        onClick={() => approveContent(application)}
                                    >
                                        <button className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center">
                                            <Check size={25} color="#998EE8" />
                                        </button>
                                        <p className="mt-1 text-xs tracking-wide">
                                            {application?.status === "Ready" && "Approve"}
                                            {application?.status === "In Review" && "Approve"}
                                            {application?.status === "Completed" && "Approved"}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {campaignContent.length === 0 && (
                        <div className="flex items-center justify-center flex-1 h-full">
                            <p className="">Creators haven't submitted content yet.</p>
                        </div>
                    )}
                </div>
            </>
        </ErrorBoundary>
    )
}
