const videoLanguages = [
  "Hindi",
  "English",
  "Telugu",
  "Tamil",
  "Marathi",
  "Bengali",
];

const flicksCategory = [
  {
    category: "Get The Looks",
    subcategory: [
      "Ethnic Wear Tips",
      "Wedding wear",
      "SkinCare Routine Tips",
      "Cocktail Wear",
      "Vintage & Retro Styling",
      "Fitness & Health Tips",
    ],
  },
  {
    category: "Haul Videoes",
    subcategory: ["Food Haul", "Tech Haul", "Beauty Haul,Fashion Haul"],
  },
  {
    category: "Tutorial Videoes",
    subcategory: [
      "Makeup Tutorial",
      "SkinCare Tutorial",
      "Smart Devices Tutorial",
      "App-Web Tools Tutorial",
      "Nail Art Tutorial",
      "Healthy Cookin Tutorial",
    ],
  },
  {
    category: "Unboxing Videoes",
    subcategory: [
      "Food & Drink Unboxing",
      "Beauty Unboxing",
      "Gadget unboxing",
      "Fashion Unboxing",
    ],
  },
  {
    category: "Store Visit Videoes",
    subcategory: ["Offline Store Visit", "Online Store Visit"],
  },
];

export { videoLanguages, flicksCategory };
