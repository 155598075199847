import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";
import InstagramPostTile from "./InstagramPostTile";

function InstagramPosts({ pageid }) {
  //media?fields=comments_count,like_count,media_type,media_url,thumbnail_url,caption

  const [{ user }, dispatch] = useStateValue();
  const [media, setMedia] = useState([]);

  useEffect(() => {
    const token = user.social.facebook.access_token;
    const instabusiness = user.social.instagram.business_account;
    axios
      .get(
        `https://graph.facebook.com/v19.0/${instabusiness}/media?fields=comments_count,permalink,like_count,media_type,media_url,thumbnail_url,caption&access_token=${token}`
      )
      .then((response) => {
        setMedia(response.data.data);
      });
  }, []);

  if (media.length === 0)
    return (
      <div className="flex h-40 items-center justify-center">
        <i className="fas fa-spinner animate-spin"></i>
      </div>
    );
  else
    return (
      <div>
        {media.map((media) => (
          <InstagramPostTile
            media={media}
            pageToken={user.social.facebook.access_token}
          />
        ))}
      </div>
    );
}

export default InstagramPosts;
