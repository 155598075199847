import React, { useEffect, useState } from 'react'
import ApplicantsCard from '../../../videoCampaign/ApplicantsCard'
import { applicantMatchScoreForCampaign, sortByDate } from '../../../videoCampaign/huntFunctions';
import { cachedAPICalls } from '../../../common/api';
import { APiTokens, apiHost } from '../../../common/Strings';
import { CircleNotch } from '@phosphor-icons/react';

export default function Applications({ campaign }) {
    const [campaignApplication, setcampaignApplication] = useState([]);
    const [filteredApplication, setfilteredApplication] = useState([]);
    const [selectedContent, setSelectedContent] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [applicationFilters, setApplicationFilters] = useState("");
    useEffect(() => {
        async function getCampaignApplications() {
            let count = 0;
            const response = await cachedAPICalls(
                `${apiHost.brandAdmin}/getAllInterestedUser/${campaign?._id}`,
                { headers: { Authorization: APiTokens.brandAdmin } }
            );
            response?.data.forEach(async (application, index) => {
                application.score = applicantMatchScoreForCampaign(campaign, application.user);
            });
            response?.data?.forEach((application, index) => {
                if (application?.status !== "pending") {
                    count += 1;
                }
            });
            setcampaignApplication(response?.data ?? []);
            setfilteredApplication(response?.data ?? []);
            setSelectedContent(count || 0);
            setIsLoading(false);
        }
        getCampaignApplications()
    }, [campaign])


    function sortApplicantList(e) {
        const selectedOption = e.target.value;
        const arr = [...campaignApplication];
        if (selectedOption === "default") {
            sortByDate(arr, 0, arr.length - 1, "date");
            setfilteredApplication(arr);
            return;
        } else if (selectedOption === "bestFit") {
            sortByDate(arr, 0, arr.length - 1, "score");
            setfilteredApplication(arr);
            return;
        } else if (selectedOption === "topPerformer") {
            sortByDate(arr, 0, arr.length - 1, "rating");
            setfilteredApplication(arr);
            return;
        }
        else if (selectedOption === "instagram") {
            // sortByDate(arr, 0, arr.length - 1, "instagram");
            setfilteredApplication(arr.filter(val => val.instagramConnected === true));
            return;
        }
    }
    const applicationsFilterConditions = {
        "pending": (val) => val.status === "pending",
        "Ready": (val) => val.status === "Ready" || val.status === "In Review" || val.status === "Completed",
        "In Review": (val) => val.status === "In Review",
        "Completed": (val) => val.status === "Completed"
    };
    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center w-full h-full">
                <CircleNotch size={22} color='white' className='animate-spin' />
                <p>Loading applications...</p>
            </div>
        )
    }
    return (
        <>
            <div className="flex items-center justify-between mx-5">
                <div className="">
                    <span>Sort By </span>
                    <select
                        onChange={(e) => sortApplicantList(e)}
                        className="font-semibold bg-transparent"
                        name=""
                        id=""
                    >
                        <option className="text-black" value="default">
                            Early Applicants
                        </option>
                        <option className="text-black" value="bestFit">
                            Best Fit
                        </option>
                        <option className="text-black" value="topPerformer">
                            Top Performer
                        </option>
                        <option className="text-black" value="instagram">
                            Instagram Linked
                        </option>
                    </select>
                </div>
                <div className="flex gap-5">
                    {/* TODO - DONE - Make this button working -- filter buttons for improved Status visuals */}
                    <button className="font-light tracking-wide hover:underline underline-offset-8" onClick={() => setApplicationFilters("")}>All</button>
                    <button className="font-light tracking-wide hover:underline underline-offset-8" onClick={() => setApplicationFilters("pending")}>New Applicants</button>
                    <button className="font-light tracking-wide hover:underline underline-offset-8" onClick={() => setApplicationFilters("Ready")}>Selected Applicants</button>
                    <button className="font-light tracking-wide hover:underline underline-offset-8" onClick={() => setApplicationFilters("In Review")}>Submissions</button>
                    <button className="font-light tracking-wide hover:underline underline-offset-8" onClick={() => setApplicationFilters("Completed")}>Approved</button>
                </div>
            </div>
            <div className="flex flex-wrap gap-5 m-5">
                {filteredApplication?.filter(val => !applicationFilters || applicationsFilterConditions[applicationFilters]?.(val))?.map((application, index) => (
                    <ApplicantsCard key={index} application={application} />
                ))}
                {filteredApplication?.filter(val => !applicationFilters || applicationsFilterConditions[applicationFilters]?.(val)).length === 0 && (
                    <div className="flex items-center justify-center flex-1 h-full">
                        <p className="">
                            No applications available to display
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}
