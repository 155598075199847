import axios from "axios";
import React, { useMemo, useState } from "react";

function InstagramPostTile({ media, pageToken }) {
  const [insight, setInsight] = useState([]);
  const [showInsight, setShowInsight] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchInsight = async () => {
    setShowInsight(!showInsight);
    setLoading(true);

    const metric =
      media.media_type === "VIDEO"
        ? "reach,plays,total_interactions,ig_reels_video_view_total_time,ig_reels_avg_watch_time"
        : "impressions,reach";

    try {
      if (insight.length === 0) {
        const response = await axios.get(
          `https://graph.facebook.com/v19.0/${media.id}/insights?metric=${metric}&access_token=${pageToken}`
        );
        setInsight(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching insight:", error);
    } finally {
      setLoading(false);
    }
  };

  const postData = useMemo(() => {
    return (
      <div className="flex w-full gap-5 py-5 h-26">
        {insight.map((card) => (
          <div
            key={card.name}
            className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]"
          >
            <p className="text-xs">{card.title}</p>
            <p className="text-xl font-bold">
              {["ig_reels_video_view_total_time", "ig_reels_avg_watch_time"].includes(card.name)
                ? `${Math.ceil(card.values[0].value / 60000)} Minutes`
                : card.values[0].value}
            </p>
          </div>
        ))}
      </div>
    );
  }, [insight]);

  return (
    <div className="p-2 m-1 duration-200 transform rounded bg-background hover:bg-opacity-50 hover:cursor-pointer">
      <div className="flex items-start justify-between w-full gap-5 p-3 rounded">
        <div className="flex items-center justify-center flex-1 w-full gap-5">
          <img src={media.media_type === "IMAGE" ? media.media_url : media.thumbnail_url} alt="" className="max-w-[100px]" />
          <div className="flex-col flex-1 gap-2">
            <button className="p-1 m-1 text-xs bg-pink-600">{media.media_type}</button>
            <p>{media.caption}</p>
            <a href={media.permalink} target="_blank" rel="noreferrer" className="text-blue-600">{media.permalink}</a>
          </div>
        </div>

        <div className="flex items-center justify-center gap-5">
          <button
            onClick={fetchInsight}
            className="px-6 py-2 text-xs duration-150 transform bg-purple-600 rounded hover:bg-purple-800"
          >
            {showInsight ? "Hide" : "Insight"}
          </button>
          <div className="flex flex-col items-center justify-center">
            <p>{media.like_count}</p>
            <p>Like</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p>{media.comments_count}</p>
            <p>comment</p>
          </div>
        </div>
      </div>
      {showInsight && (
        <div className="flex items-center justify-center">
          {loading && (
            <div className="py-5 h-26">
              <i className="fas fa-spinner animate-spin"></i>
            </div>
          )}
          {!loading && insight.length !== 0 && postData}
        </div>
      )}
    </div>
  );
}

export default InstagramPostTile;
