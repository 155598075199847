import React, { useEffect, useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { nanoid } from "nanoid";
import axios from "axios";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { strings } from "../../common/Strings";
import { showSuccess, showWarning } from "../../components/toast/toast";

export default function SocialChannels() {
  const [{ brand, user }, dispatch] = useStateValue();
  const [step, setSteps] = useState(0);
  const [tempData, setTempData] = useState(null);
  const [tempSelect, setTempSelect] = useState(null);
  const [tempMultiSelect, setTempMultiSelect] = useState([]);

  // steps
  // 0 - default
  // 11 - facebook loading
  // 12 - facebook Show/Select (Multiple) Pages
  // 21 - instagram  connect loading
  // 22 - instagram - select page which is connected to instagram account
  // 23 - instagram - confirm Instagram Account
  // 31 - Youtube  steps
  // 41 - LinkedIn steps
  // 51 - MyRevue steps
  // 61 - whatsapp connecting
  //
  //MyRevue
  // const APP_ID = "853321706468798";
  // const APP_SECRET = "3fdc4bb72c8f2dc002ea320f584f9299";
  //Yash Raj
  const APP_ID = "5793827960701399";
  const APP_SECRET = "7fc4284da07f11754bfa03a4b9372184";
  const REDIRECT_URI = window.origin + "/social/channel/facebook";

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) getFacebookToken(code);
  }, []);

  const getFacebookToken = async (code) => {
    setSteps(11);
    const tokenParams = {
      client_id: APP_ID,
      client_secret: APP_SECRET,
      redirect_uri: REDIRECT_URI,
      code: code,
    };
    try {
      const tokenResponse = await axios.get(
        "https://graph.facebook.com/v19.0/oauth/access_token",
        {
          params: tokenParams,
        }
      );
      const shortLivedAccessToken = tokenResponse.data.access_token;

      const longTokenParams = {
        grant_type: "fb_exchange_token",
        client_id: APP_ID,
        client_secret: APP_SECRET,
        fb_exchange_token: shortLivedAccessToken,
      };

      const longTokenResponse = await axios.get(
        "https://graph.facebook.com/v19.0/oauth/access_token",
        {
          params: longTokenParams,
        }
      );
      const longLivedAccessToken = longTokenResponse.data.access_token;
      console.log(longLivedAccessToken);

      var newUser = { ...user };

      if (newUser.social) {
        newUser.social["facebook"] = {
          access_token: longLivedAccessToken,
          expiry: Date.now() + 20 * 24 * 60 * 60 * 1000,
        };
      } else {
        newUser.social = {
          facebook: {
            access_token: longLivedAccessToken,
            expiry: Date.now() + 20 * 24 * 60 * 60 * 1000,
          },
        };
      }
      sessionStorage.setItem("user", JSON.stringify(newUser));
      dispatch({ type: "SET_USER", user: newUser });
      await updateDoc(doc(db, strings.adminCollection, newUser.email), {
        social: newUser.social,
      }).then((r) => {
        showSuccess("Facebook Connected -- Reloading");
        setTimeout(() => {
          window.location.href =
            window.location.origin + window.location.pathname;
        }, 2000);
      });
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setSteps(0);
    }
  };

  const connectInstagram = async () => {
    try {
      if (!user?.social?.facebook) {
        showWarning("First Connect Your Facebook then try again.");
        return;
      }
      setSteps(21);

      const FB_API_URL =
        "https://graph.facebook.com/v19.0/me/accounts?fields=id,picture{url},name,access_token&access_token=" +
        user.social.facebook.access_token;
      console.log(FB_API_URL);
      var response = await axios.get(FB_API_URL);
      if (response.status === 200) {
        setTempData(response.data.data);
        setSteps(22);
      } else {
        throw new Error(response.status + " - " + response.statusText);
      }
    } catch (error) {
      setSteps(0);
      showWarning(error.message);
    }
  };
  console.log(tempData);

  const instagramFetchAccount = async () => {
    try {
      if (!tempSelect) {
        showWarning("select a page associated with instagram");
        return;
      }
      console.log(tempSelect, tempData);

      const access_token = tempData.find(
        (page) => page.id === tempSelect
      ).access_token;
      const IG_PROFILE_URL =
        "https://graph.facebook.com/v19.0/" +
        tempSelect +
        "?fields=instagram_accounts{username,profile_pic,id},instagram_business_account&access_token=" +
        access_token;
      var response = await axios.get(IG_PROFILE_URL);

      if (response.status === 200) {
        setTempData(response.data);
        setSteps(23);
      } else {
        throw new Error(response.status + " - " + response.statusText);
      }
    } catch (error) {
      setSteps(0);
      showWarning(error.message);
    }
  };

  const confirmInstaAccount = async (instaAccount) => {
    try {
      var social = { ...user.social };
      var instagram = {
        id: instaAccount.id,
        business_account: tempData.instagram_business_account.id,
        profile_pic: instaAccount.profile_pic ?? null,
        name: instaAccount.username,
      };
      if (!social) social = {};
      social.instagram = instagram;
      const updatedUser = { ...user, social: social };
      sessionStorage.setItem("user", JSON.stringify(updatedUser));
      dispatch({ type: "SET_USER", user: updatedUser });
      await updateDoc(doc(db, strings.adminCollection, user.email), {
        social: social,
      }).then((r) => {
        showSuccess("Instagram Connected -- Reloading");
      });
    } catch (error) {
      showWarning(error.message);
    } finally {
      setTempData(null);
      setTempMultiSelect([]);
      setSteps(0);
    }
  };

  const saveFacebookPages = async () => {
    try {
      var newUser = { ...user };
      var newFbPagesid = tempMultiSelect;
      var newPages = tempData;
      var fbPages = newUser.social.pages ?? [];
      console.log(newFbPagesid);
      for (const pageId of newFbPagesid) {
        if (fbPages.includes((p) => p.id === pageId)) continue;
        var page = newPages.find((p) => p.id === pageId);
        if (page) fbPages.push(page);
      }

      newUser.social.facebook_pages = fbPages;
      sessionStorage.setItem("user", JSON.stringify(newUser));
      dispatch({ type: "SET_USER", user: newUser });
      await updateDoc(doc(db, strings.adminCollection, newUser.email), {
        social: newUser.social,
      }).then((r) => {
        showSuccess("Facebook Pages added");
      });
    } catch (error) {
      showWarning(error.message);
    } finally {
      setTempData(null);
      setTempMultiSelect([]);
      setSteps(0);
    }
  };

  const addFacebookPage = async () => {
    setSteps(11);
    try {
      const FB_API_URL =
        "https://graph.facebook.com/v19.0/me/accounts?fields=picture{url},name,id,access_token&access_token=" +
        user.social.facebook.access_token;
      var response = await axios.get(FB_API_URL);
      if (response.status === 200) {
        setTempData(response.data.data);
        setSteps(12);
      } else {
        throw new Error(response.status + " - " + response.statusText);
      }
    } catch (error) {
      showWarning(error.message);
      setTempData(null);
      setTempMultiSelect([]);
      setSteps(0);
    }
  };

  const initFacebookConenct = () => {
    const state = nanoid(12);
    const scope =
      "read_insights,publish_video,pages_show_list,read_page_mailboxes,business_management,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,public_profile";
    window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${APP_ID}&redirect_uri=${REDIRECT_URI}&scope=${scope}&state=${state}`;
  };

  async function getWhatsappToken(code) {
    try {
      let shortReqOptions = {
        url: "https://graph.facebook.com/v19.0/oauth/access_token",
        method: "GET",
        params: {
          client_id: APP_ID,
          client_secret: APP_SECRET,
          redirect_uri: "",
          code: code,
        },
      };
      let short = await axios.request(shortReqOptions);
      const token = short.data.access_token;
      let longReqOptions = {
        url: "https://graph.facebook.com/v19.0/oauth/access_token",
        method: "GET",
        params: {
          client_id: APP_ID,
          client_secret: APP_SECRET,
          grant_type: "fb_exchange_token",
          fb_exchange_token: token,
        },
      };
      let long = await axios.request(longReqOptions);
      const longtoken = long.data.access_token;
      const whatsappId = await getWhatsappId(longtoken);
      const phoneNumberId = await getWhatsappPhoneNumberId(
        whatsappId,
        longtoken
      );
      return {
        accessToken: longtoken,
        waId: whatsappId,
        phoneNumberId: phoneNumberId,
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  async function getWhatsappId(token) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "Bearer EABSVdLF8wdcBOZCdZAk2Rqba6n136PE3qaxdt886lCtUbxy2gd5GZAvRgM04dUgIEuDaW7kIY1LFs3eP7Qr2sYMuEJPegRvyCx8s0ZAQORFQSr5bYGv260ZA6NSicNjRDRpqXNZCZAolZCIzZBx1h5XGYnRWyxZBGWkIggG09lFraGqPDApI0RM2RV4E59",
    };

    let reqOptions = {
      url: `https://graph.facebook.com/v19.0/debug_token?input_token=${token}`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    return response.data.data.granular_scopes[0].target_ids[0];
  }
  async function getWhatsappPhoneNumberId(wa_Id, token) {
    let headersList = {
      Accept: "*/*",
    };

    let reqOptions = {
      url: `https://graph.facebook.com/v19.0/${wa_Id}/phone_numbers?access_token=${token}`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    return response.data.data[0].id;
  }
  function launchWhatsAppSignup() {
    return new Promise((resolve, reject) => {
      window.FB.login(
        function (response) {
          console.log(response);
          if (response.authResponse) {
            console.log("----------GOT RESPONSE---------", response);
            const accessToken = response.authResponse.code;
            getWhatsappToken(accessToken)
              .then((whatsapp) => {
                if (!whatsapp) {
                  return showWarning("Something went wrong, try again later");
                }
                var newUser = { ...user };

                if (newUser.social) {
                  newUser.social["whatsapp"] = whatsapp;
                } else {
                  newUser.social = {
                    whatsapp: whatsapp,
                  };
                }

                const updatedUser = { ...user, social: newUser.social };
                sessionStorage.setItem("user", JSON.stringify(updatedUser));
                dispatch({ type: "SET_USER", user: updatedUser });
                updateDoc(doc(db, strings.adminCollection, user.email), {
                  social: newUser.social,
                })
                  .then((r) => {
                    showSuccess("Whatsapp Connected");
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            console.log("User cancelled login or did not fully authorize.");
            reject("User cancelled login or did not fully authorize.");
          }
        },
        {
          config_id: "1443802386208770", // MyRevue
          // config_id: "1567565880745618", // Yash Raj
          response_type: "code", // must be set to 'code' for System User access token
          override_default_response_type: true,
        }
      );
    });
  }

  if (step === 11)
    return (
      <div className="relative flex flex-col items-center justify-center w-full h-screen overflow-y-auto">
        <p className="">Getting Auth information from provider...</p>
        <i className="fas fa fa-spinner animate-spin"></i>
      </div>
    );
  else if (step === 21)
    return (
      <div className="relative flex flex-col items-center justify-center w-full h-screen overflow-y-auto">
        <p className="">Getting your facebook pages...</p>
        <i className="fas fa fa-spinner animate-spin"></i>
      </div>
    );
  else if (step === 22)
    return (
      <div className="relative flex flex-col items-center justify-center w-full p-10 overflow-y-auto">
        <p className="py-5">Select Pages which is connected to instagram</p>
        <div className="flex items-center justify-start gap-10 px-4 py-3">
          {tempData && tempData.length > 0 ? (
            tempData.map((page) => (
              <div
                onClick={() => setTempSelect(page.id)}
                className={
                  tempSelect === page.id
                    ? "bg-primary rounded-md transform duration-150 hover:bg-opacity-90 h-60 w-60 flex items-center justify-center gap-5 flex-col"
                    : "border hover:cursor-pointer transform duration-150 rounded-md hover:bg-gray-700 hover:bg-opacity-90 h-60 w-60 flex gap-5 flex-col items-center justify-center"
                }
                key={page.id}
              >
                <img src={page?.picture?.data?.url} alt="" />
                <p className=""> {page.name}</p>
              </div>
            ))
          ) : (
            <div>No pages connected to this Facebook account</div>
          )}
        </div>
        <div className="py-10 text-center">
          <button
            onClick={instagramFetchAccount}
            className="px-3 py-2 rounded bg-primary "
          >
            Select
          </button>
        </div>
      </div>
    );
  else if (step === 12)
    return (
      <div className="relative flex flex-col items-center justify-center w-full p-10 overflow-y-auto">
        <p className="py-5">Select Facebook Pages To Connect </p>
        <div className="flex items-center justify-start gap-10 px-4 py-3">
          {tempData && tempData.length > 0 ? (
            tempData.map((page) => (
              <div
                onClick={() => {
                  console.log(tempMultiSelect);
                  if (tempMultiSelect.includes(page.id)) {
                    setTempMultiSelect(
                      tempMultiSelect.filter((f) => f !== page.id)
                    );
                  } else setTempMultiSelect([...tempMultiSelect, page.id]);
                }}
                className={
                  tempMultiSelect.includes(page.id)
                    ? "bg-primary cursor-pointer rounded-md transform duration-150 hover:bg-opacity-90 p-4 h-60 w-60 flex gap-5 flex-col items-center justify-center"
                    : "border hover:cursor-pointer transform duration-150 rounded-md hover:bg-gray-700 p-4 hover:bg-opacity-90 h-60 w-60 flex gap-5 flex-col items-center justify-center"
                }
                key={page.id}
              >
                <img
                  src={page.picture.data.url}
                  className="rounded-full"
                  style={{ height: "inherit" }}
                  alt=""
                />
                <p className=""> {page.name}</p>
              </div>
            ))
          ) : (
            <div>No pages connected to this Facebook account</div>
          )}
        </div>
        <div className="py-10 text-center">
          <button
            onClick={saveFacebookPages}
            className="px-3 py-2 rounded bg-primary "
          >
            Select
          </button>
        </div>
      </div>
    );
  else if (step === 23)
    return (
      <div className="relative flex flex-col items-center justify-center w-full p-10 overflow-y-auto">
        <p className="py-5">Select Instagram Account</p>
        <div className="flex items-center justify-start gap-10 px-4 py-3">
          {tempData ? (
            tempData.instagram_accounts.data.map((insta) => (
              <div
                key={insta.id}
                className="flex flex-col items-center justify-center duration-150 transform rounded-md hover:bg-opacity-90 h-60 w-60"
              >
                <img
                  src={insta.profile_pic}
                  className="w-40 h-40 border rounded-full"
                  alt=""
                />
                <p className="my-5 "> {insta.username}</p>
              </div>
            ))
          ) : (
            <div>No pages connected to this Facebook account</div>
          )}
        </div>
        <div className="py-10 text-center">
          <button
            onClick={() =>
              confirmInstaAccount(tempData.instagram_accounts.data[0])
            }
            className="px-3 py-2 mx-4 rounded bg-primary"
          >
            Confirm
          </button>
          <button
            onClick={() => {
              setSteps(0);
              setTempData(null);
              setTempSelect(null);
            }}
            className="px-3 py-2 mx-4 bg-gray-500 rounded"
          >
            cancel
          </button>
        </div>
      </div>
    );
  else
    return (
      <div className="relative w-full h-screen overflow-y-auto">
        <div className="h-14 w-full border-b flex items-center px-5 border-[#808080]">
          <p className="text-lg font-light tracking-wide">Social Channels</p>
        </div>
        <div className="p-10">
          <div className="flex items-start border-b border-[#8080801f] py-5">
            <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Ffacebook.png?alt=media&token=5675160a-b038-470c-9ffd-9803660318f3"
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <p className="text-sm font-normal">Facebook</p>
            </div>
            <div className="flex flex-1 gap-4 text-sm">
              {!user.social?.facebook ? (
                <button
                  onClick={initFacebookConenct}
                  className="transform duration-200 px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                >
                  Connect
                </button>
              ) : (
                <>
                  <button className="px-5 py-2 text-sm border rounded-full">
                    Connected
                  </button>
                  {user?.social?.facebook_pages?.map((page, index) => (
                    <div className="flex items-center gap-3" key={index}>
                      <img
                        src={page.picture.data.url}
                        className="rounded-full"
                        alt=""
                      />
                      <p className="">{page?.name}</p>
                    </div>
                  ))}
                  {user?.social?.facebook_pages?.length > 0 ? null : (
                    <button
                      onClick={addFacebookPage}
                      className="transform duration-200 px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      Add Facebook Page
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex items-start border-b border-[#8080801f] py-5">
            <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <p className="text-sm font-normal">Instagram</p>
            </div>
            <div className="flex-1 text-sm">
              {!user.social?.instagram ? (
                <button
                  onClick={connectInstagram}
                  className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                >
                  Connect
                </button>
              ) : (
                <div className="flex items-center gap-5">
                  <button className="px-5 py-2 text-sm border rounded-full">
                    Connected
                  </button>
                  <img
                    src={user.social.instagram.profile_pic}
                    className="rounded-full h-[50px] w-[50px]"
                    alt=""
                  />
                  <p className="">{user.social.instagram.name}</p>
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex items-start border-b border-[#8080801f] py-5">
            <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Fyoutube.png?alt=media&token=95cdb77b-bd34-4e0f-a5b8-81fad630e030"
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <p className="text-sm font-normal">Youtube</p>
            </div>
            <div className="flex-1 text-sm">
              <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]">
                Connect
              </button>
            </div>
          </div> */}
          {/* <div className="flex items-start border-b border-[#8080801f] py-5">
            <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Flinkedin.png?alt=media&token=1a018f13-6337-41e5-9e89-f8913c6b948c"
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <p className="text-sm font-normal">Linkedin</p>
            </div>
            <div className="flex-1 text-sm">
              <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]">
                Connect
              </button>
            </div>
          </div> */}
          {/* <div className="flex items-start border-b border-[#8080801f] py-5">
            <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Fwhatsapp.webp?alt=media&token=1e3124fb-1262-4aec-98d9-c2bdcf58fb31"
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <p className="text-sm font-normal">Whatsapp</p>
            </div>
            <div className="flex-1 text-sm">
              {user.social?.whatsapp ? (
                <button onClick={() => launchWhatsAppSignup()} className="px-5 py-2 text-sm border rounded-full">
                  Connected
                </button>
              ) : (
                <button
                  onClick={() => launchWhatsAppSignup()}
                  className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                >
                  Connect
                </button>
              )}
            </div>
          </div> */}
          <div className="flex items-start border-b border-[#8080801f] py-5">
            <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20icon.png?alt=media&token=71ebcd5d-3060-457a-a6fc-0d4dfa6de361"
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <p className="text-sm font-normal">MyRevue</p>
            </div>
            <div className="flex-1 text-sm">
              <div className="flex items-center gap-2">
                <img
                  src={brand?.imageUrl}
                  alt="logo"
                  className="h-10 rounded-full aspect-square"
                />
                <div className="">
                  <p className="cursor-pointer ">
                    Connected as
                    <span className="text-[#8EA9FA] underline tracking-wide underline-offset-2 pl-2">
                      MyRevue
                    </span>
                  </p>
                  {/* <p className="cursor-pointer text-[#8EA9FA] underline tracking-wide underline-offset-2">
                    Disconnect
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
