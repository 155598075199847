import { ArrowLeft, ArrowRight, Check, RocketLaunch, ShuffleAngular, Target, TrendUp, Wallet, X } from "@phosphor-icons/react";
import { Play } from "phosphor-react";
import "swiper/css";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from "swiper/react";
import AccordionItem from "../components/accordian";
import NotloginfooterNew from "../layouts/NotloginfooterNew";
import PlaylistFeed from "../components/playlistFeed";
import Modalcard from "../components/modalcard";
import axios from "axios";
import { showWarning } from "../components/toast/toast";
import UgcOrderDemo from "./ugcOrderDemos";
import { nanoid } from "nanoid";

export default function UgcorderNew() {
    const swiperRef = useRef(null);
    const history = useHistory();
    const [activePlaylist, setactivePlaylist] = useState([]);
    const params = new URLSearchParams(window.location.search);
    const router = useHistory();
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [notes, setNotes] = useState({
        "brandName": "",
        "brandWebsite": "",
        "websiteCategory": "",
        "spokePersonName": "",
        "contactNumber": "",
        "socialMediaLink": "",
        "brandLogoLink": "",
        "spokePersoneMailId": "",
        "referenceVideoLink": "",
    });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (params.get('showModal') === 'true') {
            if (activePlaylist.length === 0) {
                router.replace("/ugc")
            }
        }
        var title = `Get UGC Videos in 100 Hrs at most affordable prices`;
        var metaTitle = `Get UGC Videos in 100 Hrs at most affordable prices`;
        var metaDescription = `Getting UGC is EASY with MyRevue, whether you need 20 videos or just one. Simply choose the video pack that suits your business best, let us know what you need, and we’ll make sure your video is produced exactly as you picture it.`;
        var metaKeyword = `Brands - MyRevue, UGC Video, MyRevue Elite, Billo , GoTolStoy, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

        var ogUrl = `https://brand.myrevue.app/ugc`;
        var ogtype = `website`;
        var ogtitle = `Get UGC Videos in 100 Hrs at most affordable prices`;
        var ogdescription = `Getting UGC is EASY with MyRevue, whether you need 20 videos or just one. Simply choose the video pack that suits your business best, let us know what you need, and we’ll make sure your video is produced exactly as you picture it.`;
        var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FUGC.png?alt=media&token=1f9b53de-3469-4f31-a9fa-c6ebbc035458`;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/ugc`;
        var twittertitle = `Get UGC Videos in 100 Hrs at most affordable prices`;
        var twitterdescription = `Getting UGC is EASY with MyRevue, whether you need 20 videos or just one. Simply choose the video pack that suits your business best, let us know what you need, and we’ll make sure your video is produced exactly as you picture it.`;
        var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FUGC.png?alt=media&token=1f9b53de-3469-4f31-a9fa-c6ebbc035458`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
            metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
            ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
            twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
            twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
    }, [activePlaylist, params, router]);
    const topArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Ftop%2Fone.mp4?alt=media&token=2a34f4bf-a94a-490e-a0b1-dcb933bdeaa8",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Ftop%2Fone.webp?alt=media&token=bbc8e298-7b9e-408b-9a22-d7884c6c49aa",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Ftop%2Ftwo.mp4?alt=media&token=1f02284c-7312-45e4-937a-a707e4ed76e5",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Ftop%2Ftwo.webp?alt=media&token=b9d2364b-5dd8-416d-b8cb-96c769b3651c",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Ftop%2Fthree.mp4?alt=media&token=5d28164e-c1c2-4184-a05f-6be8975674a4",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Ftop%2Fthree.webp?alt=media&token=de6d205c-1f16-4b6d-a0f1-6b37eaa9415a",
        },
    ];
    const flicksArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4D5ebca875b24ba2a87b7463546eeed6.mp4?alt=media&token=3672d45e-8251-4eb6-b8af-908ea2ebbb85",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4d5ebca875b24ba2a87b7463546eeed6.png?alt=media&token=bc19efab-0992-4647-829d-949151b9a63a",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol2.mp4?alt=media&token=b08eb87a-7c95-4963-8bf2-12dd60d78610",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol2.webp?alt=media&token=bb5e8402-d3a4-45dd-827f-316530275dd8",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol3.mp4?alt=media&token=8ba06cca-84de-4cf8-98b9-2924acb4c7e0",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol3.webp?alt=media&token=96ebe8dd-0d48-4809-a636-4974dc2f8cbc",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini2.mp4?alt=media&token=6a0b0d49-b92b-4af5-ae47-0b00c5c96ede",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fbhavini2.webp?alt=media&token=51f8f3c3-0a72-42a5-9bd3-1489bfa09d20",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).mp4?alt=media&token=b7198879-89b5-4c8a-a455-a09441445922",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).png?alt=media&token=0fd786ff-6e46-4c12-85ff-755e97f0c02a",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTaponn%20(Anurag%20Sir).mp4?alt=media&token=322221ae-19fc-4bb1-a973-f4f6c060c68b",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTapOnn%20(Anurag%20Sir).png?alt=media&token=cf12877c-adb7-4f4d-9cdd-73cc624fef7b",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTaponn%20(Khushi).mp4?alt=media&token=39e81ea3-7ea6-40a2-843d-baa4ed3b619b",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTapOnn%20(Khushi).png?alt=media&token=25dd607d-ea02-445e-80ed-714562b80b5a",
        },
        // {
        //     video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FWildglow%20Reel.mp4?alt=media&token=e4a6304e-3c89-4a19-b672-cc4b364d03a8",
        //     poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FWildglow%20Reel.png?alt=media&token=41db347e-fb3d-420f-8492-b52ceb15136f",
        // },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag1.mp4?alt=media&token=172ad69d-7f1c-446e-a867-26bce250fc85",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanurag1.webp?alt=media&token=cf7a5008-5453-47c0-9e05-ec7505a9755e",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh1.mp4?alt=media&token=b7b7271e-de84-4944-a8dc-77565b207148",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fvedansh1.webp?alt=media&token=ca258d8c-fdb0-41c0-af9d-47e70426451b",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksE%2Freview_219qSxB_KIriCVfpmsov5PKBc.mp4?alt=media&token=37e0578c-87ce-43fd-b0f6-b9c87fb8a88e",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_219qSxB_KIriCVfpmsov5PKBc.jpg?alt=media&token=f171286a-0a92-48f5-ada7-cb836b5283a3",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_fuzOoj7gC4rbPhXz_p.mp4?generation=1677135449934793&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_fuzOoj7gC4rbPhXz_p.jpg?alt=media&token=acd55324-7e91-4388-b044-44d8fc7b5f16",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak1.mp4?alt=media&token=521c8f94-f535-4f97-86d8-7ad2036b80ac",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fpalak1.webp?alt=media&token=2321831f-95b0-4f16-a39b-2d30b487a6c9",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_ppsL7LRYG5VkcB93FM.mp4?alt=media&token=268435fc-6c68-4bcb-a699-ed1fe63a70d4",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_ppsL7LRYG5VkcB93FM.jpg?alt=media&token=860d6e6a-141f-4fb0-9356-1f35f5fd0eaf",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak3.mp4?alt=media&token=f7948f01-26c7-41b2-93e8-0820246244f5",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fpalak3.webp?alt=media&token=6cfd0d86-5f99-4f15-b22c-134f0b10ede3",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_skbS98U1PBPnkcTGiz.mp4?generation=1670858902234562&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_skbS98U1PBPnkcTGiz.jpg?alt=media&token=c5c78128-d63d-44f2-ab3f-e15d43032172",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy2.mp4?alt=media&token=46106ffd-4267-4b02-9bbe-2899768fd2d8",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fdaisy2.webp?alt=media&token=538b750b-17be-49f6-b25d-5d0fc87c2d3a",
        },
    ];
    const ApparelArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fapparel%2F1.mp4?alt=media&token=6eba18d4-27fe-45b1-9495-863fd08cb44e",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fapparel%2F1.webp?alt=media&token=adc54d14-9c45-4d3a-b3c2-6bec37d657a0",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fapparel%2F2.mp4?alt=media&token=c2ca9360-1408-4f10-bda9-345e243af2ac",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fapparel%2F2.webp?alt=media&token=69830563-9ad3-4bd3-a227-9231ef19e11f",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fapparel%2F3.mp4?alt=media&token=c3b592b0-2fb9-4748-bb3f-fa87b82e9575",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fapparel%2F3.webp?alt=media&token=4430c7af-78b0-4d09-8a4b-0cfe01188add",
        },
    ];
    const JewelleryArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FJewellry%2FJwellery.mp4?alt=media&token=1814785c-bbcd-48b5-a385-8c28496dae9f",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FJewellry%2Fjwellery.webp?alt=media&token=a2fbe7ce-08b3-44ad-8240-cde38551e799",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FJewellry%2F2.mp4?alt=media&token=159005e9-f5ed-4118-aadf-31d18e547afa",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FJewellry%2F2.webp?alt=media&token=6c300e30-c100-4857-aa5b-2ca3c3a37300",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FJewellry%2F3.mp4?alt=media&token=18a919a8-2739-4323-b510-1d1685047d75",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FJewellry%2F3.webp?alt=media&token=4256010d-a127-4598-a029-cf035daf9e98",
        },
    ];
    const LifestyleArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F4.mp4?alt=media&token=10e0dd35-9467-4c3e-96a4-86df3ba318d1",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F4.webp?alt=media&token=f63e4416-43f0-43fe-9482-a72898e2c35c",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F5.mp4?alt=media&token=a92b8014-1797-4795-a58b-29e4ecce57a3",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F5.png?alt=media&token=a3384da1-6ecf-4a63-ae44-bba8b0da5864",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F6.mp4?alt=media&token=35ea1f4d-62a3-4228-8b29-1744b4848b0e",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F6.webp?alt=media&token=76e530bf-8686-447a-ac93-9aafd332f5bf",
        },
    ];
    const FoodArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol1.mp4?alt=media&token=1c623df3-3586-443e-857c-d4602a08ffd4",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol1%20thumb.webp?alt=media&token=fa12f873-1fc9-497b-addb-aa7f8a689b4d",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FFood%2F8.mp4?alt=media&token=75f52123-6a1e-4ff5-baf6-5804b3ef6e0c",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FFood%2F8.webp?alt=media&token=9ef664a2-4b67-4002-a76c-a9a1eb1fe9a1",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FFood%2F9.mp4?alt=media&token=d51945ea-6b30-42c5-aaf8-85247f1fde49",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FFood%2F9.webp?alt=media&token=c08bd947-8b4d-4081-bb9c-5489db075776",
        },
    ];
    const HairCareArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FHairCare%2F10.mp4?alt=media&token=a33d6074-f949-4b46-b505-45646284cc61",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FHairCare%2F10.webp?alt=media&token=62b2934a-2000-424d-b033-6bf08a2b9462",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FHairCare%2F11.mp4?alt=media&token=c530d924-8ae4-492b-a47d-80917d42a4f5",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FHairCare%2F11.webp?alt=media&token=6b76b646-51cd-4ce4-a55e-ec5f0d4ed5ad",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FHairCare%2F12.mp4?alt=media&token=2c120fc3-2d14-4bed-a642-7f09218c6aa4",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FHairCare%2F12.webp?alt=media&token=aa034836-9900-4a56-bb87-716d9abfdc95",
        },
    ];
    const SkinCareArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FSkinCarew%2F13.mp4?alt=media&token=52bc842b-6f08-4af0-8cc3-343356db4235",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FSkinCarew%2F13.webp?alt=media&token=9d9a9e29-3263-44da-97c7-dd52735bcec9",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FSkinCarew%2F14.mp4?alt=media&token=0be21890-12f6-4563-ac97-2c2610087eba",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FSkinCarew%2F14.webp?alt=media&token=84b24a89-3027-4bf0-b5c9-7226a986e9df",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FSkinCarew%2F15.mp4?alt=media&token=6f970fcf-28cf-481c-96c9-cfe1408b76ab",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FSkinCarew%2F15.webp?alt=media&token=494815b7-34fa-46fe-ac77-7d911922797c",
        },
    ];
    const InformativeArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FInformative%2F16.mp4?alt=media&token=d0b727b8-5cc4-442f-91ee-b999111b5770",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FInformative%2F16.webp?alt=media&token=da80607e-c5db-4619-9fba-f2e6843db434",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FInformative%2F17.mp4?alt=media&token=3901d273-b21f-489a-bbaa-766ab7cea6ef",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FInformative%2F17.webp?alt=media&token=a82f1e14-9e16-4dac-a4d1-d0b55ed62279",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FInformative%2F18.mp4?alt=media&token=b5cc51ee-1ee0-4ec9-b4fb-9f0debb617b3",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FInformative%2F18.webp?alt=media&token=eae405a7-467e-4b12-ba05-398fb31f7859",
        },
    ];
    const ProductShootArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FProduct%20Shoot%2Fproduct%20shot%201.mp4?alt=media&token=574e0c75-a9c5-4c6b-ae5b-28b03218ba53",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FProduct%20Shoot%2Fproduct%20shot%201.webp?alt=media&token=0c9941e4-ac6a-42d9-8956-5b236e124bd1",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FProduct%20Shoot%2F19.mp4?alt=media&token=d55e00f4-cfc9-4cf1-a552-0a70c76756d6",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FProduct%20Shoot%2F19.webp?alt=media&token=2f1db185-e70f-424c-9355-4d4dcd0b02e6",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FProduct%20Shoot%2Fproduct%20shot%202.mp4?alt=media&token=f5c6b19b-8570-4b8c-ba14-eb0a3326b4f7",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FProduct%20Shoot%2FProduct%20shot%202.webp?alt=media&token=4b0feef7-6f19-4a5f-ba92-dd7588899eb0",
        },
    ];


    async function scrollToPlans() {
        const plans = document.getElementById("pricing-plans");
        plans.scrollIntoView({ behavior: "smooth" });
    }
    async function validatebrandinfo() {
        // Regular expression patterns for email and URL validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/; // Basic URL pattern

        // Create an object to hold errors
        let validationErrors = {};

        // Check if any required fields are empty or if they don't meet the validation criteria
        if (!notes.brandName) {
            validationErrors.brandName = "Brand name is required";
        }
        if (!notes.brandWebsite || !urlPattern.test(notes.brandWebsite)) {
            validationErrors.brandWebsite = "Valid website URL is required";
        }
        if (!notes.websiteCategory) {
            validationErrors.websiteCategory = "Website category is required";
        }
        if (!notes.spokePersonName) {
            validationErrors.spokePersonName = "Spokesperson name is required";
        }
        if (!notes.contactNumber) {
            validationErrors.contactNumber = "Contact number is required";
        }
        if (!notes.socialMediaLink || !urlPattern.test(notes.socialMediaLink)) {
            validationErrors.socialMediaLink = "Valid social media URL is required";
        }
        if (!notes.brandLogoLink || !urlPattern.test(notes.brandLogoLink)) {
            validationErrors.brandLogoLink = "Valid logo URL is required";
        }
        if (!notes.spokePersoneMailId || !emailPattern.test(notes.spokePersoneMailId)) {
            validationErrors.contactMailId = "Valid email address is required";
        }

        // Update state with errors
        setErrors(validationErrors);

        // Check if there are any errors
        return Object.keys(validationErrors).length === 0;
    }
    async function generatePaymentLink(planType) {
        setPaymentLoading(true)
        if (!planType) {
            return showWarning("Please select a plan")
        } const plan = ["TryMe", "Launch", "Grow", "Scale"];
        const amount = [8000, 25000, 40000, 70000];

        const TAX_RATE = 0.18;
        const originalAmount = amount[planType - 1];
        const taxAmount = originalAmount * TAX_RATE;
        const finalAmount = originalAmount + taxAmount;

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };
        let bodyContent = {
            amount: finalAmount,
            currency: "INR",
            description: `Order UGC Video : ${plan[planType - 1]}`,
            url: "ugc",
            metadata: {
                "type": "UGC Order",
                "plan": plan[planType - 1],
                "amount": finalAmount,
            }
        };

        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/razorpay/create/ugc/checkoutLink",
            // url: "http://localhost:8080/razorpay/create/ugc/checkoutLink",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };
        let response = await axios.request(reqOptions);
        setPaymentLoading(false)
        makePayment(response.data)
        // window.location.href = response.data.url;
    }
    async function closeModal() {
        history.replace("/ugc")
    }
    const makePayment = async (data) => {
        const res = await initializeRazorpay();
        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }
        var options = {
            key: "rzp_live_E4dtid6tq7cO0E", // Enter the Key ID generated from the Dashboard
            // key: "rzp_test_J6MDQxvvITDTvi", // Enter the Key ID generated from the Dashboard
            name: "MyRevue",
            currency: data.currency,
            amount: data.amount,
            order_id: data.id,
            description: data.notes.description,
            image: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue.png?alt=media&token=b9dc6ef0-c272-49ba-a7c5-5bf51b46eda7",
            handler: function (response) {
                setPaymentLoading(false)
                history.replace(`/ugc?paymentModal=true&orderId=${data.id}`)
            },
            theme: {
                hide_topbar: false,
                color: '#FF005C',
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    const initializeRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    async function updateUGCOrder() {
        try {
            const isValid = await validatebrandinfo();
            if (!isValid) {
                return showWarning("Please fill out all required fields");
            }
            const noteDocs = {
                brandName: notes.brandName,
                brandWebsite: notes.brandWebsite,
                websiteCategory: notes.websiteCategory,
                spokePersonName: notes.spokePersonName,
                contactNumber: notes.contactNumber,
                socialMediaLink: notes.socialMediaLink,
                brandLogoLink: notes.brandLogoLink,
                contactMailId: notes.spokePersoneMailId,
                reference: notes.referenceVideoLink ?? ""
            };
            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
            let reqOptions = {
                url: "https://stripeugcapi-zscu3untuq-as.a.run.app/ugc/orders/update/" + params.get('orderId'),
                // url: "http://localhost:8080/ugc/orders/update/" + params.get('orderId'),
                method: "POST",
                headers: headersList,
                data: noteDocs,
            };
            let response = await axios.request(reqOptions);
            if (response.data.success === true) {
                return history.replace("/ugc?success=true")
            }
            else {
                return history.replace("/ugc")
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    return (
        <div className="min-h-screen bg-[#101010] w-full">
            {params.get('paymentModal') === 'true' && (
                <>
                    <Modalcard customClass={"flex item-start w-full"} close={closeModal}>
                        <div id="thin-scrollbar" className="h-full w-full overflow-y-auto p-5 mx-auto  max-w-[500px] bg-background border-2 border-[#8080809d] rounded-lg">
                            <div className="flex items-center justify-between w-full">
                                <p className="text-2xl">Brand Information</p>
                                <button className="flex items-center justify-center h-8 rounded-md aspect-square bg-bg_highlight" onClick={() => closeModal()} ><X size={22} color="red" /></button>
                            </div>
                            <div className="w-full mt-3 space-y-4">
                                <div className="">
                                    <p className="font-light tracking-wide">Brand Name <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.brandName} onChange={(event) => setNotes({ ...notes, brandName: event.target.value })} placeholder="Enter your brand name" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.brandName ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, brandName: false })} />
                                    {errors?.brandName && <p className="text-sm tracking-wide text-red-500">{errors?.brandName}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Brand Website <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.brandWebsite} onChange={(event) => setNotes({ ...notes, brandWebsite: event.target.value })} placeholder="Enter your brand website url" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.brandWebsite ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, brandWebsite: false })} />
                                    {errors?.brandWebsite && <p className="text-sm tracking-wide text-red-500">{errors?.brandWebsite}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Business Category <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.websiteCategory} onChange={(event) => setNotes({ ...notes, websiteCategory: event.target.value })} placeholder="Enter your category" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.websiteCategory ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, websiteCategory: false })} />
                                    {errors?.websiteCategory && <p className="text-sm tracking-wide text-red-500">{errors?.websiteCategory}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Spoke Person Name <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.spokePersonName} onChange={(event) => setNotes({ ...notes, spokePersonName: event.target.value })} placeholder="Enter your brand spokeperson name" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.spokePersonName ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, spokePersonName: false })} />
                                    {errors?.spokePersonName && <p className="text-sm tracking-wide text-red-500">{errors?.spokePersonName}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Spoke Person Email id <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.spokePersoneMailId} onChange={(event) => setNotes({ ...notes, spokePersoneMailId: event.target.value })} placeholder="Enter your brand spokeperson email id" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.contactMailId ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, contactMailId: false })} />
                                    {errors?.contactMailId && <p className="text-sm tracking-wide text-red-500">{errors?.contactMailId}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Contact Number <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.contactNumber} onChange={(event) => setNotes({ ...notes, contactNumber: event.target.value })} placeholder="Enter your spokeperson number" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.contactNumber ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, contactNumber: false })} />
                                    {errors?.contactNumber && <p className="text-sm tracking-wide text-red-500">{errors?.contactNumber}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Social Media Link <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.socialMediaLink} onChange={(event) => setNotes({ ...notes, socialMediaLink: event.target.value })} placeholder="Enter your social media link" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.socialMediaLink ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, socialMediaLink: false })} />
                                    {errors?.socialMediaLink && <p className="text-sm tracking-wide text-red-500">{errors?.socialMediaLink}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Brand Logo Link <span className="text-red-500">*</span></p>
                                    <input type="text" value={notes?.brandLogoLink} onChange={(event) => setNotes({ ...notes, brandLogoLink: event.target.value })} placeholder="Enter your brand logo link" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border ${errors?.brandLogoLink ? " border-red-500" : "border-[#80808079]"}`} onBlur={() => setErrors({ ...errors, brandLogoLink: false })} />
                                    {errors?.brandLogoLink && <p className="text-sm tracking-wide text-red-500">{errors?.brandLogoLink}</p>}
                                </div>
                                <div className="">
                                    <p className="font-light tracking-wide">Reference Videos ( If any)</p>
                                    <input type="text" value={notes?.referenceVideoLink} onChange={(event) => setNotes({ ...notes, referenceVideoLink: event.target.value })} placeholder="Enter any reference urls" className={`px-3 text-sm appearance-none outline-none w-full h-10 rounded-md bg-bg_highlight border border-[#80808079]`} />
                                </div>
                                <div className="pt-4" onClick={() => updateUGCOrder()}>
                                    <button className="py-2 px-8 h-10 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modalcard>
                </>
            )}
            {params.get('showModal') === 'true' && (
                <>
                    <Modalcard close={closeModal}>
                        <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                            <div className="w-full h-full overflow-y-auto rounded snap-y snap-mandatory scrollbar" id="zero-scrollbar">
                                <style>
                                    {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
                                </style>
                                <>
                                    {activePlaylist?.map((video, index) => (
                                        <PlaylistFeed data={video} key={index} keyid={`playlist${index}`} displayscroll={true} />
                                    ))}
                                </>
                            </div>
                        </div>
                    </Modalcard>
                </>
            )}
            <div className="h-16 w-full flex items-center justify-between px-6 shadow-md sticky top-0 z-[1000] bg-[#101010]">
                <div className="">
                    <Link to="/">
                        <img
                            loading="lazy"
                            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
                            alt="logo"
                            className="h-[40px] md:h-[62px] w-auto"
                        />
                    </Link>
                </div>
                <div className="">
                    {params.get('success') ? null :
                        <button
                            onClick={() => scrollToPlans()}
                            className="py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                            Order now
                        </button>
                    }
                </div>
            </div>
            {params.get('success') ?
                <div className="max-w-[800px] mx-auto flex-1 h-[calc(100vh-64px)] flex items-center">
                    <div className="lg:col-span-2 w-full  bg-[#171717] rounded-xl p-5 py-10 flex flex-col items-center justify-center">
                        <p className="text-3xl font-medium tracking-wide text-center text-white">🎉 Payment Successfull</p>
                        <p className="mt-2 tracking-wide text-center text-white">Our team will get in touch with you soon.</p>
                        <p className="mt-3 text-xs tracking-wide text-center text-white">*For any queries mail us on connect@myrevue.app</p>
                    </div>
                </div>
                :
                <>
                    <div className="p-5 md:p-10 w-full md:mt-14 max-w-[1300px] mx-auto">
                        <div className="w-full gap-5 md:flex">
                            <div className="flex-1">
                                <p className="text-[30px] md:text-[52px] font-semibold leading-none mb-5 md:mb-9">Quick UGC Solutions</p>
                                <p className="max-w-[500px] tracking-wide text-white text-opacity-80 mb-5">
                                    Ordering UGC is a BREEZE with MyRevue, whether you want 20 videos or just one.
                                </p>
                                <p className="max-w-[500px] tracking-wide text-white text-opacity-80 mb-[15px] md:mb-[47px]">
                                    Select the video pack that fits your business, tell us your needs, and we'll create your video just the way you want it.
                                </p>
                                <button
                                    onClick={() => scrollToPlans()}
                                    className="mb-[20px] py-2 px-8 w-full md:w-fit rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                    Order now
                                </button>
                                {/* <p className="mb-[24px] hidden md:block">
                                    <span className="text-[24px] align-middle">80+</span>   Brands and Agencies Partner with MyRevue
                                </p> */}
                                <div className="mb-[24px] hidden md:block">
                                    <p className="text-[20px] align-middle">
                                        10Million+ Views | 15000+ Orders | 3X Increased Engagement
                                    </p>
                                </div>
                            </div>
                            <div className="w-full lg:w-[556px]">
                                <div className="grid w-full grid-cols-3 gap-5">
                                    {topArray?.map((vid, index) => (
                                        <div
                                            className="w-full aspect-[9/16] bg-[#121212] rounded-md relative"
                                            onClick={() => {
                                                // setplaylisttype("reviews");
                                                setactivePlaylist(topArray);
                                                // setActiveIndex(index);
                                                // setshowModal(true);
                                                history.push("/ugc?showModal=true")
                                                setTimeout(() => {
                                                    const videoid = document.getElementById(`playlist${index}`);
                                                    videoid.scrollIntoView();
                                                }, 100);
                                            }}
                                            key={index}>
                                            <div className="absolute flex items-center justify-center w-full h-full">
                                                <div className="flex items-center justify-center border-2 border-white rounded-full w-14 aspect-square">
                                                    <Play size={22} color="white" weight="fill" />
                                                </div>
                                            </div>
                                            <img loading="lazy" src={vid?.poster} alt="" className="object-cover w-full h-full rounded-md" />
                                        </div>
                                    ))}
                                </div>
                                <p className="mt-[24px] md:hidden block  text-center ">10Million+ Views | 15000+ Orders | 3X Increased Engagement</p>

                            </div>
                        </div>
                    </div>
                    <div className="bg-[#171717] pt-[40px] md:pt-[100px] p-4 md:p-10">
                        <div className="">
                            <div className="flex flex-col justify-center w-full mx-auto lg:max-w-[60%]">
                                <p className="text-left font-semibold md:text-center mb-[25px] leading-snug tracking-wide text-[24px] md:text-[40px]">
                                    Boost Engagement & ROI with UGC Ads
                                </p>
                                <p className="text-left md:text-center tracking-wide mb-[20px]">
                                    Research indicates that ads featuring faces outperform those without. Leverage UGC to craft compelling marketing campaigns that capture attention, evoke emotion, and establish trust.
                                </p>
                            </div>
                            <div className="mt-10 ">
                                <div className="flex flex-col-reverse items-center justify-center h-full lg:flex-row-reverse ">
                                    <div className="flex flex-col justify-between gap-5 lg:gap-12">
                                        <div className="flex items-center gap-3 mt-10 lg:mt-0">
                                            <div className="w-48 h-[1px] bg-white hidden lg:block"></div>
                                            <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                            <div className="text-sm font-medium">Most Effective Way To Represent Product</div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="w-44 h-[1px] bg-white hidden lg:block"></div>
                                            <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                            <div className="text-sm font-medium">Minimize the Language Barrier</div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="w-40 h-[1px] bg-white hidden lg:block"></div>
                                            <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                            <div className="text-sm font-medium">Best Way To Get Engaged With Target Consumers</div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="w-36 h-[1px] bg-white hidden lg:block"></div>
                                            <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                            <div className="text-sm font-medium">Increases the Conversion or spread Awareness Rapidly</div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="w-48 h-[1px] bg-white hidden lg:block"></div>
                                            <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                            <div className="text-sm font-medium">Most Effective Way To Reshare</div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-[380px] aspect-[9/14]  relative bg-cover bg-center bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2Fugc%20square.webp?alt=media&token=a44e9f8d-c16c-4846-8875-a5780d23dedc')]  rounded-md p-1">
                                        <div className="absolute -left-3 top-[55px] md:top-10  md:-left-10 z-[100] text-left">
                                            <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2F2-1.svg?alt=media&token=7d4fb1a9-e217-4665-8ea2-9ad2964b67bc" alt="" className="block text-left mb-[17px] align-top" />
                                            <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2F2-2.svg?alt=media&token=83985327-8bb8-4fe7-9e38-131aedbd302a" alt="" className="block text-left mb-[17px] align-top" />
                                            <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2F2-3.svg?alt=media&token=148aa716-c328-473e-aabe-57067a7e86e2" alt="" className="block text-left mb-[17px] align-top" />
                                        </div>
                                        <video playsInline
                                            className="object-cover w-full h-full rounded"
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            preload="auto">
                                            <source
                                                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4D5ebca875b24ba2a87b7463546eeed6.mp4?alt=media&token=3672d45e-8251-4eb6-b8af-908ea2ebbb85"
                                                type="video/mp4"
                                            />
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 lg:px-10 bg-[#101010] pt-[20px] mb-[20px] pb-[30px] lg:pb-[60px]" >
                        <div className="flex items-center justify-center">
                            <p className="text-3xl font-semibold tracking-wide">SOME OF OUR WORK</p>
                        </div>
                        <UgcOrderDemo array={ApparelArray} setactivePlaylist={setactivePlaylist} hashTag={"#APPAREL"} />
                        <UgcOrderDemo array={JewelleryArray} setactivePlaylist={setactivePlaylist} hashTag={"#Jewellery"} />
                        <UgcOrderDemo array={LifestyleArray} setactivePlaylist={setactivePlaylist} hashTag={"#Lifestyle"} />
                        <UgcOrderDemo array={FoodArray} setactivePlaylist={setactivePlaylist} hashTag={"#Food"} />
                        <UgcOrderDemo array={HairCareArray} setactivePlaylist={setactivePlaylist} hashTag={"#HairCare"} />
                        <UgcOrderDemo array={SkinCareArray} setactivePlaylist={setactivePlaylist} hashTag={"#SkinCare"} />
                        <UgcOrderDemo array={InformativeArray} setactivePlaylist={setactivePlaylist} hashTag={"#InformativeGREENSCREEN"} />
                        <UgcOrderDemo array={ProductShootArray} setactivePlaylist={setactivePlaylist} hashTag={"#ProductShot"} />
                        <div className="bg-[#101010]">
                            <div className="bg-[#171717] mt-5 w-full  rounded-md">
                                <div className="flex items-center justify-center h-12 pt-5">
                                    <p className="text-2xl font-semibold text-center uppercase">#CommercialAds</p>
                                </div>
                                <div className=" max-w-[1280px] mx-auto grid w-full gap-10 lg:grid-cols-2 px-5 pt-5 pb-10 lg:px-10">
                                    <video playsInline muted controls controlsList="nodownload noplaybackrate" disablePictureInPicture src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fcommercial%2FCommercial%20one.mp4?alt=media&token=b70d0c4b-2834-45cf-bd11-8b018cae4175" className="w-full aspect-video"></video>
                                    <video playsInline muted controls controlsList="nodownload noplaybackrate" disablePictureInPicture src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fcommercial%2Fcommercial%20two.mp4?alt=media&token=5e7ae957-1c5f-46bf-a345-f185d87b5e92" className="w-full aspect-video"></video>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bg-[#171717]">
                        <div className="pt-[35px] lg:pt-[60px] mb-[20px] pb-[40px] lg:pb-[60px] px-5" id="pricing-plans">
                            <p className="text-[32px] md:text-[58px] mb-[40px] text-center  font-semibold">Select Your UGC Video Package</p>
                            {/* <p className="text-[32px] md:text-[58px] mb-[40px] text-left block md:hidden font-semibold">UGC packs designed to drive results</p> */}
                            <div className="grid gap-5 mx-auto md:grid-cols-2 lg:grid-cols-4" >
                                <div className="p-3 bg-[#101010] rounded-md shadow-md">
                                    <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%203.png?alt=media&token=f13b7684-60a4-40b3-a1b8-50aff16892ab')] bg-cover bg-center">
                                        {/* <p className="text-[32px] font-medium">Try Me</p>
                                        <p className="text-sm tracking-wide">To get your first trust building video with MyRevue</p> */}
                                        <p className="text-[38px] font-semibold text-center">₹8000 + GST</p>
                                        <p className="text-[24px] font-medium text-center">Try Me Package</p>
                                        {/* <p className="text-sm tracking-wide">For individuals and teams pushing to succeed with limited resources</p> */}
                                    </div>
                                    <div className="px-[30px] py-[36px]">
                                        <div className="pb-[15px]">
                                            {/* <p className="mb-[10px]">Up to</p> */}
                                            <p className="text-[78px] font-medium">
                                                1 <span className="text-lg font-normal tracking-wide align-baseline">video</span>
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                            </p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-[30px] font-medium flex items-center flex-wrap">
                                                ₹8000 + GST
                                                {/* <span className="text-base align-middle pl-2 line-through font-light text-[#9747FF]">₹1,36,500</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">57%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span> */}
                                            </p>
                                            <p className="opacity-0">
                                                .
                                            </p>
                                        </div>
                                        <div className="mt-[50px]">
                                            <button
                                                onClick={() => generatePaymentLink(1)}
                                                className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                Order
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3 bg-[#101010] rounded-md shadow-md">
                                    <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%202.png?alt=media&token=359cfb7a-40f4-40bf-baa9-23fc9c6f5adb')] bg-cover bg-center">
                                        <p className="text-[38px] font-semibold text-center">₹5000 + GST</p>
                                        <p className="text-[24px] font-medium text-center">Launch Package</p>
                                        {/* <p className="text-sm tracking-wide">For individuals and teams pushing to succeed with limited resources</p> */}
                                    </div>
                                    <div className="px-[30px] py-[36px]">
                                        <div className="pb-[15px]">
                                            {/* <p className="mb-[10px]">Up to</p> */}
                                            <p className="text-[78px] font-medium">
                                                5 <span className="text-lg font-normal tracking-wide align-baseline">videos</span>
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                            </p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-[30px] font-medium">₹25000 + GST</p>
                                            <p className="">
                                                <span className="text-base align-middle line-through font-light text-[#9747FF]">₹40,000</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">37.5%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="mt-[50px]">
                                            <button
                                                onClick={() => generatePaymentLink(2)}
                                                className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                Order
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3 bg-[#101010] rounded-md shadow-md">
                                    <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%202.png?alt=media&token=359cfb7a-40f4-40bf-baa9-23fc9c6f5adb')] bg-cover bg-center">
                                        {/* <p className="text-[32px] font-medium">Grow</p>
                                        <p className="text-sm tracking-wide">For aspiring professionals and businesses aiming to elevate their ads to the next level</p>
                             */}
                                        <p className="text-[38px] font-semibold text-center">₹4000 + GST</p>
                                        <p className="text-[24px] font-medium text-center">Grow Package</p>
                                    </div>
                                    <div className="px-[30px] py-[36px]">
                                        <div className="pb-[15px]">
                                            {/* <p className="mb-[10px]">Up to</p> */}
                                            <p className="text-[78px] font-medium">
                                                10 <span className="text-lg font-normal tracking-wide align-baseline">videos</span>
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> 30-day refund policy
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> 2 free edit requests per video
                                            </p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                            <p className="tracking-wide mb-[7px] opacity-0">.</p>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-[30px] font-medium flex items-center flex-wrap">
                                                ₹40,000 + GST
                                                {/* <span className="text-base align-middle pl-2 line-through font-light text-[#9747FF]">₹52,500</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">42%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span> */}
                                            </p>
                                            <p className="">
                                                <span className="text-base align-middle line-through font-light text-[#9747FF]">₹80,000</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">50%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="mt-[50px]">
                                            <button
                                                onClick={() => generatePaymentLink(3)}
                                                className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                Order
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3 bg-[#101010] rounded-md shadow-md">
                                    <div className="px-[15px] py-[30px] rounded bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fugc%20pattern%203.png?alt=media&token=f13b7684-60a4-40b3-a1b8-50aff16892ab')] bg-cover bg-center">
                                        {/* <p className="text-[32px] font-medium">Scale</p> */}
                                        {/* <p className="text-sm tracking-wide">For seasoned marketers and agencies on the hunt for their next big triumph</p> */}
                                        <p className="text-[38px] font-semibold text-center">₹3500 + GST</p>
                                        <p className="text-[24px] font-medium text-center">Scale Package</p>
                                    </div>
                                    <div className="px-[30px] py-[36px]">
                                        <div className="pb-[15px]">
                                            {/* <p className="mb-[10px]">Up to</p> */}
                                            <p className="text-[78px] font-medium">
                                                20+ <span className="text-lg font-normal tracking-wide align-baseline">videos</span>
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Get lifetime copyright free access
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Video length upto 60 seconds
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> 30-day refund policy
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> 2 free edit requests per video
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Priority support
                                            </p>
                                            <p className="tracking-wide mb-[7px]">
                                                <Check size={22} className="inline-block align-middle" /> Whitelabeling
                                            </p>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-[30px] font-medium flex items-center flex-wrap">
                                                ₹70,000 + GST
                                                {/* <span className="text-base align-middle pl-2 line-through font-light text-[#9747FF]">₹1,36,500</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">57%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span> */}
                                            </p>
                                            <p className="">
                                                <span className="text-base align-middle line-through font-light text-[#9747FF]">₹1,60,000</span>
                                                <span className="">
                                                    <span className="text-base align-middle pl-2 text-[#9747FF] font-light">77.14%</span>
                                                    <span className="pl-1 text-base font-light text-white align-middle text-opacity-80">discount</span>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="mt-[50px]">
                                            <button
                                                onClick={() => generatePaymentLink(4)}
                                                className="py-3 px-8 w-full rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                                Order
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:max-w-[50%] flex flex-col items-center mt-10 lg:mt-20 mx-auto space-y-2">
                                <p className="text-2xl font-semibold text-center lg:text-3xl">Can't find what you are looking for?</p>
                                <p className="text-sm font-medium text-center lg:text-base">Let us know what you need - we're happy to help!</p>
                                <Link to="/#contact">
                                    <button className="mt-1 py-3 px-12 w-fit rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">Talk To Us</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className=" bg-[#101010] py-10">
                        <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                            <div className=" md:max-w-[80%] mx-auto">
                                <p className="text-[32px] leading-tight tracking-wide font-semibold text-left md:text-center">
                                    Seamless Video Ads from Start to Finish, Enhanced with Performance Marketing for Guaranteed Results
                                </p>
                            </div>
                            <div className="grid mt-10 md:grid-cols-2 md:gap-x-20 gap-y-10">
                                <div className="w-full space-y-5">
                                    <div className="">
                                        <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                            <Target size={22} color="#121212" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="text-[18px] text-white tracking-wide">Merge the Precision of Performance Marketing with Short Video Ads</p>
                                    </div>
                                    <div className="">
                                        <p className="text-[15px] text-white font-light tracking-wide">Scale your business seamlessly with UGC Videos & Performance Marketing—short, impactful ads paired with expert strategies, delivered by India's Best Team for the results you desire.</p>
                                    </div>
                                </div>
                                <div className="w-full space-y-5">
                                    <div className="">
                                        <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                            <ShuffleAngular size={22} color="#121212" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="text-[18px] text-white tracking-wide">Customize According to Agency Specifications with White Labeling</p>
                                    </div>
                                    <div className="">
                                        <p className="text-[15px] text-white font-light tracking-wide">Not limited to brands, agencies can activate white labeling and other tailored features. Share your agency-specific requirements or preferences with us, and we'll bring your vision to life.</p>
                                    </div>
                                </div>
                                <div className="w-full space-y-5">
                                    <div className="">
                                        <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                            <RocketLaunch size={22} color="#121212" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="text-[18px] text-white tracking-wide">Efficiently Enhance Ads on Our All-in-One Platform</p>
                                    </div>
                                    <div className="">
                                        <p className="text-[15px] text-white font-light tracking-wide">Streamline creative management and content creation with our user-friendly platform. Stay organized and on course, moving seamlessly from concept to ad in a quick, straightforward, and efficient manner.</p>
                                    </div>
                                </div>
                                <div className="w-full space-y-5">
                                    <div className="">
                                        <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                            <Wallet size={22} color="#121212" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <p className="text-[18px] text-white tracking-wide">Access Budget-Friendly Video Solutions with Our In-House Creators & Editing Services.</p>
                                    </div>
                                    <div className="">
                                        <p className="text-[15px] text-white font-light tracking-wide">Effortlessly enhance your advertising with our ad ordering system. Our in-house editors guarantee polished, high-quality ads for a professional look in no time. Explore user-generated content tailored to your target demographic and aligned with your vision from our diverse pool of talented creators.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#171717] pt-[40px] md:pt-[100px] p-5 md:p-10">
                        <div className="md:max-w-[60%] mx-auto">
                            <p className="text-center mb-[25px] leading-snug tracking-wide text-[32px] md:text-[58px] font-semibold">Tap into a network of over 5,000 verified UGC creators</p>
                        </div>
                        <div className="overflow-hidden">
                            <ReactVisibilitySensor partialVisibility once>
                                {({ isVisible }) => (
                                    <div className={`mt-10 ${isVisible ? "slide-to-left" : ""}`}>
                                        <Swiper
                                            spaceBetween={25}
                                            // slidesPerView={1}
                                            // onSlideChange={() => console.log("slide change")}
                                            // onSwiper={(swiper) => console.log(swiper)}
                                            ref={swiperRef}
                                            breakpoints={{
                                                // when window width is >= 640px
                                                640: {
                                                    width: 640,
                                                    slidesPerView: 1,
                                                },
                                                // when window width is >= 768px
                                                768: {
                                                    width: 768,
                                                    slidesPerView: 2,
                                                },
                                                964: {
                                                    width: 964,
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            navigation={{
                                                nextEl: ".next-button",
                                                prevEl: ".prev-button",
                                            }}
                                            onBeforeInit={(swiper) => {
                                                swiperRef.current = swiper;
                                            }}>
                                            {flicksArray.map((flick, index) => (
                                                <SwiperSlide
                                                    key={index}
                                                    className="relative cursor-pointer"
                                                    onClick={() => {
                                                        // setplaylisttype("reviews");
                                                        setactivePlaylist(flicksArray);
                                                        // setActiveIndex(index);
                                                        history.push("/ugc?showModal=true")
                                                        // setshowModal(true);
                                                        setTimeout(() => {
                                                            const videoid = document.getElementById(`playlist${index}`);
                                                            videoid.scrollIntoView();
                                                        }, 100);
                                                    }}>
                                                    <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                        <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                                            <Play size={34} color="white" weight="fill" />
                                                        </div>
                                                    </div>
                                                    <img loading="lazy" src={flick?.poster} alt="" className="w-full aspect-[9/16] object-cover rounded-xl" />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                )}
                            </ReactVisibilitySensor>
                            <div className="flex justify-center pt-10">
                                <button
                                    onClick={() => scrollToPlans()}
                                    className="mb-[50px]  py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                    Order now
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#101010] pt-[40px] md:pt-[70px] p-5 md:p-10">
                        <div className="max-w-[1248px] bg-[#171717] p-5 shadow-lg rounded-md mx-auto">
                            <p className="text-[24px] mb-[20px]">FAQ</p>
                            <div className="accordion">
                                <AccordionItem title="Why Choose MyRevue Elite Packages?">
                                    MyRevue Elite Packs offer a convenient and budget-friendly means to access a diverse range of video options. Whether you're in need
                                    of eCommerce videos, Instagram Reels, YouTube Shorts, TikTok content, or personalized Shopify videos, our packs come with discounted
                                    prices and added value. Opting for packs allows you to acquire more videos at a lower cost, enabling you to grow your content
                                    library and effectively captivate your audience.{" "}
                                </AccordionItem>
                                <AccordionItem title="How do packs work?">
                                    After you've placed an order, the MyRevue Elite content team will reach out to you to collaborate on designing the content.
                                </AccordionItem>
                                <AccordionItem title="What packs can be used for?">
                                    Credit packs offer versatility for use in any order on MyRevue Elite, excluding the acquisition of additional packs. Conversely, our
                                    custom packs are tailored to address specific video types, durations, and extra services as defined within the chosen pack
                                    structure.
                                </AccordionItem>
                                <AccordionItem title="Do packs expire?">
                                    Credit packs come with an extended validity period of 45 days, commencing from the date of purchase. In contrast, credits for all
                                    other packs are valid for 15 days, starting from the date of purchase.
                                </AccordionItem>
                                <AccordionItem title="Is it refundable?">Yes</AccordionItem>
                                <AccordionItem title="Can I discuss my needs with MyRevue Elite representative?">
                                    Feel free to reach out to us at connect@myrevue.app, and we'll assist you by sharing our previous experiences with similar
                                    companies, analyzing your specific situation, and devising the optimal solution for your needs.
                                </AccordionItem>
                            </div>
                        </div>
                    </div>
                    <NotloginfooterNew />
                </>
            }
        </div>
    );
}
