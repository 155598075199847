import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import { showSuccess, showWarning } from "../../components/toast/toast";
import { wait } from "@testing-library/user-event/dist/utils";

export default function ViewPost() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [{ user }, dispatch] = useStateValue();
  const [postStatus, setStatus] = useState({});

  useEffect(() => {
    getDoc(doc(db, "socialPosts", id)).then((r) => {
      var data = r.data();
      var platforms = [];
      user.social?.facebook_pages?.forEach((page) => {
        if (data.platforms.includes(page.name)) platforms.push(page);
      });
      if (data.platforms.includes("instagram")) {
        data.instagram = user.social.instagram;
      }
      data.facebook_pages = platforms;
      setStatus(data.status ?? {});
      setPost(data);
    });
  }, []);

  // status
  // 11 - executing function
  //

  const publishPage = async (pageId) => {
    var tempStatus = { ...postStatus };
    if (tempStatus[pageId]?.status === 11) {
      showWarning("Publishing please wait for a while...");
      return;
    }

    if (tempStatus[pageId]?.video_id) {
      showSuccess("Post already published");
      return;
    }

    if (!tempStatus[pageId]) {
      tempStatus[pageId] = { status: 11 };
    } else {
      tempStatus[pageId].status = 11;
    }

    setStatus(tempStatus);

    try {
      const params = new URLSearchParams({
        file_url: post.reviewUrl,
        media_type: "REELS",
        description: post.caption,
        access_token: user.social.facebook_pages.find((f) => f.id === pageId)
          .access_token,
      });

      var response = await axios.post(
        `https://graph.facebook.com/v19.0/${pageId}/videos?` + params
      );
      if (response.status !== 200 || !response.data.id) {
        throw new Error(response.data);
      }

      tempStatus[pageId].video_id = response.data.id;
      tempStatus[pageId].published = true;
      tempStatus[pageId].status = 10;
    } catch (error) {
      console.log("ERROR NOT GOOD");
      tempStatus[pageId].status = 0;
      showWarning(error.message);
    } finally {
      updateDoc(doc(db, "socialPosts", id), { status: tempStatus });
      setStatus({ ...tempStatus });
    }
  };

  const publishOnInstagram = async () => {
    var tempStatus = { ...postStatus };
    if (tempStatus[post.instagram.id]?.status === 11) {
      showWarning("Publishing please wait for a while...");
      return;
    }

    if (tempStatus[post.instagram.id]?.media_id) {
      showSuccess("Post already published");
      return;
    }

    if (!tempStatus[post.instagram.id]) {
      tempStatus[post.instagram.id] = { status: 11 };
    } else {
      tempStatus[post.instagram.id].status = 11;
    }
    setStatus(tempStatus);
    try {
      // step 1 out of 2 create media container
      console.log(tempStatus);
      var container_id = tempStatus[post.instagram.id].container_id ?? null;
      var media_id = tempStatus[post.instagram.id].media_id ?? null;

      if (!container_id) {
        console.log("------CALLING CONTAINER ID---------");
        var params = new URLSearchParams({
          media_type: "REELS",
          video_url: post.reviewUrl,
          caption: post.caption,
          access_token: user.social.facebook.access_token,
        }).toString();
        var url =
          `https://graph.facebook.com/v19.0/${post.instagram.business_account}/media?` +
          params;
        var response = await axios.post(url);
        console.log(response);
        container_id = response.data.id;
        tempStatus[post.instagram.id].container_id = container_id;
        tempStatus[post.instagram.id].text = "Ready To Publish";
        tempStatus[post.instagram.id].text = "Ready To Publish";
      }

      // step 2 out of 2 publish}

      if (!media_id) {
        console.log("------CALLING MEDIA ID---------");

        console.log(typeof container_id);
        params = new URLSearchParams({
          creation_id: container_id,
          access_token: user.social.facebook.access_token,
        }).toString();

        await wait(5000);

        url =
          `https://graph.facebook.com/v19.0/${post.instagram.business_account}/media_publish?` +
          params;
        console.log(url);
        var response2 = await axios.post(url);
        if (response2.status !== 200) {
          throw new Error(response2.data.error.error_user_msg);
        }
        tempStatus[post.instagram.id].media_id = response2.data.id;
        tempStatus[post.instagram.id].text = "Published";
        tempStatus[post.instagram.id].published = true;
        tempStatus[post.instagram.id].status = 10;
      }
    } catch (e) {
      tempStatus[post.instagram.id].status = 0;
      showWarning(e.message);
    } finally {
      setStatus({ ...tempStatus });
      updateDoc(doc(db, "socialPosts", id), { status: tempStatus });
    }
  };
  if (!post)
    return (
      <div className="relative flex flex-col items-center justify-center w-full h-screen overflow-y-auto">
        <p className="">Loading....</p>
      </div>
    );
  else
    return (
      <div className="relative w-full h-screen overflow-y-auto">
        <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
          <p className="text-lg font-light tracking-wide">Post</p>
        </div>
        <div className="px-10 py-5">
          <div className="w-full px-5 mt-5 rounded-md bg-bg_highlight">
            {post.facebook_pages.map((page) => (
              <div
                key={page.id}
                className="flex items-center justify-between border-b border-[#8080801f] py-5"
              >
                <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                  <img
                    src={page.picture.data.url}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                  <p className="text-sm font-normal">{page.name}</p>
                </div>
                <div className="flex-1 text-sm">
                  {postStatus[page.id]?.published ? (
                    <button className="px-5 py-2 text-sm rounded-full transform duration-150 bg-green-600 hover:bg-green-500">
                      Published
                    </button>
                  ) : (
                    <button
                      onClick={() => publishPage(page.id)}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      {postStatus[page.id]?.status === 11 ? (
                        <i className="fas fa-spinner animate-spin"></i>
                      ) : (
                        "Publish"
                      )}
                    </button>
                  )}
                </div>
              </div>
            ))}
            {post.platforms.includes("instagram") && (
              <div className="flex items-center justify-between border-b border-[#8080801f] py-5">
                <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                  <p className="text-sm font-normal">Instagram</p>
                </div>
                <div className="flex-1 text-sm">
                  {postStatus[post.instagram.id]?.media_id ? (
                    <button className="px-5 py-2 text-sm duration-150 transform bg-green-600 rounded-full hover:bg-green-500">
                      Published
                    </button>
                  ) : (
                    <button
                      onClick={publishOnInstagram}
                      className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                    >
                      {postStatus[post.instagram?.id]?.status === 11 && (
                        <i className="fas fa-spinner animate-spin"></i>
                      )}{" "}
                      {postStatus[post.instagram?.id]?.text ?? "Start Upload"}
                    </button>
                  )}
                </div>
              </div>
            )}
            {post.platforms.includes("youtube") && (
              <div className="flex items-center justify-between border-b border-[#8080801f] py-5">
                <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Fyoutube.png?alt=media&token=95cdb77b-bd34-4e0f-a5b8-81fad630e030"
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                  <p className="text-sm font-normal">Youtube</p>
                </div>
                <div className="flex-1 text-sm">
                  <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]">
                    Publish
                  </button>
                </div>
              </div>
            )}
            {post.platforms.includes("linkedin") && (
              <div className="flex items-center justify-between border-b border-[#8080801f] py-5">
                <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Flinkedin.png?alt=media&token=1a018f13-6337-41e5-9e89-f8913c6b948c"
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                  <p className="text-sm font-normal">Linkedin</p>
                </div>
                <div className="flex-1 text-sm">
                  <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]">
                    Publish
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-5 mt-5">
            <div className="w-[350px] aspect-[9/16] rounded-md">
              <video playsInline
                src={post.reviewUrl}
                className="object-cover w-full h-full rounded-md"
                controls
                controlsList="nodownload noplaybackrate"
                disablePictureInPicture
              />
            </div>

            <div className="w-full p-3 rounded-md bg-bg_highlight">
              <div className="flex flex-col justify-between flex-1">
                <div className="">
                  <p className="text-sm">Caption</p>
                  <textarea
                    defaultValue={post.caption}
                    className="w-full p-3 h-40 bg-transparent placeholder:text-[#8E8B8B] appearance-none outline-none bg-background rounded-md mt-1"
                    maxLength={280}
                  ></textarea>
                  <p className="text-xs text-right text-[#808080]">0/280</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
