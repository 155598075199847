import React, { useEffect, useRef } from 'react';

const BlobVideoPlayer = ({ videoUrl, width }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        // Fetch the video in chunks (adjust chunk size as needed)
        const chunkSize = 1024 * 1024; // 1 MB
        let offset = 0;

        const fetchNextChunk = async () => {
            try {
                const response = await fetch(`${videoUrl}?range=${offset}-${offset + chunkSize - 1}`);
                const chunk = await response.arrayBuffer();

                // Append the chunk to the video
                if (chunk.byteLength > 0) {
                    const uint8Array = new Uint8Array(chunk);
                    const videoBuffer = new Uint8Array(video.buffered.length > 0 ? video.buffered.end(0) : 0 + uint8Array.length);
                    videoBuffer.set(uint8Array, video.buffered.length > 0 ? video.buffered.end(0) : 0);
                    const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });

                    video.src = URL.createObjectURL(videoBlob);
                    offset += chunkSize;
                }
            } catch (error) {
                console.error('Error fetching video:', error);
            }
        };

        fetchNextChunk();

        // Event listener for when the video reaches the end
        video.addEventListener('ended', () => {
            fetchNextChunk();
        });

        return () => {
            // Clean up event listener
            video.removeEventListener('ended', () => {
                fetchNextChunk();
            });
        };
    }, [videoUrl]);

    return (
        <video playsInline ref={videoRef} controls className='aspect-[9/16] bg-[#131418] rounded-xl' style={{ aspectRatio: "9/16", width: width }} disablePictureInPicture controlsList='nodownload noplaybackrate nofullscreen' />
    );
};

export default BlobVideoPlayer;
