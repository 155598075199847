import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import Modalcard from "../../components/modalcard";
import { useStateValue } from "../../context/StateProvider";
import { ShareFat, Pencil, FileCsv, Copy, DownloadSimple } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import QRCodeStyling from "qr-code-styling";
import { showSuccess, showWarning } from "../../components/toast/toast";

function ViewcampaignFaq() {
  const router = useHistory();
  const [{ user, reviews }, dispatch] = useStateValue();
  const [forms, setforms] = useState([]);
  const [form, setform] = useState({});
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const [copied, setcopied] = useState(false);
  const [forms_search_text, setforms_search_text] = useState("");
  const [tab, settab] = useState(0);
  const [custombutton, setcustombutton] = useState(false);
  const [buttondetails, setbuttondetails] = useState({
    text: null,
    url: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          router.replace("/login");
          return;
        }

        const formDoc = await getDoc(doc(db, "faqforms/" + id));
        if (!formDoc.exists()) {
          router.replace("/engagement/viewfaqs");
          return;
        }
        setform(formDoc.data());
        setbuttondetails({
          text: formDoc?.data()?.button?.text ?? null,
          url: formDoc?.data()?.button?.url ?? null,
        });

        const q = query(collection(db, "faqforms/" + id + "/submissions"));
        const querySnapshot = await getDocs(q);
        setforms(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setloading(false);

        sendAmplitudeData("viewing campaign details", { campaign: id });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, user]);


  const copy = () => {
    navigator?.clipboard?.writeText(
      window.location.host + "/faquserView/" + id
    );
    setcopied(true);
    showSuccess("Link copied successfully")
  };

  function convertToCSV(jsonData) {
    // Function to properly escape CSV values
    const escapeCSVValue = value => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return `"${value}"`;
    };

    const header = Object.keys(jsonData[0]).map(key => escapeCSVValue(key)).join(',');
    const rows = jsonData.map(obj => Object.values(obj).map(val => escapeCSVValue(val)).join(','));
    return header + '\n' + rows.join('\n');
  }
  async function exportcsv() {
    const dataDoc = [];
    if (forms.length === 0) {
      return showWarning("No submission available")
    }
    forms.forEach((data, index) => {
      const questions = form?.questions?.map((question, index) => question?.question ? `Q${index + 1}: ${question?.question}` : "");
      const answers = data?.reviews?.map((question) => question?.reviewUrl ? `https://brand.myrevue.app/embed/faqplayer/${form?.id}/${data?.id}` : "");
      const doc = {
        "Campaign ID": form?.id || "",
        "Campaign Name": form.campaign_name || form?.campaign_info?.campaign_name || "",
        "Campaign Url": `https://brand.myrevue.app/faquserView/${form.id}`,
        "Submission Url": `https://brand.myrevue.app/engagement/view-faqsubmission/${form.id}/${data?.id}`,
        "User Name": data?.user_info?.name || '',
        "User ID": data?.user_info?.id || "",
        "User Email": data?.user_info?.email || "",
        "User Phone": data?.user_info?.phone || "",
        ...questions.reduce((acc, question, i) => {
          acc[question] = answers[i] || '';
          return acc;
        }, {})
      }
      if (form?.prodid) {
        doc["Product Name"] = form?.productname || form?.campaign_info?.productname || "";
        doc["Product Id"] = form?.prodid || form?.campaign_info?.prodid || "";
      }
      dataDoc.push(doc)
    });
    const csvData = convertToCSV(dataDoc)
    const data = `data:,${csvData}`;
    const filename = `${form?.id}.csv`;
    const aTag = document.createElement("a");

    aTag.href = data;
    aTag.download = filename;
    aTag.click();
  }

  async function addCustomButton() {
    if (!buttondetails.text) {
      showWarning("Please fill all details");
      return;
    }
    if (!buttondetails.url) {
      showWarning("Please fill all details");
      return;
    }
    await updateDoc(doc(db, "faqforms/" + id), {
      button: {
        ...buttondetails,
      },
    }).then((res) => {
      console.log("updated");
      // setbuttondetails({
      //   text: null,
      //   url: null
      // });
      setcustombutton(false);
    });
  }
  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    image:
      "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20only%20logo.png?alt=media&token=d1d344f7-ce12-44c1-826d-3963af17ad94",
    data: "https://brand.myrevue.app/faquserview/" + id,
    margin: 20,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: "extra-rounded", color: "#000000" },
    backgroundOptions: { color: "#ffffff" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#ff005c" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#ff005c" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });

  async function downloadQRCode() {
    qrCode.download({
      name: id,
      extension: "png",
    });
  }
  return (
    <div className="min-h-screen text-white scroll-smooth">
      {custombutton === true && (
        <Modalcard close={setcustombutton}>
          <div className="h-96 w-full md:w-[500px] bg-[#ffffff] p-4">
            <div className="">
              <p className="text-lg text-center text-black">
                Custom button details
              </p>
            </div>
            <div className="mt-5">
              <p className="text-black">Button Text</p>
              <input
                type="text"
                onChange={(event) =>
                  setbuttondetails({
                    ...buttondetails,
                    text: event.target.value,
                  })
                }
                defaultValue={buttondetails?.text}
                className="w-full h-10 px-3 text-black border placeholder:text-sm font-work"
                placeholder="ex: Product Name"
              />
            </div>
            <div className="mt-5">
              <p className="text-black">Redirect Link</p>
              <input
                type="text"
                onChange={(event) =>
                  setbuttondetails({
                    ...buttondetails,
                    url: event.target.value,
                  })
                }
                defaultValue={buttondetails?.url}
                className="w-full h-10 px-3 text-black border placeholder:text-sm font-work"
                placeholder="ex: https://brand.myrevue.app"
              />
            </div>

            <div className="mt-5">
              <p className="mb-2 text-black">Button Preview</p>
              <a
                href={buttondetails?.url ?? "https://brand.myrevue.app"}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <button
                  className={`button cursor-pointer w-full h-[50px] bg-[#00000099] rounded-[44px] min-w-[200px] mx-auto text-white flex items-center pl-[16px] pr-[12px] gap-3 border-white border border-opacity-30`}
                >
                  <div className="flex-1 text-left leading-[18px] font-light tracking-wide">
                    <p className="">{buttondetails?.text ?? "Preview"}</p>
                  </div>
                  <div className="flex items-center justify-center font-medium h-9 w-9">
                    <ShareFat size={24} color="white" />
                  </div>
                </button>
              </a>
            </div>
            <div className="mt-5">
              <button
                className="w-full h-10 px-3 text-white bg-black border placeholder:text-sm font-work hover:text-black hover:bg-white"
                onClick={() => addCustomButton()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modalcard>
      )}
      <div className="flex items-center justify-between p-10 ">
        {/* <div className="bg-[#383A3F] h-20  rounded-lg drop-shadow-lg w-full flex-1 flex items-center px-5">
          <div className="flex items-center w-full gap-x-6">
            <p className="font-light tracking-wider">Shareable link:</p>
            <input
              type="text"
              className="flex-1 w-full font-light tracking-wider bg-transparent"
              defaultValue={window?.location?.host + "/faquserview/" + id}
              onFocus={() => copy()}
            />
            {copied === true && (
              <button className="w-32 py-2 bg-green-500 rounded outline-none appearance-none bg-opacity-80">
                ! Copied
              </button>
            )}
          </div>

        </div> */}
        <div className="flex items-center justify-between flex-1 gap-10">
          {form?.productimage && (
            <div className="rounded-md h-28 w-28">
              <img
                src={form?.productimage}
                alt="product_image"
                className="rounded-md"
              />
            </div>
          )}
          <div className="bg-[#3B3D42] h-auto rounded-lg drop-shadow-lg w-full flex-1 flex flex-col justify-center items-center px-5 ml-10 gap-3 py-5">
            <p className="text-center text-[#D9D9D9] tracking-wide">
              Shareable link:
            </p>
            <div className="flex items-center gap-5">
              <div className="items-center bg-[#2E3036] w-fit h-12 text-sm flex gap-10 rounded-full pl-10">
                <p>{window?.location?.host + "/faquserview/" + id}</p>
                <button
                  className="p-2 m-1 rounded-full outline-none appearance-none hover:bg-green-500 bg-opacity-80"
                  onClick={() => copy()}
                >
                  <Copy size={22} color="white" />
                </button>
              </div>
              <button
                className="bg-[#2E3036] hover:bg-blue-500 h-12 text-sm tracking-wide bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none flex items-center gap-3 px-5"
                onClick={() => downloadQRCode()}
              >
                <DownloadSimple size={22} color="white" />
                Download QR Code
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <p className="">Gettings your submissions ready </p>
        </div>
      ) : (
        <div className="flex items-start justify-between px-10">
          <div className="flex-1 w-full mr-10">
            <div className="grid w-full grid-cols-5">
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 0
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(0);
                  setforms_search_text("");
                }}
              >
                All ({forms.length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 1
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(1);
                  setforms_search_text("pending");
                }}
              >
                Pending (
                {
                  forms.filter((val) => {
                    if (val.substatus === "pending" || !val?.substatus) {
                      return val;
                    }
                  }).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 2
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(2);
                  setforms_search_text("In Review");
                }}
              >
                In Review (
                {
                  forms.filter((val) => {
                    if (val.substatus === "In Review") {
                      return val;
                    }
                  }).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 3
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(3);
                  setforms_search_text("published");
                }}
              >
                Published (
                {
                  forms.filter((val) => {
                    if (val.substatus === "published") {
                      return val;
                    }
                  }).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 4
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(4);
                  setforms_search_text("rejected");
                }}
              >
                Rejected (
                {
                  forms.filter((val) => {
                    if (val.substatus === "rejected") {
                      return val;
                    }
                  }).length
                }
                )
              </button>
            </div>
            <div className="flex flex-wrap flex-1 gap-8 mt-10">
              {forms
                .filter((val) => {
                  if (forms_search_text === "") {
                    return val;
                  } else if (
                    val?.substatus
                      ?.toLowerCase()
                      .includes(forms_search_text?.toLowerCase()) ||
                    !val?.substatus
                  ) {
                    return val;
                  }
                })
                .map((doc, index) => (
                  <Link
                    to={"/engagement/view-faqsubmission/" + id + "/" + doc.id}
                    key={index}
                  >
                    <div
                      key={index}
                      className="bg-[#383A3F] aspect-[12/16] w-60  rounded-lg drop-shadow-lg relative flex  justify-center"
                    >
                      <div className="h-full w-full  bg-[#42454B] absolute rounded-lg  shadow-2xl bg-opacity-0">
                        <div className="right-0 float-right m-2 tracking-wide ">
                          {(doc?.substatus === "pending" ||
                            !doc?.substatus) && (
                              <div className="bg-[#dea513]  px-5 py-1 rounded">
                                Pending
                              </div>
                            )}
                          {doc?.substatus === "In Review" && (
                            <div className="px-5 py-1 bg-orange-500 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "rejected" && (
                            <div className="px-5 py-1 bg-red-500 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "published" && (
                            <div className="px-5 bg-green-500 rounded py-">
                              {doc?.substatus}
                            </div>
                          )}
                        </div>
                        <div className="shadow-top bottom-0 absolute rounded-b-lg pb-2 bg-gradient-to-b from-transparent via-[#000000ba] to-[#000000] w-full">
                          <p className="px-1 text-xs font-light tracking-wider">
                            Campaign Name :{" "}
                            {doc?.campaign_info?.campaign_name ??
                              form?.campaign_name}
                          </p>
                          <p className="px-1 text-xs font-light tracking-wider">
                            Reviewer : {doc?.user_info?.name}
                          </p>
                          {/* <p className="px-1 text-xs font-light tracking-wider">
                            Type : {doc?.reviewUrl !== null ? "Video" : "Text"}
                          </p> */}
                        </div>
                      </div>
                      <div className="aspect-[12/16] w-60">
                        {!doc?.thumbnailurl ? (
                          <video playsInline
                            src={doc?.reviews[0]?.reviewUrl}
                            className="object-cover w-full h-full rounded-lg"
                          ></video>
                        ) : (
                          <img
                            src={doc?.thumbnailurl}
                            className="object-cover w-full h-full rounded-lg"
                            alt="thumbnail"
                          ></img>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
          <div className="w-96 bg-[#383A3F] p-5 rounded-md mb-10">
            <div className="flex flex-col items-center justify-center gap-3 pb-5">
              <button
                className="bg-[#2E3036] hover:bg-[#151516] flex items-center gap-8 h-12 rounded-full px-5 w-fit"
                onClick={() => setcustombutton(true)}
              >
                <Pencil size={28} color="#ffffff" />
                {!form?.button ? "Add" : "Edit"} Custom Faq Button
              </button>
              <button
                className="bg-[#438B4E] hover:bg-[#25da40]  flex items-center gap-8 h-12 rounded-full px-5 w-fit"
                onClick={() => exportcsv()}
              >
                <FileCsv size={28} color="#ffffff" />
                Generate CSV
              </button>
            </div>

            <p className="text-center font-light tracking-wider text-lg w-full border-b pb-2 border-[#9b9b9b] mb-5">
              Campaign Details
            </p>
            <p className="font-light tracking-wide">
              Campaign Name : {form.campaign_name}
            </p>
            <p className="font-light tracking-wide">Campaign id : {form.id}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewcampaignFaq;
