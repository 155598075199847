import { CircleNotch } from "@phosphor-icons/react";
import { Suspense, lazy, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loadingdot from "./components/loadingdot";
import { firstRowLogo, secondRowLogo, thirdRowLogo } from "./logo";
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));
const LogoSlider = lazy(() => import("./components/logoSlider"));
export default function GetAVideo() {
  const router = useHistory();
  useEffect(() => {
    var title = `Get A Video | MyRevue - Elite`;
    var metaTitle = `Get A Video | MyRevue - Elite`;
    var metaDescription = `Discover the step-by-step process of MyRevue Elite, a powerful platform that supercharges your pitch with captivating videos. Unleash your potential to make an unforgettable impact like never before. Enter video details, make a secure payment, showcase your products, and effortlessly track your orders. Join now!`;
    var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, MyRevue Elite, captivating videos, pitch, step-by-step process, unleash your potential, make an impact, storytelling, secure payment, product showcase, captivating images, compelling product names, SKUs, track orders, smooth and efficient process.`;

    var ogUrl = `https://brand.myrevue.app/sass`;
    var ogtype = `website`;
    var ogtitle = `Get A Video | MyRevue - Elite`;
    var ogdescription = `Discover the step-by-step process of MyRevue Elite, a powerful platform that supercharges your pitch with captivating videos. Unleash your potential to make an unforgettable impact like never before. Enter video details, make a secure payment, showcase your products, and effortlessly track your orders. Join now!`;
    var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fhomepage.webp?alt=media&token=f2817158-0d3f-4903-9745-ddcea3a195b7`;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/sass`;
    var twittertitle = `Get A Video | MyRevue - Elite`;
    var twitterdescription = `Discover the step-by-step process of MyRevue Elite, a powerful platform that supercharges your pitch with captivating videos. Unleash your potential to make an unforgettable impact like never before. Enter video details, make a secure payment, showcase your products, and effortlessly track your orders. Join now!`;
    var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fhomepage.webp?alt=media&token=f2817158-0d3f-4903-9745-ddcea3a195b7`;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    // document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    // document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, []);

  return (
    <div className="h-screen overflow-y-auto bg-[#101010]">
      <Suspense fallback={<Loadingdot />}>
        <NotLoginHeaderNew />
        <div className="grid px-5 mx-auto md:my-10 lg:grid-cols-2 md:px-10 xl:px-24 2xl:32">
          <div className="">
            <h1 className="text-4xl md:text-[55px] font-semibold leading-tight ">
              Supercharge your pitch with captivating videos on MyRevue Elite.
            </h1>
            <p className="md:text-lg md:max-w-[70%] tracking-wide font-medium text-[#8E8B8B]">
              Join us now to learn the step-by-step process and unleash your
              potential to make an impact like never before!
            </p>
            <div className="flex items-center w-full gap-5 mt-5">
              <button
                className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
              <div className="relative pl-5 md:pl-0">
                <button
                  className="z-10 py-2 font-medium tracking-wide md:px-12"
                  onClick={() => router.push("/signup")}
                >
                  Try it for free
                </button>
                <img
                  loading="lazy"
                  src={
                    require("./assets/icons/icons/hero section arrow.svg")
                      .default
                  }
                  alt=""
                  className="absolute -ml-12 top-2"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center flex-1 w-full mt-24 md:mt-0">
            <img
              loading="lazy"
              src={require("./assets/images/getavideo/hero.svg").default}
              alt=""
              className=""
            />
          </div>
        </div>
        <div className="px-5 mx-auto my-10 md:px-10 md:mt-20">
          <h2 className="text-[24px] lg:text-[32px] text-center font-semibold leading-tight mt-16">
            Trusted by 80+ brands
          </h2>
          <div className="space-y-0 md:space-y-5 ">
            <LogoSlider logos={firstRowLogo} />
            <LogoSlider logos={secondRowLogo} direction="right" />
            <LogoSlider logos={thirdRowLogo} />
          </div>
        </div>
        <div className="bg-[#171717] mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 pb-20 mx-auto lg:px-10 lg:py-20 lg:grid-cols-2">
            <div className={` w-full flex-1 flex flex-col justify-center`}>
              <p className="text-3xl font-semibold leading-tight lg:text-5xl">
                Step 1: Enter Video Details
              </p>
              <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                With each detail you input, from the title to the description
                and tags, your video becomes a masterpiece of storytelling.
              </p>
              <button
                className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
            </div>
            <div className="relative">
              <div className="absolute left-1/2 -translate-x-1/2 -bottom-[9rem] lg:-bottom-48 lg:-left-36 transform scale-50 lg:scale-100 -scale-x-50 ">
                <img
                  src={require("./assets/images/getavideo/arrow.svg").default}
                  alt=""
                />
              </div>
              <div className="w-full aspect-[797/456]">
                <video
                  playsInline
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fgetavideo%2Fgetavideo1.mp4?alt=media&token=897a88f5-f718-4024-9853-6046843ca504&_gl=1*1afi22k*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTY5NDEzMC43NC4xLjE2ODU2OTQzMzUuMC4wLjA."
                  }
                  autoPlay
                  muted
                  loop
                  className="object-cover w-full h-full rounded-lg"
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 mx-auto lg:px-10 lg:py-20 lg:pb-20 lg:grid-cols-2">
            <div className="relative order-2 lg:order-1">
              <div className="absolute -bottom-48 -right-56 ">
                <img
                  src={
                    require("./assets/images/getavideo/arrow-right.svg").default
                  }
                  alt=""
                />
              </div>
              <div className="w-full aspect-[797/456]">
                <video
                  playsInline
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fgetavideo%2Fgetavideo2.mp4?alt=media&token=7ca3804b-d127-4ff7-99b0-ffdad0246b77&_gl=1*g0996j*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTY5NDEzMC43NC4xLjE2ODU2OTQzNTIuMC4wLjA."
                  }
                  autoPlay
                  muted
                  loop
                  className="object-cover w-full h-full rounded-lg"
                ></video>
              </div>
            </div>
            <div
              className={`w-full flex-1 flex flex-col lg:items-end justify-center order-1 lg:order-2`}
            >
              <p className="text-3xl font-semibold leading-tight lg:text-right lg:text-5xl">
                Step 2: Make Secure Payment
              </p>
              <p className="lg:text-right mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                It's time to take the next stride forward. In Step 2, we ensure
                a seamless journey by offering a secure payment process.
              </p>
              <button
                className="py-2 px-12 rounded-full w-fit mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <div className="bg-[#171717] mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 pb-20 mx-auto lg:px-10 lg:py-20 lg:grid-cols-2">
            <div className={`w-full flex-1 flex flex-col justify-center`}>
              <p className="text-3xl font-semibold leading-tight lg:text-5xl">
                Step 3: Enter Product Info
              </p>
              <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg::max-w-[80%]">
                In Step 3, we provide you with the platform to showcase your
                products in all their glory. From captivating images that speak
                a thousand words to compelling product names and SKUs (Stock
                Keeping Units),
              </p>
              <button
                className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
            </div>
            <div className="relative">
              <div className="absolute left-1/2 -translate-x-1/2 -bottom-[9rem] lg:-bottom-48 lg:-left-36 transform scale-50 lg:scale-100 -scale-x-50 ">
                <img
                  src={require("./assets/images/getavideo/arrow.svg").default}
                  alt=""
                />
              </div>
              <div className="w-full aspect-[797/456]">
                <video
                  playsInline
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fgetavideo%2Fgetavideo3.mp4?alt=media&token=9a3f331a-7ee1-4cc6-b2bf-6ebcd627c85b&_gl=1*71wjz1*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTY5NDEzMC43NC4xLjE2ODU2OTQzNzcuMC4wLjA."
                  }
                  autoPlay
                  muted
                  loop
                  className="object-cover w-full h-full rounded-lg"
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 mx-auto lg:px-10 lg:py-20 lg:pb-20 lg:grid-cols-2">
            <div className="relative order-2 lg:order-1">
              <div className="w-full aspect-[797/456]">
                <video
                  playsInline
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fgetavideo%2Fgetavideo4.mp4?alt=media&token=d0474993-8a97-42fc-b85d-f54e1d534c3c&_gl=1*ql07h5*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTY5NDEzMC43NC4xLjE2ODU2OTQzOTguMC4wLjA."
                  }
                  autoPlay
                  muted
                  loop
                  className="object-cover w-full h-full rounded-lg"
                ></video>
              </div>
            </div>
            <div
              className={`w-full flex-1 flex flex-col lg:items-end justify-center order-1 lg:order-2`}
            >
              <p className="text-3xl font-semibold leading-tight lg:text-right lg:text-5xl">
                Final Step: Keep track of your orders
              </p>
              <p className="lg:text-right mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                From the moment your customers place an order, you can
                effortlessly keep track of every detail, ensuring a smooth and
                efficient process.
              </p>
              <button
                className="py-2 px-12 rounded-full w-fit mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]"
                onClick={() => router.push("/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <NotloginfooterNew />
      </Suspense>
    </div>
  );
}
