import { Bell } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../context/StateProvider";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Notification from "../pages/notifications/notification";

function TopHeaderAdmin() {
  const [{ brand, notifications }, dispatch] = useStateValue();
  const [isShowing, setIsShowing] = useState(false);
  const [unread, setUnread] = useState(notifications.length);

  return (
    <div className=" h-10 w-full px-2 flex items-center justify-end bg-bg_highlight">
      <button
        className="relative"
        onClick={() => {
          setIsShowing(!isShowing);
          setUnread(notifications.length);
        }}
      >
        <Bell size={20} color="white" />
        {notifications.length > unread && (
          <i className="bg-red-500 absolute h-2 w-2 rounded-full shadow-white shadow-sm top-0 right-0"></i>
        )}
      </button>
      <Link to="/admin/settings">
        <div className="flex items-center justify-center w-8 h-8 mx-3 bg-black rounded-full">
          <img
            src={
              brand?.imageUrl ??
              "https://ui-avatars.com/api/?name=" + brand?.name
            }
            alt=""
            crossOrigin="anonymous"
            className="w-full h-full rounded-full"
          />
        </div>
      </Link>
      {isShowing && <Notification closeModal={setIsShowing} />}
    </div>
  );
}

export default TopHeaderAdmin;
