import { doc, getDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { db } from '../../firebase';
import { useStateValue } from '../../context/StateProvider';
import GallaryCardDesign from './gallaryCardDesign';
import { Envelope, FacebookLogo, InstagramLogo, LinkedinLogo, MapPin, Phone, TiktokLogo, YoutubeLogo } from '@phosphor-icons/react';
import { EnvelopeOpen } from 'phosphor-react';
import { InlineWidget } from 'react-calendly';
import axios from 'axios';

export default function Preview({ gallery, reviews, contactDetails, pages, navigation, maps, forms, youtube, calender, socialLinks }) {
    const [draggedDiv, setDraggedDiv] = useState(null);
    const mode = "light";

    const handleDragStart = (event, divId) => {
        event.dataTransfer.setData('text/plain', divId);
        setDraggedDiv(divId);
        if (event.target.tagName === 'IFRAME') {
            event.preventDefault();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, targetDivId) => {
        event.preventDefault();
        const container = document.getElementById('thin-scrollbar');
        const divs = container.getElementsByClassName('draggable');
        const targetDiv = document.getElementById(targetDivId);

        const draggedDivIndex = Array.from(divs).findIndex((div) => div.id === draggedDiv);
        const targetDivIndex = Array.from(divs).findIndex((div) => div.id === targetDivId);

        if (draggedDivIndex !== -1 && targetDivIndex !== -1 && draggedDivIndex !== targetDivIndex) {
            if (draggedDivIndex < targetDivIndex) {
                container.insertBefore(targetDiv, divs[draggedDivIndex]);
            } else {
                container.insertBefore(targetDiv, divs[draggedDivIndex].nextSibling);
            }
        }
        setDraggedDiv(null);
    };

    return (

        <div className="flex-1 mx-5 mt-10 mb-10 overflow-x-hidden overflow-y-auto bg-white rounded-lg" id="thin-scrollbar">
            {/* navigation  */}
            {(navigation?.logo || pages.length > 0) &&
                <div className="m-3 bg-black bg-opacity-80 h-[70px] rounded-lg flex justify-between items-center px-5 "
                >
                    <div className="h-12">
                        <img src={navigation?.logo} alt="" className="h-full w-fit" />
                    </div>
                    <div className="flex items-center gap-5">
                        {pages?.map((page, index) => (
                            <a href={page?.url} key={index} rel="noreferrer" target="_blank">
                                <button className="text-[#808080] hover:text-white">{page?.name}</button>
                            </a>
                        ))}
                    </div>
                    <div className="w-20"></div>
                </div>
            }
            {/* navigation  */}
            {/* Hero section  */}
            <div
                className={`pt-10 mx-3 draggable rounded-lg ${navigation?.headerType === "title" ? "" : "h-72 flex flex-col justify-center bg-no-repeat bg-cover"}`}
                style={{ backgroundImage: `url('${navigation?.headerImage}')` }}
                id="div2"
                draggable
                onDragStart={(event) => handleDragStart(event, "div2")}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, "div2")}
            >
                <h1 className={`text-4xl text-center font-medium`} style={{ color: navigation.heroTextColor }}>{gallery.title}</h1>
                <p className={`text-xl text-center mt-2`} style={{ color: navigation.heroTextColor }}>{gallery.description}</p>
            </div>
            {/* Hero section  */}
            {/* Gallery Cards  */}
            <div className="draggable"
                id="div3"
                draggable
                onDragStart={(event) => handleDragStart(event, "div3")}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, "div3")}
            >
                <GallaryCardDesign design={gallery?.design} selected={reviews} mode={mode} />
            </div>
            {/* Gallery Cards  */}
            {/* Calendly  */}
            {calender &&

                <InlineWidget id="div4" className="draggable"
                    draggable
                    onDragStart={(event) => handleDragStart(event, "div4")}
                    onDragOver={handleDragOver}
                    onDrop={(event) => handleDrop(event, "div4")} url={calender} styles={{ height: "100%", width: "100%", marginTop: 20, marginBottom: 20 }} />
            }
            {/* Calendly  */}

            {/* youtube  */}
            {youtube && <div className="w-full max-w-[900px] mx-auto my-5 draggable"
                id="div5"
                draggable
                onDragStart={(event) => handleDragStart(event, "div5")}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, "div5")}
            >
                <iframe className='w-full rounded-lg aspect-video' src={`https://www.youtube.com/embed/${youtube}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>}
            {/* youtube  */}
            {/* forms  */}
            {forms && <div className="draggable"
                id="div6"
                draggable
                onDragStart={(event) => handleDragStart(event, "div6")}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, "div6")}
            >
                <iframe src={`${forms}?embedded=true`} id='thin-scrollbar' title={"googleForms"} className='w-full my-5 aspect-video'></iframe>
            </div>}
            {/* forms  */}
            {/* maps  */}
            {maps && <div className='grid w-full grid-cols-2 px-5 my-5 draggable'
                id="div7"
                draggable
                onDragStart={(event) => handleDragStart(event, "div7")}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, "div7")}
            >
                <div className="flex items-center ">
                    <p className="text-5xl font-semibold text-black">Explore Our Location: Find Us Easily on the Map</p>
                </div>
                <iframe src={maps} title="Google Maps" className=' w-full max-w-[800px] aspect-video rounded-lg' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>}
            {/* maps  */}
            {/* footer  */}
            {contactDetails?.phone || contactDetails?.email || contactDetails?.address || socialLinks ?
                <div className={`${mode === "light" ? "bg-white text-black" : "bg-[#383838] text-white"} border-t w-full mt-5 py-3 px-5 flex items-start justify-between`}>
                    <div className="">
                        {contactDetails?.phone && <div className="flex items-center gap-2">
                            <Phone size={18} color='currentcolor' />
                            {contactDetails?.phone}
                        </div>}
                        {contactDetails?.email && <div className="flex items-center gap-2">
                            <Envelope size={18} color='currentcolor' />
                            {contactDetails?.email}
                        </div>}
                    </div>
                    <div className="flex flex-col items-end">
                        {contactDetails?.address && <div className="flex items-center gap-2">
                            <MapPin size={18} color='currentcolor' />
                            {contactDetails?.address}
                        </div>}
                        <div className="flex items-center ">
                            {socialLinks?.facebook &&
                                <a href={socialLinks?.facebook} rel='noreferrer' target='_blank' className="">
                                    <button className="ml-2">
                                        <FacebookLogo weight='fill' size={26} color='black' />
                                    </button>
                                </a>
                            }
                            {socialLinks?.instagram &&
                                <a href={socialLinks?.instagram} rel='noreferrer' target='_blank' className="">
                                    <button className="ml-2">
                                        <InstagramLogo weight='fill' size={26} color='black' />
                                    </button>
                                </a>
                            }
                            {socialLinks?.linkedin &&
                                <a href={socialLinks?.linkedin} rel='noreferrer' target='_blank' className="">
                                    <button className="ml-2">
                                        <LinkedinLogo weight='fill' size={26} color='black' />
                                    </button>
                                </a>
                            }
                            {socialLinks?.tiktok &&
                                <a href={socialLinks?.tiktok} rel='noreferrer' target='_blank' className="">
                                    <button className="ml-2">
                                        <TiktokLogo weight='fill' size={26} color='black' />
                                    </button>
                                </a>
                            }
                            {socialLinks?.youtube &&
                                <a href={socialLinks?.youtube} rel='noreferrer' target='_blank' className="">
                                    <button className="ml-2">
                                        <YoutubeLogo weight='fill' size={26} color='black' />
                                    </button>
                                </a>
                            }
                        </div>
                    </div>
                </div> : null}
            {/* footer  */}

        </div>
    )
}
