import React from 'react'

export default function ElevateButton({ onClick, children, buttonActiveColor }) {

    return (
        <div className="h-fit">
            <button className="styles__ButtonWrapper-sc-r6e3mb-0 dvYBoD group" onClick={onClick}>
                <div className="button-face elevated " style={{ '--elevate-button-color': buttonActiveColor ?? "#ff005c", }}>
                    <div className="relative flex items-center justify-center w-full h-full gap-5 text-sm font-medium ethzMW">
                        {children}
                    </div>
                </div>
                <div className="button-edge top"></div>
                <div className="button-edge left"></div>
                <div className="button-edge bottom"></div>
                <div className="button-edge right"></div>
            </button>
        </div>
    )
}
