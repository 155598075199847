import React, { useState } from "react";
import { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showWarning } from "../components/toast/toast";

function ProductListByViews() {
  const router = useHistory();

  const [{ user }, dispatch] = useStateValue();
  const [productList, setproductList] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null);

  useEffect(() => {
    async function productListCartAnalytics() {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: user?.clientSecret,
        };

        let reqOptions = {
          url: `https://api.myrevue.app/getVideoListByViews`,
          method: "GET",
          headers: headersList,
        };

        let response = sessionStorage.getItem("getProductListByViews");

        if (!response) {
          response = await axios.request(reqOptions);
          sessionStorage.setItem(
            "getVideoListByViews",
            JSON.stringify(response.data.data)
          );
          setproductList(
            response.data.data.filter((r) => r._id !== "undefined")
          );
        } else {
          setproductList(JSON.parse(response));
        }
      } catch (error) {
        showWarning(error.message);
      }
    }

    productListCartAnalytics();
  }, []);

  return (
    <div className="thin-scrollbar w-full bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
      {currentPlaying ? (
        <div
          onClick={(e) =>
            e.target === e.currentTarget ? setCurrentPlaying(null) : null
          }
          className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-40 "
        >
          <video playsInline
            src={currentPlaying}
            autoPlay
            controls
            className="h-[600px] rounded-md"
          ></video>
        </div>
      ) : null}
      <p className=" bg-[#131418] text-sm tracking-wide border-b border-[#939393] px-5 py-3 mb-3 text-[#939393]">
        Video Analytics
      </p>
      <div className="flex justify-start gap-5 px-5 py-2 text-xs">
        <div className="">
          <p className="">
            {" "}
            Views{" "}
            <span className="text-3xl">
              {productList.reduce((sum, curr) => sum + curr.views, 0)}
            </span>
          </p>
        </div>
        <div className="">
          <p className="">
            Added To Cart{" "}
            <span className="text-3xl">
              {productList.reduce((sum, curr) => sum + curr.addToCart, 0)}
            </span>
          </p>
        </div>
      </div>
      <ul className="overflow-auto h-72 thin-scrollbar">
        {productList?.map((product, index) => (
          <li
            className="flex items-start px-5 py-2 my-3 cursor-pointer gap-x-5 hover:bg-background"
            key={index}
          >
            <div className="relative flex items-center gap-x-3">
              <p className="w-5 text-lg">{index + 1}:</p>
              <img
                onClick={() =>
                  setCurrentPlaying(
                    product?.video?.reviewUrl ?? product?.rev?.reviewUrl
                  )
                }
                src={product?.video?.thumbnail ?? product?.rev?.thumbnail}
                crossOrigin="anonymous"
                alt=""
                className="w-8 rounded"
              />
            </div>

            <div className="grid w-full grid-cols-4 text-center ">
              <div className="">
                <p className="flex-1 text-base tracking-wide text-left">
                  <span className="text-xs ">Add To Cart</span>{" "}
                  <span className="text-xl">{product?.addToCart}</span>
                </p>
                <p className="flex-1 text-base tracking-wide text-left">
                  <span className="text-xs ">Views</span>{" "}
                  <span className="text-xl">{product?.views}</span>
                </p>
              </div>
              {product.lastView ? (
                <p className="text-xs">
                  Last View: <br />
                  {new Date(product.lastView).toLocaleString()}
                </p>
              ) : (
                <p>--</p>
              )}
              {product.lastAddToCart ? (
                <p className="text-xs">
                  Last Add to cart:
                  {new Date(product.lastAddToCart).toLocaleString()}
                </p>
              ) : (
                <p>--</p>
              )}

              <div className="">
                <button
                  style={{ fontStyle: "italic" }}
                  className="px-3 py-2 text-xs font-bold text-pink-600 bg-gray-200 rounded-full min-w-min"
                >
                  #{product?.video?.flickTag ?? product?.rev?.flickTag}
                </button>
              </div>
            </div>
          </li>
        ))}

        {productList?.length === 0 && (
          <li className="flex items-start px-5 py-2 my-3 cursor-pointer gap-x-5 hover:bg-background">
            <p className="flex-1 text-base tracking-wide text-center">
              No content
            </p>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ProductListByViews;
