import React, { useState } from "react";
import { flicksCategory } from "../context/consts";
function SelectVideoCategory({ setUpward }) {
  const [selected, setSelected] = useState(null);
  const [subflick, setSubflick] = useState(null);
  const [selectedSubFLick, setSelectedSubFlick] = useState(null);
  return (
    <div>
      <p className="my-2">
        What is this flicks about{" "}
        <span className="text-gray-400 ">(Choose any one)</span>
      </p>
      <div className="flex flex-wrap gap-1">
        {flicksCategory.map((flicks) => (
          <button
            onClick={() => {
              setSubflick(flicks.subcategory);
              setSelected(flicks.category);
            }}
            className={`${selected === flicks.category ? "bg-[#93A8F4]" : "bg-black"
              } hover:bg-[#93A899] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
            key={flicks.category}
          >
            #{flicks.category}
          </button>
        ))}
      </div>
      {subflick && (
        <div className="">
          <p className="my-2">More specifically...</p>
          <div className="flex flex-wrap gap-1">
            {subflick.map((sf) => (
              <button
                onClick={() => {
                  setSelectedSubFlick(sf);
                  setUpward({
                    flickTag: selected,
                    subFlicksTag: selectedSubFLick,
                  });
                }}
                className={`${selectedSubFLick === sf ? "bg-[#93A8F4]" : "bg-black"
                  } hover:bg-[#93A899] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                key={sf}
              >
                {sf}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectVideoCategory;
