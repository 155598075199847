import { CaretDown } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";
import Modalcard from "../../components/modalcard";
import { useStateValue } from "../../context/StateProvider";
import VideoPlayer from "./videoPlayer";
import { showSuccess, showWarning } from "../../components/toast/toast";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./preview.css";
import { useEffect } from "react";
import InstagramReel from "./preview/instagramReel";
import FacebookReel from "./preview/facebookReel";
import FacebookFeed from "./preview/facebookFeed";
import InstagramFeed from "./preview/instagramFeed";
import axios from "axios";
import { wait } from "@testing-library/user-event/dist/utils";

export default function Createpost() {
  const router = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [{ user, flicks, reviews }, dispatch] = useStateValue();
  const [searchText, setSearchText] = useState("");
  const [review, setReview] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [ispostScheduled, setscheduled] = useState(false);
  const [caption, setCaption] = useState("");
  const [previewChannel, setPreviewChannel] = useState("instagram_reel");
  const [readyToPublish, setReadyToPublish] = useState(false);
  const [post, setPost] = useState(null);
  const [postStatus, setStatus] = useState({});

  let scheduleRef = useRef();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scheduleParam = urlParams.get("schedule");
    if (scheduleParam) {
      setscheduled(true);

      setTimeout(() => {
        if (scheduleRef.current) {
          const dateValue = new Date(scheduleParam);
          dateValue.setMinutes(
            dateValue.getMinutes() - dateValue.getTimezoneOffset()
          );
          const dateISOString = dateValue.toISOString().slice(0, -8);
          scheduleRef.current.value = dateISOString;
        }
      }, 1000);
    }
  }, []);

  async function selectReview(data) {
    setShowModal(false);
    setReview(data);
  }

  function createPost() {
    if (!review) {
      showWarning("Please select Review");
      return;
    } else if (caption.length < 3) {
      showWarning("Please add caption");
      return;
    }
    if (ispostScheduled && !scheduleRef.current?.value) {
      showWarning("Please add Schedule Time");
      return;
    }
    if (platforms.length === 0) {
      showWarning("Please add atlest one platform to publish");
      return;
    }

    const data = {
      reviewUrl: review.reviewUrl,
      caption: caption,
      schedule: {
        ispostScheduled: ispostScheduled,
        time: scheduleRef.current?.value ?? null,
      },
      brand: user.brandId,
      email: user.email,
      platforms: platforms,
      createdAt: Date.now(),
      readyToPublish: true,
    };

    addDoc(collection(db, "socialPosts"), data).then((r) => {
      showSuccess("Post Created - Ready To publish");
      var platforms = [];
      user.social?.facebook_pages?.forEach((page) => {
        if (data.platforms.includes(page.name)) platforms.push(page);
      });
      if (data.platforms.includes("instagram")) {
        data.instagram = user.social.instagram;
      }
      data.facebook_pages = platforms;
      setStatus(data.status ?? {});
      setPost({ ...data, id: r.id });
      setReadyToPublish(true);
    });
  }
  const publishPage = async (pageId) => {
    var tempStatus = { ...postStatus };
    if (tempStatus[pageId]?.status === 11) {
      showWarning("Publishing please wait for a while...");
      return;
    }

    if (tempStatus[pageId]?.video_id) {
      showSuccess("Post already published");
      return;
    }

    if (!tempStatus[pageId]) {
      tempStatus[pageId] = { status: 11 };
    } else {
      tempStatus[pageId].status = 11;
    }

    setStatus(tempStatus);

    try {
      const params = new URLSearchParams({
        file_url: post.reviewUrl,
        media_type: "REELS",
        description: post.caption,
        access_token: user.social.facebook_pages.find((f) => f.id === pageId)
          .access_token,
      });

      var response = await axios.post(
        `https://graph.facebook.com/v19.0/${pageId}/videos?` + params
      );
      if (response.status !== 200 || !response.data.id) {
        throw new Error(response.data);
      }

      tempStatus[pageId].video_id = response.data.id;
      tempStatus[pageId].published = true;
      tempStatus[pageId].status = 10;
    } catch (error) {
      console.log("ERROR NOT GOOD");
      tempStatus[pageId].status = 0;
      showWarning(error.message);
    } finally {
      updateDoc(doc(db, "socialPosts", post.id), { status: tempStatus });
      setStatus({ ...tempStatus });
    }
  };

  const publishOnInstagram = async () => {
    var tempStatus = { ...postStatus };
    if (tempStatus[post.instagram.id]?.status === 11) {
      showWarning("Publishing please wait for a while...");
      return;
    }

    if (tempStatus[post.instagram.id]?.media_id) {
      showSuccess("Post already published");
      return;
    }

    if (!tempStatus[post.instagram.id]) {
      tempStatus[post.instagram.id] = { status: 11 };
    } else {
      tempStatus[post.instagram.id].status = 11;
    }
    setStatus(tempStatus);
    try {
      // step 1 out of 2 create media container
      console.log(tempStatus);
      var container_id = tempStatus[post.instagram.id].container_id ?? null;
      var media_id = tempStatus[post.instagram.id].media_id ?? null;

      if (!container_id) {
        console.log("------CALLING CONTAINER ID---------");
        var params = new URLSearchParams({
          media_type: "REELS",
          video_url: post.reviewUrl,
          caption: post.caption,
          access_token: user.social.facebook.access_token,
        }).toString();
        var url =
          `https://graph.facebook.com/v19.0/${post.instagram.business_account}/media?` +
          params;
        var response = await axios.post(url);
        console.log(response);
        container_id = response.data.id;
        tempStatus[post.instagram.id].container_id = container_id;
        tempStatus[post.instagram.id].text = "Ready To Publish";
        tempStatus[post.instagram.id].text = "Ready To Publish";
      }

      // step 2 out of 2 publish}

      if (!media_id) {
        console.log("------CALLING MEDIA ID---------");

        console.log(typeof container_id);
        params = new URLSearchParams({
          creation_id: container_id,
          access_token: user.social.facebook.access_token,
        }).toString();

        await wait(5000);

        url =
          `https://graph.facebook.com/v19.0/${post.instagram.business_account}/media_publish?` +
          params;
        console.log(url);
        var response2 = await axios.post(url);
        if (response2.status !== 200) {
          throw new Error(response2.data.error.error_user_msg);
        }
        tempStatus[post.instagram.id].media_id = response2.data.id;
        tempStatus[post.instagram.id].text = "Published";
        tempStatus[post.instagram.id].published = true;
        tempStatus[post.instagram.id].status = 10;
      }
    } catch (e) {
      tempStatus[post.instagram.id].status = 0;
      showWarning(e.message);
    } finally {
      setStatus({ ...tempStatus });
      updateDoc(doc(db, "socialPosts", post.id), { status: tempStatus });
    }
  };

  return (
    <div className="relative w-full h-screen overflow-y-auto">
      {showModal && (
        <Modalcard close={setShowModal}>
          <div className="h-[80vh] w-[90vw] bg-background rounded-md flex flex-wrap gap-5 p-5 overflow-y-auto">
            <div className="flex items-center w-full h-16">
              <input
                type="text"
                onChange={(event) => setSearchText(event.target.value)}
                placeholder="Search by title, product"
                className="w-full h-12 bg-bg_highlight rounded-md px-5 placeholder:text-[#808080] font-light placeholder:font-normal"
              />
            </div>
            <div className="grid w-full grid-cols-2 gap-5">
              {flicks
                ?.filter(
                  (val) =>
                    val?.name
                      ?.toLowerCase()
                      .includes(searchText.toLocaleLowerCase()) ||
                    val?.product?.name
                      ?.toLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                )
                ?.map((review, index) => (
                  <video playsInline Player
                    key={index}
                    data={review}
                    isReview={false}
                    setVideo={selectReview}
                  />
                ))}
              {reviews
                ?.filter(
                  (val) =>
                    val?.name
                      ?.toLowerCase()
                      .includes(searchText.toLocaleLowerCase()) ||
                    val?.product?.name
                      ?.toLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                )
                ?.map((review, index) => (
                  <video playsInline Player
                    key={index}
                    data={review}
                    isReview={true}
                    setVideo={selectReview}
                  />
                ))}
            </div>
          </div>
        </Modalcard>
      )}
      <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
        <p className="text-lg font-light tracking-wide">Create Post</p>
      </div>

      <div className="flex justify-between w-full gap-5 p-10">
        <div className="bg-bg_highlight p-3 border border-[#808080be] rounded-md w-full mx-auto flex">
          <div className="w-full">
            <div className="flex items-center justify-between border-b border-[#8080804d] pb-3">
              <p className="uppercase">Create A post</p>
              <div className="flex flex-wrap gap-3"></div>
            </div>
            <div className="flex items-start gap-5 p-5">
              <div className="">
                {review ? (
                  <div className="w-[320px] aspect-[9/16] group bg-background border border-[#8080804d] rounded-md flex items-center justify-center">
                    <video playsInline
                      src={review?.reviewUrl}
                      className="w-full h-full rounded-md"
                      controls
                      controlsList="nodownload noplaybackrate"
                      disablePictureInPicture
                    ></video>
                  </div>
                ) : (
                  <div
                    className="w-[320px] aspect-[9/16] group bg-background border border-[#8080804d] rounded-md flex items-center justify-center"
                    onClick={() => setShowModal(!showModal)}
                  >
                    <p className="text-[#808080] group-hover:underline underline-offset-2">
                      Click here to select review/flick
                    </p>
                  </div>
                )}
                {review && (
                  <div className="flex items-center justify-center mt-2">
                    <button
                      className="hover:underline underline-offset-2"
                      onClick={() => setShowModal(!showModal)}
                    >
                      Change Video
                    </button>
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-between flex-1">
                <div className="">
                  <p className="text-sm">Caption</p>
                  <textarea
                    value={caption}
                    onChange={(event) => setCaption(event.target.value)}
                    className="w-full p-3 h-40 bg-transparent placeholder:text-[#8E8B8B] appearance-none outline-none bg-background rounded-md mt-1"
                    placeholder="write a caption"
                    maxLength={280}
                  ></textarea>
                  <p className="text-xs text-right text-[#808080]">0/280</p>
                </div>
                <div className="">
                  <p className="text-sm">Channels</p>
                  {(!user?.social?.facebook_pages ||
                    !user.social?.instagram) && (
                      <div className="flex items-center gap-5 pt-5">
                        <p className="text-sm text-[#808080]">
                          No socials linked
                        </p>
                        <button
                          className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                          onClick={() => router.push("/social/channel")}
                        >
                          Connect
                        </button>
                      </div>
                    )}
                  <div className="flex items-center gap-2 mt-2">
                    {user?.social?.facebook_pages?.map((page, index) => (
                      <div
                        className="relative h-12 rounded-full aspect-square"
                        key={index}
                        onClick={() => {
                          if (!platforms.includes(page.name))
                            setPlatforms([...platforms, page.name]);
                          else
                            setPlatforms(
                              platforms.filter((f) => f !== page.name)
                            );
                        }}
                      >
                        <div className="absolute z-10 flex items-end justify-end w-full h-full">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Ffacebook.png?alt=media&token=5675160a-b038-470c-9ffd-9803660318f3"
                            alt="ig"
                            className="h-4 rounded-full aspect-square"
                          />
                        </div>
                        <img
                          style={{
                            border: platforms.includes(page.name)
                              ? "1px solid red"
                              : "none",
                          }}
                          className="box-border h-12 duration-200 transform rounded-full cursor-pointer hover:scale-105"
                          src={page.picture.data.url}
                          alt=""
                        />
                      </div>
                    ))}
                    {user?.social?.instagram && (
                      <div
                        className="relative h-12 rounded-full aspect-square"
                        onClick={() => {
                          if (!platforms.includes("instagram"))
                            setPlatforms([...platforms, "instagram"]);
                          else
                            setPlatforms(
                              platforms.filter((f) => f !== "instagram")
                            );
                        }}
                      >
                        <div className="absolute z-10 flex items-end justify-end w-full h-full">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
                            alt="ig"
                            className="h-4 rounded-full aspect-square"
                          />
                        </div>
                        <img
                          style={{
                            border: platforms.includes("instagram")
                              ? "1px solid red"
                              : "none",
                          }}
                          className="box-border h-12 duration-200 transform rounded-full cursor-pointer hover:scale-105"
                          alt=""
                          src={user?.social?.instagram?.profile_pic}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex items-end justify-between">
                    {/* <div className="">
                      <p
                        onClick={() => setscheduled(!ispostScheduled)}
                        className="text-sm underline duration-100 transform cursor-pointer hover:scale-105"
                      >
                        {ispostScheduled
                          ? "Post Schedule (Remove)"
                          : "Schedule this post"}
                      </p>
                      <br />
                      {ispostScheduled && (
                        <input
                          ref={scheduleRef}
                          type="datetime-local"
                          className="relative flex items-center justify-center w-64 h-12 px-4 text-xs rounded-md bg-background"
                        />
                      )}
                    </div> */}
                    {!post?.readyToPublish && (
                      <button
                        onClick={createPost}
                        className="px-5 py-2 mt-3 h-10 text-sm rounded-md bg-[#8EA9FA] hover:bg-[#4611ea]"
                      >
                        Create Post
                      </button>
                    )}
                  </div>
                  {readyToPublish && post?.readyToPublish && (
                    <div className=" px-5 mt-5 rounded-md bg-bg_highlight">
                      {post?.facebook_pages.map((page) => (
                        <div
                          key={page.id}
                          className="flex items-center justify-between border-b border-[#8080801f] py-5"
                        >
                          <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                            <img
                              src={page.picture.data.url}
                              alt=""
                              className="w-10 h-10 rounded-full"
                            />
                            <p className="text-sm font-normal">{page.name}</p>
                          </div>
                          <div className="flex-1 text-sm">
                            {postStatus[page.id]?.published ? (
                              <button className="px-5 py-2 text-sm rounded-full transform duration-150 bg-green-600 hover:bg-green-500">
                                Published
                              </button>
                            ) : (
                              <button
                                onClick={() => publishPage(page.id)}
                                className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                              >
                                {postStatus[page.id]?.status === 11 ? (
                                  <i className="fas fa-spinner animate-spin"></i>
                                ) : (
                                  "Publish"
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                      {post?.platforms.includes("instagram") && (
                        <div className="flex items-center justify-between border-b border-[#8080801f] py-5">
                          <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
                              alt=""
                              className="w-10 h-10 rounded-full"
                            />
                            <p className="text-sm font-normal">Instagram</p>
                          </div>
                          <div className="flex-1 text-sm">
                            {postStatus[post.instagram.id]?.media_id ? (
                              <button className="px-5 py-2 text-sm duration-150 transform bg-green-600 rounded-full hover:bg-green-500">
                                Published
                              </button>
                            ) : (
                              <button
                                onClick={publishOnInstagram}
                                className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
                              >
                                {postStatus[post.instagram?.id]?.status ===
                                  11 && (
                                    <i className="fas fa-spinner animate-spin"></i>
                                  )}{" "}
                                {postStatus[post.instagram?.id]?.text ??
                                  "Start Upload"}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {post.platforms.includes("youtube") && (
                        <div className="flex items-center justify-between border-b border-[#8080801f] py-5">
                          <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Fyoutube.png?alt=media&token=95cdb77b-bd34-4e0f-a5b8-81fad630e030"
                              alt=""
                              className="w-10 h-10 rounded-full"
                            />
                            <p className="text-sm font-normal">Youtube</p>
                          </div>
                          <div className="flex-1 text-sm">
                            <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]">
                              Publish
                            </button>
                          </div>
                        </div>
                      )}
                      {post.platforms.includes("linkedin") && (
                        <div className="flex items-center justify-between border-b border-[#8080801f] py-5">
                          <div className="flex items-center gap-2 font-medium tracking-wide text-white w-52">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Flinkedin.png?alt=media&token=1a018f13-6337-41e5-9e89-f8913c6b948c"
                              alt=""
                              className="w-10 h-10 rounded-full"
                            />
                            <p className="text-sm font-normal">Linkedin</p>
                          </div>
                          <div className="flex-1 text-sm">
                            <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]">
                              Publish
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-[420px] bg-bg_highlight p-3 border border-[#808080be] rounded-md">
          <div className="flex items-center justify-between border-b border-[#8080804d] pb-3">
            <p className="uppercase">Preview</p>
            <div className="flex flex-wrap gap-3">
              <div
                onClick={(event) => {
                  const childElement = event.currentTarget.firstElementChild;
                  childElement.style.display =
                    childElement.style.display === "none"
                      ? "inline-block"
                      : "none";
                }}
                className="relative flex items-center justify-between w-48 gap-2 px-3 text-sm rounded-md cursor-pointer select-none h-9 bg-background"
              >
                <div className="hidden z-50  absolute top-10 left-0 rounded-md w-full bg-background border border-[#8080801e]">
                  <div
                    className="flex items-center justify-between gap-2 px-3 cursor-pointer h-9"
                    onClick={() => setPreviewChannel("instagram_reel")}
                  >
                    <span className="">Instagram Reel</span>
                    <CaretDown weight="fill" size={18} />
                  </div>
                  <div
                    className="flex items-center justify-between gap-2 px-3 cursor-pointer h-9"
                    onClick={() => setPreviewChannel("instagram_feed")}
                  >
                    <span className="">Instagram Feed</span>
                    <CaretDown weight="fill" size={18} />
                  </div>
                  <div
                    className="flex items-center justify-between gap-2 px-3 cursor-pointer h-9"
                    onClick={() => setPreviewChannel("facebook_reel")}
                  >
                    <span className="">Facebook Reel</span>
                    <CaretDown weight="fill" size={18} />
                  </div>
                  <div
                    className="flex items-center justify-between gap-2 px-3 cursor-pointer h-9"
                    onClick={() => setPreviewChannel("facebook_feed")}
                  >
                    <span className="">Facebook Feed</span>
                    <CaretDown weight="fill" size={18} />
                  </div>
                </div>
                <div className="flex items-center justify-between w-full gap-2">
                  <span className="">
                    {previewChannel === "instagram_feed" && "Instagram Feed"}
                    {previewChannel === "instagram_reel" && "Instagram Reel"}
                    {previewChannel === "facebook_feed" && "Facebook Feed"}
                    {previewChannel === "facebook_reel" && "Facebook Reel"}
                  </span>
                  <CaretDown weight="fill" size={18} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="flex justify-center w-full h-auto mt-4">
              {previewChannel === "instagram_reel" && (
                <InstagramReel user={user} caption={caption} review={review} />
              )}
              {previewChannel === "facebook_reel" && (
                <FacebookReel user={user} caption={caption} review={review} />
              )}
              {previewChannel === "facebook_feed" && (
                <FacebookFeed user={user} caption={caption} review={review} />
              )}
              {previewChannel === "instagram_feed" && (
                <InstagramFeed user={user} caption={caption} review={review} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
