import React from 'react'

export default function Notes({ notes }) {
    return (
        <>
            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                <p className="pb-5 text-lg tracking-wide font-work">Notes</p>
                {notes?.map((note, index) => (
                    <div
                        className="flex items-center justify-between py-2"
                        key={index}
                    >
                        <p className="">
                            {index + 1}: {note}
                        </p>
                    </div>
                ))}
            </div>
        </>
    )
}
