import React, { Suspense, lazy, useEffect, useRef, useState, } from "react";
import Loadingdot from "./components/loadingdot";
import { ArrowLeft, ArrowRight, Check, Play, RocketLaunch, ShuffleAngular, Target, Wallet } from "@phosphor-icons/react";
import AccordionItem from "./components/accordian";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useHistory, useLocation, Link } from "react-router-dom";
import PlaylistFeed from "./components/playlistFeed";
import Modalcard from "./components/modalcard";
import { showSuccess } from "./components/toast/toast";
import { addDoc, collection } from "firebase/firestore";
import { db } from "./firebase";
import { Navigation } from "swiper";
const LogoSlider = lazy(() => import("./components/logoSlider"));
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));

export default function InfluencerVideos() {
    const swiperRef = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(window.location.search);
    const router = useHistory();
    const [errors, setErrors] = useState({});
    const [contactData, setContactData] = useState({
        companyName: "",
        comapnyWebsite: "",
        emailAddress: "",
        phone: "",
        videos: "",
        budget: ""
    })
    const [activePlaylist, setactivePlaylist] = useState([]);
    const flicksArray = [
        {
            video:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksA%2Freview_CaL_qBe-5t9MKZgqdSya6zyOK.mp4?alt=media&token=cc0f3992-a17d-4d1b-9f82-6739c1b3b438",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_CaL_qBe-5t9MKZgqdSya6zyOK.jpg?alt=media&token=53b4ebbf-6940-46a0-a956-f18479769e47",
        },
        {
            video:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksD%2Freview_35C2SJj63UigqoJT3MsPUZcU0.mp4?alt=media&token=cb59a45e-7a38-4071-9fa2-6089f6c054eb",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_35C2SJj63UigqoJT3MsPUZcU0.jpg?alt=media&token=ff5d1ce7-f205-4bf7-8cdd-e69a146c028e",
        },
        {
            video:
                "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_9WC8KOkFyP3QPdD4q8.mp4?generation=1676134243791411&alt=media",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_9WC8KOkFyP3QPdD4q8.jpg?alt=media&token=fe2a04e6-f8ca-4923-91f3-0ba076662abb",
        },
        {
            video:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksE%2Freview_219qSxB_KIriCVfpmsov5PKBc.mp4?alt=media&token=37e0578c-87ce-43fd-b0f6-b9c87fb8a88e",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_219qSxB_KIriCVfpmsov5PKBc.jpg?alt=media&token=f171286a-0a92-48f5-ada7-cb836b5283a3",
        },
        {
            video:
                "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_fuzOoj7gC4rbPhXz_p.mp4?generation=1677135449934793&alt=media",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_fuzOoj7gC4rbPhXz_p.jpg?alt=media&token=acd55324-7e91-4388-b044-44d8fc7b5f16",
        },
        {
            video:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_W02FYFhfAXg45EOFpV.mp4?alt=media&token=d91a6c03-0867-43ad-8d8c-2654a56064ae",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_W02FYFhfAXg45EOFpV.jpg?alt=media&token=66fea7a6-8ca9-4823-b166-ec4c9e5f44a7",
        },
        {
            video:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_ppsL7LRYG5VkcB93FM.mp4?alt=media&token=268435fc-6c68-4bcb-a699-ed1fe63a70d4",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_ppsL7LRYG5VkcB93FM.jpg?alt=media&token=860d6e6a-141f-4fb0-9356-1f35f5fd0eaf",
        },
        {
            video:
                "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_lBDcirY9w6MhH1w9Ii.mp4?generation=1672050392882208&alt=media",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_lBDcirY9w6MhH1w9Ii.jpg?alt=media&token=1056aa2d-82fa-4bbb-b0d9-c73db11c04ff",
        },
        {
            video:
                "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_skbS98U1PBPnkcTGiz.mp4?generation=1670858902234562&alt=media",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_skbS98U1PBPnkcTGiz.jpg?alt=media&token=c5c78128-d63d-44f2-ab3f-e15d43032172",
        },
        {
            video:
                "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_IEgSBWkICrQl4qliml.mp4?generation=1670856760085611&alt=media",
            poster:
                "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_IEgSBWkICrQl4qliml.jpg?alt=media&token=d7ac54b0-c4a5-4ef1-8a65-981180c47570",
        },
    ];
    async function closeModal() {
        history.replace("/influencervideos")
    }
    useEffect(() => {
        const hash = location.hash;
        if (params.get('showModal') === 'true') {
            if (activePlaylist.length === 0) {
                router.replace("/influencervideos")
            }
        }
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            const documentDiv = document.getElementById('document');
            if (element && documentDiv) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [params, router, activePlaylist, location])

    async function submit() {
        var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        var newErrors = {};
        if (!contactData.companyName.trim()) {
            newErrors.companyName = "Please enter valid brand name"
        }
        if (!contactData.comapnyWebsite.trim()) {
            newErrors.comapnyWebsite = "Please enter valid brand website"
        }
        if (!emailRegex.test(contactData.emailAddress.trim())) {
            newErrors.emailAddress = "Please enter valid email address"
        }
        if (!contactData.phone.trim()) {
            newErrors.phone = "Please enter valid phone number"
        }
        if (!contactData.videos.trim()) {
            newErrors.videos = "Please select the number of videos"
        }
        if (!contactData.budget.trim()) {
            newErrors.budget = "Please select per video budget"
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const document = {
            brandName: contactData.companyName.trim(),
            brandWebsite: contactData.comapnyWebsite.trim(),
            emailId: contactData.emailAddress.trim(),
            phone: contactData.phone.trim(),
            howDidYouFindUs: contactData.fromWhere.trim()
        }
        await addDoc(collection(db, "influencerConsult"), document).then(res => {
            showSuccess("We have received your request, we will reach out to you soon.");
            window.location.reload()
        })
    }

    return (
        <div className="h-screen overflow-y-auto bg-[#101010]" id="document">
            <Suspense fallback={<Loadingdot />}>
                <NotLoginHeaderNew />
                {params.get('showModal') === 'true' && (
                    <>
                        <Modalcard close={closeModal}>
                            <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                                <div className="w-full h-full overflow-y-auto rounded snap-y snap-mandatory scrollbar" id="zero-scrollbar">
                                    <style>
                                        {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
                                    </style>
                                    <>
                                        {activePlaylist?.map((video, index) => (
                                            <PlaylistFeed data={video} key={index} keyid={`playlist${index}`} displayscroll={true} />
                                        ))}
                                    </>
                                </div>
                            </div>
                        </Modalcard>
                    </>
                )}
                <div className=" bg-[#101010] pb-0 lg:py-10">
                    <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                        <div className="grid gap-20 lg:grid-cols-2">
                            <div className="flex justify-center">
                                <video playsInline muted autoPlay src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4D5ebca875b24ba2a87b7463546eeed6.mp4?alt=media&token=3672d45e-8251-4eb6-b8af-908ea2ebbb85" controls controlsList="nodownload noplaybackrate nofullscreen" disablePictureInPicture className="w-[300px] aspect-[9/16] object-cover rounded-2xl" />
                            </div>
                            <div className="flex flex-col justify-center">
                                <p className="text-[30px] lg:text-[50px] leading-tight tracking-wide font-medium">
                                    Get Influencer Videos With Your Offerings
                                </p>
                                <ul className="mt-8">
                                    <ol className="text-[20px] lg:text-[24px] font-light tracking-wide">1: Define Your Vision</ol>
                                    <ol className="text-[20px] lg:text-[24px] font-light tracking-wide">2: Select Your Creator</ol>
                                    <ol className="text-[20px] lg:text-[24px] font-light tracking-wide">3: Get Your winning ads</ol>
                                </ul>
                                <div className="flex justify-center mt-8 lg:justify-start">
                                    <button onClick={() => router.replace("#form")} className=" py-3 px-12 w-fit rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">Request for demo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#101010] pb-5 md:py-[40px] lg:py-[50px] p-5 md:p-10">
                    <div className="overflow-hidden">
                        <ReactVisibilitySensor partialVisibility once>
                            {({ isVisible }) => (
                                <div className={`mt-10 ${isVisible ? "slide-to-left" : ""}`}>
                                    <Swiper
                                        spaceBetween={15}
                                        ref={swiperRef}
                                        breakpoints={{
                                            340: {
                                                width: 340,
                                                slidesPerView: 3,
                                            },
                                            768: {
                                                width: 768,
                                                slidesPerView: 5,
                                            },
                                            964: {
                                                width: 964,
                                                slidesPerView: 5,
                                            },
                                        }}
                                        modules={[Navigation]}
                                        onBeforeInit={(swiper) => {
                                            swiperRef.current = swiper;
                                        }}>
                                        {flicksArray.map((flick, index) => (
                                            <SwiperSlide
                                                key={index}
                                                className="relative cursor-pointer"
                                                onClick={() => {
                                                    setactivePlaylist(flicksArray);
                                                    history.push("/influencervideos?showModal=true")
                                                    setTimeout(() => {
                                                        const videoid = document.getElementById(`playlist${index}`);
                                                        videoid.scrollIntoView();
                                                    }, 100);
                                                }}>
                                                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                    <div className="h-12 w-12 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                                        <Play size={26} color="white" weight="fill" />
                                                    </div>
                                                </div>
                                                <img loading="lazy" src={flick?.poster} alt="" className="w-full aspect-[9/16] object-cover rounded-md" />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                        </ReactVisibilitySensor>
                        <div className="flex items-center justify-center gap-3 pb-5 mt-4">
                            <button onClick={() => swiperRef.current?.slidePrev()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                                <ArrowLeft size={26} color="black" />
                            </button>
                            <button onClick={() => swiperRef.current?.slideNext()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                                <ArrowRight size={26} color="black" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" bg-[#171717] py-10 hidden lg:block">
                    <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                        <h4 className="text-[25px] font-medium text-center">Industry <span className="text-[#F92147]">Challenges!</span></h4>
                        <div className="grid grid-cols-3 mt-3 border-b border-[#80808065] min-h-[80px]">
                            <div className="flex items-center justify-center w-full h-full">
                                <p className="pb-2 font-medium tracking-wide text-center text-[#F92147]">Influencer Marketing Challenges</p>
                            </div>
                            <div className=""></div>
                            <div className="flex items-center justify-center w-full h-full">
                                <p className="pb-2 font-medium tracking-wide text-center text-[#F92147]"> MyRevue's Solutions</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-3  border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Black Box</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">No price transparency</li>
                                    <li className="text-sm font-light tracking-wide list-disc">50 to 100% markups charged by existing players that go unchecked</li>
                                </ul>
                            </div>
                            <div className="flex items-center justify-center w-full h-full px-12">
                                <p className="text-xl font-medium tracking-wide text-[#F92147]">Price</p>
                            </div>
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">100% Transparency</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Transparent pricing based on Category/ Genres/ Content format/ Social Media Fair Mark-ups</li>
                                </ul>
                            </div>
                        </div>
                        <div className="grid grid-cols-3  border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Excel/PPT/Screenshots</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Using manual aids like basic search & cold calling are time consuming</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Trackers maintained in google sheets, docs etc</li>
                                </ul>
                            </div>
                            <div className="flex items-center justify-center w-full h-full px-12">
                                <p className="text-xl font-medium tracking-wide text-[#F92147]">Operations</p>
                            </div>
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Platform Play</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Platform based Selection/ Negotiation/Content Review</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Real time insights/analytics to refine audiences</li>
                                </ul>
                            </div>
                        </div>
                        <div className="grid grid-cols-3  border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Upto 100-500 at max</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Small-scale campaigns rarely create impact. Marketers often don't know where to start, what to measure & how to scale</li>
                                </ul>
                            </div>
                            <div className="flex items-center justify-center w-full h-full px-12">
                                <p className="text-xl font-medium tracking-wide text-[#F92147]">Scale</p>
                            </div>
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">30k+ Registered Creators</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Scalable campaigns ranging from 1 to 10K</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Massive liquidity of Relevant Creator</li>
                                </ul>
                            </div>
                        </div>
                        <div className="grid grid-cols-3  border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Manual Social Match</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Finding the right influencers for your target audience can be difficult due to bot audience and fake engagement</li>
                                </ul>
                            </div>
                            <div className="flex items-center justify-center w-full h-full px-12">
                                <p className="text-xl font-medium tracking-wide text-[#F92147]">Relevance</p>
                            </div>
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Deep profiling and persona match</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Assessed based on Profile, Content, Audience, Affinity & Insights.</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Added assurance of Proprietary Risk & Fraud Detection/Prevention</li>
                                </ul>
                            </div>
                        </div>
                        <div className="grid grid-cols-3   mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Absent</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Lack of knowledge of market level insights and industry KPIs</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Vanity metrics over real time insights</li>
                                </ul>
                            </div>
                            <div className="flex items-center justify-center w-full h-full px-12">
                                <p className="text-xl font-medium tracking-wide text-[#F92147]">Insights</p>
                            </div>
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Insights & Best Practices</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Consider us your Knowledge Partners, to effectively navigate market trends and success factors.</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Deep category expertise</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-[#171717] py-10 block lg:hidden">
                    <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                        <h4 className="text-[25px] font-medium text-center">Industry <span className="text-[#F92147]">Challenges!</span></h4>
                        <div className="grid mt-3 min-h-[80px]">
                            <div className="flex items-center justify-center w-full h-full">
                                <p className="pb-2 font-medium tracking-wide text-center text-[#F92147]">Influencer Marketing Challenges</p>
                            </div>
                        </div>
                        <div className="border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Black Box</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">No price transparency</li>
                                    <li className="text-sm font-light tracking-wide list-disc">50 to 100% markups charged by existing players that go unchecked</li>
                                </ul>
                            </div>
                        </div>
                        <div className="border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Excel/PPT/Screenshots</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Using manual aids like basic search & cold calling are time consuming</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Trackers maintained in google sheets, docs etc</li>
                                </ul>
                            </div>
                        </div>
                        <div className=" border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Upto 100-500 at max</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Small-scale campaigns rarely create impact. Marketers often don't know where to start, what to measure & how to scale</li>
                                </ul>
                            </div>
                        </div>
                        <div className=" border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Manual Social Match</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Finding the right influencers for your target audience can be difficult due to bot audience and fake engagement</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Absent</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Lack of knowledge of market level insights and industry KPIs</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Vanity metrics over real time insights</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                        <div className="mt-3 border-b border-[#80808065] min-h-[80px]">
                            <div className="flex items-center justify-center w-full h-full">
                                <p className="pb-2 font-medium tracking-wide text-center text-[#F92147]"> MyRevue's Solutions</p>
                            </div>
                        </div>
                        <div className="border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">100% Transparency</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Transparent pricing based on Category/ Genres/ Content format/ Social Media Fair Mark-ups</li>
                                </ul>
                            </div>
                        </div>
                        <div className="border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Platform Play</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Platform based Selection/ Negotiation/Content Review</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Real time insights/analytics to refine audiences</li>
                                </ul>
                            </div>
                        </div>
                        <div className=" border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">30k+ Registered Creators</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Scalable campaigns ranging from 1 to 10K</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Massive liquidity of Relevant Creator</li>
                                </ul>
                            </div>
                        </div>
                        <div className="border-b border-[#80808065] mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Deep profiling and persona match</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Assessed based on Profile, Content, Audience, Affinity & Insights.</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Added assurance of Proprietary Risk & Fraud Detection/Prevention</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-[2rem] pb-[1rem]">
                            <div className="w-full h-full px-12">
                                <p className="pb-2 font-medium tracking-wide text-center">Insights & Best Practices</p>
                                <ul>
                                    <li className="text-sm font-light tracking-wide list-disc">Consider us your Knowledge Partners, to effectively navigate market trends and success factors.</li>
                                    <li className="text-sm font-light tracking-wide list-disc">Deep category expertise</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5 py-10 lg:px-10 " id="form">
                    <div className="max-w-[1248px] bg-[#101010] rounded-xl mx-auto px-5 lg:px-10 py-5">
                        <p className="text-[28px] lg:text-[36px] text-center leading-tight tracking-wide font-semibold ">
                            Want to connect with team?
                        </p>
                        <p className="mt-2 text-base tracking-wide text-center lg:text-lg">Team will help you with your queries!</p>
                        <div className="grid gap-5 my-10 lg:gap-10 lg:grid-cols-2">
                            <div className="w-full">
                                <p className="">Brand Name</p>
                                <input type="text" className={`h-[50px] mt-2 bg-[#222121] px-5 w-full rounded-md appearance-none outline-none border ${errors?.companyName ? "border-[#F92147]" : "border-[#222121]"}`} onChange={(event) => {
                                    setContactData({ ...contactData, companyName: event.target.value })
                                    setErrors({ ...errors, companyName: false })
                                }} placeholder="Your brand name*" minLength={0} />
                            </div>
                            <div className="w-full">
                                <p className="">Brand Website</p>
                                <input type="text" className={`h-[50px] mt-2 bg-[#222121] px-5 w-full rounded-md appearance-none outline-none border ${errors?.comapnyWebsite ? "border-[#F92147]" : "border-[#222121]"}`} onChange={(event) => {
                                    setContactData({ ...contactData, comapnyWebsite: event.target.value })
                                    setErrors({ ...errors, comapnyWebsite: false })
                                }} placeholder="Your brand website*" minLength={0} />
                            </div>
                            <div className="w-full">
                                <p className="">Email address</p>
                                <input type="text" className={`h-[50px] mt-2 bg-[#222121] px-5 w-full rounded-md appearance-none outline-none border ${errors?.emailAddress ? "border-[#F92147]" : "border-[#222121]"}`} onChange={(event) => {
                                    setContactData({ ...contactData, emailAddress: event.target.value })
                                    setErrors({ ...errors, emailAddress: false })
                                }} placeholder="Your email address*" minLength={0} />
                            </div>
                            <div className="w-full">
                                <p className="">Phone</p>
                                <input type="text" className={`h-[50px] mt-2 bg-[#222121] px-5 w-full rounded-md appearance-none outline-none border ${errors?.phone ? "border-[#F92147]" : "border-[#222121]"}`}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const sanitizedValue = inputValue.replace(/[^0-9\s+]/g, ''); // Allow only digits, spaces, and plus sign
                                        setContactData({ ...contactData, phone: sanitizedValue });
                                        setErrors({ ...errors, phone: false });
                                    }}
                                    onKeyDown={(event) => {
                                        const allowedKeyCodes = [8, 46, 32, 43];
                                        if (
                                            !(event.key.match(/[0-9]/) || allowedKeyCodes.includes(event.keyCode))
                                        ) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder="Your phone number*" maxLength={13} minLength={0} />
                            </div>
                            <div className="w-full">
                                <p className="">How many videos you want ?</p>
                                <select onChange={(event) => { setContactData({ ...contactData, videos: event.target.value }); setErrors({ ...errors, videos: false }); }} className={`h-[50px] mt-2 bg-[#222121] px-5 w-full rounded-md appearance-none outline-none border ${errors?.videos ? "border-[#F92147]" : "border-[#222121]"}`}>
                                    <option value="" disabled selected>Please select an option</option>
                                    <option value="1-5" >1-5</option>
                                    <option value="6-10" >6-10</option>
                                    <option value="11-20" >11-20</option>
                                    <option value="20+" >20+</option>
                                </select>
                            </div>
                            <div className="w-full">
                                <p className="">What is your budget per video ?</p>
                                <select onChange={(event) => { setContactData({ ...contactData, budget: event.target.value }); setErrors({ ...errors, budget: false }); }} className={`h-[50px] mt-2 bg-[#222121] px-5 w-full rounded-md appearance-none outline-none border ${errors?.budget ? "border-[#F92147]" : "border-[#222121]"}`}>
                                    <option value="" disabled selected>Please select an option</option>
                                    <option value="500-1000" >500-1000</option>
                                    <option value="1001-2000" >1001-2000</option>
                                    <option value="2001-5000" >2001-5000</option>
                                    <option value="5001+" >5001+</option>
                                </select>
                            </div>
                            <div className="">
                                <button onClick={() => submit()} className="py-3 px-12 w-full lg:w-fit rounded-md bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <NotloginfooterNew />
            </Suspense>
        </div>
    )
}
