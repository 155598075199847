import React, { useEffect, useState } from "react";
import { cachedAPICalls } from "../common/api";
import { APiTokens, apiHost } from "../common/Strings";
import InstagramProfile from "../pages/social/analytics/InstagramProfile";
import InstagramAnalytics from "../pages/social/analytics/InstagramAnalytics";
import PlaylistFeed from "../components/playlistFeed";
import Modalcard from "../components/modalcard";
import { showWarning } from "../components/toast/toast";
import axios from "axios";
import { compatiblityUserScoreComponent } from "./huntFunctions";
import moment from "moment";

function ViewHuntApplicantsAnalytics() {
  const [campaign, setcampaign] = useState(JSON.parse(sessionStorage.getItem("inhouserequest")));
  const [applicant, setApplicant] = useState(JSON.parse(sessionStorage.getItem("applicantTemp")));
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("videos");
  const [topFlicks, setTopFlicks] = useState([]);
  const [videoType, setVideoType] = useState("flicks");
  const [changesRef, setchangesRef] = useState({
    comments: "",
    changes: "",
  });
  useEffect(() => {
    cachedAPICalls(apiHost.flicks + "/getTopFlicksOfUser/" + applicant.userId, {
      headers: { Authorization: APiTokens.flicks },
    }).then((res) => {
      setTopFlicks(res);
    });
  }, []);

  async function selectUserApplication(application) {
    if (application?.status === "Ready") {
      showWarning(`User is already selected`);
      return;
    }
    if (application?.status !== "Ready") {
      const headersList = {
        Accept: "*/*",
        Authorization:
          "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
      };

      const bodyContent = {
        userId: application?.user?.id,
        status: "Ready",
      };

      const reqOptions = {
        url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
        method: "PUT",
        headers: headersList,
        data: bodyContent,
      };

      try {
        const response = await axios.request(reqOptions);
        if (response?.data?.success === true) {
          setApplicant({ ...applicant, status: "Ready" });
        }
      } catch (error) {
        showWarning("Error in updating user status");
        console.error("Error updating user status:", error);
      }
    }
  }
  async function approveContent() {
    const application = { ...applicant };
    if (application?.status === "Completed") {
      showWarning("Content is already approved");
      return;
    }
    // if (aprovedvideos.length === campaign?.videos) {
    //   showWarning("You have already approved maximum no of videos");
    //   return;
    // }
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
    };

    let bodyContent = {
      userId: application?.user?.id,
      status: "Completed",
    };

    let reqOptions = {
      url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };
    await axios.request(reqOptions).then((response) => {
      if (response?.data?.success === true) {
        const updatedApplicant = {
          ...applicant,
          status: "Completed",
        };
        setApplicant(updatedApplicant);
      }
    });
  }
  async function rejectContent() {
    const application = { ...applicant };
    if (application?.status === "Rejected") {
      showWarning("Content is already rejected");
      return;
    }

    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
    };

    let bodyContent = {
      userId: application?.user?.id,
      status: "Rejected",
    };

    let reqOptions = {
      url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };
    await axios.request(reqOptions).then((response) => {
      if (response?.data?.success === true) {
        setApplicant({ ...applicant, status: "Rejected" });
      }
    });
  }
  async function requestEditContent() {
    if (
      applicant?.status === "Completed" ||
      applicant?.status === "Rejected"
    ) {
      showWarning(
        `Cannot request for edit as content is already ${applicant?.status === "Completed" ? "Approved" : "Rejected"
        }`
      );
      return;
    }

    if (changesRef?.changes.length < 5) {
      showWarning(`Please select a changes title`);
      return;
    }
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
    };

    let bodyContent = {
      userId: applicant?.user?.id,
      changesTitle: changesRef?.changes,
      changesComment: changesRef?.comments ?? null,
      status: "Ready",
    };

    let reqOptions = {
      url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${campaign?._id}`,
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };
    await axios.request(reqOptions).then((response) => {
      console.log(response.data)
      if (response?.data?.success === true) {
        const updatedApplicant = {
          ...applicant,
          status: "Ready",
          changesTitle: changesRef?.changes,
          changesComment: changesRef?.comments ?? null,
        };
        setApplicant(updatedApplicant);
        setShowModal(false);
      }
    });
  }
  return (
    <>
      {showModal && (
        <Modalcard close={setShowModal}>
          {modalType === "video" ?
            <div
              className="w-full h-[100dvh]  flex items-center justify-center relative"
              onClick={(e) => {
                if (e.target === e.currentTarget) {
                  setShowModal(false);
                }
              }}
            >
              <div className="h-[80vh]  aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                <div
                  className="w-full h-full overflow-y-auto rounded snap-y snap-mandatory scrollbar"
                  id="zero-scrollbar"
                >
                  <style>
                    {`#zero-scrollbar::-webkit-scrollbar { display: none; } #zero-scrollbar { scrollbar-width: none; -ms-overflow-style: none; }`}
                  </style>
                  {videoType === "flicks" ?
                    <>
                      {topFlicks?.map((video, index) => (
                        <PlaylistFeed
                          data={video}
                          key={index}
                          keyid={`playlist${index}`}
                          displayscroll={true}
                        />
                      ))}
                    </>
                    :
                    <>
                      {[{ video: applicant?.url }]?.map((video, index) => (
                        <PlaylistFeed
                          data={video}
                          key={index}
                          hideBranding
                          keyid={`playlist${index}`}
                          displayscroll={true}
                        />
                      ))}
                    </>
                  }
                </div>
              </div>
            </div>
            :
            <div
              className="w-[900px] bg-[#2e313e] h-[80vh] overflow-y-auto rounded-md"
              id="thin-scrollbar"
            >
              <div className="h-16 border-b bg-[#2e313e] border-[#83838380] flex items-center justify-between gap-5 px-10 sticky top-0 z-10">
                <div className="flex gap-5">
                  <button className="border-b-2 border-[#4611ea]">
                    Request Edit
                  </button>
                </div>
              </div>
              <div className="flex gap-5 m-5">
                <div className="w-96">
                  <video
                    playsInline
                    onContextMenu={(e) => e.preventDefault()}
                    controls={false}
                    src={applicant?.url}
                    onClick={(event) =>
                      event.currentTarget?.paused
                        ? event.currentTarget.play()
                        : event.currentTarget.pause()
                    }
                    className="aspect-[9/16] rounded-md  w-[250px]"
                  ></video>
                </div>
                <div className="w-full gap-5">
                  <p className="pb-3 text-lg font-work">Request edits</p>
                  <div className="my-3">
                    <p className="pb-1 font-work">Changes #1</p>
                    <select
                      type="text"
                      onChange={(event) =>
                        setchangesRef({
                          ...changesRef,
                          changes: event.target.value,
                        })
                      }
                      className="w-full bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide"
                    >
                      <option value="">Please Select</option>
                      <option value="Fix aspect ratio to fit the Brief">
                        Fix aspect ratio to fit the Brief
                      </option>
                      <option value="Fix duration to fit the Brief">
                        Fix duration to fit the Brief
                      </option>
                      <option value="Videos needs to look more natural">
                        Videos needs to look more natural{" "}
                      </option>
                      <option value="Videos need more energy">
                        Videos need more energy
                      </option>
                      <option value="Remove filters or effects">
                        Remove filters or effects
                      </option>
                      <option value="Remove voice over/music">
                        Remove voice over/music
                      </option>
                      <option value="Incorrect pronounciation">
                        Incorrect pronounciation
                      </option>
                      <option value="Video/audio quality issue">
                        Video/audio quality issue
                      </option>
                      <option value="Product not shown">
                        Product not shown
                      </option>
                      <option value="Inaccurate Scene">
                        Inaccurate Scene
                      </option>
                      <option value="Incorrect mention">
                        Incorrect mention
                      </option>
                      <option value="Film it differently">
                        Film it differently
                      </option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  <div className="my-3">
                    <p className="pb-1 font-work">Comments (Optional)</p>
                    <textarea
                      type="text"
                      onChange={(event) =>
                        setchangesRef({
                          ...changesRef,
                          comments: event.target.value,
                        })
                      }
                      placeholder=""
                      className="w-full bg-[#23252D] h-32 appearance-none outline-none rounded-md p-3 text-sm tracking-wide"
                    />
                  </div>
                  <div className="flex justify-between">
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      className="border-2  border-[#93A8F4] rounded-full px-8 py-2 text-[#93A8F4]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => requestEditContent()}
                      className="bg-[#93A8F4] rounded-full px-8 py-2 text-white"
                    >
                      Send Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </Modalcard>
      )}
      <div className="grid grid-cols-4 gap-2 m-5 mt-7">
        <div className=" h-auto p-5 bg-[#131418] border border-[#545454] rounded-md relative">
          <div className="flex items-center justify-end gap-2 my-2">
            <div data-tooltip="MyRevue Verified Account">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20icon.png?alt=media&token=71ebcd5d-3060-457a-a6fc-0d4dfa6de361"
                alt=""
                className="h-6 aspect-square"
              />
            </div>
            {applicant?.instagramConnected && (
              <div data-tooltip="Linked Instagram Account">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
                  alt=""
                  className="h-6 aspect-square"
                />
              </div>
            )}
          </div>
          <div className="flex justify-center w-full">
            <div className="h-24 w-24 rounded-full flex items-center justify-center bg-[#23252D]">
              {applicant?.user?.imageUrl !== null ? (
                <img
                  src={applicant?.user?.imageUrl}
                  alt="user_image"
                  className="w-full h-full rounded-full"
                />
              ) : (
                <p className="">
                  {(applicant?.user?.name &&
                    applicant?.user?.name
                      .split(" ")
                      .map((n) => n[0].toUpperCase())
                      .join("")) ||
                    ""}
                </p>
              )}
            </div>
          </div>
          <div className="my-2">
            <p className="text-sm text-center">{applicant?.user?.name}</p>
          </div>
          <div className="grid grid-cols-3 gap-3 my-5">
            <div className="flex flex-col items-center justify-center w-full h-12 gap-3 ">
              <p className="text-lg leading-none">
                {applicant.user.rating ?? 3}
              </p>
              <p className="text-xs leading-none">Rating</p>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-12 gap-3 ">
              <p className="text-lg leading-none">
                {applicant.user.followers ?? 3}
              </p>
              <p className="text-xs leading-none">Followers</p>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-12 gap-3 ">
              <p className="text-lg leading-none">
                {applicant.user.flicks ?? 0}
              </p>
              <p className="text-xs leading-none">Flicks</p>
            </div>
          </div>
        </div>

        {/* user details with compatiblity score and action buttons as Reject, select or improvement reason */}
        <div className={`${["pending", "Ready"].includes(applicant?.status) ? "col-span-3" : "col-span-2"}  p-5 bg-[#131418] border border-[#545454] rounded-md`}>
          <div className="grid grid-cols-3 gap-5">
            <div className="bg-background border border-[#545454] p-3 h-24 rounded-md flex flex-col justify-between">
              <p className="">Compatiblity Score</p>
              {compatiblityUserScoreComponent(applicant.score)}
            </div>
            <div className="bg-background border border-[#545454] p-3 h-24 rounded-md flex flex-col justify-between">
              <p className="">Application Status</p>
              <p
                className={`mt-2 text-xl font-medium ${applicant?.status === "pending"
                  ? "text-yellow-500"
                  : "text-green-500"
                  }`}
              >
                {applicant?.status === "pending"
                  ? "Waiting For approval"
                  : "Approved"}
              </p>
            </div>
            {(["Completed", "In Review", "Rejected"].includes(applicant.status) || (applicant?.status === "Ready" && applicant?.changesTitle)) &&
              <div className="bg-background border border-[#545454] p-3 h-24 rounded-md flex flex-col justify-between">
                <p className="">Content Status</p>
                {applicant?.status === "Completed" && <p className={`mt-2 text-xl font-medium text-green-500`}> Completed  </p>}
                {applicant?.status === "In Review" && <p className={`mt-2 text-xl font-medium text-yellow-500`}> For Approval  </p>}
                {applicant?.status === "Rejected" && <p className={`mt-2 text-xl font-medium text-red-500`}> Rejected  </p>}
                {(applicant?.status === "Ready" && applicant?.changesTitle) && <p className={`mt-2 text-xl font-medium text-purple-500`}> Asked for changes  </p>}
              </div>
            }
            <div className="col-span-3">
              <p className="pb-3">Creator Criteria</p>
              <div className="grid grid-cols-3 gap-5">
                <div className="bg-background border border-[#545454] p-3 h-24 rounded-md flex flex-col justify-between">
                  <p className="">Age</p>
                  <p className={`mt-2 text-sm font-medium line-clamp-2`}>{moment(applicant?.user?.dob).fromNow(true)}</p>
                </div>
                <div className="bg-background border border-[#545454] p-3 h-24 rounded-md flex flex-col justify-between">
                  <p className="">Gender</p>
                  <p className={`mt-2 text-sm font-medium line-clamp-2`}>{applicant?.user?.gender}</p>
                </div>
                <div className="bg-background border border-[#545454] p-3 h-24 rounded-md flex flex-col justify-between">
                  <p className="">Langugae</p>
                  <p className={`mt-2 text-sm font-medium line-clamp-2`}>{applicant?.user?.languages.join(", ")}</p>
                </div>
              </div>
            </div>
          </div>
          {applicant?.status === "pending" && (
            <div className="mt-3">
              <button
                className="h-10 px-5 text-sm bg-blue-500 rounded-md"
                onClick={() => selectUserApplication(applicant)}
              >
                Approve Creator
              </button>
              {/* TODO  DONE - Button to select or Reject this user */}
            </div>
          )}
        </div>
        {!["pending", "Ready"].includes(applicant?.status) && <div className="p-5 bg-[#131418] border border-[#545454] rounded-md flex justify-between">
          {/* TODO - DONE - Show Submissions of this user here.......  */}
          <div className="h-full" onClick={() => { setShowModal(true); setVideoType("submission"); setModalType("video") }}>
            <video src={applicant?.url} className="max-h-[250px]"></video>
          </div>
          <div className="flex flex-col items-end flex-1">
            {(!["Completed", "Rejected"].includes(applicant.status) || (applicant?.status === "Ready" && applicant?.changesTitle)) && <>
              <button onClick={() => { setShowModal(true); setModalType("changes") }} className="w-40 h-10 px-5 mb-2 text-sm tracking-wide text-white rounded-md font-work bg-[#4611ea]">Ask for changes</button>
              <button onClick={() => approveContent()} className="w-40 h-10 px-5 mb-2 text-sm tracking-wide text-white bg-green-500 rounded-md font-work">Approve</button>
              <button onClick={() => rejectContent()} className="w-40 h-10 px-5 mb-2 text-sm tracking-wide text-white bg-red-500 rounded-md font-work">Reject</button>
            </>}
          </div>

          {/* TODO - DONE -  THIS IS DEFAULT CARD - EDIT to improve UI   */}
          {/* not required */}
          {/* <div className="flex flex-col items-center justify-center w-full h-full">
            <p className="mb-2">
              <button className="flex items-center gap-2 px-3 py-1 rounded-md"> <LockSimple size={22} color="white" /> Locked</button>
            </p>
            <p className="text-xs text-gray-400">Applicant hasn't uploaded video yet</p>
          </div> */}
        </div>
        }

        {/* MyRevue User Analytics- Top Flicks 5 (Playable) */}
        {topFlicks.length > 0 && (
          <div className="col-span-4 p-5 bg-[#131418] border border-[#545454] rounded-md">
            <h1 className="">Top 5 Flicks</h1>
            <div className="flex flex-wrap w-full gap-5 mt-2">
              {topFlicks?.map((review, index) => (
                <div
                  key={index}
                  onClick={() => { setShowModal(true); setVideoType("flicks"); setModalType("video") }}
                  className="w-full max-w-[240px] aspect-[9/16] bg-bg_highlight border border-[#545454] rounded-md flex gap-2"
                >
                  {review?.thumbnail ? (
                    <img
                      src={review?.thumbnail}
                      className="w-full h-full rounded-md cursor-pointer"
                      alt="thumbnail"
                    />
                  ) : (
                    <video
                      src={review?.reviewUrl}
                      poster={review?.thumbnail}
                      className="w-full h-full rounded-md cursor-pointer"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {/* Instagram Analytics - Impressions, reach, Followers, Reels Count, Audience Gender Group, Age Group */}
        {applicant.instagramConnected && (
          <div className="grid grid-flow-row col-span-4 gap-2 rounded-md bg-bg_highlight">
            <div className="p-5">
              <InstagramProfile
                pageid={applicant.instagram.accountId}
                token={applicant.instagram.accessToken}
              />
            </div>
            <InstagramAnalytics
              pageid={applicant.instagram.businessId}
              token={applicant.instagram.accessToken}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ViewHuntApplicantsAnalytics;
