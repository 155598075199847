import { ArrowRight, Check, CircleNotch, Eye, Hexagon, Pencil, ShieldSlash, Trash, UploadSimple } from '@phosphor-icons/react';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../context/StateProvider';
import { addDoc, collection, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import { showWarning } from '../components/toast/toast';
import moment from 'moment';
import { nanoid } from 'nanoid';

export default function CreateEmail() {
    const router = useHistory();
    const [{ brand, user }, dispatch] = useStateValue();
    const [flowStep, setFlowStep] = useState(0);
    const [templateSelected, setTemplateSelected] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [leads, setLeads] = useState({});
    const [loading, setLoading] = useState(false);

    const [campaignData, setCampaignData] = useState({
        campaignName: "",
        subject: "",
        senderName: "",
        senderEmailAddress: "",
        replyToEmailAddress: "",
    });

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                let headersList = {
                    "Accept": "*/*",
                }

                let reqOptions = {
                    url: "https://massemailer-zscu3untuq-as.a.run.app/templates/" + brand?.id,
                    method: "GET",
                    headers: headersList,
                    data: document
                }

                let response = await axios.request(reqOptions);
                if (response.data.success) {
                    setTemplates(response.data.data)
                }
            } catch (error) {
                console.error('Error fetching templates', error);
            }
        };
        const fetchLeads = async () => {
            try {
                let headersList = {
                    "Accept": "*/*",
                }

                let reqOptions = {
                    url: "https://massemailer-zscu3untuq-as.a.run.app/leads/" + brand?.id,
                    method: "GET",
                    headers: headersList,
                    data: document
                }

                let response = await axios.request(reqOptions);
                if (response.data.success) {
                    setLeads(response.data.data)
                }
            } catch (error) {
                console.error('Error fetching templates', error);
            }
        };
        fetchTemplates();
        fetchLeads();
    }, [])



    async function submitNextFromTemplate() {
        if (!templateSelected) {
            showWarning("Please select a template");
            return
        }
        setFlowStep(step => step + 1);
    }

    async function submitNextFromTable() {
        if (templateSelected === null) {
            showWarning("Please select a template to create a campaign");
            return
        }
        if (leads.pagination.totalLeads === 0) {
            showWarning("Please add leads to create a campaign");
            router.push("/admin/emails?tab=leads")
            return
        }
        setFlowStep(step => step + 1);
    }
    async function checkValidation() {
        if (!campaignData.campaignName || !campaignData.subject || !campaignData.senderName) {
            setLoading(false)
            showWarning("please enter all details to create camapaign")
            return
        }
        generatePaymentLink()
    }
    async function submitCampaign() {

        const document = {
            campaignName: campaignData.campaignName,
            subject: campaignData.subject,
            senderName: campaignData.senderName,
            replyToEmailAddress: campaignData.replyToEmailAddress,
            createdBy: user?.email,
            brand: brand?.id,
            leadsSegment: "all",
            email: user?.email,
            templateId: templateSelected,
            campaignStatus: "pending",
            status: false,
            paymentStatus: true,
            audidienceCount: leads?.pagination?.totalLeads
        }
        let headersList = {
            "Accept": "*/*",
        }
        let reqOptions = {
            url: "https://massemailer-zscu3untuq-as.a.run.app/campaigns",
            method: "POST",
            headers: headersList,
            data: document
        }
        let response = await axios.request(reqOptions);
        if (!response.data.success) {
            setLoading(false)
            return showWarning(response.data.message)
        }
        router.push("/admin/emails?tab=campaigns")
    }

    async function generatePaymentLink() {
        setLoading(true)
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let bodyContent = {
            amount: Math.max(1, Math.round(leads?.pagination.totalLeads * 0.07)),
            currency: "INR",
            description: `Email Order`,
            metadata: {
                emailOrderId: nanoid(20)
            }
        };
        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/razorpay/create/email/checkoutLink",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        makePayment(response.data)
        // window.location.href = response.data.url;
    }
    const makePayment = async (data) => {
        const res = await initializeRazorpay();
        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }
        var options = {
            key: "rzp_live_E4dtid6tq7cO0E", // Enter the Key ID generated from the Dashboard
            name: "MyRevue",
            currency: data.currency,
            amount: data.amount,
            order_id: data.id,
            description: data.notes.description,
            image: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue.png?alt=media&token=b9dc6ef0-c272-49ba-a7c5-5bf51b46eda7",
            handler: function (response) {
                submitCampaign()
            },
            theme: {
                hide_topbar: false,
                color: '#FF005C',
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    const initializeRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    return (
        <div className="bg-[#2D3037]">
            {flowStep === 0 && <div className='relative h-screen pb-32 overflow-y-auto'>
                <div className="fixed z-20 bottom-0  h-32 bg-gradient-to-b from-transparent via-[#12121290] to-[#121212] flex items-center justify-center w-[calc(100%-240px)]">
                    <button onClick={() => submitNextFromTemplate()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Next
                    </button>
                </div>
                <p className="mt-10 text-2xl text-center">Select a template</p>
                <div className="grid grid-cols-4 gap-10 p-10 lg:grid-cols-5">
                    {templates?.map((template, index) => (
                        <div className="w-full cursor-pointer" key={index} onClick={() => setTemplateSelected(prevtemplate => prevtemplate === template._id ? null : template._id)}>
                            <div className={`w-full bg-white  aspect-[3/4] rounded-md cursor-pointer border-4 border-transparent  ${templateSelected === template?._id ? "border-[#93A8F4]" : "hover:border-[#93A8F4]"}`}>
                                <img src={template?.previewUrl} alt="preview" className='object-cover w-full h-full rounded-md hover:grayscale' />
                            </div>
                            <div className="flex justify-center mt-2">
                                <p className="text-center">{template?.templateName}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {flowStep === 1 && <div className='relative h-screen overflow-y-auto'>
                <div className="fixed z-20 bottom-0  h-32 bg-gradient-to-b from-transparent via-[#12121290] to-[#121212] flex items-center justify-center w-[calc(100%-240px)]">
                    <button onClick={() => leads?.pagination?.totalLeads === 0 ? showWarning("Please add leads to conitnue") : setFlowStep(step => step + 1)} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Next
                    </button>
                </div>
                <div className="border border-[#6e6a6a] rounded-md m-10">
                    <div className="p-5">
                        <p className="">Audience</p>
                    </div>
                    {leads?.pagination?.totalLeads === 0 ?
                        <div className="bg-[#9b2622] px-5 py-3 rounded-b-md">
                            <p className="">No leads available. Please add to leads to create a campaign</p>
                        </div>
                        :
                        <div className="bg-[#229b6e] px-5 py-3 rounded-b-md">
                            <p className="">Approximately {leads?.pagination?.totalLeads} recipients will receive the campaign</p>
                        </div>
                    }
                </div>
            </div>}
            {flowStep === 2 && <div className='relative h-screen overflow-y-auto'>
                <div className="fixed z-20 bottom-0  h-32 bg-gradient-to-b from-transparent via-[#12121290] to-[#121212] flex items-center justify-center w-[calc(100%-240px)]">
                    <button onClick={() => submitNextFromTable()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Next
                    </button>
                </div>
                <div className="px-5 pb-5 mt-10">
                    <p className="text-2xl font-medium">Audience</p>
                </div>
                <div className="relative block px-5 mb-10">
                    <div className="relative">
                        <div className="overflow-x-auto min-h-[0.01%]">
                            <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                <thead className="h-14 bg-[#131418]">
                                    <tr>
                                        <th className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            #
                                        </th>
                                        <th className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Email
                                        </th>
                                        <th className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Full Name
                                        </th>
                                        <th className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Subscribed
                                        </th>
                                        <th className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Created At
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="table-row-group align-middle border-inherit">
                                    {leads?.leads?.map((row, rowIndex) => (
                                        <tr
                                            className="table-row align-[inherit]  bg-[#131418] border-t border-white border-opacity-20"
                                            key={rowIndex}
                                        >
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {rowIndex + 1}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {row?.email}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {row.fullName}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {row?.subscribed ? <div className='bg-green-500 w-fit px-4 py-[5px] rounded text-white'>Subscribed</div> : <div className='bg-red-500 w-fit px-4 py-[5px] rounded text-white'>Not Subscribed</div>}
                                            </td>
                                            <td className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {moment(row?.createdAt).format('MMM DD, YYYY h:mm A')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {leads?.leads.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                No fields Available
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
            {flowStep === 3 && <div className='relative h-screen p-10 overflow-y-auto'>
                <h1 className='text-2xl'>Add Campaign Details</h1>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 w-full">
                    <div className="relative w-full ">
                        <input className="text-white tracking-wide w-full flex-1 bg-transparent appearance-none outline-none placeholder:text-[#808080]" placeholder='Email Campaign Name' value={campaignData.campaignName} onChange={(event) => setCampaignData({ ...campaignData, campaignName: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Audience</p>
                    <div className="relative w-full col-span-2">
                        <input className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none" readOnly type='text' placeholder='Audience count' defaultValue={leads?.pagination?.totalLeads} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Subject</p>
                    <div className="relative w-full col-span-2">
                        <input className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none" type='text' placeholder='Email subject' value={campaignData.subject} onChange={(event) => setCampaignData({ ...campaignData, subject: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Sender name</p>
                    <div className="relative w-full col-span-2">
                        <input className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none" type='text' placeholder='Sender name' value={campaignData.senderName} onChange={(event) => setCampaignData({ ...campaignData, senderName: event.target.value })} />
                    </div>
                </div>
                {/* <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Sender email address</p>
                    <div className="relative w-full col-span-2">
                        <input className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none" type='text' placeholder='Sender email address' value={campaignData.senderEmailAddress} onChange={(event) => setCampaignData({ ...campaignData, senderEmailAddress: event.target.value })} />
                    </div>
                </div> */}
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Reply to email address (optional)</p>
                    <div className="relative w-full col-span-2">
                        <input className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none" type='text' placeholder='Reply to email address' value={campaignData.replyToEmailAddress} onChange={(event) => setCampaignData({ ...campaignData, replyToEmailAddress: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Created by</p>
                    <div className="relative w-full col-span-2">
                        <input className="flex-1 min-w-full tracking-wide text-white bg-transparent outline-none appearance-none" type='text' placeholder='Created by' readOnly defaultValue={user?.email} />
                    </div>
                </div>
                {/* <div className="mt-8">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Content</p>
                    <div className="border border-[#5C5C5D] h-60 w-full rounded-2xl mt-2">
                    </div>
                </div> */}
                <div className="flex justify-center mt-8">

                    <button onClick={() => checkValidation()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none flex items-center justify-center">
                        {loading ?
                            <CircleNotch size={22} color='white' className='animate-spin' />
                            :
                            <p className="">Send Emails</p>
                        }
                    </button>
                </div>
            </div>}
        </div>
    )
}
