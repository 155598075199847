import React, { Suspense, lazy, useEffect, useMemo, useRef, useState, } from "react";
import Loadingdot from "./components/loadingdot";
import { ArrowLeft, ArrowRight, CheckCircle, Play, RocketLaunch, ShuffleAngular, Target, Wallet, X } from "@phosphor-icons/react";
import AccordionItem from "./components/accordian";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useHistory, useLocation } from "react-router-dom";
import PlaylistFeed from "./components/playlistFeed";
import Modalcard from "./components/modalcard";
import { Navigation } from "swiper";
import { firstRowLogo, secondRowLogo, thirdRowLogo } from "./logo";
const LogoSlider = lazy(() => import("./components/logoSlider"));
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));
export default function IndexHome() {
    const swiperRef = useRef(null);
    const swiperReftwo = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const [activePlaylist, setactivePlaylist] = useState([]);
    const [activeArray, setActiveArray] = useState(null)
    const topArray = [
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh1.mp4?alt=media&token=b7b7271e-de84-4944-a8dc-77565b207148",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fvedansh1.webp?alt=media&token=ca258d8c-fdb0-41c0-af9d-47e70426451b"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini2.mp4?alt=media&token=6a0b0d49-b92b-4af5-ae47-0b00c5c96ede",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fbhavini2.webp?alt=media&token=51f8f3c3-0a72-42a5-9bd3-1489bfa09d20"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol3.mp4?alt=media&token=8ba06cca-84de-4cf8-98b9-2924acb4c7e0",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol3.webp?alt=media&token=96ebe8dd-0d48-4809-a636-4974dc2f8cbc"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak1.mp4?alt=media&token=521c8f94-f535-4f97-86d8-7ad2036b80ac",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fpalak1.webp?alt=media&token=2321831f-95b0-4f16-a39b-2d30b487a6c9"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2FFemale%20testimonial.mp4?alt=media&token=8f5c4f57-eb39-4f22-8e6c-13cddf55b047",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Ffemale%20testimonial.webp?alt=media&token=125fe150-bead-48a5-ba76-34c8c9b72e66",
            "aspect": "horizontal"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).mp4?alt=media&token=b7198879-89b5-4c8a-a455-a09441445922",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).png?alt=media&token=0fd786ff-6e46-4c12-85ff-755e97f0c02a"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2FMahip%20india%20pink%20suit(Salman%20Shaikh)1.mp4?alt=media&token=59d86463-b9cc-42d8-a7f6-75d4039f78d5",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fmahip.webp?alt=media&token=ba48df70-3a3b-4238-92aa-870599ca7418"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag1.mp4?alt=media&token=172ad69d-7f1c-446e-a867-26bce250fc85",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanurag1.webp?alt=media&token=cf7a5008-5453-47c0-9e05-ec7505a9755e"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2FBlack%20beast%2001.mp4?alt=media&token=aa27948b-68dc-4517-a97a-83733aee9abd",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fblack%20beast.webp?alt=media&token=8b5b54be-6c96-48f8-a894-021123ab0fc6"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTaponn%20(Anurag%20Sir).mp4?alt=media&token=322221ae-19fc-4bb1-a973-f4f6c060c68b",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTapOnn%20(Anurag%20Sir).png?alt=media&token=cf12877c-adb7-4f4d-9cdd-73cc624fef7b"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol2.mp4?alt=media&token=b08eb87a-7c95-4963-8bf2-12dd60d78610",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol2.webp?alt=media&token=bb5e8402-d3a4-45dd-827f-316530275dd8"
        },

    ]
    const flicksArray = [
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4D5ebca875b24ba2a87b7463546eeed6.mp4?alt=media&token=3672d45e-8251-4eb6-b8af-908ea2ebbb85",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4d5ebca875b24ba2a87b7463546eeed6.png?alt=media&token=bc19efab-0992-4647-829d-949151b9a63a",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol2.mp4?alt=media&token=b08eb87a-7c95-4963-8bf2-12dd60d78610",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fanmol2.webp?alt=media&token=bb5e8402-d3a4-45dd-827f-316530275dd8",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).mp4?alt=media&token=b7198879-89b5-4c8a-a455-a09441445922",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FInternship%20Studio%20(Preeti).png?alt=media&token=0fd786ff-6e46-4c12-85ff-755e97f0c02a",
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fplarsh%20voice%20over%20(Arjun%20Soni).mp4?alt=media&token=f6f4daaf-8a5a-45d3-8177-f3457cc47436",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fplarsh.webp?alt=media&token=6a7dc7c7-4e26-4fa2-ad28-60d11ef78ca5"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fzoocase%20script%205%20(arjun%20Soni).mp4?alt=media&token=b8c8e3ad-57b8-40c9-9240-c83f8542b8df",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fzoocase.webp?alt=media&token=d6064db5-9fe7-4eb4-be14-0ae1d5f2e95d"
        },

        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTaponn%20(Khushi).mp4?alt=media&token=39e81ea3-7ea6-40a2-843d-baa4ed3b619b",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2FTapOnn%20(Khushi).png?alt=media&token=25dd607d-ea02-445e-80ed-714562b80b5a",
        },


        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fsavvy%20groovy%20script%203%20(Arjun%20Soni).mp4?alt=media&token=06085c52-03b9-4d62-ac64-100e831bef6c",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fsavvy.webp?alt=media&token=e91c1b40-2ca2-4d13-98db-44d3264ec73f"
        },
        {
            "video": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2FSibling%20day%20V003.mp4?alt=media&token=c9412341-cfd4-40b7-bf15-e8744b775ddb",
            "poster": "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FindexHome%2Fsibling.webp?alt=media&token=f29e2798-3235-41d4-8e9a-e0d0baee8450"
        },

        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicksE%2Freview_219qSxB_KIriCVfpmsov5PKBc.mp4?alt=media&token=37e0578c-87ce-43fd-b0f6-b9c87fb8a88e",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_219qSxB_KIriCVfpmsov5PKBc.jpg?alt=media&token=f171286a-0a92-48f5-ada7-cb836b5283a3",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_fuzOoj7gC4rbPhXz_p.mp4?generation=1677135449934793&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_fuzOoj7gC4rbPhXz_p.jpg?alt=media&token=acd55324-7e91-4388-b044-44d8fc7b5f16",
        },

        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_ppsL7LRYG5VkcB93FM.mp4?alt=media&token=268435fc-6c68-4bcb-a699-ed1fe63a70d4",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_ppsL7LRYG5VkcB93FM.jpg?alt=media&token=860d6e6a-141f-4fb0-9356-1f35f5fd0eaf",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak3.mp4?alt=media&token=f7948f01-26c7-41b2-93e8-0820246244f5",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fpalak3.webp?alt=media&token=6cfd0d86-5f99-4f15-b22c-134f0b10ede3",
        },
        {
            video: "https://storage.googleapis.com/download/storage/v1/b/revueapp-4eeed.appspot.com/o/flicks%2Freview_skbS98U1PBPnkcTGiz.mp4?generation=1670858902234562&alt=media",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/flickThumb%2Freview_skbS98U1PBPnkcTGiz.jpg?alt=media&token=c5c78128-d63d-44f2-ab3f-e15d43032172",
        },
        {
            video: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy2.mp4?alt=media&token=46106ffd-4267-4b02-9bbe-2899768fd2d8",
            poster: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2Fdaisy2.webp?alt=media&token=538b750b-17be-49f6-b25d-5d0fc87c2d3a",
        },
    ];

    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    useEffect(() => {
        const hash = location.hash;
        const param = params.get('showModal')
        if (hash || param) {
            if (hash) {
                const element = document.getElementById(hash.substring(1));
                const documentDiv = document.getElementById('document');
                if (element && documentDiv) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }
            if (param === 'true') {
                if (activePlaylist.length === 0) {
                    history.replace("/")
                }
            }
        } else {
            // document.getElementById('document').scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location, activePlaylist, history, params])
    async function closeModal() {
        if (activeArray === "top") {
            history.replace("/");
            setActiveArray(null)
            return
        }
        history.replace("/#flicks");
        setActiveArray(null)
    }
    return (
        <div className="h-screen overflow-y-auto bg-[#101010]" id="document">
            <Suspense fallback={<Loadingdot />}>
                <NotLoginHeaderNew />
                {params.get('showModal') === 'true' && (
                    <>
                        <Modalcard close={closeModal}>
                            <div className="w-full h-[100dvh]  flex items-center justify-center relative" onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    closeModal();
                                }
                            }}>
                                {/* <div className="absolute right-3 top-5" onClick={() => closeModal()}>
                                    <X size={22} color="white" weight="bold" />
                                </div> */}
                                <div className="h-[80vh]  aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
                                    <div className="w-full h-full overflow-y-auto rounded snap-y snap-mandatory scrollbar" id="zero-scrollbar">
                                        <style>
                                            {`#zero-scrollbar::-webkit-scrollbar { display: none; } #zero-scrollbar { scrollbar-width: none; -ms-overflow-style: none; }`}
                                        </style>
                                        <>
                                            {activePlaylist?.map((video, index) => (
                                                <PlaylistFeed data={video} key={index} keyid={`playlist${index}`} displayscroll={true} />
                                            ))}
                                        </>
                                    </div>
                                </div>
                            </div>
                        </Modalcard>

                    </>
                )}
                <div className="w-full px-5 lg:p-10">
                    <div className="max-w-[1248px] mx-auto">
                        <h2 className="text-xl text-center">Are you selling products ONLINE or OFFLINE ?</h2>
                        <h1 className="mt-3 text-3xl font-medium text-center lg:mt-5 lg:text-6xl">EFFORTLESSLY GET HIGH QUALITY<br />VIDEOS FOR YOUR BRAND ADS</h1>
                        <div className="mt-3 font-light tracking-wide lg:mt-5 h-fit text-white/70">
                            <div className="flex flex-wrap justify-center gap-2">
                                <div className="flex items-center gap-2"><CheckCircle size={20} color="green" /> Get lifetime copyright free access <span className="hidden md:block"> | </span></div>
                                <div className="flex items-center gap-2"><CheckCircle size={20} color="green" /> Get 3x Returns <span className="hidden md:block"> | </span></div>
                                <div className="flex items-center gap-2"><CheckCircle size={20} color="green" /> 1000+ Videos Delivered</div>

                            </div>
                            <div className="flex items-center justify-center mt-4 lg:mt-5">
                                <button onClick={() => history.push("#creatives")} className="py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent !text-white signup-btn-hover">
                                    Order now
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 overflow-hidden lg:mt-10">
                        <Swiper
                            spaceBetween={15}
                            ref={swiperRef}
                            slidesPerView={"auto"}
                            modules={[Navigation]}
                            onBeforeInit={(swiper) => { swiperRef.current = swiper }}>
                            {topArray.map((flick, index) => (
                                <SwiperSlide
                                    key={index}
                                    className={`relative cursor-pointer !w-min !h-[220px] md:!h-[350px] ${flick?.aspect ? "aspect-video" : "aspect-[9/16]"}`}
                                    onClick={() => {
                                        setactivePlaylist(topArray);
                                        setActiveArray("top");
                                        history.push("/?showModal=true");
                                        setTimeout(() => {
                                            const videoid = document.getElementById(`playlist${index}`);
                                            videoid.scrollIntoView();
                                        }, 100);
                                    }}>
                                    <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        <div className="h-12 w-12 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                            <Play size={26} color="white" weight="fill" />
                                        </div>
                                    </div>
                                    <div className={`w-full h-full rounded-md overflow-hidden`}>
                                        <img loading="lazy" src={flick?.poster} alt="" className="object-cover w-full h-full" />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="flex items-center justify-center gap-3 pb-5 mt-4">
                            <button onClick={() => swiperRef.current?.slidePrev()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square">
                                <ArrowLeft size={26} color="black" />
                            </button>
                            <button onClick={() => swiperRef.current?.slideNext()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square">
                                <ArrowRight size={26} color="black" />
                            </button>
                        </div>
                    </div>

                    <div className="px-0 mt-5 mb-5 -mx-5 md:mx-auto md:px-10">
                        <h2 className="text-[24px] lg:text-[32px] text-center font-semibold leading-tight mt-16">
                            Trusted by 80+ brands
                        </h2>
                        <div className="space-y-0 md:space-y-5 ">
                            <LogoSlider logos={firstRowLogo} />
                            <LogoSlider logos={secondRowLogo} direction="right" />
                            <LogoSlider logos={thirdRowLogo} />
                        </div>
                    </div>
                </div>
                <div className="bg-[#171717] py-[40px] md:py-[50px] p-4 md:p-10">
                    <div className="">
                        <div className="flex flex-col justify-center w-full mx-auto lg:max-w-[60%]">
                            <p className="text-left font-semibold md:text-center mb-[25px] leading-snug tracking-wide text-[24px] md:text-[40px]">
                                Boost Engagement & ROI with UGC Ads
                            </p>
                            <p className="text-left md:text-center tracking-wide mb-[20px]">
                                Research indicates that ads featuring faces outperform those without. Leverage UGC to craft compelling marketing campaigns that capture attention, evoke emotion, and establish trust.
                            </p>
                        </div>
                        <div className="mt-10 ">
                            <div className="flex flex-col-reverse items-center justify-center h-full lg:flex-row-reverse ">
                                <div className="flex flex-col justify-between gap-5 lg:gap-12">
                                    <div className="flex items-center gap-3 mt-10 lg:mt-0">
                                        <div className="w-48 h-[1px] bg-white hidden lg:block"></div>
                                        <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                        <div className="text-sm font-medium">Most Effective Way To Represent Product</div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="w-44 h-[1px] bg-white hidden lg:block"></div>
                                        <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                        <div className="text-sm font-medium">Minimize the Language Barrier</div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="w-40 h-[1px] bg-white hidden lg:block"></div>
                                        <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                        <div className="text-sm font-medium">Best Way To Get Engaged With Target Consumers</div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="w-36 h-[1px] bg-white hidden lg:block"></div>
                                        <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                        <div className="text-sm font-medium">Increases the Conversion or spread Awareness Rapidly</div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="w-48 h-[1px] bg-white hidden lg:block"></div>
                                        <div className="block w-[10px] aspect-square bg-white rounded-full lg:hidden"></div>
                                        <div className="text-sm font-medium">Most Effective Way To Reshare</div>
                                    </div>
                                </div>
                                <div className="w-full md:w-[380px] aspect-[9/14]  relative bg-cover bg-center bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2Fugc%20square.webp?alt=media&token=a44e9f8d-c16c-4846-8875-a5780d23dedc')]  rounded-lg p-2">
                                    <div className="absolute -left-3 top-[55px] md:top-10  md:-left-10 z-[100] text-left">
                                        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2F2-1.svg?alt=media&token=7d4fb1a9-e217-4665-8ea2-9ad2964b67bc" alt="" className="block text-left mb-[17px] align-top" />
                                        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2F2-2.svg?alt=media&token=83985327-8bb8-4fe7-9e38-131aedbd302a" alt="" className="block text-left mb-[17px] align-top" />
                                        <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2F2-3.svg?alt=media&token=148aa716-c328-473e-aabe-57067a7e86e2" alt="" className="block text-left mb-[17px] align-top" />
                                    </div>
                                    <video playsInline
                                        className="object-cover w-full h-full rounded"
                                        autoPlay={true}
                                        muted={true}
                                        loop={true}
                                        preload="auto">
                                        <source
                                            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Fflicks%2F4D5ebca875b24ba2a87b7463546eeed6.mp4?alt=media&token=3672d45e-8251-4eb6-b8af-908ea2ebbb85"
                                            type="video/mp4"
                                        />
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="creatives" className="bg-[#101010] p-4 md:p-20">
                    <div className="max-w-[1248px] mx-auto">
                        <div className="">
                            <h2 className=" text-2xl md:text-[40px] text-center font-semibold leading-tight">
                                Choose Your Winning UGC Creatives
                            </h2>
                        </div>
                        <div className="grid gap-16 mt-8 lg:grid-cols-2">
                            <div className="w-full">
                                <div className="flex justify-center">
                                    <video controls controlsList="nodownload nodplaybackrate nofullscreen" disablePictureInPicture playsInline poster="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Finhouse%20thumbnail.webp?alt=media&token=4eed5f71-437a-46a1-95c6-9a090fcffa11" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FFrizty%20(Bhavesh%20Jain).mp4?alt=media&token=5253cbfa-5ffc-4009-88c5-af661aae5a9d" className="max-h-[450px] rounded-md" />
                                </div>
                                <div className="mt-5">
                                    <p className="pb-3 text-5xl font-medium text-center font-work">Inhouse videos</p>
                                    <p className="font-light tracking-wide text-white/70">Our in-house video production covers script writing to editing. Select from our diverse pool of 15+ creators to find the perfect match for your project's needs, be it experience, tattoos, or other unique traits.</p>
                                    <p className="mt-2 text-2xl font-medium tracking-wide text-center text-white font-work">* Starts at ₹4000<span className="text-sm"> + gst</span> per video</p>
                                </div>
                                <div className="flex justify-center w-full mt-5">
                                    <button onClick={() => history.push("/inhousevideos")} className="py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent !text-white signup-btn-hover">
                                        Order now
                                    </button>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="flex justify-center">
                                    <video controls controlsList="nodownload nodplaybackrate nofullscreen" disablePictureInPicture playsInline poster="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2Finfuencer%20thumbnail.webp?alt=media&token=59ed43da-1842-4108-8aea-c9172655b535" src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fugc%2FLifeStyle%2F5.mp4?alt=media&token=a92b8014-1797-4795-a58b-29e4ecce57a3" alt="" className="max-h-[450px] rounded-md" />
                                </div>
                                <div className="mt-5">
                                    <p className="pb-3 text-5xl font-medium text-center font-work">Influencer videos</p>
                                    <p className="font-light tracking-wide text-white/70">In our influencer video service, influencers shoot and edit. Test various user-generated content types—testimonials, showcases, mashups, and post-production edits—to find top-performing ad creatives.</p>
                                    <p className="mt-2 text-2xl font-medium tracking-wide text-center text-white font-work">* Starts at ₹3500<span className="text-sm"> + gst</span> per video</p>
                                </div>
                                <div className="flex justify-center w-full mt-5">
                                    <button onClick={() => history.push("/influencervideos")} className="py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent !text-white signup-btn-hover">
                                        Order now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#101010] pb-10">
                    <div className="max-w-[1248px] mx-auto p-5 md:p-0">
                        <div className=" md:max-w-[80%] mx-auto">
                            <p className="text-[32px] leading-tight tracking-wide font-semibold text-left md:text-center">
                                Seamless Video Ads from Start to Finish, Enhanced with Performance Marketing for Guaranteed Results
                            </p>
                        </div>
                        <div className="grid mt-10 md:grid-cols-2 md:gap-x-20 gap-y-10">
                            <div className="w-full space-y-5">
                                <div className="">
                                    <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                        <Target size={22} color="#121212" />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="text-[18px] text-white tracking-wide">Merge the Precision of Performance Marketing with Short Video Ads</p>
                                </div>
                                <div className="">
                                    <p className="text-[15px] text-white font-light tracking-wide">Scale your business seamlessly with UGC Videos & Performance Marketing—short, impactful ads paired with expert strategies, delivered by India's Best Team for the results you desire.</p>
                                </div>
                            </div>
                            <div className="w-full space-y-5">
                                <div className="">
                                    <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                        <ShuffleAngular size={22} color="#121212" />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="text-[18px] text-white tracking-wide">Customize According to Agency Specifications with White Labeling</p>
                                </div>
                                <div className="">
                                    <p className="text-[15px] text-white font-light tracking-wide">Not limited to brands, agencies can activate white labeling and other tailored features. Share your agency-specific requirements or preferences with us, and we'll bring your vision to life.</p>
                                </div>
                            </div>
                            <div className="w-full space-y-5">
                                <div className="">
                                    <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                        <RocketLaunch size={22} color="#121212" />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="text-[18px] text-white tracking-wide">Efficiently Enhance Ads on Our All-in-One Platform</p>
                                </div>
                                <div className="">
                                    <p className="text-[15px] text-white font-light tracking-wide">Streamline creative management and content creation with our user-friendly platform. Stay organized and on course, moving seamlessly from concept to ad in a quick, straightforward, and efficient manner.</p>
                                </div>
                            </div>
                            <div className="w-full space-y-5">
                                <div className="">
                                    <div className="flex items-center justify-center w-10 h-10 bg-white rounded-md">
                                        <Wallet size={22} color="#121212" />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="text-[18px] text-white tracking-wide">Access Budget-Friendly Video Solutions with Our In-House Creators & Editing Services.</p>
                                </div>
                                <div className="">
                                    <p className="text-[15px] text-white font-light tracking-wide">Effortlessly enhance your advertising with our ad ordering system. Our in-house editors guarantee polished, high-quality ads for a professional look in no time. Explore user-generated content tailored to your target demographic and aligned with your vision from our diverse pool of talented creators.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#171717] py-[40px] md:py-[50px] p-5 md:p-10" id="flicks">
                    <div className="md:max-w-[60%] mx-auto">
                        <p className="text-center mb-[25px] leading-snug tracking-wide text-[32px] md:text-[58px] font-semibold">Tap into a network of 15+ Inhouse Creators & over  23000+ verified UGC Influencer creators</p>
                    </div>
                    <div className="overflow-hidden">
                        <ReactVisibilitySensor partialVisibility once>
                            {({ isVisible }) => (
                                <div className={`mt-10 ${isVisible ? "slide-to-left" : ""}`}>
                                    <Swiper
                                        spaceBetween={25}
                                        // slidesPerView={1}
                                        // onSlideChange={() => console.log("slide change")}
                                        // onSwiper={(swiper) => console.log(swiper)}
                                        ref={swiperReftwo}
                                        breakpoints={{
                                            // when window width is >= 640px
                                            640: {
                                                width: 640,
                                                slidesPerView: 1,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                width: 768,
                                                slidesPerView: 2,
                                            },
                                            964: {
                                                width: 964,
                                                slidesPerView: 3,
                                            },
                                        }}
                                        modules={[Navigation]}
                                        onBeforeInit={(swiper) => {
                                            swiperReftwo.current = swiper;
                                        }}>
                                        {flicksArray.map((flick, index) => (
                                            <SwiperSlide
                                                key={index}
                                                className="relative cursor-pointer"
                                                onClick={() => {
                                                    // setplaylisttype("reviews");
                                                    setactivePlaylist(flicksArray);
                                                    // setActiveIndex(index);
                                                    history.push("/?showModal=true")
                                                    // setshowModal(true);
                                                    setTimeout(() => {
                                                        const videoid = document.getElementById(`playlist${index}`);
                                                        videoid.scrollIntoView();
                                                    }, 100);
                                                }}>
                                                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                    <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                                        <Play size={34} color="white" weight="fill" />
                                                    </div>
                                                </div>
                                                <img loading="lazy" src={flick?.poster} alt="" className="w-full aspect-[9/16] object-cover rounded-xl" />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                        </ReactVisibilitySensor>
                        <div className="flex items-center justify-center gap-3 pb-5 mt-4">
                            <button onClick={() => swiperReftwo.current?.slidePrev()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                                <ArrowLeft size={26} color="black" />
                            </button>
                            <button onClick={() => swiperReftwo.current?.slideNext()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                                <ArrowRight size={26} color="black" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-[#101010] pt-[40px] md:pt-[70px] p-5 md:p-10">
                    <div className="max-w-[1248px] bg-[#171717] p-5 shadow-lg rounded-md mx-auto">
                        <p className="text-[24px] mb-[20px]">FAQ</p>
                        <div className="accordion">
                            <AccordionItem title="Why Choose MyRevue Elite Packages?">
                                MyRevue Elite Packs offer a convenient and budget-friendly means to access a diverse range of video options. Whether you're in need
                                of eCommerce videos, Instagram Reels, YouTube Shorts, TikTok content, or personalized Shopify videos, our packs come with discounted
                                prices and added value. Opting for packs allows you to acquire more videos at a lower cost, enabling you to grow your content
                                library and effectively captivate your audience.{" "}
                            </AccordionItem>
                            <AccordionItem title="How do packs work?">
                                After you've placed an order, the MyRevue Elite content team will reach out to you to collaborate on designing the content.
                            </AccordionItem>
                            <AccordionItem title="What packs can be used for?">
                                Credit packs offer versatility for use in any order on MyRevue Elite, excluding the acquisition of additional packs. Conversely, our
                                custom packs are tailored to address specific video types, durations, and extra services as defined within the chosen pack
                                structure.
                            </AccordionItem>
                            <AccordionItem title="Do packs expire?">
                                Credit packs come with an extended validity period of 45 days, commencing from the date of purchase. In contrast, credits for all
                                other packs are valid for 15 days, starting from the date of purchase.
                            </AccordionItem>
                            <AccordionItem title="Is it refundable?">Yes</AccordionItem>
                            <AccordionItem title="Can I discuss my needs with MyRevue Elite representative?">
                                Feel free to reach out to us at connect@myrevue.app, and we'll assist you by sharing our previous experiences with similar
                                companies, analyzing your specific situation, and devising the optimal solution for your needs.
                            </AccordionItem>
                        </div>
                    </div>
                </div>
                <NotloginfooterNew />
            </Suspense>
        </div>
    )
}
