import { CaretDown, CaretRight, MagnifyingGlass, ShoppingCart, TrendUp } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NotloginfooterNew from "../layouts/NotloginfooterNew";
import { useHistory } from "react-router-dom";

export default function StockNew() {
    const router = useHistory();
    const [searchText, setSearchText] = useState("");
    const [items, setItems] = useState(JSON.parse(sessionStorage.getItem("stock")) || []);

    useEffect(() => {
        var title = `Ready-to-use UGC videos. In seconds!`;
        var metaTitle = `Ready-to-use UGC videos. In seconds!`;
        var metaDescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var metaKeyword = `Brands - MyRevue, UGC Video, MyRevue Elite, Billo , GoTolStoy, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

        var ogUrl = `https://brand.myrevue.app/stock`;
        var ogtype = `website`;
        var ogtitle = `Ready-to-use UGC videos. In seconds!`;
        var ogdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock.png?alt=media&token=b57398ab-7e39-4110-a395-797e527d54d5`;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/stock`;
        var twittertitle = `Ready-to-use UGC videos. In seconds!`;
        var twitterdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock.png?alt=media&token=b57398ab-7e39-4110-a395-797e527d54d5`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
            metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
            ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
            twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
            twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
    }, []);
    const categories = [
        { name: "UGC videos for bath and body care", tag: "Bath and Body Care" },
        { name: "UGC videos for eye care", tag: "Eye Care" },
        { name: "UGC videos for eye makeup", tag: "Eye Makeup" },
        { name: "UGC videos for face makeup", tag: "Face Makeup" },
        { name: "UGC videos for hair styling", tag: "Hair Styling" },
        { name: "UGC videos for hair tools", tag: "Hair Tools" },
        { name: "UGC videos for hair treatments", tag: "Hair Treatments" },
        { name: "UGC videos for lip balms and treatments", tag: "Lip Balms" },
        { name: "UGC videos for lip makeup", tag: "Lip Makeup" },
        { name: "UGC videos for nail care", tag: "Nail Care" },
        { name: "UGC videos for oral care", tag: "Oral Care" },
        { name: "UGC videos for perfume", tag: "Perfume" },
        { name: "UGC videos for skincare", tag: "Skin Care" },
        { name: "UGC videos for skincare tools", tag: "Skin Care Tools" },
        { name: "UGC videos for sun protection", tag: "Sun Protection" },
        { name: "UGC videos for women hygiene", tag: "Women Hygiene" },
        { name: "UGC videos for health and wellness", tag: "Health and Wellness" },
        { name: "UGC videos for eye wellbeing", tag: "Eye Wellbeing" },
        { name: "UGC videos for fitness gear and tools", tag: "Fitness Gear and Tools" },
        { name: "UGC videos for mobile apps", tag: "Mobile Apps" },
        { name: "UGC videos for pain relief and relaxation", tag: "Pain Relief and Relaxation" },
        { name: "UGC videos for sexual wellbeing", tag: "Sexual Wellbeing" },
        { name: "UGC videos for sports nutrition", tag: "Sports Nutrition" },
        { name: "UGC videos for superfoods and teas", tag: "Superfoods and Teas" },
        { name: "UGC videos for supplements", tag: "Supplements" },
        { name: "UGC videos for consumer goods", tag: "Consumer Goods" },
        { name: "UGC videos for bedroom and textile", tag: "Bedroom and Textile" },
        { name: "UGC videos for cats", tag: "Cats" },
        { name: "UGC videos for dogs", tag: "Dogs" },
        { name: "UGC videos for gifts", tag: "Gifts" },
        { name: "UGC videos for kitchen", tag: "Kitchen" },
        { name: "UGC videos for laundry and cleaning", tag: "Laundry and Cleaning" },
        { name: "UGC videos for personal care", tag: "Personal Care" },
        { name: "UGC videos for plants", tag: "Plants" },
        { name: "UGC videos for tech accessories", tag: "Tech Accessories" }
    ];
    return (
        <div className="min-h-screen bg-[#101010] w-full">
            <div className="h-20 w-full flex items-center justify-between px-6 shadow-md sticky top-0 z-[1000] bg-[#171717]">
                <div className="">
                    <Link to="/">
                        <img
                            loading="lazy"
                            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
                            alt="logo"
                            className="h-[40px] md:h-[62px] w-auto"
                        />
                    </Link>
                </div>
                <div className="">
                    <Link to="/stock/cart?step=1">
                        <button className="py-2 px-5 font-semibold flex items-center gap-5 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                            <ShoppingCart size={22} color="white" />
                            {items?.length}
                        </button>
                    </Link>
                </div>
            </div>
            <div className="max-w-[1352px] m-auto grid md:grid-cols-2 relative pt-[30px] pb-[50px] px-[30px]">
                <div className="flex flex-col justify-center">
                    <p className="text-[33px] md:text-[58px] font-medium leading-tight bg-clip-text text-white transform rotate-140">
                        UGC videos at your fingertips, instantly available!
                    </p>
                    <p className="text-[20px] mb-[20px] font-light tracking-wide mt-[20px]">
                        MyRevue's UGC stock videos are a great way for brands to try out different content elements effortlessly. Explore various ad hooks, b-rolls, angles, faces, CTAs, and more! 👇
                    </p>
                    <div className="w-full w-max-[300px] relative bg-[#171717] rounded-full shadow-md shadow-[#191919]">
                        <div className="absolute h-full bg-[#F92147] w-[70px] right-0 rounded-r-full flex items-center justify-center">
                            <MagnifyingGlass size={22} color="white" />
                        </div>
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            router.push("/stock/videos?q=" + searchText)
                        }}>
                            <input
                                type="text"
                                onChange={(event) => setSearchText(event.target.value)}
                                className="h-[50px] appearance-none outline-none w-full bg-transparent px-4 placeholder:text-[#808080] placeholder:tracking-wide placeholder:font-light tracking-wide"
                                placeholder="Search by category, text etc.."
                                autoCapitalize="off"
                                autoCorrect="off"
                                enterKeyHint="search"
                                spellCheck="false"
                            />
                        </form>
                    </div>
                    <div className="flex flex-wrap items-center gap-3 mt-5">
                        <div className="flex items-center gap-3 text-sm tracking-wide" >
                            <TrendUp size={22} color="white" />
                            Trending
                        </div>
                        <button onClick={() => router.push("/stock/videos?tag=Skin Care")} className="py-2 px-4 border border-white hover:border-transparent rounded-full text-sm tracking-wide hover:bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] ">Skincare</button>
                        <button onClick={() => router.push("/stock/videos?tag=Sports Nutrition")} className="py-2 px-4 border border-white hover:border-transparent rounded-full text-sm tracking-wide hover:bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] ">Sports Nutrition</button>
                        <button onClick={() => router.push("/stock/videos?tag=Face Makeup")} className="py-2 px-4 border border-white hover:border-transparent rounded-full text-sm tracking-wide hover:bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] ">Face Makeup</button>
                    </div>
                </div>
                <div className="mt-10 md:mt-0">
                    <video playsInline
                        className="max-h-[500px] aspect-[9/16] m-auto h-full rounded-md"

                        // width="100%"
                        autoplay="true"
                        muted="true"
                        loop="true"
                        controls
                        disablePictureInPicture
                        controlsList="noplaybackrate nodownload"
                        preload="auto">
                        <source src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhero%201.mp4?alt=media&token=acce40e3-0bb8-4bb7-941a-034bca1cdffa" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className=" bg-[#171717] w-full">
                <div className="max-w-[1352px] mx-auto relative pt-[30px] pb-[50px] px-[30px]">
                    <p className="text-[33px] md:text-[58px] text-center font-medium">It only takes these 4 simple steps</p>
                    <div className=" grid md:grid-cols-2 lg:grid-cols-4 my-[30px]">
                        <div className="flex flex-col w-full md:items-center">
                            <div className="flex items-center gap-1 ">
                                <div className="flex-1 hidden w-full opacity-0 md:flex">
                                    <div data-framer-component-type="SVG" data-framer-name="dots" className="framer-12zgicx" style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20239%2015%22%3E%3Cg%20transform%3D%22translate(1%200)%22%20id%3D%22ss3332761798_1%22%3E%3Cpath%20d%3D%22M%205.386%200%20L%20230.075%200%20L%20230.075%2015%20L%205.386%2015%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%200%208%20L%20237%208%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(189%2C189%2C189)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.1%2C%208%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")`, opacity: 1, flex: "none", height: 15, position: "relative", width: 239, }}></div>
                                </div>
                                <div className="relative flex items-center h-10 pr-8 text-black bg-white rounded-full hover:bg-gradient-to-r min-w-fit">
                                    <div className="absolute h-9 aspect-square left-[2px] rounded-full flex items-center justify-center bg-[#121212] text-white">1</div>
                                    <div className="pl-12">Step 1</div>
                                </div>
                                <div className="flex-1 hidden w-full opacity-0 md:flex lg:opacity-100">
                                    <div data-framer-component-type="SVG" data-framer-name="dots" className="framer-12zgicx" style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20239%2015%22%3E%3Cg%20transform%3D%22translate(1%200)%22%20id%3D%22ss3332761798_1%22%3E%3Cpath%20d%3D%22M%205.386%200%20L%20230.075%200%20L%20230.075%2015%20L%205.386%2015%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%200%208%20L%20237%208%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(189%2C189%2C189)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.1%2C%208%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")`, opacity: 1, flex: "none", height: 15, position: "relative", width: 239, }}></div>
                                </div>
                            </div>
                            <div className="w-full my-5 md:text-center">
                                <p className="text-xl text-white">Explore our UGC stock video library.</p>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:items-center">
                            <div className="flex items-center gap-1 ">
                                <div className="flex-1 hidden w-full opacity-0 md:flex ">
                                    <div data-framer-component-type="SVG" data-framer-name="dots" className="framer-12zgicx" style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20239%2015%22%3E%3Cg%20transform%3D%22translate(1%200)%22%20id%3D%22ss3332761798_1%22%3E%3Cpath%20d%3D%22M%205.386%200%20L%20230.075%200%20L%20230.075%2015%20L%205.386%2015%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%200%208%20L%20237%208%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(189%2C189%2C189)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.1%2C%208%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")`, opacity: 1, flex: "none", height: 15, position: "relative", width: 239, }}></div>
                                </div>
                                <div className="relative flex items-center h-10 pr-8 text-black bg-white rounded-full min-w-fit">
                                    <div className="absolute h-9 aspect-square left-[2px] rounded-full flex items-center justify-center bg-[#121212] text-white">2</div>
                                    <div className="pl-12">Step 2</div>
                                </div>
                                <div className="flex-1 hidden w-full opacity-0 md:flex lg:opacity-100">
                                    <div data-framer-component-type="SVG" data-framer-name="dots" className="framer-12zgicx" style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20239%2015%22%3E%3Cg%20transform%3D%22translate(1%200)%22%20id%3D%22ss3332761798_1%22%3E%3Cpath%20d%3D%22M%205.386%200%20L%20230.075%200%20L%20230.075%2015%20L%205.386%2015%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%200%208%20L%20237%208%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(189%2C189%2C189)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.1%2C%208%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")`, opacity: 1, flex: "none", height: 15, position: "relative", width: 239, }}></div>
                                </div>
                            </div>
                            <div className="w-full my-5 md:text-center">
                                <p className="text-xl text-white">Found your favorite? Buy it!</p>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:items-center">
                            <div className="flex items-center gap-1 ">
                                <div className="flex-1 hidden w-full opacity-0 md:flex">
                                    <div data-framer-component-type="SVG" data-framer-name="dots" className="framer-12zgicx" style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20239%2015%22%3E%3Cg%20transform%3D%22translate(1%200)%22%20id%3D%22ss3332761798_1%22%3E%3Cpath%20d%3D%22M%205.386%200%20L%20230.075%200%20L%20230.075%2015%20L%205.386%2015%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%200%208%20L%20237%208%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(189%2C189%2C189)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.1%2C%208%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")`, opacity: 1, flex: "none", height: 15, position: "relative", width: 239, }}></div>
                                </div>
                                <div className="relative flex items-center h-10 pr-8 text-black bg-white rounded-full min-w-fit">
                                    <div className="absolute h-9 aspect-square left-[2px] rounded-full flex items-center justify-center bg-[#121212] text-white">3</div>
                                    <div className="pl-12">Step 3</div>
                                </div>
                                <div className="flex-1 hidden w-full opacity-0 md:flex lg:opacity-100">
                                    <div data-framer-component-type="SVG" data-framer-name="dots" className="framer-12zgicx" style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20239%2015%22%3E%3Cg%20transform%3D%22translate(1%200)%22%20id%3D%22ss3332761798_1%22%3E%3Cpath%20d%3D%22M%205.386%200%20L%20230.075%200%20L%20230.075%2015%20L%205.386%2015%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%200%208%20L%20237%208%22%20fill%3D%22transparent%22%20stroke-width%3D%222%22%20stroke%3D%22rgb(189%2C189%2C189)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%220.1%2C%208%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")`, opacity: 1, flex: "none", height: 15, position: "relative", width: 239, }}></div>
                                </div>
                            </div>
                            <div className="w-full my-5 md:text-center">
                                <p className="text-xl text-white">Download the video within seconds.</p>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:items-center">
                            <div className="relative flex items-center h-10 pr-8 text-black bg-white rounded-full w-fit">
                                <div className="absolute h-9 aspect-square left-[2px] rounded-full flex items-center justify-center bg-[#121212] text-white">4</div>
                                <div className="pl-12">Step 4</div>
                            </div>
                            <div className="w-full my-5 md:text-center">
                                <p className="text-xl text-white">Use it freely, anywhere you want!</p>
                            </div>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 gap-10 py-[50px]">
                        <div className="flex flex-col justify-center order-2 w-full h-full md:order-1">
                            <p className="text-[30px] md:text-[48px] leading-tight my-[15px] font-medium">Explore our library of stock UGC videos</p>
                            <p className="text-[20px] mb-[20px] font-light tracking-wide">
                                Delve into our extensive collection of UGC stock videos, encompassing a variety of categories, video types, keywords, and other variables to discover the ideal fit for your upcoming marketing campaign.
                            </p>
                            <button onClick={() => router.push("/stock/videos")} className="py-2 px-8 w-full md:w-fit rounded bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                Buy Now
                            </button>
                        </div>
                        <div className="order-1 w-full h-full md:order-2 ">
                            <img
                                decoding="async"
                                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2Fugc%201.png?alt=media&token=af60fda8-ea4d-4966-9a55-5aec1c9523e0"
                                alt=""
                                className="w-full h-auto rounded-xl"
                            ></img>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-10 py-[50px]">
                        <div className="flex flex-col justify-center order-2 w-full h-full">
                            <p className="text-[30px] md:text-[48px] leading-tight my-[15px] font-medium">Found your match? Make it yours!</p>
                            <p className="text-[20px] mb-[20px] font-light tracking-wide">
                                Select a video (or even multiple videos!) that aligns perfectly with your current needs and marketing objectives. With a simple purchasing process, you can acquire the video you love in just seconds!
                            </p>
                            <button onClick={() => router.push("/stock/videos")} className="py-2 px-8 w-full md:w-fit rounded bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                Buy Now
                            </button>
                        </div>
                        <div className="w-full h-full">
                            <img
                                decoding="async"
                                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2Fugc2.png?alt=media&token=c9104dbd-5ff3-4172-98cc-b09ccf69e3c2"
                                alt=""
                                className="w-full h-auto rounded-xl"
                            ></img>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-10 py-[50px]">
                        <div className="flex flex-col justify-center order-2 w-full h-full md:order-1">
                            <p className="text-[30px] md:text-[48px] leading-tight my-[15px] font-medium">Grab the video in seconds!</p>
                            <p className="text-[20px] mb-[20px] font-light tracking-wide">
                                Yes, it's truly that easy—purchase and download instantly!
                            </p>
                            <button onClick={() => router.push("/stock/videos")} className="py-2 px-8 w-full md:w-fit rounded bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                Buy Now
                            </button>
                        </div>
                        <div className="order-1 w-full h-full md:order-2">
                            <img
                                decoding="async"
                                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2Fdownloads.png?alt=media&token=f0dba61d-6160-4e55-8ce0-47896011296b"
                                alt=""
                                className="w-full h-auto rounded-xl"
                            ></img>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-10 py-[50px]">
                        <div className="flex flex-col justify-center order-2 w-full h-full">
                            <p className="text-[30px] md:text-[48px] leading-tight my-[15px] font-medium">Use it freely, anywhere you want!</p>
                            <p className="text-[20px] mb-[20px] font-light tracking-wide">
                                No rules – use the video for your website, social media, or ads as you please.
                            </p>
                            <button onClick={() => router.push("/stock/videos")} className="py-2 px-8 w-full md:w-fit rounded bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                                Buy Now
                            </button>
                        </div>
                        <div className="w-full h-full ">
                            <img
                                decoding="async"
                                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock%2Fhome%2Fugc%204.jpg?alt=media&token=bd3c83a9-65a4-4022-a43b-d8c59e07f8b2"
                                alt=""
                                className="w-full h-auto rounded-xl"
                            ></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-[1352px] mx-auto relative pt-[30px] pb-[50px] px-[30px]">
                <ul className="grid w-full md:grid-cols-3 gap-x-10">
                    {categories?.map((category, index) => (
                        <a href={`/stock/videos?tag=${category?.tag}`} key={index}>
                            <li className="mb-[10px] border rounded-md py-2 px-5 w-full md:w-fit hover:border-transparent hover:bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] text-[14px] md:text-[16px] leading-[25px] block tracking-wide cursor-pointer">{category?.name}</li>
                        </a>
                    ))}
                </ul>
            </div>
            <NotloginfooterNew />
        </div>
    );
}
