import React, { useState } from "react";
import { videoLanguages } from "../context/consts";

function SelectVideoLanguage({ setUpward }) {
  const [selected, setSelected] = useState(null);
  return (
    <div>
      <p className="my-2">Language</p>
      <div className="flex flex-wrap w-full gap-2">
        {videoLanguages.map((lang) => (
          <button
            onClick={() => {
              setSelected(lang);
              setUpward(lang);
            }}
            key={lang}
            className={`${selected === lang ? "bg-[#93A8F4]" : "bg-black"
              } hover:bg-[#93A899] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
          >
            {lang}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SelectVideoLanguage;
