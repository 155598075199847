import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../firebase';

export default function Badgesembed() {
    const { brandId, badge } = useParams();

    useEffect(() => {
        getDoc(doc(db, "badges", brandId), document).then((res) => {

        })
    }, [])

    return (
        <>
            <div className='h-[250px] aspect-square flex items-center justify-center'>
                <img src={require(`../assets/images/badges/${badge}.png`)} alt="" className="h-auto w-auto max-h-[85%] max-w-[85%]" />
            </div>
        </>
    )
}
