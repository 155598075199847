import React from 'react'

export default function FacebookFeed({ user, caption, review }) {
    return (
        <div className="flex flex-col bg-white rounded">
            {/* {user?.social?.facebook_pages?.map((page, index) => ( */}
            <div className="flex my-3 ml-3">
                <div className="flex w-10 h-10 bg-black rounded-full">
                    {user?.social?.facebook_pages > 0 &&
                        <img
                            className="h-10 rounded-full cursor-pointer aspect-square"
                            src={user?.social?.facebook_pages[0]?.page?.picture?.data.url}
                            alt=""
                        />
                    }
                </div>
                <div className="pl-2">
                    {user?.social?.facebook_pages > 0 && <p className="font-medium text-[#050505] text-[15px]">
                        {user?.social?.facebook_pages[0]?.page?.name}
                    </p>
                    }
                    <div className="flex items-center gap-1">
                        <p className="text-[#65676B] text-[13px] leading-none">
                            Just now{" "}
                        </p>
                        <i
                            data-visualcompletion="css-img"
                            className="x1b0d499 x1d69dk1"
                            style={{
                                backgroundImage:
                                    'url("https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/oP-Rui4Wcby.png")',
                                backgroundPosition: "-102px -361px",
                                backgroundSize: "auto",
                                width: 10,
                                height: 10,
                                backgroundRepeat: "no-repeat",
                                display: "inline-block",
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* ))} */}
            <div className="ml-3">
                {caption?.length === 0 ? (
                    <div className="pb-2 space-y-2">
                        <div className="bg-[#F1F4F7] w-[80%] rounded-full h-3"></div>
                        <div className="bg-[#F1F4F7] w-[40%] rounded-full h-3"></div>
                    </div>
                ) : (
                    <div className="pb-2">
                        <p className="text-[.9375rem] text-[#050505]">
                            {caption}
                        </p>
                    </div>
                )}
            </div>
            <div className={`w-full aspect-square`}>
                {review?.reviewUrl ? (
                    <video playsInline
                        src={review?.reviewUrl}
                        className="object-cover w-full aspect-square"
                        controls={false}
                        disablePictureInPicture
                    ></video>
                ) : (
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffacebookpreview.png?alt=media&token=ca5406fc-100f-4835-834a-318efffc4e94"
                        alt="preview"
                        className="object-cover w-full aspect-square"
                    />
                )}
            </div>
            <div className="w-full  h-[44px] grid grid-cols-3">
                <div className="flex items-center justify-center w-full gap-2 faceboookicons">
                    <i
                        data-visualcompletion="css-img"
                        className=""
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/Ima_pXlhqeL.png")',
                            backgroundPosition: "0 -760px",
                            backgroundSize: "auto",
                            width: 20,
                            height: 20,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                    <p className="text-[15px] text-[#65676B]">Like</p>
                </div>
                <div className="flex items-center justify-center w-full gap-2 faceboookicons">
                    <i
                        data-visualcompletion="css-img"
                        className=""
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/Ima_pXlhqeL.png")',
                            backgroundPosition: "0 -571px",
                            backgroundSize: "auto",
                            width: 20,
                            height: 20,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                    <p className="text-[15px] text-[#65676B]">Comment</p>
                </div>
                <div className="flex items-center justify-center w-full gap-2 faceboookicons">
                    <i
                        data-visualcompletion="css-img"
                        className=""
                        style={{
                            backgroundImage:
                                'url("https://static.xx.fbcdn.net/rsrc.php/v3/yq/r/Ima_pXlhqeL.png")',
                            backgroundPosition: "0 -907px",
                            backgroundSize: "auto",
                            width: 20,
                            height: 20,
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                        }}
                    />
                    <p className="text-[15px] text-[#65676B]">Share</p>
                </div>
            </div>
        </div>
    )
}
