import React from 'react'

export default function Loadingdot() {
    return (
        <div className="flex items-center justify-center flex-1 w-full h-full text-white">
            <div className="spinner-box">
                <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                    <div className="pulse-bubble pulse-bubble-4"></div>
                    <div className="pulse-bubble pulse-bubble-5"></div>
                </div>
            </div>
        </div>
    )
}
