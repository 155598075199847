import React from 'react';
import { useHistory, useLocation, Switch, Route, useRouteMatch } from 'react-router';
import Leads from './emails/leads';
import Templates from './emails/templates';
import Campaigns from './emails/campaigns';

export default function Emails() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const location = useLocation();
    const activetab = useLocation().search;
    const currentTab = new URLSearchParams(activetab);

    return (
        <div className="flex w-full h-screen">
            <div className="h-screen overflow-hidden w-[200px] bg-[#1e1f25] p-[8px]">
                <p className="text-xs tracking-wide px-[12px] py-[6px]">Emails : </p>
                <div className="">
                    <button
                        className={`px-[12px] py-[8px] rounded-md bg-transparent  ${(currentTab.get("tab") === null || currentTab.get("tab")?.includes("leads"))
                            ? "bg-[#131418]"
                            : "hover:bg-[#131418]"
                            } w-full flex items-center gap-3 text-xs tracking-wide`}
                        onClick={() => history.push("/admin/emails?tab=leads")}
                    >
                        Leads
                    </button>
                    <button
                        className={`px-[12px] py-[8px] rounded-md bg-transparent  ${currentTab.get("tab")?.includes("templates")
                            ? "bg-[#131418]"
                            : "hover:bg-[#131418]"
                            } w-full flex items-center gap-3 text-xs tracking-wide`}
                        onClick={() => history.push("/admin/emails?tab=templates")}
                    >
                        Templates
                    </button>
                    <button
                        className={`px-[12px] py-[8px] rounded-md bg-transparent  ${currentTab.get("tab")?.includes("campaigns")
                            ? "bg-[#131418]"
                            : "hover:bg-[#131418]"
                            } w-full flex items-center gap-3 text-xs tracking-wide`}
                        onClick={() => history.push("/admin/emails?tab=campaigns")}
                    >
                        Campaigns
                    </button>
                </div>
            </div>
            <div className="flex-1 w-full h-screen overflow-y-auto">
                {(currentTab.get("tab") === null || currentTab.get("tab")?.includes("leads")) && (
                    <Leads />
                )}
                {currentTab.get("tab")?.includes("templates") && (
                    <Templates />
                )}
                {currentTab.get("tab")?.includes("campaigns") && (
                    <Campaigns />
                )}

            </div>
        </div>
    );
}
