import React, { useEffect, useRef, useState } from "react";
import DashboardCard from "../components/dashboardCard";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useStateValue } from "../context/StateProvider";
import AWN from "awesome-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProjectCard from "./ProjectCard";
import { Plus, X } from "@phosphor-icons/react";
import Modalcard from "../components/modalcard";
import { showWarning } from "../components/toast/toast";

function AIvideoFrontPage() {
  const [{ user, brand }, dispatch] = useStateValue();
  const [projects, setProjects] = useState([]);
  const [filter, setfilter] = useState("All");
  const [createProject, setCreateProject] = useState(false);

  let projectName = useRef();
  const router = useHistory();

  useEffect(() => {
    getDocs(
      query(
        collection(db, "aivideosProjects"),
        where("brandId", "==", user.brandId)
      )
    ).then((res) => {
      setProjects(res.docs.map((d) => ({ ...d.data(), id: d.id })));
    });
  }, []);

  const createProjectFun = () => {
    const prName = projectName.current.value;
    if (!prName || prName.length < 3) {
      showWarning("Please enter more than 4 letters");
      return;
    }
    var project = {
      name: prName,
      brandId: user.brandId,
      date: Date.now(),
      regenerate: 0,
      draft: true,
      published: false,
    };

    addDoc(collection(db, "aivideosProjects"), project).then((res) => {
      setProjects([...projects, { ...project, id: res.id }]);
      router.push("/admin/generate-ai-video/" + res.id);
    });
  };
  //generate-ai-video
  return (
    <div className="relative min-h-screen p-5 overflow-y-auto ">
      <p className="text-3xl ">Generate your AI Video ads in just 20 seconds</p>
      <div className="flex flex-wrap gap-5 pt-10 ">
        <DashboardCard text={"Total Projects"} value={projects.length} />
        <DashboardCard
          text={"Published"}
          value={projects.filter((r) => !r.draft).length}
        />
        <DashboardCard
          text={"Draft"}
          value={projects.filter((r) => !r.published).length}
        />
      </div>
      <div className="flex items-center justify-between w-full gap-5">
        <div className="flex gap-2 p-1 my-8 text-black bg-white rounded-full buttonGroup w-min">
          <button
            onClick={() => setfilter("All")}
            className={
              filter === "All"
                ? "px-4 py-2 rounded-full bg-black text-white"
                : "px-4 py-2 rounded-full"
            }
          >
            All
          </button>
          <button
            onClick={() => setfilter("Draft")}
            className={
              filter === "Draft"
                ? "px-4 py-2 rounded-full bg-black text-white"
                : "px-4 py-2 rounded-full"
            }
          >
            Draft
          </button>
          <button
            onClick={() => setfilter("Published")}
            className={
              filter === "Published"
                ? "px-4 py-2 rounded-full bg-black text-white"
                : "px-4 py-2 rounded-full"
            }
          >
            Published
          </button>
        </div>
        <div className="">
          <button
            className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
            onClick={() => setCreateProject(true)}
          >
            <Plus size={22} color="white" /> Create
          </button>
        </div>
      </div>
      {projects.length !== 0 && (
        <div className="grid gap-4 md:grid-cols-3 xl:grid-cols-4">
          {filter === "All" &&
            projects
              .sort((a, b) => b.date - a.date)
              .map((pr) => <ProjectCard key={pr.id} data={pr} />)}
          {projects
            .filter((f) => f[filter.toLowerCase()])
            .sort((a, b) => b.date - a.date)
            .map((pr) => (
              <ProjectCard key={pr.id} data={pr} />
            ))}
        </div>
      )}
      {projects.length === 0 && (
        <div className="flex items-center justify-center h-96">
          <p className="">No current projects.</p>
        </div>
      )}
      {createProject && (

        <Modalcard close={createProject}>
          <div className="w-[600px] bg-[#202020] p-5 rounded-md">
            <div className="flex items-center justify-between">
              <p className="text-2xl borber-b">Create Project</p>
              <button className="" onClick={() => setCreateProject(false)}>
                <X size={22} color="white" />
              </button>
            </div>
            <div className="mt-6 ">
              <div className="">
                <p className="text-[#F8F8F8]">Project Name</p>
                <input
                  type="text"
                  className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                  placeholder="Enter project name"
                  ref={projectName}
                />
              </div>
              <br />
              <button
                onClick={createProjectFun}
                className="py-2 px-4 transform duration-150 bg-[#93A8F4] hover:bg-[#4611ea] rounded"
              >
                Create Now
              </button>
            </div>
          </div>
        </Modalcard>
      )}
    </div>
  );
}

export default AIvideoFrontPage;
