import React from 'react'
import { useStateValue } from '../../context/StateProvider';

export default function BrandInformation() {
    const [{ brand, user }, dispatch] = useStateValue();
    return (
        <div className='relative w-full h-screen overflow-y-auto'>
            <div className="h-14 w-full border-b flex items-center px-5 border-[#808080]">
                <p className="text-lg font-light tracking-wide">Brand Information</p>
            </div>
            <div className="p-10">
                <div className="flex items-start border-b border-[#8080801f] py-5">
                    <div className="w-40 font-medium tracking-wide text-[#808080]">
                        Display Name
                    </div>
                    <div className="flex-1 text-sm">
                        {brand?.name}
                    </div>
                </div>
                <div className="flex items-start border-b border-[#8080801f] py-5">
                    <div className="w-40 font-medium tracking-wide text-[#808080]">
                        Photo
                    </div>
                    <div className="flex-1">
                        <img src={brand?.imageUrl} alt="logo" className="h-20 rounded-full aspect-square" />
                    </div>
                </div>
                <div className="flex items-start border-b border-[#8080801f] py-5">
                    <div className="w-40 font-medium tracking-wide text-[#808080]">
                        Description
                    </div>
                    <div className="flex-1 text-sm">
                        {brand?.description}
                    </div>
                </div>
            </div>
        </div>
    )
}
