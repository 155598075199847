import React, { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Cstats from "./view/Cstats";
import { useStateValue } from "../context/StateProvider";
import { sendAmplitudeData } from "../amplitude";
import { Eye, Hexagon, Plus, ShieldSlash } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { showWarning } from "../components/toast/toast";

function Cdashboard() {
  const [forms, setforms] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const { path } = useRouteMatch();
  const router = useHistory();

  useEffect(() => {
    sendAmplitudeData("campaign dashboard page opened");

    const fetchData = async () => {
      try {
        const q = query(
          collection(db, "forms"),
          where("brandemail", "==", user?.email)
        );

        const querySnapshot = await getDocs(q);
        const formsData = querySnapshot.docs.map(doc => doc.data());

        const formsCountPerProductId = formsData.reduce((acc, form) => {
          acc[form.prodid] = (acc[form.prodid] || 0) + 1;
          return acc;
        }, {});

        const uniqueFormsData = Object.keys(formsCountPerProductId).map(prodid => {
          const matchingForm = formsData.find(form => form.prodid === prodid);
          return {
            ...matchingForm,
            campaigns: formsCountPerProductId[prodid],
          };
        });

        setforms(uniqueFormsData);
        setloading(false);
      } catch (error) {
        console.error("Error fetching forms data:", error);
        setloading(false);
      }
    };

    fetchData();
  }, [user?.email]);


  async function changeRoute() {
    let limit = user?.limit?.campaignLimit ?? 1;
    if ((user?.campaigns ?? forms?.length) >= limit) {
      showWarning("Campaign limit exceeded, Please upgrade your plan.");
      return;
    }
    router.push(path + "/chooseTemplate");
  }

  if (loading)
    return (
      <div className=" min-h-screen -mt-16 relative flex items-center justify-center overflow-y-auto bg-[#323336] py-16 px-20">
        <p className="">Gettings your forms ready</p>
      </div>
    );

  if (forms.length === 0) {
    return (
      <div className="relative min-h-screen px-20 py-16 -mt-16 overflow-y-auto ">
        <div className="">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffaq%20initial%20login%20image.png?alt=media&token=e461dbfd-68e5-44c8-aeea-69a539e20f54"
            alt="rightImage"
            className="absolute right-0 bottom-0 max-h-[80vh]"
          />
        </div>
        <div className="pt-28">
          <p className="text-6xl font-semibold">Welcome</p>
          <div className="mt-8">
            <p className="mb-1 text-xl font-medium tracking-wider">
              What is campaign ?
            </p>
            <p className="font-light tracking-wider">
              Start by creating a video collector. This will let you remotely
              gather videos from any device just by sharing a link.
            </p>
          </div>
          <div className="my-8">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create Campaign
            </button>
          </div>
        </div>
        <Cstats />
      </div>
    );
  } else
    return (
      <div className="min-h-screen ">
        <div className="px-10 py-5">
          <div className="w-full lg:max-w-[70%] mx-auto grid grid-cols-3 gap-5">
            <div className="w-full h-auto p-3 py-5 my-2 text-center border-2 border-white rounded-xl">
              <p className="mx-auto text-lg font-medium tracking-wider">
                Campaigns used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.campaigns ?? 0} / <span className="">{user?.limit?.campaignLimit ?? 1}</span>
                </span>
              </div>
            </div>
            <div className="w-full h-auto p-3 py-5 my-2 text-center border-2 border-white rounded-xl">
              <p className="mx-auto text-lg font-medium tracking-wider">
                Downloads used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.downloads ?? 0} / <span className=""> {user?.limit?.downloadLimit === 5000 ? "Unlimited" : user?.limit?.downloadLimit ?? 3}</span>
                </span>
              </div>
            </div>
            <div className="w-full h-auto p-3 py-5 my-2 text-center border-2 border-white rounded-xl">
              <p className="mx-auto text-lg font-medium tracking-wider">
                Comments used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.comments ?? 0}/ <span className="">{user?.limit?.commentLimit ?? 0}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between p-10">
          <div className="">
            <p className="text-3xl font-medium">Your Campaigns</p>
            <p className="tracking-wide">
              Use campaigns to collect testimonials from your users.
            </p>
          </div>
          <div className="">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create Campaign
            </button>
          </div>
        </div>

        <div className="grid w-full p-5 pb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-16 ">
          {forms?.sort((a, b) => b.dateAdded - a.dateAdded)?.map((form, index) => (
            <div
              className=" cursor-pointer w-full bg-[#232323] h-[173px] rounded-3xl relative flex flex-col justify-between"
              key={index}
              onClick={() =>
                router.push(path + "/view-product-campaigns/" + form?.prodid)
              }
            >
              <div className="">
                <div className="flex items-center gap-3 p-3">
                  <img
                    src={form?.productimage}
                    alt="thumbnail"
                    className="h-[85px] aspect-square rounded-lg -mt-8"
                  />
                  <div className="">
                    {/* <p className="text-sm text-[#D9D9D9] tracking-wide">Campaign Id: <span className="text-white"> {form?.id}</span></p> */}
                  </div>
                </div>
                <div className="flex items-center justify-between px-3">
                  <div className="">
                    <div className="flex items-center gap-3">
                      <p className="text-sm text-[#D9D9D9] font-work tracking-wide">
                        Product ID
                      </p>
                      <p className="font-work">{form?.prodid}</p>
                    </div>
                    <div className="flex items-center gap-3">
                      <p className="text-sm text-[#D9D9D9] font-work tracking-wide">Campaigns</p>
                      <p className="font-work">{form?.campaigns}</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="relative flex items-center justify-center w-6 h-6">
                      <Hexagon size={22} weight="light" />
                      <Eye size={10} className="absolute" weight="bold" />
                    </div>
                    <p className="text-[8px] tracking-wide">View</p>
                  </div>
                </div>
              </div>
              <div className="h-20 overflow-clip text-sm font-light tracking-wide py-2 px-3 bg-[#2C2C2C] text-[#D9D9D9] rounded-b-3xl">
                {form?.productname}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Cdashboard;
