import { AmazonLogo, CheckCircle, Checks, ContactlessPayment, DotsThreeCircleVertical, DotsThreeVertical, ShieldSlash, Trash, UploadSimple, } from '@phosphor-icons/react';
import { Check, CircleNotch, FacebookLogo, InstagramLogo, Minus, Plus, SnapchatLogo, Star, TwitterLogo, YoutubeLogo } from 'phosphor-react';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { db, storage } from '../firebase';
import { useHistory } from 'react-router';
import { useStateValue } from '../context/StateProvider';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { toast } from 'react-toastify';
import Modalcard from '../components/modalcard';
import { addDoc, collection, doc, getDocs, increment, query, updateDoc, where } from 'firebase/firestore';
import { showSuccess, showWarning } from '../components/toast/toast';
export default function RequestCampaignNew() {
    const params = new URLSearchParams(window.location.search);
    const [{ brand, user, products }, dispatch] = useStateValue();
    const [id, setid] = useState(nanoid(20));
    const [product_type, setproduct_type] = useState(true);
    const [video_type, setvideo_type] = useState(1);
    const [video_Quality, setvideo_Quality] = useState(2);
    const [tab, settab] = useState(1);
    const [packages, setPackages] = useState(1);
    const [creatorType, setCreatorType] = useState(1);
    const [influencerType, setInfluencerType] = useState(1);
    const [duration, setduration] = useState(3);
    const [video_platform, setvideo_platform] = useState(1);
    const [video_format, setvideo_format] = useState(1);
    const [photos_product, setphotos_product] = useState(false);
    const [photos_description, setphotos_description] = useState('');
    const [addOn, setaddOn] = useState({
        successManager: null,
        scripts: 0
    })
    const [notes, setnotes] = useState([]);
    const [noteText, setNoteText] = useState('');
    const [criteria, setcriteria] = useState({
        gender: "",
        creatorPreference: "",
        language: ""
    });
    const [locations, setLocations] = useState([]);
    const [age, setage] = useState({
        eighteen: false,
        twentyfive: false,
        thirtyfive: false,
        fourtyfour: false
    })
    const [videos, setvideos] = useState(1);
    const [total, settotal] = useState(1500);
    const [delivery_Date, setdelivery_Date] = useState('');
    const [ad_product_type, setad_product_type] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [product_info, setproduct_info] = useState({
        productName: "",
        productLink: "",
        productValue: "",
        productSKU: "",
        estimatedDeliveryTime: "",
        sript: "",
        referenceVideo: "",
        id: ""
    });
    const [extraInfo, setExtraInfo] = useState({
        spokePersonName: "",
        spokePersonNumber: "",
        shipmentDate: "",
        attire: "",
        hairstyle: "",
        location: "",
        vocal: []
    });

    const [campaign_Details, setcampaign_Details] = useState({});
    const [productImage, setproductImage] = useState('');
    const [paymentDetails, setpaymentDetails] = useState({
        name: user?.name,
        email: user?.email,
        phone: ''
    });
    const [paymentLinkDetails, setpaymentLinkDetails] = useState({});
    const [fetchStatus, setfetchStatus] = useState(false);
    const [paymentTokenDetails, setpaymentTokenDetails] = useState({});
    const [productExist, setproductExist] = useState(null)
    const [videoMoal, setvideoMoal] = useState(false);
    const [videoModalUrl, setvideoModalUrl] = useState('');
    const [couponUsed, setcouponUsed] = useState(null);
    const [couponDiscount, setcouponDiscount] = useState(0);
    const [coupon_name, setcoupon_name] = useState(null);
    const [creators, setCreators] = useState(1);
    const [creatorSelected, setCreatorSelected] = useState([]);

    const router = useHistory();


    const inHouseCeators = [
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR1.webp?alt=media&token=45bdbe9d-797e-47cb-9e06-e860ebaade8b",
            gender: "female",
            id: "MR1",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol1.mp4?alt=media&token=1c623df3-3586-443e-857c-d4602a08ffd4',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol2.mp4?alt=media&token=b08eb87a-7c95-4963-8bf2-12dd60d78610',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol3.mp4?alt=media&token=8ba06cca-84de-4cf8-98b9-2924acb4c7e0',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnmol4.mp4?alt=media&token=2dfdd881-dfdc-4659-a635-2ff78ed66045',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR2.webp?alt=media&token=ef789f52-b273-4742-89bd-34d3d3edcb1a",
            gender: "female",
            id: "MR2",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini1.mp4?alt=media&token=f0c92456-32b9-4cfc-a8b0-713022702cdd',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FBhavini2.mp4?alt=media&token=6a0b0d49-b92b-4af5-ae47-0b00c5c96ede',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR3.webp?alt=media&token=03e74c19-ea6e-47a6-a47d-01bdd3191b3f",
            gender: "female",
            id: "MR3",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy1.mp4?alt=media&token=78ab7b00-1b2a-4e63-87ee-f11482faf725',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy2.mp4?alt=media&token=46106ffd-4267-4b02-9bbe-2899768fd2d8',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FDaisy3.mp4?alt=media&token=e581f916-91c8-4fff-9f01-bd38d10a7966',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR4.webp?alt=media&token=ad8b341f-7ace-41c9-ac27-0a46ac5d383a",
            gender: "female",
            id: "MR4",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan1.mp4?alt=media&token=7a4e45ee-9890-4c93-9e9c-1b320914eb16',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan2.mp4?alt=media&token=80a1ace7-6ed9-4a12-be33-88ede3708a29',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan3.mp4?alt=media&token=d4e574b4-f047-4b88-9a58-64d41b0c7a47',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMuskan4.mp4?alt=media&token=2b3aa29d-19f9-46bc-8352-5bb15098e2dd',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR5.webp?alt=media&token=7a2f4fab-9a30-41cf-a17c-2414a97962d9",
            gender: "female",
            id: "MR5",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak1.mp4?alt=media&token=521c8f94-f535-4f97-86d8-7ad2036b80ac',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak2.mp4?alt=media&token=bedd1e0b-3398-4e0d-a2fb-0bb13ceaf33a',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPalak3.mp4?alt=media&token=f7948f01-26c7-41b2-93e8-0820246244f5',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR6.webp?alt=media&token=fa352735-b0f7-4f7f-8382-9233fa85d09a",
            gender: "female",
            id: "MR6",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti1.mp4?alt=media&token=c6fc3274-15fe-48a9-ac1c-6327c63dbc73',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti2.mp4?alt=media&token=2e6d7c99-0d42-4384-9004-e58edddd1dd0',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti3.mp4?alt=media&token=09fb22a6-4e78-4092-9233-2afddcc74451',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti4.mp4?alt=media&token=a56c66cf-1665-4e1c-b662-a61aa764f553',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti5.mp4?alt=media&token=a01ccba0-0695-4504-83c3-52ff8f0a0a9f',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FPreeti6.mp4?alt=media&token=4bd5d875-6478-40c1-af1e-5d664415708a',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR7.webp?alt=media&token=a1fcb7ad-9a8e-43f5-a678-16a2b312df2e",
            gender: "female",
            id: "MR7",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi1.mp4?alt=media&token=35ed07fb-787e-4128-a458-c628861a51cd',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi2.mp4?alt=media&token=8b272888-898b-4d98-931d-70b44ad9c13c',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi3.mp4?alt=media&token=e4589efe-8fc2-4105-a9fa-1bf0a794ed1a',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi4.mp4?alt=media&token=6813ec5c-65c2-44c7-874e-fbabdf4d0756',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVashnavi5.mp4?alt=media&token=4274e1cf-b39b-41ad-adfe-a553ecea9142',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR8.webp?alt=media&token=15e6aecd-7edb-44f0-a070-8805f0306917",
            gender: "female",
            id: "MR8",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVarusha1.mp4?alt=media&token=f09a983d-64fe-4728-b9d5-dd974214cd08',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVarusha2.mp4?alt=media&token=36b8a21e-8076-4965-86f0-50d6e38b2857',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVarusha3.mp4?alt=media&token=d3522737-6d3f-4493-a298-37d6a119f9dc',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR51.webp?alt=media&token=8cab2891-74a4-47ba-bd00-16dbc28f8dc5",
            gender: "male",
            id: "MR9",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh1.mp4?alt=media&token=b7b7271e-de84-4944-a8dc-77565b207148',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FVedansh2.mp4?alt=media&token=5fef69a5-af4f-4a73-bbd2-74c381345ae0',
            ],
        },
        {
            dp: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FMR52.webp?alt=media&token=c4878f9c-12cb-4f02-aa46-3410ae999136",
            gender: "male",
            id: "MR10",
            videos: [
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag1.mp4?alt=media&token=172ad69d-7f1c-446e-a867-26bce250fc85',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag2.mp4?alt=media&token=28e9c419-161c-420b-a25a-36690f7d9029',
                'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fgetavideo%2FAnurag3.mp4?alt=media&token=202773d7-b9f2-4e83-8916-c5a5971751c5',
            ]
        }
    ]

    const videoTypes = ['', 'Video Ad', 'Unboxing Video', 'Tutorial Video', 'Get The Look Video', 'Haul Video', '360 Showcase Video', 'Testimonial Video', 'Store Visit Video'];
    const durationsec = [0, 15, 30, 60];
    const videoAd = ['', 'Facebook', 'Instagram', 'Twitter', 'Amazon', 'YouTube', 'Snapchat']
    const videoTypesDescription = ['', 'Best for ads on Facebook, Instagram, YouTube, etc.', 'Opening of new product and showcasing its content and features.', 'Record how to use or step by step instructions video.', 'Showcases how to recreate a specific style or look.', 'Showcasing and reviewing a collection of products they have purchased.', 'Show your product from every angle.', 'Highlights your product benefits.', 'Record a walkin video in brand store.'];
    const demoVideos = [{
        basic: "",
        premium: ""
    },
    {
        basic: "",
        premium: ""
    },
    {
        basic: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Funboxing%2FVideo%202.mp4?alt=media&token=c251602a-522f-4aea-b5b5-1535b2ad19a9",
        premium: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Funboxing%2FUnboxing%20Video%201.mp4?alt=media&token=3b5fb0b3-56be-4b92-ad5e-3e65999f1ed6"
    },
    {
        basic: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Ftutorial%2Fcostmetic%20makeup1.mp4?alt=media&token=2cc77824-7fe2-407b-a4c5-81c5f129a1b9",
        premium: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Ftutorial%2FTutorial.mp4?alt=media&token=c1043b77-5b31-483e-b7f7-1b0e40a00c55"
    },
    {
        basic: "",
        premium: ""
    },
    {
        basic: "",
        premium: ""
    },
    {
        basic: "",
        premium: ""
    }, {
        basic: "",
        premium: ""
    }, {
        basic: "",
        premium: ""
    }]

    useEffect(() => {
        if (!user) {
            router.replace("/")
        }
        handleCreatorType(1)
    }, [user, router])

    async function calculateCharge() {
        let photosPrice = 0;
        let videosPrice = 0;
        let scriptPrice = 0;
        let successManagerPrice = 0;
        if (creatorType === 1) {
            if (videos < 5) {
                setPackages(1)
                videosPrice = 8000
            }
            if (videos >= 5 && videos <= 9) {
                setPackages(2)
                videosPrice = 6000
            }
            if (videos >= 10 && videos <= 19) {
                setPackages(3)
                videosPrice = 5000
            }
            if (videos >= 20) {
                setPackages(4)
                videosPrice = 4000
            }
        }
        if (creatorType === 2) {
            if (influencerType === 1) {
                if (videos < 5) {
                    videosPrice = 4000
                }
                if (videos >= 5 && videos <= 9) {
                    videosPrice = 4000 * 0.95
                }
                if (videos >= 10) {
                    videosPrice = 4000 * 0.85
                }
            }
            if (influencerType === 2) {
                if (videos < 5) {
                    videosPrice = 7000
                }
                if (videos >= 5 && videos <= 9) {
                    videosPrice = 7000 * 0.95
                }
                if (videos >= 10) {
                    videosPrice = 7000 * 0.85
                }
            }
            if (influencerType === 3) {
                if (videos < 5) {
                    videosPrice = 12000
                }
                if (videos >= 5 && videos <= 9) {
                    videosPrice = 12000 * 0.95
                }
                if (videos >= 10) {
                    videosPrice = 12000 * 0.85
                }
            }
        }

        if (photos_product === true) {
            photosPrice = 800;
        }
        else if (photos_product === false) {
            photosPrice = 0;
        }
        if (addOn.successManager) {
            successManagerPrice = 699;
        }
        if (addOn.scripts) {
            successManagerPrice = 699;
        }
        settotal(videosPrice + photosPrice + successManagerPrice)
        return;
    }

    const calculateCreators = () => {
        if (videos === 1) {
            return 1;
        } else if (videos <= 5) {
            return 2;
        } else if (videos <= 10) {
            return 4;
        } else if (videos <= 15) {
            return 6;
        } else if (videos <= 20) {
            return 7;
        }
        else {
            return 10;
        }
    };
    useEffect(() => {
        calculateCharge();
        const creatorsNo = calculateCreators();
        setCreators(creatorType === 1 ? creatorsNo : videos)
    }, [videos, addOn, photos_product, creatorType, influencerType]);
    useEffect(() => {
        setaddOn({
            successManager: false,
            scripts: 0
        })
    }, [creatorType])


    useEffect(() => {
        if (fetchStatus === true) {
            const intervalId = setInterval(() => {
                fetchPaymentStatus()
            }, 10000);
            return () => clearInterval(intervalId)
        }

    }, [fetchStatus])


    async function fetchPaymentStatus() {
        const paymentStatusData = await axios.get(`https://prod-paymentapi-zscu3untuq-el.a.run.app/verifyPaymentToken/${paymentLinkDetails?.id}`);
        if (paymentStatusData?.data !== "") {
            if (paymentStatusData?.data?.payment_token?.status === "paid") {
                setpaymentTokenDetails({ discount: couponDiscount, couponName: coupon_name, ...paymentStatusData?.data });
                if (couponDiscount > 0) {
                    await updateDoc(doc(db, "getavideocoupons", couponUsed), { "used": increment(1) })
                }
                setfetchStatus(false);
                settab(3);
                return
            }
        }
    }





    async function createCampaign() {
        if (criteria?.gender === '') {
            showWarning('Please select a creator gender');
            return
        }
        if (selectedLanguages.length === 0) {
            showWarning('Please select atleast one language');
            return;
        }
        if (locations.length === 0) {
            showWarning('Please select atleast one location');
            return;
        }
        if (!Object.values(age).includes(true)) {
            showWarning('Please select atleast one age preference');
            return;
        }
        if (creatorType === 1) {
            if (creators !== creatorSelected.length) {
                return showWarning(`Please select atleast ${creators} creators`);
            }
        }
        if (creatorType === 2) {
            if (addOn.successManager === null) {
                return showWarning(`Please select a yes or no for success manager to continue`);
            }
        }
        const defaultPhotoDescription = `1. Instagram - style product shot in the natural environment
        2. Video thumbnail - high - quality image from the video shoot
        3. Selfie with a product
        4. Product highlight shot - product in use`;
        const ageRanges = Object.entries(age)
            .filter(([key, value]) => value)
            .map(([key]) => {
                switch (key) {
                    case 'eighteen':
                        return '18-24';
                    case 'twentyfive':
                        return '25-34';
                    case 'thirtyfive':
                        return '35-44';
                    case 'fourtyfour':
                        return '44+';
                    default:
                        return '';
                }
            });
        let campaign = {
            productType: product_type === true ? "Physical" : "Digital",
            videoType: videoTypes[video_type],
            videoTypesDescription: videoTypesDescription[video_type],
            videoQuality: video_Quality === 1 ? "Basic Video Quality" : "Premium Video Quality",
            videoQualityText: video_Quality === 1 ? "Basic quality is a home environment video. It’s simpler and usually shot on a smartphone." : "Premium quality videos are produced by a selection of more experienced creators with home studios. Expect more attention to detail: better lighting, solid color backdrops.",
            videoDuration: durationsec[duration],
            adDisplayed: videoAd[video_platform],
            videoFormat: "9:16",
            photos: photos_product,
            addOn: addOn,
            photosDescription: photos_product === true ? photos_description === '' ? defaultPhotoDescription : photos_description : null,
            creatorsType: creatorType === 1 ? "inhouse" : "influencers",
            creators: creatorType === 1 ? creatorSelected : null,
            creatorCriteria: {
                gender: criteria?.gender,
                language: selectedLanguages,
                age: ageRanges,
                preference: criteria?.creatorPreference
            },
            status: "In Review",
            email: user.email,
            brandId: brand.id,
            dateAdded: Date.now(),
        }
        const today = new Date();
        const future = new Date(today.setDate(today.getDate() + 14));
        const formattedDate = future.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
        setdelivery_Date(formattedDate)
        setcampaign_Details(campaign);
        settab(2);
    }


    async function createPayment() {
        if (paymentDetails?.email.length < 5 || paymentDetails?.name < 4 || paymentDetails.phone.length < 10) {
            showWarning('Please fill all details');
            return;
        }
        await generatePaymentLink()
        return
        let headersList = {
            "Accept": "*/*",
            "Content-Type": "application/json"
        }

        let bodyContent = {
            "name": paymentDetails?.name,
            "email": paymentDetails?.email,
            "phoneNumber": paymentDetails?.phone,
            "invoiceId": Date.now(),
            "currency": "INR",
            "amount": parseInt((total * videos) - couponDiscount),
            // "amount": 1,
        };

        let reqOptions = {
            url: "https://prod-paymentapi-zscu3untuq-el.a.run.app/createPaymentToken",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        }
        try {
            let response = await axios.request(reqOptions);
            setpaymentLinkDetails(response.data);
            triggerLayer(response.data)
            setfetchStatus(true);

        } catch (error) {
            showWarning(error.message);
        }
        function triggerLayer(data) {
            window.Layer.checkout(
                {
                    token: data?.id,
                    // accesskey: "2ee70b30-b33f-11ed-8323-af62a19e9aa0",
                    accesskey: "785ad3c0-e970-11ed-aa1c-7161138d8d0f",
                },
                function (response) {
                    if (response?.status === 'failed') {
                        showWarning('Something wrong happend, please fill details again to proceed');
                        setfetchStatus(false);
                        return;
                    }
                    if (response?.status === 'pending') {
                        showWarning('Payment is in pending, if amount is deducted from bank, it will get refunded within 24-48 hours.');
                        setfetchStatus(false);
                        return;
                    }
                    if (response !== null || response.length > 0) {
                        if (response.payment_id !== undefined) {
                            return;
                        }
                    }
                },
                function (err) {
                    setfetchStatus(false);
                    alert(err.message);
                    return;
                }
            );
        }


        // settab(3);

    }

    const handleAddNotes = (event) => {
        event.preventDefault();
        setnotes([...notes, noteText]);
        setNoteText('');
    };
    const handleRemoveNote = (indexToRemove) => {
        setnotes(notes.filter((note, index) => index !== indexToRemove));
    };


    function selectLanguage(checked, value) {
        const selectedLanguage = value;
        const isChecked = checked;

        if (isChecked === true) {
            setSelectedLanguages([...selectedLanguages, selectedLanguage]);
        } else {
            setSelectedLanguages(selectedLanguages.filter(lang => lang !== selectedLanguage));
        }
    }

    function selectLocations(checked, value) {
        const selectedLocation = value;
        const isChecked = checked;

        if (isChecked === true) {
            if (locations.length >= videos) return showWarning("Can't select more locations than no of videos")
            setLocations([...locations, selectedLocation]);
        } else {
            setLocations(locations.filter(loc => loc !== selectedLocation));
        }
    }
    async function submit() {
        if (!product_info?.productName || !product_info?.productLink || !product_info?.productValue || !product_info?.productSKU) {
            showWarning('Please fill all details.');
            return;
        }
        if (ad_product_type === "Giveaway" && !product_info?.estimatedDeliveryTime) {
            showWarning('Please add estimated delivery time.');
            return;
        }
        if (productImage === '') {
            showWarning('Please select product or upload image for new product.');
            return;
        }
        if (!extraInfo?.spokePersonName || !extraInfo?.spokePersonNumber) {
            showWarning('Please add brand spokeperson details');
            return;
        }
        let finalDoc = {
            ...campaign_Details,
            extra: extraInfo,
            totalAmount: total * videos,
            perVideoAmount: total,
            paymentToken: paymentTokenDetails ?? null,
            videos: videos,
            approved: 0,
            paymentStatus: tab === 3 ? "paid" : null,
            couonCode: coupon_name,
            productDetails: {
                productType: ad_product_type === "Giveaway" ? "Giveaway" : "Pre Owned Product",
                productName: product_info?.productName,
                productLink: product_info?.productLink,
                productValue: product_info?.productValue,
                productSKU: product_info?.productSKU,
                estimatedDeliveryTime: ad_product_type === "Giveaway" ? product_info?.estimatedDeliveryTime : "",
                script: product_info?.sript ?? '',
                referenceVideo: product_info?.referenceVideo ?? '',
                productImage: productImage,
                id: product_info?.id === '' ? null : product_info?.id
            },
            notes: notes
        }
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        }
        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/createHouseRequest`,
            method: "PUT",
            headers: headersList,
            data: finalDoc
        }
        await axios.request(reqOptions).then((res) => {
            router.push("/admin/inhouserequest")
        }).catch(err => showWarning(err.message))
    }

    async function uploadFile(event, type) {
        const image = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = async () => {
            const base64Image = reader.result.split(',')[1];
            const img = new Image();
            img.src = reader.result;
            img.onload = async () => {
                if (type === 'main') {
                    if (img.width !== img.height) {
                        showWarning('Product image aspect ratio must be 1:1');
                        return;
                    }
                    const fileName = `inhousecampaign/${id}/main.png`;
                    const storageRef = ref(storage, fileName);
                    await uploadString(storageRef, base64Image, 'base64', { contentType: 'image/png' });
                    const downloadURL = await getDownloadURL(storageRef);
                    setproductImage(downloadURL);
                }
            };
        };
    }
    async function handleVerbalChange(event) {
        const { name, checked } = event.target;
        let updatedVocal;
        if (checked) {
            if (videos === 1 && extraInfo.vocal.length === 1) {
                return showWarning("Can select only one vocal type")
            }
            updatedVocal = [...extraInfo.vocal, name];
        } else {
            updatedVocal = extraInfo.vocal.filter(item => item !== name);
        }
        setExtraInfo(prevState => ({
            ...prevState,
            vocal: updatedVocal
        }));
    };

    async function selectProduct(value) {
        if (value === "new") {
            setproductExist('new')
        }
        else {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25"
            }

            let reqOptions = {
                url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + value,
                method: "GET",
                headers: headersList,
            }

            let response = await axios.request(reqOptions);
            let prod = response.data;
            setproductExist(true);
            setproduct_info({
                productSKU: prod?.sku ?? '',
                productLink: prod?.affiliateLink ?? '',
                productName: prod?.name ?? '',
                id: prod?.id
            });
            setproductImage(prod?.imageUrl)
        }
    }

    async function closemodal() {
        setvideoMoal(false);
        setvideoModalUrl('')
    }

    async function checkcoupons(value) {
        const q = query(collection(db, "getavideocoupons"), where("couponName", "==", value));
        const docs = await getDocs(q).then(res => res.docs.map(doc => ({ id: doc.id, ...doc.data() }))).catch(err => showWarning(err.message));
        if (docs.length > 0) {
            if (docs[0].used < docs[0].quantity) {
                setcouponDiscount(docs[0].value);
                setcouponUsed(docs[0].id)
                showSuccess('Discount applied');
                setcouponUsed(docs[0].id);
                setcoupon_name(docs[0].couponName);
            }
            else {
                setcouponDiscount(0);
                showWarning('Coupon code is expired');
                setcouponUsed(null);
                setcoupon_name(null);
            }
        }
        else {
            setcouponDiscount(0);
            showWarning('Coupon code is not valid');
            setcouponUsed(null);
            setcoupon_name(null);
        }
    }



    async function saveDraft() {
        const defaultPhotoDescription = `1. Instagram - style product shot in the natural environment
        2. Video thumbnail - high - quality image from the video shoot
        3. Selfie with a product
        4. Product highlight shot - product in use`;
        const ageRanges = Object.entries(age)
            .filter(([key, value]) => value)
            .map(([key]) => {
                switch (key) {
                    case 'eighteen':
                        return '18-24';
                    case 'twentyfive':
                        return '25-34';
                    case 'thirtyfive':
                        return '35-44';
                    case 'fourtyfour':
                        return '44+';
                    default:
                        return '';
                }
            });

        const document = {
            productType: product_type === true ? "Physical" : "Digital",
            videoType: videoTypes[video_type],
            videoTypesDescription: videoTypesDescription[video_type],
            videoQuality: video_Quality === 1 ? "Basic Video Quality" : "Premium Video Quality",
            videoQualityText: video_Quality === 1 ? "Basic quality is a home environment video. It’s simpler and usually shot on a smartphone." : "Premium quality videos are produced by a selection of more experienced creators with home studios. Expect more attention to detail: better lighting, solid color backdrops.",
            videoDuration: durationsec[duration],
            adDisplayed: videoAd[video_platform],
            videoFormat: "9:16",
            photos: photos_product,
            creatorsType: creatorType === 1 ? "inhouse" : "influencers",
            creators: creatorType === 1 ? creatorSelected : null,
            photosDescription: photos_product === true ? photos_description === '' ? defaultPhotoDescription : photos_description : null,
            creatorCriteria: {
                gender: criteria?.gender,
                language: selectedLanguages,
                age: ageRanges,
                preference: criteria?.creatorPreference
            },
            status: "In Review",
            email: user.email,
            brandId: brand.id,
            dateAdded: Date.now(),
            totalAmount: total * videos,
            perVideoAmount: total,
            paymentToken: null,
            paymentStatus: tab === 3 ? "paid" : null,
            couonCode: coupon_name,
            videos: videos,
            approved: 0,
            productDetails: {
                productType: ad_product_type === "Giveaway" ? "Giveaway" : "Pre Owned Product",
                productName: product_info?.productName,
                productLink: product_info?.productLink,
                productValue: product_info?.productValue,
                productSKU: product_info?.productSKU,
                estimatedDeliveryTime: ad_product_type === "Giveaway" ? product_info?.estimatedDeliveryTime : "",
                script: product_info?.sript ?? '',
                referenceVideo: product_info?.referenceVideo ?? '',
                productImage: productImage,
                id: product_info?.id === '' ? null : product_info?.id
            },
            notes: notes
        }
        await addDoc(collection(db, "brandAdmin", user?.email, "draftGetaVideo"), document).then(res => {
            router.push("/admin/inhouserequest")
        })
    }

    async function generatePaymentLink() {
        setfetchStatus(true)
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let bodyContent = {
            amount: ((((videos * total) + (addOn.scripts * 399)) * 1.18) - couponDiscount).toFixed(0),
            currency: "INR",
            description: `Get A Video Order`,
            url: "ugc",
            metadata: {
                order_id: nanoid(10)
            }
        };

        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/razorpay/create/order/checkoutLink",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        setfetchStatus(false)
        makePayment(response.data)
        // window.location.href = response.data.url;
    }
    const makePayment = async (data) => {
        const res = await initializeRazorpay();
        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }
        var options = {
            key: "rzp_live_E4dtid6tq7cO0E", // Enter the Key ID generated from the Dashboard
            name: "MyRevue",
            currency: data.currency,
            amount: data.amount,
            order_id: data.id,
            description: data?.notes?.description ?? "Get A Video Order",
            image: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue.png?alt=media&token=b9dc6ef0-c272-49ba-a7c5-5bf51b46eda7",
            handler: function (response) {
                settab(3);
            },
            theme: {
                hide_topbar: false,
                color: '#FF005C',
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    const initializeRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    const handleSuccessManager = (value) => {
        if (value === true) {
            return setaddOn({ ...addOn, successManager: value });
        }
        setaddOn({ scripts: 0, successManager: value });
    };
    async function handleCreatorType(val) {
        if (val === 1) {
            setCreatorType(1);
            // setSelectedLanguages(["English", "Hindi"]);
            setage({ "eighteen": true, "twentyfive": true, "thirtyfive": false, "fourtyfour": false })
            setcriteria({ ...criteria, gender: "any" });
            setCreatorSelected([])
            return
        } else {
            setCreatorType(2)
            setSelectedLanguages([])
            setage({ "eighteen": false, "twentyfive": false, "thirtyfive": false, "fourtyfour": false })
            setcriteria({ ...criteria, gender: "" })
            setCreatorSelected([])
            return
        }
    }
    async function handleCreatorSelection(id) {
        if (creatorSelected.includes(id)) {
            // If the creator is already selected, deselect it
            setCreatorSelected(prevSelected => prevSelected.filter(item => item !== id));
        } else if (creatorSelected.length >= creators) {
            // If the maximum limit is reached, show a warning
            showWarning(`You can add a maximum of ${creators} creators to create ${videos} videos.`);
        } else {
            // Otherwise, add the creator to the selection
            setCreatorSelected(prevSelected => [...prevSelected, id]);
        }
    }

    async function closePreviewModal() {
        router.push("/admin/requestcampaign")
    }
    return (
        <div className='w-full h-screen overflow-y-auto bg-background'>
            {videoMoal === true && <>
                <Modalcard close={closemodal}>
                    <video playsInline src={videoModalUrl} controls controlsList='nodownload noplaybackrate' disablePictureInPicture className='h-[80vh] aspect-[9/16]'></video>
                </Modalcard>
            </>
            }
            {tab === 1 &&
                <>
                    <div className="grid grid-cols-3 gap-5 px-5 pt-5 ">
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93A8F4]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Video details</div>
                        </div>
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93a8f483]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Payment</div>
                        </div>
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93a8f483]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Video brief</div>
                        </div>
                    </div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="text-lg tracking-wide font-work ">Is your product physical or digital?</p>
                        <div className="flex items-center gap-5 mt-3 font-work">
                            <div onClick={() => setproduct_type(true)} className={`bg-[#23252d] cursor-pointer h-[122px] w-[200px] shadow-gray-600 border-4 ${product_type === true ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col items-center justify-center gap-1`}>
                                <div className="">
                                    <svg
                                        width="40"
                                        height="40"
                                        fill={`${product_type === true ? "#93A8F4" : "#ffffff"}`}
                                        className="bui-icon"
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="none" d="M0 0H24V24H0z"></path>
                                        <path d="M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-6-2c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2zm6 16H6V8h2v2c0 .55.45 1 1 1s1-.45 1-1V8h4v2c0 .55.45 1 1 1s1-.45 1-1V8h2v12z"></path>
                                    </svg>
                                </div>
                                <div className="font-work">Physical</div>
                            </div>
                            <div onClick={() => setproduct_type(false)} className={`bg-[#23252d] cursor-pointer h-[122px] w-[200px] shadow-gray-600 border-4 ${product_type === false ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col items-center justify-center gap-1`}>
                                <div className="">
                                    <svg
                                        width="40"
                                        height="40"
                                        fill={`${product_type === false ? "#93A8F4" : "#ffffff"}`}
                                        className="bui-icon"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"></path>
                                    </svg>
                                </div>
                                <div className="font-work">Digital</div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Video type</p>
                        <div className="flex flex-wrap gap-5">
                            <div onClick={() => setvideo_type(1)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 1 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[1]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[1]}</div>
                            </div>
                            <div onClick={() => setvideo_type(2)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 2 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[2]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[2]}</div>
                            </div>
                            <div onClick={() => setvideo_type(3)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 3 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[3]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[3]}</div>
                            </div>
                            <div onClick={() => setvideo_type(4)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 4 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[4]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[4]}</div>
                            </div>
                            <div onClick={() => setvideo_type(5)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 5 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[5]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[5]}</div>
                            </div>
                            <div onClick={() => setvideo_type(6)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 6 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[6]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[6]}</div>
                            </div>
                            <div onClick={() => setvideo_type(7)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 7 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[7]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[7]}</div>
                            </div>
                            <div onClick={() => setvideo_type(8)} className={`bg-[#23252d] cursor-pointer h-[142px] w-[200px] shadow-gray-600 border-4 ${video_type === 8 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work">{videoTypes[8]}</div>
                                <div className="text-[13px] font-work tracking-[0.4px]">{videoTypesDescription[8]}</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Video quality</p>
                        <div className="flex flex-wrap gap-5">
                            <div className={`bg-[#23252d] cursor-pointer h-[358px] w-[312px] shadow-gray-600 border-4 ${video_Quality === 1 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work h-[285px] w-full">
                                    <video playsInline  src={video_type === 2 ? demoVideos[video_type]?.basic : demoVideos[3]?.basic} className='object-cover object-center w-full h-full' onClick={() => { setvideoMoal(true); setvideoModalUrl(video_type === 2 ? demoVideos[video_type]?.basic : demoVideos[3]?.basic) }} />
                                </div>
                                <div className="flex-1 w-full h-full p-3 font-work ">
                                    <button className="h-full w-full rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                                        {video_Quality === 1 && <Check size={28} color='#6200ee' />}
                                        <p className="">Basic</p>
                                    </button>
                                </div>
                            </div>
                            <div className={`bg-[#23252d] cursor-pointer h-[358px] w-[312px] shadow-gray-600 border-4 ${video_Quality === 2 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col`}>
                                <div className="text-[#93A8F4] font-medium text-lg font-work h-[285px] w-full">
                                    <video playsInline  src={video_type === 2 ? demoVideos[video_type]?.premium : demoVideos[3]?.premium} className='object-cover object-center w-full h-full' onClick={() => { setvideoMoal(true); setvideoModalUrl(video_type === 2 ? demoVideos[video_type]?.premium : demoVideos[3]?.premium) }} />
                                </div>
                                <div className="flex-1 w-full h-full p-3 font-work ">
                                    <button className="h-full w-full rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                                        {video_Quality === 2 && <Star weight='fill' size={22} color='#6200ee' />}
                                        <p className="">Premium</p>
                                    </button>
                                </div>
                            </div>
                            <div className="bg-[#333640] p-3 rounded-md flex-1 w-full">
                                <p className="my-3 font-work"><span className="font-medium ">Basic quality</span> is a home environment video. It’s simpler and usually shot on a smartphone.</p>
                                <p className="my-3 font-work"><span className="font-medium font-work">Premium quality</span> videos are produced by a selection of more experienced creators with home studios. Expect more attention to detail: better lighting, solid color backdrops.</p>
                                <p className="my-3 font-work"><span className="font-medium underline underline-offset-2 text-[#93A8F4]">Learn more</span> about Basic vs Premium quality.</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Creator type</p>
                        <div className="flex flex-wrap gap-5">
                            <div onClick={() => { handleCreatorType(1); }} className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${creatorType === 1 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col items-center justify-center`}>
                                <div className={`text-center font-work mt-4 text-2xl font-medium ${packages === 1 ? "text-[#93A8F4]" : "text-white"}`}>Inhouse video</div>
                            </div>
                            <div onClick={() => { handleCreatorType(2) }} className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${creatorType === 2 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col items-center justify-center`}>
                                <div className={`text-center font-work mt-4 text-2xl font-medium ${packages === 2 ? "text-[#93A8F4]" : "text-white"}`}>Influencer video</div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-white/70 font-work">Inhouse video: We take care of script writing, content creator, videographer, quality check, editing</p>
                            <p className="text-white/70 font-work">Influencer video: Influencer is reponsible of all shooting & editing</p>
                        </div>
                    </div>

                    {creatorType === 1 &&
                        <>
                            <div className="border-b mt-10 m-5 border-[#555555]"></div>
                            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                                <p className="pb-3 text-lg tracking-wide font-work">Video Packages <span className="text-sm text-gray-400">* Pricing is exclusive taxes</span></p>
                                <div className="flex flex-wrap gap-5">
                                    <div onClick={() => { setPackages(1); setvideos(1) }} className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${packages === 1 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col`}>
                                        <div className="mt-3 text-center font-work">1 video</div>
                                        <div className={`text-center font-work mt-4 text-2xl font-medium ${packages === 1 ? "text-[#93A8F4]" : "text-white"}`}>₹8000 <span className="text-sm">/video</span></div>
                                        <div className="mt-3 text-center font-work">upto 60 sec video</div>
                                    </div>
                                    <div onClick={() => { setPackages(2); setvideos(5) }} className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${packages === 2 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col`}>
                                        <div className="mt-3 text-center font-work">5+ video</div>
                                        <div className={`text-center font-work mt-4 text-2xl font-medium ${packages === 2 ? "text-[#93A8F4]" : "text-white"}`}>₹6000 <span className="text-sm">/video</span></div>
                                        <div className="mt-3 text-center font-work">upto 60 sec video</div>
                                    </div>
                                    <div onClick={() => { setPackages(3); setvideos(10) }} className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${packages === 3 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col`}>
                                        <div className="mt-3 text-center font-work">10+ video</div>
                                        <div className={`text-center font-work mt-4 text-2xl font-medium ${packages === 3 ? "text-[#93A8F4]" : "text-white"}`}>₹5000 <span className="text-sm">/video</span></div>
                                        <div className="mt-3 text-center font-work">upto 60 sec video</div>
                                    </div>
                                    <div onClick={() => { setPackages(4); setvideos(20) }} className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${packages === 4 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md flex flex-col`}>
                                        <div className="mt-3 text-center font-work">20+ video</div>
                                        <div className={`text-center font-work mt-4 text-2xl font-medium ${packages === 4 ? "text-[#93A8F4]" : "text-white"}`}>₹4000 <span className="text-sm">/video</span></div>
                                        <div className="mt-3 text-center font-work">upto 60 sec video</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {creatorType === 2 &&
                        <>
                            <div className="border-b mt-10 m-5 border-[#555555]"></div>
                            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                                <p className="pb-3 text-lg tracking-wide font-work">Influencer Packages <span className="text-sm text-gray-400">* Pricing is exclusive taxes</span></p>
                                <div className="flex flex-wrap gap-5 mt-5">
                                    <div onClick={() => setInfluencerType(1)} className={`w-52 h-[75px] px-3 flex items-center gap-3 rounded-md ${influencerType === 1 ? "bg-black/70" : "bg-background"} hover:bg-black/30 border border-[#555555] cursor-pointer`}>
                                        <div className="flex-1">
                                            <p className="pb-2 text-lg leading-none tracking-wide font-work">Regular</p>
                                            <p className="leading-none tracking-wide font-work">4000/video</p>
                                            <p className="mt-1 text-sm leading-none tracking-wide font-work text-white/50 first-letter:uppercase">5k-25k followers</p>
                                        </div>
                                    </div>
                                    <div onClick={() => setInfluencerType(2)} className={`w-52 h-[75px] px-3 flex items-center gap-3 rounded-md ${influencerType === 2 ? "bg-black/70" : "bg-background"} hover:bg-black/30 border border-[#555555] cursor-pointer`}>
                                        <div className="flex-1">
                                            <p className="pb-2 text-lg leading-none tracking-wide font-work">Premium</p>
                                            <p className="leading-none tracking-wide font-work ">7000/video</p>
                                            <p className="mt-1 text-sm leading-none tracking-wide font-work text-white/50 first-letter:uppercase">26k-75k followers</p>
                                        </div>
                                    </div>
                                    <div onClick={() => setInfluencerType(3)} className={`w-52 h-[75px] px-3 flex items-center gap-3 rounded-md ${influencerType === 3 ? "bg-black/70" : "bg-background"} hover:bg-black/30 border border-[#555555] cursor-pointer`}>
                                        <div className="flex-1">
                                            <p className="pb-2 text-lg leading-none tracking-wide font-work">Ultra Premium</p>
                                            <p className="leading-none tracking-wide font-work ">12000/video</p>
                                            <p className="mt-1 text-sm leading-none tracking-wide font-work text-white/50 first-letter:uppercase">75k-150K followers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Where <span className="lowercase">your {videoTypes[video_type]} {video_type === 1 ? "" : "videos"} will be displayed</span></p>
                        <div className="flex flex-wrap gap-5">
                            <div onClick={() => setvideo_platform(1)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_platform === 1 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-5">
                                    <FacebookLogo size={52} color={video_platform === 1 ? "#93A8F4" : "#ffffff"} weight='fill' />
                                </div>
                                <div className="text-[13px] font-work text-center">Facebook</div>
                            </div>
                            <div onClick={() => setvideo_platform(2)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_platform === 2 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-5">
                                    <InstagramLogo size={52} color={video_platform === 2 ? "#93A8F4" : "#ffffff"} weight='fill' />
                                </div>
                                <div className="text-[13px] font-work text-center">Instagram</div>
                            </div>
                            {/* <div onClick={() => setvideo_platform(3)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_platform === 3 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-5">
                                    <TwitterLogo size={52} color={video_platform === 3 ? "#93A8F4" : "#ffffff"} weight='fill' />
                                </div>
                                <div className="text-[13px] font-work text-center">Twitter</div>
                            </div> */}
                            <div onClick={() => setvideo_platform(4)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_platform === 4 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-5">
                                    <AmazonLogo size={52} color={video_platform === 4 ? "#93A8F4" : "#ffffff"} weight='fill' />
                                </div>
                                <div className="text-[13px] font-work text-center">Amazon</div>
                            </div>
                            <div onClick={() => setvideo_platform(5)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_platform === 5 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-5">
                                    <YoutubeLogo size={52} color={video_platform === 5 ? "#93A8F4" : "#ffffff"} weight='fill' />
                                </div>
                                <div className="text-[13px] font-work text-center">Youtube</div>
                            </div>
                            {/* <div onClick={() => setvideo_platform(6)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_platform === 6 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-5">
                                    <SnapchatLogo size={52} color={video_platform === 6 ? "#93A8F4" : "#ffffff"} weight='fill' />
                                </div>
                                <div className="text-[13px] font-work text-center">Snapchat</div>
                            </div> */}
                        </div>
                    </div>
                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Choose video format</p>
                        <div className="flex flex-wrap gap-5">
                            <div onClick={() => setvideo_format(1)} className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${video_format === 1 ? "border-[#93A8F4]" : "border-transparent hover:border-[#93A8F4] shadow-sm"} rounded-md p-4`}>
                                <div className="flex items-center justify-center mb-3">
                                    <div className={`h-12 aspect-[10/16] border-4 border-[${video_format === 1 ? "#93A8F4" : "#ffffff"}]`}></div>
                                </div>
                                <div className="text-[13px] font-work text-center">9:16</div>
                                <div className="text-[12px] font-work text-center">Portrait</div>
                            </div>

                        </div>
                    </div>

                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <div className="flex items-start justify-between w-full">
                            <div className="">
                                <p className="pb-3 text-lg tracking-wide font-work">Photos</p>
                                <p className="pb-3 text-lg font-work">4 authentic photos of your product from each creator</p>
                                <button onClick={() => { setphotos_product(!photos_product) }} className="h-12 w-fit px-8 rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                                    {photos_product === true && <Check size={28} color='#6200ee' />}
                                    <p className=""> {photos_product === true ? "Added" : "Add"} - ₹800  / (₹200 per photo)</p>
                                </button>
                            </div>
                            <div className="flex gap-5 w-[390px]">
                                <div className="h-[185px]  aspect-square">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcontent_examples.png?alt=media&token=3f9e80a5-1226-4f4c-a3d3-0b051c10894d" alt="" className="h-[185px] aspect-square object-cover" />
                                </div>
                                <div className="">
                                    <p className="pb-3 tracking-wide font-work">Upgrade your product listing or ads with authentic stills of your product.</p>
                                    <p className="tracking-wide font-work pb-3 underline underline-offset-2 text-[#93A8F4]">See examples</p>
                                </div>
                            </div>
                        </div>
                        {photos_product === true && <div className="w-full">
                            <textarea className='h-32 bg-[#23252D] w-full mt-5 rounded-md p-3 font-work' defaultValue={`1. Instagram-style product shot in the natural environment
2. Video thumbnail - high-quality image from the video shoot
3. Selfie with a product
4. Product highlight shot - product in use`} onChange={(event) => setphotos_description(event.target.value)} maxLength={300} />
                            <div className="flex justify-between">
                                <p className="text-xs tracking-wide font-work text-[#b7b7b7]">Edit to your preference</p>
                                <p className="text-xs tracking-wide font-work text-[#b7b7b7]">{photos_description?.length}/300</p>
                            </div>
                        </div>}
                    </div>

                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="pb-3 text-lg tracking-wide font-work">Creator criteria</p>
                        {creatorType === 2 &&
                            <div className="">
                                <p className="pb-1 tracking-wide font-work">Gender</p>
                                <select onChange={(event) => setcriteria({ ...criteria, gender: event.target.value })} className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide'>
                                    <option value="" >Please Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="any" selected={creatorType === 1 && criteria.gender === "any"}>Any</option>
                                </select>
                            </div>
                        }
                        <div className="mt-5">
                            <p className="pb-2 tracking-wide font-work">Language</p>
                            <div className="flex gap-5">
                                <div className="">
                                    <input type="checkbox" id="english" name="english" checked={selectedLanguages.includes("English")} onChange={(event) => selectLanguage(event.currentTarget.checked, "English")} />
                                    <label htmlFor="english" className='ml-2'>English</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="hindi" name="hindi" checked={selectedLanguages.includes("Hindi")} onChange={(event) => selectLanguage(event.currentTarget.checked, "Hindi")} />
                                    <label htmlFor="hindi" className='ml-2'>Hindi</label>
                                </div>
                                {creatorType === 2 &&
                                    <>
                                        <div className="">
                                            <input type="checkbox" id="bengali" name="bengali" disabled={creatorType === 1} checked={selectedLanguages?.includes("Bengali")} onChange={(event) => selectLanguage(event.currentTarget.checked, "Bengali")} />
                                            <label htmlFor="bengali" className='ml-2'>Bengali</label>
                                        </div>
                                        <div className="">
                                            <input type="checkbox" id="tamil" name="tamil" disabled={creatorType === 1} checked={selectedLanguages?.includes("Tamil")} onChange={(event) => selectLanguage(event.currentTarget.checked, "Tamil")} />
                                            <label htmlFor="tamil" className='ml-2'>Tamil</label>
                                        </div>
                                        <div className="">
                                            <input type="checkbox" id="telgu" name="telgu" disabled={creatorType === 1} checked={selectedLanguages?.includes("Telgu")} onChange={(event) => selectLanguage(event.currentTarget.checked, "Telgu")} />
                                            <label htmlFor="telgu" className='ml-2'>Telgu</label>
                                        </div>
                                        <div className="">
                                            <input type="checkbox" id="gujrati" name="gujrati" disabled={creatorType === 1} checked={selectedLanguages?.includes("Gujrati")} onChange={(event) => selectLanguage(event.currentTarget.checked, "Gujrati")} />
                                            <label htmlFor="gujrati" className='ml-2'>Gujrati</label>
                                        </div>
                                        <div className="">
                                            <input type="checkbox" id="marathi" name="marathi" disabled={creatorType === 1} checked={selectedLanguages?.includes("Marathi")} onChange={(event) => selectLanguage(event.currentTarget.checked, "Marathi")} />
                                            <label htmlFor="marathi" className='ml-2'>Marathi</label>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {creatorType === 2 &&
                            <div className="mt-5">
                                <p className="pb-2 tracking-wide font-work">Age</p>
                                <div className="flex gap-5">
                                    <div className="flex items-center">
                                        <input type="checkbox" name="18-24" id="18-24" disabled={creatorType === 1} checked={age?.eighteen} onChange={(event) => setage({ ...age, eighteen: event.currentTarget.checked ? true : false })} />
                                        <label htmlFor="18-24" className='ml-2'>18 - 24</label>
                                    </div>
                                    <div className="">
                                        <input type="checkbox" name="25-34" id="25-34" disabled={creatorType === 1} checked={age?.twentyfive} onChange={(event) => setage({ ...age, twentyfive: event.currentTarget.checked ? true : false })} />
                                        <label htmlFor="25-34" className='ml-2'>25-34</label>
                                    </div>
                                    <div className="">
                                        <input type="checkbox" name="35-44" id="35-44" disabled={creatorType === 1} checked={age?.thirtyfive} onChange={(event) => setage({ ...age, thirtyfive: event.currentTarget.checked ? true : false })} />
                                        <label htmlFor="35-44" className='ml-2'>35-44</label>
                                    </div>
                                    <div className="">
                                        <input type="checkbox" name="44+" id="44+" disabled={creatorType === 1} checked={age?.fourtyfour} onChange={(event) => setage({ ...age, fourtyfour: event.currentTarget.checked ? true : false })} />
                                        <label htmlFor="44+" className='ml-2'>44+</label>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="mt-5">
                            <p className="pb-2 tracking-wide font-work">Location</p>
                            <div className="flex gap-5">
                                <div className="">
                                    <input type="checkbox" id="kitchen" name="kitchen" checked={locations.includes("kitchen")} onChange={(event) => selectLocations(event.currentTarget.checked, "kitchen")} />
                                    <label htmlFor="kitchen" className='ml-2'>Kitchen</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="bathroom" name="bathroom" checked={locations.includes("bathroom")} onChange={(event) => selectLocations(event.currentTarget.checked, "bathroom")} />
                                    <label htmlFor="bathroom" className='ml-2'>Bathroom</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="living-room" name="living-room" checked={locations?.includes("living-room")} onChange={(event) => selectLocations(event.currentTarget.checked, "living-room")} />
                                    <label htmlFor="living-room" className='ml-2'>Living Room</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="office" name="office" checked={locations?.includes("office")} onChange={(event) => selectLocations(event.currentTarget.checked, "office")} />
                                    <label htmlFor="office" className='ml-2'>Office</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="garage/workshop" name="garage/workshop" checked={locations?.includes("garage/workshop")} onChange={(event) => selectLocations(event.currentTarget.checked, "garage/workshop")} />
                                    <label htmlFor="garage/workshop" className='ml-2'>Garage / Workshop</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="beach" name="beach" checked={locations?.includes("beach")} onChange={(event) => selectLocations(event.currentTarget.checked, "beach")} />
                                    <label htmlFor="beach" className='ml-2'>Beach</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="outdoor" name="outdoor" checked={locations?.includes("outdoor")} onChange={(event) => selectLocations(event.currentTarget.checked, "outdoor")} />
                                    <label htmlFor="outdoor" className='ml-2'>Outdoor</label>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <p className="pb-1 tracking-wide font-work"> </p>
                            <input type="text" onChange={(event) => setcriteria({ ...criteria, creatorPreference: event.target.value })} className="h-10 w-full appearance-none outline-none rounded-md bg-[#23252D] px-3 tex-sm placeholder:text-sm" placeholder='Looking for anything specific? Describe creator qualities (e.g. has a pet / can film in a car)' />
                        </div>
                    </div>
                    {(params.get('previewModal') === 'true' && params.get('creatorId')) &&
                        <Modalcard close={closePreviewModal}>
                            <div className="bg-background border border-[#555555] rounded-md p-5 w-full sm:min-w-[500px] sm:max-w-[800px] min-h-[300px] max-h-[500px] overflow-y-auto" id="thin-scrollbar">
                                <div className="pb-2 tracking-wide text-center font-work">Previous works by creators</div>
                                <div className="flex flex-wrap gap-5">
                                    {inHouseCeators.filter(val => val.id === params.get('creatorId'))[0]?.videos?.map((video, index) => (
                                        <div key={index} className="w-[200px] aspect-[9/16] bg-bg_highlight rounded-md">
                                            <video playsInline src={video} className='w-full h-full rounded-md' controls controlsList='nodownload noplaybackrate nofullscreen' disablePictureInPicture></video>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Modalcard>
                    }
                    {creatorType === 1 &&
                        <>
                            <div className="border-b mt-10 m-5 border-[#555555]"></div>
                            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                                <p className="pb-3 text-lg tracking-wide font-work">Creators</p>
                                <div className="flex flex-wrap gap-5">
                                    {inHouseCeators?.map((creator, index) => (
                                        <div key={index} className={`w-52 h-16 px-3 flex items-center gap-3 rounded-md bg-background hover:bg-black/30 border border-[#555555] ${creatorSelected.includes(creator?.id) && "!bg-black/30"}`}>
                                            <div className="relative h-12 rounded-full cursor-pointer aspect-square" onClick={() => handleCreatorSelection(creator?.id)} >
                                                {creatorSelected?.includes(creator?.id) &&
                                                    <div className="absolute flex items-center justify-center w-full h-full rounded-full bg-black/70">
                                                        <CheckCircle size={32} color='#93A8F4' weight='duotone' />
                                                    </div>
                                                }
                                                <img src={creator?.dp} alt="dp" className="w-full h-full rounded-full" />
                                            </div>
                                            <div className="flex-1">
                                                <p className="leading-none tracking-wide font-work ">{creator?.id}</p>
                                                <p className="text-sm leading-none tracking-wide font-work text-white/50 first-letter:uppercase">{creator?.gender}</p>
                                            </div>
                                            <div className="">
                                                <button onClick={() => router.push("/admin/requestcampaign?previewModal=true&creatorId=" + creator?.id)}>
                                                    <DotsThreeVertical size={32} color='white' />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                    {/* {creatorType === 2 &&
                        <>
                            <div className="border-b mt-10 m-5 border-[#555555]"></div>
                            <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                                <div className="flex items-start justify-between w-full">
                                    <div className="">
                                        <p className="pb-3 text-lg tracking-wide font-work">MyRevue will do this for you</p>
                                        <div className="mt-3">
                                            <p className="pb-2 tracking-wide font-work"> Success Manager Add-on ₹699/video (Creating SOP + Influencer Shortlisting + 99hrs Video Delivery)</p>
                                            <div className="flex gap-5">
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="yes_i_am_interested"
                                                        name="yes_i_am_interested"
                                                        checked={addOn.successManager === true}
                                                        onChange={() => handleSuccessManager(true)}
                                                    />
                                                    <label htmlFor="yes_i_am_interested" className='ml-2'>Yes, I am interested</label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="no_i_am_not_interested"
                                                        name="no_i_am_not_interested"
                                                        checked={addOn.successManager === false}
                                                        onChange={() => handleSuccessManager(false)}
                                                    />
                                                    <label htmlFor="no_i_am_not_interested" className='ml-2'>I am not interested</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-7">
                                            <p className="pb-2 tracking-wide font-work">No of scripts - ₹399/script</p>
                                            <div className="flex items-center">
                                                <div className="border border-[#404040] p-1 flex items-center gap-5 select-none">
                                                    <div onClick={() => addOn.scripts === 0 ? null : setaddOn({ ...addOn, scripts: addOn.scripts - 1 })} className="h-6 w-6 rounded-full flex items-center justify-center hover:bg-[#252528] cursor-pointer">
                                                        <Minus size={18} color='white' />
                                                    </div>
                                                    <div className="">
                                                        {addOn.scripts}
                                                    </div>
                                                    <div onClick={() => {
                                                        if (!addOn.successManager) {
                                                            return showWarning("Please select success manager Add-on to add scripts")
                                                        }
                                                        if (videos === addOn.scripts) {
                                                            return showWarning("Cannot add more scripts than videos.");
                                                        }
                                                        setaddOn({ ...addOn, scripts: addOn.scripts + 1 })
                                                    }} className="h-6 w-6 rounded-full flex items-center justify-center hover:bg-[#252528] cursor-pointer">
                                                        <Plus size={18} color='white' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    } */}

                    <div className="border-b mt-10 m-5 border-[#555555]"></div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5 mb-8">
                        <p className="pb-3 text-lg tracking-wide font-work">Number of videos</p>
                        <div className="flex w-full gap-5">
                            <div className="">
                                <div className={`bg-[#23252d] cursor-pointer h-[152px] w-[200px] shadow-gray-600 shadow-sm rounded-md p-4`}>
                                    <div className="py-1 text-xs tracking-wide text-center">
                                        How many videos you'd like to order?
                                    </div>
                                    <div className="text-[#93A8F4] text-2xl text-center font-medium mt-1 flex items-center justify-center">
                                        <input
                                            type="text"
                                            value={videos}
                                            className='mr-2 text-right bg-transparent outline-none appearance-none w-7'
                                            onChange={(event) => {
                                                const newValue = parseInt(event.target.value);
                                                if (!isNaN(newValue)) {
                                                    setvideos(newValue);
                                                }
                                            }}
                                            onKeyDown={(event) => {
                                                const keyCode = event.keyCode || event.which;
                                                const isNumericKey = /\d/.test(String.fromCharCode(keyCode));
                                                const isNumericPad = keyCode >= 96 && keyCode <= 105;
                                                const isBackspaceOrDelete = keyCode === 8 || keyCode === 46;
                                                if (!isNumericKey && !isNumericPad && !isBackspaceOrDelete) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <span> videos</span>
                                    </div>
                                    {/* <div className="text-[#93A8F4] text-2xl text-center font-medium mt-1 flex items-center justify-center"> {videos}  videos</div> */}
                                    <div className="flex items-center justify-center gap-5 mt-2 font-work">
                                        <div className="h-10 w-10 rounded-full bg-[#2f4dba] flex items-center justify-center text-3xl font-light cursor-pointer select-none" onClick={() => videos === 1 ? null : setvideos(videos - 1)}>-</div>
                                        <div className="h-10 w-10 rounded-full bg-[#93A8F4] flex items-center justify-center text-3xl font-light cursor-pointer select-none" onClick={() => setvideos(videos + 1)}>+</div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#333640] p-3 rounded-md flex-1 w-full">
                                <p className="font-work">Order will be carried out by <span className="text-[#93A8F4]">{creators} creators</span></p>
                                <p className="my-2 text-sm font-medium tracking-wide font-work">Your product shipping</p>
                                <p className="my-2 text-sm tracking-wide font-work">Note that you <span className="underline underline-offset-2">will have to send one product to each creator producing the video (as a giveaway).</span></p>
                                <p className="my-2 text-sm tracking-wide font-work">The faster you ship products to approved creators, the faster you can expect to receive the final videos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#131418] w-full h-16 sticky bottom-0 flex items-center justify-between border-t border-[#474747]">
                        <div className="">
                        </div>
                        <div className="flex items-center mr-5">
                            <div className="">
                                <p className="font-work">{videos} videos x  ₹{total} {addOn.scripts > 0 && `+ (Script: ₹399 x ${addOn.scripts})`}</p>
                                <p className="-mt-1 font-work">Total:  ₹{(videos * total) + (399 * addOn.scripts)} + GST</p>
                            </div>
                            <div className="ml-5 border-l">
                                <button onClick={() => { createCampaign(); }} className="bg-[#93A8F4] h-9 rounded-full ml-5 w-fit px-8" >Next Step</button>
                            </div>
                        </div>
                    </div>
                </>
            }
            {tab === 2 &&
                <>
                    <div className="grid grid-cols-3 gap-5 px-5 pt-5 ">
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93a8f483]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Video details</div>
                        </div>
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93A8F4]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Payment</div>
                        </div>
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93a8f483]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Video brief</div>
                        </div>
                    </div>
                    <div className="py-5 m-5 text-xl font-medium text-center font-work">Here's your order summary</div>
                    <div className="flex justify-center m-5">
                        <div className="w-[720px] bg-[#131418] border-2 border-[#404040] rounded-md p-5">
                            <div className="flex justify-between items-center pb-5 border-b border-[#404040] ">
                                <div className="">
                                    <p className="text-xl font-medium font-work">Video ad videos</p>
                                    <p className="text-xs font-work">₹{total}/video</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="border border-[#404040] p-1 flex items-center gap-5 select-none">
                                        <div onClick={() => videos === 1 ? null : setvideos(videos - 1)} className="h-6 w-6 rounded-full flex items-center justify-center hover:bg-[#252528] cursor-pointer">
                                            <Minus size={18} color='white' />
                                        </div>
                                        <div className="">
                                            {videos}
                                        </div>
                                        <div onClick={() => setvideos(videos + 1)} className="h-6 w-6 rounded-full flex items-center justify-center hover:bg-[#252528] cursor-pointer">
                                            <Plus size={18} color='white' />
                                        </div>
                                    </div>
                                </div>
                                <div className="select-none font-work">₹{videos * total}</div>
                            </div>

                            <div className="flex justify-between items-center py-5 border-b border-[#404040]">
                                <div className="text-xl font-medium font-work">Coupons</div>
                                <input type="text" placeholder='ex: MRNEW' onBlur={(event) => checkcoupons(event.target.value)} className='text-sm px-3  tracking-wide h-8 w-40 bg-transparent rounded-md border border-[#404040]' />
                            </div>
                            <div className="flex justify-between items-center py-5 border-b border-[#404040]">
                                <div className="text-xl font-medium font-work">Scripts</div>
                                <div className="font-work">₹{(addOn.scripts * 399)}</div>
                            </div>
                            <div className="flex justify-between items-center py-5 border-b border-[#404040]">
                                <div className="text-xl font-medium font-work">Tax</div>
                                <div className="font-work">₹{(videos * total) * 0.18}</div>
                            </div>
                            <div className="flex justify-between items-center py-5 border-b border-[#404040]">
                                <div className="text-xl font-medium font-work">Discount</div>
                                <div className="font-work">₹{couponDiscount}</div>
                            </div>
                            <div className="flex justify-between items-center py-5 border-b border-[#404040]">
                                <div className="text-xl font-medium font-work">Order total</div>
                                <div className="font-work">₹{Math.max(0, (((videos * total) + (addOn.scripts * 399)) * 1.18) - couponDiscount).toFixed(0)}</div>
                            </div>
                            <div className="flex justify-between items-center p-2 mt-5 rounded-md border-[#404040] bg-[#23252D]">
                                <p className="font-work">Estimated video delivery: <span className="underline underline-offset-2">{delivery_Date}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center m-5 mb-20">
                        <div className="w-[720px] bg-[#131418] border-2 border-[#404040] rounded-md p-5">
                            <div className="my-5">
                                <p className="font-work">Name</p>
                                <input type='text' placeholder='Enter full name' defaultValue={paymentDetails?.name} onChange={(event) => setpaymentDetails({ ...paymentDetails, name: event.target.value })} className='w-full mt-1 bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="my-5">
                                <p className="font-work">Email</p>
                                <input type='text' placeholder='Enter your email' defaultValue={paymentDetails?.email} onChange={(event) => setpaymentDetails({ ...paymentDetails, email: event.target.value })} className='w-full mt-1 bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="my-5">
                                <p className="font-work">Phone Number</p>
                                <input type='text' placeholder='Enter your phone number' defaultValue={paymentDetails?.phone} onChange={(event) => setpaymentDetails({ ...paymentDetails, phone: event.target.value })} className='w-full mt-1 bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="flex justify-center mt-5">
                                <button className="bg-[#23252D] h-10 w-52 rounded-md flex items-center justify-center gap-5" onClick={async () => {
                                    const totalPrice = Math.max(0, (((videos * total) + (addOn.scripts * 399)) * 1.18) - couponDiscount).toFixed(0);
                                    if (Number(totalPrice) < 1) {
                                        await updateDoc(doc(db, "getavideocoupons", couponUsed), { "used": increment(1), usedBy: user?.email })
                                        settab(3);
                                        return
                                    } else {
                                        return createPayment();
                                    }
                                }}>
                                    {fetchStatus === false ? <>
                                        <ContactlessPayment size={24} color='white' />
                                        {((videos * total) * 1.18) - couponDiscount === 0 ? "Proceed" : "Pay Now"}
                                    </>
                                        :
                                        <>
                                            <CircleNotch size={22} color="#ffffff" weight='bold' className='animate-spin' />
                                        </>
                                    }
                                </button>
                            </div>
                            {/* <p className="mt-2 text-xs tracking-wide text-center font-work">For Indian Region: Payment will be made in INR</p> */}
                        </div>
                    </div>
                    {/* <div className="bg-[#131418] w-[calc(100%-240px)] h-16 absolute bottom-0 flex items-center justify-between border-t border-[#474747]">
                        <div className="">
                            <button onClick={() => { saveDraft(); }} className="bg-[#222222] h-9 rounded-full ml-5 w-fit px-8" >Save as draft</button>
                        </div>
                        <div className="flex items-center mr-5">
                            <div className="">
                                <p className="font-work">{videos} videos x  ₹{total}</p>
                                <p className="-mt-1 font-work">Total:  ₹{(videos * total) * 1.18} (18% GST)</p>
                            </div>
                            <div className="ml-5 border-l">
                                <button onClick={() => { createCampaign(); }} className="bg-[#93A8F4] h-9 rounded-full ml-5 w-fit px-8" >Next Step</button>
                            </div>
                        </div>
                    </div> */}
                </>
            }
            {tab === 3 &&
                <>
                    <div className="grid grid-cols-3 gap-5 px-5 pt-5 ">
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93a8f483]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Video details</div>
                        </div>
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93a8f483]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Payment</div>
                        </div>
                        <div className="">
                            <div className="h-2 w-full rounded-xl bg-[#93A8F4]"></div>
                            <div className="text-sm text-center mt-2 tracking-wide text-[rgba(255,255,255,0.6)] font-work">Video brief</div>
                        </div>
                    </div>
                    <>
                        <div className="bg-[#131418] m-5 rounded-md mt-5 p-5">
                            <p className="pb-3 text-lg tracking-wide font-work">Product Info</p>
                            <div className="">
                                <p className="pb-1 font-work">Product</p>
                                {/* <input type='text' onChange={(event) => setproduct_info({ ...product_info, productName: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' /> */}
                                <select onChange={(event) => selectProduct(event.target.value)} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' >
                                    <option value="">Please Select</option>
                                    <option value="new">Add new product</option>
                                    {products?.map((product, index) => (
                                        <option value={product?.id} key={index}>{product?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </>

                    {(productExist === true || productExist === "new") && <>
                        <div className="bg-[#131418] m-5 rounded-md mt-5 p-5">
                            <p className="pb-3 text-lg tracking-wide font-work">Product Info</p>
                            <div className="">
                                <p className="pb-1 tracking-wide font-work">Product Type <span className="text-red-500">*</span></p>
                                <select onChange={(event) => setad_product_type(event.target.value)} className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide'>
                                    <option value="">Please Select</option>
                                    <option value="Giveaway">Giveaway</option>
                                    <option value="Pre Owned">Pre Owned</option>
                                </select>
                                {ad_product_type === "Giveaway" && <p className="text-xs tracking-wide mt-2 text-[#808080]">Note: You need to send the physical product to creator</p>}
                                {ad_product_type === "Pre Owned" && <p className="text-xs tracking-wide mt-2 text-[#808080]">Note: Creator is already using or have your product</p>}
                            </div>
                            <div className="grid grid-cols-2">
                                <div className=''>
                                    <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Product Name <span className="text-red-500">*</span></p>
                                        <input type='text' defaultValue={product_info?.productName} onChange={(event) => setproduct_info({ ...product_info, productName: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>
                                    <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Product (SKU Code) <span className="text-red-500">*</span></p>
                                        <input type='text' defaultValue={product_info?.productSKU} onChange={(event) => setproduct_info({ ...product_info, productSKU: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>
                                    <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Product Link <span className="text-red-500">*</span></p>
                                        <input type='text' defaultValue={product_info?.productLink} onChange={(event) => setproduct_info({ ...product_info, productLink: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>
                                    <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Product Value <span className="text-red-500">*</span></p>
                                        <input type='text' defaultValue={product_info?.value} onChange={(event) => setproduct_info({ ...product_info, productValue: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>
                                    {ad_product_type === "Giveaway" && <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Estimated Delivery Time <span className="text-red-500">*</span></p>
                                        <input type='text' onChange={(event) => setproduct_info({ ...product_info, estimatedDeliveryTime: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>}
                                    <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Script/ Guidelines</p>
                                        <textarea type='text' onChange={(event) => setproduct_info({ ...product_info, sript: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-32 pt-2 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>
                                    <div className="mt-5">
                                        <p className="pb-1 tracking-wide font-work">Reference Video (Link)</p>
                                        <input type='text' onChange={(event) => setproduct_info({ ...product_info, referenceVideo: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                                    </div>
                                </div>
                                <div className="">
                                    <p className="pb-1 font-work">Product image <span className="text-red-500">*</span></p>
                                    <div className="h-52 aspect-square bg-[#23252D] relative flex flex-col items-center justify-center rounded-md">
                                        <input type="file" accept='.png,.jpg,.jpeg' onChange={(event) => uploadFile(event, 'main')} className='absolute z-10 w-full h-full bg-transparent opacity-0 ' />
                                        {(productImage !== '') ?
                                            <div className="w-full h-full">
                                                <img src={productImage} alt="productimage" className="object-cover w-full h-full rounded-md" />
                                            </div>
                                            :
                                            <div className="flex flex-col items-center gap-3 p-3">
                                                <UploadSimple size={28} color='white' />
                                                <p className="text-xs text-center">Drag or click to upload Product Image</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="text-lg tracking-wide font-work ">Other Information</p>
                        <div className="">
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Brand Spoke Person Name <span className="text-red-500">*</span></p>
                                <input type='text' onChange={(event) => setExtraInfo({ ...extraInfo, spokePersonName: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Brand Spoke Person Number <span className="text-red-500">*</span></p>
                                <input type='text' onChange={(event) => setExtraInfo({ ...extraInfo, spokePersonNumber: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Attire</p>
                                <input type='text' onChange={(event) => setExtraInfo({ ...extraInfo, attire: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Hairstyle</p>
                                <input type='text' onChange={(event) => setExtraInfo({ ...extraInfo, hairstyle: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Location/Background</p>
                                <input type='text' onChange={(event) => setExtraInfo({ ...extraInfo, location: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Vocal</p>
                                <div className="flex items-center gap-2 pt-2">
                                    <div className="flex items-center">
                                        <input type="checkbox" name="verbal" id="verbal" checked={extraInfo.vocal.includes("verbal")} onChange={handleVerbalChange} />
                                        <label htmlFor="verbal" className='ml-2'>Verbal</label>
                                    </div>
                                    <div className="">
                                        <input type="checkbox" name="non-verbal" id="non-verbal" checked={extraInfo.vocal.includes("non-verbal")} onChange={handleVerbalChange} />
                                        <label htmlFor="non-verbal" className='ml-2'>Non-verbal</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mt-5">
                                <p className="pb-1 tracking-wide font-work">Shipment Date</p>
                                <input type='date' onChange={(event) => setExtraInfo({ ...extraInfo, shipmentDate: event.target.value })} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div> */}
                        </div>
                    </div>
                    <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                        <p className="text-lg tracking-wide font-work ">Add Notes</p>
                        <form onSubmit={(event) => handleAddNotes(event)} className="">
                            <input value={noteText} className='h-10 bg-[#23252D] w-full mt-5 rounded-md p-3 font-work appearance-none outline-none' onChange={(e) => setNoteText(e.target.value)} />
                        </form>

                        {notes.length > 0 &&
                            <>
                                <p className="pb-5 mt-5 text-lg tracking-wide font-work">Notes</p>
                                {notes.map((note, index) => (
                                    <div className="flex items-center justify-between py-2" key={index}>
                                        <p className="font-work" >{index + 1}: {note}</p>
                                        <button onClick={() => handleRemoveNote(index)} className='flex items-center justify-center gap-5'>Delete <Trash size={28} color="#ffffff" /></button>
                                    </div>
                                ))}
                            </>
                        }
                    </div>
                    <button className="mx-5 bg-[#4611ea] h-11 w-52 rounded-md mb-10" onClick={() => submit()}>
                        Submit
                    </button>
                    <div className="pb-10"></div>
                </>
            }
        </div>
    )
}
