import React, { useEffect, Suspense, lazy, useState } from "react";
import { sendAmplitudeData } from "../amplitude";
import { useHistory } from "react-router-dom";
import NotLoginHeaderNew from "../layouts/NotLoginHeaderNew";
import { Check, CircleWavyQuestion } from "@phosphor-icons/react";
import Loadingdot from "../components/loadingdot";
const NotloginfooterNew = lazy(() => import("../layouts/NotloginfooterNew"));

function ChoosePlan() {
  const [monthly, setmonthly] = useState(true);
  const router = useHistory();
  useEffect(() => {
    sendAmplitudeData("pricing page opened");
    var title = `Pricing & Plans | MyRevue`;
    var metaTitle = `Pricing & Plans | MyRevue`;
    var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool, lead generation software free trial, zapier zap, marketing lead gen, lead generation it companies, video testimonial page design, b2b lead software, lead generation app, testimonial write up,client testimonials page, lead gen platform, short testimonial examples, lead finding software`;

    var ogUrl = `https://brand.myrevue.app/login`;
    var ogtype = `website`;
    var ogtitle = `Pricing & Plans | MyRevue`;
    var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/login`;
    var twittertitle = `Pricing & Plans | MyRevue`;
    var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content = metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content = ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content = twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content = twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, []);

  return (
    <div className="h-screen overflow-y-auto bg-[#101010]">
      <Suspense fallback={<Loadingdot />}>
        <NotLoginHeaderNew />
        <div className="px-5 mx-auto md:my-10 md:px-10 xl:px-24 2xl:px-32">
          <h1 className="text-[28px] md:text-[36px] lg:text-[40px] text-center leading-tight font-semibold tracking-wide">Plans and Pricing</h1>
          <p className="text-[#8E8B8B] text-center tracking-wide mt-3 max-w-[440px] mx-auto">
            Our paid plans which come with publishing, video downloads, and no watermark.
          </p>
          <div className="grid grid-cols-2 w-[245px] mx-auto mt-5 bg-[#2B2F37] rounded-full">
            <button
              onClick={() => setmonthly(true)}
              className={`py-2 w-full rounded-full ${monthly === true ? "bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]" : "bg-transparent"
                } `}>
              Monthly
            </button>
            <button
              onClick={() => setmonthly(false)}
              className={`py-2 w-full rounded-full ${monthly === false ? "bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]" : "bg-transparent"
                } `}>
              Yearly
            </button>
          </div>

          <section className="flex flex-wrap items-center justify-center gap-10 pb-10 mt-10">
            <div className="bg-[#2B2F37]  p-6 w-full max-w-[310px] rounded-[20px] mt-8 h-max">
              <p className="text-center text-xl pb-4 text-[#F92147] font-semibold tracking-wide">Freemium</p>
              <p className="text-2xl text-center ">
                <span className="">₹0/</span>
                {monthly === true ? "month" : "year"}
              </p>
              <ul className="mt-5 space-y-1 font-light tracking-wide">
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Publish Unlimited Reviews
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Download Upto 3 Videos
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />1 Campaign
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />1 User
                </li>
              </ul>
              <div className="flex items-center justify-center w-full">
                <button
                  className="py-2 px-12 rounded-full w-fit  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                  onClick={() => router.push("/signup")}>
                  Get Started
                </button>
              </div>
            </div>
            <div className="bg-[#2B2F37] p-6  w-full max-w-[310px] rounded-[20px] mt-8 relative h-max">
              <div className="absolute z-50 top-0 left-0 -m-5 bg-[#2B2F37] rounded-md ">
                <div className="px-5 py-2 border border-white rounded-md w-max backdrop-blur-sm ">Popular</div>
              </div>
              <p className="text-center text-xl pb-4 text-[#F92147] font-semibold tracking-wide">Basic</p>
              <p className="text-2xl text-center ">
                <span className="">₹{monthly === true ? "999" : "9999"}/</span>
                {monthly === true ? "month" : "year"}
              </p>
              <ul className="mt-5 space-y-1 font-light tracking-wide">
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Everything in Free, plus...
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Download upto 50 Videos
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />25 Campaigns
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />3 Users
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Api Integration
                </li>
              </ul>
              <div className="flex items-center justify-center w-full">
                <button
                  className="py-2 px-12 rounded-full w-fit  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                  onClick={() => router.push("/signup")}>
                  Get Started
                </button>
              </div>
            </div>
            <div className="bg-[#2B2F37] p-6  w-full max-w-[310px] rounded-[20px] mt-8 h-max">
              <p className="text-center text-xl pb-4 text-[#F92147] font-semibold tracking-wide">Standard</p>
              <p className="text-2xl text-center">
                <span className="">₹{monthly === true ? "2499" : "24999"}/</span>
                {monthly === true ? "month" : "year"}
              </p>
              <ul className="mt-5 space-y-1 font-light tracking-wide">
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Everything in Basic, plus...
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Download Unlimited
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />
                  Upto 50 Campaigns
                </li>
                <li className="flex items-center gap-x-2">
                  <Check size={26} color="green" weight="bold" />5 Users
                </li>
              </ul>
              <div className="flex items-center justify-center w-full">
                <button
                  className="py-2 px-12 rounded-full w-fit  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                  onClick={() => router.push("/signup")}>
                  Get Started
                </button>
              </div>
            </div>
          </section>
          <section className="p-5 pt-16 -mt-10 overflow-x-auto">
            <table className="w-[700px] lg:w-[1050px] overflow-x-auto mx-auto text-center text-sm tableplans">
              <thead className="">
                <tr className="bg-[#2B2F37]">
                  <th className=" border border-[#858585] h-16 p-2 font-medium tracking-wide">Plans</th>
                  <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Freemium</th>
                  <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Basic</th>
                  <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Standard</th>
                  <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Curated</th>
                </tr>
              </thead>
              <tbody className="tbody">
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Features
                      <button
                        aria-label="questionmark"
                        className="z-50 flex items-center justify-center w-8 h-8"
                        data-tooltip="Get a video collector and turn the power of reviews into more
                traffic, increased sales, and strong customer loyalty.. Simply
                share Link/ QR code with your customers and get their video
                reviews.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>

                  <td className="font-light border border-[#858585] h-10 p-2 ">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2 ">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2 ">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>

                  <td className="font-light border border-[#858585] p-2">
                    For larger businesses with a need for the most advanced features in a bespoke package.
                  </td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Campaigns
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="No of campaigns can be created">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">1</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">25</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">50</td>
                  <td className="font-light border border-[#858585] h-10 p-2">On Request</td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Flag Detection Tool
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Flag the fake reviews by our detection tool.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    A review solution tailored to your needs and delivered with top-tier customer support.
                  </td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Remote hassle free Review Collection
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Remotely collect reviews from your customers through any device.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    Throughout your experience, a dedicated Customer Support Team will work with you every step of the way. With an
                    understanding of your unique business drivers and objectives, they’ll help you create and implement a review strategy that
                    grows your brand and your bottom line.
                  </td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Users
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Get a no of ID login to Customer Dashboard.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">1</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">3</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">5</td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    Work directly with our success managers, implementation experts and support engineers to grow your brand trust quicker.
                  </td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Acknowledgements
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="No. of Comment Credits to acknowledge customer reviews">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">25</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">100</td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    With our artificial intelligence doing the heavy lifting, dive deep into your customer feedback to see trends and insights
                    within your reviews.
                  </td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Publish
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Share unlimited reviews on your Social Media Handles and Website through easily configurable APIs with Brand and MyRevue watermark.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    Showcase Product Reviews in ads and on your website to make buying decisions easier for prospects considering your products.
                  </td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Video Storage
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Review storage for upto 14 days for Unpublished and Draft videos on MyRevue Customer Dashboard.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Download published videos and raw response footage
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Download upto no of videos per month and share on various platforms.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">3</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">50</td>
                  <td className="font-light border border-[#858585] h-10 p-2 text-xl">Unlimited</td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Customized Gallery
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Get your personlized website and showcase your video reviews as testimonials to your prospective customers.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Dedicated Support Manager
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip="Get your personlized website and showcase your video reviews as testimonials to your prospective customers.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      API Integration
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip=" Improve your brand value by access to our entire service review widget library to showcase your star rating and review content across your site.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Customized Brand Page
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip=" Customize your profile page with your own branding, promotions and company info on MyRevue app to fuel organic growth and free referral traffic to your site.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
                <tr className="">
                  <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                    <div className="flex items-center justify-between w-full h-full ">
                      Incentives &amp; Rewards
                      <button
                        aria-label="questionmark"
                        className="flex items-center justify-center w-8 h-8"
                        data-tooltip=" Create coupon codes with a click of a button to provide special discounts to customers giving reviews and make them happy.">
                        <CircleWavyQuestion size={22} color="white" />
                      </button>
                    </div>
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                  <td className="font-light border border-[#858585] h-10 p-2">
                    <Check size={32} color="#ffffff" className="mx-auto" />
                  </td>
                  <td className="font-light border border-[#858585] h-10 p-2"></td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <NotloginfooterNew />
      </Suspense>
    </div>
  );
}

export default ChoosePlan;
