import React from "react";

export default function Modalcard({ children, close, customClass }) {
  return (
    <div className={`fixed top-0 left-0 z-[1500] flex h-screen w-full ${customClass ?? "items-start lg:items-center justify-center "}  select-none`}>
      <div
        className=" absolute h-screen w-full cursor-pointer bg-[#121212e1] bg-opacity-80"
        onClick={() => close(false)}
      ></div>
      <div className={`z-50 ${customClass ? "w-full p-7 mb-20" : ""} `}>{children}</div>
    </div>
  );
}
