import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initAmplitude } from "./amplitude";
import { StateProvider } from "./context/StateProvider";
import reducer, { initialState } from "./context/Reducer";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);
initAmplitude();

const MainApp = (
  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>
);
root.render(MainApp);

reportWebVitals();
