import React, { useState } from 'react';

const ErrorBoundary = ({ children }) => {
    const [error, setError] = useState(null);

    // This function is called when an error occurs in the component tree
    const handleError = (error) => {
        setError(error);
        console.error(error);
    };

    // Reset the error state when the component is re-rendered
    if (error) {
        return <div>Something went wrong!</div>;
    }

    return children;
};

export default ErrorBoundary;
