import { doc, getDoc, setDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash, VideoCamera } from "phosphor-react";
import {
  CaretLeft,
  Checks,
  CircleNotch,
  GoogleLogo,
  Phone,
  Upload,
} from "@phosphor-icons/react";
import { useLocation, useHistory } from "react-router-dom";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { nanoid } from "nanoid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { logEngagementHook } from "../../common/functions";
import { useStateValue } from "../../context/StateProvider";
import { showSuccess, showWarning } from "../../components/toast/toast";
import { pushNotification } from "../../common/notificationFunctions";

export default function Userviewsfaq() {
  const [campaign, setcampaign] = useState({});
  const [pageLoading, setpageLoading] = useState(false);
  const [isvalidCampaign, setisValidCampaign] = useState(true);
  const [campaignProduct, setcampaignProduct] = useState({});
  const [campaignProductAttributes, setcampaignProductAttributes] = useState(
    []
  );

  var user_id = `user_${nanoid(12)}`;
  const [video_id, setvideo_id] = useState(nanoid(20));
  const [userDetails, setuserDetails] = useState({});
  const [device, setDevice] = useState(null);
  const [phoneNumber, setphoneNumber] = useState("");
  const [phoneCountryCode, setphoneCountryCode] = useState("91");
  const [phoneOtp, setphoneOtp] = useState("");
  const [optSent, setoptSent] = useState(false);
  const [uploadingVideo, setuploadingVideo] = useState(null);
  const [thumbnailurl, setthumbnailurl] = useState(null);
  const [uploadProgress, setuploadProgress] = useState(0);
  const [videoDataURL, setvideoDataURL] = useState([]);
  const [otpLoading, setOtpLoading] = useState(false);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const { form } = useParams();
  const router = useHistory();

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDevice("Mobile");
    } else {
      setDevice("Desktop");
    }
    const getCampaign = async () => {
      try {
        setpageLoading(true);
        setisValidCampaign(true);

        const campaignDoc = await getDoc(doc(db, "faqforms", form));
        if (!campaignDoc.exists()) {
          setpageLoading(false);
          setisValidCampaign(false);
        } else {
          const data = campaignDoc.data();
          const videoArray = [];
          data.questions?.map((question, index) =>
            videoArray.push({
              id: index,
              videoUrl: null,
              question: question.question,
            })
          );
          setvideoDataURL(videoArray);
          setcampaign({ ...data, id: campaignDoc.id });

          logEngagementHook({
            campaignId: form,
            date: Date.now(),
            platform: device,
            action: "open_faq",
            brand: data.bpsId,
          });

          if (data?.prodid) {
            await getProduct(data.prodid);
          } else {
            setpageLoading(false);
            setisValidCampaign(true);
          }
        }
      } catch (error) {
        console.error("Error fetching campaign:", error);
        setpageLoading(false);
        setisValidCampaign(false);
      }
    };

    const getProduct = async (prodid) => {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        };

        let reqOptions = {
          url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + prodid,
          method: "GET",
          headers: headersList,
        };

        let response = await axios.request(reqOptions);
        let data = response.data;

        if (!data) {
          showWarning("Product information not available");
        }

        setpageLoading(false);
        setisValidCampaign(true);
        setcampaignProduct(data);
        setcampaignProductAttributes(Object.keys(data?.attributes ?? {}));
      } catch (error) {
        console.error("Error fetching product:", error);
        setpageLoading(false);
        setisValidCampaign(false);
      }
    };

    if (searchParams.get("step") !== null) {
      if (JSON.stringify(userDetails) === "{}") {
        getCampaign();
        const url = new URL(window.location.href);
        url.searchParams.delete("step");
        const newURL = url.pathname;
        router.replace(newURL);
      } else {
        getCampaign();
      }
    } else {
      getCampaign();
      return;
    }
  }, [form]);

  // }

  async function signinwithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: campaign.bpsId,
          action: "faq_login_success",
        });
        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName?.toLowerCase() ?? "Change User Name",
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          phone: user.phoneNumber,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
          joinDate: Date.now(),
          categories: [],
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          showWarning("Something went wrong");
          return;
        }
        setuserDetails(response?.data?.user);
        router.push("?step=question1");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function sendOtpToPhone() {
    let regex = /^[6-9][0-9]{9}/i;
    if (!regex.test(phoneNumber)) {
      return showWarning("Please enter valid phone number");
    }

    const auth = getAuth();

    const phonenumber = "+" + phoneCountryCode + phoneNumber;

    if (phonenumber.length >= 12) {
      setOtpLoading(true);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
          },
        },
        auth
      );
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phonenumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setoptSent(true);
          setOtpLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setOtpLoading(false);
          router.replace("?step=signin");
        });
    }
  }
  async function signinwithOtp() {
    let regex = /\d{6}/i;
    if (!regex.test(phoneOtp)) {
      return showWarning("Please enter valid otp");
    }
    setOtpLoading(true);
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(phoneOtp)
      .then(async (result) => {
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: campaign.bpsId,
          action: "faq_login_success",
        });
        const user = result.user;
        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName?.toLowerCase() ?? "Change User Name",
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          phone: user.phoneNumber,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
          joinDate: Date.now(),
          categories: [],
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          showWarning("Something went wrong");
          return;
        }
        setuserDetails(response?.data?.user);
        setOtpLoading(false);
        router.push("?step=question1");
      })
      .catch((err) => {
        showWarning("Something went wrong");
        setOtpLoading(false);
        router.replace("?step=signin");
      });
  }

  async function uploadVideo(event, index) {
    setuploadingVideo(true);
    var file = event.target.files[0];
    var type = file.type.split("/")[1];
    const formats = ["mp4"];

    if (!formats.includes(type)) {
      alert("Please upload a video file");
      return;
    }

    if (index === 0) {
      await handleFileUpload(event);
    }

    const storage = getStorage();
    const storageRef = ref(
      storage,
      "faqforms/" + video_id + `/question${index}.mp4`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setuploadingVideo(true);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setuploadProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setvideoDataURL((prevArray) => {
            const updatedArray = prevArray.map((obj) => {
              if (obj.id === index) {
                return { ...obj, videoUrl: downloadURL };
              }
              return obj;
            });

            return updatedArray;
          });
          setuploadingVideo(false);
          setuploadProgress(0);
        });
      }
    );
  }
  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  const handleFileUpload = async (e) => {
    const thumbnail_snap = await generateVideoThumbnail(e.target.files[0]);

    const storage = getStorage();
    const storageRef = ref(storage, "faqforms/" + video_id + `/thumbnail.jpg`);

    uploadString(storageRef, thumbnail_snap, "data_url").then((snapshot) => {
      getDownloadURL(storageRef).then((downloadURL) => {
        setthumbnailurl(downloadURL);
      });
    });
  };

  async function changeQuestion(index) {
    if (searchParams.get("step") === `question${campaign?.questions?.length}`) {
      setuploadingVideo(null);
      setuploadProgress(0);
      await submitSubmission();
      router.replace(`?step=success`);
      logEngagementHook({
        campaignId: form,
        date: Date.now(),
        platform: device,
        brand: campaign.bpsId,
        action: "user_complete_faq",
      });
      return;
    } else {
      setuploadingVideo(null);
      setuploadProgress(0);
      router.replace(`?step=question${index + 1}`);
    }
    return;
  }

  async function questionBack(index) {
    if (searchParams.get("step") === "question1") {
      setuploadingVideo(null);
      setuploadProgress(0);
      router.replace("?step=signin");
      return;
    } else {
      setuploadingVideo(null);
      setuploadProgress(0);
      router.replace(`?step=question${index - 1}`);
      return;
    }
  }

  async function submitSubmission() {
    let newdoc = {
      campaign_info: campaign?.campaign_name,
      user_info: {
        name: userDetails?.name,
        tag: userDetails.tag ?? null,
        token: userDetails.token ?? null,
        id: userDetails?.id,
        image: userDetails.imageUrl ?? null,
        email: userDetails.email ?? null,
        phone: userDetails.phone ?? null,
      },
      thumbnailurl: thumbnailurl,
      reviews: videoDataURL.map((obj) => ({
        id: obj.id,
        question: obj.question,
        reviewUrl: obj.videoUrl,
      })),
      questions: campaign?.questions,
      substatus: "pending",
      dateAdded: Date.now(),
    };
    await setDoc(
      doc(db, "faqforms/" + form + "/submissions/", video_id),
      newdoc
    )
      .then(() => {
        showSuccess("Submitted successFully");
        pushNotification({
          message: "FAQ submission got for " + campaign?.campaign_name,
          endpoint: "/engagement/view-faqcampaigns/" + campaign.id,
          type: "faq",
          client: campaign.bpsId,
        });
      })
      .catch((err) => console.log(err));
  }
  if (pageLoading === true) {
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="relative flex items-center justify-center h-full overflow-y-auto"
            id="thin-scrollbar"
          >
            <span className="animate-spin">
              <CircleNotch size={46} color="white" />
            </span>
          </div>
        </div>
      </div>
    );
  }
  if (isvalidCampaign === false) {
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="relative flex flex-col justify-between h-full overflow-y-auto "
            id="thin-scrollbar"
          >
            <div className="flex items-center justify-center px-5 pt-10">
              <div className="h-16 px-8 py-2 overflow-hidden bg-white rounded-xl ">
                <img
                  src={
                    campaign.LogoLink ??
                    "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                  }
                  alt=""
                  className="h-full mx-auto "
                />
              </div>
            </div>
            <div className="">
              <p className="text-center font-work">
                This isn't a valid campaign <br /> please check link
              </p>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        {searchParams.get("step") === null && (
          <div
            className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] transition-all delay-200 overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
            style={{
              backgroundColor:
                campaign?.customizations?.secondaryColor ?? "#02638F",
            }}
          >
            <div
              className="relative h-full overflow-y-auto"
              id="thin-scrollbar"
            >
              <div className="flex items-center justify-center px-5 pt-10">
                <div className="flex items-center justify-center h-16 px-8 py-2 overflow-hidden bg-white rounded-xl ">
                  <img
                    src={
                      campaign.LogoLink ??
                      "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                    }
                    alt=""
                    className="h-full mx-auto "
                  />
                </div>
              </div>
              <div className="px-5 mt-5">
                <div className="">
                  <p className="font-medium tracking-wider text-center">
                    {campaign?.campaign_name}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center my-5">
                  <div>
                    {campaign?.spokeLink ? (
                      <img
                        src={campaign?.spokeLink}
                        alt=""
                        className="object-cover w-16 h-16 mx-auto my-2 rounded-full"
                      />
                    ) : (
                      <div className="flex items-center justify-center w-16 h-16 text-3xl text-pink-500 bg-white rounded-full">
                        {campaign?.name
                          ?.split(" ")[0]
                          .substring(0, 2)
                          .toUpperCase() ?? "NA"}
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <p className="tracking-wide text-center ">
                      {campaign?.name}
                    </p>
                    <p className="font-light tracking-wide text-center opacity-50">
                      {campaign?.designation}
                    </p>
                  </div>
                  <div className="border-l-[3px] border-red-500 mt-5">
                    <p className="px-2 tracking-wide">
                      {campaign?.welcomeMessage}
                    </p>
                  </div>
                  <div className="flex flex-col items-center mt-12">
                    <p className="text-lg font-medium">How it Works</p>
                    <div className="flex w-full mt-5 gap-x-2">
                      <p className="flex-1 w-full text-sm tracking-wide">
                        Tell your story by recording a short video clip here.
                        Nothing to download or install.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="absolute bottom-0 flex flex-col justify-end w-full h-32 px-5 pb-5 bg-gradient-to-b from-transparent via-transparent md:rounded-b-lg"
              style={{ "--tw-gradient-to": "#00000069" }}
            >
              <button
                onClick={() => router.push("?step=signin")}
                className="w-full py-3 rounded-lg outline-none appearance-none"
                style={{
                  backgroundColor:
                    campaign?.customizations?.primaryColor ?? "#0097A7",
                }}
              >
                Let's Go
              </button>
            </div>
          </div>
        )}
        {searchParams.get("step") === "signin" && (
          <div
            className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] transition-all delay-200 overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
            style={{
              backgroundColor:
                campaign?.customizations?.secondaryColor ?? "#02638F",
            }}
          >
            <div
              className="relative flex flex-col h-full overflow-y-auto"
              id="thin-scrollbar"
            >
              <div className="flex items-end justify-between px-5 pt-5">
                <div
                  onClick={() => router.goBack()}
                  className="flex items-center justify-center bg-white rounded-lg cursor-pointer bg-opacity-10 h-14 w-14"
                >
                  <CaretLeft size={22} color="white" />
                </div>
                <div className="">
                  <p className="text-[#F8F8F8] text-right pb-1 tracking-wide font-medium">
                    Step 2 of {campaign?.questions?.length + 2}{" "}
                  </p>
                  <div className="flex gap-1">
                    <div className="h-1 w-5 rounded-full bg-[#bebebe]"></div>
                    <div className="h-1 w-5 rounded-full bg-[#ffffff]"></div>
                    {campaign?.questions?.map((question, index) => (
                      <div
                        className="h-1 w-5 rounded-full bg-[#bebebe]"
                        key={index}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="px-5 mt-5">
                <div className="">
                  <p className=""></p>
                  <p className="tracking-wide">
                    Please login /signup to <br />
                    continue
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-end flex-1 h-full gap-3 px-5 pb-5">
                <button
                  onClick={() => router.push("?step=phonesignin")}
                  className="h-12 px-3 rounded-lg appearance-none outline-none w-full bg-[#E3E3E3] hover:bg-white transition-all text-[#443434] flex items-center justify-between gap-3 font-medium tracking-wide"
                >
                  <Phone size={22} color="currentcolor" /> Sign in with Phone{" "}
                  <span className=""></span>
                </button>
                <button
                  onClick={() => signinwithGoogle()}
                  className="h-12 px-3 rounded-lg appearance-none outline-none w-full border border-[#B0B0B0] hover:border-white transition-all text-[#F8F8F8] flex items-center justify-between gap-3 font-medium tracking-wide"
                >
                  <GoogleLogo size={22} color="currentcolor" weight="bold" />{" "}
                  Sign in with Google <span className=""></span>
                </button>
              </div>
            </div>
          </div>
        )}
        {searchParams.get("step") === "phonesignin" && (
          <div
            className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] transition-all delay-200 overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
            style={{
              backgroundColor:
                campaign?.customizations?.secondaryColor ?? "#02638F",
            }}
          >
            <div
              className="relative flex flex-col h-full overflow-y-auto"
              id="thin-scrollbar"
            >
              <div className="flex items-end justify-between px-5 pt-5">
                <div
                  onClick={() => router.goBack()}
                  className="flex items-center justify-center bg-white rounded-lg cursor-pointer bg-opacity-10 h-14 w-14"
                >
                  <CaretLeft size={22} color="white" />
                </div>
                <div className="">
                  <p className="text-[#F8F8F8] text-right pb-1 tracking-wide font-medium">
                    Step 1 of {campaign?.questions?.length + 2}{" "}
                  </p>
                  <div className="flex gap-1">
                    <div className="h-1 w-5 rounded-full bg-[#bebebe]"></div>
                    <div className="h-1 w-5 rounded-full bg-[#ffffff]"></div>
                    {campaign?.questions?.map((question, index) => (
                      <div
                        className="h-1 w-5 rounded-full bg-[#bebebe]"
                        key={index}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="px-5 mt-5">
                <div className="" id="sign-in-button"></div>
                <div className="">
                  <p className=""></p>
                  <p className="leading-tight tracking-wider">
                    Please enter your <br />
                    phone number to continue
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-end flex-1 h-full gap-3 px-5 pb-5">
                <div className="pb-2">
                  {optSent === false ? (
                    <div className="flex w-full gap-3">
                      <input
                        type="number"
                        className="appearance-none outline-none h-11 bg-transparent border border-[#E3E3E3] focus:border-white hover:border-white px-3 w-14 rounded-lg placeholder:tracking-wide placeholder:text-[#c4c4c4] placeholder:text-sm"
                        placeholder="Country Code"
                        value={phoneCountryCode}
                        onChange={(event) =>
                          setphoneCountryCode(event.target.value)
                        }
                      />
                      <input
                        type="number"
                        className="appearance-none outline-none h-11 bg-transparent border border-[#E3E3E3] focus:border-white hover:border-white px-3 flex-1 w-full rounded-lg placeholder:tracking-wide placeholder:text-[#c4c4c4] placeholder:text-sm"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        maxLength="10"
                        onChange={(event) => setphoneNumber(event.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="flex w-full gap-3">
                      <input
                        type="number"
                        className="appearance-none outline-none h-11 bg-transparent border border-[#E3E3E3] focus:border-white hover:border-white px-3 flex-1 w-full rounded-lg placeholder:tracking-wide placeholder:text-[#c4c4c4] placeholder:text-sm"
                        placeholder="Enter otp sent to your phone number"
                        value={phoneOtp}
                        maxLength={10}
                        onChange={(event) => setphoneOtp(event.target.value)}
                      />
                    </div>
                  )}
                </div>
                {optSent === false ? (
                  <button
                    onClick={() => sendOtpToPhone()}
                    className="h-12 px-3 rounded-lg appearance-none outline-none w-full bg-[#E3E3E3] hover:bg-white transition-all text-[#443434] flex items-center justify-center gap-3 font-medium tracking-wide"
                  >
                    {otpLoading === true ? (
                      <span className="animate-spin">
                        <CircleNotch size={22} color="currentcolor" />
                      </span>
                    ) : (
                      <span>Get OTP</span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={() => signinwithOtp()}
                    className="h-12 px-3 rounded-lg appearance-none outline-none w-full bg-[#E3E3E3] hover:bg-white transition-all text-[#443434] flex items-center justify-center gap-3 font-medium tracking-wide"
                  >
                    {otpLoading === true ? (
                      <span className="animate-spin">
                        <CircleNotch size={22} color="currentcolor" />
                      </span>
                    ) : (
                      <span className="">Verify OTP</span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {searchParams.get("step")?.includes("question") && (
          <div
            className="h-screen w-full md:w-fit md:h-full transition-all delay-200 md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
            id="thin-scrollbar"
            style={{
              backgroundColor:
                campaign?.customizations?.secondaryColor ?? "#02638F",
            }}
          >
            {campaign?.questions?.map((question, index) => (
              <div
                key={index}
                className={`${
                  searchParams.get("step") === `question${index + 1}`
                    ? "block h-full w-full transition-all delay-200"
                    : "hidden transition-all delay-200"
                }`}
              >
                {uploadingVideo === null ? (
                  <div
                    className="relative flex flex-col h-full overflow-y-auto"
                    id="thin-scrollbar"
                  >
                    <div className="flex items-end justify-between px-5 pt-5">
                      <div
                        onClick={() => questionBack(index + 1)}
                        className="flex items-center justify-center bg-white rounded-lg cursor-pointer bg-opacity-10 h-14 w-14"
                      >
                        <CaretLeft size={22} color="white" />
                      </div>
                      <div className="">
                        <p className="text-[#F8F8F8] text-right pb-1 tracking-wide font-medium">
                          Step {index + 3} of {campaign?.questions?.length + 2}{" "}
                        </p>
                        <div className="flex gap-1">
                          <div className="h-1 w-5 rounded-full bg-[#bebebe]"></div>
                          <div className="h-1 w-5 rounded-full bg-[#bebebe]"></div>
                          {campaign?.questions?.map((question, sindex) => (
                            <div
                              className={`h-1 w-5 rounded-full ${
                                searchParams.get("step").includes(sindex + 1)
                                  ? "bg-white"
                                  : "bg-[#bebebe]"
                              }`}
                              key={sindex}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="px-5 mt-5">
                      <div className="">
                        <p className=""></p>
                        <p className="tracking-wide leading-tight font-medium text-[#c93434]">
                          Record a video{" "}
                        </p>
                        <p className="text-[#ebebeb] tracking-wide">
                          Question :{" "}
                          <span className="text-white">
                            {question?.question}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-end flex-1 h-full gap-3 px-5 pb-5">
                      <div className="pb-2">
                        <p className="text-[#d33631] tracking-wide">Tips</p>
                        {campaign?.points?.map((point, index) => (
                          <div key={index} className="">
                            <p className="text-sm tracking-wider ">
                              {index + 1}: {point.question}
                            </p>
                          </div>
                        ))}{" "}
                      </div>

                      <div className="flex flex-col gap-1">
                        {device === "Mobile" ? (
                          <>
                            <button
                              className="relative flex items-center justify-center w-full h-12 gap-3 px-3 font-medium tracking-wide rounded-lg outline-none appearance-none"
                              style={{
                                backgroundColor:
                                  campaign?.customizations?.primaryColor ??
                                  "#0097A7",
                              }}
                            >
                              <input
                                type="file"
                                accept="video/*"
                                capture="camera"
                                className="absolute z-10 w-full h-full rounded-lg opacity-0"
                                onChange={(event) => uploadVideo(event, index)}
                              />
                              <VideoCamera size={22} color="white" />
                              Record Video
                            </button>
                            <p className="text-center">OR</p>
                          </>
                        ) : null}
                        <button
                          className="relative flex items-center justify-center w-full h-12 gap-3 px-3 font-medium tracking-wide rounded-lg outline-none appearance-none"
                          style={{
                            backgroundColor:
                              campaign?.customizations?.primaryColor ??
                              "#0097A7",
                          }}
                        >
                          <input
                            type="file"
                            accept=".mp4"
                            className="absolute z-10 w-full h-full rounded-lg opacity-0"
                            onChange={(event) => uploadVideo(event, index)}
                          />
                          <Upload size={22} color="white" />
                          Upload Video
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="relative flex flex-col h-full overflow-y-auto"
                    id="thin-scrollbar"
                  >
                    <div className="flex items-end justify-between px-5 pt-5">
                      <div
                        onClick={() => questionBack(index + 1)}
                        className="flex items-center justify-center bg-white rounded-lg cursor-pointer bg-opacity-10 h-14 w-14"
                      >
                        <CaretLeft size={22} color="white" />
                      </div>
                      <div className="">
                        <p className="text-[#F8F8F8] text-right pb-1 tracking-wide font-medium">
                          Step {index + 3} of {campaign?.questions?.length + 2}{" "}
                        </p>
                        <div className="flex gap-1">
                          <div className="h-1 w-5 rounded-full bg-[#bebebe]"></div>
                          <div className="h-1 w-5 rounded-full bg-[#bebebe]"></div>
                          {campaign?.questions?.map((question, sindex) => (
                            <div
                              className={`h-1 w-5 rounded-full ${
                                searchParams.get("step").includes(sindex + 1)
                                  ? "bg-white"
                                  : "bg-[#bebebe]"
                              }`}
                              key={sindex}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="px-5 mt-5">
                      <div className="">
                        <p className="text-[#ebebeb] tracking-wide">
                          Question :{" "}
                          <span className="text-white">
                            {question?.question}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-end flex-1 h-full gap-3 px-5 pb-5">
                      {videoDataURL[index]?.videoUrl !== null ? (
                        <div className="flex flex-1 w-full h-full pt-5">
                          <video
                            playsInline
                            src={videoDataURL[index]?.videoUrl}
                            className={`rounded-lg w-full aspect-square ${
                              document.fullscreenElement ===
                              document.getElementById("videoplayer")
                                ? ""
                                : "object-cover"
                            }`}
                            controls
                            disablePictureInPicture
                            controlsList="nodownload noplaybackrate"
                            id="videoplayer"
                            onLoadedMetadata={() => {
                              const handleFullscreenChange = () => {
                                const videoPlayer =
                                  document.getElementById("videoplayer");
                                videoPlayer.className = `rounded-lg w-full aspect-square ${
                                  document.fullscreenElement === videoPlayer
                                    ? ""
                                    : "object-cover"
                                }`;
                              };

                              document.addEventListener(
                                "fullscreenchange",
                                handleFullscreenChange
                              );

                              return () => {
                                document.removeEventListener(
                                  "fullscreenchange",
                                  handleFullscreenChange
                                );
                              };
                            }}
                          ></video>
                        </div>
                      ) : (
                        <div className="pb-2">
                          <p className="text-[#698FAC] tracking-wide pb-1">
                            Uploading video...
                          </p>
                          <div className="h-2 w-full bg-[#D9D9D9] rounded-full p-[1px]">
                            <div
                              className="h-full rounded-full"
                              style={{
                                width: `${uploadProgress}%`,
                                backgroundColor:
                                  campaign?.customizations?.primaryColor ??
                                  "#0097A7",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}

                      {videoDataURL[index]?.videoUrl !== null && (
                        <div className="flex flex-col gap-1">
                          {uploadProgress > 1 &&
                          uploadProgress < 99 &&
                          uploadingVideo === true ? (
                            <div className="pb-2">
                              <p className="text-[#698FAC] tracking-wide pb-1">
                                Uploading new submission... {uploadProgress}
                              </p>
                              <div className="h-2 w-full bg-[#D9D9D9] rounded-full p-[1px]">
                                <div
                                  className="h-full rounded-full"
                                  style={{
                                    width: `${uploadProgress}%`,
                                    backgroundColor:
                                      campaign?.customizations?.primaryColor ??
                                      "#0097A7",
                                  }}
                                ></div>
                              </div>
                            </div>
                          ) : null}
                          <button
                            onClick={() => changeQuestion(index + 1)}
                            className="flex items-center justify-center w-full h-12 gap-3 px-3 font-medium tracking-wide rounded-lg outline-none appearance-none"
                            style={{
                              backgroundColor:
                                campaign?.customizations?.primaryColor ??
                                "#0097A7",
                            }}
                          >
                            Submit
                          </button>
                          <p className="text-center">OR</p>
                          <button
                            className="relative flex items-center justify-center w-full h-12 gap-3 px-3 font-medium tracking-wide rounded-lg outline-none appearance-none"
                            style={{
                              backgroundColor:
                                campaign?.customizations?.primaryColor ??
                                "#0097A7",
                            }}
                          >
                            <input
                              type="file"
                              accept="video/*"
                              capture="camera"
                              className="absolute z-10 w-full h-full rounded-lg opacity-0"
                              onChange={(event) => uploadVideo(event, index)}
                            />
                            <VideoCamera size={22} color="white" />
                            Re - Record Video
                          </button>
                          <button
                            className="relative flex items-center justify-center w-full h-12 gap-3 px-3 font-medium tracking-wide rounded-lg outline-none appearance-none"
                            style={{
                              backgroundColor:
                                campaign?.customizations?.primaryColor ??
                                "#0097A7",
                            }}
                          >
                            <input
                              type="file"
                              accept="video/*"
                              capture="camera"
                              className="absolute z-10 w-full h-full rounded-lg opacity-0"
                              onChange={(event) => uploadVideo(event, index)}
                            />
                            <Upload size={22} color="white" />
                            Re - Upload Video
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {searchParams.get("step") === "success" && (
          <div
            className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative transition-all delay-200"
            style={{
              backgroundColor:
                campaign?.customizations?.secondaryColor ?? "#02638F",
            }}
          >
            <div
              className="relative flex flex-col h-full overflow-y-auto"
              id="thin-scrollbar"
            >
              <div className="flex items-center justify-center px-5 pt-10">
                <div className="flex items-center justify-center h-16 px-8 py-2 overflow-hidden bg-white rounded-xl ">
                  <img
                    src={
                      campaign.LogoLink ??
                      "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                    }
                    alt=""
                    className="h-full mx-auto "
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center p-5 mt-12">
                <div className="border-l-[3px] border-red-500 ">
                  <p className="px-2 text-3xl font-medium">
                    🎉 {campaign?.headLine}
                  </p>
                  <p className="px-2 mt-3 font-sans text-xl font-light tracking-wide">
                    {campaign?.thankYouMessageRef}
                  </p>
                </div>
                <div className="flex items-center mt-8 gap-x-3">
                  <div>
                    {campaign?.spokeLink ? (
                      <img
                        src={campaign?.spokeLink}
                        alt=""
                        className="object-cover w-16 h-16 mx-auto my-2 rounded-full"
                      />
                    ) : (
                      <div className="flex items-center justify-center w-16 h-16 text-3xl text-pink-500 bg-white rounded-full">
                        {campaign?.name
                          ?.split(" ")[0]
                          .substring(0, 2)
                          .toUpperCase() ?? "NA"}
                      </div>
                    )}
                  </div>
                  <div className="">
                    <p className="tracking-wide ">{campaign?.name}</p>
                    <p className="font-light tracking-wide opacity-50">
                      {campaign?.designation}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-10 ">
                  <div className="flex flex-col items-center justify-center w-full p-10 bg-white rounded-2xl">
                    <p className="font-sans text-lg tracking-widest text-black uppercase ">
                      POWERED BY
                    </p>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMyRevue%20Logo%20(1)%201.svg?alt=media&token=f3878494-f182-43c4-bbfb-6d1f7247af27"
                      alt=""
                      className="my-3 h-14"
                    />
                    <p className="text-center text-black">
                      Tech Enabled
                      <span className="font-semibold">
                        {" "}
                        Content As A Service (CAAS) Platform{" "}
                      </span>
                      For BHARAT Brands
                    </p>
                    <span className="font-semibold text-[13px] text-[#FF005C]">
                      {" "}
                      AWARE. ENGAGE. RETAIN
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
