import React from "react";
import { Link } from "react-router-dom";

export default function GalleryCardView({ data }) {
  return (
    <Link to={"viewgallery/" + data.design + "/" + data.id}>
      <div className="h-[240px] w-full mx-auto flex items-center group cursor-pointer">
        {data?.reviews?.length >= 3 && (
          <div
            className={`rounded-xl grid grid-cols-3 h-full aspect-square bg-white relative`}
          >
            <img
              src={data?.reviews[0]?.thumbnail}
              alt="thumbnail"
              className="object-cover w-full h-full transition-all first:rounded-l-xl last:rounded-r-xl saturate-0 group-hover:saturate-100"
            />
            <img
              src={data?.reviews[1]?.thumbnail}
              alt="thumbnail"
              className="object-cover w-full h-full transition-all first:rounded-l-xl last:rounded-r-xl saturate-0 group-hover:saturate-100"
            />
            <div className="absolute z-10 flex justify-end w-full h-full rounded-xl">
              <div className="w-1/3 bg-gradient-to-r from-transparent via-[#2e30366c] to-[#2e3036e8] h-full"></div>
            </div>
            <img
              src={data?.reviews[2]?.thumbnail}
              alt="thumbnail"
              className="object-cover w-full h-full transition-all first:rounded-l-xl last:rounded-r-xl saturate-0 group-hover:saturate-100"
            />
          </div>
        )}
        {data?.reviews?.length === 2 && (
          <div
            className={`rounded-xl grid grid-cols-2 h-full aspect-square bg-white relative`}
          >
            <img
              src={data?.reviews[0]?.thumbnail}
              alt="thumbnail"
              className="object-cover w-full h-full transition-all first:rounded-l-xl last:rounded-r-xl saturate-0 group-hover:saturate-100"
            />
            <div className="absolute z-10 flex justify-end w-full h-full rounded-xl">
              <div className="w-1/3 bg-gradient-to-r from-transparent via-[#2e30366c] to-[#2e3036e8] h-full"></div>
            </div>
            <img
              src={data?.reviews[1]?.thumbnail}
              alt="thumbnail"
              className="object-cover w-full h-full transition-all first:rounded-l-xl last:rounded-r-xl saturate-0 group-hover:saturate-100"
            />
          </div>
        )}
        {data?.reviews?.length === 1 && (
          <div className="relative grid h-full grid-cols-1 bg-white rounded-xl aspect-square">
            <img
              src={data?.reviews[0]?.thumbnail}
              alt="thumbnails"
              className="object-cover w-full transition-all aspect-square rounded-xl saturate-0 group-hover:saturate-100"
            />
            <div className="absolute z-10 flex justify-end w-full h-full rounded-xl">
              <div className="w-1/3 bg-gradient-to-r from-transparent via-[#2e30366c] to-[#2e3036e8] h-full rounded-r-xl"></div>
            </div>
          </div>
        )}
        <div className="z-50 flex items-center flex-1 h-full -ml-12">
          <div className="space-y-1">
            <p className="font-work font-semibold text-[36px] leading-none">
              {data?.design}
            </p>
            <p className="text-lg font-work">{data?.title}</p>
            <button
              className={`${data?.published === true ? "bg-[#63D876]" : "bg-[#B0B0B0]"
                } font-work w-40 h-11 rounded-lg text-lg`}
            >
              {data?.published === true ? "Published" : "Draft"}
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
}
