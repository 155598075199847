import React, { useState } from "react";
import { Eye } from "phosphor-react";
import { useHistory } from "react-router";
import { sendAmplitudeData } from "../amplitude";
import { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import {
  Hexagon,
  ListDashes,
  MagnifyingGlass,
  SquaresFour,
} from "@phosphor-icons/react";
export default function Products() {
  const [{ products }, dispatch] = useStateValue();
  const [gridView, setgridView] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    sendAmplitudeData("product list opened");
  }, []);

  const router = useHistory();

  return (
    <div className="relative w-full pt-5">
      <div className="px-5">
        <p className="text-3xl font-medium tracking-wide text-white">
          Products
        </p>
      </div>
      <div className="flex items-center justify-between w-full px-5 mt-5">
        <div className="h-11">
          <div className="bg-[#D9D9D9] h-full p-1 rounded-full w-fit flex items-center text-black ">
            <button
              onClick={() => setgridView(false)}
              className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${gridView === false ? "bg-[#131418] text-white" : null
                } rounded-full`}
            >
              <ListDashes size={18} color="currentcolor" />
              <p className="">List</p>
            </button>
            <button
              onClick={() => setgridView(true)}
              className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${gridView === true ? "bg-[#131418] text-white" : null
                } rounded-full`}
            >
              <SquaresFour size={18} color="currentcolor" />
              <p className="">Grid</p>
            </button>
          </div>
        </div>
        <div className="h-11 w-80 relative bg-[#131418] rounded-lg flex items-center px-3 group gap-3">
          <MagnifyingGlass
            size={22}
            className="group-focus-within:text-white text-[#8E8B8B]"
          />
          <input
            type="text"
            onChange={(event) => setSearchText(event.target.value)}
            className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B]"
            placeholder="Search by product name"
          />
        </div>
        <div className="">
          <button
            className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded"
            onClick={() => router.push("/admin/createproduct")}
          >
            Create New
          </button>
        </div>
      </div>
      {gridView === false && (
        <div className="relative block px-5 mt-10 mb-10">
          <div className="relative">
            <div className="overflow-x-auto min-h-[0.01%]">
              <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                <thead className="h-14 bg-[#131418]">
                  <tr>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      #
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Title
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Product Id
                    </th>
                    <th className=" font-normal border-b-0 p-[15px]  text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Status
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Rating
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Reviews
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Flicks
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="table-row-group align-middle border-inherit">
                  {products
                    ?.filter((val) =>
                      val?.name
                        ?.toLowerCase()
                        ?.includes(searchText?.toLowerCase())
                    )
                    ?.map((product, index) => (
                      <React.Fragment key={index}>
                        <tr className="table-row-spacer"></tr>
                        <tr className="table-row align-[inherit]  bg-[#131418]">
                          <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                            {index + 1}
                          </td>
                          <td className="break-words max-w-[400px]  align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                            <div className="flex items-center w-full h-full gap-2">
                              <img
                                src={product?.imageUrl}
                                alt="product_image"
                                className="w-10 h-10 rounded"
                              />
                              <span className="font-light tracking-wider line-clamp-2">
                                {product?.name}
                              </span>
                            </div>
                          </td>
                          <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                            {product?.id}
                          </td>
                          <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                            {product?.status === true ? (
                              <span className="flex items-center gap-2">
                                {" "}
                                <div className="w-2 h-2 bg-green-500 rounded-full"></div>{" "}
                                Published
                              </span>
                            ) : (
                              <span className="flex items-center gap-2">
                                {" "}
                                <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div>{" "}
                                Pending
                              </span>
                            )}
                          </td>
                          <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                            {product?.rating?.toFixed(2) ?? 0}
                          </td>
                          <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                            {product?.reviews ?? 0}
                          </td>
                          <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                            {product?.flicks ?? 0}
                          </td>
                          <td
                            className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                            onClick={() =>
                              router.push("/admin/viewproduct/" + product.id)
                            }
                          >
                            <div className="flex items-center gap-x-3">
                              <div className="relative flex items-center justify-center w-10 h-10">
                                <Hexagon size={40} weight="light" />
                                <Eye
                                  size={20}
                                  className="absolute"
                                  weight="bold"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
              {products.length === 0 && (
                <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                  No Products Available
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {gridView === true && (
        <div className="grid w-full p-5 pb-10 mt-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-16">
          {products
            ?.filter((val) =>
              val?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
            )
            ?.map((product, index) => (
              <div
                className=" cursor-pointer w-full bg-[#232323] h-[173px] rounded-3xl relative flex flex-col justify-between"
                key={index}
                onClick={() => router.push("/admin/viewproduct/" + product.id)}
              >
                <div className="absolute top-[2px] right-[2px]">
                  {product?.status === true && (
                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                  )}
                  {product?.status === false && (
                    <div className="h-3 w-3 rounded-full bg-[#D88D63]"></div>
                  )}
                </div>
                <div className="">
                  <div className="flex items-center gap-3 p-3">
                    <img
                      src={product?.imageUrl}
                      alt="thumbnail"
                      className="h-[85px] aspect-square rounded-lg -mt-8"
                    />
                    <div className="">
                      <div className="flex items-center gap-3">
                        <p className="text-sm text-[#D9D9D9] font-work tracking-wide">
                          Rating
                        </p>
                        <p className="font-work">
                          {product?.rating?.toFixed(2)}
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <p className="text-sm text-[#D9D9D9] font-work tracking-wide">
                          Reviews
                        </p>
                        <p className="font-work">{product?.reviews}</p>
                      </div>
                      <div className="flex items-center gap-3">
                        <p className="text-sm text-[#D9D9D9] font-work tracking-wide">
                          Flicks
                        </p>
                        <p className="font-work">{product?.flicks}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between px-3">
                    <p className="text-sm text-[#D9D9D9] tracking-wide">
                      {product?.id}
                    </p>
                    <div className="flex flex-col items-center">
                      <div className="relative flex items-center justify-center w-6 h-6">
                        <Hexagon size={22} weight="light" />
                        <Eye size={10} className="absolute" weight="bold" />
                      </div>
                      <p className="text-[8px] tracking-wide">View</p>
                    </div>
                  </div>
                </div>
                <div className="text-sm font-light tracking-wide py-2 px-3 bg-[#2C2C2C] text-[#D9D9D9] rounded-b-3xl line-clamp-2 min-h-fit max-h-[50px]">
                  {product?.name}
                </div>
              </div>
            ))}
        </div>
      )}
      {/* <div className="sticky bottom-0 bg-[#131418] px-5 z-50 w-full border-t-[2px] border-[#292929] flex items-center justify-between">
        <div className="h-12 flex items-center justify-between  w-full text-[#808080]">
          Showing 1 to {products?.length} of {products?.length}
        </div>
        <div className="text-[#808080] hover:text-white cursor-pointer">
          {products?.length}
        </div>
      </div> */}
    </div>
  );
}
