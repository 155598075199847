import axios from "axios";
import { nanoid } from "nanoid";

async function logEngagementHook(data) {
  try {
    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/engagementHook",
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios.request(reqOptions);

    return true;
  } catch (error) {
    return false;
  }
}
async function getNotificationFromDB(brandId) {
  try {
    let reqOptions = {
      url: ` https://brandadminapi-zscu3untuq-el.a.run.app/getBrandNotifications/${brandId}`,
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization:
          "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
        "Content-Type": "application/json",
      },
    };

    const response = await axios.request(reqOptions);

    if (response.data.success) return response.data.data;
    throw new Error("no success");
  } catch (error) {
    return [];
  }
}

async function getEngagementAnalytics(brand, base) {
  try {
    let reqOptions = {
      url:
        "https://adminapi-zscu3untuq-el.a.run.app/getEngagementAnalytics/" +
        base +
        "/" +
        brand,
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        "Content-Type": "application/json",
      },
    };
    var response = await axios.request(reqOptions);
    return response.data;
  } catch (error) {
    return null;
  }
}

const wait = (time) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });

async function publishFlick(flick) {
  var flickdata = {
    id: "review_" + nanoid(16),
    title: flick?.description,
    name: flick?.description,
    description: flick?.description,
    bpsId: flick?.prodId,
    flickTag: flick?.flickTag,
    uploadedBy: flick?.appUserId,
    thumbnail: flick?.thumbnail,
    reviewUrl: flick?.reviewUrl,
    subFlicksTag: flick?.subFlicksTag,
    brand: flick?.brand,
    reviewLanguage: flick?.language,
    views: 0,
    hearts: 0,
    rating: 0,
    type: "flicks",
    processed: false,
    subcategory: null,
    catId: null,
    purchaseMode: null,
    purchaseTime: null,
    orderId: null,
  };

  const reqOptions = {
    url: `https://flicksapi-zscu3untuq-el.a.run.app/createFlicks`,
    method: "PUT",
    headers: {
      Accept: "*/*",
      Authorization: "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
    },
    data: flickdata,
  };

  const response = await axios.request(reqOptions);
  return response.data.success;
}

export {
  logEngagementHook,
  getEngagementAnalytics,
  publishFlick,
  getNotificationFromDB,
};
