import AWN from 'awesome-notifications';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { nanoid } from 'nanoid';
import { UploadSimple, X } from 'phosphor-react'
import React from 'react'
import { useState } from 'react';

export default function Userviewfileupload({ images, setimages }) {

    const [progress, setprogress] = useState(0);
    const addItem = (event) => {

        const files = event.target.files;

        if (files?.length > 3) {
            return new AWN().warning("maximum 3 images allowed")
        }
        if (!files.length) return;
        var a = []
        for (let i = 0; i <= files.length - 1; i++) {
            const file = files[i]
            var type = file.type.split("/")[1];
            const id = nanoid(20)
            const storage = getStorage();
            const storageRef = ref(storage, "formReview/images/" + id + "." + type);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setprogress(progress)
                },
                (error) => { },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        if (i === files.length - 1) {
                            setimages([...a, downloadURL])
                        }
                        else {
                            a.push(downloadURL)
                        }
                    });
                }
            );
        }
    };

    const removeItem = (index) => {
        const newimages = [...images];
        newimages.splice(index, 1);
        setimages(newimages);
    };
    return (
        <>
            <div className="">
                <p className=""> Upload image (optional)</p>
            </div>
            {images?.length === 0 ? <div className="grid grid-cols-3 gap-8 mt-2">
                <div className="w-full aspect-square bg-[#00000059] rounded-md relative">
                    <div className="relative flex items-center justify-center w-full h-full">
                        <UploadSimple size={32} color="#bbbbbb" />
                        <input type="file" accept='.jpg, .jpeg, .png' multiple className='absolute w-full h-full opacity-0 ' onChange={(event) => addItem(event)} />
                    </div>
                </div>
            </div>
                :
                <div className="grid grid-cols-3 gap-8 mt-5">
                    {images?.map((images, index) => (
                        <div className="w-full aspect-square bg-[#00000059] rounded-md relative select-none" key={index}>
                            <div className="absolute top-0 right-0 z-10 flex justify-center w-5 h-5 -m-2 bg-white rounded-full images-center" onClick={() => removeItem(index)}>
                                <X size={16} color="black" />
                            </div>
                            <div className="relative flex items-center justify-center w-full h-full">
                                <img src={images} alt={index} className="w-full h-full rounded-md" />
                            </div>
                        </div>
                    ))}
                </div>
            }

            {progress > 0 && progress < 100 && <div className="">
                Uploading images {progress} %
            </div>}
        </>
    )
}
