import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./awn.css";
import "./App.css";
import Layout from "./layouts/layout";
import Login from "./pages/login";
import ScrollToTop from "./components/scrolltotop";
import SignUp from "./pages/SignUp";
import VideoEditorPage from "./pages/VideoEditorPage";
import ChoosePlan from "./pages/ChoosePlan";
import LandingPage from "./LandingPage";
import CollectorLayout from "./layouts/CollectorLayout";
import Test from "./Test";
import Whyvideoreviews from "./whyvideoreviews";
import Studio from "./pages/studio";
import Forgotpassword from "./forgotpassword";
import MasterLogin from "./pages/masterlogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Refundpolicy from "./pages/refundpolicy";
import Cancellationpolicy from "./cancellationpolicy";
import Terms from "./terms";
import Privacy from "./privacy";
import Becomepartner from "./becomepartner";
import Servicereview from "./servicereview";
import Feedback from "./feedback";
import Feed from "./pages/feed";
import Faqplayer from "./pages/faqplayer";
import Saas from "./Saas";
import GetAVideo from "./getAvideo";
import Showcase from "./showcase";
import Userviewsfaq from "./pages/view/userviewsfaq";
import Influencer from "./influencer";
import InfluencerApplyNow from "./influencerApplyNow";
import ChallangeUserView from "./pages/view/ChallangeUserView";
import Badgesembed from "./pages/badgesembed";
import StockVideos from "./pages/stockvideos";
import StockVideosID from "./pages/stockvideosid";
import StockCart from "./pages/stockcart";
import StockNew from "./pages/StockNew";
import UgcorderNew from "./pages/ugcorderNew";
import QuizUserPage from "./quiz/QuizUserPage";
import CampaignUserView from "./pages/view/campaignuserview";
import MainFourZeroFour from "./pages/main404";
import SocialLayout from "./pages/social/layout";
import IndexHome from "./indexHome";
import InhouseVideos from "./inhouseVideos";
import InfluencerVideos from "./influencerVideos";
import { useStateValue } from "./context/StateProvider";
import AWN from "awesome-notifications";
import sound from "./assets/sound/notification.mp3";
import { getNotificationFromDB } from "./common/functions";

export default function App() {
  const [{ user }, dispatch] = useStateValue();
  useEffect(() => {
    function handleContextMenu(e) {
      if (e.target.tagName === "VIDEO") {
        e.preventDefault(); // Prevent the default context menu for video elements
      }
    }
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const audio = document.getElementById("notificationAudio");
    if (!user) return;
    function sseEvents() {
      console.log(user.brandId);
      getNotificationFromDB(user.brandId).then((res) => {
        dispatch({
          type: "SET_NOTIFICATION",
          notifications: res,
        });
      });
      let eventSource = new EventSource(
        "https://api.video.myrevue.app/notification/listen/" + user.brandId
        //"http://localhost:5004/notification/listen/" + user.brandId
      );

      audio.src = sound;

      eventSource.onmessage = (event) => {
        const notificationData = JSON.parse(event.data);
        new AWN().info(notificationData.message, {
          position: "top-right",
          dismissable: false,
        });
        dispatch({
          type: "PUSH_NOTIFICATION",
          notifications: notificationData,
        });
        audio.play();
      };

      eventSource.onerror = () => {
        setTimeout(sseEvents, 5000);
      };
    }
    sseEvents();
  }, [user]);

  return (
    <div
      className="min-h-screen text-white font-Poppins !bg-[#171717]"
      id="main-background"
    >
      <Router>
        <title>MyRevue Brand Admin</title>
        <ToastContainer
          closeButton={false}
          toastStyle={{
            backgroundColor: "#121212",
            color: "white",
            fontSize: 13,
          }}
        />
        <ScrollToTop />
        <Switch>
          <Route path="/admin" component={Layout} />
          <Route path="/social" component={SocialLayout} />
          <Route path="/badges/:brandId/:badge" component={Badgesembed} />
          <Route path="/embed/feed/:id" component={Feed} />
          <Route
            path="/embed/faqplayer/:campaignid/:id"
            component={Faqplayer}
          />
          <Route path="/plans" component={ChoosePlan} />
          {/* <Route path="/userView/:form" component={ChallangeUserView} /> */}
          <Route path="/userView/:form" component={CampaignUserView} />
          <Route path="/submitChallenge/:form" component={ChallangeUserView} />
          <Route path="/submitQuiz/:form" component={QuizUserPage} />
          <Route path="/faquserview/:form" component={Userviewsfaq} />
          <Route path="/engagement" component={CollectorLayout} />
          <Route path="/studio" component={Studio} />
          <Route path="/editVideo/:reviewId" component={VideoEditorPage} />
          <Route path="/login" exact component={Login} />
          <Route path="/applynow" exact component={InfluencerApplyNow} />
          <Route path="/influencer" exact component={Influencer} />
          <Route path="/ugc" exact component={UgcorderNew} />
          <Route path="/stock" exact component={StockNew} />
          <Route path="/stock/videos" exact component={StockVideos} />
          <Route path="/stock/videos/:id" exact component={StockVideosID} />
          <Route path="/stock/cart" exact component={StockCart} />
          <Route path="/masterlogin" exact component={MasterLogin} />
          <Route path="/test" exact component={Test} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/whyvideoreviews" exact component={Whyvideoreviews} />
          <Route path="/refundpolicy" exact component={Refundpolicy} />
          <Route path="/privacy-policy" exact component={Privacy} />
          <Route path="/become_partner" exact component={Becomepartner} />
          <Route path="/serviceReview" exact component={Servicereview} />
          <Route path="/feedback" exact component={Feedback} />
          <Route path="/terms-and-conditions" exact component={Terms} />
          <Route
            path="/cancellationpolicy"
            exact
            component={Cancellationpolicy}
          />
          <Route path="/forgotpassword" exact component={Forgotpassword} />
          <Route path="/saas" exact component={Saas} />
          <Route path="/getavideo" exact component={GetAVideo} />
          <Route path="/showcase" exact component={Showcase} />
          <Route path="/inhousevideos" exact component={InhouseVideos} />
          <Route path="/influencervideos" exact component={InfluencerVideos} />
          <Route path="/home" exact component={LandingPage} />
          <Route path="/" exact component={IndexHome} />
          <Route path={`/*`} component={MainFourZeroFour} />
        </Switch>
      </Router>
    </div>
  );
}
