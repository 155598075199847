import AWN from "awesome-notifications";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toBlob, } from "html-to-image";
import React from "react";
import { useState } from "react";
import { showWarning } from "./toast/toast";
import { X } from "@phosphor-icons/react";

function UserDetailsVideo({ addLines, reviewId }) {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [loading, setloading] = useState(false);

  const handleUpload = async (e) => {
    //addLines(dataUrl);
    if (first.length < 2) return showWarning("Please add more words");
    if (second.length < 2) return showWarning("Please add more words");
    setloading(true);
    const node = document.getElementById("imageText");
    toBlob(node)
      .then(async (blob) => {
        // upload file to storage
        var storage = getStorage();
        const storageRef = ref(storage, `reviewTitleImage/${reviewId}.jpeg`);
        const uploadTask = await uploadBytesResumable(storageRef, blob)
          .then((res) => res.ref)
          .catch((e) => {
            return showWarning(e);
          });
        const downloadURL = await getDownloadURL(uploadTask);
        setloading(false);
        addLines(downloadURL);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
        showWarning(error);

        setloading(false);
      });
  };
  if (loading)
    return (
      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black hideOut-normal bg-opacity-70">
        <i className="fas fa fa-spinner animate-spin"></i>
      </div>
    );
  else
    return (
      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black/70 hideOut-normal">

        <div className="p-5 rounded-md bg-bg_highlight min-w-[750px]">
          <div className="flex flex-col items-center justify-center flex-1">
            <p className="m-2 text-xs text-gray-300">
              Add two small lines each containing not more less than or first
              line - 20 second line - 30 letter.
            </p>
            <p className="flex items-center mx-2 mb-3 text-xs text-gray-300">
              <span className="px-1 text-lg text-red-500">*</span>
              <span className="inline-block">These text only appear on first 8s of review.</span>
            </p>
            <div className="flex gap-3">
              <div className="flex flex-col">
                <input
                  type="text"
                  onChange={(e) => setFirst(e.target.value)}
                  placeholder="John Doe"
                  maxLength="20"
                  className="h-12 p-1 m-2 bg-background border border-[#8080801e] rounded-md px-3 appearance-none w-80"
                />
                <input
                  type="text"
                  onChange={(e) => setSecond(e.target.value)}
                  placeholder="Public Administrator"
                  maxLength="30"
                  className="h-12 p-1 m-2 bg-background border border-[#8080801e] rounded-md px-3 appearance-none w-80"
                />
              </div>
              <div className="flex flex-col items-center justify-center px-2 rounded-md bg-background">
                <p className="tracking-wide">Preview</p>
                <div
                  id="imageText"
                  style={{
                    width: "250px",
                    height: "60px",
                    borderLeft: "6px solid #e3077d",
                    paddingLeft: "10px",
                    color: "white",
                    textShadow: "1px 1px 1px #000000",
                  }}
                >
                  <span style={{ fontSize: "20px" }}>{first}</span> <br />
                  <span style={{ fontSize: "12px" }}>{second}</span> <br />
                </div>
              </div>
            </div>
            <button
              className="bg-[#8ea9fa] h-10 px-8 mt-4 cursor-pointer select-none text-white rounded m-1"
              onClick={handleUpload}
            >
              Add This
            </button>
          </div>

        </div>
      </div>
    );
}

export default UserDetailsVideo;
