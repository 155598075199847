import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loadingdot from "../../components/loadingdot";
import Sharecard from "../../components/sharecard";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import { ArrowLeft } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";

export default function ViewFlick() {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [review, setreview] = useState({});
  const [share, setshare] = useState(false);
  const router = useHistory();

  console.log(review);

  useEffect(() => {
    fetchreview();
    sendAmplitudeData("viewing review, view review page", {
      reviewid: id,
    });
  }, []);

  async function fetchreview() {
    setloading(true);
    let headersList = {
      Accept: "*/*",
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
    };

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getFlick/" + id,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    let data = response.data;
    setreview(data);
    setloading(false);
  }

  return (
    <div className="flex flex-col h-full">
      <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-2xl tracking-wide font-medium">
        <div
          className="flex items-center justify-center w-12 h-12 cursor-pointer"
          onClick={() => router.goBack()}
        >
          <ArrowLeft size={36} color="#ffffff" />
        </div>
        <p className="pl-5 first-letter:uppercase ">{review?.name}</p>
      </div>
      {loading ? (
        <Loadingdot />
      ) : (
        <div className="p-5">
          {share === true ? (
            <Sharecard
              setshare={setshare}
              url={
                "https://myrevue.app/viewReview/" +
                review?.type +
                "/" +
                review?.id
              }
              text={`Hey! Check out this review on MyRevue app. Get the best review before buying anything on MyRevue! download the app `}
            />
          ) : null}
          <div className="flex items-start w-full p-4 rounded-lg bg-bg_highlight gap-x-5">
            <div className="w-80 aspect-[9/16] bg-background rounded-lg">
              <video playsInline
                src={review?.reviewUrl}
                onClick={(e) =>
                  e.currentTarget.paused ? e.target.play() : e.target.pause()
                }
                className="cursor-pointer rounded-lg w-80 aspect-[9/16] object-cover object-right-top"
              ></video>
            </div>
            <div className="flex-1 w-full bg-bg_highlight">
              <div className="">
                <div className="mb-3 text-white">
                  <p className="font-work">ID</p>
                  <input
                    type="text"
                    defaultValue={review?.id}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Name</p>
                  <input
                    type="text"
                    defaultValue={review?.name}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Product Id</p>
                  <input
                    type="text"
                    defaultValue={review?.product?.id}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Product Name</p>
                  <input
                    type="text"
                    defaultValue={review?.product?.name}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Flick Tag</p>
                  <input
                    type="text"
                    defaultValue={review?.flickTag}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Sub Flick Tag</p>
                  <input
                    type="text"
                    defaultValue={review?.subFlickTag}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
                <div className="mb-3 text-white">
                  <p className="font-work">Uploaded By</p>
                  <input
                    type="text"
                    defaultValue={review?.user?.name}
                    readOnly
                    className="w-full h-10 px-3 mt-1 text-sm tracking-wide truncate rounded-md outline-none appearance-none bg-background font-work"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
