import { Eye } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../../context/StateProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { showWarning } from "../../components/toast/toast";
import moment from "moment";

export default function Posts() {
  const router = useHistory();
  const [{ user }, dispatch] = useStateValue();
  const [posts, setPosts] = useState([]);
  const [loding, setloading] = useState(true);

  useEffect(() => {
    getDocs(
      query(collection(db, "socialPosts"), where("brand", "==", user.brandId))
    )
      .then((docs) => {
        const data = docs.docs.map((d) => ({ ...d.data(), id: d.id }));
        console.log(data);
        setPosts(data);
      })
      .catch((e) => {
        showWarning(e.message);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);

  return (
    <div className="relative w-full h-screen overflow-y-auto">
      <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
        <p className="text-lg font-light tracking-wide">Social Posts</p>
        <button
          className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
          onClick={() => router.push("/social/posts/create")}
        >
          Create Post
        </button>
      </div>
      <div className="flex flex-wrap w-full gap-5 p-10">
        {posts.map((post) => (
          <div key={post.id} style={{ width: "250px" }} className="w-full p-3 bg-bg_highlight border border-[#8080801a] rounded-md relative group cursor-pointer" onClick={() => router.push("/social/posts/view-post/" + post.id)}  >
            <div className="relative w-full aspect-square">
              <div className="absolute flex items-center justify-center w-full h-full transition-all bg-black bg-opacity-50 rounded-sm opacity-0 group-hover:opacity-100">
                <div className="px-3 py-[5px] rounded-full bg-[#272626] flex gap-2">
                  <Eye size={22} color="white" />
                  <p className="" >
                    View post
                  </p>
                </div>
              </div>
              {/* <img
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fsamples%2Fsample%201.png?alt=media&token=6e835f0b-e8ee-4d95-91c9-986c9784144b"
                alt=""
                className="object-cover w-full h-full rounded-sm"
              /> */}
              <div className="w-full h-full bg-red-500 rounded-md">
                <video playsInline src={post?.reviewUrl} className="object-cover w-full h-full rounded-md" />
              </div>
            </div>
            <div className="mt-3">
              <p className="text-xs">
                {new Date(post.createdAt).toLocaleDateString()}
              </p>
              <p className="text-sm font-light tracking-wide text-white">
                {post.caption}
              </p>
              <div className="flex items-center justify-between mt-4">
                <p className="text-sm font-normal tracking-wide">Channels</p>
                <div className="flex-1 pr-2">
                  <div class="flex items-center justify-end">
                    {user.social?.facebook_pages
                      ?.filter((f) => post.platforms.includes(f.name))
                      .map((page) => (
                        <img
                          key={page.id}
                          className="w-8 h-8 rounded-full border border-solid border-[#000000] transform translate-x-[15px]"
                          src={page?.picture?.data?.url}
                          alt=""
                        />
                      ))}

                    {post.platforms.includes("instagram") && (
                      <img
                        className="w-8 h-8 rounded-full border border-solid border-[#000000] transform translate-x-[10px]"
                        src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe"
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
