import axios from "axios";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { ThumbsUp } from "@phosphor-icons/react";

function PostListTile({ card, pageToken }) {
  const [insight, setInsight] = useState([]);
  const [showInsight, setShowInsight] = useState(false);

  const postData = useMemo(() => {
    return (
      <div className="flex w-full gap-5 px-5 py-5 h-26">
        {insight.map((card) => {
          if (card.name === "post_reactions_by_type_total")
            return (
              <div
                key={card.name}
                className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]"
              >
                <p className="text-xs">{card.title}</p>
                <div className="flex items-center justify-center gap-2 mt-2">
                  {Object.entries(card.values[0]?.value).map(([key, val]) => (
                    <div className="flex items-center gap-1 text-center">
                      {key === "like" &&
                        <>
                          <ThumbsUp size={16} color="white" />
                        </>
                      }
                      <p className="text-xs font-bold">{val}</p>
                    </div>
                  ))}
                </div>
              </div>
            );
          else
            return (
              <div
                key={card.name}
                className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]"
              >
                <p className="text-xs">{card.title}</p>
                <p className="text-xl font-bold">{card.values[0]?.value}</p>
              </div>
            );
        })}
      </div>
    );
  }, [insight]);

  const fetchInsight = () => {
    setShowInsight(!showInsight);
    if (insight.length === 0)
      axios
        .get(
          `https://graph.facebook.com/v19.0/${card.id}/insights?metric=post_engaged_users,post_reactions_by_type_total,post_impressions,post_impressions_unique&access_token=${pageToken}`
        )
        .then((response) => {
          console.log(response.data);
          setInsight(response.data.data);
        });
  };

  return (
    <div className="py-2 pr-5 duration-200 transform rounded bg-background hover:bg-opacity-50 hover:cursor-pointer">
      <div className="flex items-center gap-4">
        <div className="flex items-center justify-center w-32">
          <img
            className="h-32"
            src={card.attachments?.data[0]?.media?.image?.src}
            alt=""
          />
        </div>
        <div className="flex-1">
          <p className="flex-1 text-sm font-light tracking-wide">
            {card.message}
          </p>
          <p className="text-xs text-[#afa5a5c0]">
            {moment(card.created_time).endOf("day").fromNow()}
          </p>
        </div>
        <div className="">
          <button
            onClick={fetchInsight}
            className="px-5 py-2 text-xs duration-150 transform bg-purple-600 rounded hover:bg-purple-800"
          >
            {showInsight ? "Hide" : "Insight"}
          </button>
        </div>
        <div className="flex flex-col items-center justify-center ">
          <p className="">{card?.likes?.summary?.total_count ?? 0}</p>
          <p className="text-xs">Likes</p>
        </div>
        <div className="flex flex-col items-center justify-center ">
          <p className="">{card?.comments?.summary?.total_count ?? 0}</p>
          <p className="text-xs">comments</p>
        </div>
      </div>
      {showInsight && (
        <div className="flex items-center justify-center">
          {insight.length === 0 && (
            <div className="py-5 h-26">
              <i className="fas fa-spinner animate-spin"></i>
            </div>
          )}
          {insight.length !== 0 && postData}
        </div>
      )}
    </div>
  );
}

export default PostListTile;
