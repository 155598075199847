import React, { useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Article, Note, UserPlus, VideoCamera, } from "@phosphor-icons/react";
import Brief from "./viewGetAVideo/brief";
import Notes from "./viewGetAVideo/notes";
import Applications from "./viewGetAVideo/applications";
import Delivery from "./viewGetAVideo/delivery";
import Content from "./viewGetAVideo/content";

export default function ViewRequestCampaign() {
  const { id } = useParams();
  const [campaign] = useState(JSON.parse(sessionStorage.getItem("inhouserequest")));
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get("tab")
  const router = useHistory();
  const [notes] = useState(JSON.parse(sessionStorage.getItem("inhouserequest"))?.notes ?? []);

  async function settab(index) {
    const arr = ["", "brief", "notes", "applications", "delivery", "content"];
    router.push(`/engagement/requestcampaign/6459e81ce80b8196f4e2a5a1?tab=${arr[index]}`)
  }

  return (
    <div className="w-full min-h-[calc(100vh-64px)] bg-background overflow-y-auto">
      <div className=" flex gap-5 m-5 pb-4 border-b border-[#757575]">
        <div className="cursor-pointer" onClick={() => settab(1)}>
          <div
            className={`text-md text-center mt-2 tracking-wide text-[${tab === null || tab === "brief" ? "#93A8F4" : "rgba(255,255,255,0.6)"
              }] font-medium font-work pb-1 flex gap-x-2 items-center`}
          >
            <Article size={26} color="currentcolor" />
            Brief
          </div>
          <div
            className={`h-[2px] w-full rounded-xl ${tab === null || tab === "brief" ? "bg-[#93A8F4]" : "bg-transparent"
              }`}
          ></div>
        </div>

        <div className="cursor-pointer" onClick={() => settab(2)}>
          <div
            className={`text-md text-center mt-2 tracking-wide text-[${tab === "notes" ? "#93A8F4" : "rgba(255,255,255,0.6)"
              }] font-medium font-work pb-1 flex gap-x-2 items-center`}
          >
            <Note size={26} color="currentcolor" />
            Notes
          </div>
          <div
            className={`h-[2px] w-full rounded-xl ${tab === "notes" ? "bg-[#93A8F4]" : "bg-transparent"
              }`}
          ></div>
        </div>
        <div className="cursor-pointer" onClick={() => settab(3)}>
          <div
            className={`text-md text-center mt-2 tracking-wide text-[${tab === "applications" ? "#93A8F4" : "rgba(255,255,255,0.6)"
              }] font-medium font-work pb-1 flex gap-x-2 items-center`}
          >
            <UserPlus size={26} color="currentcolor" />
            Applications
          </div>
          <div
            className={`h-[2px] w-full rounded-xl ${tab === "applications" ? "bg-[#93A8F4]" : "bg-transparent"
              }`}
          ></div>
        </div>
        <div className="cursor-pointer" onClick={() => settab(4)}>
          <div
            className={`text-md text-center mt-2 tracking-wide text-[${tab === "delivery" ? "#93A8F4" : "rgba(255,255,255,0.6)"
              }] font-medium font-work pb-1 flex gap-x-2 items-center`}
          >
            <VideoCamera size={26} color="currentcolor" />
            Product Delivery
          </div>
          <div
            className={`h-[2px] w-full rounded-xl ${tab === "delivery" ? "bg-[#93A8F4]" : "bg-transparent"
              }`}
          ></div>
        </div>
        <div className="cursor-pointer" onClick={() => settab(5)}>
          <div
            className={`text-md text-center mt-2 tracking-wide text-[${tab === "content" ? "#93A8F4" : "rgba(255,255,255,0.6)"
              }] font-medium font-work pb-1 flex gap-x-2 items-center`}
          >
            <VideoCamera size={26} color="currentcolor" />
            Content
          </div>
          <div
            className={`h-[2px] w-full rounded-xl ${tab === "content" ? "bg-[#93A8F4]" : "bg-transparent"
              }`}
          ></div>
        </div>
      </div>
      {(tab === null || tab === "brief") && (
        <Brief campaign={campaign} />
      )}

      {tab === "notes" && (
        <Notes notes={notes} />
      )}
      {tab === "applications" && (
        <Applications campaign={campaign} />
      )}
      {tab === "delivery" && (
        <Delivery campaign={campaign} id={id} />
      )}
      {tab === "content" && (
        <Content campaign={campaign} />
      )}
    </div>
  );
}
