import React from "react";

function QuizIcon({ color, type, top, left }) {
  if (type === "star")
    return (
      <div
        className="absolute"
        style={{
          top: top + "px",
          left: left + "px",
          zIndex: 0,
          animation: "beat 1200ms 500ms infinite",
        }}
      >
        <svg
          width="70"
          height="70"
          viewBox="0 0 166 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M68.0601 10.0834C73.0724 -2.5315 90.9276 -2.5315 95.9399 10.0834L107.006 37.9342C109.147 43.3219 114.2 46.9936 119.986 47.3647L149.893 49.2828C163.44 50.1516 168.957 67.1329 158.509 75.7981L135.441 94.9291C130.978 98.6299 129.048 104.571 130.483 110.188L137.9 139.224C141.26 152.376 126.815 162.871 115.345 155.612L90.022 139.584C85.1234 136.484 78.8767 136.484 73.978 139.584L48.655 155.612C37.185 162.871 22.7399 152.376 26.0997 139.224L33.5173 110.188C34.9522 104.571 33.0219 98.6299 28.5594 94.9291L5.49134 75.7981C-4.95722 67.1329 0.560307 50.1516 14.1067 49.2828L44.0141 47.3647C49.7995 46.9936 54.8532 43.3219 56.9939 37.9343L68.0601 10.0834Z"
              fill={color}
            />
            <path
              d="M70.3834 11.0065C74.5604 0.494122 89.4396 0.494116 93.6166 11.0065L104.683 38.8574C107.18 45.143 113.076 49.4266 119.826 49.8595L149.733 51.7777C161.022 52.5017 165.62 66.6527 156.913 73.8738L133.845 93.0047C128.638 97.3223 126.386 104.253 128.06 110.807L135.478 139.843C138.278 150.803 126.24 159.549 116.682 153.499L91.359 137.472C85.6439 133.855 78.3561 133.855 72.641 137.472L47.318 153.499C37.7597 159.549 25.7221 150.803 28.5219 139.843L35.9395 110.807C37.6136 104.253 35.3615 97.3223 30.1554 93.0047L7.08724 73.8738C-1.61989 66.6527 2.97805 52.5017 14.2667 51.7777L44.1741 49.8595C50.9238 49.4266 56.8197 45.143 59.3172 38.8574L70.3834 11.0065Z"
              stroke="#0D0D0D"
              strokeWidth="5"
            />
          </g>
        </svg>
      </div>
    );
  else if (type === "triangle")
    return (
      <div
        className="absolute"
        style={{
          top: top + "px",
          left: left + "px",
          zIndex: 0,
          animation: "beat 1500ms 500ms infinite",
        }}
      >
        <svg
          width="70"
          height="70"
          viewBox="0 0 174 158"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M72.9923 7.65057C78.7581 -2.38945 93.2419 -2.38944 99.0077 7.65059L133.872 68.361L169.017 128.91C174.829 138.923 167.587 151.466 156.009 151.44L86 151.278L15.991 151.44C4.41319 151.466 -2.82872 138.923 2.98332 128.91L38.1278 68.361L72.9923 7.65057Z"
              fill={color}
            />
            <path
              d="M131.704 69.606L131.71 69.616L166.855 130.165C171.698 138.509 165.663 148.962 156.015 148.94L86.0058 148.778L85.9942 148.778L15.9852 148.94C6.33705 148.962 0.30212 138.509 5.14549 130.165L40.29 69.616L40.2958 69.606L75.1603 8.89557C79.9651 0.528883 92.0349 0.528889 96.8397 8.89558L131.704 69.606Z"
              stroke="#0D0D0D"
              strokeWidth="5"
            />
          </g>
        </svg>
      </div>
    );
  else if (type === "square")
    return (
      <div
        className="absolute"
        style={{
          top: top + "px",
          left: left + "px",
          zIndex: 0,
          animation: "beat 1800ms 500ms infinite",
        }}
      >
        <svg
          width="70"
          height="70"
          viewBox="0 0 176 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M69.8642 11.7762C76.7566 -2.61373 97.2434 -2.61372 104.136 11.7762L120.056 45.0153C121.928 48.9222 125.078 52.0723 128.985 53.9436L162.224 69.8642C176.614 76.7566 176.614 97.2434 162.224 104.136L128.985 120.056C125.078 121.928 121.928 125.078 120.056 128.985L104.136 162.224C97.2434 176.614 76.7566 176.614 69.8642 162.224L53.9436 128.985C52.0723 125.078 48.9222 121.928 45.0153 120.056L11.7762 104.136C-2.61373 97.2434 -2.61372 76.7566 11.7762 69.8642L45.0153 53.9436C48.9222 52.0723 52.0723 48.9222 53.9436 45.0153L69.8642 11.7762Z"
              fill={color}
            />
            <path
              d="M72.1189 12.8561C78.1044 0.359621 95.8956 0.359629 101.881 12.8561L117.802 46.0953C119.919 50.5162 123.484 54.0808 127.905 56.1983L161.144 72.1189C173.64 78.1044 173.64 95.8956 161.144 101.881L127.905 117.802C123.484 119.919 119.919 123.484 117.802 127.905L101.881 161.144C95.8956 173.64 78.1044 173.64 72.1189 161.144L56.1983 127.905C54.0808 123.484 50.5162 119.919 46.0953 117.802L12.8561 101.881C0.359621 95.8956 0.359631 78.1044 12.8561 72.1189L46.0953 56.1983C50.5162 54.0808 54.0808 50.5162 56.1983 46.0953L72.1189 12.8561Z"
              stroke="#0D0D0D"
              strokeWidth="5"
            />
          </g>
        </svg>
      </div>
    );
  else if (type === "starfull")
    return (
      <div
        className="absolute"
        style={{
          top: top + "px",
          left: left + "px",
          zIndex: 0,
          animation: "beat 1400ms 500ms infinite",
        }}
      >
        <svg
          width="70"
          height="70"
          viewBox="0 0 186 181"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M85.3624 4.81957C87.4955 -1.5498 96.5045 -1.54981 98.6376 4.81956L115.861 56.2458C116.809 59.0775 119.449 60.9957 122.435 61.0226L176.667 61.5109C183.383 61.5714 186.167 70.1395 180.769 74.1365L137.182 106.408C134.782 108.185 133.773 111.289 134.67 114.137L150.964 165.865C152.983 172.272 145.694 177.567 140.225 173.668L96.0633 142.187C93.6318 140.453 90.3682 140.453 87.9367 142.187L43.7755 173.668C38.3059 177.567 31.0174 172.272 33.0355 165.865L49.3295 114.137C50.2267 111.289 49.2182 108.185 46.8182 106.408L3.23111 74.1365C-2.16735 70.1395 0.616589 61.5714 7.33339 61.5109L61.5649 61.0226C64.5509 60.9957 67.1912 59.0775 68.1395 56.2458L85.3624 4.81957Z"
              fill={color}
            />
            <path
              d="M87.7329 5.61349C89.1042 1.51888 94.8958 1.5189 96.2671 5.61348L113.49 57.0398C114.777 60.8827 118.36 63.486 122.413 63.5225L176.644 64.0108C180.962 64.0497 182.752 69.5578 179.281 72.1272L135.694 104.399C132.437 106.81 131.068 111.023 132.286 114.888L148.58 166.616C149.877 170.735 145.192 174.139 141.676 171.632L97.5145 140.151C94.2145 137.798 89.7855 137.798 86.4855 140.151L42.3243 171.632C38.8081 174.139 34.1227 170.735 35.42 166.616L51.714 114.888C52.9316 111.023 51.563 106.81 48.3059 104.399L4.71872 72.1273C1.24827 69.5578 3.03797 64.0497 7.3559 64.0108L61.5874 63.5225C65.6399 63.486 69.2231 60.8827 70.5101 57.0398L87.7329 5.61349Z"
              stroke="#0D0D0D"
              strokeWidth="5"
            />
          </g>
        </svg>
      </div>
    );
  else if (type === "hexagon")
    return (
      <div
        className="absolute"
        style={{
          top: top + "px",
          left: left + "px",
          zIndex: 0,
          animation: "beat 1400ms 500ms infinite",
        }}
      >
        <svg
          width="70"
          height="70"
          viewBox="0 0 131 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M57 2.33013C61.641 -0.349365 67.359 -0.349364 72 2.33013L120.653 30.4199C125.294 33.0994 128.153 38.0513 128.153 43.4103V99.5897C128.153 104.949 125.294 109.901 120.653 112.58L72 140.67C67.359 143.349 61.641 143.349 57 140.67L8.34713 112.58C3.70612 109.901 0.847134 104.949 0.847134 99.5897V43.4103C0.847134 38.0513 3.70612 33.0994 8.34713 30.4199L57 2.33013Z"
              fill={color}
            />
            <path
              d="M58.25 4.49519C62.1175 2.26228 66.8825 2.26228 70.75 4.49519L119.403 32.5849C123.27 34.8178 125.653 38.9444 125.653 43.4103V99.5897C125.653 104.056 123.27 108.182 119.403 110.415L70.75 138.505C66.8825 140.738 62.1175 140.738 58.25 138.505L9.59713 110.415C5.72962 108.182 3.34713 104.056 3.34713 99.5897V43.4103C3.34713 38.9444 5.72962 34.8178 9.59713 32.5849L58.25 4.49519Z"
              stroke="#0D0D0D"
              strokeWidth="5"
            />
          </g>
        </svg>
      </div>
    );
  else
    return (
      <div
        className="absolute"
        style={{
          top: top + "px",
          left: left + "px",
          zIndex: 0,
          animation: "beat 1200ms 500ms infinite",
        }}
      >
        <svg
          width="70"
          height="70"
          viewBox="0 0 140 148"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M58.476 8.5044C60.9308 -2.80955 77.0692 -2.80954 79.524 8.50441C81.2732 16.5664 91.0661 19.7483 97.2199 14.2542C105.856 6.54387 118.912 16.0298 114.248 26.6258C110.925 34.1763 116.977 42.5066 125.185 41.6789C136.704 40.5173 141.691 55.8659 131.689 61.6967C124.562 65.8516 124.562 76.1484 131.689 80.3033C141.691 86.1341 136.704 101.483 125.185 100.321C116.977 99.4934 110.925 107.824 114.248 115.374C118.912 125.97 105.856 135.456 97.2199 127.746C91.0661 122.252 81.2732 125.434 79.524 133.496C77.0692 144.81 60.9308 144.81 58.476 133.496C56.7268 125.434 46.9339 122.252 40.7801 127.746C32.144 135.456 19.0877 125.97 23.7519 115.374C27.0755 107.824 21.0231 99.4934 12.8152 100.321C1.29647 101.483 -3.69057 86.1341 6.31106 80.3033C13.4379 76.1484 13.4379 65.8516 6.31106 61.6967C-3.69057 55.8659 1.29647 40.5173 12.8152 41.6789C21.0231 42.5066 27.0755 34.1762 23.7519 26.6258C19.0877 16.0298 32.144 6.54387 40.7801 14.2542C46.9339 19.7483 56.7268 16.5663 58.476 8.5044Z"
              fill={color}
            />
            <path
              d="M60.9192 9.03449C62.8041 0.347092 75.1959 0.347088 77.0808 9.0345C79.2361 18.968 91.3024 22.8886 98.8849 16.1191C105.516 10.1987 115.541 17.4825 111.96 25.6186C107.865 34.9219 115.322 45.1861 125.436 44.1663C134.28 43.2744 138.11 55.0597 130.43 59.5369C121.648 64.6564 121.648 77.3436 130.43 82.4631C138.11 86.9403 134.28 98.7256 125.436 97.8337C115.322 96.8139 107.865 107.078 111.96 116.381C115.541 124.518 105.516 131.801 98.8849 125.881C91.3024 119.111 79.2361 123.032 77.0808 132.965C75.1959 141.653 62.8041 141.653 60.9192 132.965C58.7639 123.032 46.6976 119.111 39.1151 125.881C32.4839 131.801 22.4586 124.518 26.0401 116.381C30.1352 107.078 22.6778 96.8139 12.5644 97.8337C3.71973 98.7256 -0.109558 86.9403 7.57018 82.4631C16.3515 77.3436 16.3515 64.6564 7.57019 59.5369C-0.109558 55.0597 3.71973 43.2744 12.5644 44.1663C22.6778 45.1861 30.1352 34.9219 26.0401 25.6186C22.4586 17.4825 32.4839 10.1987 39.1151 16.1191C46.6976 22.8886 58.7639 18.968 60.9192 9.03449Z"
              stroke="#0D0D0D"
              strokeWidth="5"
            />
          </g>
        </svg>
      </div>
    );
}

export default QuizIcon;
