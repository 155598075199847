async function cachedAPICalls(
  url,
  { method = "GET", body = {}, cached = true, headers }
) {
  if (!url) return;
  const response = await fetch(url, {
    method: method,
    cache: cached ? "default" : "no-cache",
    headers: headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-
    body: method === "GET" ? null : JSON.stringify(body),
  });
  if (response.status === 200) return await response.json();
  return response.status;
}

export { cachedAPICalls };
