import React from 'react'

export default function MainFourZeroFour() {
    return (
        <div className="h-screen overflow-y-auto bg-[#101010]">
            <div className="flex items-center justify-center w-full h-full">
                <div className='flex flex-col items-center'>
                    <div className="text-[150px] font-bold tracking-widest">404</div>
                    <div className="text-2xl tracking-widest">Page not found</div>
                </div>
            </div>
        </div>
    )
}
