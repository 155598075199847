import { useHistory } from "react-router-dom";
import { Suspense, lazy, useState } from "react";
import { CircleNotch, CircleWavyQuestion, ClockCounterClockwise, Repeat } from "@phosphor-icons/react";
import Loadingdot from "./components/loadingdot";
import { firstRowLogo, secondRowLogo, thirdRowLogo } from "./logo";
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));
const LogoSlider = lazy(() => import("./components/logoSlider"));
export default function Showcase() {
  const router = useHistory();
  const [tab, settab] = useState(0);

  return (
    <div className="h-screen overflow-y-auto bg-[#101010]">
      <Suspense fallback={<Loadingdot />}>
        <NotLoginHeaderNew />

        <div className="grid px-5 mx-auto md:my-10 lg:grid-cols-2 md:px-10 xl:px-24 2xl:32">
          <div className="">
            <h1 className="text-3xl md:text-[40px] font-semibold leading-tight ">Ignite engagement and create meaningful Reviews with our showcase.</h1>
            <p className="md:text-lg md:max-w-[70%] tracking-wide font-medium text-[#8E8B8B]">
              Experience the transformative impact of our showcase as it brings your message to life, resonating with your audience on a personal level.
            </p>
            <div className="flex items-center w-full gap-5 mt-5">
              <button
                className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}>
                Sign Up
              </button>
              <div className="relative pl-5 md:pl-0">
                <button className="z-10 py-2 font-medium tracking-wide md:px-12" onClick={() => router.push("/signup")}>
                  Try it for free
                </button>
                <img loading="lazy" src={require("./assets/icons/icons/hero section arrow.svg").default} alt="" className="absolute -ml-12 top-2" />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center flex-1 w-full mt-24 md:mt-0">
            <img loading="lazy" src={require("./assets/images/showcase/hero.png")} alt="" className="" />
          </div>
        </div>
        <div className="px-5 mx-auto my-10 md:px-10 md:mt-20">
          <h2 className="text-[24px] lg:text-[32px] text-center font-semibold leading-tight mt-16">
            Trusted by 80+ brands
          </h2>
          <div className="space-y-0 md:space-y-5 ">
            <LogoSlider logos={firstRowLogo} />
            <LogoSlider logos={secondRowLogo} direction="right" />
            <LogoSlider logos={thirdRowLogo} />
          </div>
        </div>
        <div className="p-5 mt-7 lg:mt-24">
          <p className="text-3xl font-semibold leading-tight text-center lg:text-5xl">What you can do with<br />
            <span className="text-[#F92147]">Interactive videos</span>
          </p>
          <div className="w-full max-w-[1039px] mx-auto mt-10">
            <div className="bg-[#2B2F37] h-16 rounded-lg flex lg:items-center lg:justify-between p-2 mb-5 w-full overflow-x-auto gap-5" >
              <button onClick={() => settab(0)} className={`flex items-center gap-3 h-full min-w-fit px-5 rounded-lg transition-all ${tab === 0 ? "bg-gradient-to-r from-[#F92147] via-[#C734A5] to-[#9747FF]" : ""}`}>
                <CircleWavyQuestion size={22} color="white" />
                FAQ
              </button>
              <button onClick={() => settab(1)} className={`flex items-center gap-3 h-full min-w-fit px-5 rounded-lg transition-all ${tab === 1 ? "bg-gradient-to-r from-[#F92147] via-[#C734A5] to-[#9747FF]" : ""}`}>
                <ClockCounterClockwise size={22} color="white" />
                Story
              </button>
              <button onClick={() => settab(2)} className={`flex items-center gap-3 h-full min-w-fit px-5 rounded-lg transition-all ${tab === 2 ? "bg-gradient-to-r from-[#F92147] via-[#C734A5] to-[#9747FF]" : ""}`}>
                <Repeat size={22} color="white" />
                Mis & Match
              </button>
            </div>
            <>
              {tab === 0 &&
                <div className="grid gap-5 lg:grid-cols-2">
                  <div className="max-h-[439px] w-full">
                    <video playsInline autoPlay loop muted src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Ffaq.mp4?alt=media&token=9a81f45b-80c6-4c15-a5e7-0ad604ccfe1b" className="w-auto h-full rounded-lg"></video>
                  </div>
                  <div className="max-h-[439px] w-full flex flex-col justify-center">
                    <p className="text-3xl text-white">Answer FAQ to increase sales</p>
                    <p className="text-[#8E8B8B] tracking-wide">Empower your customers with essential information and anticipate their needs by proactively addressing their most crucial questions. Guide them through the journey of understanding why your product is precisely what they've been searching for.</p>
                    <button
                      className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                      onClick={() => router.push("/signup")}>
                      Sign Up
                    </button>
                  </div>
                </div>
              }
              {tab === 1 &&
                <div className="grid gap-5 lg:grid-cols-2">
                  <div className="max-h-[439px] w-full">
                    <video playsInline autoPlay loop muted src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Fstory.mp4?alt=media&token=6531d7c1-c567-4501-8f89-024d20ddb06b" className="w-auto h-full rounded-lg"></video>
                  </div>
                  <div className="max-h-[439px] w-full flex flex-col justify-center">
                    <p className="text-3xl text-white">Engage with Interactive Video Stories!</p>
                    <p className="text-[#8E8B8B] tracking-wide">Immerse your audience in captivating interactive video stories that ignite their imagination and stir their emotions. With each click, they become active participants, shaping the narrative and experiencing a truly engaging and memorable journey.</p>
                    <button
                      className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                      onClick={() => router.push("/signup")}>
                      Sign Up
                    </button>
                  </div>
                </div>
              }
              {tab === 2 &&
                <div className="grid gap-5 lg:grid-cols-2">
                  <div className="max-h-[439px] w-full">
                    <video playsInline autoPlay loop muted src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Fmis%20and%20match.mp4?alt=media&token=1bed3c15-45c9-4301-96d1-af11d839c0b8" className="w-auto h-full rounded-lg"></video>
                  </div>
                  <div className="max-h-[439px] w-full flex flex-col justify-center">
                    <p className="text-3xl text-white">Engage with Interactive Video Stories!</p>
                    <p className="text-[#8E8B8B] tracking-wide">Immerse your audience in captivating interactive video stories that ignite their imagination and stir their emotions. With each click, they become active participants, shaping the narrative and experiencing a truly engaging and memorable journey.</p>
                    <button
                      className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                      onClick={() => router.push("/signup")}>
                      Sign Up
                    </button>
                  </div>
                </div>
              }

            </>
          </div>
        </div>
        <div className="bg-[#171717] mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 pb-20 mx-auto lg:px-10 lg:py-20 lg:grid-cols-2">
            <div className={` w-full flex-1 flex flex-col justify-center`}>
              <p className="text-3xl font-semibold leading-tight lg:text-4xl">Supercharge Your Sales with <span className="text-[#F92147]">Shoppable Videos</span> </p>
              <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                Revolutionize the way your audience consumes content and discovers products with the influence of TikTok, Instagram, and YouTube.
              </p>
              <button
                className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                onClick={() => router.push("/signup")}>
                Sign Up
              </button>
            </div>
            <div className="relative">
              <div className="lg:w-[85%] aspect-[658/513]">
                <video playsInline src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Fshoppable.mp4?alt=media&token=c3b07667-677a-4112-96b8-54722aa2fecf&_gl=1*2wzdh7*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NjI3OTgwOS4xMDYuMS4xNjg2Mjc5ODQ5LjAuMC4w"} autoPlay muted loop className="object-cover w-full h-full rounded-lg"></video>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 mx-auto lg:px-10 lg:py-20 lg:pb-20 lg:grid-cols-2">
            <div className="relative order-2 lg:order-1">
              <div className="w-full ">
                <img src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Fugc.svg?alt=media&token=ca947cf5-8fd3-4547-ac0a-947bb83f5c68&_gl=1*tm6uko*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NjI3OTgwOS4xMDYuMS4xNjg2MjgxMDgzLjAuMC4w"} alt="ugc" className="w-auto h-full max-h-[450px] mx-auto rounded-lg" />
              </div>
            </div>
            <div className={`w-full flex-1 flex flex-col lg:items-end justify-center order-1 lg:order-2`}>
              <p className="text-3xl font-semibold leading-tight lg:text-right lg:text-4xl">The Power of Creator Content, UGC, and Video Reviews: Elevate Your Brand's Storytelling Game!</p>
              <p className="lg:text-right mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                Let the power of storytelling shine through as your customers become brand advocates and share their experiences through compelling videos.
              </p>
              <button
                className="py-2 px-12 rounded-full w-fit mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]"
                onClick={() => router.push("/signup")}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <div className="bg-[#171717] mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 pb-20 mx-auto lg:px-10 lg:py-20 lg:grid-cols-2">
            <div className={`w-full flex-1 flex flex-col justify-center`}>
              <p className="text-3xl font-semibold leading-tight lg:text-4xl">Unleash Global Engagement: No Content Creation Required!</p>
              <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                Seamlessly Integrate Captivating Content: Import from TikTok, Instagram, YouTube, and Your Brand Library Effortlessly. Transform your website with a dynamic video feed in just minutes, enhancing user experience and boosting engagement.            </p>
              <button
                className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                onClick={() => router.push("/signup")}>
                Sign Up
              </button>
            </div>
            <div className="relative">
              <div className="w-full lg:aspect-[797/456] flex justify-center">
                <video playsInline src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Fugc.mp4?alt=media&token=632e1968-2ae8-4720-aea4-76e17c60ad3f"} autoPlay muted loop className="h-full "></video>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 mx-auto lg:px-10 lg:py-20 lg:pb-20 lg:grid-cols-2">
            <div className="relative order-2 lg:order-1">
              <div className="w-full lg:aspect-[797/456]">
                <video playsInline src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Fseamless.mp4?alt=media&token=8a7a3341-46de-45a5-9b49-73a6a6ce614c"} autoPlay muted loop className="h-full rounded-lg"></video>
              </div>
            </div>
            <div className={`w-full flex-1 flex flex-col lg:items-end justify-center order-1 lg:order-2`}>
              <p className="text-3xl font-semibold leading-tight lg:text-right lg:text-4xl">Seamless Global Connectivity: Reach Audiences Anywhere, Anytime</p>
              <p className="lg:text-right mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                Connect with Audiences Anywhere with Remote Video Capabilities. Overcome geographical limitations and effortlessly deliver your message to diverse markets, fostering effective communication and engagement.
              </p>
              <button
                className="py-2 px-12 rounded-full w-fit mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]"
                onClick={() => router.push("/signup")}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <div className="bg-[#171717] mt-10">
          <div className="grid justify-between w-full gap-10 px-5 py-10 pb-20 mx-auto lg:px-10 lg:py-20 lg:grid-cols-2">
            <div className={`w-full flex-1 flex flex-col justify-center`}>
              <p className="text-3xl font-semibold leading-tight lg:text-4xl">Effortless Production, Genuine Testimonials</p>
              <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
                With fully remote video reviews, capture genuine testimonials from customers without the need for in-person interactions. Seamlessly produce high-quality video content that highlights the real experiences and opinions of your customers. </p>
              <button
                className="py-2 px-12 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-fit"
                onClick={() => router.push("/signup")}>
                Sign Up
              </button>
            </div>
            <div className="relative">
              <div className="w-full lg:aspect-[797/456] flex justify-center lg:justify-end">
                <video playsInline src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fshowcase%2Feffortless.mp4?alt=media&token=98641cee-1bf5-494b-8748-c1a216d4c3bb"} autoPlay muted loop className="h-full rounded-lg"></video>
              </div>
            </div>
          </div>
        </div>
        <NotloginfooterNew />
      </Suspense>
    </div>
  );
}
