import React, { useEffect, useRef, useState } from "react";
import { aiResponse, aiServerUrl, getOpenAIContent } from "./aiapis";
import soothingImage from "../assets/images/soundImage.webp";
import { useStateValue } from "../context/StateProvider";
import AWN from "awesome-notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import PublishVideoContent from "./PublishVideoContent";
import { strings } from "../common/Strings";
import { showWarning } from "../components/toast/toast";

function AIVideoProject() {
  const [{ user }, dispatch] = useStateValue();
  const [videoType, setVideoType] = useState("");
  const [audioType, setAudioType] = useState("soothing1");
  const [project, setProejct] = useState(null);
  const [categories, setCategory] = useState([]);
  const [step, setStep] = useState(1);
  const [selectedCat, setSelectedCat] = useState(null);
  const [responseGenerated, setResponseGenerated] = useState(false);
  let { id } = useParams();
  let responseWaitingRef = useRef();

  const submitSelectedCat = () => {
    var updateData = {
      category: selectedCat,
    };

    updateDoc(doc(db, strings.adminCollection, user.email), updateData);
    dispatch({ type: "SET_USER", user: { ...user, ...updateData } });

    getDoc(doc(db, "aivideosProjects", id))
      .then((res) => {
        if (res.exists()) {
          let data = res.data();
          setProejct(data);
          if (data.published) {
            setStep(6);
          } else if (data.videoUrl) {
            setStep(4);
          } else {
            setStep(2);
          }
        }
      })
      .catch((e) => {
        setStep(2);
      })
      .finally(() => {
        setCategory(categories.find((a) => a.id === selectedCat).images);
      });
  };

  const regenerateVideo = () => {
    setStep(3);
    aiResponse(
      id,
      user.brandName ?? user.name,
      project.query,
      project.videoType,
      project.audioType
    ).then((res) => {
      if (res) {
        var update = {
          regenerate: project.regenerate + 1,
        };

        updateDoc(doc(db, "aivideosProjects", id), update);
        setProejct({
          ...project,
          ...update,
        });
        setStep(4);
      }
    });
  };
  useEffect(() => {
    if (!user.category) {
      getDocs(query(collection(db, "category"))).then((res) => {
        setCategory(res.docs.map((r) => r.data()));
        setStep(7);
      });

      return;
    }
    getDoc(doc(db, "category", user.category)).then((cat) => {
      setCategory(cat.data().images);
      getDoc(doc(db, "aivideosProjects", id))
        .then((res) => {
          if (res.exists()) {
            let data = res.data();
            setProejct(data);
            if (data.published) {
              setStep(6);
            } else if (data.videoUrl) {
              setStep(4);
            } else {
              setStep(2);
            }
          }
        })
        .catch((e) => {
          setStep(2);
        });
    });
  }, []);

  // l - loading
  // 2 - startpage
  // 3 - generating response
  // 4 - response generated
  // 5 - show publish tab
  // 6 - already published tab
  // 7 - select category

  const updateProject = (update) => {
    setProejct({ ...project, ...update });
  };

  const activeAudio =
    "hover:scale-105 border-2 transform duration-150  border-[#FF005C] rounded-md flex h-20 w-40 items-center justify-center";
  const passiveAudio =
    "hover:scale-105 hover:border-2 transform duration-150  border-[#FF005C] rounded-md flex h-20 w-40 items-center justify-center";

  let promptRef = useRef();

  const getAiResponse = async () => {
    let text = promptRef.current.value;
    if (!text) {
      promptRef.current.style.border = "2px solid red";
      promptRef.current.placeholder = "please describe your idea here...";
      return;
    }
    if (!videoType) {
      showWarning("please select video type");
      return;
    }
    if (!audioType) {
      showWarning("please select audio type");
      return;
    }
    setStep(3);

    const brandName = user.brandName ?? user.name;

    setResponseGenerated(true);

    var content = await getOpenAIContent(
      `Complete the following text: ${text}. Brand Name :${brandName}. Generate title, and 5 one liners max 10 words each. Response need to be in json , with properties, title as string, and one liners as array of strings`
    );
    content = JSON.parse(content);
    setResponseGenerated(false);

    aiResponse(
      id,
      brandName,
      content,
      videoType.toLowerCase().replaceAll(/[^a-z]/g, ""),
      audioType
    ).then((res) => {
      if (res) {
        var update = {
          videoUrl: aiServerUrl + "task/" + id,
          regenerate: project.regenerate + 1,
          query: text,
          videoType: videoType,
          audioType: audioType,
        };

        updateDoc(doc(db, "aivideosProjects", id), update);
        setProejct({
          ...project,
          ...update,
        });
        setStep(4);
      }
    });
  };

  const generatePage = () => {
    switch (step) {
      case 7:
        return (
          <div className="flex flex-col items-center justify-center gap-3 ">
            <p className="">
              Please Choose a category{" "}
              <span className="text-xs text-red-500">(one time only)</span>
            </p>
            <div className="flex flex-col items-center justify-center w-2/3 gap-2">
              {categories.map((cat, index) => (
                <div
                  key={cat.id}
                  onClick={() => setSelectedCat(cat.id)}
                  className={
                    "p-1 rounded-md w-full cursor-pointer transform duration-150  " +
                    (cat.id === selectedCat
                      ? " bg-pink-600"
                      : " bg-black hover:bg-opacity-30")
                  }
                >
                  <p className="">{cat.name}</p>
                  <div className="flex flex-wrap gap-2">
                    {cat.images.map((sc, index) => (
                      <button
                        className="p-1 text-xs bg-gray-900 rounded-md"
                        key={sc.name}
                      >
                        {sc.name}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {selectedCat !== null && (
              <button
                onClick={submitSelectedCat}
                className="px-3 py-2 duration-150 transform bg-pink-600 rounded-md hover:bg-opacity-75"
              >
                Submit
              </button>
            )}
          </div>
        );
      case 6:
        return (
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <button className="px-4 py-2 bg-green-500 shadow-md">
                Pubslished
              </button>
              <p className="">This is published in MyRevue Platform.</p>
              <p className="">
                You can download this video by clicking on download button
              </p>
            </div>
            <div className="flex items-center justify-center flex-1">
              <video
                playsInline
                controls
                autoPlay
                className="rounded-lg shadow-lg shadow-black"
                style={{ height: "600px" }}
                src={aiServerUrl + "task/" + id}
              ></video>
            </div>
          </div>
        );

      case 5:
        return (
          <PublishVideoContent
            setUpward={updateProject}
            videoUrl={aiServerUrl + "/task/" + id}
          />
        );
      case 3:
        return (
          <div className="flex flex-col items-center justify-center h-96">
            {responseGenerated ? (
              <p className="">Generating Response by AI...</p>
            ) : (
              <p className="">
                Generating Your Engaging Ads, it will take atmost 20 seconds..
              </p>
            )}
            <i className="fas fa fa-spinner animate-spin"></i>
          </div>
        );
      case 4:
        return (
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center flex-1">
              <video
                playsInline
                controls
                autoPlay
                controlsList="nodownload noplaybackrate"
                disablePictureInPicture
                className="rounded-lg shadow-lg shadow-black aspect-[9/16]"
                style={{ height: "600px" }}
                src={aiServerUrl + "task/" + id}
              ></video>
            </div>

            <div className="flex items-center gap-5 my-8 text-center">
              {project.regenerate < 2 && (
                <button
                  className="bg-[#2E3036] hover:bg-[#5c5c5c] w-fit px-12 rounded-md h-11 flex items-center gap-2"
                  onClick={regenerateVideo}
                >
                  Regenerate
                </button>
              )}
              <button
                className="bg-[#93A8F4] hover:bg-[#4611ea] w-fit px-6 rounded-md h-11 flex items-center gap-2"
                onClick={() => setStep(5)}
              >
                Publish & Download
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="">
            <div className="grid grid-cols-2">
              <div className="">
                <div className="">
                  <p className="my-3 font-bold">Video Type</p>
                  <div className="flex flex-wrap items-start justify-start gap-2">
                    {categories.map((sc, index) => (
                      <button
                        key={sc.name}
                        onClick={() => setVideoType(sc.name)}
                        className={
                          videoType === sc.name
                            ? "flex bg-[#93A8F4] h-10 px-5  rounded-full items-center justify-center"
                            : "flex bg-[#2E3036] hover:bg-[#93A8F4] h-10 px-5  items-center justify-center rounded-full"
                        }
                      >
                        {sc.name}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-10 w-min">
                  <p className="">
                    Describe about your product and we will generate an engaging
                    video ad for you.
                  </p>
                  <textarea
                    ref={promptRef}
                    type="text"
                    className="first-letter:uppercase  bg-[#23252D] appearance-none outline-none rounded-md p-3 text-sm tracking-wide"
                    placeholder="include objectives, solutions"
                    name=""
                    id=""
                    cols="80"
                    rows="3"
                    maxLength={100}
                  ></textarea>
                  <p className="text-sm tracking-wide">
                    Example : this product help in moisturising the skin
                  </p>
                </div>
              </div>
              <div className="">
                <p className="my-2 font-bold">Audio Type</p>
                <div className="flex flex-wrap items-start justify-start gap-2">
                  <div
                    style={{
                      backgroundImage: `url(${soothingImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className={
                      audioType === "soothing1" ? activeAudio : passiveAudio
                    }
                    onMouseEnter={(e) => {
                      e.currentTarget.querySelector("audio").play();
                    }}
                    onMouseLeave={(e) => {
                      var audi = e.currentTarget.querySelector("audio");
                      audi.currentTime = 0;
                      audi.pause();
                    }}
                    onClick={() => setAudioType("soothing1")}
                  >
                    <audio
                      controls
                      className="hidden"
                      src={aiServerUrl + "bgmc/soothing1"}
                    ></audio>
                    <p className="">type 1</p>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${soothingImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className={
                      audioType === "soothing2" ? activeAudio : passiveAudio
                    }
                    onMouseEnter={(e) => {
                      e.currentTarget.querySelector("audio").play();
                    }}
                    onMouseLeave={(e) => {
                      var audi = e.currentTarget.querySelector("audio");
                      audi.currentTime = 0;
                      audi.pause();
                    }}
                    onClick={() => setAudioType("soothing2")}
                  >
                    <audio
                      controls
                      className="hidden"
                      src={aiServerUrl + "bgmc/soothing2"}
                    ></audio>
                    <p className="">type 2 </p>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${soothingImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className={
                      audioType === "soothing3" ? activeAudio : passiveAudio
                    }
                    onMouseEnter={(e) => {
                      e.currentTarget.querySelector("audio").play();
                    }}
                    onMouseLeave={(e) => {
                      var audi = e.currentTarget.querySelector("audio");
                      audi.currentTime = 0;
                      audi.pause();
                    }}
                    onClick={() => setAudioType("soothing3")}
                  >
                    <audio
                      controls
                      className="hidden"
                      src={aiServerUrl + "bgmc/soothing3"}
                    ></audio>
                    <p className="">type 3 </p>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${soothingImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className={
                      audioType === "soothing4" ? activeAudio : passiveAudio
                    }
                    onMouseEnter={(e) => {
                      e.currentTarget.querySelector("audio").play();
                    }}
                    onMouseLeave={(e) => {
                      var audi = e.currentTarget.querySelector("audio");
                      audi.currentTime = 0;
                      audi.pause();
                    }}
                    onClick={() => setAudioType("soothing4")}
                  >
                    <audio
                      controls
                      className="hidden"
                      src={aiServerUrl + "bgmc/soothing4"}
                    ></audio>
                    <p className="">type 4 </p>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${soothingImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className={
                      audioType === "soothing5" ? activeAudio : passiveAudio
                    }
                    onMouseEnter={(e) => {
                      e.currentTarget.querySelector("audio").play();
                    }}
                    onMouseLeave={(e) => {
                      var audi = e.currentTarget.querySelector("audio");
                      audi.currentTime = 0;
                      audi.pause();
                    }}
                    onClick={() => setAudioType("soothing5")}
                  >
                    <audio
                      controls
                      className="hidden"
                      src={aiServerUrl + "bgmc/soothing5"}
                    ></audio>
                    <p className="">type 5 </p>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${soothingImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className={
                      audioType === "soothing6" ? activeAudio : passiveAudio
                    }
                    onMouseEnter={(e) => {
                      e.currentTarget.querySelector("audio").play();
                    }}
                    onMouseLeave={(e) => {
                      var audi = e.currentTarget.querySelector("audio");
                      audi.currentTime = 0;
                      audi.pause();
                    }}
                    onClick={() => setAudioType("soothing6")}
                  >
                    <audio
                      controls
                      className="hidden"
                      src={aiServerUrl + "bgmc/soothing6"}
                    ></audio>
                    <p className="">type 6 </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full my-5">
              <button
                onClick={getAiResponse}
                className="h-12 px-8 py-2 bg-[#93A8F4] hover:bg-[#4611ea] duration-200 transform rounded-md"
              >
                Generate
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-center w-full h-96">
            <i className="fas fa fa-spinner animate-spin"></i>
          </div>
        );
    }
  };

  return (
    <div className="relative min-h-screen p-5 overflow-y-auto ">
      <div className="bg-[#131418] rounded-md p-5">
        <div className="">
          <div className="flex items-center justify-between">
            <p className="m-0 text-4xl font-semibold">
              AI-Powered Short Video Ad Generator: Elevate Your Marketing with
              Intelligent Creativity
            </p>
          </div>
          <div className="my-4">
            <p className="font-light tracking-wider">
              Elevate your brand with AI magic: Instantly craft compelling short
              video ads that captivate your audience and boost engagement
            </p>
          </div>
        </div>
        {generatePage()}
      </div>
    </div>
  );
}

export default AIVideoProject;
