import React, { useEffect, useMemo, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

function PageOverView({ pageid }) {
  const [{ user }, dispatch] = useStateValue();
  const [insight, setInsight] = useState([]);

  useEffect(() => {
    var token = user.social.facebook_pages.find(
      (p) => p.id === pageid
    )?.access_token;
    if (!token) {
      token = user.social.facebook.access_token;
    }

    var timestamp = Math.floor(Date.now() / 1000) - 28 * 24 * 60 * 60;
    console.log(timestamp);
    axios
      .get(
        `https://graph.facebook.com/v19.0/${pageid}/insights?metric=page_impressions_unique,page_impressions_paid_unique,page_impressions_organic_unique_v2&period=day&since=${timestamp}&access_token=${token}`
      )
      .then((response) => {
        setInsight(response.data.data);
      });
  }, []);

  const chartData = useMemo(() => {
    return {
      totalSum: insight.map((metric) => ({
        name: metric.name,
        sum: metric.values.reduce((sum, curr) => sum + curr.value, 0),
      })),
      options: {
        markers: {
          show: false, // Hide the data point markers
        },
        legend: {
          show: true, // Hide the legend
        },
        dataLabels: {
          enabled: false, // Disable the data labels
        },
        grid: {
          show: false,
        },
        chart: {
          id: "area-datetime",
          toolbar: {
            show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
          },
        },
        xaxis: {
          type: "datetime",
        },
      },
      series: insight.map((d) => ({
        name: d.title,
        data: d.values.map((f) => [new Date(f.end_time).getTime(), f.value]),
      })),
    };
  }, [insight]);

  console.log(chartData);

  if (!insight)
    return (
      <div className="">
        <p className="">Loading...</p>
      </div>
    );
  else
    return (
      <div className="px-5 py-3 rounded-md bg-bg_highlight">
        <div className="">
          <div className="">
            <div className="">Performance</div>
            <div className="">Reach</div>
            <div className=""></div>
          </div>
          <div className="flex">
            <div className="flex-1">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={460}
              />
            </div>
            <div className="flex flex-col items-start justify-end w-64 h-full">
              <p className="pb-3 text-lg font-light tracking-wide">
                Reach Breakdown <br />
                <span className="text-xs text-gray-400">(Last 28 Days)</span>
              </p>
              <div className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]">
                <p className="">Total</p>
                <p className="text-lg font-medium">
                  {chartData?.totalSum[0]?.sum}
                </p>
              </div>
              <div className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]">
                <p className="">From Organic</p>
                <p className="text-lg font-medium">
                  {chartData?.totalSum[2]?.sum}
                </p>
              </div>
              <div className="w-full my-2 px-5 py-3 bg-background rounded-md border border-[#8080801e]">
                <p>From Ads</p>
                <p className="text-lg font-medium">
                  {chartData?.totalSum[1]?.sum}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default PageOverView;
