import React from 'react'

export default function Templates({ templates, settemplate, setcustomTempId, settype, setlogodisplay }) {

    const custom_templates = [
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2F1.png?alt=media&token=d13f23ac-6f84-47cd-9940-34e70879d82f",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2F2.png?alt=media&token=19e8414a-d44f-45a2-bde9-266a1d6d0648",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2F3.png?alt=media&token=8fedbb0a-eb7b-471c-ab9b-e8acd2a38802",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2F4.png?alt=media&token=7ec50889-7d53-420a-877c-cddbc673383b",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2F5.png?alt=media&token=5b1a22ff-c799-45bb-a1b9-e4b653029b17",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2Fdesign%206.png?alt=media&token=15ca7001-d8dd-4fef-9b5a-cd0503994f77",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2Fdesign%207.png?alt=media&token=40ae6f39-9ef9-4726-87e5-b6335fc4637c",
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/studio%2Fcustom%20templates%2Fdesign%208.png?alt=media&token=039c4e47-58e3-416e-a98d-41e0588b4e17"

    ]

    return (
        <div className=''>

            <div className="">
                <p className="font-light tracking-wide text-white text-opacity-60"> Templates</p>
                <div className='grid grid-cols-3 gap-3'>
                    {templates.map((template, index) => (
                        <div className="w-full aspect-[9/16] cursor-pointer" onClick={() => { settemplate(template); settype('') }} key={index}>
                            <img src={template} alt="" className="object-cover w-full h-full" />
                        </div>
                    ))}

                </div>
            </div>

            <div className="mt-10">
                <p className="font-light tracking-wide text-white text-opacity-60"> Custom Templates</p>
                <div className="flex justify-between py-3">
                    <p className="w-[calc(100%-70px)] text-sm">Would you like to display your logo:</p>
                    <label className="switch">
                        <input type="checkbox" className='' onChange={(event) => event.target.checked === true ? setlogodisplay(true) : setlogodisplay(false)} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className='grid grid-cols-3 gap-3'>
                    {custom_templates.map((template, index) => (
                        <div className="w-full rounded cursor-pointer aspect-square" onClick={() => { setcustomTempId(index + 1); settype('custom') }} key={index}>
                            <img src={template} alt="" className="object-cover w-full h-full rounded" />
                        </div>
                    ))}
                </div>
            </div>


        </div>

    )
}
