import { Play, PlayCircle } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";

export default function GridCard({ data, index, playVideos, mode }) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenChange = () => {
    setIsFullscreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);
  return (
    <div className={`aspect-[9/16] w-[330px]  ${mode === "light" ? "bg-white shadow-sm border" : "bg-[#202020]"} rounded-lg flex flex-col`} onClick={() => playVideos(index)} >
      <div className="relative w-full h-full rounded-lg">
        <div className="absolute z-10 flex items-center justify-center w-full h-full">
          <div className="h-14 w-14 rounded-full bg-[#1F1D20] flex items-center justify-center">
            <Play weight="fill" size={32} color="white" />
          </div>
        </div>
        <div className="absolute flex flex-col justify-between w-full h-full bg-black rounded-lg bg-opacity-40">
          <div className=""></div>
          <div className="w-full px-3 pb-3">
            <div className="w-full mb-2">
              <p className={`text-white text-xl font-medium capitalize mt-4 line-clamp-2 text-center`}  >
                {data?.name}
              </p>
            </div>
            <div className="flex items-center w-full gap-2 p-2 bg-white rounded-md">
              <img src={data?.user?.imageUrl ?? `https://ui-avatars.com/api/?size=100&name=${data?.user?.name}`} alt="profile" className="object-cover h-10 rounded-full aspect-square" />
              <div className="flex-1">
                <p className={`text-[#121212] truncate break-all first-letter:uppercase tracking-wide text-xs`} >
                  {data?.user?.name}
                </p>
                <p className={`text-[#121212] truncate break-all first-letter:uppercase tracking-wide text-xs`}>
                  @{data?.user?.id}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src={data?.thumbnail}
          className="object-cover w-full h-full rounded-lg"
          alt="thumbnail"
        />
      </div>
      {/* <div className="flex flex-col justify-between flex-1 h-full p-5 space-y-4">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="23"
            viewBox="0 0 30 23"
            fill="none"
          >
            <path
              d="M24.618 0.451996L29.046 2.666C25.684 7.34 22.24 12.834 22.24 22.51H13.712C13.958 12.588 21.01 3.158 24.618 0.451996ZM11.252 0.451996L15.598 2.666C12.318 7.258 8.792 12.834 8.792 22.51H0.346C0.592 12.588 7.644 3.158 11.252 0.451996Z"
              fill={mode === "light" ? "#121212" : "#D9D9D9"}
            />
          </svg>
          <p
            className={`${mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"
              } text-3xl capitalize mt-4`}
          >
            {data?.name}
          </p>
        </div>
        <div className="flex items-center w-full gap-3">
          <div className="w-16 h-16 border rounded-full">
            <img
              src={
                data?.user?.imageUrl ??
                `https://ui-avatars.com/api/?size=100&name=${data?.user?.name}`
              }
              alt="profile"
              className="object-cover w-full h-full rounded-full"
            />
          </div>
          <div className="flex-1 w-[70%]">
            <p
              className={`${mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"
                } truncate break-all first-letter:uppercase tracking-wide`}
            >
              {data?.user?.name}
            </p>
            <p
              className={`${mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"
                } truncate break-all first-letter:uppercase tracking-wide`}
            >
              {data?.user?.id}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
