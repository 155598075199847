import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import AWN from "awesome-notifications";
import { nanoid } from "nanoid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import {
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import ReactStars from "react-rating-stars-component";
import { sendAmplitudeData } from "../../amplitude";
import Userviewfileupload from "../../components/Userviewfileupload";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import { CircleNotch } from "@phosphor-icons/react";
import { logEngagementHook } from "../../common/functions";
import { showWarning } from "../../components/toast/toast";

function ChallangeUserView() {
  const [cstep, setStep] = useState(0);
  const { form } = useParams();
  const [device, setdevice] = useState("");
  //   const router = useHistory();
  const [loading, setloading] = useState(true);
  const [userRsp, setuserRsp] = useState({});
  const [loaded, setloaded] = useState(false);
  const [formData, setformData] = useState({});
  const [product_details, setproduct_details] = useState({});
  const [product_attributes, setproduct_attributes] = useState([]);
  const [uploading, setuploading] = useState(false);
  const [review_info, setreview_info] = useState({});
  const [progress_video, setprogress_video] = useState(0);
  const [user, setuser] = useState({});
  const [user_details, setuser_details] = useState({});
  const [phone_modal, setphone_modal] = useState(false);
  const [phone_number, setphone_number] = useState("");
  const [countrycode, setcountrycode] = useState(91);
  const [getotp, setgetotp] = useState(false);
  const [otp, setotp] = useState("");
  const [valid, setValid] = useState(true);
  const [error, seterror] = useState({});
  const [otp_verifying, setotp_verifying] = useState(false);
  var user_id = `user_${nanoid(8)}`;
  const [video_id, setvideo_id] = useState(nanoid(20));
  const [thumbnailurl, setthumbnailurl] = useState("");
  const [review_type, setreview_type] = useState("video");
  const [review_images, setreview_images] = useState([]);
  const [reviewsfetch, setreviewsfetch] = useState([]);
  useEffect(() => {
    if (loaded) return;
    setloaded(true);
    getDoc(doc(db, "challenges", form)).then((res) => {
      if (!res.exists()) {
        setloading(false);
        setValid(false);
      } else {
        const data = res.data();
        var date = new Date().toDateString();
        if (!data.openDates.includes(date)) {
          setloading(false);
          setValid(false);
        }
        setuserRsp({ ...data, id: res.id });
        setreviewsfetch(data?.reviews || []);
        setformData(data);
        setloading(false);
        getproduct(data.prodid);
        console.log(data)

        sendAmplitudeData("campaign link opened", { campaign: form });
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
          )
        ) {
          setdevice("Mobile");
          logEngagementHook({
            campaignId: form,
            date: Date.now(),
            platform: "mobile",
            brand: data.bpsId,
            action: "open_challenge",
          });
        } else {
          setdevice("Desktop");
          logEngagementHook({
            campaignId: form,
            date: Date.now(),
            platform: "Desktop",
            brand: data.bpsId,
            action: "open_challenge",
          });
        }

        var title = `${data?.campaign_name}`;
        var metaTitle = `${data?.campaign_name}`;
        var metaDescription = `${data?.welcomeMessage}`;
        var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, ${data?.productname}`;

        var ogUrl = `https://brand.myrevue.app/userView/${form}`;
        var ogtype = `website`;
        var ogtitle = `${data?.campaign_name} `;
        var ogdescription = `${data?.welcomeMessage} `;
        var ogimage = `${data?.productimage} `;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/userView/${form}`;
        var twittertitle = `${data?.campaign_name}`;
        var twitterdescription = `${data?.welcomeMessage}`;
        var twitterimage = `${data?.productimage}`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
          metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
          ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content =
          twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
          twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content =
          twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content =
          twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
          twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content =
          twitterimage;
      }
    });
  }, []);

  async function getproduct(id) {
    let headersList = {
      Accept: "*/*",
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
    };

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + id,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    let data = response.data;
    if (!data) {
      return showWarning("Product information not available");
    }
    setproduct_details(data);
    setproduct_attributes(Object.keys(data?.attributes ?? {}));
  }

  const signinByGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: formData.bpsId,
          action: "challenge_login_success",
        });
        setuser({
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });

        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName,
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          showWarning("Something went wrong");
          return;
        }
        setuser_details(response?.data?.user);
        setStep(2);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const video_upload = async (e) => {
    var image = e.target.files[0];
    var type = image.type.split("/")[1];
    const formats = ["mp4", "mkv", "mov", "avi"];

    if (!formats.includes(type)) {
      alert("Please upload a video file");
      return;
    }
    await handleFileUpload(e);
    const storage = getStorage();
    const storageRef = ref(storage, "formReview/" + video_id + ".mp4");
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setuploading(true);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // console.log(progress)
        setprogress_video(progress);
      },
      (error) => { },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setuserRsp({
            ...userRsp,
            reviewUrl: downloadURL,
          });
          setuploading(false);
        });
      }
    );
  };

  const submit_review = async () => {
    let newdoc = {
      thumbnail: thumbnailurl,
      campaign_info: {
        campaign_name: userRsp?.campaign_name,
        productname: userRsp?.productname,
        prodid: userRsp?.prodid,
        catId: userRsp?.catId,
        productimage: userRsp?.productimage,
        subcategory: userRsp?.subcategory,
      },
      user_info: {
        name: user_details.name,
        tag: user_details.tag,
        token: user_details.token ?? null,
        id: user_details.id,
        image: user_details.imageUrl ?? null,
        email: user_details.email ?? null,
        phone: user_details.phone ?? null,
      },
      reviewUrl: userRsp.reviewUrl || null,
      review_type: "video",
      substatus: "pending",
      dateAdded: Date.now(),
    };

    let updates = { submissions: increment(1), videoReviews: increment(1) };
    await updateDoc(doc(db, "challenges/" + userRsp?.id), updates).then((res) =>
      console.log("done")
    );
    addDoc(
      collection(db, "challenges/" + userRsp?.id + "/submissions"),
      newdoc
    ).then(() => {
      new AWN().success("Submitted successFully");
      logEngagementHook({
        campaignId: form,
        date: Date.now(),
        platform: device,
        brand: formData.bpsId,
        action: "user_challenge_submited",
      });
      setStep(5);
    });
  };

  function getphoneotp() {
    let regex = /^[6-9][0-9]{9}/i;
    if (!regex.test(phone_number)) {
      return new AWN().warning("Please enter valid phone number");
    }

    const auth = getAuth();

    const phonenumber = "+" + countrycode + phone_number;

    if (phonenumber.length >= 12) {
      setgetotp(true);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
          },
        },
        auth
      );
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phonenumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }
  const verifyOTP = () => {
    let regex = /\d{6}/i;
    if (!regex.test(otp)) {
      return new AWN().alert("Please enter valid otp");
    }
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then(async (result) => {
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          brand: formData.bpsId,
          platform: device,
          action: "challenge_login_success",
        });
        const user = result.user;
        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName?.toLowerCase() ?? "Change User Name",
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          phone: user.phoneNumber,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
          joinDate: Date.now(),
          categories: [],
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          showWarning("Something went wrong");
          return;
        }
        setuser_details(response?.data?.user);
        setStep(2);
      })
      .catch((error) => {
        console.log(error);
        showWarning("Sign In error. Please try again.");
        window.location.reload(true);
      });
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  const handleFileUpload = async (e) => {
    const thumbnail_snap = await generateVideoThumbnail(e.target.files[0]);

    const storage = getStorage();
    const storageRef = ref(storage, "formReview/" + video_id + ".jpg");

    uploadString(storageRef, thumbnail_snap, "data_url").then((snapshot) => {
      getDownloadURL(storageRef).then((downloadURL) => {
        setthumbnailurl(downloadURL);
      });
    });
  };
  async function gobackfromquestion() {
    if (review_type === "text") {
      if (userRsp?.askQuestionBefore === true) {
        setreview_type("");
        setStep(2);
      } else {
        setreview_type("");
        setStep(2);
      }
    } else {
      if (userRsp?.askQuestionBefore === true) {
        setreview_type("");
        setStep(2);
      } else {
        setStep(2);
      }
    }
  }

  async function gobackfromreview() {
    if (review_type === "text") {
      if (userRsp?.askQuestionBefore === true) {
        setStep(3);
      } else {
        setStep(3);
      }
    } else {
      if (userRsp?.askQuestionBefore === true) {
        setStep(2);
      } else {
        setStep(3);
      }
    }
  }
  if (!valid)
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="relative flex flex-col justify-between h-full overflow-y-auto "
            id="thin-scrollbar"
          >
            <div className="flex items-center justify-center px-5 pt-10">
              <div className="h-16 px-8 py-2 overflow-hidden bg-white rounded-xl ">
                <img
                  src={
                    formData.LogoLink ??
                    "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                  }
                  alt=""
                  className="h-full mx-auto "
                />
              </div>
            </div>
            <div className="">
              <p className="text-center font-work">
                This challenge is not valid. <br />Make sure to double-check the link.
              </p>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    );

  if (loading) {
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="relative flex items-center justify-center h-full overflow-y-auto"
            id="thin-scrollbar"
          >
            <span className="animate-spin">
              <CircleNotch size={46} color="white" />
            </span>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="relative flex flex-col justify-between h-full overflow-y-auto "
            id="thin-scrollbar"
          >
            {cstep === 0 ? (
              <div className="">
                <div className="flex items-center justify-center p-10">
                  <div className="flex items-center justify-center h-16 px-8 overflow-hidden bg-white rounded-xl ">
                    <img
                      src={
                        formData.LogoLink ??
                        "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      }
                      alt=""
                      className="h-16 mx-auto "
                    />
                  </div>
                </div>
                <div className="px-10">
                  <div className="">
                    <p className="font-medium tracking-wider text-center">
                      {formData?.campaign_name || formData?.parentName}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center my-5">
                    <div>
                      {formData?.spokeLink ? (
                        <img
                          src={formData?.spokeLink}
                          alt=""
                          className="object-cover w-20 h-20 mx-auto my-2 rounded-full"
                        />
                      ) : (
                        <div className="flex items-center justify-center w-20 h-20 text-3xl text-pink-500 bg-white rounded-full">
                          {formData?.name
                            ?.split(" ")[0]
                            .substring(0, 2)
                            .toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <p className="font-medium text-center">{formData.name}</p>
                      <p className="font-sans font-light text-center opacity-50">
                        {formData.designation}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-l-[3px] border-red-500 mx-10 mt-12">
                  <p className="px-2 tracking-wide">
                    {formData.welcomeMessage}
                  </p>
                </div>
                <div className="flex flex-col items-center mx-10 mt-12">
                  <p className="text-lg font-medium">How it Works</p>
                  <div className="flex mt-10 gap-x-2">
                    <p className="">
                      Complete the challange and win{" "}
                      {(formData?.incentive).toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}{" "}
                      cashback incentive.
                    </p>
                  </div>
                </div>
                {/* <div className="p-5 mt-8">
                  <p className="">Reviews</p>
                  {displayReviews?.map((rev, index) => (
                    <video playsInline  className="w-full aspect-[9/16] rounded-md py-5" poster={rev?.thumbnail} src={rev?.reviewUrl} key={index} onClick={(e) => {
                      e.currentTarget.paused ? e.target.play() : e.target.pause()
                    }} />

                  ))}
                </div> */}
                {/* <div className="mx-auto w-full lg:w-96 md:w-2/3 fixed z-50  px-10 pb-6 pt-12 bottom-0 bg-gradient-to-b from-transparent   via-[#00354C] to-[#003147] ">
                  <button
                    className=" py-4 bg-[#0097A7]  rounded-lg appearance-none outline-none w-full"
                    onClick={() => setStep(1)}
                  >
                    Let's Go
                  </button>
                </div> */}
                <div
                  className="absolute bottom-0 flex flex-col justify-end w-full h-32 px-5 pb-5 bg-gradient-to-b from-transparent via-transparent md:rounded-b-lg"
                  style={{ "--tw-gradient-to": "#00000069" }}
                >
                  <button
                    onClick={() => setStep(1)}
                    className="w-full py-3 rounded-lg outline-none appearance-none"
                    style={{ backgroundColor: "#0097A7" }}
                  >
                    Let's Go
                  </button>
                </div>
              </div>
            ) : null}

            {cstep === 1 ? (
              <div className="">
                {phone_modal === true ? (
                  <div className="absolute z-30 w-full h-full p-5 ">
                    <div className="w-full h-full bg-white rounded">
                      <div className="" id="sign-in-button"></div>
                      <div className="flex items-center justify-between h-10 px-3 text-black border-b">
                        <p className="">Sign in with Phone</p>
                        <button
                          className=""
                          onClick={() => setphone_modal(false)}
                        >
                          <i className="fas fa fa-close fa-xl"></i>
                        </button>
                      </div>
                      <div className="h-[93%] w-full flex flex-col items-center justify-between">
                        <div className=""></div>
                        <div className="w-full p-5">
                          <div className="flex gap-5">
                            <input
                              type="number"
                              defaultValue={countrycode}
                              className="w-16 border border-black appearance-none outline-none h-10 placeholder:text-[#12121259] px-3 text-black"
                              placeholder="+91"
                              onChange={(e) => setcountrycode(e.target.value)}
                            />
                            <input
                              type="text"
                              maxLength="10"
                              autocomplete="one-time-code"
                              className="border border-black appearance-none outline-none h-10 w-full flex-1 placeholder:text-[#12121259] px-3 text-black"
                              placeholder="Enter phone number"
                              onChange={(e) => setphone_number(e.target.value)}
                            />
                          </div>
                          {getotp === true ? (
                            <input
                              type="text"
                              // maxLength="6"
                              autoComplete="one-time-code"
                              className="border border-black appearance-none outline-none h-10 w-full placeholder:text-[#12121259] px-3 mt-3 text-black"
                              placeholder="Enter OTP"
                              onChange={(e) => setotp(e.target.value)}
                            />
                          ) : null}
                          {getotp === false ? (
                            <button
                              className="w-full h-10 mt-2 bg-black"
                              onClick={getphoneotp}
                            >
                              Get OTP
                            </button>
                          ) : null}
                          {/* {getotp === true ? <p onClick={() => getphoneotp()} className="my-3 text-sm font-medium text-right text-black cursor-pointer">Resend OTP</p> : null} */}
                          {getotp === true ? (
                            <button
                              className="w-full h-10 mt-2 bg-black"
                              onClick={verifyOTP}
                            >
                              {otp_verifying === true ? (
                                <div id="loading"></div>
                              ) : (
                                "Verify OTP"
                              )}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="flex flex-col h-full p-6">
                  <div
                    className="flex items-center justify-center bg-white rounded-lg bg-opacity-10 h-14 w-14"
                    onClick={() => setStep(0)}
                  >
                    <i className="transform scale-125 rotate-180 fas fa-chevron-right opacity-80"></i>
                  </div>
                  <div className="border-l-[3px] border-red-500 mt-12">
                    <p className="px-2 font-medium tracking-wide opacity-40">
                      Step 1 of 4
                    </p>
                    <p className="px-2 tracking-wide">
                      Please login/signup to continue
                    </p>
                  </div>
                </div>
                <div
                  className="absolute bottom-0 flex flex-col justify-end w-full h-32 gap-5 px-5 pb-5 bg-gradient-to-b from-transparent via-transparent md:rounded-b-lg"
                  style={{ "--tw-gradient-to": "#00000069" }}
                >
                  <button
                    onClick={() => signinByGoogle()}
                    className="bg-white h-10 w-full bottom-10 rounded-md px-4 flex items-center justify-center shadow-md shdaow-[#121212]"
                  >
                    <img
                      src="https://freesvg.org/img/1534129544.png"
                      alt=""
                      className="w-6 h-6 mx-3 "
                    />
                    <p className="text-[#121212] text-sm tracking-wide">
                      Sign in with Google
                    </p>
                  </button>
                  <button
                    onClick={() => setphone_modal(true)}
                    className="bg-white h-10 w-full  bottom-24  rounded-md px-4 flex items-center justify-center shadow-md shdaow-[#121212]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mx-3"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                    <p className="text-[#121212] text-sm tracking-wide">
                      Sign in with Phone
                    </p>
                  </button>
                </div>
              </div>
            ) : null}

            {cstep === 2 ? (
              <div className="">
                <div className="p-6">
                  <div
                    className="flex items-center justify-center bg-white rounded-lg bg-opacity-10 h-14 w-14"
                    onClick={() => {
                      setreview_type("");
                      setStep(2);
                    }}
                  >
                    <i className="transform scale-125 rotate-180 fas fa-chevron-right opacity-80"></i>
                  </div>
                </div>
                {uploading === true || userRsp?.reviewUrl ? null : (
                  <div className="border-l-[3px] border-red-500 mt-12 m-6">
                    <p className="px-2 font-medium tracking-wide opacity-40">
                      Step 2 of 4
                    </p>
                    <p className="px-2 text-sm tracking-wide">
                      Share your review.
                    </p>
                  </div>
                )}

                <div className="p-6">
                  {uploading ? (
                    <div className="">
                      <div className="">
                        <svg
                          width="24"
                          height="24"
                          className="stroke-current text-white transform scale-[3] mt-10 ml-6"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408" />
                        </svg>
                      </div>
                      <div className="border-l-[3px] border-red-500 mt-6">
                        <p className="px-2 font-medium tracking-wide opacity-40">
                          Thank You
                        </p>
                        <p className="px-2 tracking-wide">
                          Hang tight while we save your response.
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {uploading ? (
                    <div className="bg-[rgba(0,0,0,0.3)] h-64 rounded-lg mt-10 flex flex-col items-center justify-center">
                      <div className="transform scale-150 ">
                        <div className="dot-fire"></div>
                      </div>
                      <div className="flex flex-col items-center justify-center mt-10 text-xl font-medium tracking-wide">
                        <p className="">Uploading ...</p>
                        <p className="">{progress_video}%</p>
                      </div>
                    </div>
                  ) : null}
                  {userRsp.reviewUrl ? (
                    <div>
                      <div className="flex items-center justify-center mt-5 rounded-lg ">
                        <video playsInline
                          src={userRsp?.reviewUrl}
                          autoPlay
                          controls
                          className="w-full rounded-lg"
                        ></video>
                      </div>
                      <div className="">
                        <button
                          className="mt-4 py-4 bg-[#0097A7] my-4 rounded-lg appearance-none outline-none w-full"
                          onClick={() => submit_review()}
                        >
                          Submit
                        </button>
                        {device === "Mobile" ? (
                          <>
                            <p className="mx-auto text-center">Or</p>
                            <div className="relative mt-4 ">
                              <input
                                type="file"
                                accept=".mp4"
                                capture="camera"
                                className="absolute w-full py-3 my-4 border opacity-0"
                                onChange={(e) => video_upload(e)}
                              />
                              <button className="py-4 bg-[#0097A7]  rounded-lg appearance-none outline-none w-full">
                                <i className="mr-5 fas fa-video"></i> Record
                                Video
                              </button>
                            </div>
                          </>
                        ) : null}

                        <div className="relative mt-4 ">
                          <input
                            type="file"
                            accept=".mp4"
                            // capture="camera"
                            className="absolute w-full py-3 my-4 border opacity-0"
                            onChange={(e) => video_upload(e)}
                          />
                          <button className="py-4 bg-[#12121265]  rounded-lg appearance-none outline-none w-full">
                            Re - Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {uploading === true || userRsp?.reviewUrl ? null : (
                  <div className="absolute bottom-0 w-full ">
                    <p className="pb-5 text-sm font-medium tracking-wide text-center">
                      How would you like to respond
                    </p>
                    <div className="relative w-full px-5">
                      {review_type === "video" && (
                        <>
                          {device === "Mobile" ? (
                            <>
                              <div className="relative mt-4 ">
                                <input
                                  type="file"
                                  accept="video/*"
                                  capture="camera"
                                  maxLength="120"
                                  className="absolute w-full h-full border rounded-lg opacity-0"
                                  onChange={(e) => video_upload(e)}
                                />
                                <button className=" py-3 bg-[#0097A7] rounded-lg appearance-none outline-none w-full">
                                  <i className="mr-5 fas fa-video"></i> Record
                                  Video
                                </button>
                              </div>
                              <p className="mx-auto my-2 text-center">Or</p>
                            </>
                          ) : null}
                          <div className="relative mb-5">
                            <input
                              type="file"
                              accept=".mp4"
                              className="absolute w-full h-full border rounded-lg opacity-0"
                              onChange={(e) => video_upload(e)}
                            />
                            <button
                              className="py-3 bg-[#0097A7] rounded-lg appearance-none outline-none w-full"
                            // onClick={() => setvideorecord_modal(true)}
                            >
                              <i className="mr-5 fas fa-video"></i> Upload Video
                            </button>
                          </div>
                        </>
                      )}
                      {/* {review_type === 'text' && <>
                    <div className="p-5">
                      <p className="pb-2 text-sm font-light tracking-wide text-white text-opacity-50">Please write a review in more than 150 characters</p>
                      <textarea onChange={(event) => settextreview(event.target.value)} className="w-full h-40 p-2 text-black border border-white rounded-md outline-none appearance-none border-opacity-20"></textarea>
                      <button
                        className=" py-3 bg-[#0097A7] rounded-lg appearance-none outline-none w-full"

                        onClick={() => submittextreview()}>
                        Submit
                      </button>
                    </div>

                  </>} */}
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {cstep === 4 ? (
              <div className="p-6 ">
                <div
                  className="flex items-center justify-center bg-white rounded-lg bg-opacity-10 h-14 w-14"
                  onClick={() => gobackfromreview()}
                >
                  <i className="transform scale-125 rotate-180 fas fa-chevron-right opacity-80"></i>
                </div>
                <div className="">
                  <p className="my-5 text-2xl font-light text-center ">
                    Please enter review details
                  </p>
                </div>
                <div className="">
                  {review_type === "video" && (
                    <div className="">
                      <p className="text-sm tracking-wide">
                        Review Title{" "}
                        <span className="text-lg text-red-500">*</span>
                      </p>
                      <input
                        type="text"
                        onChange={(event) =>
                          setreview_info({
                            ...review_info,
                            review_name: event.target.value,
                          })
                        }
                        maxLength={30}
                        className="h-10 bg-[#00000059] mt-1 w-full appearance-none outline-none px-3 rounded-md text-sm text-[#bbbbbb]"
                        placeholder="enter title of review"
                      />
                      {error?.review_name ? (
                        <p className="pl-1 text-sm font-medium tracking-wider text-red-500">
                          Please add review title
                        </p>
                      ) : null}
                    </div>
                  )}
                  <div className="mt-3">
                    <p className="text-sm tracking-wide">
                      Review Description
                      <span className="text-lg text-red-500"> *</span>
                    </p>
                    <textarea
                      type="text"
                      onChange={(event) =>
                        setreview_info({
                          ...review_info,
                          review_description: event.target.value,
                        })
                      }
                      maxLength={50}
                      className="h-28 bg-[#00000059] mt-1 w-full appearance-none outline-none p-3 rounded-md text-sm text-[#bbbbbb] "
                      placeholder={
                        review_type === "text"
                          ? "write a review"
                          : "enter review description upto 50 letters"
                      }
                    ></textarea>
                    {error?.review_description === true ? (
                      <p className="pl-1 text-sm font-medium tracking-wider text-red-500">
                        Please add description for review
                      </p>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <p className="text-sm font-medium tracking-wide text-center">
                      How would you rate this product
                    </p>
                    <p className="text-sm tracking-wide text-center">
                      Rate this out of 5 stars{" "}
                      <span className="text-lg text-red-500">*</span>
                    </p>
                    {error?.attributes ? (
                      <p className="pl-1 text-sm font-medium tracking-wider text-red-500">
                        Please add rating for all attributes
                      </p>
                    ) : null}
                    <div className="grid justify-between grid-flow-row grid-cols-2 mt-4">
                      {product_attributes?.map((att, index) => (
                        <div
                          className={`${index % 2 === 0
                            ? "w-full grid items-end justify-start my-3"
                            : "w-full grid items-end justify-center my-3"
                            }`}
                          key={index}
                        >
                          <p className="text-center">{att}</p>
                          <ReactStars
                            size={25}
                            count={5}
                            isHalf={false}
                            value={0}
                            color="white"
                            activeColor="#FF7D58"
                            onChange={(newValue) =>
                              setreview_info({
                                ...review_info,
                                attributes: {
                                  ...review_info.attributes,
                                  [att]: newValue,
                                },
                              })
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {review_type === "text" && (
                    <div className="mt-5">
                      <Userviewfileupload
                        setimages={setreview_images}
                        images={review_images}
                      />
                    </div>
                  )}
                  <div className="mt-6">
                    <button
                      className=" py-2 bg-[#0097A7]  rounded-lg appearance-none outline-none w-full"
                      onClick={() => submit_review()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {cstep === 5 ? (
              <div className="p-6 ">
                <div className="flex items-center justify-center ">
                  <div className="flex items-center justify-center h-16 p-6 bg-white overflow-clip w-44 rounded-xl">
                    <img
                      src={
                        formData.LogoLink ??
                        "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      }
                      alt=""
                      className=""
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center mt-12">
                  <div className="border-l-[3px] border-red-500 ">
                    <p className="px-2 text-3xl font-medium">
                      🎉 {formData?.headLine}
                    </p>
                    <p className="px-2 mt-3 font-sans text-xl font-light tracking-wide">
                      {formData?.thankYouMessageRef}
                    </p>
                  </div>
                  <div className="flex items-center mt-8 gap-x-3">
                    <div>
                      {formData?.spokeLink ? (
                        <img
                          src={formData?.spokeLink}
                          alt=""
                          className="object-cover w-12 h-12 mx-auto my-2 rounded-full"
                        />
                      ) : (
                        <div className="flex items-center justify-center w-12 h-12 text-xl text-pink-500 bg-white rounded-full">
                          {formData?.name
                            ?.split(" ")[0]
                            .substring(0, 2)
                            .toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <p className="font-medium">{formData.name}</p>
                      <p className="opacity-40">{formData.designation}</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-16 ">
                  <div className="w-[410px] bg-white  flex flex-col rounded-2xl p-10 items-center justify-center">
                    <p className="font-sans text-lg tracking-widest text-black uppercase ">
                      POWERED BY
                    </p>
                    <img
                      src="https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      alt=""
                      className="my-3 h-14"
                    />
                    <p className="mt-3 italic text-center text-black">
                      <span className="not-italic font-medium text-pink-500 underline underline-offset-2">
                        MyRevue
                      </span>{" "}
                      is first indian platform for reviews content. Stream and
                      publish reviews and get assured cashbacks.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
}

export default ChallangeUserView;
