async function pushNotification({
  message = "",
  endpoint = "",
  read = false,
  type = "",
  client = "",
}) {
  const dateTime = new Date.now();
  const body = { message, endpoint, read, type, client, dateTime };
  const response = await fetch(
    "https://api.video.myrevue.app/notification/push/" + client,
    {
      method: "POST",
      cache: "default",
      headers: {
        Authorization: "------------------",
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-
      body: JSON.stringify(body),
    }
  );
  return response.status;
}

export { pushNotification };
