import React, { Suspense, lazy } from 'react'
import Loadingdot from '../components/loadingdot';
const NotloginfooterNew = lazy(() => import("../layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("../layouts/NotLoginHeaderNew"));

export default function Refundpolicy() {
    return (
        <div className="bg-[#101010] min-h-screen h-full w-full overflow-y-auto">
            <Suspense fallback={<Loadingdot />}>
                <NotLoginHeaderNew />

                <div className='p-10'>
                    <h1 className='pb-5 text-xl text-center uppercase'>Refund Policy</h1>
                    <p className="py-2 tracking-wide font-work">At MyRevue, we strive to provide our customers with the best experience possible. If you are not satisfied with your purchase, we offer a 14-day refund policy.</p>

                    <p className="py-2 tracking-wide font-work">To be eligible for a refund, you must submit a refund request within 14 days of the date of purchase. You can submit a refund request by emailing our customer support team at connect@myrevue.app</p>

                    <p className="py-2 tracking-wide font-work">Please include your order number and the reason for your refund request. We may require additional information to process your request.</p>

                    <p className="py-2 tracking-wide font-work">Once your refund request is received, we will review it and determine whether a refund is appropriate. If your request is approved, we will issue a refund within 7-10 business days of approval.</p>

                    <p className="py-2 tracking-wide font-work">Please note that we reserve the right to refuse refunds in cases where we believe the refund policy is being abused or misused.</p>

                    <p className="py-2 tracking-wide font-work">Thank you for choosing MyRevue, and we apologize for any inconvenience you may have experienced. If you have any questions or concerns, please do not hesitate to contact us at connect@myrevue.app</p>
                </div>
                <NotloginfooterNew />
            </Suspense>
        </div>
    )
}