import React from 'react'

export default function FacebookReel({ review, user, caption }) {
    return (
        <div className="w-full max-w-[320px]  aspect-[9/16] bg-black border border-[#525050f6] rounded relative">
            <div className="absolute flex items-end w-full h-full">
                <div className="flex-1 w-full pr-2  pl-[10px] flex-col flex justify-between h-full">
                    <div className="flex-1 w-full"></div>
                    <div className="">
                        <div className="pb-[16px] w-full flex items-center gap-3">
                            <div className="h-[32px] w-[32px] rounded-full bg-black">
                                {user?.social?.facebook_pages.length > 0 &&
                                    <img src={user?.social?.facebook_pages[0]?.picture?.data?.url} alt="" className="w-full h-full rounded-full " />
                                }
                            </div>
                            <p className="flex-1 w-full font-semibold ">
                                <span className="text-[14px] ">{user?.social?.facebook_pages[0]?.name}</span>
                                <span className="text-[16px] px-2">·</span>
                                <span className="text-[16px] px-[6px] py-1 rounded-md  text-[#1763cf] ">Follow</span>
                            </p>
                        </div>
                        <div className="pb-[16px] w-full">
                            <p className="line-clamp-1 text-[14px]">
                                {caption}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-[40px] pb-[10px] mr-2">
                    <div className="pb-[16px]">
                        <div className="w-10 aspect-square rounded-full flex items-center justify-center bg-[#4B4C4F]">
                            <i
                                data-visualcompletion="css-img"
                                className="invert"
                                style={{
                                    backgroundImage:
                                        'url("https://static.xx.fbcdn.net/rsrc.php/v3/yd/r/WfXPMghq_2a.png")',
                                    backgroundPosition: "0px -147px",
                                    backgroundSize: "auto",
                                    width: 20,
                                    height: 20,
                                    backgroundRepeat: "no-repeat",
                                    display: "inline-block"
                                }}
                            />
                        </div>
                        <p className="text-[13px] text-center pt-[2px]">33</p>
                    </div>
                    <div className="pb-[16px]">
                        <div className="w-10 aspect-square rounded-full flex items-center justify-center bg-[#4B4C4F]">
                            <i
                                data-visualcompletion="css-img"
                                className="invert"
                                style={{
                                    backgroundImage:
                                        'url("https://static.xx.fbcdn.net/rsrc.php/v3/y1/r/u0K0iysfBw2.png")',
                                    backgroundPosition: "0px -42px",
                                    backgroundSize: "auto",
                                    width: 20,
                                    height: 20,
                                    backgroundRepeat: "no-repeat",
                                    display: "inline-block"
                                }}
                            />

                        </div>
                        <p className="text-[13px] text-center pt-[2px]">33</p>
                    </div>
                    <div className="pb-[16px]">
                        <div className="w-10 aspect-square rounded-full flex items-center justify-center bg-[#4B4C4F]">
                            <i
                                data-visualcompletion="css-img"
                                className="invert"
                                style={{
                                    backgroundImage:
                                        'url("https://static.xx.fbcdn.net/rsrc.php/v3/y1/r/u0K0iysfBw2.png")',
                                    backgroundPosition: "0px -63px",
                                    backgroundSize: "auto",
                                    width: 20,
                                    height: 20,
                                    backgroundRepeat: "no-repeat",
                                    display: "inline-block"
                                }}
                            />

                        </div>
                        <p className="text-[13px] text-center pt-[2px]">33</p>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center w-full h-full rounded">
                {review?.reviewUrl ? (
                    <video playsInline
                        src={review?.reviewUrl}
                        className="object-center w-full h-auto rounded"
                        controls={false}
                    ></video>
                ) : (
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffacebookpreview.png?alt=media&token=ca5406fc-100f-4835-834a-318efffc4e94"
                        alt="preview"
                        className="object-cover w-full h-full"
                    />
                )}
            </div>
        </div>
    )
}
