import {
  CaretLeft,
  CaretRight,
  Checks,
  CircleNotch,
  GoogleLogo,
  Phone,
  ShieldSlash,
  UploadSimple,
} from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ElevatedCard from "../../components/elevatedCard";
import ElevateButton from "../../components/elevateButton";
import ReactStars from "react-rating-stars-component";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { logEngagementHook } from "../../common/functions";
import axios from "axios";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { nanoid } from "nanoid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import Userviewfileupload from "../../components/Userviewfileupload";
import { sendAmplitudeData } from "../../amplitude";
import { showSuccess, showWarning } from "../../components/toast/toast";
import { pushNotification } from "../../common/notificationFunctions";

export default function CampaignUserView() {
  const router = useHistory();
  const { form } = useParams();
  const params = new URLSearchParams(window.location.search);
  const user_id = `user_${nanoid(12)}`;
  const [video_id, setvideo_id] = useState(nanoid(20));
  const step = params.get("step") || "none";
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [userData, setUserData] = useState(null);
  const [device, setDevice] = useState(null);
  const [formData, setFormData] = useState(null);
  const [userRsp, setUserRsp] = useState(null);
  const [product, setProduct] = useState(null);
  const [reviewUrl, setReviewUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [review_type, setReview_type] = useState(null);
  const [review_info, setReview_info] = useState({});
  const [product_attributes, setProduct_attributes] = useState([]);
  const [review_images, setReview_images] = useState([]);
  const [textreview, setTextreview] = useState(null);
  const [phoneLoading, setPhoneLoading] = useState(false);

  let countryCodeRef = useRef(null);
  let phoneNumberRef = useRef(null);
  let otpRef = useRef(null);

  useEffect(() => {
    async function getData() {
      if (params.get("step") && !userData) {
        router.replace(`/userView/${form}`);
      }
      console.log("fetched data");
      const res = await getDoc(doc(db, "forms", form));
      if (!res.exists()) {
        setLoading(false);
        setIsValid(false);
      } else {
        const data = res.data();
        getProduct(data?.prodid);
        setFormData(data);
        setUserRsp({ ...data, id: data.id });
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
          )
        ) {
          setDevice("Mobile");
        } else {
          setDevice("Desktop");
        }
        sendAmplitudeData("campaign link opened", { campaign: form });
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: "mobile",
          action: "open_campaign",
          brand: data.bpsId,
        });
      }
    }
    getData();
  }, [form]);

  async function signInWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const document = {
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
    const userDoc = await createUser(document);
    if (!userDoc) {
      return showWarning("Something went wrong, please try later");
    }
    setUserData(userDoc);
    logEngagementHook({
      campaignId: form,
      date: Date.now(),
      platform: device,
      brand: formData.bpsId,
      action: "campaign_login_success",
    });
    if (formData?.askQuestionBefore) {
      return router.push(`/userView/${form}?step=question`);
    }
    router.push(`/userView/${form}?step=option`);
  }
  async function signInWithPhone() {
    const phone = countryCodeRef.current.value + phoneNumberRef.current.value;
    if (phone.length < 12) {
      return showWarning("Please enter valid phone number");
    }
    setPhoneLoading(true);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth
    );
    let appVerifier = window.recaptchaVerifier;
    try {
      const result = await signInWithPhoneNumber(
        auth,
        `+${phone}`,
        appVerifier
      );
      window.confirmationResult = result;
      setPhoneLoading(false);
      router.push(`/userView/${form}?step=otp`);
    } catch (error) {
      showWarning("Something went wrong while sending otp");
      console.log(error);
    }
  }

  async function verifyOtp() {
    if (otpRef.current.value.length < 6) {
      return showWarning("Please enter valid otp");
    }
    setPhoneLoading(true);
    let confirmationResult = window.confirmationResult;
    const result = await confirmationResult.confirm(otpRef.current.value);
    const user = result.user;
    const document = {
      phoneNumber: user?.phoneNumber,
      displayName: null,
      photoURL: null,
    };
    const userDoc = await createUser(document);
    if (!userDoc) {
      return showWarning("Something went wrong, please try later");
    }
    setPhoneLoading(false);
    setUserData(userDoc);
    if (formData?.askQuestionBefore) {
      return router.push(`/userView/${form}?step=question`);
    }
    router.push(`/userView/${form}?step=option`);
  }
  async function createUser(user) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
      "Content-Type": "application/json",
    };

    let bodyContent = {
      name: user?.displayName?.toLowerCase() ?? "Change User Name",
      id: user_id,
      tag: `@${user_id}`,
      email: user.email,
      phone: user.phoneNumber,
      followers: 0,
      following: 0,
      reviews: 0,
      views: 0,
      flicks: 0,
      joinDate: Date.now(),
      categories: [],
    };

    let reqOptions = {
      url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);
    if (response.data?.success === false) {
      showWarning("Something went wrong");
      return;
    }
    return response?.data?.user;
  }
  async function getProduct(prodid) {
    try {
      let headersList = {
        Accept: "*/*",
        Authorization:
          "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD",
      };

      let reqOptions = {
        url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${prodid}`,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      if (response.data.success === true) {
        setProduct(response.data.product);
        setProduct_attributes(
          Object.keys(response.data.product?.attributes ?? {})
        );
        const initialAttributes = Object.fromEntries(
          product_attributes.map((att) => [att, 0])
        );
        setReview_info({
          ...review_info,
          attributes: initialAttributes,
        });
        setLoading(false);
        setIsValid(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };
  const handleFileUpload = async (e) => {
    const thumbnail_snap = await generateVideoThumbnail(e.target.files[0]);

    const storage = getStorage();
    const storageRef = ref(storage, "formReview/" + video_id + ".jpg");

    uploadString(storageRef, thumbnail_snap, "data_url").then((snapshot) => {
      getDownloadURL(storageRef).then((downloadURL) => {
        setThumbnailUrl(downloadURL);
      });
    });
  };
  async function uploadReview(event) {
    var image = event.target.files[0];
    var type = image.type.split("/")[1];
    const formats = ["mp4", "mkv", "mov", "avi"];

    if (!formats.includes(type)) {
      alert("Please upload a video file");
      return;
    }
    await handleFileUpload(event);
    const storage = getStorage();
    const storageRef = ref(storage, "formReview/" + video_id + ".mp4");
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // console.log(progress)
        setUploadProgress(progress);
      },
      (error) => {
        setUploadProgress(0);
        showWarning("Something went wrong while uploading video");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUserRsp({
            ...userRsp,
            reviewUrl: downloadURL,
          });
          setReviewUrl(downloadURL);
          setUploadProgress(0);
        });
      }
    );
  }
  async function submitQuestion() {
    let answered = 0;
    let not_answered = 0;

    userRsp.questions.forEach((question) => {
      if (question.hasOwnProperty("answer")) {
        answered = answered + 1;
      } else {
        not_answered = not_answered + 1;
      }
    });

    if (not_answered > 0) {
      return showWarning("Please answer to all questions");
    }
    userRsp.answered = true;
    userRsp.substatus = "pending";
    if (formData?.askQuestionBefore) {
      router.push(`/userView/${form}?step=option`);
      return;
    }
    router.push(`/userView/${form}?step=reviewdetails`);
  }
  async function finalSubmit() {
    if (review_type === "video") {
      if (!review_info.review_name) {
        return showWarning("Please fill review title");
      }
    }
    if (!review_info?.review_description) {
      return showWarning("Please fill description");
    }
    if (!review_info.attributes) {
      return showWarning("Please rate all attributes");
    }
    if (review_info.attributes) {
      if (
        Object.keys(review_info?.attributes).length <
        Object.keys(product_attributes).length
      ) {
        return showWarning("Please rate all attributes");
      }
    }
    const attribute_arr = Object.values(review_info?.attributes);
    const mixedSum = (arr = []) => {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        sum += +el;
      }
      return sum;
    };
    const average_rating = (mixedSum(attribute_arr) / 5).toFixed(2);
    review_info.average_rating = average_rating;

    let newdoc = {
      thumbnail: thumbnailUrl,
      review_info: review_info,
      campaign_info: {
        campaign_name: userRsp?.campaign_name,
        productname: userRsp?.productname,
        prodid: userRsp?.prodid,
        catId: userRsp?.catId,
        productimage: userRsp?.productimage,
        subcategory: userRsp?.subcategory,
      },
      user_info: {
        name: userData.name,
        tag: userData.tag,
        token: userData.token ?? null,
        id: userData.id,
        image: userData.imageUrl ?? null,
        email: userData.email ?? null,
        phone: userData.phone ?? null,
      },
      reviewUrl: reviewUrl || null,
      review_type: review_type,
      text_review: textreview,
      questions: userRsp.questions,
      substatus: "pending",
      dateAdded: Date.now(),
      images: review_images,
    };

    let questions = JSON.parse(JSON.stringify(userRsp.questions));
    questions.forEach((quest) => {
      const answer = quest.answer;
      quest[answer] = (quest[answer] || 0) + 1;
      delete quest.answer;
      delete quest.option_choosed;
    });
    let updates = {
      questions: questions,
      submissions: increment(1),
      textReviews: review_type === "text" ? increment(1) : increment(0),
      videoReviews: review_type === "video" ? increment(1) : increment(0),
    };
    await updateDoc(doc(db, "forms/" + userRsp?.id), updates).then((res) =>
      console.log("done")
    );
    addDoc(
      collection(db, "forms/" + userRsp?.id + "/submissions"),
      newdoc
    ).then(() => {
      showSuccess("Submitted successFully");
      logEngagementHook({
        campaignId: form,
        date: Date.now(),
        platform: device,
        brand: formData.bpsId,
        action: "user_campaign_submission",
      });
      pushNotification({
        message: userRsp?.campaign_name + " Campaign has 1 new submission. ",
        endpoint: "/engagement/view-campaign/" + userRsp?.id,
        read: false,
        type: "campaign",
        client: formData.bpsId,
      });
      router.push(`/userView/${form}?step=thank_you`);
    });
  }
  const handleRadioChange = (questionIndex, optionIndex) => {
    setUserRsp((prevUserResponses) => {
      const updatedQuestions = [...prevUserResponses.questions];
      const updatedQuestion = { ...updatedQuestions[questionIndex] };

      // Update the selected option and option index
      updatedQuestion.answer = updatedQuestion[`opt${optionIndex}`];
      updatedQuestion.option_choosed = optionIndex;

      updatedQuestions[questionIndex] = updatedQuestion;

      return {
        ...prevUserResponses,
        questions: updatedQuestions,
      };
    });
  };
  if (loading) {
    return (
      <div className="relative w-full h-full tracking-wide text-white bg-black">
        <div className="p-0 sm:p-5 h-[100dvh] w-full sm:flex sm:justify-center overflow-y-auto">
          <div
            className="bg-[#121212] h-full sm:aspect-[9/16] xl:aspect-[9/18] sm:rounded-xl sm:border-4 border-white relative overflow-y-auto"
            id="zero-scrollbar"
          >
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="flex justify-center">
                    {/* <div className="w-auto px-5 py-2 rounded-md h-14">
                                            <img src={require("../../assets/logo/MyRevue Logo elite new.svg").default} alt="" className="h-full " />
                                        </div> */}
                  </div>
                </div>
                <div className="flex justify-center">
                  <CircleNotch
                    size={32}
                    color="white"
                    className="animate-spin"
                    weight="bold"
                  />
                </div>
                <div className=""></div>
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
  if (!isValid) {
    return (
      <div className="relative w-full h-full tracking-wide text-white bg-black">
        <div className="p-0 sm:p-5 h-[100dvh] w-full sm:flex sm:justify-center overflow-y-auto">
          <div
            className="bg-[#121212] h-full sm:aspect-[9/16] xl:aspect-[9/18] sm:rounded-xl sm:border-4 border-white relative overflow-y-auto"
            id="zero-scrollbar"
          >
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="flex justify-center">
                    <div className="w-auto px-5 py-2 rounded-md h-14">
                      <img
                        src={
                          require("../../assets/logo/MyRevue Logo elite new.svg")
                            .default
                        }
                        alt=""
                        className="h-full "
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="text-xl font-medium text-center ">
                    Link isn't valid
                  </p>
                </div>
                <div className=""></div>
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="relative w-full h-full tracking-wide text-white bg-black">
      <div className="p-0 sm:p-5 h-[100dvh] w-full sm:flex sm:justify-center overflow-y-auto">
        <div
          style={{
            backgroundColor:
              formData?.customizations?.secondaryColor ?? "#121212",
          }}
          className="h-full sm:aspect-[9/16] xl:aspect-[9/18] sm:rounded-xl sm:border-4 border-white relative overflow-y-auto"
          id="zero-scrollbar"
        >
          {step === "none" && (
            <>
              <div className="flex flex-col justify-between w-full h-full px-3 pt-3">
                <div className="">
                  <div className="flex justify-center">
                    <div className="w-auto px-5 py-2 rounded-md h-14">
                      {/* <img src={require("../../assets/logo/MyRevue Logo elite new.svg").default} alt="" className="h-full " /> */}
                      <img
                        src={formData?.LogoLink}
                        alt=""
                        className="h-full "
                      />
                    </div>
                  </div>
                  <div className="w-full mt-5">
                    <p className="text-xl font-medium leading-tight text-center">
                      {formData?.welcomeMessage}
                    </p>
                  </div>
                  <div className="mt-5">
                    <ElevatedCard>
                      <div className="flex flex-col items-center gap-3 -mx-4 -my-4">
                        <div className="flex items-center justify-center w-1/2 bg-black aspect-square">
                          <img
                            src={product?.imageUrl}
                            alt="product_image"
                            className="object-cover w-full h-full"
                          />
                        </div>
                        <div className="flex flex-col items-center flex-1 w-full pb-3">
                          <p className="text-[15px] leading-tight text-center font-medium">
                            {product?.name}
                          </p>
                        </div>
                      </div>
                    </ElevatedCard>
                  </div>
                  <div className="mt-5">
                    <ElevatedCard>
                      <div className="flex items-center gap-3 -mx-4 -my-4">
                        <div className="flex items-center justify-center w-12 h-12 bg-black">
                          {formData?.spokeLink ? (
                            <img
                              src={formData?.spokeLink}
                              alt=""
                              className="object-cover w-12 h-12 mx-auto my-2"
                            />
                          ) : (
                            <div className="flex items-center justify-center w-12 h-12 text-lg text-white bg-black">
                              {formData?.name
                                ?.split(" ")[0]
                                .substring(0, 2)
                                .toUpperCase()}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <p className="text-sm leading-tight">
                            {formData?.name}
                          </p>
                          <p className="text-xs leading-tight">
                            {formData?.designation}
                          </p>
                        </div>
                      </div>
                    </ElevatedCard>
                  </div>
                  {formData?.incentive && (
                    <div className="mt-5">
                      <ElevatedCard>
                        <div className="flex items-center gap-3 px-2 py-1 -mx-4 -my-4">
                          <p className="text-sm font-medium first-letter:uppercase">
                            {formData?.incentive?.type} :{" "}
                          </p>
                          <div className="">
                            {formData?.incentive?.type === "cashback" && (
                              <>
                                <span className="text-base">
                                  {formData?.incentive?.cashback?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )}
                                </span>
                              </>
                            )}
                            {formData?.incentive?.type === "discount" && (
                              <>
                                <span className="text-base">
                                  {formData?.incentive?.discount}% discount
                                </span>
                              </>
                            )}
                            {formData?.incentive?.type === "freeProduct" && (
                              <>
                                <span className="text-base">
                                  {formData?.incentive?.freeProduct}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </ElevatedCard>
                    </div>
                  )}
                </div>
                <div className="">
                  <div className="">
                    <p className="text-[14px] font-medium">How it Works</p>
                    <p className="text-[12px] leading-tight">
                      Tell your story by recording a short video clip here.
                      Nothing to download or install.
                    </p>
                  </div>
                  {/* <button className="w-full h-10 mt-5 bg-white rounded-full " onClick={() => router.push(`/userView/${form}?step=signin`)}>
                                        <p className="text-black" >
                                            Continue
                                        </p>
                                    </button> */}
                  <div className="relative w-full mt-2">
                    <ElevateButton
                      buttonActiveColor={formData?.customizations?.primaryColor}
                      onClick={() =>
                        router.push(`/userView/${form}?step=signin`)
                      }
                    >
                      <span className="">Let's Go</span>
                    </ElevateButton>
                  </div>
                  <div className="flex items-center justify-center gap-2">
                    <span className="text-xs">Powered by</span>
                    <img
                      src={
                        require("../../assets/logo/MyRevue Logo elite new.svg")
                          .default
                      }
                      alt=""
                      className="h-8"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {step === "signin" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="space-y-3">
                  <button className="mt-3" onClick={() => router.goBack()}>
                    <CaretLeft
                      size={22}
                      color="white"
                      weight="bold"
                      className="hover:opacity-40"
                    />
                  </button>
                  <div className="">
                    <p className="text-[20px] text-center font-semibold ">
                      Choose a method to sign in
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <ElevateButton
                    buttonActiveColor={formData?.customizations?.primaryColor}
                    onClick={() => router.push(`/userView/${form}?step=phone`)}
                  >
                    <Phone size={22} color="currentcolor" />
                    <span className="">Sign in with Phone</span>
                    <span className=""></span>
                  </ElevateButton>
                  <ElevateButton
                    buttonActiveColor={formData?.customizations?.primaryColor}
                    onClick={() => signInWithGoogle()}
                  >
                    <div className="flex items-center justify-center gap-5 group">
                      <GoogleLogo
                        size={22}
                        color="currentcolor"
                        weight="bold"
                        className="hidden group-hover:block"
                      />
                      <img
                        src="https://freesvg.org/img/1534129544.png"
                        alt=""
                        className="h-[22px] w-[22px] block group-hover:hidden"
                      />
                      <span className=""> Sign in with Google</span>
                      <span className=""></span>
                    </div>
                  </ElevateButton>
                </div>
              </div>
            </>
          )}
          {step === "phone" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="space-y-3">
                  <button className="" onClick={() => router.goBack()}>
                    <CaretLeft
                      size={22}
                      color="white"
                      weight="bold"
                      className="hover:opacity-40"
                    />
                  </button>
                  <div className="">
                    <p className="text-[20px] text-center  font-semibold ">
                      What's your number?
                    </p>
                    {/* <p className="text-[14px] leading-[18px] mt-2">Phone number is required to verify your identity</p> */}
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center">
                    <div className="w-fit">
                      <p className="text-[12px] font-medium">Country</p>
                      <input
                        type="text"
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const limitedNumericValue = numericValue.slice(0, 3);
                          countryCodeRef.current.value = limitedNumericValue;
                        }}
                        className="bg-white text-black w-16 py-[5px] px-[10px]  font-medium appearance-none outline-none"
                        maxLength={3}
                        ref={countryCodeRef}
                        defaultValue={91}
                      />
                    </div>
                    <div className="flex-1 ml-5">
                      <p className="text-[12px]  font-medium">Phone number</p>
                      <input
                        type="text"
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const limitedNumericValue = numericValue.slice(0, 10);
                          phoneNumberRef.current.value = limitedNumericValue;
                        }}
                        className="bg-white text-black w-full py-[5px] px-[10px]  font-medium appearance-none outline-none"
                        maxLength={10}
                        ref={phoneNumberRef}
                      />
                    </div>
                    <div className="" id="sign-in-button"></div>
                  </div>
                  <div className="flex items-center justify-between gap-5 mt-3">
                    <p className="text-[12px] flex-1 font-normal">
                      We will send an otp to this number to verify
                    </p>
                    <button
                      onClick={() => signInWithPhone()}
                      className="h-10 w-10 rounded-full bg-[#ffffff] hover:bg-[#8A8A8A] flex items-center justify-center"
                    >
                      {phoneLoading ? (
                        <CircleNotch
                          size={17}
                          color="black"
                          weight="bold"
                          className="animate-spin"
                        />
                      ) : (
                        <CaretRight size={17} color="black" weight="bold" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {step === "otp" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="space-y-3">
                  <button className="" onClick={() => router.goBack()}>
                    <CaretLeft
                      size={22}
                      color="white"
                      weight="bold"
                      className="hover:opacity-40"
                    />
                  </button>
                  <div className="">
                    <p className="text-[20px] text-center font-semibold ">
                      OTP?
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center">
                    <div className="flex-1">
                      <p className="text-[12px]  font-medium">OTP</p>
                      <input
                        type="text"
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const limitedNumericValue = numericValue.slice(0, 10);
                          otpRef.current.value = limitedNumericValue;
                        }}
                        className="bg-white text-black w-full py-[5px] px-[10px] font-medium appearance-none outline-none"
                        maxLength={6}
                        ref={otpRef}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-5 mt-3">
                    <p className="text-[12px] flex-1 font-normal">
                      We have sent an otp on your phone number for verification
                    </p>
                    <button
                      onClick={() => verifyOtp()}
                      className="h-10 w-10 rounded-full bg-[#ffffff] hover:bg-[#8A8A8A] flex items-center justify-center"
                    >
                      {phoneLoading ? (
                        <CircleNotch
                          size={17}
                          color="black"
                          weight="bold"
                          className="animate-spin"
                        />
                      ) : (
                        <CaretRight size={17} color="black" weight="bold" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {step === "option" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="space-y-3">
                  <button className="mt-3" onClick={() => router.goBack()}>
                    <CaretLeft
                      size={22}
                      color="white"
                      weight="bold"
                      className="hover:opacity-40"
                    />
                  </button>
                  <div className="">
                    <p className="text-[20px] text-center font-semibold ">
                      How would you like to respond ?
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <ElevateButton
                    buttonActiveColor={formData?.customizations?.primaryColor}
                    onClick={() => {
                      setReview_type("text");
                      router.push(`/userView/${form}?step=question`);
                    }}
                  >
                    <span className="">Write a review</span>
                  </ElevateButton>
                  <ElevateButton
                    buttonActiveColor={formData?.customizations?.primaryColor}
                    onClick={() => {
                      setReview_type("video");
                      router.push(`/userView/${form}?step=upload`);
                    }}
                  >
                    <span className="">Upload/Record a video</span>
                  </ElevateButton>
                </div>
              </div>
            </>
          )}
          {step === "question" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="space-y-3">
                    <button className="mt-3" onClick={() => router.goBack()}>
                      <CaretLeft
                        size={22}
                        color="white"
                        weight="bold"
                        className="hover:opacity-40"
                      />
                    </button>
                  </div>
                  <div className="flex-1 my-5 space-y-3">
                    {userRsp?.questions?.map((quest, index) => (
                      <ElevatedCard key={index}>
                        <p className="mb-2 text-sm font-medium leading-tight">
                          {quest.id}: {quest.question}
                        </p>
                        {[1, 2, 3].map(
                          (optionIndex) =>
                            quest[`opt${optionIndex}`] && (
                              <div
                                className="flex items-center my-1"
                                key={optionIndex}
                              >
                                <input
                                  type="radio"
                                  className="!border-[#2d2d2d] customRadio"
                                  id={`opt${optionIndex}${index}`}
                                  name={quest.id}
                                  onChange={() =>
                                    handleRadioChange(index, optionIndex)
                                  }
                                  value={quest[`opt${optionIndex}`]}
                                  defaultChecked={
                                    quest.option_choosed === optionIndex
                                  }
                                />
                                <label
                                  className="flex-1 w-full pl-2 text-sm"
                                  htmlFor={`opt${optionIndex}${index}`}
                                >
                                  {quest[`opt${optionIndex}`]}
                                </label>
                              </div>
                            )
                        )}
                      </ElevatedCard>
                    ))}
                  </div>
                </div>
                <div className="pb-5 space-y-2">
                  <ElevateButton
                    buttonActiveColor={formData?.customizations?.primaryColor}
                    onClick={() => submitQuestion()}
                  >
                    <span className="">Continue</span>
                  </ElevateButton>
                </div>
              </div>
            </>
          )}
          {step === "upload" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="space-y-3">
                    <button className="mt-3" onClick={() => router.goBack()}>
                      <CaretLeft
                        size={22}
                        color="white"
                        weight="bold"
                        className="hover:opacity-40"
                      />
                    </button>
                  </div>
                  {!reviewUrl && (
                    <div className="flex-1 my-5 space-y-3">
                      {uploadProgress > 0 && uploadProgress < 100 ? (
                        <>
                          <p className="text-lg font-medium">
                            Great. Uploading your video, please wait for a
                            moment.
                          </p>
                          <ElevatedCard>
                            <div className="flex flex-col items-center justify-center w-full h-32">
                              <UploadSimple
                                size={22}
                                color="#121212"
                                className="animate-bounce"
                              />
                              <p className="">Uploading {uploadProgress}%</p>
                            </div>
                          </ElevatedCard>
                        </>
                      ) : (
                        <div className="">
                          <p className="text-lg font-medium">
                            Points to cover in this video
                          </p>

                          <ol>
                            {formData?.points?.map((point, index) => (
                              <li
                                key={index}
                                className="flex items-start gap-2 py-1 "
                              >
                                <div className="mt-1 mark"></div>
                                <p className="flex-1 text-sm leading-tight">
                                  {point?.question}
                                </p>
                              </li>
                            ))}
                          </ol>
                        </div>
                      )}
                    </div>
                  )}
                  {reviewUrl && (
                    <div className="mt-3">
                      <ElevatedCard>
                        <div className="bg-white aspect-[9/16] rounded-md">
                          <video
                            playsInline
                            src={reviewUrl}
                            muted
                            controls
                            controlsList="nodownload noplaybackrate"
                            disablePictureInPicture
                          ></video>
                        </div>
                      </ElevatedCard>
                    </div>
                  )}
                </div>
                <div className="pb-3 mt-3 space-y-2">
                  {reviewUrl && (
                    <>
                      <ElevateButton
                        buttonActiveColor={
                          formData?.customizations?.primaryColor
                        }
                        onClick={() =>
                          formData?.askQuestionBefore
                            ? router.push(
                                `/userView/${form}?step=reviewdetails`
                              )
                            : router.push(`/userView/${form}?step=question`)
                        }
                      >
                        <span className="">Continue</span>
                      </ElevateButton>
                      <ElevateButton
                        buttonActiveColor={
                          formData?.customizations?.primaryColor
                        }
                      >
                        <div className="relative flex items-center justify-center w-full h-full">
                          <input
                            type="file"
                            accept=".mp4, .mkv, .avi"
                            onChange={(event) => uploadReview(event)}
                            className="absolute w-full h-full opacity-0"
                          />
                          <span className="">Re-upload</span>
                        </div>
                      </ElevateButton>
                    </>
                  )}
                  {!reviewUrl && (
                    <div className="">
                      {uploadProgress > 0 && uploadProgress < 100 ? null : (
                        <ElevateButton
                          buttonActiveColor={
                            formData?.customizations?.primaryColor
                          }
                        >
                          <div className="relative flex items-center justify-center w-full h-full">
                            <input
                              type="file"
                              accept=".mp4, .mkv, .avi"
                              onChange={(event) => uploadReview(event)}
                              className="absolute w-full h-full opacity-0"
                            />
                            <span className="">Upload Video</span>
                          </div>
                        </ElevateButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {step === "reviewdetails" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="space-y-3">
                    <button className="mt-3" onClick={() => router.goBack()}>
                      <CaretLeft
                        size={22}
                        color="white"
                        weight="bold"
                        className="hover:opacity-40"
                      />
                    </button>
                  </div>
                  <div className="flex-1 my-5 space-y-3">
                    <div className="font-medium tracking-wide">
                      Please enter review details
                    </div>
                    <div className="w-full space-y-2">
                      {review_type === "video" && (
                        <div className="w-full">
                          <p className="text-[12px] font-normal tracking-wide">
                            Review title <span className="text-red-400">*</span>
                          </p>
                          <input
                            type="text"
                            className="bg-white  text-black w-full h-10 text-sm py-[5px] px-[10px] font-medium appearance-none outline-none placeholder:font-normal placeholder:text-sm"
                            placeholder="Enter title of review"
                            value={review_info?.review_name}
                            onChange={(event) =>
                              setReview_info({
                                ...review_info,
                                review_name: event.target.value,
                              })
                            }
                            maxLength={30}
                          />
                        </div>
                      )}
                      <div className="w-full">
                        <p className="text-[12px] font-normal tracking-wide">
                          Review {review_type === "video" && "description"}{" "}
                          <span className="text-[#FF005C]">*</span>
                        </p>
                        <textarea
                          type="text"
                          className="bg-white text-black w-full h-24 text-sm py-[5px] px-[10px] font-medium appearance-none outline-none placeholder:font-normal placeholder:text-sm"
                          value={review_info?.review_description}
                          onChange={(event) =>
                            setReview_info({
                              ...review_info,
                              review_description: event.target.value,
                            })
                          }
                          placeholder={
                            review_type === "text"
                              ? "write a review"
                              : "Enter review description min 50 characters"
                          }
                        />
                      </div>
                      <div className="">
                        <p className="text-sm font-medium text-center">
                          How would you rate this product
                        </p>
                        <p className="mt-2 text-xs font-normal text-center">
                          Rate this out of 5 stars{" "}
                          <span className="text-[#FF005C]">*</span>
                        </p>
                        <div className="grid w-full grid-cols-2 mt-2 gap-x-5 gap-y-2">
                          {product_attributes?.map((att, index) => (
                            <div
                              className="flex justify-center w-full"
                              key={index}
                            >
                              <div className="flex flex-col items-center w-fit">
                                <p className="text-[13px] -mb-1 ">{att}</p>
                                <ReactStars
                                  size={25}
                                  count={5}
                                  isHalf={false}
                                  value={review_info?.attributes[att] ?? 0}
                                  color="white"
                                  activeColor="#FF005C"
                                  onChange={(newValue) =>
                                    setReview_info({
                                      ...review_info,
                                      attributes: {
                                        ...review_info.attributes,
                                        [att]: newValue,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {review_type === "text" && (
                        <div className="mt-5">
                          <Userviewfileupload
                            setimages={setReview_images}
                            images={review_images}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pb-5 space-y-2">
                  <ElevateButton
                    buttonActiveColor={formData?.customizations?.primaryColor}
                    onClick={() => finalSubmit()}
                  >
                    <span className="">Submit</span>
                  </ElevateButton>
                </div>
              </div>
            </>
          )}
          {step === "thank_you" && (
            <>
              <div className="flex flex-col justify-between w-full h-full p-3">
                <div className="">
                  <div className="flex-1 space-y-3">
                    <div className="mt-10">
                      <p className="text-2xl font-medium text-center first-letter:uppercase">
                        {formData?.headLine}
                      </p>
                      <p className="text-sm text-center first-letter:uppercase">
                        {formData?.thankYouMessageRef}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <ElevatedCard>
                    <div className="flex flex-col items-center">
                      <p className="font-sans tracking-widest text-black ">
                        Powered By
                      </p>
                      <a
                        href="https://brand.myrevue.app"
                        target="_blank"
                        className=""
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMyRevue%20Logo%20(1)%201.svg?alt=media&amp;token=f3878494-f182-43c4-bbfb-6d1f7247af27"
                          alt="logo"
                          className="h-14"
                        />
                      </a>
                      <p className="text-center text-black">
                        Tech Enabled
                        <span className="font-semibold">
                          {" "}
                          Content As A Service (CAAS) Platform{" "}
                        </span>
                        For BHARAT Brands
                      </p>
                      <span className="font-semibold text-[13px] text-[#FF005C]">
                        {" "}
                        AWARE. ENGAGE. RETAIN
                      </span>
                    </div>
                  </ElevatedCard>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
