import AWN from "awesome-notifications";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { db } from "../firebase";
import { sendAmplitudeData } from "../amplitude";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { nanoid } from "nanoid";
import { CircleNotch } from "@phosphor-icons/react";
import { showWarning } from "../components/toast/toast";

function MasterLogin() {
  const [{ user }, dispatch] = useStateValue();
  const [newUserId, setNewUserId] = useState(nanoid(12));
  const [loginLoading, setLoginLoading] = useState(false);

  useEffect(() => {
    sendAmplitudeData("login page opened");
    var title = `Login - MyRevue`;
    var metaTitle = `Login - MyRevue`;
    var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var metaKeyword = `Brands - MyRevue, video testimonials software ,best video testimonial software, video testimonials, get video testimonials, collect video testimonials, free video testimonial software, video testimonials app, video customer testimonials, video testimonial platform, video testimonial tool`;

    var ogUrl = `https://brand.myrevue.app/login`;
    var ogtype = `website`;
    var ogtitle = `Login - MyRevue`;
    var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/login`;
    var twittertitle = `Login - MyRevue`;
    var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, []);

  let brandIdref = useRef();
  let emailRef = useRef();
  const router = useHistory();

  const changeBrandId = async (brandId, email) => {
    setLoginLoading(true)
    const adminQuery = query(collection(db, "brandAdmin"), where("email", "==", email), where("brandId", "==", brandId));
    const brandAdminDocs = await getDocs(adminQuery);
    if (brandAdminDocs.docs.length === 0) {
      setLoginLoading(false)
      showWarning("No account found with this brand");
      return
    }
    const brandAdmin = brandAdminDocs.docs[0].data()
    // const getbrandadmin = await getDoc(doc(db, "brandAdmin", emailRef.current.value.trim()));
    // if (!getbrandadmin.exists) {
    //   return showWarning("No account found with this brand");
    // }
    let headersList = {
      Accept: "*/*",
      Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
    };

    let reqOptions = {
      url: `https://brandapi-zscu3untuq-el.a.run.app/getBrand/${brandAdmin.brandId}`,
      method: "GET",
      headers: headersList,
    };

    let getbrand = await axios.request(reqOptions).then((res) => res?.data?.brand).catch((err) => console.log(err));

    if (!brandAdmin.appUserId) {
      const appUserId = await GetUserId(brandAdmin);
      brandAdmin.appUserId = appUserId;
    }
    if (!brandAdmin?.limit) {
      const result = await updateLimit(brandAdmin);
      brandAdmin.limit = result;
    }
    if (getbrand && brandAdmin) {
      sessionStorage.setItem("brand", JSON.stringify(getbrand));
      dispatch({ type: "SET_BRAND", brand: getbrand });
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...brandAdmin, access: "admin" })
      );
      dispatch({
        type: "SET_USER",
        user: { ...brandAdmin, access: "admin" },
      });
      setLoginLoading(false)
      router.replace("/admin");
    } else {
      setLoginLoading(false)
      return showWarning("No brand found");
    }
  };
  async function updateLimit(data) {
    try {
      const limitDoc = {
        campaignLimit: data?.campaignLimit ?? 1,
        commentLimit: data?.commentLimit ?? 0,
        downloadLimit: data?.downloadLimit ?? 3,
        faqLimit: 1,
        galleryLimit: 1,
        challangeLimit: 1,
        quizLimit: 1
      }
      await updateDoc(doc(db, "brandAdmin", data.email), { limit: limitDoc });
      return limitDoc
    } catch (error) {
      console.log(error);
      return null
    }
  }

  async function GetUserId(data) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
      "Content-Type": "application/json",
    };

    let bodyContent = {
      rating: 3,
      followers: 0,
      following: 0,
      reviews: 0,
      views: 0,
      flicks: 0,
      languages: ["English"],
      name: data?.companyName ?? data?.name,
      categories: ["cat_kdik4uvKPxQPc1iG8Nmn4S"],
      refCode: null,
      weight: -1,
      rank: null,
      socialMedia: null,
      token: null,
      qrCodeUrl: null,
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fdefault_dp.png?alt=media&token=12a37164-51dc-48a8-9bf3-f008fa6a5113",
      refBy: null,
      joinDate: Date.now(),
      about: "",
      dob: null,
      verified: null,
      address: null,
      favorite: null,
      savedReviews: null,
      phone: null,
      id: "user_" + newUserId,
      lastLogin: Date.now(),
      email: data?.email,
      achievement: null,
      totalReviews: 0,
      gender: "male",
      tag: "@user_" + newUserId,
      coins: 0,
      totalCoins: 0,
    };

    let reqOptions = {
      url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);

    if (response.data?.newUser === false) {
      if (!data?.appUserId) {
        await updateDoc(doc(db, "brandAdmin", data.email), {
          appUserId: response.data.user.id,
        });
        return response.data.user?.id;
      }
    }
    if (response.data?.newUser === true) {
      await updateDoc(doc(db, "brandAdmin", data.email), {
        appUserId: response.data.user.id,
      });
      return response.data.user?.id;
    }
  }
  if (user?.email !== "myrevueappofficial@gmail.com") {
    return router.replace("/login")
  }

  async function validateLogin(event) {
    event.preventDefault();
    let brandId = brandIdref.current.value.trim();
    let email = emailRef.current.value.trim();
    brandId = brandId.trim();
    email = email.trim();
    if (!brandId || !email) {
      return showWarning("Please enter all details");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setLoginLoading(false)
      showWarning("Please enter a valid email address");
      return
    }
    changeBrandId(brandId, email)
  }
  return (
    <div className="bg-[#1A1A1A] h-screen">
      <div className="grid w-full h-full lg:grid-cols-2">
        <div className="relative items-center justify-center hidden w-full h-full lg:flex">
          <img
            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
            alt="logo"
            className="h-[50px]  transform scale-150 absolute left-24 top-10"
          />
          <video playsInline
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Flogin.mp4?alt=media&token=52fec1d6-7ce1-4ff8-9bf8-0e12640e053e"
            className="max-h-[380px]"
            loop
            muted
            autoPlay
          ></video>
        </div>
        <div className="h-full w-full bg-[#2B2F37] rounded-l-xl flex lg:items-center justify-center p-5">
          <div className="w-full md:w-96">
            <img
              src={require("../assets/logo/MyRevue Logo elite new.svg").default}
              alt="logo"
              className="h-[60px]   mx-auto mb-20 lg:hidden"
            />
            <p className="text-4xl">Brand Login </p>
            <form onSubmit={validateLogin}>
              <div className="w-full mt-5 ">
                <input
                  type="text"
                  ref={brandIdref}
                  className="bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Brand ID"
                />
              </div>
              <div className="w-full mt-5">
                <input
                  type="text"
                  ref={emailRef}
                  className="bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Brand Email Address"
                />
              </div>
              <div className="w-full mt-3">
                <button
                  onClick={validateLogin}
                  className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md flex justify-center items-center"
                >
                  {loginLoading ?
                    <CircleNotch size={25} weight="bold" className="animate-spin" />
                    :
                    "Log in"
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterLogin;
