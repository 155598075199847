import { CaretDown, FunnelSimple, MagnifyingGlass, ShieldSlash, ShoppingCart, SpeakerSimpleHigh, SpeakerSimpleSlash } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotloginfooterNew from "../layouts/NotloginfooterNew";
import { useHistory } from "react-router-dom";
import { nanoid, urlAlphabet } from "nanoid";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import StockVideoComponent from "../components/stockvideo";
import StockVideoModal from "../components/stockVideoModal";
import ReactVisibilitySensor from "react-visibility-sensor";
import { showWarning } from "../components/toast/toast";

export default function StockVideos() {
    const router = useHistory();
    const [items, setItems] = useState(JSON.parse(sessionStorage.getItem("stock")) || []);
    const urlParams = new URLSearchParams(window.location.search);
    const [searchText, setSearchText] = useState("");
    const [selectTab, setSelectTab] = useState(null);
    const [genderFilters, setGenderFilters] = useState([]);
    const [ageFilters, setAgeFilters] = useState([]);
    const [ethnicityFilters, setEthnicityFilters] = useState([]);
    const [videoFilters, setVideoFilters] = useState([]);
    const [isMute, setIsMute] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        var title = `Ready-to-use UGC videos. In seconds!`;
        var metaTitle = `Ready-to-use UGC videos. In seconds!`;
        var metaDescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var metaKeyword = `Brands - MyRevue, UGC Video, MyRevue Elite, Billo , GoTolStoy, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

        var ogUrl = `https://brand.myrevue.app/stock/videos`;
        var ogtype = `website`;
        var ogtitle = `Ready-to-use UGC videos. In seconds!`;
        var ogdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock.png?alt=media&token=b57398ab-7e39-4110-a395-797e527d54d5`;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/stock/videos`;
        var twittertitle = `Ready-to-use UGC videos. In seconds!`;
        var twitterdescription = `MyRevue’s UGC stock videos are ideal for brands looking to experiment & test their content: explore a variety of ad hooks, b-rolls, angles, faces, CTAs and so much more! 👇`;
        var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fstock.png?alt=media&token=b57398ab-7e39-4110-a395-797e527d54d5`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
            metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
            ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
            twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
            twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
    }, []);
    async function getVideos() {
        let headersList = {
            "Accept": "*/*",
        }
        let params = {}
        console.log(urlParams.get('q'))
        if (urlParams.get('q') !== undefined) {
            params.title = urlParams.get('q')
        }
        if (urlParams.get('tag') !== undefined) {
            params.tag = urlParams.get('tag')
        }
        let reqOptions = {
            url: "https://stripeugcapi-zscu3untuq-as.a.run.app/videos?page=" + currentPage,
            method: "GET",
            headers: headersList,
            params: params
        }
        console.log(reqOptions)

        let response = await axios.request(reqOptions);
        console.log(response.data)
        if (response.data.success) {
            setVideos(prevdata => [...prevdata, ...response.data.data]);
            setCurrentPage(currentPage => currentPage + 1)
        }
        else {
            showWarning("Something went wrong while fetching videos.")
            return
        }
    }


    async function changeGenderFilter(value) {
        if (genderFilters.includes(value)) {
            setGenderFilters(genderFilters.filter((item) => item !== value));
        } else {
            setGenderFilters([...genderFilters, value]);
        }
    }
    async function changeAgeFilter(value) {
        if (ageFilters.includes(value)) {
            setAgeFilters(ageFilters.filter((item) => item !== value));
        } else {
            setAgeFilters([...ageFilters, value]);
        }
    }
    async function changeEthnicityFilter(value) {
        if (ethnicityFilters.includes(value)) {
            setEthnicityFilters(ethnicityFilters.filter((item) => item !== value));
        } else {
            setEthnicityFilters([...ethnicityFilters, value]);
        }
    }
    async function changeVideoFilter(value) {
        if (videoFilters.includes(value)) {
            setVideoFilters(videoFilters.filter((item) => item !== value));
        } else {
            setVideoFilters([...videoFilters, value]);
        }
    }

    async function addToCart(docuemnt) {
        const existingCart = JSON.parse(sessionStorage.getItem('stock')) || [];
        existingCart.push(docuemnt);
        setItems(existingCart)
        sessionStorage.setItem('stock', JSON.stringify(existingCart));
    }

    const handleVisibilityChange = (isVisible) => {
        if (isVisible) {
            getVideos();
        }
    };

    return (
        <div className="min-h-screen bg-[#101010] w-full">
            {/* {showModal &&
                <StockVideoModal close={setShowModal} />
            } */}
            <div className="h-20 w-full flex items-center justify-between px-6 shadow-md sticky top-0 z-[1000] bg-[#171717]">
                <div className="flex items-center gap-5">
                    <Link to="/">
                        <img
                            loading="lazy"
                            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
                            alt="logo"
                            className="h-[40px] md:h-[62px] w-auto"
                        />
                    </Link>
                    <div className="hidden md:relative w-56 h-[45px] bg-[#2b2b2b] rounded border border-[#575757]">
                        <div className="absolute h-full w-[47px] flex items-center justify-center">
                            <MagnifyingGlass size={22} color="white" />
                        </div>
                        <input
                            type="text"
                            value={searchText}
                            onChange={(event) => setSearchText(event.target.value)}
                            className="h-full w-full tracking-wide pl-[47px] bg-transparent appearance-none outline-none placeholder:text-[#808080] pr-3"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="flex items-center gap-5">
                    <Link to="/stock/cart?step=1">
                        <button className="py-2 px-5 font-semibold flex items-center gap-5 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
                            <ShoppingCart size={22} color="white" />
                            {items.length}
                        </button>
                    </Link>
                    <Link to="/login">
                        <button className="py-2 px-5 hidden md:flex items-center gap-5 rounded-full hover:bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] ">
                            Sign in
                        </button>
                    </Link>
                </div>
            </div>
            <div className="max-w-[1352px] mx-auto relative pt-[30px] md:pb-[50px] px-[30px]">
                <div className="md:hidden relative w-full mb-5 h-[50px] bg-[#2b2b2b] rounded-full border border-[#575757]">
                    <div className="absolute h-full w-[47px] flex items-center justify-center">
                        <MagnifyingGlass size={22} color="white" />
                    </div>
                    <input
                        type="text"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        className="h-full w-full tracking-wide pl-[47px] bg-transparent appearance-none outline-none placeholder:text-[#808080] pr-3"
                        placeholder="Search"
                    />
                </div>
                <div
                    onClick={() => setShowFilter(!showFilter)}
                    className="md:hidden relative flex items-center justify-center gap-5 w-full mb-5 h-[50px]  rounded-full border border-[#575757]">
                    <FunnelSimple size={22} color="white" />
                    <span>Filters</span>
                </div>
                <div className={`${showFilter ? "flex" : "hidden"} md:flex items-center gap-3 flex-wrap w-full`}>
                    <div className="relative h-full">
                        <button
                            onClick={() => setSelectTab((prevtab) => (prevtab === 1 ? null : 1))}
                            className="flex items-center gap-[1em] bg-[#2b2b2b] text-[1rem] leading-6  border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                            type="button">
                            <span>Gender</span>
                            <CaretDown size={22} color="white" className={`${selectTab === 1 ? "transform rotate-180 transition-all ease-linear duration-200" : "transform rotate-0 transition-all ease-linear duration-200"}`} />
                        </button>
                        {selectTab === 1 && (
                            <div className="absolute top-12 z-50 space-y-3  w-60 !p-3 bg-[#2b2b2b] border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md">
                                {["male", "female", "all"].map((item, index) => (
                                    <div className="flex items-center" key={index}>
                                        <input
                                            type="checkbox"
                                            className="defaultCheckbox"
                                            checked={genderFilters.includes(item)}
                                            id={`gender-${index}`}
                                            onChange={(event) => changeGenderFilter(item)}
                                        />
                                        <label htmlFor={`gender-${index}`} className="first-letter:uppercase">
                                            {item}
                                        </label>
                                    </div>
                                ))}
                                <button
                                    onClick={() => setGenderFilters([])}
                                    className="mt-3 flex items-center justify-center text-sm tracking-wide gap-[1em] bg-[#171717] w-full   border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                                    type="button">
                                    Clear All
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="relative h-full">
                        <button
                            onClick={() => setSelectTab((prevtab) => (prevtab === 2 ? null : 2))}
                            className="flex items-center gap-[1em] bg-[#2b2b2b] text-[1rem] leading-6  border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                            type="button">
                            <span>Age</span>
                            <CaretDown size={22} color="white" className={`${selectTab === 2 ? "transform rotate-180 transition-all ease-linear duration-200" : "transform rotate-0 transition-all ease-linear duration-200"}`} />
                        </button>
                        {selectTab === 2 && (
                            <div className="absolute top-12 z-50 space-y-3  w-60 !p-3 bg-[#2b2b2b] border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md">
                                {["18-24", "25-34", "35-44", "45+"].map((item, index) => (
                                    <div className="flex items-center" key={index}>
                                        <input
                                            type="checkbox"
                                            className="defaultCheckbox"
                                            checked={ageFilters.includes(item)}
                                            id={`age-${index}`}
                                            onChange={(event) => changeAgeFilter(item)}
                                        />
                                        <label htmlFor={`age-${index}`} className="first-letter:uppercase">
                                            {item}
                                        </label>
                                    </div>
                                ))}
                                <button
                                    onClick={() => setAgeFilters([])}
                                    className="mt-3 flex items-center justify-center text-sm tracking-wide gap-[1em] bg-[#171717] w-full   border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                                    type="button">
                                    Clear All
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="relative h-full">
                        <button
                            onClick={() => setSelectTab((prevtab) => (prevtab === 3 ? null : 3))}
                            className="flex items-center gap-[1em] bg-[#2b2b2b] text-[1rem] leading-6  border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                            type="button">
                            <span>Ethnicity</span>
                            <CaretDown size={22} color="white" className={`${selectTab === 3 ? "transform rotate-180 transition-all ease-linear duration-200" : "transform rotate-0 transition-all ease-linear duration-200"}`} />
                        </button>
                        {selectTab === 3 && (
                            <div className="absolute top-12 z-50 space-y-3  w-60 !p-3 bg-[#2b2b2b] border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md">
                                {["asian", "black", "white", "multiracial", "latino"].map((item, index) => (
                                    <div className="flex items-center" key={index}>
                                        <input
                                            type="checkbox"
                                            className="defaultCheckbox"
                                            checked={ethnicityFilters.includes(item)}
                                            id={`ethnicity-${index}`}
                                            onChange={(event) => changeEthnicityFilter(item)}
                                        />
                                        <label htmlFor={`ethnicity-${index}`} className="first-letter:uppercase">
                                            {item}
                                        </label>
                                    </div>
                                ))}
                                <button
                                    onClick={() => setEthnicityFilters([])}
                                    className="mt-3 flex items-center justify-center text-sm tracking-wide gap-[1em] bg-[#171717] w-full   border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                                    type="button">
                                    Clear All
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="relative h-full">
                        <button
                            onClick={() => setSelectTab((prevtab) => (prevtab === 4 ? null : 4))}
                            className="flex items-center gap-[1em] bg-[#2b2b2b] text-[1rem] leading-6  border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                            type="button">
                            <span>Video Type</span>
                            <CaretDown size={22} color="white" className={`${selectTab === 4 ? "transform rotate-180 transition-all ease-linear duration-200" : "transform rotate-0 transition-all ease-linear duration-200"}`} />
                        </button>
                        {selectTab === 4 && (
                            <div className="absolute top-12 z-50 space-y-3  w-60 !p-3 bg-[#2b2b2b] border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md">
                                {["non-verbal", "verbal"].map((item, index) => (
                                    <div className="flex items-center" key={index}>
                                        <input
                                            type="checkbox"
                                            className="defaultCheckbox"
                                            checked={videoFilters.includes(item)}
                                            id={`video-${index}`}
                                            onChange={(event) => changeVideoFilter(item)}
                                        />
                                        <label htmlFor={`video-${index}`} className="first-letter:uppercase">
                                            {item}
                                        </label>
                                    </div>
                                ))}
                                <button
                                    onClick={() => setVideoFilters([])}
                                    className="mt-3 flex items-center justify-center text-sm tracking-wide gap-[1em] bg-[#171717] w-full   border border-[#2b2b2b] hover:border-[#575757] py-[0.625em] px-[1.2em] rounded-md"
                                    type="button">
                                    Clear All
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="relative h-full">
                        <button
                            onClick={() => setIsMute(!isMute)}
                            className="flex items-center gap-[1em] text-[1rem] leading-6   px-[1.2em] rounded-md"
                            type="button">
                            <span>Audio</span>
                            <span className="h-8 w-8 flex items-center justify-center border border-[#2b2b2b] rounded-full">
                                {isMute ? <SpeakerSimpleSlash size={16} color="white" /> : <SpeakerSimpleHigh size={16} color="white" />}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="max-w-[1352px] mx-auto relative pt-[30px] md:pt-[0px] pb-[50px] px-[30px] grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
                {[...new Set(videos.map(video => video.videoId))].map((uniqueVideoId, index) => {
                    const uniqueVideo = videos.find(video => video.videoId === uniqueVideoId);
                    return (
                        <StockVideoComponent
                            isMuted={isMute}
                            document={uniqueVideo}
                            setShowModal={setShowModal}
                            addToCart={addToCart}
                            key={index}
                        />
                    );
                })}

            </div>
            <div className="max-w-[1352px] mx-auto relative pt-[30px] md:pt-[0px] pb-[50px] px-[30px] gap-10">
                {[...new Set(videos.map(video => video.videoId))].length === 0 && (
                    <div className="w-full text-2xl text-center">
                        Not videos available
                    </div>
                )}
            </div>
            <ReactVisibilitySensor offset={{ top: -20 }} onChange={handleVisibilityChange}>
                {({ isVisible }) => (
                    <div className={`relative h-40 w-full`}>
                    </div>
                )}
            </ReactVisibilitySensor>
            <NotloginfooterNew />
        </div>
    );
}
