import AWN from "awesome-notifications";
import axios from "axios";
import { UploadSimple } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useStateValue } from "../../context/StateProvider";

export default function Sharereview() {
  const [screenno, setscreenno] = useState(0);
  const [sender_info, setsender_info] = useState({});
  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [headers, setheaders] = useState([]);
  const [template, settemplate] = useState(0);
  const [{ products, brand, }, dispatch] = useStateValue();

  const router = useHistory();

  useEffect(() => {
    const productarr = products?.filter(
      (val) => val?.name === sender_info?.product
    );
    setsender_info({ ...sender_info, imageUrl: productarr[0]?.imageUrl });
  }, [sender_info?.product]);

  function checkstepone() {
    if (!sender_info.subject) {
      return alert("Please fill all details");
    }
    if (!sender_info.name) {
      return alert("Please fill all details");
    }
    if (!sender_info.email) {
      return alert("Please fill all details");
    }
    if (!sender_info.product) {
      return alert("Please select products");
    }
    setscreenno(2);
  }
  const getMessage = (type) => {
    switch (type) {
      case "ordered":
        return "I have seen you loved this product and I want to share some stories bypeople who loved so and so product also.";
      case "wishlisted":
        return "I have seen you loved this product and I want to share some stories by people who loved so and so product also.";

      case "returning":
        return "I have seen you are returning this product before doing so lets have a look  other user's experience and what they are saying about this product. ";
      default:
        return "I have seen you loved this product and I want to share some stories by people who loved so and so product also.";
    }
  };

  const defaultTemplates = (data, type) => {
    let message = getMessage(type);
    return `<div>
      <p>Dear User</p>
      <p >${message}</p>
     
      <div style="height:20px;" >
      <a href="${data.ref}" > watch stories/reviews</a>
      </div>
      <img 
      crossOrigin="anonymous"
      style="height:300px:width:400px"  src=${data.refLink ?? ""
      } alt='no image' />
      <p>By ${data.senderName ?? "Myrevue Team"}</p>
      <p>reply  ${data.replyEmail ?? "myrevueappofficial@gmail.com"}</p>
    </div>`;
  };

  async function sendtoall() {
    let tempData = {
      ref:
        "https://www.myrevue.app/product/" +
        sender_info.product.replaceAll(" ", "_"),
      refLink: sender_info.imageUrl,
      senderName: sender_info.name,
      replyEmail: sender_info.replyEmail,
    };
    let templateToSend = defaultTemplates(tempData, template);
    let userList = list.filter((a) => a.email != null);
    let data = {
      brandId: brand?.id,
      subject: sender_info.subject,
      senderData: { name: sender_info.name, address: sender_info.email },
      template: templateToSend,
      userList: userList,
    };

    let response = await axios.post(
      "https://restapi-zscu3untuq-em.a.run.app/sendBulkMail",
      data,
      {
        headers: {
          Authorization:
            "bearer ZDSFASQ345suyuo6786786kssd-5i2q3mfnak1048!#%#%#",
        },
      }
    );
    if (response.status === 200) {
      new AWN().success(response.data.message);
      router.goBack();
    } else {
      new AWN().alert("something went wrong");
    }
  }

  function csvToArray(str, delimiter = ",") {
    var headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    headers = headers.map((a) => a.trim());
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const arr = rows.map(function (row, index) {
      const values = row.split(delimiter);
      var el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      el["id"] = values["email"] ?? index;
      return el;
    });
    return arr;
  }
  function successFunction(data) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const strData = event.target.result;
      const arrData = csvToArray(strData);
      setlist(arrData);
      setheaders(Object.keys(arrData[0]));
      setloading(false);
      setscreenno(3);
    };
    reader.readAsText(data);
  }

  const csvupload = (e) => {
    if (loading) return;
    setloading(true);
    var image = e.target.files[0];
    if (!image) {
      alert("Please upload a file");
      setloading(false);
      return;
    }
    successFunction(image);
  };
  return (
    <div className="w-full h-full p-5">
      {screenno === 0 && (
        <div className="w-full h-full rounded-md bg-bg_highlight">
          <div className="h-20 border-b border-[#ececec30] flex items-center justify-between px-5">
            <p className="text-3xl text-[#C0AAF6] font-light tracking-wide">
              Create Email
            </p>
            <button
              onClick={() => setscreenno(1)}
              className="px-4 py-2 rounded-md text-sm bg-[#e2e2e2]"
            >
              Create New
            </button>
          </div>
          <div className="p-5">
            <div className="flex items-center justify-between">
              <div className="">
                <p className="text-[#C0AAF6] text-2xl font-light">
                  Recent Invites
                </p>
                <p className="text-[#7B7B7B] text-sm tracking-wide">
                  Check all the invitation status.
                </p>
              </div>
              <div className="text-[#7B7B7B] flex items-center tracking-wide">
                Total Invites sent:{" "}
                <span className="text-[#7AF4BC] text-2xl pl-2">0</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {screenno === 1 && (
        <div className="w-full h-full rounded-md bg-bg_highlight">
          <div className="h-20 border-b border-[#ececec30] flex items-center justify-between px-5">
            <p className="text-3xl text-[#C0AAF6] font-light tracking-wide">
              Create Email
            </p>
            <button
              onClick={() => setscreenno(0)}
              className="px-4 py-2 rounded-md text-sm bg-[#e2e2e2]"
            >
              Reset / Clear
            </button>
          </div>
          <div className="p-5">
            <div className="px-6 py-4 text-white">
              <div className="">
                <p className="tracking-wide opacity-40 font-extralight">
                  Sender Info
                </p>
              </div>
              <div className="flex flex-col mt-8 gap-y-5">
                <div className="flex items-center gap-x-8">
                  <p className="">Subject of Mail :</p>
                  <input
                    type="text"
                    defaultValue={sender_info?.subject}
                    onChange={(event) =>
                      setsender_info({
                        ...sender_info,
                        subject: event.target.value,
                      })
                    }
                    className="bg-[#1C1C1C80] h-12 w-96 outline-none appearance-none px-3 text-sm tracking-wide rounded"
                    placeholder="Give a suitable line to the subject of Mail."
                  />
                </div>
                <div className="flex items-center gap-x-8">
                  <p className="">Sender’s Name:</p>
                  <input
                    type="text"
                    defaultValue={sender_info?.name}
                    onChange={(event) =>
                      setsender_info({
                        ...sender_info,
                        name: event.target.value,
                      })
                    }
                    className="bg-[#1C1C1C80] h-12 w-96  outline-none appearance-none px-3 text-sm tracking-wide rounded"
                    placeholder="The name you want to use as sender."
                  />
                </div>
                <div className="flex items-center gap-x-8">
                  <p className="">Sender’s Email :</p>
                  <input
                    type="text"
                    defaultValue={sender_info?.email}
                    onChange={(event) =>
                      setsender_info({
                        ...sender_info,
                        email: event.target.value,
                      })
                    }
                    className="bg-[#1C1C1C80] h-12 w-96  outline-none appearance-none px-3 text-sm tracking-wide rounded"
                    placeholder="Email to which users can reply back."
                  />
                </div>
                <div className="">
                  <select
                    defaultValue={sender_info?.product}
                    onChange={(event) =>
                      setsender_info({
                        ...sender_info,
                        product: event.target.value,
                      })
                    }
                    className="bg-[#1C1C1C80] h-12 w-full  outline-none appearance-none px-3 text-sm tracking-wide rounded"
                  >
                    <option value="" disabled selected>
                      Please select a product
                    </option>
                    {products?.map((product, index) => (
                      <option
                        value={product?.name}
                        key={index + 1}
                        className="bg-[#0e0e0eea]"
                      >
                        {product?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end mt-8">
                  <div className="flex gap-x-5">
                    <button
                      onClick={() => checkstepone()}
                      className="px-6 py-2 bg-green-500 rounded-md outline-none appearance-none"
                    >
                      Next
                    </button>
                    <button
                      onClick={() => setscreenno(0)}
                      className="px-6 py-2 bg-white rounded-md outline-none appearance-none bg-opacity-5"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="text-sm font-light tracking-wide text-white text-opacity-40">
                  <p className="">Email will be sent by our side.</p>
                  <p className="">SMTP mail setting will come soon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {screenno === 2 && (
        <div className="w-full h-full rounded-md bg-bg_highlight">
          <div className="h-20 border-b border-[#ececec30] flex items-center justify-between px-5">
            <p className="text-3xl text-[#C0AAF6] font-light tracking-wide">
              Create Email
            </p>
            <button
              onClick={() => setscreenno(0)}
              className="px-4 py-2 rounded-md text-sm bg-[#e2e2e2]"
            >
              Reset / Clear
            </button>
          </div>
          <div className="p-5">
            <div className="px-6 py-4 text-white">
              <div className="">
                <p className="tracking-wide opacity-40 font-extralight">
                  Recipient Info
                </p>
              </div>
              <div className="flex flex-col mt-8 gap-y-5">
                <div className="flex items-center gap-x-8">
                  <p className="">Upload csv file :</p>
                  <div className="relative bg-[#1C1C1C80] h-12 w-96  outline-none appearance-none text-sm tracking-wide rounded">
                    <input
                      type="file"
                      onChange={(event) => csvupload(event)}
                      className="absolute w-full h-full border opacity-0"
                    />
                    <div className="flex items-center h-full px-3 gap-x-4">
                      <UploadSimple size={20} color="#ffffff" />
                      Browse or drag & drop your .csv file
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-x-8">
                  <a
                    href={require("../../assets/csvsample.csv")}
                    download="sample"
                  >
                    <p className="font-light underline cursor-pointer underline-offset-4">
                      sample file:
                    </p>
                  </a>
                </div>
                <div className="flex justify-end mt-8">
                  <div className="flex gap-x-5">
                    {/* <button onClick={() => checksteptwo()} className="px-6 py-2 bg-green-500 rounded-md outline-none appearance-none">
                                            Save
                                        </button> */}
                    <button
                      onClick={() => setscreenno(1)}
                      className="px-6 py-2 bg-white rounded-md outline-none appearance-none bg-opacity-5"
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="text-sm font-light tracking-wide text-white text-opacity-40">
                  <p className="">Email will be sent by our side.</p>
                  <p className="">SMTP mail setting will come soon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {screenno === 3 && (
        <div className="w-full h-full rounded-md bg-bg_highlight">
          <div className="h-20 border-b border-[#ececec30] flex items-center justify-between px-5">
            <p className="text-3xl text-[#C0AAF6] font-light tracking-wide">
              Create Email
            </p>
            <button
              onClick={() => setscreenno(0)}
              className="px-4 py-2 rounded-md text-sm bg-[#e2e2e2]"
            >
              Reset / Clear
            </button>
          </div>
          <div className="p-5">
            <div className="px-6 py-4 text-white">
              <div className="">
                <p className="tracking-wide opacity-40 font-extralight">
                  Template
                </p>
              </div>
              <div className="flex flex-col mt-8 gap-y-5">
                <div className="grid grid-flow-col grid-cols-3 gap-8">
                  <div onClick={() => settemplate("ordered")}>
                    <div
                      className={`w-full bg-white bg-opacity-5 rounded-lg transform duration-150 ${template === "ordered"
                        ? " border-2 border-purple-300"
                        : "hover:border-2 hover:border-purple-300"
                        } p-5`}
                    >
                      <p className="text-sm font-light tracking-wide">
                        Subject:
                        <span className="">
                          &nbsp; {sender_info.subject ?? "not mentioned"}
                        </span>
                      </p>
                      <p className="mt-5 text-sm font-light tracking-wide">
                        Dear <span className="font-medium">Customer Name,</span>
                      </p>
                      <p className="text-sm tracking-wider font-extralight opacity-70">
                        I have seen you ordered this product and I want to share
                        some stories by people who loved so and so product also.
                      </p>
                      <div className="my-4">
                        <img
                          crossOrigin="anonymous"
                          src={sender_info.imageUrl}
                          alt=""
                          className=" h-52"
                        />
                        <button className="text-xs tracking-wide text-purple-500 ">
                          Watch Stories/Reviews
                        </button>
                      </div>
                      <div className="">
                        <div className="text-xs font-light tracking-wide text-white text-opacity-50">
                          <p className="">
                            By {sender_info.name ?? "not configured"}
                          </p>
                          <p className="">
                            {" "}
                            {sender_info.email ?? "not configured"}
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center mt-2">
                      Ordered
                    </div>
                  </div>
                  <div onClick={() => settemplate("wishlisted")}>
                    <div
                      className={`w-full bg-white bg-opacity-5 rounded-lg transform duration-150 ${template === "wishlisted"
                        ? " border-2 border-purple-300"
                        : "hover:border-2 hover:border-purple-300"
                        } p-5`}
                    >
                      <p className="text-sm font-light tracking-wide">
                        Subject:
                        <span className="">
                          &nbsp; {sender_info.subject ?? "not mentioned"}
                        </span>
                      </p>
                      <p className="mt-5 text-sm font-light tracking-wide">
                        Dear <span className="font-medium">Customer Name,</span>
                      </p>
                      <p className="text-sm tracking-wider font-extralight opacity-70">
                        I have seen you ordered this product and I want to share
                        some stories by people who loved so and so product also.
                      </p>
                      <div className="my-4">
                        <img
                          crossOrigin="anonymous"
                          src={sender_info.imageUrl}
                          alt=""
                          className=" h-52"
                        />
                        <button className="text-xs tracking-wide text-purple-500 ">
                          Watch Stories/Reviews
                        </button>
                      </div>
                      <div className="">
                        <div className="text-xs font-light tracking-wide text-white text-opacity-50">
                          <p className="">
                            By {sender_info.name ?? "not configured"}
                          </p>
                          <p className="">
                            {" "}
                            {sender_info.email ?? "not configured"}
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center mt-2">
                      Wishlisted
                    </div>
                  </div>
                  <div onClick={() => settemplate("returning")}>
                    <div
                      className={`w-full bg-white bg-opacity-5 rounded-lg transform duration-150 ${template === "returning"
                        ? " border-2 border-purple-300"
                        : "hover:border-2 hover:border-purple-300"
                        } p-5`}
                    >
                      <p className="text-sm font-light tracking-wide">
                        Subject:
                        <span className="">
                          &nbsp; {sender_info.subject ?? "not mentioned"}
                        </span>
                      </p>
                      <p className="mt-5 text-sm font-light tracking-wide">
                        Dear <span className="font-medium">Customer Name,</span>
                      </p>
                      <p className="text-sm tracking-wider font-extralight opacity-70">
                        I have seen you ordered this product and I want to share
                        some stories by people who loved so and so product also.
                      </p>
                      <div className="my-4">
                        <img
                          src={sender_info.imageUrl}
                          alt=""
                          crossOrigin="anonymous"
                          className=" h-52"
                        />
                        <button className="text-xs tracking-wide text-purple-500 ">
                          Watch Stories/Reviews
                        </button>
                      </div>
                      <div className="">
                        <div className="text-xs font-light tracking-wide text-white text-opacity-50">
                          <p className="">
                            By {sender_info.name ?? "not configured"}
                          </p>
                          <p className="">
                            {" "}
                            {sender_info.email ?? "not configured"}
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center mt-2">
                      Returning
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-8">
                  <div className="flex gap-x-5">
                    <button
                      onClick={() => setscreenno(4)}
                      className="px-6 py-2 bg-green-500 rounded-md outline-none appearance-none"
                    >
                      Next
                    </button>
                    <button
                      onClick={() => setscreenno(2)}
                      className="px-6 py-2 bg-white rounded-md outline-none appearance-none bg-opacity-5"
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="text-sm font-light tracking-wide text-white text-opacity-40">
                  <p className="">Email will be sent by our side.</p>
                  <p className="">SMTP mail setting will come soon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {screenno === 4 && (
        <div className="w-full h-full rounded-md bg-bg_highlight">
          <div className="h-20 border-b border-[#ececec30] flex items-center justify-between px-5">
            <p className="text-3xl text-[#C0AAF6] font-light tracking-wide">
              Create Email
            </p>
            <button
              onClick={() => setscreenno(0)}
              className="px-4 py-2 rounded-md text-sm bg-[#e2e2e2]"
            >
              Reset / Clear
            </button>
          </div>
          <div className="p-5">
            <div className="px-6 py-4 text-white">
              <div className="">
                <p className="tracking-wide opacity-40 font-extralight">
                  Recipients
                </p>
              </div>
              <div className="flex flex-col mt-8 gap-y-5">
                <div className="bg-[#2D2D2D] border-[1px] border-[#E9E0FF33] w-[740px] rounded">
                  <table className="w-full">
                    <thead className="w-full">
                      <tr className="w-full">
                        {headers.map((heading, index) => (
                          <th className="font-normal w-1/3 text-[#ffffff70] h-12 border-b border-[#f2f2f225]">
                            {heading}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((val, index) => (
                        <tr
                          key={"tableRow" + index}
                          className="border-t border-[#f2f2f230] text-center"
                        >
                          {Object.keys(val).map((v, index) => (
                            <td key={"rowId" + index}>{val[v]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="flex justify-end mt-8">
                  <div className="flex gap-x-5">
                    <button
                      onClick={() => sendtoall()}
                      className="px-6 py-2 bg-green-500 rounded-md outline-none appearance-none"
                    >
                      Send
                    </button>
                    <button
                      onClick={() => setscreenno(3)}
                      className="px-6 py-2 bg-white rounded-md outline-none appearance-none bg-opacity-5"
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="text-sm font-light tracking-wide text-white text-opacity-40">
                  <p className="">Email will be sent by our side.</p>
                  <p className="">SMTP mail setting will come soon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
