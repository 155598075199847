import React, { useState } from 'react'
import dayjs from "dayjs";
import Dialog from './dialog';
import { useHistory } from 'react-router-dom';

export default function Week({ week, weekIndex }) {
    const router = useHistory();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null)


    return (
        <>
            {showDialog && <Dialog setShowDialog={setShowDialog} day={selectedDay} />}
            <div className="grid flex-1 grid-cols-7 grid-rows-1">
                {week.map((day, i) => {
                    return (
                        <React.Fragment key={i}>
                            <div onClick={() => { setShowDialog(true); setSelectedDay(day) }} className={`flex flex-col h-full border-r border-t border-[#8080801e] bg-bg_highlight/20 hover:bg-background/20`}>
                                <div className={`flex items-center justify-center gap-2 py-2 text-white border-b border-[#8080801e] ${day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "!bg-[#8EA9FA]" : ""}`}>
                                    {day.format("ddd").toUpperCase()} {day.format("DD")}
                                </div>
                                {day.format("DD/MM/YYYY") === "06/02/2024" &&
                                    <div className="w-full p-2 space-y-2">
                                        <div className="w-full bg-background border border-[#8080801e] aspect-[3/3.5] rounded-md flex flex-col justify-between">
                                            <img
                                                src="https://scontent.cdninstagram.com/v/t51.29350-15/197833225_153388233477137_3648037191102101147_n.jpg?stp=dst-jpg_s280x280_u&_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=An3qPp3cIJUAX-dbNlr&_nc_ht=scontent.cdninstagram.com&oh=00_AfBMHj2B2HpN8s3AUkaXcWVx3jVQY_m_qekZXslEz-A7Bw&oe=65CA273B"
                                                alt=""
                                                className='rounded'
                                            />
                                            <div className="flex items-center justify-between flex-1 px-2">
                                                <div className="text-[13px]">18:30</div>
                                                <div className="flex items-center gap-1">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Ffacebook.png?alt=media&token=5675160a-b038-470c-9ffd-9803660318f3" alt="" className="h-4 rounded-full aspect-square" />
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe" alt="" className="h-4 rounded-full aspect-square" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full bg-background border border-[#8080801e] aspect-[3/3.5] rounded-md flex flex-col justify-between">
                                            <img
                                                src="https://scontent.cdninstagram.com/v/t51.29350-15/197833225_153388233477137_3648037191102101147_n.jpg?stp=dst-jpg_s280x280_u&_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=An3qPp3cIJUAX-dbNlr&_nc_ht=scontent.cdninstagram.com&oh=00_AfBMHj2B2HpN8s3AUkaXcWVx3jVQY_m_qekZXslEz-A7Bw&oe=65CA273B"
                                                alt=""
                                                className='rounded'
                                            />
                                            <div className="flex items-center justify-between flex-1 px-2">
                                                <div className="text-[13px]">18:30</div>
                                                <div className="flex items-center gap-1">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Ffacebook.png?alt=media&token=5675160a-b038-470c-9ffd-9803660318f3" alt="" className="h-4 rounded-full aspect-square" />
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe" alt="" className="h-4 rounded-full aspect-square" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {day.format("DD/MM/YYYY") === "10/02/2024" &&
                                    <div className="w-full p-2 space-y-2">
                                        <div onClick={() => router.push("/social/posts/view-post/6smTfpCbtOT9zBsOTHry")} className="cursor-pointer w-full bg-background border border-[#8080801e] aspect-[3/3.5] rounded-md flex flex-col justify-between">
                                            <img
                                                src="https://scontent.cdninstagram.com/v/t51.29350-15/197833225_153388233477137_3648037191102101147_n.jpg?stp=dst-jpg_s280x280_u&_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=An3qPp3cIJUAX-dbNlr&_nc_ht=scontent.cdninstagram.com&oh=00_AfBMHj2B2HpN8s3AUkaXcWVx3jVQY_m_qekZXslEz-A7Bw&oe=65CA273B"
                                                alt=""
                                                className='rounded'
                                            />
                                            <div className="flex items-center justify-between flex-1 px-2">
                                                <div className="text-[13px]">12:30</div>
                                                <div className="flex items-center gap-1">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Ffacebook.png?alt=media&token=5675160a-b038-470c-9ffd-9803660318f3" alt="" className="h-4 rounded-full aspect-square" />
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe" alt="" className="h-4 rounded-full aspect-square" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
}
