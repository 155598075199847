import React from "react";
import { useStateValue } from "../../context/StateProvider";

function Cstats() {
  const [{ user }, dispatch] = useStateValue();

  return (
    <div className="flex flex-wrap items-center w-full gap-5">
      <div className="h-auto p-3 my-2 text-center border-2 border-white w-fit rounded-xl">
        <p className="mx-auto text-sm font-light tracking-wider">Campaigns used</p>
        <div className="mt-5 text-3xl">
          <span className="">
            {user?.campaigns ?? 0} / <span className="">{user?.limit?.campaignLimit ?? 1}</span>
          </span>
        </div>
      </div>
      <div className="h-auto p-3 my-2 text-center border-2 border-white w-fit rounded-xl">
        <p className="mx-auto text-sm font-light tracking-wider">Downloads used</p>
        <div className="mt-5 text-3xl">
          <span className="">
            {user?.downloads ?? 0} / <span className=""> {user?.limit?.downloadLimit === 5000 ? "Unlimited" : user?.limit?.downloadLimit ?? 3}</span>
          </span>
        </div>
      </div>
      <div className="h-auto p-3 my-2 text-center border-2 border-white w-fit rounded-xl">
        <p className="mx-auto text-sm font-light tracking-wider">Comments used</p>
        <div className="mt-5 text-3xl">
          <span className="">
            {user?.comments ?? 0}/ <span className="">{user?.limit?.commentLimit ?? 0}</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Cstats;
