import { ChartDonut } from "@phosphor-icons/react";
import React from "react";
import ReactApexChart from "react-apexcharts";

export default function AnalyticsCard({ text, value, options, from, }) {
  return (
    <div className={`bg-[#131418] shadow-sm shadow-gray-600 ${from === "analytics" ? "h-72" : "h-64"} w-full rounded-md p-5 text-white col-span-2`}>
      <div className="flex items-center justify-between">
        <p className="text-sm font-light tracking-wider">{text}</p>
        <p className="text-3xl text-[#9a9999]">{value}</p>
        <ChartDonut size={22} color="#ffffff" />
      </div>
      {options?.options?.xaxis?.categories.length === 0 ?
        <div className="flex items-center justify-center w-full h-full">
          <p className="font-work">No data available for this week</p>
        </div>
        : <div className="w-full">
          <ReactApexChart
            options={options.options}
            series={options.series}
            type="area"
            height={from === "analytics" ? 240 : 200}
          />
        </div>
      }
    </div>
  );
}
