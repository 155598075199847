import { Calendar } from '@phosphor-icons/react'
import React, { useState } from 'react'
import dayjs from "dayjs";
import { showWarning } from '../../../components/toast/toast';
import { useHistory } from 'react-router-dom';

export default function Dialog({ setShowDialog }) {
    const router = useHistory();
    const [sdate, setDate] = useState(null);

    async function schedulePost() {
        const currentDateTime = dayjs();
        const selectedDateTime = dayjs(sdate);
        console.log(selectedDateTime)
        if (!sdate) return showWarning("Please select a date")
        if (selectedDateTime.isAfter(currentDateTime)) {
            router.push(`/social/posts/create?schedule=${sdate}`)
        }
        else {
            showWarning("Please select a future date to create post")
        }
    }

    return (
        <div
            data-state="open"
            className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
            style={{ pointerEvents: "auto" }}
            data-aria-hidden="true"
            aria-hidden="true"
        >
            <div
                role="alertdialog"
                id="radix-:r15:"
                aria-describedby="radix-:r17:"
                aria-labelledby="radix-:r16:"
                data-state="open"
                className="fixed left-[50%] top-[50%] z-50  border-[#8080801e] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-bg_highlight p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg"
                tabIndex={-1}
                style={{ pointerEvents: "auto" }}
            >
                <div className="flex flex-col space-y-2 text-center sm:text-left">
                    <h2 id="radix-:r16:" className="font-light tracking-wide text-white ">
                        Please select a date & time you want to schedule post .
                    </h2>
                    <input onChange={(event) => setDate(event.target.value)} type="datetime-local" className="relative flex items-center justify-center w-full h-12 px-4 text-xs rounded-md bg-background" />
                </div>
                <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center h-10 px-4 py-2 mt-2 text-sm font-medium transition-colors border border-[#8080801e] rounded-md shadow-sm whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground sm:mt-0"
                        onClick={() => setShowDialog(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium transition-colors rounded-md shadow whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#8EA9FA] text-primary-foreground hover:bg-[#4611ea]"
                        onClick={() => schedulePost()}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>

    )
}
