import { ArrowLeft, ArrowRight, Play } from '@phosphor-icons/react';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper';
export default function UgcOrderDemo({ array, setactivePlaylist, hashTag }) {
    const swiperRef = useRef(null);
    const history = useHistory();
    return (
        <div className="bg-[#101010] ">
            <div className="bg-[#171717] mt-5 w-full  rounded-md">
                <div className="flex items-center justify-center h-12 pt-5">
                    <p className="text-2xl font-semibold text-center uppercase">{hashTag}</p>
                </div>
                <div className="flex justify-center w-full px-5 py-5 lg:px-10">
                    <Swiper
                        modules={[Navigation]} spaceBetween={25}
                        // slidesPerView={1}
                        // onSlideChange={() => console.log("slide change")}
                        // onSwiper={(swiper) => console.log(swiper)}
                        ref={swiperRef}
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                width: 640,
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                            964: {
                                width: 964,
                                slidesPerView: 3,
                            },
                        }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}>

                        {array?.map((flick, index) => (
                            <SwiperSlide
                                key={index}
                                className="relative cursor-pointer"
                                onClick={() => {
                                    // setplaylisttype("reviews");
                                    setactivePlaylist(array);
                                    // setActiveIndex(index);
                                    history.push("/ugc?showModal=true")
                                    // setshowModal(true);
                                    setTimeout(() => {
                                        const videoid = document.getElementById(`playlist${index}`);
                                        videoid.scrollIntoView();
                                    }, 100);
                                }}>
                                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                    <div className="h-16 w-16 border-[3px] border-white rounded-full flex items-center justify-center cursor-pointer">
                                        <Play size={34} color="white" weight="fill" />
                                    </div>
                                </div>
                                <img loading="lazy" src={flick?.poster} alt="" className="w-full aspect-[9/16] object-cover rounded-xl" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="flex items-center justify-center gap-3 pb-5 md:hidden">
                    <button onClick={() => swiperRef.current?.slidePrev()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                        <ArrowLeft size={26} color="black" />
                    </button>
                    <button onClick={() => swiperRef.current?.slideNext()} className="flex items-center justify-center h-10 bg-white rounded-full aspect-square ">
                        <ArrowRight size={26} color="black" />
                    </button>
                </div>
            </div>

        </div>
    )
}
