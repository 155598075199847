import React from 'react'
import Stickyheader from '../components/stickyheader';
import { Ticket } from "phosphor-react"
import { useEffect } from 'react';
import { useStateValue } from '../context/StateProvider';
export default function Support() {
    const [{ user }, dispatch] = useStateValue();

    const handleMaximize = () => {
        if (window.Tawk_API) {
            window.Tawk_API.maximize()
        }
    };
    useEffect(() => {

        if (window.Tawk_API) {
            window.Tawk_API.visitor = {
                email: user?.email,
                brandName: user?.brandName
            };
        }
    }, []);


    return (
        <div className=''>
            <Stickyheader title={"Support"} back={true} />

            <div className="p-5">
                <button onClick={() => handleMaximize()} className='bg-bg_highlight float-right hover:bg-[#4611ea] h-10  px-5 text-white text-sm tracking-wider flex items-center gap-x-4'>Create a Ticket <Ticket size={24} color="#ffffff" /></button>
            </div>

        </div>
    )
}
