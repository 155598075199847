import React, { useMemo, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { logEngagementHook } from "../common/functions";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import { nanoid } from "nanoid";
import AWN from "awesome-notifications";
import QuizIcon from "../assets/icons/quiz/QuizIcon";
import { Phone } from "@phosphor-icons/react";
import { showWarning } from "../components/toast/toast";
import { pushNotification } from "../common/notificationFunctions";

function QuizUserPage() {
  let quizdiv = useRef();
  const [page, setPage] = useState(1);
  const [current, setCurrent] = useState(0);
  const [quiz, setQuiz] = useState(null);
  const [userResponse, setResponse] = useState([]);
  const [user, setUser] = useState(null);
  const [device, setDevice] = useState("");
  const [loginModal, setLoginModal] = useState(false);

  const iconColors = [
    "#FF5733",
    "#FFBD33",
    "#75FF33",
    "pink",
    "#33FFBD",
    "#33FF57",
  ];
  const iconTypes = ["square", "triangle", "star", "starfull", "hexagon", ""];

  const { form } = useParams();
  let videoRef = useRef();
  let phoneRef = useRef();
  let verifyButtonRef = useRef();
  const user_id = "user_" + nanoid(15);

  const cachedBgIcons = useMemo(() => {
    return (
      <div className="relative">
        {Array.from(Array(10)).map((_, index) => (
          <QuizIcon
            key={"icons" + index}
            color={iconColors[Math.floor(Math.random() * iconColors.length)]}
            type={iconTypes[Math.floor(Math.random() * iconTypes.length)]}
            top={70 * index}
            left={Math.random() * 350}
          />
        ))}
      </div>
    );
  }, []);

  const delay = (time) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, time);
    });

  useEffect(() => {
    const inputs = document.getElementById("otpinputs");

    inputs?.addEventListener("input", function (e) {
      const target = e.target;
      const val = target.value;

      if (isNaN(val)) {
        target.value = "";
        return;
      }

      if (val !== "") {
        const next = target.nextElementSibling;
        if (next) {
          next.focus();
        }
      }
    });

    inputs?.addEventListener("keyup", function (e) {
      const target = e.target;
      const key = e.key.toLowerCase();

      if (key === "backspace" || key === "delete") {
        target.value = "";
        const prev = target.previousElementSibling;
        if (prev) {
          prev.focus();
        }
        return;
      }
    });
  }, [page]);

  useEffect(() => {
    if (window.innerWidth > 450) {
      const height = Math.ceil(window.innerHeight * 0.9);
      const width = Math.floor(height / 2);
      setDevice("Desktop");
      quizdiv.current.style.width = width + "px";
      quizdiv.current.style.height = height + "px";
    } else {
      setDevice("mobile");
      window.scrollTo(0, document.body.scrollHeight);
    }
    if (!quiz)
      getDoc(doc(db, "quizs", form)).then((d) => {
        if (!d.exists()) {
          setPage(7);
        }
        var quizData = d.data();
        setQuiz(quizData);
        setPage(0);
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: "Desktop",
          brand: quizData.bpsId,
          action: "open_quiz",
        });
      });
  }, []);

  const signinByGoogle = () => {
    setLoginModal(false);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: quiz.bpsId,
          action: "quiz_login_success",
        });
        setUser({
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });

        var isSubmited = await getDocs(
          query(
            collection(db, "quizs/" + form + "/submissions"),
            where("user_info.email", "==", user.email)
          )
        ).then((res) => res.docs.length !== 0);
        if (isSubmited) {
          setPage(7);
          return;
        }

        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName,
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          showWarning("Something went wrong");
          return;
        }
        setUser(response?.data?.user);
        setPage(4);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  async function getphoneotp(e) {
    const phone_number = phoneRef.current.value;
    let regex = /^[6-9][0-9]{9}/i;
    if (!regex.test(phone_number)) {
      return new AWN().warning("Please enter valid phone number");
    }
    const auth = getAuth();

    const phonenumber = "+91" + phone_number;

    var isSubmited = await getDocs(
      query(
        collection(db, "quizs/" + form + "/submissions"),
        where("user_info.phone", "==", phone_number)
      )
    ).then((res) => res.docs.length !== 0);
    setLoginModal(false);
    if (isSubmited) {
      setPage(7);
      return;
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth
    );
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phonenumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPage(3);
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  const verifyOTP = (otp) => {
    let regex = /\d{6}/i;
    if (!regex.test(otp)) {
      verifyButtonRef.current.innerHTML = "Verify";
      return new AWN().alert("Please enter valid otp");
    }
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then(async (result) => {
        const user = result.user;

        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: quiz.bpsId,
          action: "quiz_login_success",
        });

        var isSubmited = await getDocs(
          query(
            collection(db, "quizs/" + form + "/submissions"),
            where("user_info.phone", "==", user.phoneNumber)
          )
        ).then((res) => res.docs.length !== 0);
        if (isSubmited) {
          setPage(7);
          return;
        }
        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName?.toLowerCase() ?? "Change User Name",
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          phone: user.phoneNumber,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
          joinDate: Date.now(),
          categories: [],
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          showWarning("Something went wrong");
          return;
        }
        setUser(response?.data?.user);
        gotoPageAfterLoading(4);
      })
      .catch((error) => {
        new AWN().alert("Sign In error. Please try again.");
      });
  };

  const gotoPageAfterLoading = (pageNumber) => {
    setPage(1);
    setTimeout(() => {
      setPage(pageNumber);
    }, 1500);
  };

  const nextPage = (jump = 1) => {
    // 1.loading
    // 2.login
    // 3.otp verification page
    // 4.begin page
    // 5.question page
    // 6.thank you page
    // 7.already submitted page
    // 8.invalid form page
    if (jump !== 1) {
      setPage(jump);
      return;
    }
    if (page + 1 === 3) {
      getphoneotp();
      return;
    }
    if (page === 3) {
      verifyButtonRef.current.innerHTML = `<i className='fas fa fa-spinner animate-spin'></i>`;
      const inputs = document.getElementById("otpinputs");
      var otp = "";
      inputs.childNodes.forEach((otpfields) => {
        otp += otpfields.value;
      });
      verifyOTP(otp);
      return;
    }
    setPage(page + 1);
  };
  const submit_review = async () => {
    let userResponses = Object.keys(userResponse).reduce((total, curr) => {
      total.push({
        question: quiz.questions[curr].question,
        answer: userResponse[curr],
        isRight:
          quiz.questions[curr].answers[quiz.questions[curr].correctAnswer] ===
          quiz[curr],
      });
      return total;
    }, []);
    let newdoc = {
      campaign_info: {
        campaign_name: quiz?.campaign_name,
        productname: quiz?.productname,
        prodid: quiz?.prodid,
        catId: quiz?.catId,
        productimage: quiz?.productimage,
        subcategory: quiz?.subcategory,
      },
      user_info: {
        name: user.name,
        tag: user.tag,
        token: user.token ?? null,
        id: user.id,
        image: user.imageUrl ?? null,
        email: user.email ?? null,
        phone: user.phone ?? null,
      },
      dateAdded: Date.now(),
      response: userResponses,
    };
    let updates = { submissions: increment(1) };
    await updateDoc(doc(db, "quizs/" + form), updates).then((res) =>
      console.log("done")
    );
    addDoc(collection(db, "quizs/" + form + "/submissions"), newdoc).then(
      () => {
        new AWN().success("Submitted successFully");
        pushNotification({
          message: "Quiz submission got for " + quiz?.campaign_name,
          endpoint: "/engagement/view-quiz/" + quiz.id,
          type: "quiz",
          client: quiz.bpsId,
        });
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: quiz.bpsId,
          action: "user_quiz_complete",
        });
      }
    );
  };
  const nextCurrent = () => {
    if (current >= 4) {
      // submit response -- save
      submit_review();
      setPage(6);
    } else setCurrent(current + 1);
  };

  const renderPages = () => {
    switch (page) {
      case 7:
        return (
          <div
            key={page + "page"}
            className="flex flex-col items-center justify-center h-screen gap-6 text-black fade"
          >
            <p className="text-5xl font-semibold first-letter:uppercase">
              Thank You
            </p>
            <p className="">Your response is already recorded.</p>
          </div>
        );
      case 6:
        const answerCorrected =
          userResponse.filter((d) => d.isRight).length - 1;

        return (
          <div
            key={page + "page"}
            className="flex flex-col items-center justify-center h-screen gap-6 text-black fade"
          >
            <p className="text-5xl font-semibold first-letter:uppercase">
              Thank You
            </p>
            <div className="flex gap-2 text-xl">
              {Array.from(Array(5)).map((s, index) => {
                if (index <= answerCorrected)
                  return (
                    <i key={"star" + index} className="fa fas fa-star "></i>
                  );
                else
                  return (
                    <i
                      key={"star" + index}
                      className="fa fas fa-star opacity-20"
                    ></i>
                  );
              })}
            </div>
          </div>
        );
      case 5:
        var question = quiz.questions[current];
        return (
          <div
            key={page + "page"}
            className="flex flex-col items-center h-full p-3 text-white fade"
          >
            <div className="h-3"></div>
            <div className="flex gap-2">
              {Array.from(Array(quiz.questions.length ?? 5)).map((d, index) => {
                if (index === current)
                  return (
                    <div
                      key={"progress" + index}
                      className="h-1 w-4 rounded-full transform duration-300 scale-110 bg-[#121212]"
                    ></div>
                  );
                else if (index < current)
                  return (
                    <div
                      key={"progress" + index}
                      className="w-4 h-1 bg-white rounded-full"
                    ></div>
                  );
                else
                  return (
                    <div
                      key={"progress" + index}
                      className="w-4 h-1 bg-white rounded-full bg-opacity-70"
                    ></div>
                  );
              })}
            </div>
            <div
              className=" rounded-lg overflow-hidden w-4/5 my-3 aspect-[9/16] relative"
              style={{ border: "5px solid #ff005c" }}
            >
              <video
                playsInline
                autoPlay
                ref={videoRef}
                className="object-cover w-full h-full duration-300 transform"
                src={question.questionVideoUrl}
              ></video>
              <button
                onClick={() => {
                  videoRef.current.currentTime = 0;
                  videoRef.current.play();
                }}
                className="absolute left-0 right-0 p-2 px-3 mx-auto text-xs bg-pink-700 rounded-full w-min bottom-1"
              >
                Replay
              </button>
            </div>
            <div className="grid flex-1 w-full grid-cols-2 gap-2 text-black grid-row-2">
              {Object.entries(question.answers).map(([key, value]) => {
                return (
                  <button
                    key={"option" + key + current}
                    onClick={async (e) => {
                      if (userResponse[current]) return;
                      var resp = question;
                      resp["userAnswer"] = value;
                      resp["userOption"] = key;
                      resp["isRight"] = question.correctAnswer === key;
                      setResponse([...userResponse, resp]);
                      if (resp.isRight) {
                        e.currentTarget.style.backgroundColor = "green";
                      } else {
                        e.currentTarget.style.backgroundColor = "red";
                      }
                      await delay(2000);
                      nextCurrent();
                    }}
                    style={{ fontFamily: "Irish Grover" }}
                    className="bg-[#ffffffff]  p-2 rounded-lg border border-white  text-pink-600"
                  >
                    {value}
                  </button>
                );
              })}
            </div>
            <div className="h-12"></div>
          </div>
        );
      case 4:
        return (
          <div
            key={page + "page"}
            className="flex flex-col justify-between h-full p-3 pt-10 text-white fade pb-14"
          >
            <div className=""></div>
            <div className="">
              <p className="font-medium text-black ">Why wait more?</p>
              <p
                style={{ textShadow: "2px 2px 2px black" }}
                className="text-5xl font-medium text-white"
              >
                Let's Begin
              </p>
            </div>
            <button
              onClick={() => nextPage(1)}
              className="fade h-10 mt-5 rounded-md text-center font-medium text-[#121212] hover:bg-[#eaeaea] bg-white w-full"
            >
              Sure!!
            </button>
          </div>
        );
      case 3:
        return (
          <div
            key={page + "page"}
            className="flex flex-col items-center justify-between h-full p-3 text-black fade pb-14"
          >
            <div className=""></div>
            <div className="text-white">
              <p
                style={{ textShadow: "2px 2px 2px black" }}
                className="my-4 text-3xl font-semibold"
              >
                Please Verify and You are ready!
              </p>
              <p className="my-4 text-sm">
                For additional security, we’ve sent an OTP to your registered
                mobile number
              </p>
            </div>
            <div className="">
              <div id="otpinputs" className="grid grid-cols-6 gap-3">
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full h-10 text-center duration-200 bg-transparent border-2 border-black rounded-md outline-none focus:border-white"
                  maxLength={1}
                />
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full h-10 text-center duration-200 bg-transparent border-2 border-black rounded-md outline-none focus:border-white"
                  maxLength={1}
                />
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full h-10 text-center duration-200 bg-transparent border-2 border-black rounded-md outline-none focus:border-white"
                  maxLength={1}
                />
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full h-10 text-center duration-200 bg-transparent border-2 border-black rounded-md outline-none focus:border-white"
                  maxLength={1}
                />
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full h-10 text-center duration-200 bg-transparent border-2 border-black rounded-md outline-none focus:border-white"
                  maxLength={1}
                />
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full h-10 text-center duration-200 bg-transparent border-2 border-black rounded-md outline-none focus:border-white"
                  maxLength={1}
                />
              </div>
              <button
                ref={verifyButtonRef}
                onClick={() => nextPage(1)}
                className="fade h-10 mt-5 rounded-md text-center font-medium text-[#121212] hover:bg-[#eaeaea] bg-white w-full"
              >
                Verify
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div
            key={page + "page"}
            className="flex flex-col items-center justify-start h-full text-white fade"
          ></div>
        );
      case 1:
        return (
          <div
            key={"page" + page}
            className="flex items-center justify-center w-full h-full fade"
          >
            <i className="text-white fas fa-spinner animate-spin"></i>
          </div>
        );
      default:
        return (
          <div className="relative flex flex-col items-center justify-start h-full py-12 fade ">
            <div className=" rounded-xl bg-white h-16 min-w-[120px] p-1 flex items-center justify-center">
              <img
                src={quiz?.LogoLink}
                alt=""
                className="w-auto h-full rounded"
              />
            </div>
            <p className="mt-4 -mb-3 text-sm font-bold">Presents</p>
            <p className="quiz-text">Quiz!!!</p>
            <img
              src={quiz?.productimage}
              alt=""
              className="mt-4 shadow-md rounded-3xl h-52"
            />
            <p className="my-3 font-semibold text-center ">
              Play the quizz <br /> and earn rewards
            </p>
            <button
              onClick={(e) => {
                e.currentTarget.nextSibling.style.display = "flex";
                setLoginModal(true);
              }}
              className="h-12 rounded-lg border-2 border-black font-semibold tracking-wide shadow-md shadow-black text-white bg-[#323232] w-2/3"
            >
              Start
            </button>
            <div
              style={{ boxShadow: "-1px -1px 20px 5px black" }}
              className="hidden slideup gap-2 flex-col justify-start p-5 bg-white rounded-t-3xl absolute -bottom-[10px] left-0 z-[100] w-full shadow-md shadow-black h-[250px]"
            >
              <div className="flex items-center justify-end w-full">
                <i
                  className="p-2 duration-150 transform rounded-lg cursor-pointer fas fa-close hover:bg-gray-300"
                  onClick={(e) => {
                    e.currentTarget.parentElement.parentElement.style.display =
                      "none";
                    setLoginModal(false);
                  }}
                ></i>
              </div>
              <p className="text-center">Continue with</p>
              <div className="flex border border-[#c7c7c7] rounded-md">
                <div className="flex items-center justify-center w-10 h-full">
                  <Phone size={22} color="#121212" weight="bold" />
                </div>
                <input
                  type="text"
                  maxLength={10}
                  minLength={10}
                  ref={phoneRef}
                  inputMode="numeric"
                  className="h-10 flex-1 w-full outline-none rounded appearance-none px-2 focus:appearance-none placeholder:text-[#c7c7c7] "
                  placeholder="Phone Number"
                />
                <button
                  onClick={getphoneotp}
                  className="flex items-center justify-center h-full text-white bg-black rounded-r-md"
                >
                  <i className="px-4 cursor-pointer fas fa-caret-right"></i>
                </button>
              </div>
              <p className="text-xs text-center">or</p>
              <button
                onClick={signinByGoogle}
                className="flex items-center justify-center w-full h-10 gap-5 text-white bg-black rounded-md"
              >
                <p className="font-medium">Sign in with Google</p>
              </button>
              <div className="" id="sign-in-button"></div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="items-center justify-center w-full h-screen text-black bg-black md:flex">
      <div
        ref={quizdiv}
        className="relative w-full h-full overflow-hidden rounded quiz-background"
      >
        {cachedBgIcons}
        <div
          style={{ backdropFilter: "blur(1px)" }}
          className="relative w-full h-full"
        >
          {renderPages()}
        </div>
        {!loginModal && (
          <div className="absolute left-0 right-0 z-10 flex items-center justify-center h-10 bottom-1">
            <p className="flex items-center p-1 px-4 text-xs bg-white rounded-full bg-opacity-60">
              Powered By &nbsp;
              <img
                className="h-5"
                src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMyRevue%20Logo%20(1)%201.svg?alt=media&token=f3878494-f182-43c4-bbfb-6d1f7247af27"
                alt=""
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuizUserPage;
