import React, { useState } from 'react'
import dayjs from "dayjs";
import Dialog from './dialog';
import { useHistory } from 'react-router-dom';

export default function DayCalender({ day, rowIdx, monthIndex }) {
    const router = useHistory();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null)

    return (
        <>
            {showDialog && <Dialog setShowDialog={setShowDialog} day={selectedDay} />}
            <div className={`flex flex-col border border-[#8080801e] bg-bg_highlight ${day.format("MMM") === dayjs().month(monthIndex).format('MMM') ? "" : "bg-opacity-20 text-opacity-20 text-white"} ${day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "!bg-background" : ""}`}>
                <header header className="flex flex-col items-center" >
                    {rowIdx === 0 && (
                        <p className="mt-1 text-sm">
                            {day.format("ddd").toUpperCase()}
                        </p>
                    )
                    }
                    <p className={`text-sm p-1 my-1 text-center  text-white`}>
                        {day.format("DD")}
                    </p>
                </header >
                <div className="flex-1 px-3 cursor-pointer" onClick={() => { setShowDialog(true); setSelectedDay(day) }} >
                    {day.format("DD/MM/YYYY") === "10/02/2024" &&
                        <div className="w-full p-2 space-y-2">
                            <div onClick={() => router.push("/social/posts/view-post/6smTfpCbtOT9zBsOTHry")} className=" cursor-pointer w-full bg-background border border-[#8080801e] py-1 rounded-md flex flex-col justify-between">
                                <div className="flex items-center justify-between flex-1 px-2">
                                    <div className="text-[13px]">12:30</div>
                                    <div className="flex items-center gap-1">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Ffacebook.png?alt=media&token=5675160a-b038-470c-9ffd-9803660318f3" alt="" className="h-4 rounded-full aspect-square" />
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fintegration_social%2Finstagram.png?alt=media&token=3eafb9a6-b59c-49a9-a140-79a876bdbcfe" alt="" className="h-4 rounded-full aspect-square" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className={`bg-green-500 p-1  text-black tracking-wide text-sm rounded mb-1 truncate`}  >
                    This is video title
                </div> */}
                </div>
            </div >
        </>
    )
}
