import { Eye, Hexagon, Plus } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { collection, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useStateValue } from '../../context/StateProvider';
import { toPng } from 'html-to-image';
import axios from 'axios';

export default function Templates() {
    const router = useHistory();
    const [templates, setTemplates] = useState([]);
    const [{ brand, user }, dispatch] = useStateValue(); // Update with your actual state provider

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                let headersList = {
                    "Accept": "*/*",
                }

                let reqOptions = {
                    url: "https://massemailer-zscu3untuq-as.a.run.app/templates/" + brand?.id,
                    method: "GET",
                    headers: headersList,
                    data: document
                }

                let response = await axios.request(reqOptions);
                if (response.data.success) {
                    setTemplates(response.data.data)
                }
            } catch (error) {
                console.error('Error fetching templates', error);
            }
        };
        fetchTemplates();
    }, [user.email]); // Make sure to include user.email in the dependencies array if needed
    return (
        <div className="mt-10">
            <div className="flex items-center justify-between px-5">
                <p className="text-3xl font-medium tracking-wide text-white">
                    Template
                </p>
                <button onClick={() => router.push("/admin/emails/createemailtemplate")} className="bg-[#93A8F4] h-12 rounded-lg flex items-center gap-3 px-5 appearance-none outline-none">
                    <Plus size={22} color='#f8f8f8' />
                    Create New Template
                </button>
            </div>
            <div className="grid gap-10 px-5 py-10 md:grid-cols-4 lg:grid-cols-5">
                {templates?.map((template, index) => (
                    <div className="w-full" key={index}>
                        <div className="w-full bg-white aspect-[3/4] rounded-md cursor-pointer" onClick={() => router.push("/admin/emails/editemailtemplate/" + template?._id)}>
                            <img src={template?.previewUrl} alt="preview" className='object-cover w-full h-full rounded-md' />
                        </div>
                        <div className="flex justify-center mt-2">
                            <p className="text-center">{template?.templateName}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="relative block px-5 mt-10 mb-10">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="h-14 bg-[#131418]">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Email address
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Full name
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Subscription status
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Added at
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {templates?.map((template, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="table-row-spacer"></tr>
                                        <tr
                                            className="table-row align-[inherit]  bg-[#131418]"
                                        >
                                            <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {index + 1}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {template?.templateName}
                                            </td>
                                            <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                {template?.status === true ? <span className="flex items-center gap-2"> <div className="w-2 h-2 bg-green-500 rounded-full"></div> Published</span> : <span className="flex items-center gap-2"> <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div> Pending</span>}
                                            </td>
                                            <td
                                                className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                                onClick={() =>
                                                    router.push("/admin/emails/editemailtemplate/" + template.id)
                                                }
                                            >
                                                <div className="flex items-center gap-x-3">
                                                    <div className="relative flex items-center justify-center w-10 h-10">
                                                        <Hexagon size={40} weight="light" />
                                                        <Eye size={20} className="absolute" weight="bold" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        {templates.length === 0 &&
                            <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                No Templates Available
                            </div>
                        }
                    </div>
                </div>
            </div> */}
        </div>
    )
}
