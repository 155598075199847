import AWN from 'awesome-notifications';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router';
import { sendAmplitudeData } from './amplitude';
import { showSuccess, showWarning } from './components/toast/toast';

export default function Forgotpassword() {
    let emailRef = useRef();
    const router = useHistory()
    useEffect(() => {
        sendAmplitudeData("forgot password page opened");
        var title = `Forgot Passowrd - MyRevue`;
        var metaTitle = `Forgot Passowrd - MyRevue`;
        var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
        var metaKeyword = `Brands - MyRevue, video testimonials software ,best video testimonial software, video testimonials, get video testimonials, collect video testimonials, free video testimonial software, video testimonials app, video customer testimonials, video testimonial platform, video testimonial tool`;

        var ogUrl = `https://brand.myrevue.app/forgotpassword`;
        var ogtype = `website`;
        var ogtitle = `Forgot Passowrd - MyRevue`;
        var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
        var ogimage = ``;


        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/forgotpassword`;
        var twittertitle = `Forgot Passowrd - MyRevue`;
        var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
        var twitterimage = ``;

        document.title = title
        document.getElementsByTagName('meta')["title"].content = metaTitle;
        document.getElementsByTagName('meta')["description"].content = metaDescription;
        document.getElementsByTagName('meta')["keywords"].content = metaKeyword;


        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content = ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content = twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content = twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
    }, []);

    const resetemail = () => {
        const email = emailRef.current.value.trim();
        if (!email) {
            return showWarning("Please enter an email address");
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return showWarning("Please enter a valid email address");
        }

        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                showSuccess("Reset email has been sent");
                router.push("/login");
            })
            .catch((error) => {
                showWarning(error.message);
            });
    };


    return (
        <div className="bg-[#1A1A1A] h-screen">
            <div className="grid w-full h-full lg:grid-cols-2">
                {/* <div className="grid grid-cols-2 bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-full w-full"> */}
                <div className="relative items-center justify-center hidden w-full h-full lg:flex">
                    <img
                        src={require("./assets/logo/MyRevue Logo elite new.svg").default}
                        alt="logo"
                        className="h-[60px]  transform scale-150 absolute left-24 top-10"
                    />
                    <video playsInline src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Flogin.mp4?alt=media&token=52fec1d6-7ce1-4ff8-9bf8-0e12640e053e" className="max-h-[380px]" loop muted autoPlay></video>
                </div>
                <div className="h-full w-full bg-[#2B2F37] rounded-l-xl flex lg:items-center justify-center p-5">
                    <div className="w-full md:w-96">
                        <p className="text-4xl font-work">Reset Password</p>
                        <div className="w-full mt-5">
                            <input type="text" ref={emailRef} className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full" placeholder="Business Email Address" />
                        </div>
                        <div className="w-full mt-3">
                            <button onClick={() => resetemail()} className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md">Send reset link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
