import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import Modalcard from "../../components/modalcard";
import { useStateValue } from "../../context/StateProvider";
import AWN from "awesome-notifications";
import {
  Checks,
  Copy,
  DownloadSimple,
  FileCsv,
  Video,
} from "@phosphor-icons/react";
import { toast } from "react-toastify";
import HalfStarRating from "../../components/halfstarrating";
import QRCodeStyling from "qr-code-styling";
import ReactApexChart from "react-apexcharts";
import { NumberCircleEight } from "phosphor-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showSuccess, showWarning } from "../../components/toast/toast";
import axios from "axios";

function Viewcampaign() {
  const [{ user, reviews }, dispatch] = useStateValue();
  const [forms, setforms] = useState([]);
  const [form, setform] = useState({});
  const [words, setwords] = useState(null);
  const [product_details, setproduct_details] = useState({});
  const [showaddreviews, setshowaddreviews] = useState(false);
  const [product_attributes, setproduct_attributes] = useState([]);
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const [tab, settab] = useState(0);
  const [forms_search_text, setforms_search_text] = useState("");
  const [average_attributes, setaverage_attributes] = useState({});
  const [attributes_set, setattributes_set] = useState([]);
  const [selected, setSelected] = useState([]);
  const router = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const formDoc = await getDoc(doc(db, "forms/" + id));
        if (!formDoc.exists()) {
          router.replace("/engagement");
          return;
        }
        const formData = formDoc.data();
        setform(formData);

        const q = query(collection(db, "forms/" + id + "/submissions"));
        const querySnapshot = await getDocs(q);
        const cmpns = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setforms(cmpns);
        extractWords(cmpns);

        const attributeRatings = cmpns.map((cmpn) => cmpn.review_info?.average_rating ?? 3);
        const campaignAverageRating = isNaN((attributeRatings.reduce((acc, cur) => acc + cur, 0) / attributeRatings.length).toFixed(2)) ? 0 : (attributeRatings.reduce((acc, cur) => acc + cur, 0) / attributeRatings.length).toFixed(2);

        if (formData.campaign_average_rating !== campaignAverageRating) {
          await updateDoc(doc(db, "forms/" + id), { campaign_average_rating: campaignAverageRating });
        }

        setloading(false);
        setattributes_set(cmpns.map((cmpn) => cmpn.review_info?.attributes));
      } catch (error) {
        console.error("Error fetching data:", error);
        setloading(false);
      }
    };

    const getProduct = async (prodid) => {
      try {
        const headersList = {
          "Accept": "*/*",
          "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
        };

        const reqOptions = {
          url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${prodid}`,
          method: "GET",
          headers: headersList,
        };

        const response = await axios.request(reqOptions);
        if (response.data.success === true) {
          setproduct_details(response.data.product);
          setproduct_attributes(Object.keys(response.data.product?.attributes ?? {}));
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
    getProduct(form?.prodid);

    sendAmplitudeData("viewing campaign details", { campaign: id });
  }, [id, form?.prodid]);


  useEffect(() => {
    if (attributes_set.length > 0) {
      let result = attributes_set?.reduce(
        (a, c) => (
          Object.keys(c)?.forEach((k) => (a[k] = (a[k] || 0) + c[k])), a
        ),
        {}
      );
      setaverage_attributes(result);
    }
  }, [attributes_set]);

  function extractWords(arr = []) {
    try {
      const filterWords =
        "a is the me you this are we am your its it will were if and he she i their there on in what but maybe might ";
      var wordsFiltered = {};
      for (var sentence of arr) {
        sentence = sentence.review_info.review_description
          .toLowerCase()
          .split(" ");
        sentence = sentence.filter((word) => !filterWords.includes(word));
        for (const word of sentence) {
          if (wordsFiltered[word]) {
            wordsFiltered += 1;
          } else {
            wordsFiltered[word] = 1;
          }
        }
      }
      setwords(wordsFiltered);
    } catch (error) {
      console.log("error caugh- [extractWords] --> " + error.toString());
      setwords([]);
    }
  }

  const copy = () => {
    navigator?.clipboard?.writeText(window.location.host + "/userView/" + id);
    showSuccess("Link copied to clipboard");
  };

  function convertToCSV(jsonData) {
    // Function to properly escape CSV values
    const escapeCSVValue = value => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return `"${value}"`;
    };

    const header = Object.keys(jsonData[0]).map(key => escapeCSVValue(key)).join(',');
    const rows = jsonData.map(obj => Object.values(obj).map(val => escapeCSVValue(val)).join(','));
    return header + '\n' + rows.join('\n');
  }
  async function exportcsv() {
    try {
      const dataDoc = [];
      if (forms.length === 0) {
        return showWarning("No submission available")
      }
      forms.forEach((data, index) => {
        const questions = form?.questions?.map((question, index) =>
          question?.question ? `Q${index + 1}: ${question?.question}` : ""
        );
        const answers = form?.questions?.map((question, index) => {
          const answerData = data?.questions?.[index];
          return answerData ? answerData.answer : "";
        });
        const questionAnswerPairs = questions.map((question, index) => ({
          question: question,
          answer: answers[index] || ''
        }));

        const doc = {
          // "Submission Date": data?.dateAdded || "",
          "Campaign ID": form?.id || "",
          "Campaign Name": form.campaign_name || "",
          "Campaign Url": `https://brand.myrevue.app/userView/${form.id}`,
          "Submission Url": `https://brand.myrevue.app/engagement/view-submission/${data?.id}/${form.id}`,
          "Product Name": form?.productname || "",
          "Product ID": form?.prodid || "",
          "User Name": data?.user_info?.name || '',
          "User ID": data?.user_info?.id || "",
          "User Email": data?.user_info?.email || "",
          "User Phone": data?.user_info?.phone || "",
          "Status": data.substatus || "Pending",
          "Video url": data.review_type === "text" ? "Text Review" : `https://embed.myrevue.app/embed/submission/${form?.id}/${data?.id}` || "",
          "Review Title": data?.review_info?.review_name || data?.review_info?.review_description || "",
          "Average rating": data?.review_info?.average_rating || "",
          // "Purchase Mode": data?.review_info?.purchaseMode || "",
        }
        questionAnswerPairs.forEach(pair => {
          doc[pair.question] = pair.answer;
        });
        dataDoc.push(doc)
      });
      const csvData = convertToCSV(dataDoc)
      const data = `data:,${csvData}`;
      const filename = `${form?.id}.csv`;
      const aTag = document.createElement("a");

      aTag.href = data;
      aTag.download = filename;
      aTag.click();
    } catch (error) {
      console.log(error.message)
      showWarning(error.message)
      return
    }
  }

  const calculateOptionPercent = (count, total) => {
    if (!count) return 0;

    count = count / total;
    count *= 1000;
    count = Math.round(count);
    return count / 10;
  };
  const handleSelectClick = (e) => {
    if (selected.includes(e.id)) {
      setSelected(selected.filter((r) => r !== e.id));
    } else {
      setSelected([...selected, e.id]);
    }
  };

  async function updateform() {
    if (selected.length === 0) {
      return new AWN().warning("Please select atleast one review");
    }

    await updateDoc(doc(db, "forms", form.id), { reviews: selected }).then(
      () => {
        new AWN().success("Reviews added in campaign");
        window.location.reload();
        return;
      }
    );
  }


  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    image:
      "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20only%20logo.png?alt=media&token=d1d344f7-ce12-44c1-826d-3963af17ad94",
    data: "http://brand.myrevue.app/userView/" + id,
    margin: 20,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: "extra-rounded", color: "#000000" },
    backgroundOptions: { color: "#ffffff" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#ff005c" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#ff005c" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });

  async function downloadQRCode() {
    qrCode.download({
      name: id,
      extension: "png",
    });
  }

  const pieData = {
    labels: ["Category 1", "Category 2", "Category 3"],
    series: [44, 55, 41],
  };

  // Define options for the pie chart
  const pieOptions = {
    chart: {
      type: "pie",
    },
    labels: pieData.labels,
  };
  return (
    <div className="min-h-screen text-white scroll-smooth">
      {showaddreviews === true && (
        <Modalcard close={setshowaddreviews}>
          <div className="h-[80vh] w-[90vw] bg-[#232326] overflow-y-auto p-5">
            <div className="pb-5">
              <p className="text-2xl">Reviews</p>
              <p className="">
                Selected reviews will be displayed on campaigns page.
              </p>
            </div>

            <div className="flex flex-wrap gap-5 ">
              {reviews
                ?.filter((val) => val.bpsId === form?.prodid)
                ?.map((rev, index) => (
                  <div
                    className={`w-40 aspect-[9/16] ${selected.includes(rev.id) &&
                      "border-[3px] border-blue-500 rounded-md"
                      }`}
                    key={index}
                    onClick={() => handleSelectClick(rev)}
                  >
                    <img
                      src={rev?.thumbnail}
                      alt=""
                      className="object-cover w-full h-full rounded-md"
                    />
                  </div>
                ))}
            </div>
            <div className="mt-5">
              <button
                className="bg-[#121212] px-8 appearance-none outline-none hover:bg-[#4611ea] py-2 mr-2"
                onClick={() => updateform()}
              >
                Add reviews
              </button>
            </div>
          </div>
        </Modalcard>
      )}
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <p className="">Gettings your submissions ready </p>
        </div>
      ) : (
        <div className="flex items-start justify-between gap-10 p-10">
          <div className="flex-1 w-full">
            <div className="flex items-center justify-between flex-1">
              <div className="rounded-md h-28 w-28">
                <img
                  src={form?.productimage}
                  alt="product_image"
                  className="rounded-md"
                />
              </div>
              <div className="bg-[#3B3D42] h-auto rounded-lg drop-shadow-lg w-full flex-1 flex flex-col justify-center items-center px-5 ml-10 gap-3 py-5">
                <p className="text-center text-[#D9D9D9] tracking-wide">
                  Shareable link:
                </p>
                <div className="flex items-center gap-5">
                  <div className="items-center h-12 text-xs bg-[#2E3036] w-fit flex gap-10 rounded-full pl-10">
                    <p>{window?.location?.host + "/userView/" + id}</p>
                    <button
                      className="p-2 m-1 rounded-full outline-none appearance-none hover:bg-green-500 bg-opacity-80"
                      onClick={() => copy()}
                    >
                      <Copy size={22} color="white" />
                    </button>
                  </div>
                  <button
                    className="bg-[#2E3036] h-12 hover:bg-blue-500 text-sm bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none flex items-center gap-3 px-3"
                    onClick={() => downloadQRCode()}
                  >
                    <DownloadSimple size={22} color="white" />
                    Download QR Code
                  </button>
                </div>
                <div className="w-full text-left">
                  <p className="">Most used Words</p>
                  <div className="flex flex-wrap gap-2 my-1">
                    {words
                      ? Object.keys(words ?? {}).map((k) => (
                        <button
                          key={k}
                          className="px-3 py-1 text-xs border rounded-full border-violet-700"
                        >
                          {k}-{words[k]}
                        </button>
                      ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid w-full grid-cols-5 mt-10">
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 0
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(0);
                  setforms_search_text("");
                }}
              >
                All ({forms.length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 1
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(1);
                  setforms_search_text("pending");
                }}
              >
                Pending (
                {
                  forms.filter(
                    (val) => val.substatus === "pending" || !val?.substatus
                  ).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 2
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(2);
                  setforms_search_text("In Review");
                }}
              >
                In Review (
                {forms.filter((val) => val.substatus === "In Review").length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 3
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(3);
                  setforms_search_text("published");
                }}
              >
                Published (
                {forms.filter((val) => val.substatus === "published").length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${tab === 4
                  ? "border-b border-[#D9D9D9] text-white"
                  : "bg-transparent text-[#808080]"
                  }`}
                onClick={() => {
                  settab(4);
                  setforms_search_text("rejected");
                }}
              >
                Rejected (
                {forms.filter((val) => val.substatus === "rejected").length})
              </button>
            </div>
            <div className="flex flex-wrap flex-1 gap-8 mt-10">
              {forms
                .filter((val) => {
                  if (forms_search_text === "") {
                    return val;
                  } else if (
                    val?.substatus
                      ?.toLowerCase()
                      .includes(forms_search_text?.toLowerCase()) ||
                    !val?.substatus
                  ) {
                    return val;
                  } else return false;
                })
                .map((doc, index) => (
                  <Link
                    to={"/engagement/view-submission/" + doc.id + "/" + id}
                    key={index}
                  >
                    <div
                      key={index}
                      className="bg-gradient-to-b from-[#42454B] to-[#383A3F] aspect-[12/16] w-60  rounded-lg drop-shadow-lg relative flex  justify-center"
                    >
                      <div className="h-full w-full  bg-[#42454B] absolute rounded-lg  shadow-2xl bg-opacity-0">
                        <div className="right-0 float-right m-2 tracking-wide">
                          {(doc?.substatus === "pending" ||
                            !doc?.substatus) && (
                              <div className="bg-[#dea513]  px-5 py-1 rounded">
                                Pending
                              </div>
                            )}
                          {doc?.substatus === "In Review" && (
                            <div className="px-5 py-1 bg-orange-500 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "rejected" && (
                            <div className="px-5 py-1 bg-red-500 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "published" && (
                            <div className="px-5 bg-green-500 rounded py-">
                              {doc?.substatus}
                            </div>
                          )}
                        </div>
                        <div className="shadow-top bottom-0 absolute rounded-b-lg pb-2 bg-gradient-to-b from-transparent via-[#00000070] to-[#00000090] w-full">
                          <p className="p-1 text-xs tracking-wider ">
                            Campaign Name :{" "}
                            {doc?.campaign_info?.campaign_name ??
                              form?.campaign_name}
                          </p>
                          <p className="px-1 text-xs tracking-wider ">
                            Reviewer : {doc?.user_info?.name}
                          </p>
                          <p className="px-1 text-xs tracking-wider ">
                            Type : {doc?.reviewUrl !== null ? "Video" : "Text"}
                          </p>
                        </div>
                      </div>
                      <div className="aspect-[12/16] w-60">
                        {!doc?.thumbnail ? (
                          <video playsInline
                            src={doc?.reviewUrl}
                            className="object-cover w-full h-full rounded-lg"
                          ></video>
                        ) : (
                          <img
                            src={doc?.thumbnail}
                            className="object-cover w-full h-full rounded-lg"
                            alt="thumbnail"
                          ></img>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div className="bg-[#383A3F] w-full max-w-[422px] min-h-full p-5 rounded-lg">
            <div className="flex flex-col items-center w-full gap-5">
              {/* <button
                className="bg-[#2E3036] hover:bg-[#151516] flex items-center gap-8 h-14 rounded-full px-5 w-fit"
                onClick={() => setshowaddreviews(true)}
              >
                <video playsInline  size={28} color="#ffffff" />
                Add reviews in campaign form
              </button> */}
              <button
                className="bg-[#438B4E] hover:bg-[#25da40]  flex items-center gap-8 h-12 rounded-full px-5 w-fit"
                onClick={() => exportcsv()}
              >
                <FileCsv size={28} color="#ffffff" />
                Generate CSV
              </button>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Campaign Details</p>
              </div>
              <div className="">
                <p className="mt-2 text-sm tracking-wider">
                  Campaign Name :{" "}
                  {doc?.campaign_info?.campaign_name ?? form?.campaign_name}
                </p>
                <p className="mt-2 text-sm tracking-wider">
                  Text Reviews :{" "}
                  {forms.filter((val) => val.reviewUrl === null).length}
                </p>
                <p className="mt-2 text-sm tracking-wider">
                  Video Reviews :{" "}
                  {forms.filter((val) => val.reviewUrl !== null).length}
                </p>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center text-[#D9D9D9]">
                  Product Details
                </p>
              </div>
              <div className="">
                <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">
                  Product Name : {form?.productname}
                </p>
                <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">
                  Product ID : {form?.prodid}
                </p>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Attributes</p>
              </div>
              <div className="">
                <div className="grid grid-cols-2">
                  {product_attributes?.map((attribute, index) => (
                    <div
                      className={`${index % 2 === 0
                        ? "grid items-end justify-start my-2 select-none"
                        : "grid items-end justify-center my-2 select-none"
                        }`}
                      key={index}
                    >
                      <p className="pb-2 text-center">{attribute}</p>
                      <HalfStarRating
                        sizeh={22}
                        rating={average_attributes?.[attribute] / forms.length}
                        colorcode="#E2BE45"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center text-[#D9D9D9]">
                  Questions Poll
                </p>
              </div>
              {form?.questions?.map((question, index) => (
                <div className="my-5" key={index}>
                  <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">
                    Question {question.id} : {question.question}
                  </p>
                  <div className="bg-[#2E3036] w-full h-12 rounded-full flex justify-between items-center px-5 mt-2">
                    <span className="">{question.opt1}</span>
                    <span className="">
                      {calculateOptionPercent(
                        question[question.opt1],
                        forms.length
                      )}
                      %
                    </span>
                  </div>
                  <div className="bg-[#2E3036] w-full h-12 rounded-full flex justify-between items-center px-5 mt-2">
                    <span className="">{question.opt2}</span>
                    <span className="">
                      {calculateOptionPercent(
                        question[question.opt2],
                        forms.length
                      )}
                      %
                    </span>
                  </div>
                  <div className="bg-[#2E3036] w-full h-12 rounded-full flex justify-between items-center px-5 mt-2">
                    <span className="">{question.opt3}</span>
                    <span className="">
                      {calculateOptionPercent(
                        question[question.opt3],
                        forms.length
                      )}
                      %
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Viewcampaign;
