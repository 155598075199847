import React, { useEffect, useMemo, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import axios from "axios";
import moment from "moment";
import PostListTile from "./PostListTile";

function PostListAnalytic({ pageid }) {
  const [{ user }, dispatch] = useStateValue();
  const [insight, setInsight] = useState(null);

  const token = useMemo(() => {
    var token = user.social.facebook_pages.find(
      (p) => p.id === pageid
    )?.access_token;
    if (!token) {
      token = user.social.facebook.access_token;
    }
    return token;
  }, [user]);

  useEffect(() => {
    axios
      .get(
        `https://graph.facebook.com/v19.0/${pageid}/posts?fields=created_time,id,message,attachments{media,type},likes.limit(1).summary(true),comments.limit(1).summary(true)&access_token=${token}`
      )
      .then((response) => {
        console.log(response.data);
        setInsight(response.data.data);
      });
  }, []);

  if (!insight)
    return (
      <div className="">
        <p className="">Loading...</p>
      </div>
    );
  else
    return (
      <div className="px-5 py-3 rounded-md bg-bg_highlight">
        <p className="text-xl tracking-wide">Posts Analytics</p>
        <div className="w-full pt-5 space-y-5 list-none">
          {insight?.map((card, index) => (
            <PostListTile card={card} pageToken={token} />
          ))}
        </div>
      </div>
    );
}

export default PostListAnalytic;
