import React from 'react'

export default function ElevatedCard({ children }) {
    return (
        <div className="text-black imIIYU" >
            <div className=" dLlWlu">
                <div className="p-5">
                    {children}
                </div>
            </div>
            <div className="dQUuuB right"></div>
            <div className="dQUuuB bottom"></div>
        </div>
    )
}
