import React from "react";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Coupons from "./coupons";
import Sharereview from "./sharereview";

export default function Assets() {
  const location = useLocation();
  let { path, url } = useRouteMatch();

  return (
    <div className="flex flex-col h-full">
      <div className="grid grid-cols-3 gap-x-10 px-16 mx-auto border-b border-[#ffffff17] pb-5 w-full pt-5">
        <Link to={`${url}/coupons`}>
          <button
            className={`w-full ${location.pathname === path ||
              location.pathname.includes("/coupons")
              ? "bg-[#90a7eb]"
              : "bg-[#121212] hover:bg-[#90a7eb]"
              } h-[2.7rem] text-white rounded-md smooth_shadow`}
          >
            Coupons
          </button>
        </Link>
        <Link to={`${url}/sharereview`}>
          <button
            className={`w-full ${location.pathname.includes("/sharereview")
              ? "bg-[#90a7eb]"
              : "bg-[#121212] hover:bg-[#90a7eb]"
              } h-[2.7rem] text-white rounded-md smooth_shadow`}
          >
            Share reviews
          </button>
        </Link>
        <Link to="/collector">
          <button className="w-full bg-[#121212] hover:bg-[#90a7eb] h-[2.7rem] text-white rounded-md smooth_shadow">
            Campaigns
          </button>
        </Link>
      </div>

      <div className="flex-1">
        <Switch>
          <Route path={path} exact component={Coupons} />
          <Route path={`${path}/coupons`} component={Coupons} />
          <Route path={`${path}/sharereview`} component={Sharereview} />
        </Switch>
      </div>
    </div>
  );
}
