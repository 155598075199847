import React, { lazy, Suspense } from 'react'
import Loadingdot from './components/loadingdot';
const NotloginfooterNew = lazy(() => import("./layouts/NotloginfooterNew"));
const NotLoginHeaderNew = lazy(() => import("./layouts/NotLoginHeaderNew"));
export default function Terms() {
    return (
        <div className="bg-[#101010] ">
            <Suspense fallback={<Loadingdot />}>
                <NotLoginHeaderNew />
                <div className="font-work w-full p-5 md:p-10 max-w-[1380px] text-white mx-auto">
                    <h2 className="text-lg font-medium tracking-wide text-center font-work">
                        TribeVibe Experiences Private Limited
                    </h2>
                    <h3 className="text-lg font-medium tracking-wide text-center uppercase font-work">
                        TERMS AND CONDITIONS
                    </h3>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="py-3 text-justify font-work">
                            These terms of service (the “Terms”) govern your access to and use
                            of websites (the “Site”). To access or use the Site, you must be 18
                            years or older and have the requisite power and authority to enter
                            into these Terms. By accessing or using the Site, you are agreeing
                            to these Terms and concluding a legally binding contract with
                            Tribevibe Experiences Private Limited which is running its
                            operations under the brand name MYREVUE (“we” or “us “) in Indore.
                            Do not access or use the Site if you are unwilling or unable to be
                            bound by the Terms. You acknowledge and agree that by accessing or
                            using the MyRevue site or services, or posting or retrieving any
                            content on the site or through the services, you are indicating that
                            you have read, understand, and agree to be bound by these terms. If
                            you do not agree to these terms, then you have no right to access or
                            use the site, services or MyRevue content. If you are using the
                            Services on behalf of an organisation BRANDS, you are agreeing to
                            these Terms for that organisation and promising that you have the
                            authority to bind that organisation to these terms. In that case,
                            “you”, “your”, “user brand” or “Client” will refer to that
                            organisation. In the context of these Terms, the “Content” shall
                            refer to any type of material which shall include but, is not
                            limited to any reviews, illustrations, graphics, pictures, images,
                            photos, profiles, notes, messages, information, data, sounds, music,
                            articles, videos, code, themes, and software. The Site may be
                            modified, updated, interrupted, suspended, or discontinued at any
                            time giving notice to the user.
                            These terms of service (the “Terms”) govern your access to and use
                            of websites (the “Site”). To access or use the Site, you must be 18
                            years or older and have the requisite power and authority to enter
                            into these Terms. By accessing or using the Site, you are agreeing
                            to these Terms and concluding a legally binding contract with
                            Tribevibe Experiences Private Limited which is running its
                            operations under the brand name MYREVUE (“we” or “us “) in Indore.
                            Do not access or use the Site if you are unwilling or unable to be
                            bound by the Terms. You acknowledge and agree that by accessing or
                            using the MyRevue site or services, or posting or retrieving any
                            content on the site or through the services, you are indicating that
                            you have read, understand, and agree to be bound by these terms. If
                            you do not agree to these terms, then you have no right to access or
                            use the site, services or MyRevue content. If you are using the
                            Services on behalf of an organisation BRANDS, you are agreeing to
                            these Terms for that organisation and promising that you have the
                            authority to bind that organisation to these terms. In that case,
                            “you”, “your”, “user brand” or “Client” will refer to that
                            organisation. In the context of these Terms, the “Content” shall
                            refer to any type of material which shall include but, is not
                            limited to any reviews, illustrations, graphics, pictures, images,
                            photos, profiles, notes, messages, information, data, sounds, music,
                            articles, videos, code, themes, and software. The Site may be
                            modified, updated, interrupted, suspended, or discontinued at any
                            time giving notice to the user.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">1: USER ACCOUNTS</p>
                        <p className="py-3 text-justify font-work">
                            You may create an account and provide specific information about
                            yourself when you use some of the features that are offered through
                            the Site. We ask that you provide complete and accurate information
                            about yourself to bolster your credibility as a contributor to the
                            Site. MyRevue handles such information with the utmost attention,
                            care, and security. Nonetheless, you, not MyRevue, shall be
                            responsible for maintaining and protecting your Credentials in
                            connection with the Services. If your contact information, or other
                            information relating to your username or password changes, you must
                            notify MyRevue promptly and keep such information current. You are
                            solely responsible for any activity using your Credentials, whether
                            or not you authorised that activity. You should immediately notify
                            MyRevue of any unauthorised use of your Credentials or if your email
                            or password has been hacked or stolen. If you discover that someone
                            is using your Identifications without your consent, or you discover
                            any other breach of security, you agree to notify MyRevue instantly.
                            You are also responsible for all activities that occur in connection
                            with your account. We reserve the right to close your account at any
                            time for any or no reason. You are responsible for maintaining the
                            confidentiality of your account password.
                            You may create an account and provide specific information about
                            yourself when you use some of the features that are offered through
                            the Site. We ask that you provide complete and accurate information
                            about yourself to bolster your credibility as a contributor to the
                            Site. MyRevue handles such information with the utmost attention,
                            care, and security. Nonetheless, you, not MyRevue, shall be
                            responsible for maintaining and protecting your Credentials in
                            connection with the Services. If your contact information, or other
                            information relating to your username or password changes, you must
                            notify MyRevue promptly and keep such information current. You are
                            solely responsible for any activity using your Credentials, whether
                            or not you authorised that activity. You should immediately notify
                            MyRevue of any unauthorised use of your Credentials or if your email
                            or password has been hacked or stolen. If you discover that someone
                            is using your Identifications without your consent, or you discover
                            any other breach of security, you agree to notify MyRevue instantly.
                            You are also responsible for all activities that occur in connection
                            with your account. We reserve the right to close your account at any
                            time for any or no reason. You are responsible for maintaining the
                            confidentiality of your account password.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">2: LICENSE</p>
                        <p className="py-3 text-justify font-work">
                            MyRevue gives you a personal, royalty-free, non-assignable, and
                            non-exclusive licence to use the software or website provided to you
                            by MyRevue as part of the Services provided to you by MyRevue.
                            Please note that you may use this software or optimised site for
                            internal business purposes only, and only in the manner permitted by
                            the Terms. You may not licence, sublicense, sell, resell, transfer,
                            assign, distribute or otherwise commercially exploit or make
                            available to any third party the Services or the content provided by
                            or on behalf of MyRevue through the Services (the content) in any
                            way, except as allowed by the Terms. Other than your User Content,
                            all the stored or available content, including the Services, all
                            other Users’ Content, any content made available by us or any third
                            party, and the variety and configuration of all such Content and
                            User Content ( hereinafter collectively referred to the “Service
                            Content”), shall be considered our property. For Optimised sites, we
                            hereby grant you a restricted, private, non-transferable,
                            conditional, and revocable, at any time with or without notice or
                            cause, and non-exclusive right and licence to access and then use
                            the Services. We own the Optimised Site that we create for you.
                            Therefore, as long as you pay your subscription, you can use the
                            Site for your business.
                            MyRevue gives you a personal, royalty-free, non-assignable, and
                            non-exclusive licence to use the software or website provided to you
                            by MyRevue as part of the Services provided to you by MyRevue.
                            Please note that you may use this software or optimised site for
                            internal business purposes only, and only in the manner permitted by
                            the Terms. You may not licence, sublicense, sell, resell, transfer,
                            assign, distribute or otherwise commercially exploit or make
                            available to any third party the Services or the content provided by
                            or on behalf of MyRevue through the Services (the content) in any
                            way, except as allowed by the Terms. Other than your User Content,
                            all the stored or available content, including the Services, all
                            other Users’ Content, any content made available by us or any third
                            party, and the variety and configuration of all such Content and
                            User Content ( hereinafter collectively referred to the “Service
                            Content”), shall be considered our property. For Optimised sites, we
                            hereby grant you a restricted, private, non-transferable,
                            conditional, and revocable, at any time with or without notice or
                            cause, and non-exclusive right and licence to access and then use
                            the Services. We own the Optimised Site that we create for you.
                            Therefore, as long as you pay your subscription, you can use the
                            Site for your business.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">3: USER CONTENT LICENCES</p>
                        <p className="py-3 text-justify font-work">
                            We do not claim ownership of any content that is submitted,
                            uploaded, published, posted, input, transmitted, copied, made
                            available, distributed, or otherwise conveyed to us or any third
                            party through the Service or Site (collectively referred to as the
                            “User Content”). By providing any User Content through your user
                            account, you agree and acknowledge the following:
                            We do not claim ownership of any content that is submitted,
                            uploaded, published, posted, input, transmitted, copied, made
                            available, distributed, or otherwise conveyed to us or any third
                            party through the Service or Site (collectively referred to as the
                            “User Content”). By providing any User Content through your user
                            account, you agree and acknowledge the following:
                        </p>
                        <ul>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that We reserve the right, in our sole and absolute discretion,
                                    to refuse to accept, transmit or display any User Content;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that you have granted us the global, royalty-free, completely
                                    paid, immutable, irreversible, non-exclusive privilege and
                                    licence for using, distributing, reproducing, reformatting,
                                    modifying, adapting, deleting, publish, translate, copy, edit,
                                    perform, display, and create derivative works from such User
                                    Content as permitted by the Terms;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that MyRevue does not acknowledge any User Content which is
                                    paid/sponsored, if the same is found and no express
                                    authorization is granted by MyRevue, same will labelled as
                                    “Sponsored Content”. that the content posted by the user
                                    specifically by the Brands will be labelled as “Posted by
                                    Brand”.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that any content published by brand can be used by MyRevue in
                                    any manner that they deem fit (Read License Terms)
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that any unpublished video content will be solely brand’s
                                    property and the content cannot be used by MyRevue in any way.
                                    MyRevue can however use the reports for analysis of unpublished
                                    content.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that Brand cannot use any content posted on MyRevue anywhere
                                    else without express approval of MyRevue.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Any content posted by user on MyRevue can be used by brand if
                                    they have taken the appropriate payment plan.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that to defend and keep us unharmed from any charges, damages,
                                    losses, or expenses incurred by us as a result of any
                                    third-party allegations that your User Content infringes on the
                                    rights of another person;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that we may keep a copy(ies) content for record keeping or
                                    security measures, or to otherwise provide our Services to you
                                    or others, including but not limited to, through aggregation or
                                    predictive analysis that we may provide you or others
                                    periodically even if you delete your User Account of any such
                                    User Content.
                                </p>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that We reserve the right, in our sole and absolute discretion,
                                    to refuse to accept, transmit or display any User Content;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that you have granted us the global, royalty-free, completely
                                    paid, immutable, irreversible, non-exclusive privilege and
                                    licence for using, distributing, reproducing, reformatting,
                                    modifying, adapting, deleting, publish, translate, copy, edit,
                                    perform, display, and create derivative works from such User
                                    Content as permitted by the Terms;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that MyRevue does not acknowledge any User Content which is
                                    paid/sponsored, if the same is found and no express
                                    authorization is granted by MyRevue, same will labelled as
                                    “Sponsored Content”. that the content posted by the user
                                    specifically by the Brands will be labelled as “Posted by
                                    Brand”.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that any content published by brand can be used by MyRevue in
                                    any manner that they deem fit (Read License Terms)
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that any unpublished video content will be solely brand’s
                                    property and the content cannot be used by MyRevue in any way.
                                    MyRevue can however use the reports for analysis of unpublished
                                    content.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that Brand cannot use any content posted on MyRevue anywhere
                                    else without express approval of MyRevue.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Any content posted by user on MyRevue can be used by brand if
                                    they have taken the appropriate payment plan.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that to defend and keep us unharmed from any charges, damages,
                                    losses, or expenses incurred by us as a result of any
                                    third-party allegations that your User Content infringes on the
                                    rights of another person;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    that we may keep a copy(ies) content for record keeping or
                                    security measures, or to otherwise provide our Services to you
                                    or others, including but not limited to, through aggregation or
                                    predictive analysis that we may provide you or others
                                    periodically even if you delete your User Account of any such
                                    User Content.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">4: CONTENT GUIDELINES</p>
                        <p className="py-3 text-justify font-work">
                            Any of the following content, or links to such, any such content, is
                            not permitted to be published on or via MyRevue:
                            Any of the following content, or links to such, any such content, is
                            not permitted to be published on or via MyRevue:
                        </p>
                        <ul>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Users (Brands) will abstain posting Content that are
                                    pornographic, sexually suggestive, or violent in appearance or
                                    nature;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Illegal Material including but not limited to stolen copyrighted
                                    material;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Information on illicit enterprises or activities.
                                </p>
                            </li>
                            <ul>
                                <li>
                                    <p className="py-3 text-justify font-work">
                                        Users (Brands) will abstain posting Content that are
                                        pornographic, sexually suggestive, or violent in appearance or
                                        nature;
                                    </p>
                                </li>
                                <li>
                                    <p className="py-3 text-justify font-work">
                                        Illegal Material including but not limited to stolen copyrighted
                                        material;
                                    </p>
                                </li>
                                <li>
                                    <p className="py-3 text-justify font-work">
                                        Information on illicit enterprises or activities.
                                    </p>
                                </li>
                                <li>
                                    <p className="py-3 text-justify font-work">
                                        Pirated software sites, such as cracking software programs,
                                        codes, or archives;
                                    </p>
                                    <p className="py-3 text-justify font-work">
                                        Pirated software sites, such as cracking software programs,
                                        codes, or archives;
                                    </p>
                                </li>
                                <li>
                                    <p className="py-3 text-justify font-work">
                                        Contents having defamatory statements; User (Brand) will be
                                        solely responsible for any content that is posted by them on
                                        Myrevue.
                                    </p>
                                    <p className="py-3 text-justify font-work">
                                        Contents having defamatory statements; User (Brand) will be
                                        solely responsible for any content that is posted by them on
                                        Myrevue.
                                    </p>
                                </li>
                            </ul>
                        </ul></div>
                    <div>
                        <p className="underline font-work">5: RESPONSIBILITY FOR YOUR CONTENT</p>
                        <p className="py-3 text-justify font-work">
                            Users (Brands) alone are responsible for Your Content, and once
                            published, it cannot always be withdrawn. You assume all risks
                            associated with Your Content, including anyone’s reliance on its
                            quality, accuracy, or reliability, or any disclosure by you of
                            information in Your Content that makes you personally identifiable.
                            You represent that you own or have the necessary permissions to use
                            and authorise the use of Your Content as described herein. You may not
                            imply that Your Content “other than labelled content” is in any way
                            sponsored or endorsed.
                        </p>
                        <p className="py-3 text-justify font-work">
                            You may expose yourself to liability if, for example, Your Content
                            contains material that is false, intentionally misleading, or
                            defamatory; violates any third-party right, including any copyright,
                            trademark, patent, trade secret, moral right, privacy right, right of
                            publicity, or any other intellectual property or proprietary right;
                            contains material that is unlawful, including illegal hate speech or
                            pornography; exploits or otherwise harms minors; or violates or
                            advocates the violation of any law or regulation.
                        </p>
                        <p className="py-3 text-justify font-work">
                            By submitting any User Content to MyRevue, you represent that: you
                            have the required rights and/or authorization to submit such User
                            Content to MyRevue as set forth herein, without any obligation by
                            MyRevue to pay any fees or other limitations; and you guaranty such
                            User Content does not violate any third-party rights or laws, rules,
                            or regulations. You shall also observe any local laws governing online
                            behaviour and appropriate content, including any rules governing data
                            export.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">6: UNAUTHORISED USE OF MATERIALS</p>
                        <p className="py-3 text-justify font-work">
                            Subject to our Privacy Policy, any statement or material that you
                            transmit to this site or us, whether by electronic mail, post, or
                            other means, for any reason, will be treated as non-confidential and
                            non-proprietary. While you retain all rights in such communications or
                            material, you grant our agents and us and affiliates a non-exclusive,
                            paid-up, perpetual, and worldwide right to copy, distribute, display,
                            perform, publish, translate, adapt, modify, and otherwise use such
                            material for any purpose regardless of the form or medium (now known
                            or not currently known) in which it is used.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">7: RESTRICTIONS</p>
                        <p className="py-3 text-justify font-work">
                            Users (Brands) agree not to do any of the following while using the
                            Site, Services, or MyRevue Content:
                        </p>

                        <ul>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Access, post, submit or transmit any text, graphics, images,
                                    software, video, information, or other material that: (i)
                                    infringes, misappropriates, or violates a third party’s patent,
                                    copyright, trademark, trade secret, moral rights, or other
                                    intellectual property rights, or rights of publicity or privacy;
                                    (ii) violates, or encourages any conduct that would violate, any
                                    applicable law or regulation or would give rise to civil
                                    liability; (iii) is fraudulent, false, misleading or deceptive;
                                    (iv) is defamatory, obscene, vulgar or offensive; (v) constitutes
                                    child pornography or child erotica; (vi) promotes discrimination,
                                    bigotry, racism, hatred, harassment or harm against any individual
                                    or group.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Use, display, mirror, frame, or utilise framing techniques to
                                    enclose the Site or Services; any individual element or material
                                    within the Site or Services. MyRevue name; any MyRevue trademark,
                                    logo or other proprietary information; the content of any text;
                                    the layout and design of any page or form contained on a page,
                                    without MyRevue express written consent;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Access, tamper with, or use non-public areas of the Site or
                                    Services, MyRevue computer systems, or the technical delivery
                                    systems of MyRevue providers;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Attempt to probe, scan, or test the vulnerability of any MyRevue
                                    system or network or breach any security or authentication
                                    measures;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Avoid, bypass, remove, deactivate, impair, descramble, or
                                    otherwise circumvent any technological measure implemented by
                                    MyRevue or any other third party (including another user) to
                                    protect the Site, Services or MyRevue Content;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Send any unsolicited or unauthorized advertising, promotional
                                    materials, email, junk mail, spam, chain letters, or other forms
                                    of solicitation;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Use the Site, Services or MyRevue Content for the determination of
                                    bringing an intellectual property infringement claim against
                                    MyRevue or to create a product or service competitive with the
                                    Services.
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Attempt to decipher, decompile, disassemble or reverse engineer
                                    any of the software used to provide the Site, Services, or MyRevue
                                    Content;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Interfere with, or attempt to interfere with, the access of any
                                    user, host or network connected to the Site or Services,
                                    including, without limitation, sending a virus, overloading,
                                    flooding, spamming, or mail-bombing the Site;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Collect or store any personally identifiable information from the
                                    Site or Services from other users of the Site or Services without
                                    their express permission;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Impersonate or misrepresent your relationship with any person or
                                    entity;
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Violate any applicable law or regulation; or
                                </p>
                            </li>
                            <li>
                                <p className="py-3 text-justify font-work">
                                    Embolden or aid any other individual to do any of the preceding.
                                    MyRevue will have the right to investigate and prosecute
                                    violations of any of the above, including intellectual property
                                    rights infringement and Site and Services security issues, to the
                                    fullest extent of the law. MyRevue may involve and collaborate
                                    with law enforcement authorities in prosecuting users who violate
                                    these Terms. You acknowledge that MyRevue has no obligation to
                                    monitor your access to the Site, Services, or MyRevue Content or
                                    to review or edit any User Content. However, MyRevue has the right
                                    to do so to operate the Site and Services, to ensure your
                                    compliance with these Terms, or to comply with applicable law or
                                    the order or requirement of a court, administrative agency, or
                                    other governmental bodies. MyRevue reserves the right, at any time
                                    and without prior notice, to remove or disable access to any
                                    MyRevue Content and any User Content, that MyRevue reviews, in its
                                    sole discretion, considers violating these Terms or otherwise
                                    harmful to the Site or Services.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">
                            8: COMPLIANCE WITH THIRD PARTY REVIEW SITE POLICIES
                        </p>
                        <p className="py-3 text-justify font-work">
                            Clients using the MyRevue platform agree to obey the Terms of Service
                            of all other review sites. This includes but is not limited to Google,
                            Facebook and other review sites. When using the MyRevue platform,
                            Clients are not allowed to use our platform to send review request
                            invitations to their customers to post a review on other review sites.
                            Many Review sites prohibits review solicitation of any kind or method.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">9: OWNERSHIP</p>
                        <p className="py-3 text-justify font-work">
                            The Site, Services, and MyRevue Content are protected by copyright,
                            trademark, and other laws of the Republic of India. Except as provided
                            in these Terms, MyRevue and its licensors exclusively own all rights,
                            titles, and interests in and to the Site, Services, and MyRevue
                            Content, including all associated intellectual property rights. You
                            will not remove, alter or obscure any copyright, trademark, service
                            mark, or other proprietary rights notices incorporated in or
                            accompanying the Site, Services, or MyRevue Content. MyRevue claims no
                            ownership interest in any Third-Party Material and disclaims any
                            liability concerning those materials.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">10: CHANGES TO SERVICES OR TERMS</p>
                        <p className="py-3 text-justify font-work">
                            MyRevue reserves the right, in its sole discretion, to modify, suspend
                            or terminate the Site or Services or to modify these Terms, at any
                            time. If MyRevue modify these Terms, we will post the modification on
                            the Site or otherwise provide you with notice of the modification
                            connect@myrevue.app. By continuing to access or use the Site or
                            Services after we have posted a modification to these Terms or have
                            provided you with notice of a modification, you are indicating that
                            you agree to be bound by the modified Terms. If the modified Terms are
                            not acceptable to you, your only recourse is to cease using the Site
                            and Services. These Terms may only be modified in writing as outlined
                            in this paragraph and may not be modified orally. Please visit this
                            page regularly to review these Terms for any changes.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">11: LIMITATION OF LIABILITY</p>
                        <p className="py-3 text-justify font-work">
                            In no event will MyRevue, or its licensors, be liable with respect to
                            any subject matter of this agreement under any contract, negligence,
                            strict liability, or other legal or equitable theory for: (i) any
                            special, incidental or consequential damages; (ii) the cost of
                            procurement for substitute products or services; (iii) for
                            interruption of use or loss or corruption of data; or (iv) for any
                            amounts that exceed the fees paid by you to MyRevue, under this
                            agreement. MyRevue shall have no liability for any failure or delay
                            due to matters beyond their reasonable control.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">12: Indemnity and Release</p>
                        <p className="py-3 text-justify font-work">
                            You shall indemnify and hold harmless TribeVibe Experiences Pvt. Ltd.
                            ("MyRevue"), its subsidiaries, affiliates and their respective
                            officers, directors, agents and employees, from any claim or demand,
                            or actions including reasonable attorney's fees, made by any third
                            party or penalty imposed due to or arising out of your breach of these
                            Conditions of Use or any document incorporated by reference, or your
                            violation of any law, rules, regulations or the rights of a third
                            party.
                        </p>
                        <p className="py-3 text-justify font-work">
                            You hereby expressly release TribeVibe Experiences Private Limited.
                            ("MyRevue") and/or its affiliates and/or any of its officers and
                            representatives from any cost, damage, liability or other consequence
                            of any of the actions/ inactions of the vendors and specifically
                            waiver any claims or demands that you may have in this behalf under
                            any statute, contract or otherwise.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">13: GOVERNING LAW AND FORUM FOR DISPUTES</p>
                        <p className="py-3 text-justify font-work">
                            The laws of Republic of India, State Madhya Pradesh, District Indore
                            shall govern this Agreement without regard to principles of conflicts
                            of law.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">14: AMENDMENTS</p>
                        <p className="py-3 text-justify font-work">
                            We retain the right, with or without notice, to perform maintenance
                            on, add to, remove from, upgrade and update, suspend, and alter the
                            Services or any portion thereof at any time and from time to time. You
                            agree that we will not be responsible to you or any third party in any
                            way for any such upkeep, addition, deletion, upgrading, update,
                            suspension, or change, regardless of any provision hereof.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">15: TERMINATION</p>
                        <p className="py-3 text-justify font-work">
                            You may terminate the Terms at any time by closing your account,
                            discontinuing your use of the Site, and providing MyRevue with a
                            notice of termination here. Please review our Privacy Policy for
                            information about what we do with your account when terminated. No
                            cancellation is effective until you get confirmation of your
                            cancellation. If you violate a significant provision of these Terms of
                            Service, we may immediately discontinue the Services. We may not be
                            bound to provide any backup or retrieval of your Content if your
                            access to the Services is terminated. We are under no obligation to
                            provide any backup or retrieval of your Content if your access to the
                            Services are terminated.
                        </p>
                        <p className="py-3 text-justify font-work">
                            We may close your account, suspend your ability to use certain
                            portions of the Site, and ban you altogether from the Site for any or
                            no reason and without notice or liability of any kind. Any such action
                            could prevent you from accessing your account, the Site, Your Content,
                            Site Content, or any other related information. After closing your
                            account, you will not be billed any further. However, we cannot refund
                            previous payments.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">16: DISCLAIMERS</p>
                        <p className="py-3 text-justify font-work">
                            We are not responsible or liable in any way for any User Content or
                            Third Party Content made available or caused to be made available on
                            or via the Services, whether made accessible by the Users or by any of
                            the electronic structures with or employed in the Services. We provide
                            these Terms and other policies and rules for User behavior and
                            conduct; but, we have no control over or responsibility for our Users’
                            actions, and we are not liable for any Content you may discover on the
                            Service, whether User Content or Third Party Content. From time to
                            time, the Services, or any Service, may be temporarily down due to
                            maintenance or other reasons. We are not liable for any error,
                            inconsistency, interruptions, deletion, malfunction, delay in
                            operations or transmission, communications line failures, theft or
                            destruction of User Content, or unauthorized access to or alteration
                            of User Content.
                        </p>
                        <p className="py-3 text-justify font-work">
                            We cannot and do not guarantee any specific results as a result of
                            using the services.
                        </p>
                        <p className="py-3 text-justify font-work">
                            We make no representations or warranties about the accuracy,
                            completeness, reliability, correctness, or error-free nature of the
                            service’s content and that the service is free of viruses or other
                            dangerous components. As a result, you should tread cautiously while
                            using and downloading such content and utilize industry-recognized
                            virus detection and disinfection tools.
                        </p>
                    </div>
                    <div className="mb-8 tracking-wide font-work">
                        <p className="underline font-work">17: GENERAL TERMS</p>
                        <p className="py-3 text-justify font-work">
                            We reserve the right to modify, update, or discontinue the Site at our
                            sole discretion, at any time, for any or no reason, and without notice
                            or liability. We may provide you with notices, including those
                            regarding changes to the Terms by email, regular mail, or
                            communications through the Site. Except as otherwise stated, nothing
                            herein is intended, nor will be deemed, to confer rights or remedies
                            upon any third party. The Terms contain the entire agreement between
                            you and us regarding the use of the Site and supersede any prior
                            agreement between you and us on such subject matter. The parties
                            acknowledge that no reliance is placed on any representation made but
                            not expressly contained in these Terms. Any failure on MyRevue part to
                            exercise or enforce any right or provision of the Terms does not
                            constitute a waiver of such right or provision. The failure of either
                            party to exercise in any respect any right provided for herein shall
                            not be deemed a waiver of any further rights hereunder. Suppose any
                            provision of the Terms is found to be unenforceable or invalid. In
                            that case, only that provision shall be modified to reflect the
                            parties’ intention or eliminated to the minimum extent necessary so
                            that the Terms shall otherwise remain in full force and effect and
                            enforceable. The Terms and any rights or obligations hereunder are not
                            assignable, transferable, or sub licensable by you except with
                            MyRevue, prior written consent. However, they may be assigned or
                            transferred by us without restriction. Any attempted assignment by you
                            shall violate these Terms and be void. The section titles in the Terms
                            are for convenience only and have no legal or contractual effect. If
                            you have any questions regarding the use of the Site, you may write to
                            us at connect@myrevue.app.
                        </p>
                    </div>
                </div>
                <NotloginfooterNew />
            </Suspense>
        </div>
    )
}
