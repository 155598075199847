import React, { useState } from "react";
import { useStateValue } from "../context/StateProvider";

function SelectProductList({ setUpward }) {
  const [{ products }, dispatch] = useStateValue();
  const [selected, setSelected] = useState(null);


  return (
    <div className="relative flex items-center justify-center select-none">
      {selected ? (
        <div
          onClick={(e) => {
            const nextElement = e.currentTarget.nextElementSibling;
            nextElement.style.display = nextElement.style.display === "block" ? "none" : "block";
          }}
          className="flex items-center w-full gap-4 px-3 py-1 my-2 duration-150 transform rounded-md cursor-pointer bg-background hover:bg-gray-600"
        >
          <img
            src={selected.imageUrl}
            className="w-10 h-10 rounded-full"
            alt=""
          />
          <p className="flex-1 text-sm tracking-wide">{selected.name}</p>
        </div>
      ) : (
        <input
          onClick={(e) => {
            const nextElement = e.currentTarget.nextElementSibling;
            nextElement.style.display = nextElement.style.display === "block" ? "none" : "block";
          }}
          type="text"
          placeholder="search product ..."
          className="w-full h-12 p-3 mt-2 text-sm font-normal tracking-wide break-words whitespace-pre-wrap rounded-md outline-none bg-background"
        />
      )}
      <ul className="bg-black thin-scrollbar hidden h-80 overflow-scroll w-full absolute top-16 border border-[#363232]  text-xs rounded p-1">
        {products?.map((pr) => (
          <li
            onClick={(e) => {
              setSelected(pr);
              setUpward(pr.id);
              e.currentTarget.parentElement.style.display = "none";
            }}
            key={pr.id}
            className="flex items-center gap-4 p-1 my-2 duration-150 transform rounded cursor-pointer hover:bg-gray-500"
          >
            <img src={pr.imageUrl} className="w-10 h-10 rounded-full" alt="" />
            <p className="flex-1 ">{pr.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SelectProductList;
