import React from "react";
import Modalcard from "../components/modalcard";
import { useState } from "react";
import {
  ArrowElbowRight,
  Bell,
  MagnifyingGlass,
  VideoCamera,
  X,
} from "@phosphor-icons/react";
import { useStateValue } from "../context/StateProvider";
import { useHistory, Link } from "react-router-dom";
import Notification from "./notifications/notification";

export default function Home() {
  const [{ brand }, dispatch] = useStateValue();
  const [showModal, setShowModal] = useState(false);
  const [querytext, setquerytext] = useState("");
  const [notifications, setNotifications] = useState(false);

  const router = useHistory();

  const categories = [
    "All",
    "Trends",
    "Sesonal",
    "Digital Products",
    "Cosmetics",
    "Appearal",
    "Consumer Goods",
    "TikTok/Reel/Shorts",
    "Wellness",
    "Home",
    "Pets",
    "Kids",
  ];
  return (
    <div className="min-h-screen px-5 bg-background">
      {showModal && (
        <Modalcard close={setShowModal}>
          <div className="bg-background h-[90vh] w-[90vw] rounded-xl border border-[#cebaba48] flex flex-col">
            <div className="flex items-center justify-end w-full px-5 h-14 border-b border-[#cebaba48]">
              <button onClick={() => setShowModal(false)}>
                <X color="red" size={30} />
              </button>
            </div>
            <div className="flex items-start flex-1 bg-bg_highlight rounded-b-xl">
              <div className="relative flex-1 w-full h-full p-5">
                <div className="relative flex items-center justify-center h-full">
                  <div className=" max-h-[500px] rounded-lg aspect-[9/16] border border-[#cebaba48] bg-background">
                    <video
                      src="https://static.billo.app/content-hub/Josy_Apparel_Premium.mp4"
                      className="object-cover w-full h-full rounded-lg"
                      controls
                      controlsList="nodownload noplaybackrate nofullscreen"
                      disableRemotePlayback
                    ></video>
                  </div>
                </div>
              </div>
              <div className="w-[500px] p-5 border-l border-[#cebaba48] h-full space-y-6">
                <div className="">
                  <p className="text-xl font-work">Video type</p>
                  <p className="text-white/70 font-work">
                    Video ad - 15 seconds - Basic
                  </p>
                </div>
                <div className="">
                  <p className="text-xl font-work">Description</p>
                  <p className="text-white/70 font-work">
                    Sunny shares a fun & safe way to teach your kids to skate.
                  </p>
                </div>
                <div className="">
                  <p className="text-xl font-work">Highlight Points</p>
                  <p className="text-white/70 font-work">
                    Original and edited footage access, Animated subtitles,
                    Logo, Graphic call-to-action, Motion graphics, Music
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modalcard>
      )}
      <div className="flex items-center justify-between p-5">
        <div className="relative flex items-center h-10 w-80">
          <div className="absolute right-3">
            <MagnifyingGlass size={24} color="white" />
          </div>
          <form onSubmit={() => router.push(`/admin/search/${querytext}`)}>
            <input
              type="text"
              onChange={(e) => setquerytext(e.target.value)}
              className="bg-bg_highlight h-10 pr-12 rounded w-80 appearance-none outline-none pl-3 text-white placeholder:text-[#606165] font-light placeholder:font-normal text-sm tracking-wide"
              placeholder="Search products"
            />
          </form>
        </div>
        <div className="flex items-center divide-x-[2px] divide-[#282a2d]">
          <div className="">
            <div className="h-10 mx-3 w-52">
              <Link
                to={{
                  pathname: `https://www.myrevue.app/brand/${brand?.name?.replaceAll(
                    " ",
                    "-"
                  )}/${brand?.id}`,
                }}
                target="_blank"
              >
                <button className="h-10 w-52 rounded flex justify-between items-center px-3 border-[2px] border-[#707378]">
                  <p className="text-white truncate w-44">
                    https://myrevue.app/brand/
                    {brand?.name
                      ?.replaceAll(" ", "-")
                      .replaceAll("&", "%26")
                      .replaceAll(",", "%2C")}
                    /{brand?.id}
                  </p>
                  <ArrowElbowRight size={24} color="white" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1480px] mx-auto flex flex-col items-center py-20 border-b border-[#cebaba48]">
        <h1 className="text-6xl font-medium">Start with your first</h1>
        <h1 className="mt-3 text-6xl font-medium">Video ad</h1>
        <div className="flex items-center gap-5 mt-5">
          <button className="py-2 px-12 rounded-full mt-5  transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
            <span className="">Get a video</span>
          </button>
          <button className="py-2 px-12 rounded-full mt-5  transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">
            <span className="">How does it work ?</span>
          </button>
        </div>
      </div>
      <div className="max-w-[1480px] mx-auto py-3">
        <div className="text-xl font-work">What UGC videos can you get?</div>
        <div className="flex flex-wrap gap-3 mt-4" id="thin-scrollbar">
          {categories?.map((category, index) => (
            <button
              key={index}
              className="bg-bg_highlight hover:bg-black/70 cursor-pointer border border-[#808080] rounded px-4 py-[4px] text-white w-fit"
            >
              <span className="text-sm leading-none">{category}</span>
            </button>
          ))}
        </div>
        <div className="flex flex-wrap gap-5 mt-5">
          {Array(10)
            .fill(0)
            .map((_, index) => (
              <div className="" key={index} onClick={() => setShowModal(true)}>
                <video
                  preload="auto"
                  poster="https://static.billo.app/content-hub/Josy_Apparel_Premium.png"
                  muted
                  className="aspect-[9/16] object-cover min-h-[166px] max-h-[586px] rounded-lg"
                  src="https://static.billo.app/content-hub/Josy_Apparel_Premium.mp4"
                  playsInline
                  onMouseEnter={(event) => event.currentTarget.play()}
                  onMouseLeave={(event) => event.currentTarget.pause()}
                ></video>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
