import { ShieldSlash, UploadSimple, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Stickyheader from "../../components/stickyheader";
import { getDoc, getDocs, doc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { nanoid } from "nanoid";
import { useRef } from "react";
import Loadingdot from "../../components/loadingdot";
import { useHistory } from "react-router";
import { sendAmplitudeData } from "../../amplitude";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import { getOpenAIContent } from "../../aivideoGenerator/aiapis";
import { showWarning } from "../../components/toast/toast";

export default function Createproduct() {
  const [{ products, brand }, dispatch] = useStateValue();

  const [progress, setprogress] = useState(0);
  const [product_details, setproduct_details] = useState({});
  const [imageUrl, setimageUrl] = useState("");
  const [id, setid] = useState(nanoid(8));
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState([]);
  const [loading, setloading] = useState(false);
  const router = useHistory();
  const tagref = useRef();
  useEffect(() => {
    setloading(true);
    getDocs(collection(db, "category")).then((res) =>
      setcategories(res.docs.map((doc) => doc.data()))
    );
    async function checkid() {
      const productExist = await fetchproduct(`pro_${id}`);
      if (productExist) {
        setid(nanoid(10))
      }
      setloading(false)
    }
    checkid();
  }, [id]);

  useEffect(() => {
    sendAmplitudeData("creating a product ");
  }, []);
  async function fetchproduct(prodid) {
    let headersList = {
      Accept: "*/*",
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
    };

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + prodid,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    let data = response.data;
    if (data !== null) {
      return true
    }
    else {
      return false
    }
  }

  async function submit() {
    if (!imageUrl) {
      showWarning(`Please upload a product image.`);
      return;
    }
    if (!product_details?.name) {
      showWarning(`Please fill all details for this product.`);
      return;
    }
    if (
      !product_details?.description ||
      product_details?.description?.length < 50
    ) {
      showWarning(`Description must be more than 50 characters`);
      return;
    }
    if (!product_details?.category) {
      showWarning(`Please fill all details for this product.`);
      return;
    }
    if (!product_details?.subcategory) {
      showWarning(`Please fill all details for this product.`);
      return;
    }

    let attr;

    try {
      attr = categories
        .find((cat) => cat.id === product_details?.category)
        .images.find(
          (subc) => subc.name === product_details?.subcategory
        ).attributes;
      // console.log(attr);
    } catch (error) {
      alert("No attributes found for this subcategory.");
      return;
    }
    var filterName = product_details?.name.toLowerCase().split(" ");

    const productdetails = {
      id: `pro_${id}`,
      subscribes: 0,
      isverified: false,
      status: false,
      experiences: 0,
      reviews: 0,
      attributes: attr,
      totalExps: 0,
      totalReviews: 0,
      views: 0,
      rating: 3,
      name: product_details.name,
      description: product_details.description,
      catId: product_details.category,
      parentName: brand?.name,
      affliateLink: null,
      subcategory: product_details.subcategory,
      coverImage: null,
      parent: brand?.id,
      tag: `@pro_${id}`,
      createdAt: Date.now(),
      addedBy: {
        id: brand?.id,
        name: brand?.name,
        tag: brand?.tag,
        image: brand?.imageUrl,
      },
      filter: [...tags, ...filterName],
      imageUrl: imageUrl,
    };

    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization:
        "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD",
    };

    let reqOptions = {
      url: "https://productapi-zscu3untuq-el.a.run.app/createProduct",
      method: "PUT",
      headers: headersList,
      data: productdetails,
    };

    try {
      const response = await axios.request(reqOptions);
      const pro_data = response.data;
      console.log(pro_data);
      dispatch({
        type: "SET_PRODUCTS",
        products: [...products, productdetails],
      });
      router.push("/admin/products");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `product/pro_${id}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimageUrl(downloadURL);
        });
      }
    );
  };
  const uploadCoverFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `test/${brand?.id}/pro_${id}cover.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimageUrl(downloadURL);
        });
      }
    );
  };

  const removeTag = (e) => {
    // console.log(e);
    settags(tags.filter((word) => e !== word));
  };
  const addtags = (e) => {
    e.preventDefault();

    if (tags?.includes(tagref.current.value)) {
      return;
    }
    settags([...tags, tagref.current.value.trim()]);
    tagref.current.value = "";
  };
  return (
    <div className="flex flex-col h-full">
      <Stickyheader title={"Create New Product"} back={true} />
      <div className="p-5">
        {loading ? (
          <Loadingdot />
        ) : (
          <div className="grid flex-1 h-full grid-cols-2 p-4 pb-10 bg-bg_highlight">
            <div className="flex items-center justify-center h-full">
              <div className="h-96 w-96 bg-background bg-opacity-50 rounded-2xl border border-dashed border-[#ececec50] hover:border-white hover:bg-opacity-100 relative">
                <input
                  type="file"
                  onChange={(e) => uploadFile(e)}
                  className="absolute opacity-0 h-96 w-96 rounded-2xl"
                  accept=".jpg, .jpeg, .png"
                />
                {progress < 99 ? (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <UploadSimple size={50} color="#ffffff" />
                    <div className="mt-5 text-sm font-light tracking-wide text-center text-white">
                      {progress <= 0 ? (
                        <>
                          <p className="">Browse or drag and drop image file</p>
                          <p className="">We support: JPG, JPEG, PNG</p>
                        </>
                      ) : (
                        <p className="">
                          {progress <= 99 ? (
                            <span className="">Uploading {progress} %</span>
                          ) : (
                            <span>Uploaded</span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <img
                      src={imageUrl}
                      alt=""
                      crossOrigin="anonymous"
                      className="w-full h-full rounded-2xl"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="text-white">
                <p className="">Name</p>
                <input
                  type="text"
                  onChange={(event) =>
                    setproduct_details({
                      ...product_details,
                      name: event.target.value,
                    })
                  }
                  placeholder="Enter product name"
                  className="w-full h-10 px-3 mt-2 text-sm font-normal tracking-wide truncate rounded-md border border-[#8080801e] outline-none appearance-none bg-background"
                />
              </div>
              <div className="my-3 text-white">
                <p className="">Description</p>
                <div className="relative">
                  <i
                    onClick={async (e) => {
                      const icon = e.currentTarget;
                      if (icon) {
                        icon.classList.remove("fa-lightbulb");
                        icon.classList.add("fa-spinner");
                        icon.classList.add("animate-spin");
                        try {
                          const response = await getOpenAIContent(
                            product_details.description
                          );
                          setproduct_details({
                            ...product_details,
                            description: response,
                          });
                        } catch (error) {
                          console.error(error);
                        } finally {
                          if (icon) {
                            icon.classList.remove("fa-spinner");
                            icon.classList.remove("animate-spin");
                            icon.classList.add("fa-lightbulb");
                          }
                        }
                      }
                    }}
                    className="absolute hidden text-yellow-400 fa-solid top-4 right-2 fa-lightbulb"
                  ></i>
                  <textarea
                    type="text"
                    value={product_details?.description}
                    onChange={(event) => {
                      const icon =
                        event.target.parentElement.querySelector("i");
                      if (icon) {
                        icon.style.display =
                          event.target.value.length > 25 ? "block" : "none";
                      }

                      setproduct_details({
                        ...product_details,
                        description: event.target.value,
                      });
                    }}
                    placeholder="Enter product description"
                    className="w-full h-40 p-3 mt-2 text-sm font-normal tracking-wide break-words rounded-md border border-[#8080801e] whitespace-pre-wrap outline-none bg-background"
                  />
                </div>
              </div>
              <div className="my-3 text-white">
                <p className="">Category</p>
                <select
                  onChange={(e) =>
                    setproduct_details({
                      ...product_details,
                      category: e.target.value,
                    })
                  }
                  className="w-full h-10 px-3 mt-2 text-sm font-normal tracking-wide truncate rounded-md border border-[#8080801e] outline-none appearance-none bg-background"
                >
                  <option value="" hidden>
                    Please select
                  </option>
                  {categories.map((e, index) => (
                    <option value={e.id} key={index}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="my-3 text-white">
                <p className="">Sub Category</p>
                <select
                  onChange={(e) =>
                    setproduct_details({
                      ...product_details,
                      subcategory: e.target.value,
                    })
                  }
                  className="w-full h-10 px-3 mt-2 text-sm font-normal tracking-wide truncate rounded-md border border-[#8080801e] outline-none appearance-none bg-background"
                >
                  <option value="" className="" hidden>
                    Please select category
                  </option>
                  {categories
                    .find((ele) => ele.id === product_details?.category)
                    ?.subcategory.map((e, index) => (
                      <option value={e} key={index}>
                        {e}
                      </option>
                    ))}
                </select>
              </div>
              <div className="my-3 text-white">
                <p className="">Tags</p>
                <div className="">
                  <div className="flex flex-wrap items-center mt-2 bg-input bg-background rounded-md border border-[#8080801e]">
                    {tags?.map((e) => (
                      <span className="m-2 text-white bg-black cursor-pointer">
                        <span className="flex items-center py-1">
                          <p className="px-2">{e}</p>
                          <div
                            className="pr-1 cursor-pointer"
                            onClick={() => removeTag(e)}
                          >
                            <X size={20} color="white" />
                          </div>
                        </span>
                      </span>
                    ))}
                    <form onSubmit={(e) => addtags(e)}>
                      <input
                        type="text"
                        ref={tagref}
                        className="w-full h-12 px-5 text-sm tracking-wide bg-transparent outline-none appearance-none"
                        placeholder="Enter Tag(s)"
                      />
                    </form>
                  </div>
                </div>
                {/* <input className="w-full h-10 px-3 mt-2 text-sm font-normal tracking-wide truncate outline-none appearance-none bg-background" /> */}
              </div>
              <div className="mt-5">
                <button
                  onClick={() => submit()}
                  className="h-10 w-40 appearance-none outline-none rounded-md  bg-background hover:bg-[#4611ea] text-white text-sm tracking-wider"
                >
                  Add product
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
