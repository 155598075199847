import React from 'react'

export default function Sidemodal({ children, close }) {
    return (
        <div className="fixed top-0 left-0  z-[100] flex h-screen w-full items-center justify-end select-none">
            <div
                className=" absolute h-screen w-full cursor-pointer bg-[#12121298] bg-opacity-80"
                onClick={() => close(false)}
            ></div>
            <div className="z-50">{children}</div>
        </div>
    )
}