import { Download } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

export default function StockVideoComponent({ document, setShowModal, addToCart, isMuted }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [added, setAdded] = useState(false);
    const router = useHistory();
    const [variant, setVariant] = useState({});

    useEffect(() => {
        function getVariantUrlByAspectRatio(product, aspectRatio) {
            const variant = product.variants.find((v) => v.aspect_ratio === aspectRatio);

            if (variant) {
                return variant;
            } else {
                return null; // Aspect ratio not found
            }
        }

        const items = JSON.parse(sessionStorage.getItem("stock")) || [];
        setAdded(items.some((obj) => obj.videoId === document?.videoId))


        const aspectRatioToGet = "9:16";
        const pvariant = getVariantUrlByAspectRatio(document, aspectRatioToGet);
        setVariant(pvariant)
    }, [])


    async function addProduct(doc) {
        const product = {
            title: doc?.title,
            videoId: doc?.videoId,
            variant: variant
        }
        addToCart(product);
        setAdded(true)
    }

    return (
        <div
            onMouseEnter={() => setIsPlaying(true)}
            onMouseLeave={() => setIsPlaying(false)}
            className="relative w-full aspect-[9/16] rounded-md cursor-pointer group"
        >
            <div className="absolute z-10 w-full h-full rounded-md" onClick={() => router.push("/stock/videos/" + document.videoId)}>
                <div className="flex items-center justify-end gap-3 w-full px-3 h-10 bg-gradient-to-t from-transparent via-[#12121290] to-[#121212]">
                    <Download size={22} color='white' />
                    <p className="">{document?.download_count ?? 0}</p>
                </div>
            </div>
            <div className="absolute h-fit pt-10 z-50 w-full bottom-0 rounded-[4px] bg-gradient-to-b from-transparent via-[#1212129f]  to-[#121212] flex items-end">
                <div className="w-full px-5 pb-5">
                    <p className="text-sm font-medium leading-tight tracking-wide">
                        {document.title}
                    </p>
                    <div className="flex items-center justify-center w-full mt-2">
                        {added ?
                            <button className="py-3 px-8 flex items-center justify-center gap-5 text-sm md:text-base rounded-md bg-[#FF005C] group-hover:bg-[#F92147] font-semibold">
                                <span className="">Added to Cart</span>
                            </button>
                            :
                            <button onClick={() => addProduct(document)} className="py-3 px-8 flex items-center gap-5 text-sm md:text-base rounded-md bg-[#FF005C] group-hover:bg-[#F92147] font-semibold">
                                <span className="">Add to Cart</span>
                                <span className="">|</span>
                                <span className="">₹{variant.discounted_price}</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div className="w-full h-full  rounded-[5px]">
                {isPlaying ? (
                    <video playsInline muted={isMuted} poster={variant?.urls?.thumbnail_url} autoPlay className="w-full h-full rounded-[5px] object-cover">
                        <source
                            src={variant?.urls?.watermarked_url}
                            type="video/mp4"
                        />
                    </video>
                ) : (
                    <img
                        src={variant?.urls?.thumbnail_url}
                        alt=""
                        className="w-full h-full object-cover rounded-[5px]"></img>
                )}
            </div>
        </div>
    )
}
