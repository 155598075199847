import { UploadSimple } from "phosphor-react";
import React, { useState } from "react";
import { useRef } from "react";
import Loadingdot from "../../components/loadingdot";
import Stickyheader from "../../components/stickyheader";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { nanoid } from "nanoid";
import { useHistory } from "react-router";
import { useStateValue } from "../../context/StateProvider";
export default function Createcoupon() {
  const [loading, setloading] = useState(false);
  const [progress, setprogress] = useState(0);
  const [imageUrl, setimageUrl] = useState("");
  const [coupon_details, setcoupon_details] = useState({});
  const [terms, setterms] = useState([]);
  const [{ brand, }, dispatch] = useStateValue();
  const [id] = useState(nanoid(8));
  const [submisson_loading, setsubmisson_loading] = useState(false);

  const termsref = useRef();
  const router = useHistory();
  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `coupan/` + `coupon_${id}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimageUrl(downloadURL);
        });
      }
    );
  };

  async function submit() {
    if (!imageUrl) {
      return alert("please upload coupon image");
    }
    if (!coupon_details.title) {
      return alert("please fill all details");
    }
    if (!coupon_details.value) {
      return alert("please fill all details");
    }
    if (!coupon_details.quantity) {
      return alert("please fill all details");
    }
    if (!coupon_details.expiresAt) {
      return alert("please fill all details");
    }
    if (!coupon_details.category) {
      return alert("please fill all details");
    }
    var coupon = {
      id: `coupan_${id}`,
      isverified: false,
      expires: coupon_details.expiresAt,
      type: "discount",
      title: coupon_details.title,
      brandId: brand?.id,
      coupanValue: Number.parseInt(coupon_details.value),
      quantity: Number.parseInt(coupon_details.quantity),
      dateAdded: serverTimestamp(),
      rules: terms,
      category: coupon_details.category,
      imageUrl: imageUrl,
    };
    setsubmisson_loading(true);
    setDoc(doc(db, "rewardCoupan", `coupan_${id}`), coupon)
      .then(async (res) => {
        await generateCoupanCodes(coupon.title);
        setsubmisson_loading(false);
        router.replace("/admin/assets");
        return;
      })
      .catch((err) => {
        alert(err);
        return;
      });
  }

  const removeTerms = (e) => {
    setterms(terms.filter((word) => e !== word));
  };

  const addTerms = (e) => {
    e.preventDefault();

    if (terms?.includes(termsref.current.value)) {
      return;
    }
    setterms([...terms, termsref.current.value.trim()]);
    termsref.current.value = "";
  };

  const generateCoupanCodes = async (title) => {
    var coupancodes = [];
    var brand_initials = "";
    var title_initials = "";
    let name_arr = brand?.name.split(" ");
    let title_arr = title.split(" ");
    brand_initials = (
      name_arr[0][0] + (name_arr[1] ? name_arr[1][0] : name_arr[0][1])
    ).toUpperCase();
    title_initials = (
      title_arr[0][0] + (title_arr[1] ? title_arr[1][0] : title_arr[0][1])
    ).toUpperCase();

    for (let i = 0; i < coupon_details.quantity; i++) {
      let newCoupan = {
        id: `MR${brand_initials}${title_initials}` + nanoid(4),
        alloted: null,
      };
      var newCoupanhandler = new Promise((reject, resolve) => {
        setDoc(
          doc(db, `rewardCoupan/coupan_${id}/coupanCodes/${newCoupan.id}`),
          newCoupan
        )
          .then(() => {
            return resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
      coupancodes.push(newCoupanhandler);
    }
    Promise.all(coupancodes).then(() => {
      // new AWN().success("Successfully generated and assigned new CoupanCodes ");
    });
  };

  return (
    <div className="flex flex-col h-full">
      <Stickyheader title={"Create New Coupon"} back={true} />
      <div className="p-5">
        {loading ? (
          <Loadingdot />
        ) : (
          <div className="grid flex-1 h-full grid-flow-col grid-cols-3 p-4 pb-10 bg-bg_highlight">
            <div className="flex items-start justify-center h-full">
              <div className="h-72 w-72 mt-5 bg-background bg-opacity-50 rounded-2xl border border-dashed border-[#ececec50] hover:border-white hover:bg-opacity-100 relative">
                <input
                  type="file"
                  onChange={(e) => uploadFile(e)}
                  className="absolute w-full h-full opacity-0 rounded-2xl"
                  accept=".jpg, .jpeg, .png"
                />
                {progress < 99 ? (
                  <div className="flex flex-col items-center justify-center h-72 w-72">
                    <UploadSimple size={50} color="#ffffff" />
                    <div className="mt-5 text-sm font-light tracking-wide text-center text-white">
                      {progress <= 0 ? (
                        <>
                          <p className="text-xs font-light tracking-wide">
                            Browse or drag and drop image file
                          </p>
                          <p className="text-xs font-light tracking-wide">
                            We support: JPG, JPEG, PNG
                          </p>
                        </>
                      ) : (
                        <p className="">
                          {progress <= 99 ? (
                            <span className="">Uploading {progress} %</span>
                          ) : (
                            <span>Uploaded</span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <img
                      src={imageUrl}
                      crossOrigin="anonymous"
                      alt=""
                      className="w-full h-full rounded-2xl"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full col-span-2">
              <div className="grid w-full grid-flow-col grid-cols-2 mt-5 gap-x-5">
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Coupon title
                  </p>
                  <input
                    placeholder="ex: Black friday offer"
                    type="text"
                    onChange={(event) =>
                      setcoupon_details({
                        ...coupon_details,
                        title: event.target.value,
                      })
                    }
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Expires At
                  </p>
                  <input
                    placeholder="ex: MRLAYT2039"
                    type="datetime-local"
                    onChange={(event) =>
                      setcoupon_details({
                        ...coupon_details,
                        expiresAt: event.target.value,
                      })
                    }
                    className=" select-none text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
              </div>
              <div className="grid w-full grid-flow-col grid-cols-2 mt-5 gap-x-5">
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Category
                  </p>
                  <select
                    onChange={(event) =>
                      setcoupon_details({
                        ...coupon_details,
                        category: event.target.value,
                      })
                    }
                    className="text-sm bg-transparent  border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  >
                    <option className="bg-bg_highlight" value="" hidden>
                      Please Select
                    </option>
                    <option className="bg-bg_highlight" value="free">
                      Free Product
                    </option>
                    <option className="bg-bg_highlight" value="paid">
                      Paid Product
                    </option>
                  </select>
                </div>
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Quantity
                  </p>
                  <input
                    placeholder="ex: 10"
                    type="number"
                    onChange={(event) =>
                      setcoupon_details({
                        ...coupon_details,
                        quantity: event.target.value,
                      })
                    }
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
              </div>
              <div className="grid grid-flow-col grid-cols-2 mt-5 gap-x-5">
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Coupon value (in coins)
                  </p>
                  <select
                    onChange={(event) =>
                      setcoupon_details({
                        ...coupon_details,
                        value: event.target.value,
                      })
                    }
                    className="text-sm bg-transparent  border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  >
                    <option
                      className="bg-bg_highlight hover:!bg-background"
                      value=""
                      hidden
                    >
                      Please Select
                    </option>
                    <option
                      className="bg-bg_highlight hover:!bg-background"
                      value="3000"
                    >
                      3000
                    </option>
                    <option
                      className="bg-bg_highlight hover:!bg-background"
                      value="5000"
                    >
                      5000
                    </option>
                    <option
                      className="bg-bg_highlight hover:!bg-background"
                      value="10000"
                    >
                      10000
                    </option>
                    <option
                      className="bg-bg_highlight hover:!bg-background"
                      value="20000"
                    >
                      20000
                    </option>
                    <option
                      className="bg-bg_highlight hover:!bg-background"
                      value="25000"
                    >
                      25000
                    </option>
                  </select>
                </div>
                <div className="w-full"></div>
              </div>
              <div className="grid grid-flow-col grid-cols-2 mt-5 gap-x-5">
                <div className="w-full col-span-2">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Terms & Conditions
                  </p>
                  {terms?.map((e, index) => (
                    <div
                      className="bg-[#121212] text-white my-2 p-2 cursor-pointer text-sm tracking-wide font-light"
                      onClick={() => removeTerms(e)}
                    >
                      {index + 1}: {e}
                    </div>
                  ))}
                  <form onSubmit={(e) => addTerms(e)}>
                    <input
                      type="text"
                      ref={termsref}
                      className="text-sm bg-transparent  border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                      placeholder="Add terms and conditions one by one"
                    />
                  </form>
                </div>
              </div>
              <div className="mt-5">
                {submisson_loading === false ? (
                  <button
                    onClick={() => submit()}
                    className="h-10 w-40 appearance-none outline-none bg-background hover:bg-[#4611ea] text-white text-sm tracking-wider"
                  >
                    Create Coupon
                  </button>
                ) : (
                  <button
                    onClick={() => submit()}
                    className="h-10 w-40  flex items-center justify-center appearance-none outline-none bg-background hover:bg-[#4611ea] text-white text-sm tracking-wider"
                  >
                    <div className="" id="loading"></div>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
