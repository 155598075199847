import React from "react";
import { useStateValue } from "../../context/StateProvider";
import { useHistory } from "react-router-dom";
import InstagramAnalytics from "./analytics/InstagramAnalytics";
import InstagramProfile from "./analytics/InstagramProfile";
import PageAnalyticDashboard from "./analytics/PageAnalyticDashboard";
import PageProfile from "./analytics/PageProfile";

export default function Dashboard() {
  const [{ brand, user }, dispatch] = useStateValue();
  const router = useHistory();

  return (
    <div className="relative w-full h-screen overflow-y-auto">
      <div className="h-14 w-full border-b flex items-center justify-between px-5 border-[#808080]">
        <p className="text-lg font-light tracking-wide">Dashboard</p>
      </div>
      <div className="w-full p-5">
        <div className="flex items-center justify-between">
          <div className="mb-5">
            <p className="text-2xl">👋 Hey {brand?.name} </p>
            {(!user?.social?.facebook_pages || !user.social?.instagram) && (
              <p className="text-[#b3b3b3]">
                Connect your social channels, publish and track analytics from
                MyRevueElite
              </p>
            )}{" "}
          </div>
          {/* <div className="">
            <button className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]" onClick={() => router.push("/social/channel")}>
              Connect
            </button>
          </div> */}
        </div>
      </div>
      {(!user?.social?.facebook_pages || !user.social?.instagram) && (
        <div className="w-full px-5">
          <div className="grid grid-cols-3 gap-10">
            <div className="bg-[#131418] shadow-sm shadow-gray-600 h-40 flex-1 rounded-md flex flex-col justify-between p-5 text-white">
              <div className="">
                <p className="text-xl font-light text-white">Total Followers</p>
                <p className="font-light text-sm text-[#9a9999]">7 Days</p>
              </div>
              <div className="">
                <p className="text-sm font-light tracking-wider">--</p>
              </div>
            </div>
            <div className="bg-[#131418] shadow-sm shadow-gray-600 h-40 flex-1 rounded-md flex flex-col justify-between p-5 text-white">
              <div className="">
                <p className="text-xl font-light text-white">
                  Total Impressions
                </p>
                <p className="font-light text-sm text-[#9a9999]">7 Days</p>
              </div>
              <div className="">
                <p className="text-sm font-light tracking-wider">--</p>
              </div>
            </div>
            <div className="bg-[#131418] shadow-sm shadow-gray-600 h-40 flex-1 rounded-md flex flex-col justify-between p-5 text-white">
              <div className="">
                <p className="text-xl font-light text-white">
                  Total Engagement
                </p>
                <p className="font-light text-sm text-[#9a9999]">7 Days</p>
              </div>
              <div className="">
                <p className="text-sm font-light tracking-wider">--</p>
              </div>
            </div>
          </div>
          <div className="h-[500px] w-full bg-bg_highlight border border-[#80808073] mt-10 rounded-md flex flex-col items-center justify-center gap-3">
            <p className="font-light">
              Connect your social channel to start publishing content and track
              analytics
            </p>
            <button
              onClick={() => router.push("/social/channel")}
              className="px-5 py-2 text-sm rounded-full bg-[#8EA9FA] hover:bg-[#4611ea]"
            >
              Connect
            </button>
          </div>
        </div>
      )}
      {user?.social?.facebook_pages && (
        <div className="px-5">
          <div className="">
            <PageProfile pageid={user?.social?.facebook_pages[0]?.id} />
          </div>
        </div>
      )}
      {user?.social?.facebook_pages && (
        <div className="px-5">
          <div className="my-5">
            <PageAnalyticDashboard
              pageid={user?.social?.facebook_pages[0]?.id}
            />
          </div>
        </div>
      )}
      {user.social?.instagram && (
        <div className="px-5">
          <div className="my-5">
            <InstagramProfile
              pageid={user?.social?.instagram?.id}
              token={user.social.facebook.access_token}
            />
          </div>
        </div>
      )}
      {user.social?.instagram && (
        <div className="px-5 my-5">
          <InstagramAnalytics
            pageid={user.social.instagram.business_account}
            token={user.social.facebook.access_token}
          />
        </div>
      )}
    </div>
  );
}
