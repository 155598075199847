import { ArrowCircleRight, } from '@phosphor-icons/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'


export default function Influencer() {
    const router = useHistory();
    return (
        <div className='w-full min-h-screen bg-black'>
            <div className="flex items-center justify-between w-full h-16 px-6">
                <div className="">
                    <Link to="/">
                        <img loading="lazy" src={require("./assets/logo/MyRevue Logo elite new.svg").default} alt="logo" className="h-[40px] md:h-[62px] w-auto" />
                    </Link>
                </div>
                <div className="hidden gap-10 lg:flex ">
                    {/* <button className="min-w-[180px] tracking-wide">What to do</button>
                    <button className="min-w-[180px] tracking-wide">Eligibility</button>
                    <button className="min-w-[180px] tracking-wide">Rewards</button> */}
                </div>
                <div className="">
                    <button onClick={() => router.push("/applynow")} className="rounded-7 bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[150px] rounded-md">Apply Now</button>
                </div>
            </div>
            <div className="h-auto lg:h-[calc(100vh-64px)] w-full relative">
                <div className="absolute top-0 left-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FIntersect.svg?alt=media&token=a532282b-dc96-4b8f-9098-12331533d89c" alt="" className="max-w-[140px]" />
                </div>
                <div className="absolute bottom-0 left-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FVector%205%20bottom%20left.svg?alt=media&token=e8057ea7-98f5-4cce-a0b2-7f64c53dd08e" alt="" className="max-w-[150px]" />
                </div>
                <div className="absolute top-0 right-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FIntersect%20top%20right.svg?alt=media&token=7627081b-dd47-43e4-aeeb-c81fd3b41b23" alt="" className="max-w-[500px]" />
                </div>
                <div className="absolute bottom-0 right-0 hidden lg:flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FIntersect%20bottom.svg?alt=media&token=45c1c1b0-d1d1-43be-9804-94e9c9c314ac" alt="" className="" />
                </div>
                <div className="z-50 grid w-full h-full p-5 md:grid-cols-2 lg:absolute md:p-0">
                    <div className="flex flex-col justify-center w-full md:pl-20">
                        <div className="flex items-center gap-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fsquare%20logo.png?alt=media&token=b58d74f0-785a-4478-aa9e-69ada2105bab" alt="" className="h-8 md:h-auto" />
                            <p className="text-xl md:text-3xl">MyRevue Presents</p>
                        </div>
                        <div className="mt-3">
                            <p className="hidden md:block text-[70px] leading-[70px] font-semibold bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">India's Next <br /> Sensation</p>
                            <p className="md:hidden block text-[50px] leading-[50px] font-semibold bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">India's Next <br /> Sensation</p>
                            <p className="hidden my-3 text-xl tracking-wide md:block">Discovering The Next Generation of <br /> India’s Greatest Influencers .</p>
                            <p className="block my-3 text-xl tracking-wide md:hidden">Discovering The Next Generation of  India’s Greatest Influencers .</p>
                            <p className="bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent text-xl font-medium">Are You Ready ?</p>
                            <button onClick={() => router.push("/applynow")} className="mt-3 rounded-md bg-gradient-to-b from-pink-500 to-purple-700 shadow-md h-11 min-w-[170px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full mt-10 md:mt-0">
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FGroup%20599.png?alt=media&token=891ddf71-e30e-4268-86b3-a6ed806df2b0" alt="" className="max-h-[85vh]" />
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#282828] bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fpattern.png?alt=media&token=f8556b6a-9c64-40e3-9a77-79e1fc3256a8')]">
                <div className="grid md:grid-cols-2">
                    <div className="w-full">
                        <p className="hidden w-full text-4xl font-semibold leading-tight tracking-wide text-center md:block md:text-5xl md:text-left">Do you have what it <br />needs to become <br />the next big <br /> sensation?</p>
                        <p className="block w-full text-4xl font-semibold leading-tight tracking-wide text-center md:hidden md:text-5xl md:text-left">Do you have what it needs to become the next big sensation?</p>
                    </div>
                    <div className="w-full mt-4 md:mt-0">
                        <p className="text-center md:text-xl md:text-right">The MyRevue Creator Program is a prestigious initiative designed to nurture talented young individuals into becoming the foremost content creators in India. Supported by a team of influential figures in the industry, we are actively searching for exceptional content creators who have the potential to redefine the realm of content creation.</p>
                        <div className="flex justify-center md:justify-end">
                            <button onClick={() => router.push("/applynow")} className="mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#010101] grid md:grid-cols-2">
                <div className="order-2 mt-8 md:order-1 md:mt-0">
                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fwho%20is%20it%20for.png?alt=media&token=70167ee4-60f1-4263-8891-a5f4aed8dc48" alt="" className="" />
                </div>
                <div className="flex flex-col justify-center order-1 space-y-2 md:order-2">
                    <p className="text-4xl font-semibold text-center md:text-right md:text-5xl">Who’s it for?</p>
                    <p className="md:text-right text-center text-xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">Everyone!</p>
                    <p className="hidden text-lg text-center md:text-right lg:block">Whether you're new to this or have been at it <br /> for a while, whether content creation is your<br /> full-time gig or a part-time pursuit alongside <br /> your day job, all it takes is your passion for<br /> content creation!</p>
                    <p className="block text-lg text-center lg:text-right lg:hidden">Whether you're new to this or have been at it  for a while, whether content creation is your full-time gig or a part-time pursuit alongside  your day job, all it takes is your passion for content creation!</p>
                    <div className="flex justify-center md:justify-end">
                        <button onClick={() => router.push("/applynow")} className="mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#161616] ">
                <div className="items-center justify-between md:flex">
                    <div className="hidden text-4xl font-semibold leading-tight md:text-5xl md:block">So, what do you have <br /> to do?</div>
                    <div className="block text-4xl font-semibold leading-tight text-center md:text-5xl md:hidden">So, what do you have to do?</div>
                    <div className="flex justify-center md:justify-start">
                        <button onClick={() => router.push("/applynow")} className="mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                    </div>
                </div>
                <div className="w-full rounded-xl md:rounded-3xl py-10 bg-gradient-to-r from-[#A24DAD] via-[#A84781] to-[#D24E7C] p-5 grid md:grid-cols-3 gap-10 md:0 divide-white mt-5">
                    <div className="flex flex-col items-center justify-center w-full">
                        <p className="text-4xl font-semibold md:text-5xl">Step 1</p>
                        <p className="px-10 mt-3 text-base text-center md:text-lg">Apply now, fill the form and pay the registration fee.</p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full pt-5 border-t border-white md:border-t-0 md:pt-0 md:border-l">
                        <p className="text-4xl font-semibold md:text-5xl">Step 2</p>
                        <p className="px-10 mt-3 text-base text-center md:text-lg">Follow the steps to create a 30 sec reel given in the email we'll send you.</p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full pt-5 border-t border-white md:border-t-0 md:pt-0 md:border-l">
                        <p className="text-4xl font-semibold md:text-5xl">Step 3</p>
                        <p className="px-10 mt-3 text-base text-center md:text-lg">Post the reel and tag cybees.official to it.</p>
                    </div>
                </div>
            </div>
            <div className="p-3 md:p-10 w-full bg-[#010101] ">
                <div className="bg-[#282828] grid md:grid-cols-2 py-5 md:py-12  px-5 md:px-24 rounded-xl md:rounded-3xl">
                    <div className="relative w-full">
                        <p className="pb-2 text-4xl font-medium">Wondering if you’re eligible for it?</p>
                        <div className="absolute bg-[#282828] mt-8 w-full py-2">
                            <p className=" text-xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">
                                To be eligible you need to...
                            </p>
                        </div>

                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fwonderling%20if%20you%20are%20eligible.png?alt=media&token=a25d4fee-6865-4b10-a08f-2528ab6d05d2" alt="wondering" className="w-full max-w-[550px] h-auto" />
                    </div>
                    <div className="flex flex-col justify-center mt-10 space-y-2 md:mt-0">
                        <div className="">
                            <p className="min-h-[60px] py-3 px-8 text-sm md:text-base md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">Be 18 years or older</p>
                        </div>
                        <div className="">
                            <p className="min-h-[60px] py-3 px-8 text-sm md:text-base md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">A public social media profile.</p>
                        </div>
                        <div className="">
                            <p className="min-h-[60px] py-3 px-8 text-sm md:text-base md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">An engaged social media following on at least one platform.</p>
                        </div>
                        <div className="">
                            <p className="min-h-[60px] py-3 px-8 text-sm md:text-base md:px-10 w-full rounded-full bg-[#161616] flex items-center tracking-wide">High-quality, shoppable content posted regularly (high-performing Creators share content daily!)</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <button onClick={() => router.push("/applynow")} className="mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-11 min-w-[200px]  flex items-center justify-center gap-3">Apply Now <ArrowCircleRight size={28} color="#ffffff" /></button>
                        </div>
                        <div className="">
                            <p className="text-[#AFAFAF] text-center mt-3 tracking-wide">*Can be of any niche</p>
                        </div>
                    </div>
                </div>
                <div className="px-0 py-5 md:px-12 md:py-12">
                    <p className="text-4xl font-semibold">What to know what's in it for you ?</p>
                    <div className="mt-5">
                        <p className=""><span className="text-xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">MyRevue</span> was created by a fellow content creator, with a focus on serving the creator community. We truly understand what distinguishes exceptional influencers across all categories. Our dedicated teams are here to provide hands-on strategic guidance, coupled with the latest digital tools in the industry, to help you make the most out of your content monetization efforts and cultivate long-lasting brand partnerships.</p>
                    </div>

                    <div className="flex flex-col items-center justify-between gap-10 mt-12 md:flex-row">
                        <div className="flex-1 w-full p-5 space-y-6 border border-white rounded-3xl">
                            <div className="flex items-center gap-5">
                                <div className="">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                </div>
                                <div className="flex-1 w-full">
                                    <p className="tracking-wide">Participate in the Monthly Leaderboard Contest for a chance to secure the ₹10K Cash Prize and the coveted MyRevue Sensation Trophy 🏆.</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-5">
                                <div className="">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                </div>
                                <div className="flex-1 w-full">
                                    <p className="tracking-wide">Enjoy opportunities for brand collaborations valued between ₹50K to ₹100K each month 🤝</p>
                                </div>
                            </div>

                            <div className="flex items-center gap-5">
                                <div className="">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                </div>
                                <div className="flex-1 w-full">
                                    <p className="tracking-wide">Gain entry to exclusive community events with a combined worth of ₹25K 🎟️.</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-5">
                                <div className="">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                </div>
                                <div className="flex-1 w-full">
                                    <p className="tracking-wide">Receive complimentary goodies from renowned brands, totaling ₹7K in value 💰.</p>
                                </div>
                            </div>

                            <div className="flex items-center gap-5">
                                <div className="">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCheckCircle.svg?alt=media&token=051e5f7f-1225-4cf9-a31c-dff19973e638" alt="" className="w-12 md:w-14" />
                                </div>
                                <div className="flex-1 w-full">
                                    <p className="tracking-wide">Access training sessions conducted by industry experts, valued at ₹50K 🧑🏻‍💻</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full max-w-[450px]">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FWhat%20to%20know%20what's%20in%20it%20for%20you.png?alt=media&token=5adf30c3-fb69-46b2-b396-34472d6c0de9" alt="" className="w-full h-auto" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full p-3 md:p-10">
                <div className="bg-[#161616] rounded-xl px-5 p-10 bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fpattern%202.png?alt=media&token=3ade6d45-b734-456a-8593-751f57b0e46d')]">
                    <div className="">
                        <p className="text-4xl font-semibold text-center text-white md:text-5xl">Why Apply Now ??</p>
                    </div>
                    <div className="flex flex-col items-start justify-center gap-20 mt-20 md:flex-row md:mt-24 md:gap-10">
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FCodesandboxLogo.png?alt=media&token=2347dc5a-66e3-47cd-a38e-4dbb5e6b519c" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className="mt-5 text-2xl font-medium text-white ">Demand Growth</p>
                            <p className="mt-3 text-lg tracking-wide text-center"> Influencer industry <br /> expands 6-8% yearly.</p>
                        </div>
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FPiggyBank.png?alt=media&token=f22cc603-4a3a-46a1-9f5a-9e43e9210a22" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className="mt-5 text-2xl font-medium text-white ">Earnings Range</p>
                            <p className="mt-3 text-lg tracking-wide text-center">upto ₹5L per post, higher <br /> for top influencers.</p>
                        </div>
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FProjectorScreenChart.png?alt=media&token=7768d9d3-7c42-4178-b9e4-e1f1c90fd27d" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className="mt-5 text-2xl font-medium text-white ">Creative Showcase</p>
                            <p className="mt-3 text-lg tracking-wide text-center">75% express uniqueness <br /> through platforms.</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-center gap-20 mt-20 md:flex-row md:gap-10">
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FWifiHigh.png?alt=media&token=f3f0d24c-2484-44b8-9ea6-7240dd2577bc" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className="mt-5 text-2xl font-medium text-white ">Networking Benefits</p>
                            <p className="mt-3 text-lg tracking-wide text-center">80% form valuable <br />industry connections.</p>
                        </div>
                        <div className="w-full max-w-[350px] border border-white rounded-xl relative flex flex-col items-center py-8">
                            <div className="w-32 rounded-full bg-[#161616] -mt-24 flex items-center justify-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2FUsersFour.png?alt=media&token=82a3523c-1ea2-462f-98a8-fee4534fa013" alt="" className=" w-full aspect-square max-w-[100px]" />
                            </div>
                            <p className="mt-5 text-2xl font-medium text-white ">Positive Influence</p>
                            <p className="mt-3 text-lg tracking-wide text-center">65% believe they impact <br /> audience decisions.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="p-5 md:p-10 w-full bg-[#010101] ">
                <div className="text-3xl font-semibold leading-tight text-center">Why Choose The MyRevue Creator Program?</div>
                <div className="flex flex-col items-center justify-center gap-6 mt-10 md:flex-row md:gap-10">
                    <div className="max-w-[430px] w-full border border-white bg-[#1E1E1E] rounded-xl p-5">
                        <p className="text-xl font-medium">Over</p>
                        <p className=" mt-2 text-4xl md:text-6xl font-semibold text-[#F92147]">23000+</p>
                        <div className="flex items-center justify-between mt-10">
                            <p className="text-xl font-medium">Vast Creator <br />Network</p>
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fcommunity.png?alt=media&token=ed7e3e4f-759a-4256-895b-6b4648c00919" alt="" className="" />
                        </div>
                    </div>
                    <div className="max-w-[430px] w-full border border-white bg-[#1E1E1E] rounded-xl p-5">
                        <p className="text-xl font-medium">Partnered with</p>
                        <p className=" mt-2 text-4xl md:text-6xl font-semibold text-[#F0DE7D]">50+ Brands</p>
                        <div className="flex items-center justify-between mt-10">
                            <p className="text-xl font-medium">Strong Brand<br /> Engagement</p>
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fgraph%20up.png?alt=media&token=00e772a4-e299-4b95-a919-1893cf40c6e2" alt="" className="" />
                        </div>
                    </div>
                    <div className="max-w-[430px] w-full border border-white bg-[#1E1E1E] rounded-xl p-5">
                        <p className="text-xl font-medium"> Released an amount of</p>
                        <p className=" mt-2 text-4xl md:text-6xl font-semibold text-[#B3F7A1]">₹10 Lakhs</p>
                        <div className="flex items-center justify-between mt-10">
                            <p className="text-xl font-medium">Substantial <br />Funding</p>
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finfluencer%2Fdollatr.png?alt=media&token=7e6cd38e-edc9-442d-a5f2-d9fbbc13e992" alt="" className="" />
                        </div>
                    </div>
                </div>
                <div className="flex items-end justify-center w-full mt-5 text-xl tracking-wide">
                    <p className="">Impressive Social Reach: In-house Facebook & Instagram properties reach <span className="text-3xl font-medium bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent"> 10 </span>  <span className="bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">million</span></p>
                </div>
                <div className="w-full p-4 mt-8 border border-white rounded-3xl md:p-8 md:mt-16">
                    <p className="text-4xl font-semibold text-center md:text-5xl">Become The India’s next Sensation With <br /> <span className="bg-gradient-to-b from-[#E63946] to-[#9842A5] bg-clip-text text-transparent">MyRevue</span></p>
                    <div className="flex items-center justify-center">
                        <button onClick={() => router.push("/applynow")} className="mt-6 rounded-full bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-12 min-w-[200px] px-4 md:px-12 flex  items-center justify-center md:gap-3">Apply now to be the next sensation <ArrowCircleRight size={28} color="#ffffff" className='hidden md:block' /></button>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#252525] p-4 md:p-8 mt-5 flex flex-col md:flex-row justify-between items-center">
                <p className="hidden text-4xl font-semibold leading-snug text-left  md:text-5xl md:block">1001 creators already <br /> registered, what are you <br /> waiting for ?</p>
                <p className="block text-4xl font-semibold leading-snug text-center  md:text-5xl md:hidden">1001 creators already registered, what are you  waiting for ?</p>
                <div className="flex items-center justify-center">
                    <button onClick={() => router.push("/applynow")} className="mt-6 rounded-xl bg-gradient-to-r from-pink-500 to-purple-700 shadow-md h-12 min-w-[130px] px-12  flex items-center justify-center gap-3">Apply now</button>
                </div>
            </div>
        </div>
    )
}
