import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Copy, DownloadSimple, FileCsv } from "@phosphor-icons/react";
import QRCodeStyling from "qr-code-styling";
import { db } from "../firebase";
import { sendAmplitudeData } from "../amplitude";
import Modalcard from "../components/modalcard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showSuccess } from "../components/toast/toast";

function ViewQuizs() {
  const [quiz, setquiz] = useState([]);
  const [form, setform] = useState({});
  const [showaddreviews, setshowaddreviews] = useState(false);
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const router = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const quizDocRef = doc(db, `quizs/${id}`);
        const quizDocSnapshot = await getDoc(quizDocRef);
        if (!quizDocSnapshot.exists()) {
          return router.replace("/engagement/quiz");
        }
        setform(quizDocSnapshot.data());
        const submissionsQuery = query(collection(db, `quizs/${id}/submissions`));
        const submissionsSnapshot = await getDocs(submissionsQuery);
        const submissionsData = submissionsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setquiz(submissionsData);
        setloading(false);
        sendAmplitudeData("viewing Quiz details", { Quiz: id });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const copy = () => {
    navigator?.clipboard?.writeText(window.location.host + "/submitQuiz/" + id);
    showSuccess("Link copied to clipboard");
  };

  function convertToCSV(jsonData) {
    // Function to properly escape CSV values
    const escapeCSVValue = value => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return `"${value}"`;
    };

    const header = Object.keys(jsonData[0]).map(key => escapeCSVValue(key)).join(',');
    const rows = jsonData.map(obj => Object.values(obj).map(val => escapeCSVValue(val)).join(','));
    return header + '\n' + rows.join('\n');
  }

  async function exportcsv() {
    const csvString = quiz.reduce((total, curr) => {
      const user = curr.user_info;
      var row = [user.name, user.email ?? "-", user.phone ?? "-", curr.response.filter((e) => e.isRight).length + "/" + curr.response.length].join(",");
      return total + "\n" + row;
    }, "name,email,phone,answer corrected");

    const data = `data:,${csvString}`;
    const filename = `${form?.campaign_name || form?.productname}.csv`;
    const aTag = document.createElement("a");
    aTag.href = data;
    aTag.download = filename;
    aTag.click();
  }

  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    image: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20only%20logo.png?alt=media&token=d1d344f7-ce12-44c1-826d-3963af17ad94",
    data: "http://brand.myrevue.app/userView/" + id,
    margin: 20,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: "extra-rounded", color: "#000000" },
    backgroundOptions: { color: "#ffffff" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#ff005c" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#ff005c" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });

  async function downloadQRCode() {
    qrCode.download({
      name: id,
      extension: "png",
    });
  }

  function revealTemplate() {
    return (
      <Modalcard close={setshowaddreviews}>
        <div className="h-[80vh] w-[90vw] bg-[#232326] overflow-y-auto p-5">
          <div className="pb-5">
            <p className="text-2xl">Templates</p>
            <p className="">message template.</p>
          </div>

          <div className="flex flex-wrap gap-5 ">
            {form?.openDates.map((c, index) => (
              <div key={"template" + index}>
                <p className="my-1 text-xs">{c}</p>
                <div className="bg-[#dcf8c6] rounded-md p-2" style={{ maxWidth: "300px" }}>
                  <p
                    className="text-black"
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.innerText);
                      showSuccess("Copied");
                    }}>
                    Hey hie you recently bought product from us, would you like to do a 30 days challenge by reviewing product benefits per{" "}
                    {form.frequency}.
                    <br />
                    <br />
                    If yes use this link to submit review :<br />
                    <a href={window?.location?.host + "/submitQuiz/" + id} className="text-blue-600 break-words">
                      {window?.location?.host + "/submitQuiz/" + id}
                    </a>
                    <br />
                    <br />
                    no worry... Rewards awaiting.
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modalcard>
    );
  }

  return (
    <div className="min-h-screen text-white scroll-smooth">
      {showaddreviews === true && revealTemplate()}
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <p className="">Gettings your submissions ready </p>
        </div>
      ) : (
        <div className="flex items-start justify-between gap-10 p-10">
          <div className="flex-1 w-full">
            <div className="flex items-center justify-between flex-1">
              <div className="rounded-md h-28 w-28">
                <img src={form?.productimage} alt="product_image" className="rounded-md" />
              </div>
              <div className="bg-[#3B3D42] h-auto rounded-lg drop-shadow-lg w-full flex-1 flex flex-col justify-center items-center px-5 ml-10 gap-3 py-5">
                <p className="text-center text-[#D9D9D9] tracking-wide">Shareable link:</p>
                <div className="flex items-center gap-5">
                  <div className="items-center flex bg-[#2E3036] w-fit h-12 text-sm  gap-10 rounded-full pl-10">
                    <p>{window?.location?.host + "/submitQuiz/" + id}</p>
                    <button
                      className="p-2 m-1 rounded-full outline-none appearance-none hover:bg-green-500 bg-opacity-80"
                      onClick={() => copy()}>
                      <Copy size={22} color="white" />
                    </button>
                  </div>
                  <button
                    className="bg-[#2E3036] h-12 text-sm hover:bg-blue-500 transform duration-100 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none flex items-center gap-3 px-5"
                    onClick={() => downloadQRCode()}>
                    <DownloadSimple size={22} color="white" />
                    Download QR Code
                  </button>
                </div>
              </div>
            </div>

            <div className={"flex flex-1  flex-wrap gap-8 mt-10"}>
              {quiz.map((doc, index) => (
                <Link to={id + "/" + doc.id} key={index}>
                  <div
                    key={index}
                    className="bg-gradient-to-b from-[#42454B] to-[#383A3F] min-w-[390px] h-32  rounded-lg drop-shadow-lg relative flex gap-5 p-2 ">
                    <div className="h-full bg-white rounded-md aspect-square">
                      <img
                        src={doc?.user_info?.image ?? `https://ui-avatars.com/api/?background=random&name=${doc?.user_info?.name}`}
                        alt=""
                        className="w-full h-full rounded-md"
                      />
                    </div>
                    <div className="flex-1 w-full h-full">
                      <p className="tracking-wide">Name: {doc?.user_info?.name}</p>
                      <p className="Email/phone">Email/Phone: {doc?.user_info?.email ?? doc?.user_info?.phone}</p>
                      <p className="Email/phone">Id: {doc?.user_info?.id}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="bg-[#383A3F] w-[422px] min-h-full p-5 rounded-lg">
            <div className="flex flex-col items-center w-full gap-5">
              <button
                className="bg-[#438B4E] hover:bg-[#25da40]  transform duration-100 flex items-center gap-3 h-12 rounded-full px-5 w-fit"
                onClick={() => exportcsv()}>
                <FileCsv size={28} color="#ffffff" />
                Generate CSV
              </button>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Quiz Details</p>
              </div>
              <div className="">
                <p className="mt-2 text-sm tracking-wider">Quiz Name :{doc?.campaign_info?.campaign_name ?? form?.campaign_name}</p>

                <p className="mt-2 text-sm tracking-wider">Quiz Submissions :{quiz.filter((val) => val.reviewUrl !== null).length}</p>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center text-[#D9D9D9]">Product Details</p>
              </div>
              <div className="">
                <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">Product Name : {form.productname}</p>
                <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">Product ID : {form.prodid}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewQuizs;
